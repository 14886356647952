import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { LOADING_SCREEN } from 'routes';
import { selectAppOnboardingsList } from 'modules/onboarding/selectors';
import { notifyAppOnboardingSeen } from 'modules/onboarding/actions';
import OnboardingCarousel from 'modules/onboarding/onboarding-carousel';
import { OnboardingContainer } from 'modules/onboarding/components';
import { AppDispatch } from 'redux/typings';

const AppOnboarding = () => {
  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();

  const appOnboardings = useSelector(selectAppOnboardingsList);

  const handleGetStarted = useCallback(() => {
    dispatch(notifyAppOnboardingSeen());
    history.push(LOADING_SCREEN);
  }, [dispatch, history]);

  return (
    <OnboardingContainer>
      {appOnboardings.length > 0 ? (
        <OnboardingCarousel
          onboardings={appOnboardings}
          handleGetStarted={handleGetStarted}
        />
      ) : (
        <p>Loading...</p>
      )}
    </OnboardingContainer>
  );
};

export default AppOnboarding;
