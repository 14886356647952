import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { parseISO, addMinutes, addDays } from 'date-fns';

import { selectActiveQuest } from '../modules/loading-screen/quests/selectors';
import { selectQuestsContentful } from '../modules/loading-screen/contentful/quests/selectors';
import { selectWeeksContentful } from '../modules/loading-screen/contentful/weeks/selectors';
import { selectContents } from '../modules/loading-screen/contents/selectors';
import { selectContentsContentful } from '../modules/loading-screen/contentful/contents/selectors';
import { Content } from '../models/Content';
import { selectChallengeLateMap } from '../modules/loading-screen/schedules/selectors';
import { ChallengeInformation } from 'models/ChallengeInformation';
import { getWeeklyOverviewEmptyElement } from 'helpers/helperFunctions';

export type Section = {
  title: string;
  startingDate: Date;
  endingDate: Date;
  weekContentfulId: string;
  data: ChallengeInformation[];
};

const useSectionsList = () => {
  const activeQuest = useSelector(selectActiveQuest);
  const questsContentful = useSelector(selectQuestsContentful);
  const weekContentfulMap = useSelector(selectWeeksContentful);
  const contentUserMap = useSelector(selectContents);
  const contentContentfulMap = useSelector(selectContentsContentful);
  const challengeLateMap = useSelector(selectChallengeLateMap);

  const sections = useMemo(() => {
    const contentMapByContentfulId = Object.values(contentUserMap).reduce(
      (acc, contentUser) => {
        return {
          ...acc,
          [contentUser.contentfulId]: contentUser,
        };
      },
      {} as Record<string, Content>,
    );

    const questContentful = questsContentful[activeQuest?.contentfulId ?? ''];

    return questContentful?.weekIds.map((weekContentfulId) => {
      const weekContentful = weekContentfulMap[weekContentfulId];
      const questStartingDateString = activeQuest?.startingDate.toString();
      const questStartingDateWithOffset = parseISO(questStartingDateString!);
      const questStartingDate = addMinutes(
        questStartingDateWithOffset,
        questStartingDateWithOffset.getTimezoneOffset(),
      );
      const startingDate = addDays(
        questStartingDate,
        weekContentful.unlockAfterDays,
      );
      const endingDate = addDays(startingDate, 6);

      return {
        title: weekContentful.title,
        startingDate,
        endingDate,
        weekContentfulId,
        data: [...weekContentful.contentIds]
          .sort((contentContentfulIdA, contentContentfulIdB) => {
            const contentContentfulA =
              contentContentfulMap[contentContentfulIdA];
            const contentContentfulB =
              contentContentfulMap[contentContentfulIdB];
            return (
              (contentContentfulA?.number ?? 0) -
              (contentContentfulB?.number ?? 0)
            );
          })
          .map((contentContentfulId) => {
            const contentContentful = contentContentfulMap[contentContentfulId];
            if (!contentContentful) {
              return getWeeklyOverviewEmptyElement(contentContentfulId);
            }
            const contentUser = contentMapByContentfulId[contentContentful.id];
            const isLate = contentUser
              ? challengeLateMap[contentUser.id]
              : false;
            return {
              title: contentContentful.title,
              totalXp: contentContentful.totalXp ?? 0,
              stepIds: contentContentful.stepIds,
              imageUrl: contentContentful.imageUrl,
              contentUser,
              isLate,
              completed: contentUser?.completed,
              bonusChallenge: contentContentful?.bonusChallenge,
            } as ChallengeInformation;
          }),
      } as Section;
    });
  }, [
    contentUserMap,
    questsContentful,
    activeQuest?.contentfulId,
    activeQuest?.startingDate,
    weekContentfulMap,
    contentContentfulMap,
    challengeLateMap,
  ]);

  return sections;
};

export default useSectionsList;
