import React, { useMemo } from 'react';
import { StepIconProps } from '@material-ui/core';

import {
  OrangeCircle,
  GreyCircle,
} from 'modules/challenge/step-list/components';
import Check from 'modules/challenge/step-list/step-button/check';

const StepIcon = ({ completed, active, icon }: StepIconProps) => {
  const renderStep = useMemo(() => {
    const stepNumber = String(icon);
    if (completed || active) {
      return (
        <OrangeCircle clickable={false} currentStep={active}>
          {completed ? <Check color="white" /> : stepNumber}
        </OrangeCircle>
      );
    }
    return <GreyCircle clickable={false}>{stepNumber}</GreyCircle>;
  }, [active, completed, icon]);

  return renderStep;
};

export default StepIcon;
