import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { LinkProps, LinkText } from 'components/contentful-renders/components';
import { BlockNodeProps } from 'components/contentful-renders/typings';
import { openAssetHyperlinkModal } from 'modules/modal/actions';

type AssetHyperlinkProps = BlockNodeProps & LinkProps;

const AssetHyperlink = ({
  node,
  children,
  ...linkProps
}: AssetHyperlinkProps) => {
  const dispatch = useDispatch();

  const [isPdf, isImage, isVideo] = useMemo(() => {
    const type: string = node.data?.target?.fields?.file?.contentType || '';
    const pdf = type.includes('pdf');
    const image = type.includes('image');
    const video = type.includes('video');

    return [pdf, image, video];
  }, [node]);

  const url = useMemo(() => {
    const assetUrl = node.data?.target?.fields?.file?.url || '';
    const url = assetUrl ? `https:${assetUrl}` : '';

    return url;
  }, [node]);

  const [width, height, description] = useMemo(() => {
    let wid = 0;
    let hei = 0;
    const desc = node.data?.target?.fields?.description || '';
    const imageInfo = node.data?.target?.fields?.file?.details?.image;
    if (isImage && imageInfo) {
      wid = imageInfo.width;
      hei = imageInfo.height;
    }

    return [wid, hei, desc];
  }, [node, isImage]);

  const onPress = useCallback(() => {
    dispatch(
      openAssetHyperlinkModal({
        assetHyperlinkModalInformation: {
          isImage,
          isVideo,
          isPdf,
          url,
          width,
          height,
          description,
        },
      }),
    );
  }, [description, dispatch, height, isImage, isPdf, isVideo, url, width]);
  return (
    <LinkText onClick={onPress} {...linkProps}>
      {children}
    </LinkText>
  );
};

export default AssetHyperlink;
