import React from 'react';
import Theme from '../../../../utils/theme';

const QuestAppletIcon = ({ active = false }: { active: boolean }) => {
  return (
    <svg
      width="28"
      height="34"
      viewBox="0 0 28 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect
        width="28"
        height="34"
        rx="8"
        fill={active ? Theme.colors.blue2[100] : Theme.colors.white[100]}
      />
      <circle
        cx="14"
        cy="14"
        r="10"
        stroke={!active ? Theme.colors.blue3[40] : Theme.colors.white[100]}
        strokeWidth="2"
      />
      <path
        d="M17 13.4603C17 18.4309 15.6622 24 14.0053 24C12.3485 24 11.0052 18.4309 11.0052 13.4603C11.0052 8.48976 12.3485 4 14.0053 4C15.6622 4 17 8.48976 17 13.4603Z"
        stroke={!active ? Theme.colors.blue3[40] : Theme.colors.white[100]}
        strokeWidth="2"
      />
      <path
        d="M5 14H24"
        stroke={!active ? Theme.colors.blue3[40] : Theme.colors.white[100]}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M5.83325 29.5926H22.1666"
        stroke={!active ? Theme.colors.blue3[40] : Theme.colors.white[100]}
      />
    </svg>
  );
};

export default QuestAppletIcon;
