import React from 'react';

const DateArrow = () => {
  return (
    <svg
      width="22px"
      height="22px"
      viewBox="0 0 8 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.25"
        d="M3.64645 13.3536C3.84171 13.5488 4.15829 13.5488 4.35355 13.3536L7.53553 10.1716C7.7308 9.97631 7.7308 9.65973 7.53553 9.46447C7.34027 9.2692 7.02369 9.2692 6.82843 9.46447L4 12.2929L1.17157 9.46447C0.97631 9.2692 0.659728 9.2692 0.464466 9.46447C0.269203 9.65973 0.269203 9.97631 0.464466 10.1716L3.64645 13.3536ZM3.5 -2.18557e-08L3.5 13L4.5 13L4.5 2.18557e-08L3.5 -2.18557e-08Z"
        fill="white"
      />
    </svg>
  );
};

export default DateArrow;
