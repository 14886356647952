import React, { useCallback, useState } from 'react';

import {
  CarouselCenterContainer,
  OnboardingCarrouselIndicatorContainer,
  OnboardingNextButton,
  OnboardingPrevButton,
} from 'modules/onboarding/components';
import OnboardingItem from './OnboardingItem';
import Carousel from 'components/carousel';
import ActiveSlideIndicator from 'components/carousel/ActiveSlideIndicator';
import { Onboarding } from 'models/Onboarding';
import localizedStrings from 'localization';

type OnboardingCarouselProps = {
  onboardings: Onboarding[];
  handleGetStarted: () => void;
  buttonText?: string;
};

const OnboardingCarousel = ({
  onboardings,
  handleGetStarted,
  buttonText = localizedStrings.getStarted,
}: OnboardingCarouselProps) => {
  const [index, setIndex] = useState(0);
  const maxIndex = onboardings.length;

  const handleNext = useCallback(() => {
    setIndex((index) => index + 1);
  }, []);

  const handlePrev = useCallback(() => {
    setIndex((index) => index - 1);
  }, []);

  return (
    <>
      <OnboardingPrevButton onClick={handlePrev} disabled={index === 0} />
      <CarouselCenterContainer>
        <Carousel currentIndex={index}>
          {onboardings.map((onboarding, slideIndex) => (
            <OnboardingItem
              key={onboarding.title}
              item={onboarding}
              activeIndex={index}
              lastItem={slideIndex === maxIndex - 1}
              handleGetStarted={handleGetStarted}
            />
          ))}
        </Carousel>
        <OnboardingCarrouselIndicatorContainer>
          <ActiveSlideIndicator activeIndex={index} maxIndex={maxIndex} />
        </OnboardingCarrouselIndicatorContainer>
      </CarouselCenterContainer>
      <OnboardingNextButton
        onClick={handleNext}
        disabled={index === maxIndex - 1}
      />
    </>
  );
};

export default OnboardingCarousel;
