import React, { useEffect } from 'react';
import SyncAllContent from './SyncAllContent';
import SyncChallenge from './SyncChallenge';
import { useDispatch } from 'react-redux';
import { selectStep } from 'modules/loading-screen/contents/actions';

type SyncContentProps = {
  onlyChallenge?: boolean;
};

const SyncContent = ({ onlyChallenge }: SyncContentProps) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(selectStep(0));
  }, [dispatch])


  return onlyChallenge ? <SyncChallenge /> : <SyncAllContent />;
};

export default SyncContent;
