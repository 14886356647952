import React, { PropsWithChildren } from 'react';
import { CarouselContainer, CarouselItemsContainer } from './components';

type CarouselProps = {
  currentIndex: number;
};

const Carousel = ({
  currentIndex,
  children,
}: PropsWithChildren<CarouselProps>) => {
  return (
    <CarouselContainer>
      <CarouselItemsContainer currentIndex={currentIndex}>
        {children}
      </CarouselItemsContainer>
    </CarouselContainer>
  );
};

export default Carousel;
