import React, { useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { selectAppletContentfulById } from 'modules/loading-screen/contentful/applets/selectors';
import useContentfulRenderer from 'hooks/useContentfulRenderer';
import {
  selectAppletAnswers,
  selectAppletById,
  selectAppletsForShareModal,
} from 'modules/applet-upload/selectors';
import useExistingApplet from 'hooks/useExistingApplet';
import useIsAppletDisabled from 'hooks/useIsAppletDisabled';
import useIsModalOpen from 'hooks/useIsModalOpen';
import theme from 'utils/theme';
import MadlibApplet from 'components/applets/madlib';
import ShareAppletButton from 'components/applets/share-applet-button';
import { checkHasCompletedMadlib } from 'helpers/AppletHelpers';
import { MadlibQuestionContainer } from '../../../../modules/challenge/components';
import MadlibAppletModal from '../../../../modules/challenge/madlib-applet-challenge/MadlibAppletModal';
import { IApplet, IQuizQuestion } from '../../../../models/contentful';
import { updateQuizQuestionValue } from '../../../../modules/applet-upload/actions';
import { QuizQuestionAnswerType } from '../../../../models/QuizQuestionAnswerType';
import useMadlibSentenceRenderer from '../../../../hooks/useMadlibSentenceRenderer';

type MadlibQuizQuestionOptionProps = {
  madlibAppletContentfulId: string;
  question: IQuizQuestion;
  appletContentfulId: string;
  quizContentfulId: string;
  quizQuestionContentfulId: string;
  quizSectionContentfulId: string;
};

const MadlibQuizQuestionOption = ({
  madlibAppletContentfulId,
  question,
  appletContentfulId,
  quizContentfulId,
  quizQuestionContentfulId,
  quizSectionContentfulId,
}: MadlibQuizQuestionOptionProps) => {
  const [isModalOpen, openModal, closeModal] = useIsModalOpen();
  const appletContentful = useSelector(
    selectAppletContentfulById(madlibAppletContentfulId),
  );
  const appletToUpload = useSelector(
    selectAppletById(madlibAppletContentfulId),
  );
  const appletAnswers = useSelector(selectAppletAnswers);
  const disabled = useIsAppletDisabled();
  const shareModalApplets = useSelector(selectAppletsForShareModal);
  const dispatch = useDispatch();

  const madlibAnswers = useMemo(() => {
    if (appletToUpload?.madlibAnswers) {
      return Object.values(appletToUpload?.madlibAnswers);
    }

    return [];
  }, [appletToUpload]);

  const showSendButton = useMemo(
    () => appletContentful?.sendButton && shareModalApplets.length <= 1,
    [appletContentful, shareModalApplets],
  );

  useExistingApplet(madlibAppletContentfulId);

  const madlibTitle = useContentfulRenderer(appletContentful?.uploadPrompt, {
    additionalStyles: {
      paragraph: {
        color: theme.colors.blue2[100],
        fontSize: theme.typography.large24,
        marginBottom: '2rem',
        textAlign: 'center',
      },
    },
  });

  const renderedMadlibSentence = useMadlibSentenceRenderer(
    appletContentful?.madlibSentence,
    {
      quizAppletContentfulId: appletContentfulId,
      quizContentfulId,
      quizSectionContentfulId,
      quizQuestionContentfulId,
      madlibAppletContentfulId,
    },
  );

  const renderedEditableMadlibSentence = useMadlibSentenceRenderer(
    appletContentful?.madlibSentence,
    {
      quizAppletContentfulId: appletContentfulId,
      quizContentfulId,
      quizSectionContentfulId,
      quizQuestionContentfulId,
      madlibAppletContentfulId,
      isMadlibEditMode: true,
    },
  );

  const onValueChange = useCallback(
    (option?: IApplet) => {
      dispatch(
        updateQuizQuestionValue({
          applet: {
            id: appletToUpload?.id,
            appletId: appletContentfulId,
            quizContentfulId: quizQuestionContentfulId,
            quizSectionContentfulId,
            contentfulId: question.sys.id,
            answer: undefined,
            answerType: QuizQuestionAnswerType.MADLIB,
            madlibAppletContentfulId,
            madlibAnswers:
              appletToUpload?.quizInformation?.questionsMap[
                quizQuestionContentfulId
              ]?.madlibAnswers,
          },
          modified: true,
        }),
      );
    },
    [
      dispatch,
      appletToUpload,
      appletContentfulId,
      quizQuestionContentfulId,
      quizSectionContentfulId,
      question.sys.id,
      madlibAppletContentfulId,
    ],
  );

  const onEditPress = useCallback(() => {
    openModal();
    onValueChange();
  }, [onValueChange, openModal]);

  return (
    <MadlibQuestionContainer>
      <MadlibApplet
        disabled={disabled}
        madlibSentence={renderedMadlibSentence}
        onEditPress={onEditPress}
        shareButton={
          showSendButton ? (
            <ShareAppletButton
              appletContentfulId={madlibAppletContentfulId}
              type="madlib"
              madlibAnswers={madlibAnswers}
              disabled={
                !checkHasCompletedMadlib(
                  appletAnswers,
                  appletContentful,
                  madlibAppletContentfulId,
                )
              }
            />
          ) : null
        }
      />
      <MadlibAppletModal
        closeModal={closeModal}
        editableMadlibSentence={renderedEditableMadlibSentence}
        isModalOpen={isModalOpen}
        modalTitle={madlibTitle}
      />
    </MadlibQuestionContainer>
  );
};

export default MadlibQuizQuestionOption;
