import { baseService, extractErrorMessage } from 'modules/baseService';
import HttpClient from 'services/HttpClient';
import { OldQuests } from 'models/OldQuests';
import { GetActiveQuestServiceResponse } from './typings';

const baseUrl = '/quests';

export const getActiveQuest =
  async (): Promise<GetActiveQuestServiceResponse> => {
    try {
      const result = await HttpClient.get(`${baseUrl}/active`);
      return {
        status: 'success',
        data: result.data,
      };
    } catch (err) {
      if (err.response.data?.errorCode) {
        // It returns a complex object in order to be able to handle additional information
        // and pass error information to the reducer handling rejected thunks.
        return {
          status: 'failed',
          error: err,
          errorCode: err.response.data.errorCode,
          errorInformation: err.response.data?.additionalInformation,
        };
      } else {
        // It throws en error here in order to have a default behaviour when the error
        // does not contain additional information about the failure of the request.
        throw new Error(extractErrorMessage(err.response));
      }
    }
  };

export const getOldQuests = baseService<void, OldQuests>(() =>
  HttpClient.get(`${baseUrl}/old`),
);
