import { useMemo } from 'react';
import { nanoid } from '@reduxjs/toolkit';

import { RandomSelectionOptionData } from 'modules/challenge/random-selection-applet-challenge';
import { RankOption } from 'models/RankOption';
import { IRankOptions } from 'models/contentful';

const useRandomSelectionOptions = (
  optionsContentful?: IRankOptions[],
  userOptions?: RankOption[],
) => {
  const options = useMemo(() => {
    const ret: RandomSelectionOptionData[] = [];
    for (const contentfulOption of optionsContentful || []) {
      ret.push({
        id: contentfulOption.sys.id,
        title: contentfulOption.fields.title,
        content: contentfulOption.fields.content,
        optionType: 'contentful-option',
      });
    }

    for (const userOption of userOptions || []) {
      ret.push({
        id: userOption.id || nanoid(),
        title: userOption.userInput,
        content: userOption.userInput,
        optionType: 'user-option',
      });
    }

    return ret;
  }, [optionsContentful, userOptions]);

  return options;
};

export default useRandomSelectionOptions;
