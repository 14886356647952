import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'redux/typings';

const selectNotifications = (state: RootState) => state.notifications;

export const selectAllNotifications = createSelector(
  selectNotifications,
  (notifications) => notifications.list,
);

export const selectHasNotifications = createSelector(
  selectNotifications,
  (notifications) => !!notifications.list.length,
);
