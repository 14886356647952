import React, { useMemo } from 'react';
import Check from './check';
import { GreyCircle, OrangeCircle, StepListSeparator } from '../components';

type StepButtonProps = {
  completed: boolean;
  currentStep: boolean;
  onStepClick?: () => void;
  isLast: boolean;
};

const StepButton = ({
  completed,
  currentStep,
  onStepClick,
  isLast,
}: StepButtonProps) => {
  const stepButton = useMemo(() => {
    if (completed || currentStep) {
      return (
        <OrangeCircle onClick={onStepClick} currentStep={currentStep}>
          {completed && <Check color="white" />}
        </OrangeCircle>
      );
    }
    return <GreyCircle onClick={onStepClick} />;
  }, [completed, currentStep, onStepClick]);

  return (
    <>
      {stepButton}
      {!isLast && <StepListSeparator />}
    </>
  );
};

export default StepButton;
