import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { AUTH_LOGIN } from '../routes';
import { selectIsLoggedIn } from 'modules/auth/selectors';
import { AUTH } from 'routes';

const PrivateRoute = (routeProps: RouteProps) => {
  const isLoggedIn = useSelector(selectIsLoggedIn);

  return isLoggedIn ? (
    <Route {...routeProps} />
  ) : (
    <Redirect to={AUTH + AUTH_LOGIN} />
  );
};

export default PrivateRoute;
