import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import PromiseKeptMedalIcon from './PromiseKeptMedal';
import {
  AchievementCardContainer,
  AchievementCardImage,
  AchievementCardSubTitle,
  AchievementCardTitle,
  AchievementCardTitleAndRating,
} from 'modules/profile/components';
import AchievementBadge from './Badge';
import { ACHIEVEMENT_TYPE } from 'modules/profile/typings.d';
import { selectWeeksContentful } from 'modules/loading-screen/contentful/weeks/selectors';
import { selectQuestsContentful } from 'modules/loading-screen/contentful/quests/selectors';
import localizedStrings from 'localization';
import { selectContentsContentful } from 'modules/loading-screen/contentful/contents/selectors';
import { Feedback } from 'models/Feedback';

type OtherAchievementCardProps = {
  xp: number;
  bp: number;
  type: ACHIEVEMENT_TYPE;
  weekContentfulId?: string;
  questContentfulId?: string;
  contentContentfulId?: string;
  feedbacks?: Feedback[];
};

const OtherAchievementCard = ({
  xp,
  bp,
  type,
  weekContentfulId = '',
  questContentfulId = '',
  contentContentfulId = '',
  feedbacks = [],
}: OtherAchievementCardProps) => {
  const weeksContentfulMap = useSelector(selectWeeksContentful);
  const questsContentfulMap = useSelector(selectQuestsContentful);
  const contentContentfulMap = useSelector(selectContentsContentful);

  const [title, subtitle] = useMemo(() => {
    let achievementTitle = '';
    let achievementSubtitle = '';

    switch (type) {
      case ACHIEVEMENT_TYPE.WEEK_PROMISE:
        const weekTitle = weeksContentfulMap[weekContentfulId]?.title ?? '';
        achievementTitle = localizedStrings.weekCompletedTitle(weekTitle);
        achievementSubtitle = localizedStrings.completedOnSchedule;
        break;
      case ACHIEVEMENT_TYPE.QUEST_PROMISE:
        const questTitle = questsContentfulMap[questContentfulId]?.title ?? '';
        achievementTitle = localizedStrings.questCompletedTitle(questTitle);
        achievementSubtitle = localizedStrings.completedOnSchedule;
        break;
      case ACHIEVEMENT_TYPE.REVIEW:
        const content = contentContentfulMap[contentContentfulId];
        const feedbackScore = feedbacks[0]?.score ?? 0;
        achievementTitle = localizedStrings.reviewAchievementTitle(
          feedbackScore,
        );
        achievementSubtitle = content?.title ?? '';
        break;
      default:
    }
    return [achievementTitle, achievementSubtitle];
  }, [
    type,
    weeksContentfulMap,
    weekContentfulId,
    questsContentfulMap,
    questContentfulId,
    contentContentfulMap,
    contentContentfulId,
    feedbacks,
  ]);

  const image = useMemo(() => {
    switch (type) {
      case ACHIEVEMENT_TYPE.REVIEW:
        const content = contentContentfulMap[contentContentfulId ?? ''];
        return (
          <AchievementCardImage src={content?.imageUrl} alt={content?.title} />
        );
      default:
        return <PromiseKeptMedalIcon />;
    }
  }, [type, contentContentfulMap, contentContentfulId]);

  return (
    <AchievementCardContainer>
      {image}
      <AchievementCardTitleAndRating>
        <AchievementCardTitle>{title}</AchievementCardTitle>
        <AchievementCardSubTitle>{subtitle}</AchievementCardSubTitle>
      </AchievementCardTitleAndRating>
      <AchievementBadge ribbonColor="green" xp={xp} bp={bp} />
    </AchievementCardContainer>
  );
};

export default OtherAchievementCard;
