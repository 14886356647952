import { useSelector } from 'react-redux';

import {
  selectHasFailed,
  selectIsUploadingFile,
  selectProgressForCurrentUploads,
} from 'modules/files/selectors';
import LocalizedStrings from '../localization';

const useFileUploadStatus = (): [boolean, string, boolean, string] => {
  const isUploading = useSelector(selectIsUploadingFile);
  const uploadProgress = useSelector(selectProgressForCurrentUploads);
  const hasFailed = useSelector(selectHasFailed);
  return [
    isUploading,
    `Uploading... ${uploadProgress}%`,
    hasFailed,
    LocalizedStrings.stepCompletionFileError,
  ];
};

export default useFileUploadStatus;
