import { useEffect } from 'react';
import { toast } from 'react-toastify';

import useErrorMessage from './useErrorMessage';

const useToastErrorMessage = () => {
  const [errorMessage, setErrorMessage] = useErrorMessage();

  useEffect(() => {
    if (errorMessage) {
      toast.error(errorMessage);
      setErrorMessage('');
    }
  }, [errorMessage, setErrorMessage]);
};

export default useToastErrorMessage;
