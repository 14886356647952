import React from 'react';
import { IQuizSection, IQuizQuestion } from '../../../../models/contentful';
import { QuestionTitle } from '../components';
import QuestionOptions from '../quiz-question/QuestionOptions';
import QuizQuestionOptionsListChallenge from '../quiz-question/QuizQuestionOptionsListChallenge';
import { QuizSectionSeparator } from '../../../contentful-renders/components';
import QuizQuestionSliderList from '../quiz-question/QuizQuestionSliderList';

type QuizSectionQuestionsListChallengeProps = {
  quizSection: IQuizSection;
  appletId: string;
  disabled: boolean;
  quizContentfulId: string;
};

const QuizSectionQuestionsListChallenge = ({
  quizSection,
  appletId,
  disabled,
  quizContentfulId,
}: QuizSectionQuestionsListChallengeProps) => {
  return (
    <>
      {quizSection.fields.questions.map((question: IQuizQuestion) => {
        return (
          <div key={question.sys.id}>
            <QuestionTitle>{question.fields.title}</QuestionTitle>
            <QuestionOptions
              questionOptions={
                question.fields.options ? (
                  <QuizQuestionOptionsListChallenge
                    key={question.sys.id}
                    question={question}
                    appletId={appletId}
                    quizContentfulId={quizContentfulId}
                    quizQuestionContentfulId={question.sys.id}
                    quizSectionContentfulId={quizSection.sys.id}
                  />
                ) : (
                  <QuizQuestionSliderList
                    question={question}
                    disabled={disabled}
                    appletId={appletId}
                    quizSectionContentfulId={quizSection.sys.id}
                    quizQuestionContentfulId={question.sys.id}
                  />
                )
              }
            />
            <QuizSectionSeparator />
          </div>
        );
      })}
    </>
  );
};

export default QuizSectionQuestionsListChallenge;
