import { createReducer } from '@reduxjs/toolkit';
import { getNotifications } from './actions';
import { NotificationsState } from './typings';

const initialState: NotificationsState = {
  list: [],
};

export default createReducer<NotificationsState>(initialState, (builder) =>
  builder.addCase(getNotifications.fulfilled, (_, action) => ({
    list: action.payload,
  })),
);
