import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';

import {
  Container,
  ContainerLeft,
  ContainerRight,
  FormContainer,
  FormContent,
  FormTitle,
  Logo,
  AuthErrorMessage,
  ButtonSecondary,
} from 'modules/auth/components';
import { AppDispatch, RootState } from 'redux/typings';
import logo from 'assets/images/Logo.webp';
import localizedStrings from 'localization';
import { forgotPasswordSchema } from 'modules/auth/validationSchemas';
import * as StatusSelectors from 'modules/status/selectors';
import { forgotPassword } from 'modules/auth/actions';
import { AUTH } from 'routes';
import { AUTH_LOGIN, AUTH_RESET_PASSWORD } from 'modules/auth/routes';
import { EmailPayload } from 'modules/auth/typings';
import { emailSelector } from 'modules/auth/selectors';
import ButtonPrimary from 'components/button-primary';
import InputField from 'components/input-field';
import useErrorMessage from 'hooks/useErrorMessage';

const ForgotPassword = () => {
  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();

  const [errorMessage, setErrorMessage] = useErrorMessage();

  const email = useSelector(emailSelector);
  const isLoading = useSelector((state: RootState) =>
    StatusSelectors.isPendingSelector(state, forgotPassword.typePrefix),
  );
  const sentCode = useSelector((state: RootState) =>
    StatusSelectors.wasFulfilledSelector(state, forgotPassword.typePrefix),
  );

  const handleSubmit = useCallback(
    (values: EmailPayload) => {
      setErrorMessage('');
      dispatch(forgotPassword(values));
    },
    [dispatch, setErrorMessage],
  );

  const goToLogin = useCallback(() => {
    history.push(AUTH + AUTH_LOGIN);
  }, [history]);

  useEffect(() => {
    if (sentCode && email) {
      history.push(AUTH + AUTH_RESET_PASSWORD);
    }
  }, [sentCode, email, history]);

  return (
    <Container>
      <ContainerLeft>
        <Logo src={logo}></Logo>
      </ContainerLeft>
      <ContainerRight>
        <FormContainer>
          <FormTitle>{localizedStrings.forgotPasswordHeader}</FormTitle>
          <Formik
            initialValues={{ email: '' }}
            onSubmit={handleSubmit}
            validationSchema={forgotPasswordSchema}>
            <FormContent>
              <InputField
                type="email"
                name="email"
                placeholder={localizedStrings.emailPlaceholder}
              />
              {errorMessage && (
                <AuthErrorMessage>{errorMessage}</AuthErrorMessage>
              )}
              <ButtonPrimary type="submit" isLoading={isLoading}>
                {localizedStrings.forgotPasswordMainBtn}
              </ButtonPrimary>
              <ButtonSecondary type="button" onClick={goToLogin}>
                {localizedStrings.forgotPasswordCancelBtn}
              </ButtonSecondary>
            </FormContent>
          </Formik>
        </FormContainer>
      </ContainerRight>
    </Container>
  );
};

export default ForgotPassword;
