import React, { useMemo } from 'react';

import Path from '../../../../assets/images/Path.webp';
import Star from '../../../../assets/icons/Star.webp';
import {
  ShowcaseCardImage,
  ShowCaseCardSubtitle,
  ShowCaseCardTitle,
  ShowcaseCardTextContainer,
  ShowcaseTimelineCard,
  ShowCaseTimelineContainer,
} from '../ShowcaseOverviewComponents';
import { useSelector } from 'react-redux';
import { selectApplets } from 'modules/showcase/selectors';
import AppletPreview from 'modules/profile/portfolio/quest-applets/AppletPreview';
import { QuestApplet } from 'models/QuestApplet';
import { buildQuestAppletsList } from 'helpers/AppletHelpers';

type ShowcaseTimelineProps = {
  onAppletClick: (applet: QuestApplet) => void;
};

const ShowcaseTimeline = ({ onAppletClick }: ShowcaseTimelineProps) => {
  const applets = useSelector(selectApplets);
  const groupedApplets = useMemo(() => buildQuestAppletsList(applets), [
    applets,
  ]);

  const renderCards = () => {
    return groupedApplets.map((applet, index) => (
      <ShowcaseTimelineCard
        key={applet.id}
        placeInScreen={index % 2 ? 'flex-start' : 'flex-end'}>
        <ShowcaseCardImage>
          <AppletPreview
            key={applet.id}
            applet={applet as QuestApplet}
            onAppletClick={onAppletClick}
            height="12rem"
            width="12rem"
            margin="0px"
          />
        </ShowcaseCardImage>
        <ShowcaseCardTextContainer>
          <ShowCaseCardTitle>{applet.title}</ShowCaseCardTitle>
          <ShowCaseCardSubtitle>{applet.description}</ShowCaseCardSubtitle>
        </ShowcaseCardTextContainer>
      </ShowcaseTimelineCard>
    ));
  };

  return (
    <ShowCaseTimelineContainer>
      {renderCards()}
      <div
        style={{
          backgroundRepeat: 'repeat-y',
          backgroundImage: `url(${Path})`,
          position: 'absolute',
          width: '5px',
          height: '95%',
          objectFit: 'cover',
          objectPosition: 'center center',
          top: '4rem',
          left: 'calc(50% - 2.5px)',
          zIndex: 8,
        }}></div>
      <img
        src={Star}
        style={{ alignSelf: 'center', left: 'calc(50% - 2.5px)' }}
        alt="star"
      />
    </ShowCaseTimelineContainer>
  );
};

export default ShowcaseTimeline;
