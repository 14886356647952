import {
  Relationship,
  RelationshipType,
  RelationshipStatus,
} from 'models/Relationship.d';
import localizedStrings from 'localization';
import {
  InvitePayload,
  RelationshipStatusChangePayload,
} from 'modules/relationships/typings';
import { RunningPartner } from 'models/RunningPartner';

export const getInvitePayloadFromRelationship = (
  relationship: Relationship,
  relationshipType: RelationshipType,
): InvitePayload => {
  const {
    relatedUser: { displayName, email },
  } = relationship;
  const newInvitePayload: InvitePayload = {
    inviteeName: displayName,
    inviteeEmail: email,
    inviteTitle:
      relationshipType === RelationshipType.RUNNING_PARTNER
        ? localizedStrings.defaultRunningPartnerInviteTitle
        : localizedStrings.defaultMentorInviteTitle,
    inviteMessage:
      relationshipType === RelationshipType.RUNNING_PARTNER
        ? localizedStrings.defaultRunningPartnerInviteMessage
        : localizedStrings.defaultMentorInviteMessage,
    relationshipType,
  };
  return newInvitePayload;
};

export const getInvitePayload = (
  name: string,
  email: string,
  message: string,
  relationshipType: RelationshipType,
): InvitePayload => {
  const newInvitePayload: InvitePayload = {
    inviteeName: name,
    inviteeEmail: email,
    inviteTitle:
      relationshipType === RelationshipType.EXTERNAL_RUNNING_PARTNER
        ? localizedStrings.defaultRunningPartnerInviteTitle
        : localizedStrings.defaultMentorInviteTitle,
    inviteMessage: message,
    relationshipType,
  };
  return newInvitePayload;
};

export const getRelationshipStatusChangePayloadFromRelationship = (
  relationship: Relationship,
  displayName = '',
): RelationshipStatusChangePayload => ({
  relationshipId: relationship.id,
  relationshipRelatedUserId: relationship.relatedUser.id,
  relationshipStatus: RelationshipStatus.CONFIRMED,
  notificationBody:
    relationship.relationshipType === RelationshipType.RUNNING_PARTNER ||
    relationship.relationshipType === RelationshipType.EXTERNAL_RUNNING_PARTNER
      ? localizedStrings.acceptRunningPartnerNotifBody(displayName)
      : localizedStrings.acceptMentorNotifBody(displayName),
  pushNotificationTitle:
    relationship.relationshipType === RelationshipType.RUNNING_PARTNER ||
    relationship.relationshipType === RelationshipType.EXTERNAL_RUNNING_PARTNER
      ? localizedStrings.acceptRunningPartnerPushTitle
      : localizedStrings.acceptMentorPushTitle,
  pushNotificationBody:
    relationship.relationshipType === RelationshipType.RUNNING_PARTNER ||
    relationship.relationshipType === RelationshipType.EXTERNAL_RUNNING_PARTNER
      ? localizedStrings.acceptRunningPartnerPushBody
      : localizedStrings.acceptMentorPushBody,
});

export const filterFellowTravelerByEmail = (
  relationshipHash: Record<string, Relationship>,
  invitePayload: InvitePayload,
): Record<string, Relationship> =>
  Object.fromEntries(
    Object.entries(relationshipHash).filter(
      ([id, relationship]) =>
        relationship.relatedUser.email !== invitePayload.inviteeEmail,
    ),
  );

export const modifyRelationshipListStatus = (
  relationships: Record<string, Relationship>,
  relationshipId: string,
  relationshipStatus: RelationshipStatus,
): Record<string, Relationship> => {
  const oldRelationship = relationships[relationshipId];

  const updated = {
    ...relationships,
    [relationshipId]: { ...oldRelationship, relationshipStatus },
  };

  return relationshipStatus === RelationshipStatus.CONFIRMED &&
    oldRelationship?.relationshipStatus === RelationshipStatus.RECEIVED
    ? Object.fromEntries(
        Object.entries(updated).filter(
          ([id, relationship]) =>
            relationship.relationshipStatus !== RelationshipStatus.RECEIVED,
        ),
      )
    : updated;
};

export const getRelationshipStatusFromList = (
  relationshipList: Relationship[],
  relationshipId: string,
): RelationshipStatus => {
  const oldRelationship = relationshipList.find(
    (relationship) => relationship.id === relationshipId,
  );
  return oldRelationship?.relationshipStatus || RelationshipStatus.REQUESTED;
};

export const reflectDeleteInvo = (
  relationshipHash: Record<string, Relationship>,
  relationshipId: string,
): Record<string, Relationship> => {
  const oldRelationship = Object.values(relationshipHash).find(
    (rel) => rel.id === relationshipId,
  );
  const otherRelationships = Object.fromEntries(
    Object.entries(relationshipHash).filter(
      ([id, relationship]) => relationship.id !== relationshipId,
    ),
  );

  if (!oldRelationship) {
    return otherRelationships;
  }

  if (
    oldRelationship?.relationshipType ===
      RelationshipType.EXTERNAL_RUNNING_PARTNER ||
    oldRelationship?.relationshipType === RelationshipType.EXTERNAL_MENTOR
  ) {
    return otherRelationships;
  }

  return {
    ...otherRelationships,
    [oldRelationship.relatedUser.id]: {
      ...oldRelationship,
      relationshipType: RelationshipType.FELLOW_TRAVELER,
      relationshipStatus: RelationshipStatus.CONFIRMED,
    },
  };
};

// This object is used to map a database relationship type value to a string that needs to be shown to the user in the frontend.
export const RelationshipStringMap = {
  [RelationshipType.MENTOR]: localizedStrings.mentorGuide,
  [RelationshipType.EXTERNAL_MENTOR]: localizedStrings.externalMentorGuide,
};

export const findRunningPartner = (relatioships: Relationship[]) => {
  const runningPartnerRelationship = relatioships.find(
    (relationship) =>
      relationship.relationshipType === RelationshipType.RUNNING_PARTNER ||
      relationship.relationshipType ===
        RelationshipType.EXTERNAL_RUNNING_PARTNER,
  );
  return {
    id: runningPartnerRelationship?.relatedUser.id,
    relationshipId: runningPartnerRelationship?.id,
    displayName: runningPartnerRelationship?.relatedUser.displayName,
    profilePictureUrl:
      runningPartnerRelationship?.relatedUser.profilePictureUrl,
  } as RunningPartner;
};
