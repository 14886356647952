import { createAsyncThunk } from '@reduxjs/toolkit';

import { getWeeksOnboardings as getWeeksOnboardingsService } from './services';
import { WeekOnboarding } from 'models/WeekOnboarding';

const GET_WEEKS_ONBOARDINGS = 'GET_WEEKS_ONBOARDINGS';
export const getWeeksOnboardings = createAsyncThunk<
  WeekOnboarding[],
  string | undefined
>(GET_WEEKS_ONBOARDINGS, getWeeksOnboardingsService);
