import { createSelector } from '@reduxjs/toolkit';
import compareDesc from 'date-fns/compareDesc';
import { Applet } from 'models/Applet';
import { ACHIEVEMENT_TYPE } from 'modules/profile/typings.d';

import { RootState } from 'redux/typings';

const selectRelatedUserProfile = (state: RootState) => state.relatedUserProfile;

export const selectUserAchievements = createSelector(
  selectRelatedUserProfile,
  (relatedUserProfile) =>
    relatedUserProfile.achievementsState?.achievements ?? [],
);

export const selectUserActiveQuestContentfulId = createSelector(
  selectRelatedUserProfile,
  (relatedUserProfile) =>
    relatedUserProfile.achievementsState?.activeQuestContentfulId,
);

export const selectUserXp = createSelector(
  selectRelatedUserProfile,
  (relatedUserProfile) => relatedUserProfile.achievementsState?.totalXp ?? 0,
);

export const selectUserBp = createSelector(
  selectRelatedUserProfile,
  (relatedUserProfile) => relatedUserProfile.achievementsState?.totalBp ?? 0,
);

export const selectUserTotalScore = createSelector(
  selectUserXp,
  selectUserBp,
  (xp, bp) => xp + bp,
);

export const selectUserShowcases = createSelector(
  selectRelatedUserProfile,
  (relatedUserProfile) =>
    [...(relatedUserProfile.showcases ?? [])].sort((a, b) =>
      compareDesc(new Date(a.createdAt), new Date(b.createdAt)),
    ),
);

export const selectSelectedAchievement = createSelector(
  selectRelatedUserProfile,
  (relatedUserProfile) =>
    relatedUserProfile.achievementsState?.achievements.find(
      (ach) => ach.id === relatedUserProfile.selectedAchievementId,
    ) ??
    // This is for when we navigate from notifications
    relatedUserProfile.achievementsState?.achievements.find(
      (ach) =>
        ach.contentContentfulId === relatedUserProfile.selectedAchievementId &&
        ach.type === ACHIEVEMENT_TYPE.CHALLENGE,
    ),
);

export const selectSelectedAchievementApplets = createSelector(
  selectSelectedAchievement,
  (ach) => ach?.applets,
);

export const selectSelectedAchievementAppletMap = createSelector(
  selectSelectedAchievementApplets,
  (apps) =>
    apps?.reduce(
      (acc, app) => ({
        ...acc,
        [app.contentfulId]: app,
      }),
      {} as Record<string, Applet>,
    ),
);

export const selectAchievementAppletById = (appletId: string) =>
  createSelector(selectSelectedAchievementAppletMap, (map) => map?.[appletId]);
