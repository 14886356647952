import React from 'react';
import { useSelector } from 'react-redux';

import { selectActiveQuestId } from 'modules/loading-screen/quests/selectors';
import { AllTabContainer, SummaryPadding } from '../components';
import QuestSummary from './QuestSummary';

const AllTab = () => {
  const activeQuestId = useSelector(selectActiveQuestId);

  return (
    <AllTabContainer>
      <SummaryPadding />
      <QuestSummary questId={activeQuestId} key={activeQuestId} />
      <SummaryPadding />
    </AllTabContainer>
  );
};

export default AllTab;
