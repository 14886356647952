import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Document } from '@contentful/rich-text-types';

import { selectAppletContentfulById } from 'modules/loading-screen/contentful/applets/selectors';
import RandomSelectionApplet from '../../../components/applets/random-selection';
import RandomSelectionOption from '../../../components/applets/random-selection-option';
import useContentfulRenderer from 'hooks/useContentfulRenderer';
import LocalizedStrings from 'localization';
import { selectAppletById } from 'modules/applet-upload/selectors';
import { uploadResponse } from 'modules/applet-upload/actions';
import useExistingApplet from 'hooks/useExistingApplet';
import useIsAppletDisabled from 'hooks/useIsAppletDisabled';
import RandomSelectionUserOptionsModal from './RandomSelectionUserOptionsModal';
import {
  rankOptionToRandomSelectionUserOption,
  randomSelectionUserOptionToRankOption,
} from 'helpers/AppletHelpers';
import useRandomSelectionOptions from 'hooks/useRandomSelectionOptions';
import theme from 'utils/theme';

type RandomSelectionAppletChallengeProps = {
  appletId: string;
};

type RandomSelectionContentfulOption = {
  id?: string;
  title?: string;
  content?: Document;
  optionType: 'contentful-option';
};

export type RandomSelectionUserOption = {
  id?: string;
  title?: string;
  content?: string;
  optionType: 'user-option';
};

export type RandomSelectionOptionData =
  | RandomSelectionContentfulOption
  | RandomSelectionUserOption;

const RandomSelectionAppletChallenge = ({
  appletId,
}: RandomSelectionAppletChallengeProps) => {
  const dispatch = useDispatch();
  const appletContentful = useSelector(selectAppletContentfulById(appletId));
  const renderedUploadPrompt = useContentfulRenderer(
    appletContentful?.uploadPrompt,
    {
      additionalStyles: {
        paragraph: {
          color: theme.colors.grey[90],
        },
      },
    },
  );
  const appletUpload = useSelector(selectAppletById(appletId));
  const id = useExistingApplet(appletId);
  const disabled = useIsAppletDisabled();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [userOptions, setUserOptions] = useState<RandomSelectionUserOption[]>(
    () => {
      if (appletUpload?.options) {
        const uploadedOptions = appletUpload.options.map(
          rankOptionToRandomSelectionUserOption,
        );
        return uploadedOptions;
      }
      return [];
    },
  );
  const options = useRandomSelectionOptions(
    appletContentful?.options,
    appletUpload?.options,
  );

  const buttonText = useMemo(() => {
    let text;
    if (appletUpload?.text) {
      text = LocalizedStrings.reset;
    }

    return text;
  }, [appletUpload]);

  const onButtonClick = useCallback(() => {
    if (!appletUpload?.text) {
      const arrayLength = options?.length || 0;
      const randomlyChosenOption =
        options?.[Math.floor(Math.random() * arrayLength)];
      const selectedIndex = options?.findIndex(
        (opt) => opt.id === randomlyChosenOption?.id,
      );
      if (selectedIndex !== undefined && selectedIndex >= 0) {
        dispatch(
          uploadResponse({
            applet: {
              id,
              appletId,
              appletType: 'random-selection',
              text: randomlyChosenOption.id,
              options: appletUpload?.options,
              untouched: false,
            },
            modified: true,
          }),
        );
      }
    } else {
      dispatch(
        uploadResponse({
          applet: {
            id,
            appletId,
            appletType: 'random-selection',
            text: undefined,
            options: appletUpload?.options,
            untouched: false,
          },
          modified: true,
        }),
      );
    }
  }, [
    appletId,
    appletUpload?.options,
    appletUpload?.text,
    dispatch,
    id,
    options,
  ]);

  const onListUpdate = useCallback(
    (options: RandomSelectionUserOption[]) => {
      dispatch(
        uploadResponse({
          applet: {
            id,
            appletId,
            appletType: 'random-selection',
            options: userOptions.map(randomSelectionUserOptionToRankOption),
            untouched: false,
          },
          modified: true,
        }),
      );
    },
    [appletId, dispatch, id, userOptions],
  );

  const onAddOptionsClick = useCallback(() => {
    setIsEditModalOpen(true);
  }, []);

  useEffect(() => {
    if (
      appletUpload?.options &&
      appletUpload?.untouched &&
      userOptions.length < appletUpload.options.length
    ) {
      const uploadedOptions = appletUpload.options.map(
        rankOptionToRandomSelectionUserOption,
      );
      setUserOptions(uploadedOptions);
    }
  }, [appletUpload, userOptions]);

  return (
    <>
      <RandomSelectionApplet
        options={options?.map((opt) => {
          const selected = opt.id === appletUpload?.text;
          const optionDisabled =
            disabled || (Boolean(appletUpload?.text) && !selected);
          return (
            <RandomSelectionOption
              key={opt.id}
              option={opt}
              selected={opt.id === appletUpload?.text}
              disabled={optionDisabled}
            />
          );
        })}
        uploadPrompt={renderedUploadPrompt}
        onButtonClick={onButtonClick}
        buttonText={buttonText}
        disabled={disabled}
        onAddOptionsClick={onAddOptionsClick}
        showAddOptionsButton={!Boolean(appletUpload?.text)}
      />
      <RandomSelectionUserOptionsModal
        isEditModalOpen={isEditModalOpen}
        setIsEditModalOpen={setIsEditModalOpen}
        userOptions={userOptions}
        setUserOptions={setUserOptions}
        onListUpdate={onListUpdate}
      />
    </>
  );
};

export default RandomSelectionAppletChallenge;
