import React, { useCallback, useMemo, useRef, useState } from 'react';
import { nanoid } from '@reduxjs/toolkit';

import Modal from 'components/modal';
import {
  FormContainer,
  UserOptionContainer,
  AddOptionButtonContainer,
  AddOptionButton,
  InputContainer,
  UserOptionIconsContainer,
  SaveOptionsButtonContainer,
  SaveOptionsButton,
  IconContainer,
} from 'common/CommonComponents';
import EditIcon from 'components/icons/EditIcon';
import localizedStrings from 'localization';
import { Formik, FormikProps } from 'formik';
import InputField from 'components/input-field';
import { addUserOptionValidationSchema } from './validationSchema';
import { ErrorMessage } from 'components/contentful-renders/components';
import { RandomSelectionUserOption } from '.';
import DeleteIcon from 'components/icons/DeleteIcon';

type AddOptionSchema = {
  userValue: string;
};

type RandomSelectionUserOptionsModalProps = {
  setUserOptions: (values: RandomSelectionUserOption[]) => void;
  userOptions: RandomSelectionUserOption[];
  isEditModalOpen: boolean;
  setIsEditModalOpen: (val: boolean) => void;
  onListUpdate?: (items: RandomSelectionUserOption[]) => void;
};

const RandomSelectionUserOptionsModal = ({
  userOptions,
  setUserOptions,
  isEditModalOpen,
  setIsEditModalOpen,
  onListUpdate,
}: RandomSelectionUserOptionsModalProps) => {
  const formikRef = useRef<FormikProps<AddOptionSchema>>(null);
  const [error, setError] = useState('');
  const disabled = useMemo(() => userOptions.length < 1, [userOptions]);

  const handleEditOption = useCallback(
    (id: string) => () => {
      const filteredOptions = userOptions.filter((opt) => opt.id !== id);
      const editedOption = userOptions.find((opt) => opt.id === id);
      if (editedOption?.content) {
        formikRef.current?.setFieldValue(
          'userValue',
          editedOption?.content || '',
        );
      }
      setUserOptions(filteredOptions);
      setError('');
    },
    [setUserOptions, userOptions],
  );

  const handleDeleteOption = useCallback(
    (id: string) => () => {
      let filteredArray = userOptions.filter(
        (item: RandomSelectionUserOption) => item.id !== id,
      );
      setUserOptions(filteredArray);
      setError('');
    },
    [setUserOptions, userOptions],
  );

  const saveOptions = useCallback(() => {
    onListUpdate?.(userOptions);
    setIsEditModalOpen(false);
  }, [onListUpdate, setIsEditModalOpen, userOptions]);

  const closeModal = useCallback(() => {
    setIsEditModalOpen(false);
  }, [setIsEditModalOpen]);

  const addOption = useCallback(
    ({ userValue }: { userValue: string }) => {
      if (userValue) {
        setUserOptions([
          ...userOptions,
          {
            id: nanoid(),
            title: userValue,
            content: userValue,
            optionType: 'user-option',
          },
        ]);
        setError('');
        formikRef.current?.resetForm();
      }
    },
    [setUserOptions, userOptions],
  );

  return (
    <Modal
      title={localizedStrings.addRandomSelectionOptionsButton}
      open={isEditModalOpen}
      closeModal={closeModal}>
      <div>
        <>
          {userOptions?.map(
            (option: RandomSelectionUserOption, index: number) => (
              <UserOptionContainer key={option.id}>
                {`${option.content}`}
                <UserOptionIconsContainer>
                  <IconContainer onClick={handleEditOption(option.id!)}>
                    <EditIcon />
                  </IconContainer>
                  <IconContainer onClick={handleDeleteOption(option.id!)}>
                    <DeleteIcon />
                  </IconContainer>
                </UserOptionIconsContainer>
              </UserOptionContainer>
            ),
          )}
        </>
        <Formik
          initialValues={{ userValue: '' } as AddOptionSchema}
          validationSchema={addUserOptionValidationSchema}
          onSubmit={addOption}
          innerRef={formikRef}>
          <FormContainer>
            <InputContainer>
              <InputField name="userValue" />
            </InputContainer>
            <AddOptionButtonContainer>
              <AddOptionButton type="submit">
                {localizedStrings.addOption}
              </AddOptionButton>
            </AddOptionButtonContainer>
          </FormContainer>
        </Formik>
        <SaveOptionsButtonContainer>
          <SaveOptionsButton onClick={saveOptions} disabled={disabled}>
            {localizedStrings.save}
          </SaveOptionsButton>
          {error ? <ErrorMessage>{error}</ErrorMessage> : null}
        </SaveOptionsButtonContainer>
      </div>
    </Modal>
  );
};

export default RandomSelectionUserOptionsModal;
