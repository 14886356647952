import styled from '@emotion/styled';
import { Form } from 'formik';

import background from 'assets/images/SignIn.webp';

export const Container = styled.div`
  font-family: ${(props) => props?.theme?.typography.normal};
  display: flex;
  height: 100vh;
`;

export const ContainerLeft = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(${background});
  background-position: center;
  background-size: cover;
`;

export const Logo = styled.img`
  flex-basis: 190px;
`;

export const ContainerRight = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 70%;
  max-width: 465px;
`;

export const FormTitle = styled.p`
  color: #032d52;
  font-size: ${(props) => props?.theme?.typography.large60};
  line-height: ${(props) => props?.theme?.typography.large60};
  font-weight: ${(props) => props?.theme?.typography.bold};
  max-width: 900px;
  margin-bottom: 2rem;
`;

export const FormSubtitle = styled.p`
  color: #03284a;
  font-size: ${(props) => props?.theme?.typography.large24};
  line-height: ${(props) => props?.theme?.typography.large32};
  max-width: 312px;
`;

export const FormContent = styled(Form)`
  display: flex;
  flex-direction: column;
`;

export const AuthErrorMessage = styled.div`
  color: ${(props) => props?.theme?.colors.red[80]};
  font-size: ${(props) => props?.theme?.typography.large16};
  line-height: ${(props) => props?.theme?.typography.large16};
  margin-bottom: 1rem;
`;

export const ForgotPasswordLink = styled.span`
  color: ${(props) => props?.theme?.colors.blue2[100]};
  font-size: ${(props) => props?.theme?.typography.large13};
  line-height: ${(props) => props?.theme?.typography.large13};
  text-align: center;
  cursor: pointer;
  margin-bottom: 1rem;
`;

export const ButtonSecondary = styled.button`
  background-color: ${(props) => props?.theme?.colors.white[100]};
  font-size: ${(props) => props?.theme?.typography.large18};
  color: ${(props) => props?.theme?.colors.blue2[100]};
  padding: 0.7rem 0 0.7rem 0;
  border: 1px solid ${(props) => props?.theme?.colors.blue2[100]};
  border-radius: 0.4rem;
`;

export const FormMessage = styled.div`
  color: ${(props) => props?.theme?.colors.grey[90]};
  font-size: ${(props) => props?.theme?.typography.large13};
  line-height: ${(props) => props?.theme?.typography.large18};
  text-align: center;
  width: 80%;
  align-self: center;
  margin-bottom: 1rem;
`;

export const FormLink = styled.span`
  color: ${(props) => props?.theme?.colors.blue2[100]};
  cursor: pointer;
`;
