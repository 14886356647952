import styled from '@emotion/styled';

import {
  NextButton,
  PrevButton,
  ScrollableArea,
  MapBackground,
} from 'components/components';
import ButtonPrimary from 'components/button-primary';

export const OnboardingContainer = styled(MapBackground)`
  font-family: ${(props) => props?.theme?.typography.normal};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
`;

export const CarouselCenterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 55rem;
  max-width: 70%;
  height: 100%;
`;

export const OnboardingPrevButton = styled(PrevButton)`
  height: 3.3rem;
  width: 3.3rem;
  margin-right: 3.5rem;
  margin-left: 1.1rem;
  @media (max-width: ${(props) => props?.theme?.breakpoints?.desktopLarge}px) {
    margin-right: 1.1rem;
  }
`;

export const OnboardingNextButton = styled(NextButton)`
  height: 3.3rem;
  width: 3.3rem;
  margin-left: 3.5rem;
  margin-right: 1.1rem;
  @media (max-width: ${(props) => props?.theme?.breakpoints?.desktopLarge}px) {
    margin-left: 1.1rem;
  }
`;

export const OnboardingCarrouselIndicatorContainer = styled.div`
  margin-top: 1.6rem;
`;

export const OnboardingItemContainer = styled.div`
  border-radius: 0.6rem;
  background-color: ${(props) => props?.theme?.colors.blue3[100]};
  overflow: hidden;
  height: 80vh;
  flex: 0 0 100%;
`;

type OnboardingItemImageProps = {
  backgroundImage: string;
};

export const OnboardingItemImage = styled.div<OnboardingItemImageProps>`
  height: 55%;
  width: 100%;
  background-image: url(${(props) => props.backgroundImage});
  background-size: cover;
`;

export const OnboardingItemVideo = styled.video`
  height: 55%;
  width: 100%;
`;

export const OnboardingItemContent = styled.div`
  height: 45%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const OnboardingItemTitleAndDescription = styled(ScrollableArea)`
  flex: 1;
  margin-bottom: 1rem;
  margin-top: 1.5rem;
`;

export const OnboardingItemTitle = styled.div`
  color: ${(props) => props?.theme?.colors.white[100]};
  font-size: ${(props) => props?.theme?.typography.large24};
  font-weight: ${(props) => props?.theme?.typography.semiBold};
  line-height: ${(props) => props?.theme?.typography.large24};
  margin-left: 1rem;
`;

export const OnboardingItemDescription = styled.div`
  color: ${(props) => props?.theme?.colors.white[100]};
  font-size: ${(props) => props?.theme?.typography.large18};
  line-height: ${(props) => props?.theme?.typography.large24};
  margin: 1.5rem 1rem -0.7rem 1rem;
`;

export const OnboardingButton = styled(ButtonPrimary)`
  width: 11rem;
  align-self: center;
  margin-bottom: 2rem;
`;



export const RowOnboarding = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex: 1;
  justify-content: center;
`;
