import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'redux/typings';

const selectQuestsOnboardingsState = (state: RootState) =>
  state.contentful.questsOnboardings;

export const selectQuestsOnboardings = createSelector(
  selectQuestsOnboardingsState,
  (questsOnboardingsState) => questsOnboardingsState.questsOnboardings,
);

export const selectQuestsOnboardingsLastUpdatedAt = createSelector(
  selectQuestsOnboardingsState,
  (questsOnboardingsState) => questsOnboardingsState.lastUpdatedAt,
);
