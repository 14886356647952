import React, {
  PropsWithChildren,
  ReactNode,
  useCallback,
  useState,
  useMemo,
} from 'react';

import DownCaret from 'components/icons/DownCaret';
import UpCaret from 'components/icons/UpCaret';
import {
  CollapsibleContainer,
  CollapsibleSummaryContainer,
  CollapsibleSummaryRightSide,
  CollapsibleDetail,
  CollapsibleInformationContainer,
  CollapsibleProgressContainer,
  CollapsibleSeparator,
  CollapsibleTitle,
  DashedLineContainer,
  DetailContent,
  FullDashContainer,
  HidingContainer,
} from './components';
import PieProgressIndicator from 'components/icons/PieProgressIndicator';
import theme from 'utils/theme';
import DashedLine from 'components/dashed-line';

type CollapsibleProps = {
  title: ReactNode;
  initOpen?: boolean;
  withProgress?: boolean;
  progress?: number;
  withoutDash?: boolean;
  separatorColor?: string;
  caretColor?: string;
  contentLength?: number;
};

const Collapsible = ({
  title,
  initOpen = false,
  withProgress,
  progress = 0,
  withoutDash,
  children,
  separatorColor,
  caretColor,
  contentLength,
}: PropsWithChildren<CollapsibleProps>) => {
  const [collapsed, setCollapsed] = useState(!initOpen);

  const toggleCollapsed = useCallback(() => {
    setCollapsed((collapsed) => !collapsed);
  }, []);

  const numberOfDashes = useMemo(() => {
    if (contentLength) {
      const dashes = contentLength * 3;
      if (dashes <= 6) {
        return 9;
      } else {
        return dashes;
      }
    }
    return 15;
  }, [contentLength]);

  return (
    <CollapsibleContainer>
      <CollapsibleInformationContainer>
        <CollapsibleSummaryContainer>
          {withProgress ? (
            <CollapsibleProgressContainer>
              <PieProgressIndicator
                progress={progress}
                height={theme.typography.large32}
                width={theme.typography.large32}
              />
            </CollapsibleProgressContainer>
          ) : null}
          <CollapsibleSummaryRightSide>
            <CollapsibleTitle onClick={toggleCollapsed}>
              {title}
              {collapsed ? (
                <DownCaret color={caretColor} />
              ) : (
                <UpCaret color={caretColor} />
              )}
            </CollapsibleTitle>
            <CollapsibleSeparator color={separatorColor} />
          </CollapsibleSummaryRightSide>
        </CollapsibleSummaryContainer>
        <CollapsibleDetail collapsed={collapsed}>
          {withProgress ? (
            <DashedLineContainer>
              {withoutDash ? null : (
                <HidingContainer>
                  <FullDashContainer>
                    <DashedLine
                      width="4px"
                      numberOfDashes={numberOfDashes}
                      vertical
                    />
                  </FullDashContainer>
                </HidingContainer>
              )}
            </DashedLineContainer>
          ) : null}
          <DetailContent collapsed={collapsed}>{children}</DetailContent>
        </CollapsibleDetail>
      </CollapsibleInformationContainer>
    </CollapsibleContainer>
  );
};

export default Collapsible;
