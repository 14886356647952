import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { nanoid } from 'nanoid/non-secure';

import { ChatMessage, Subscription } from './typings';
import { selectUserId, selectDisplayName } from 'modules/auth/selectors';
import { selectSubscription } from './selectors';
import { selectSelectedRelationship } from 'modules/relationships/selectors';
import {
  upsertChatroomForRelationship as upsertChatroomService,
  fetchMessagesFromChatroom,
  subscribeToChatroom as chatService,
  sendMessageToChatroom,
  signInAnon,
} from './services';
import { updateRelationshipDate } from 'modules/relationships/actions';

export const selectChatroom = createAction<string>('SELECT_CHATROOM');
export const pushMessages = createAction<ChatMessage[]>('PUSH_MESSAGES');
export const subscribedToChatroom = createAction<Subscription>(
  'SUBSCRIBED_TO_CHATROOM',
);
export const unsubscribedFromChatroom = createAction(
  'UNSUBSCRIBED_FROM_CHATROOM',
);

const SUBSCRIBE_TO_CHATROOM = 'SUBSCRIBE_TO_CHATROOM';
export const subscribeToChatroom = createAsyncThunk(
  SUBSCRIBE_TO_CHATROOM,
  async (_, { dispatch, getState }) => {
    const state = getState() as any;
    const relationship = selectSelectedRelationship(state);
    const userId = selectUserId(state);

    if (!relationship) {
      return;
    }
    const chatId = relationship.chatId;
    await signInAnon();
    dispatch(selectChatroom(chatId));
    await upsertChatroomService(chatId, userId);
    dispatch(pushMessages(await fetchMessagesFromChatroom(chatId)));
    const subscription = chatService(chatId, {
      onNewMessage: (msg) => dispatch(pushMessages([msg])),
    });
    dispatch(subscribedToChatroom(subscription));
  },
);

const SEND_MESSAGE = 'SEND_MESSAGE';
export const sendMessage = createAsyncThunk<void, string>(
  SEND_MESSAGE,
  async (messageBody, { dispatch, getState }) => {
    const state = getState() as any;
    const senderId = selectUserId(state);
    const senderName = selectDisplayName(state);
    const relationship = selectSelectedRelationship(state);

    if (!senderId || !senderName || !relationship) {
      return;
    }

    await sendMessageToChatroom(relationship, {
      id: nanoid(8),
      text: messageBody,
      createdAt: new Date(),
      senderId,
    });

    dispatch(updateRelationshipDate(relationship.id));
  },
);

const UNSUBSCRIBE_FROM_CHATROOM = 'UNSUBSCRIBE_FROM_CHATROOM';
export const unsubscribeFromChatroom = createAsyncThunk(
  UNSUBSCRIBE_FROM_CHATROOM,
  async (_, { dispatch, getState }) => {
    const state = getState() as any;
    const subscription = selectSubscription(state);

    if (!subscription) {
      return;
    }

    dispatch(unsubscribedFromChatroom());
    subscription.unsubscribe();
  },
);
