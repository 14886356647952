import React, { useCallback } from 'react';

import {
  RadioButtonContainer,
  RadioButtonText,
  RadioButtonCircle,
  RadioButtonFill,
} from 'modules/relationships/components';

type RadioButtonProps = {
  text: string;
  value: string;
  selectedValue: string;
  onClick: (value: string) => void;
};

const RadioButton = ({
  text,
  value,
  selectedValue,
  onClick,
}: RadioButtonProps) => {
  const handleClick = useCallback(() => {
    onClick(value);
  }, [onClick, value]);

  return (
    <RadioButtonContainer onClick={handleClick}>
      <RadioButtonText>{text}</RadioButtonText>
      <RadioButtonCircle>
        {selectedValue === value && <RadioButtonFill />}
      </RadioButtonCircle>
    </RadioButtonContainer>
  );
};

export default RadioButton;
