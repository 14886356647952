import { createReducer } from '@reduxjs/toolkit';
import { arrayToHash } from 'helpers/helperFunctions';
import { Week } from 'models/Week';
import { submitPost } from '../contents/actions';
import { getActiveQuest, getOldQuests } from '../quests/actions';
import {
  getAvailableWeeks,
  resetHasCompletedWeeks,
  selectWeek,
  selectWeekHighlighted,
} from './actions';
import { WeeksState } from './typings';

const initialState: WeeksState = {
  weeks: {},
  weeksSelected: [0],
};

export default createReducer<WeeksState>(initialState, (builder) =>
  builder
    .addCase(getAvailableWeeks.fulfilled, (state, action) => ({
      ...state,
      weeks: { ...state.weeks, ...weeksArrayToHash(action.payload) },
    }))
    .addCase(selectWeek, (state, action) => ({
      ...state,
      selectedId: action.payload,
    }))
    .addCase(selectWeekHighlighted, (state, action) => ({
      ...state,
      weeksSelected: action.payload,
    }))
    .addCase(getActiveQuest.fulfilled, (state, action) => ({
      ...state,
      weeks: { ...state.weeks, ...weeksArrayToHash(action.payload.weeks) },
    }))
    .addCase(getOldQuests.fulfilled, (state, action) => ({
      ...state,
      weeks: { ...state.weeks, ...weeksArrayToHash(action.payload.weeks) },
    }))
    .addCase(submitPost.fulfilled, (state, action) => ({
      ...state,
      hasCompletedWeek: action.payload.hasCompletedWeeks,
      completedWeekId: action.payload.completedWeekId,
    }))
    .addCase(resetHasCompletedWeeks, (state) => ({
      ...state,
      hasCompletedWeek: false,
      completedWeekId: undefined,
    })),
);

const weeksArrayToHash = (weeksArray: Week[]) =>
  arrayToHash<Week>(weeksArray, (week) => week.id);
