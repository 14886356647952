import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import localizedStrings from 'localization';
import { SHOWCASE } from 'routes';
import { Achievement } from 'modules/profile/typings.d';
import {
  selectUserAchievements,
  selectUserShowcases,
  selectUserXp,
  selectUserBp,
  selectUserActiveQuestContentfulId,
} from '../selectors';
import {
  RelatedUserPortfolioSection,
  RelatedUserCurrentQuest,
  RelatedUserAchievementsSection,
} from '../../components';
import { PublishedShowcaseEmpty } from 'modules/profile/components';
import ExperienceSummary from 'modules/profile/achievements/experience-summary';
import PublishedShowcaseItem from 'modules/profile/portfolio/published-showcases/PublishedShowcaseItem';
import AchievementCard from 'modules/profile/achievements/achievements-list/AchievementCard';
import { selectQuestsContentful } from 'modules/loading-screen/contentful/quests/selectors';
import {
  selectSelectedRelationship,
  selectVisibilityPermissions,
} from 'modules/relationships/selectors';
import { getProfileVisibilityAchievements } from 'modules/relationships/actions';
import { VisibilityAchievementsType } from 'modules/auth/typings';

type RelatedUserDetailsProps = {
  userId?: string;
};

const VISIBILITY_ACHIEVEMENTS_EVERYBODY: VisibilityAchievementsType =
  'EVERYBODY';

const RelatedUserDetails = ({ userId }: RelatedUserDetailsProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const selectedRelationship = useSelector(selectSelectedRelationship);
  const userAchievements = useSelector(selectUserAchievements);
  const userVisibilityPermissions = useSelector(selectVisibilityPermissions);
  const userShowcases = useSelector(selectUserShowcases);
  const userXp = useSelector(selectUserXp);
  const userBp = useSelector(selectUserBp);
  const userActiveQuestContentfulId = useSelector(
    selectUserActiveQuestContentfulId,
  );
  const questsContentful = useSelector(selectQuestsContentful);
  const [achievementSelected, setAchievementSelected] = useState<Achievement>();

  const questTitle = useMemo(() => {
    if (questsContentful && userActiveQuestContentfulId) {
      return questsContentful[userActiveQuestContentfulId]?.title ?? '';
    }
    return '';
  }, [questsContentful, userActiveQuestContentfulId]);

  const navigateToShowcase = useCallback((showcaseId: string) => {
    window.open(`${SHOWCASE}/${showcaseId}`);
  }, []);

  const handleAchievementClick = useCallback(
    (achievement: Achievement) => {
      if (userId) {
        dispatch(getProfileVisibilityAchievements(userId));
        setAchievementSelected(achievement);
      }
    },
    [dispatch, userId],
  );

  const renderAchievements = useMemo(
    () =>
      userAchievements?.map((achievement) => (
        <AchievementCard
          key={achievement.id}
          achievement={achievement}
          onAchievementClick={handleAchievementClick}
        />
      )),
    [userAchievements, handleAchievementClick],
  );

  useEffect(() => {
    if (userId) {
      dispatch(getProfileVisibilityAchievements(userId));
    }
  }, [dispatch, userId]);

  useEffect(() => {
    if (achievementSelected) {
      if (
        selectedRelationship?.relationshipType.toLowerCase() ===
          userVisibilityPermissions?.toLowerCase() ||
        userVisibilityPermissions === VISIBILITY_ACHIEVEMENTS_EVERYBODY
      ) {
        history.push(`${location.pathname}/${achievementSelected.id}`);
      } else {
        toast.error(localizedStrings.errorVisibilityPermission);
      }
    }
  }, [
    history,
    location.pathname,
    achievementSelected,
    selectedRelationship?.relationshipType,
    userVisibilityPermissions,
  ]);

  return (
    <>
      <RelatedUserPortfolioSection>
        <RelatedUserCurrentQuest>
          Current Quest: {questTitle}
        </RelatedUserCurrentQuest>
        {userShowcases && userShowcases.length > 0 ? (
          userShowcases.map((publishedShowcase, index) => (
            <PublishedShowcaseItem
              key={publishedShowcase.id}
              showcase={publishedShowcase}
              index={index}
              maxIndex={userShowcases.length - 1}
              onViewShowcaseClick={navigateToShowcase}
              showShareAndDelete={false}
            />
          ))
        ) : (
          <PublishedShowcaseEmpty>
            {localizedStrings.noPublishedShowcases}
          </PublishedShowcaseEmpty>
        )}
      </RelatedUserPortfolioSection>
      <RelatedUserAchievementsSection>
        <ExperienceSummary xp={userXp} bp={userBp} />
        {renderAchievements}
      </RelatedUserAchievementsSection>
    </>
  );
};

export default RelatedUserDetails;
