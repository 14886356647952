import { createReducer } from '@reduxjs/toolkit';
import { HelpState } from './typings';
import { getHelp } from './actions';

const initialState: HelpState = {
  questions: [],
};

export default createReducer(initialState, (builder) =>
  builder.addCase(getHelp.fulfilled, (_, action) => ({
    questions: action.payload,
  })),
);
