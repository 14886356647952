import styled from '@emotion/styled';

export const FilePreviewContainer = styled.div`
  display: flex;
  margin-bottom: 2rem;
`;

export const RemoveFileContainer = styled.div`
  margin-left: 10px;
`;

export const TrashIconContainer = styled.div`
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  cursor: pointer;
`;

export const DropzoneContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 6rem;
  width: 22rem;
  max-width: 100%;
  border: 1px dashed ${(props) => props?.theme?.colors.grey[90]};
  border-radius: 0.7rem;
  outline: none;
  margin-bottom: 2rem;
  background-color: ${(props) => props?.theme?.colors.grey[70]};
  cursor: pointer;
`;

export const DropzoneText = styled.div`
  color: ${(props) => props?.theme?.colors.grey[90]};
`;

export const ImagePreview = styled.img`
  max-width: calc(100% - 44px);
  border-radius: 12px;
  max-height: 500px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  padding: 15px;
  border-radius: 12px;
`;

export const VideoPreview = styled.video`
  max-width: calc(100% - 44px);
  border-radius: 12px;
  max-height: 500px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  padding: 15px;
  border-radius: 12px;
`;
