import { createReducer } from '@reduxjs/toolkit';
import {
  setFeedbackMode,
  getUserAchievements,
  getUserShowcases,
  selectAchievement,
  unselectUserChallenge,
  giveFeedback,
  removeUserAchivements,
} from './actions';
import { RelatedUserProfileState } from './typings';

const initialState: RelatedUserProfileState = {};

export default createReducer<RelatedUserProfileState>(initialState, (builder) =>
  builder
    .addCase(setFeedbackMode, (state, action) => ({
      ...state,
      feedbackMode: action.payload,
    }))
    .addCase(removeUserAchivements, (state) => ({
      ...state,
      achievementsState: undefined,
    }))
    .addCase(getUserAchievements.fulfilled, (state, action) => ({
      ...state,
      achievementsState: action.payload,
    }))
    .addCase(getUserShowcases.pending, (state) => ({
      ...state,
      showcases: undefined,
    }))
    .addCase(getUserShowcases.fulfilled, (state, action) => ({
      ...state,
      showcases: action.payload,
    }))
    .addCase(selectAchievement, (state, action) => ({
      ...state,
      selectedAchievementId: action.payload,
    }))
    .addCase(unselectUserChallenge, (state) => ({
      ...state,
      selectedAchievementId: undefined,
    }))
    .addCase(giveFeedback.fulfilled, (state, action) =>
      state.achievementsState
        ? {
            ...state,
            achievementsState: {
              ...state.achievementsState,
              achievements: state.achievementsState.achievements.map((ach) =>
                ach.id === state.selectedAchievementId
                  ? {
                      ...ach,
                      feedbacks: ach.feedbacks
                        ? [...ach.feedbacks, action.payload]
                        : [action.payload],
                    }
                  : ach,
              ),
            },
          }
        : state,
    ),
);
