import React from 'react';
import { useSelector } from 'react-redux';

import { selectReading } from './selectors';
import Modal from 'components/modal';
import WebReading from '../../components/web-reading/index';

type ReadingModalProps = {
  open: boolean;
  close: () => void;
};

const ReadingModal = ({ open, close }: ReadingModalProps) => {
  const readingContent = useSelector(selectReading);

  return (
    <Modal
      key="playlist-artifact-item-modal"
      open={open}
      closeModal={close}
      title={readingContent?.title}>
      <WebReading readingContent={readingContent} />
    </Modal>
  );
};

export default ReadingModal;
