import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { ShareAppletEmailPayload } from 'modules/applets/typings';
import useActionStatus from 'hooks/useActionStatus';
import LocalizedStrings from 'localization';
import { shareApplet } from 'modules/applets/actions';
import {
  selectIsShareAppletModalOpen,
  selectShareAppletModalInformation,
} from './selectors';
import { closeShareAppletModal } from './actions';
import { shareAppletInitialValues } from './share-applet-modal/shareAppletInitialValues';
import theme from 'utils/theme';
import { uploadFile } from 'modules/files/services';
import ShareByEmailModal from './ShareByEmailModal';
import shareAppletValidationSchema from './share-applet-modal/shareAppletValidationSchema';
import useSetValuesOnInitialValuesChange from 'hooks/useSetValuesOnInitialValuesChange';
import ShareAppletModalPreview from 'components/share-applet-modal-preview';
import { checkShareModalAppletCompleted } from 'helpers/AppletHelpers';

const ShareAppletModal = () => {
  const dispatch = useDispatch();
  const [isPreparingMail, setIsPreparingMail] = useState(false);
  const [hasSentMail, setHasSentMail] = useState(false);

  const isShareAppletModalOpen = useSelector(selectIsShareAppletModalOpen);
  const shareAppletModalInformation = useSelector(
    selectShareAppletModalInformation,
  );
  const [shareAppletPending, shareAppletFulfilled] =
    useActionStatus(shareApplet);

  const closeModal = useCallback(() => {
    dispatch(closeShareAppletModal());
  }, [dispatch]);

  const initialValues = useMemo(
    () => shareAppletInitialValues(shareAppletModalInformation),
    [shareAppletModalInformation],
  );

  const [formikRef, assignRef] =
    useSetValuesOnInitialValuesChange<ShareAppletEmailPayload>(initialValues);

  const onValidSubmit = useCallback(
    (values: ShareAppletEmailPayload) => {
      if (values.file && values.file.uri && values.file.uri.includes('blob')) {
        setIsPreparingMail(true);
        uploadFile({ file: values.file, id: 'asd' }, () => {})
          .then((fileResponse) => {
            dispatch(
              shareApplet({
                ...values,
                file: {
                  uri: fileResponse.fileUrl,
                  type: fileResponse.fileType || values.file?.type || '',
                  name: fileResponse.id,
                },
              }),
            );
            setIsPreparingMail(false);
            setHasSentMail(true);
          })
          .catch((err) => {
            setIsPreparingMail(false);
            console.log(err);
          });
      } else {
        dispatch(shareApplet(values));
        setHasSentMail(true);
      }
    },
    [dispatch],
  );

  const isSendButtonDisabled = useCallback(
    (values: ShareAppletEmailPayload, isValid: boolean) => {
      const valuesCheck =
        !values.receiver ||
        !values.subject ||
        !values.appletContentfulId ||
        !isValid;

      let appletSpecificCheck = shareAppletModalInformation
        ? !checkShareModalAppletCompleted(shareAppletModalInformation)
        : true;

      const ret = valuesCheck || appletSpecificCheck;

      return ret;
    },
    [shareAppletModalInformation],
  );

  useEffect(() => {
    if (shareAppletFulfilled && hasSentMail) {
      setHasSentMail(false);
      closeModal();
      formikRef.current?.resetForm();
      toast.success(LocalizedStrings.emailSuccess, {
        autoClose: 5000,
        style: {
          backgroundColor: theme.colors.success,
        },
      });
    }
  }, [shareAppletFulfilled, hasSentMail, closeModal, dispatch, formikRef]);

  return (
    <ShareByEmailModal
      isOpen={isShareAppletModalOpen}
      closeModal={closeModal}
      initialValues={initialValues}
      assignRef={assignRef}
      validationSchema={shareAppletValidationSchema}
      onValidSubmit={onValidSubmit}
      loading={shareAppletPending || isPreparingMail}
      preview={<ShareAppletModalPreview {...shareAppletModalInformation} />}
      sendButtonDisabled={isSendButtonDisabled}
    />
  );
};

export default ShareAppletModal;
