import React from 'react';

type RubricsIconProps = {
  colorPrimary?: string;
  colorDisabled?: string;
  disabled?: boolean;
};

const RubricsIcon = ({
  colorPrimary = '#007DC2',
  colorDisabled = '#8E8E93',
  disabled = false,
}: RubricsIconProps) => {
  return (
    <svg width="16px" height="20px" viewBox="0 0 16 20">
      <title>CE001859-4B3C-460B-952C-04C79F2C4473</title>
      <g id="V3" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="Challenge---03-"
          transform="translate(-140.000000, -439.000000)"
          fill={disabled ? colorDisabled : colorPrimary}>
          <g id="Group-2" transform="translate(125.500000, 437.000000)">
            <g id="Icons/24/Rubrics" transform="translate(10.500000, 0.000000)">
              <path d="M15,20 C15.552,20 16,20.448 16,21 C16,21.552 15.552,22 15,22 L9,22 C8.448,22 8,21.552 8,21 C8,20.448 8.448,20 9,20 L15,20 Z M12,2 C16.713,2 20,4.841 20,9.379 C20,11.229 19.316,12.763 18.013,14.449 L17.777,14.749 C17.568,15.008 16.772,15.941 16.505,16.272 L16.353,16.467 C16.142,16.75 16.033,16.944 16.004,17.047 L15.979,17.202 C15.885,17.661 15.479,18 15,18 L9,18 C8.521,18 8.115,17.661 8.021,17.202 L7.996,17.047 C7.967,16.944 7.858,16.75 7.647,16.467 L7.495,16.272 L6.223,14.749 L5.987,14.449 C4.684,12.763 4,11.229 4,9.379 C4,4.841 7.287,2 12,2 Z M12,4 C8.332,4 6,6.016 6,9.379 C6,10.7 6.513,11.855 7.563,13.218 L7.78,13.493 C7.949,13.702 8.639,14.514 8.981,14.929 L9.128,15.111 C9.38,15.434 9.577,15.722 9.719,16 L14.281,16 L14.373,15.832 C14.504,15.606 14.671,15.369 14.872,15.111 L15.445,14.419 C15.763,14.04 16.107,13.633 16.22,13.493 L16.437,13.218 C17.487,11.856 18,10.7 18,9.379 C18,6.016 15.668,4 12,4 Z"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default RubricsIcon;
