import React from 'react';

import useQuizChallengeLogic from 'hooks/useQuizChallengeLogic';
import QuizApplet from 'components/applets/quiz-applet';
import QuizResult from 'components/applets/quiz-applet/QuizResult';
import QuizAppletResultModal from './QuizAppletResultModal';
import QuizSectionListChallenge from '../../../components/applets/quiz-applet/quiz-section-list/QuizSectionListChallenge';

type QuizAppletChallengeProps = {
  appletId: string;
};

const QuizAppletChallenge = ({ appletId }: QuizAppletChallengeProps) => {
  const {
    disabled,
    openResultsModal,
    quiz,
    renderedQuizIntroduction,
    isModalOpen,
    quizQuestions,
    setIsModalOpen,
    showQuizResult,
  } = useQuizChallengeLogic(appletId);

  return (
    <>
      <QuizApplet
        disabled={disabled}
        openResultsModal={openResultsModal}
        showResultsButton={Boolean(quiz?.fields?.rewards)}
        quizIntroduction={renderedQuizIntroduction}
        quizSections={<QuizSectionListChallenge appletId={appletId} />}
        quizResult={
          showQuizResult ? (
            <QuizResult
              appletId={appletId}
              quiz={quiz}
              mixedResultText={quiz?.fields.mixedResultText}
              quizQuestions={quizQuestions}
            />
          ) : null
        }
      />
      <QuizAppletResultModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        quiz={quiz}
        mixedResultText={quiz?.fields.mixedResultText}
        quizQuestions={quizQuestions}
        appletId={appletId}
      />
    </>
  );
};

export default QuizAppletChallenge;
