import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types';
import { Options } from '@contentful/rich-text-html-renderer';
import {
  getParagraphHtml,
  getQuoteHtml,
  getH1Html,
  getH2Html,
  getH3Html,
  getH4Html,
  getH5Html,
  getH6Html,
  getHrHtml,
} from '../components.html-renderer';
import { getOlHtml } from '../blocks/ordered-list/components.html-renderer';
import { getUlHtml } from '../blocks/unordered-list/components.html-renderer';
import { getLiHtml } from 'components/app-list-item/components.html-renderer';
import { getHyperlinkHtml } from '../inlines/hyperlink/components.html-renderer';
import { getAssetHyperlinkHtml } from '../inlines/asset-hyperlink/components.html-renderer';
import { getEmbeddedEntryHtml } from '../blocks/embedded-entry/components.html-renderer';
import { getEmbeddedAssetHtml } from '../blocks/embedded-asset/components.html-renderer';
import { getInlineEntryHyperlinkHtml } from '../inlines/entry-hyperlink/components.html-renderer';
import { getInlineEmbeddedEntryHtml } from '../inlines/embedded-entry-inline/components.html-renderer';
import { AppletContentful } from 'models/AppletContentful';
import { MadlibAnswer } from '../../../models/Applet';
import { QuestApplet } from '../../../models/QuestApplet';

export const getHtmlRendererOptions = (
  matchedAppletContentful: AppletContentful,
  htmlRendererOptions: {
    matchedAppletUser?: QuestApplet;
    madlibAnswers?: MadlibAnswer[];
  },
) => {
  return {
    renderNode: {
      [BLOCKS.QUOTE]: getQuoteHtml,
      [BLOCKS.PARAGRAPH]: getParagraphHtml,
      [BLOCKS.EMBEDDED_ENTRY]: getEmbeddedEntryHtml(
        matchedAppletContentful,
        htmlRendererOptions.matchedAppletUser,
      ),
      [BLOCKS.HEADING_1]: getH1Html,
      [BLOCKS.HEADING_2]: getH2Html,
      [BLOCKS.HEADING_3]: getH3Html,
      [BLOCKS.HEADING_4]: getH4Html,
      [BLOCKS.HEADING_5]: getH5Html,
      [BLOCKS.HEADING_6]: getH6Html,
      [BLOCKS.HR]: getHrHtml,
      [BLOCKS.OL_LIST]: getOlHtml,
      [BLOCKS.UL_LIST]: getUlHtml,
      [BLOCKS.LIST_ITEM]: getLiHtml,
      [BLOCKS.EMBEDDED_ASSET]: getEmbeddedAssetHtml,
      [INLINES.HYPERLINK]: getHyperlinkHtml,
      [INLINES.EMBEDDED_ENTRY]: getInlineEmbeddedEntryHtml(
        matchedAppletContentful,
        htmlRendererOptions.madlibAnswers,
      ),
      [INLINES.ENTRY_HYPERLINK]: getInlineEntryHyperlinkHtml,
      [INLINES.ASSET_HYPERLINK]: getAssetHyperlinkHtml,
    },
    renderMark: {
      [MARKS.BOLD]: (text: string) => `<b>${text}</b>`,
      [MARKS.ITALIC]: (text: string) => `<i>${text}</i>`,
      [MARKS.UNDERLINE]: (text: string) => `<u>${text}</u>`,
      [MARKS.CODE]: (text: string) => `<code>${text}</code>`,
    },
  } as Options;
};
