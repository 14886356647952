import React, { useCallback } from 'react';

import ShareAppletModalPreview from 'components/share-applet-modal-preview';
import { ShareStepAppletInformation } from 'modules/applets/typings';

type ShareStepModalPreviewProps = {
  applets?: ShareStepAppletInformation[];
};

const ShareStepModalPreview = ({ applets }: ShareStepModalPreviewProps) => {
  const renderItem = useCallback(
    (item: ShareStepAppletInformation) => <ShareAppletModalPreview {...item} />,
    [],
  );

  return <>{applets?.map(renderItem)}</>;
};

export default ShareStepModalPreview;
