import axios from 'axios';
import { apiUrl } from 'config';
import { baseService } from 'modules/baseService';
import { ExternalSubmission, GetSubmissionArgs } from './typings';

export const getSubmission = baseService<GetSubmissionArgs, ExternalSubmission>(
  ({ id, token }) =>
    axios.get(apiUrl + 'posts/' + id, {
      headers: { authorization: `Bearer ${token}` },
    }),
);
