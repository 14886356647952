import React from 'react';

import theme from 'utils/theme';

type CheckIconProps = {
  width?: string;
  height?: string;
  circleColor?: string;
  checkColor?: string;
};

const CheckIcon = ({
  width = theme.typography.large24,
  height = theme.typography.large24,
  circleColor = theme.colors.gold[100],
  checkColor = theme.colors.white[100],
}: CheckIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ overflow: 'visible' }}>
    <rect width="24" height="24" rx="12" fill={circleColor} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.1428 15.9619C11.3632 15.9619 11.5375 15.8645 11.6606 15.6748L16.5056 8.04585C16.5979 7.89717 16.6337 7.78438 16.6337 7.66646C16.6337 7.38447 16.4492 7.1999 16.1672 7.1999C15.9621 7.1999 15.8493 7.26655 15.7263 7.46138L11.1223 14.798L8.73311 11.6706C8.60493 11.4912 8.47676 11.4194 8.29219 11.4194C7.99995 11.4194 7.8 11.6193 7.8 11.9013C7.8 12.0192 7.85127 12.1525 7.94868 12.2756L10.6096 15.6645C10.7634 15.8645 10.9223 15.9619 11.1428 15.9619Z"
      fill={checkColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9123 15.838C11.7363 16.1094 11.4691 16.2619 11.1428 16.2619C10.9849 16.2619 10.8381 16.2261 10.7025 16.1488C10.5711 16.0738 10.4647 15.968 10.3728 15.8487L7.71347 12.4618C7.71333 12.4616 7.71318 12.4614 7.71304 12.4613C7.58516 12.2995 7.5 12.1027 7.5 11.9013C7.5 11.6873 7.57718 11.4875 7.72596 11.3409C7.87456 11.1945 8.07606 11.1194 8.29219 11.1194C8.426 11.1194 8.55735 11.1461 8.68188 11.2168C8.80157 11.2847 8.89506 11.382 8.97426 11.4921L11.0981 14.2722L15.4726 7.30118C15.5464 7.18441 15.6361 7.07472 15.763 6.99949C15.8937 6.92207 16.0317 6.8999 16.1672 6.8999C16.375 6.8999 16.5723 6.96922 16.7183 7.1153C16.8644 7.26139 16.9337 7.45868 16.9337 7.66646C16.9337 7.85717 16.8718 8.02462 16.7605 8.20406L16.7588 8.20669L11.9123 15.838ZM15.7263 7.46138C15.8493 7.26655 15.9621 7.1999 16.1672 7.1999C16.4492 7.1999 16.6337 7.38447 16.6337 7.66646C16.6337 7.78438 16.5979 7.89717 16.5056 8.04585L11.6606 15.6748C11.5375 15.8645 11.3632 15.9619 11.1428 15.9619C10.9223 15.9619 10.7634 15.8645 10.6096 15.6645L7.94868 12.2756C7.85127 12.1525 7.8 12.0192 7.8 11.9013C7.8 11.6193 7.99995 11.4194 8.29219 11.4194C8.47676 11.4194 8.60493 11.4912 8.73311 11.6706L11.1223 14.798L15.7263 7.46138Z"
      fill={checkColor}
    />
  </svg>
);
export default CheckIcon;
