import { createReducer } from '@reduxjs/toolkit';

import { ChatState } from './typings';
import {
  selectChatroom,
  pushMessages,
  subscribedToChatroom,
  unsubscribedFromChatroom,
} from './actions';

const initialState: ChatState = { messages: [], loading: false };

export default createReducer<ChatState>(initialState, (chat) =>
  chat
    .addCase(selectChatroom, (_, action) => ({
      chatroomId: action.payload,
      messages: [],
      loading: true,
    }))
    .addCase(pushMessages, (state, action) => ({
      ...state,
      messages: [...state.messages, ...action.payload],
    }))
    .addCase(subscribedToChatroom, (state, action) => ({
      ...state,
      subscription: action.payload,
      loading: false,
    }))
    .addCase(unsubscribedFromChatroom, () => initialState),
);
