import styled from '@emotion/styled';

export const ShowCaseBackItems = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 3.3rem;
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
  @media (min-width: ${(props) => props?.theme?.breakpoints?.largeTablet}px) {
    margin-top: 3.3rem;
  }
`;

export const ShowCaseImagesText = styled.div`
  ${(props) => `
    color: ${props?.theme?.colors.dark[100]};
    font-size: ${props?.theme?.typography.large24};
    font-weight: ${props?.theme?.typography.bold};
    margin-bottom: 3rem;
    text-align: center;
    @media (min-width: ${props?.theme?.breakpoints?.largeTablet}px) {
      font-size: ${props?.theme?.typography.large48};
      margin-bottom: 3.3rem;
    }
  `}
`;

export const MountainImage = styled.img`
  margin-top: 3.3rem;
`;
