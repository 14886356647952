import { createReducer } from '@reduxjs/toolkit';
import { arrayToHash } from 'helpers/helperFunctions';
import { AppletContentful } from 'models/AppletContentful';
import { getApplets } from './actions';
import { AppletsState } from './typings';

const initialState: AppletsState = {
  applets: {},
};

export default createReducer<AppletsState>(initialState, (builder) =>
  builder.addCase(getApplets.fulfilled, (state, action) => ({
    applets: arrayToHash<AppletContentful>(
      action.payload,
      (applet) => applet.id,
      state.applets,
    ),
    lastUpdatedAt: new Date().toISOString(),
  })),
);
