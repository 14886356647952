import React from 'react';

import Modal from 'components/modal';
import {
  AddAppletButton,
  AddAppletModalContainer,
} from 'modules/profile/components';
import localizedStrings from 'localization';
import { QuestPortfolio } from 'models/QuestPortfolio';

type AddAppletModalProps = {
  open: boolean;
  quest: QuestPortfolio;
  closeModal: () => void;
  onAddFileClick: (quest: QuestPortfolio) => void;
  onAddRichTextClick: (quest: QuestPortfolio) => void;
};

const AddAppletModal = ({
  open,
  quest,
  closeModal,
  onAddFileClick,
  onAddRichTextClick,
}: AddAppletModalProps) => {
  return (
    <Modal open={open} closeModal={closeModal} width="516px">
      <AddAppletModalContainer>
        <AddAppletButton onClick={() => onAddFileClick(quest)}>
          {localizedStrings.uploadFile}
        </AddAppletButton>
        <AddAppletButton onClick={() => onAddRichTextClick(quest)}>
          {localizedStrings.uploadRichText}
        </AddAppletButton>
      </AddAppletModalContainer>
    </Modal>
  );
};

export default AddAppletModal;
