import React, { useCallback } from 'react';

import theme from 'utils/theme';

type SendIconProps = {
  width?: number | string;
  height?: number | string;
  color?: string;
  disabled?: boolean;
};

const SendIcon = ({
  width = '20px',
  height = '20px',
  color = theme.colors.blue2[100],
  disabled,
}: SendIconProps) => {
  const getIconColor = useCallback(
    () => (disabled ? theme?.colors.grey[90] : color),
    [disabled, color],
  );

  return (
    <svg width={width} height={height} viewBox="0 0 20 20">
      <path
        fill={getIconColor()}
        d="M19.2549401,11.2057244 L1.95413883,19.8520385 C0.788284055,20.4347956 -0.424638068,19.1846607 0.145168756,18.0441577 C0.145168756,18.0441577 2.28944183,13.7139142 2.87924183,12.5796638 C3.46904191,11.4454134 4.14422966,11.2490768 10.4141873,10.4387193 C10.646192,10.4087062 10.8361276,10.2336289 10.8361276,9.99977575 C10.8361276,9.76633941 10.646192,9.59126216 10.4141873,9.561249 C4.14422966,8.7508915 3.46904191,8.55455491 2.87924183,7.4203045 C2.28944183,6.286471 0.145168756,1.95581067 0.145168756,1.95581067 C-0.424638068,0.815724417 0.788284055,-0.434827233 1.95413883,0.147929834 L19.2549401,8.79466083 C20.2483533,9.29071291 20.2483533,10.7092554 19.2549401,11.2057244"></path>
    </svg>
  );
};

export default SendIcon;
