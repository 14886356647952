import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'redux/typings';

const selectProfileState = (state: RootState) => state.profile;

const selectAchievementsState = (state: RootState) =>
  state.profile.achievementsState;

const selectShowcaseState = (state: RootState) => state.profile.showcaseState;

export const selectUserTotalXp = createSelector(
  selectAchievementsState,
  (achievementsState) => achievementsState.totalXp,
);

export const selectUserTotalBp = createSelector(
  selectAchievementsState,
  (achievementsState) => achievementsState.totalBp,
);

export const selectAchievements = createSelector(
  selectAchievementsState,
  (achievementsState) => achievementsState.achievements,
);

export const selectPublishedShowcases = createSelector(
  selectShowcaseState,
  (showcase) => showcase.publishedShowcases,
);

export const selectGenericApplets = createSelector(
  selectShowcaseState,
  (showcase) => showcase.genericApplets,
);

export const selectUploadFileGenericApplet = createSelector(
  selectGenericApplets,
  (genericApplets) =>
    genericApplets.find((applet) => applet.type === 'generic-upload-file'),
);

export const selectRichTextGenericApplet = createSelector(
  selectGenericApplets,
  (genericApplets) =>
    genericApplets.find((applet) => applet.type === 'generic-rich-text'),
);

export const selectAchievementsLength = createSelector(
  selectAchievements,
  (achievementsState) => achievementsState?.length || 0,
);

export const selectProfileMenuSelected = createSelector(
  selectProfileState,
  (profileState) => profileState.profileMenuSelected,
);

export const selectPublishedShowcasesLength = createSelector(
  selectShowcaseState,
  (showcase) => showcase.publishedShowcases?.length || 0,
);

export const sortByQuestSelect = createSelector(
  selectProfileState,
  (profileState) => profileState.sortByQuest,
);
