import React from 'react';

import { InlineAppletText } from 'components/applets/components';
import useContentfulRenderer from 'hooks/useContentfulRenderer';
import { QuestApplet } from 'models/QuestApplet';
import { ChallengeApplet } from 'models/ChallengeApplet';

type InlineTextPreviewProps = {
  applet: QuestApplet | ChallengeApplet;
};

const InlineTextPreview = ({ applet }: InlineTextPreviewProps) => {
  const renderedPrompt = useContentfulRenderer(applet.uploadPrompt);
  return (
    <div>
      {renderedPrompt}
      <InlineAppletText> {applet.text}</InlineAppletText>
    </div>
  );
};

export default InlineTextPreview;
