import { IAppletFields } from 'models/contentful';
import {
  ShareAppletEmailPayload,
  ShareAppletModalInformation,
} from 'modules/applets/typings';

export const shareAppletInitialValues = (args?: ShareAppletModalInformation) =>
  ({
    appletContentfulId: args?.appletContentfulId || '',
    type: args?.type,
    richText:
      args?.type === 'rich-text-entry' ? args?.richText ?? '' : undefined,
    madlibAnswers:
      args?.type === 'madlib' ? args?.madlibAnswers ?? [] : undefined,
    receiver: '',
    subject: '',
    message: '',
    file:
      args?.type &&
      (
        [
          'upload-file',
          'upload-image',
          'upload-video',
        ] as IAppletFields['type'][]
      ).includes(args.type)
        ? args?.file
        : undefined,
  } as ShareAppletEmailPayload);
