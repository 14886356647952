import React, { useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  WeekProgressTimelineContainer,
  WeeksProgressContainer,
  WeekProgressTimelineTopRow,
  QuestTitle,
  Overview,
  RowElement,
  WeekTitle,
} from './components';
import WeekProgressIndicator from './week-progress-indicator';

import ArrowIcon from './ArrowIcon';
import localizedStrings from 'localization';
import { selectWeeksProgress } from 'modules/loading-screen/weeks/selectors';
import { selectActiveQuestTitle } from 'modules/loading-screen/quests/selectors';
import { selectIsLate } from 'modules/loading-screen/schedules/selectors';
import OnTimeIcon from 'components/icons/OnTimeIcon';
import { useHistory } from 'react-router';
import { OVERVIEW } from 'routes';
import useCurrentWeekInformation from 'hooks/useCurrentWeekInformation';
import { setWeekNumber } from '../../modules/overview/actions';
import useMinHoursPerWeek from 'hooks/useMinHoursPerWeek';

const WeekProgressTimeline = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const weeksProgress = useSelector(selectWeeksProgress);
  const questTitle = useSelector(selectActiveQuestTitle);
  const isLate = useSelector(selectIsLate);
  const minHoursPerWeek = useMinHoursPerWeek();
  const isModuleSchedule = minHoursPerWeek < 0 ? true : false;

  const [weekNumber, title] = useCurrentWeekInformation();

  const renderWeeksProgress = useMemo(
    () =>
      weeksProgress.map((weekProgress, index) => (
        <WeekProgressIndicator
          progress={weekProgress.progress}
          weekName={weekProgress.weekName}
          weekNumber={weekProgress.weekNumber}
          isCurrentWeek={weekProgress.weekNumber === weekNumber}
          isLast={index === weeksProgress.length - 1}
          key={index}
        />
      )),
    [weekNumber, weeksProgress],
  );

  const onClick = useCallback(() => {
    dispatch(setWeekNumber(undefined));
    history.push(OVERVIEW);
  }, [dispatch, history]);

  return (
    <WeekProgressTimelineContainer onClick={onClick}>
      <WeekProgressTimelineTopRow>
        <RowElement>
          <QuestTitle>{questTitle}</QuestTitle>
          <Overview>{localizedStrings.overview}</Overview>
          {!isLate ? <OnTimeIcon /> : null}
        </RowElement>
        <RowElement>
          <ArrowIcon />
        </RowElement>
      </WeekProgressTimelineTopRow>
      <WeeksProgressContainer>{renderWeeksProgress}</WeeksProgressContainer>
      {!isModuleSchedule ? <WeekTitle>{title}</WeekTitle> : null}
    </WeekProgressTimelineContainer>
  );
};

export default WeekProgressTimeline;
