import * as yup from 'yup';

import LocalizedStrings from 'localization';
import { shareByEmailSchema } from 'helpers/ValidationElements';
import { basicAppletValidation } from '../share-applet-modal/shareAppletValidationSchema';

export default yup
  .object()
  .shape({
    stepId: yup
      .string()
      .required(LocalizedStrings.shareAppletContentfulIdRequired),
    applets: yup.array().of(basicAppletValidation).required(),
  })
  .concat(shareByEmailSchema.required());
