import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

import localizedStrings from 'localization';
import {
  selectActiveCohortInformation,
  selectHasRunningPartner,
} from 'modules/relationships/selectors';
import {
  AddRelationshipButton,
  AddRelationshipButtonText,
  AddRelationshipModalContent,
} from 'modules/relationships/components';
import Modal from 'components/modal';
import { selectHasMentor } from '../../selectors';

export enum AddOptions {
  ADD_RUNNING_PARTNER,
  SWITCH_RUNNING_PARTNER,
  ADD_MENTOR,
}

type AddModalProps = {
  open: boolean;
  closeModal: () => void;
  onOptionClick: (option: AddOptions) => void;
};

const AddModal = ({ open, closeModal, onOptionClick }: AddModalProps) => {
  const userHasRunningPartner = useSelector(selectHasRunningPartner);
  const userHasMentor = useSelector(selectHasMentor);
  const activeCohortInformation = useSelector(selectActiveCohortInformation);

  const handleSwitchRunningPartner = useCallback(() => {
    onOptionClick(AddOptions.SWITCH_RUNNING_PARTNER);
  }, [onOptionClick]);

  const handleAddRunningPartner = useCallback(() => {
    onOptionClick(AddOptions.ADD_RUNNING_PARTNER);
  }, [onOptionClick]);

  const handleInviteMentor = useCallback(() => {
    onOptionClick(AddOptions.ADD_MENTOR);
  }, [onOptionClick]);

  return (
    <Modal
      open={open}
      closeModal={closeModal}
      width={'516px'}
      title={localizedStrings.invite}>
      <AddRelationshipModalContent>
        {userHasRunningPartner ? (
          <AddRelationshipButton onClick={handleSwitchRunningPartner}>
            <AddRelationshipButtonText>
              {localizedStrings.switchRunningPartner}
            </AddRelationshipButtonText>
          </AddRelationshipButton>
        ) : (
          <AddRelationshipButton onClick={handleAddRunningPartner}>
            <AddRelationshipButtonText>
              {localizedStrings.pairWithRunningPartner}
            </AddRelationshipButtonText>
          </AddRelationshipButton>
        )}
        {!userHasMentor && activeCohortInformation?.hasMentors ? (
          <AddRelationshipButton onClick={handleInviteMentor}>
            <AddRelationshipButtonText>
              {localizedStrings.addMentorTitle}
            </AddRelationshipButtonText>
          </AddRelationshipButton>
        ) : null}
      </AddRelationshipModalContent>
    </Modal>
  );
};

export default AddModal;
