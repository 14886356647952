import { createAsyncThunk, createAction } from '@reduxjs/toolkit';

import { getProfileVisibilityAchievements as getProfileVisibilityAchievementsService } from './services';

import {
  getRelationships as getRelationshipsService,
  invite as inviteService,
  changeRelationshipStatus as changeRelationshipStatusService,
  deleteInvo as deleteInvoService,
  getActiveCohortInformation as getActiveCohortInformationService,
} from './services';

const GET_RELATIONSHIPS = 'GET_RELATIONSHIPS';
export const getRelationships = createAsyncThunk(
  GET_RELATIONSHIPS,
  getRelationshipsService,
);

const INVITE = 'INVITE';
export const invite = createAsyncThunk(INVITE, inviteService);

const CHANGE_RELATIONSHIP_STATUS = 'CHANGE_RELATIONSHIP_STATUS';
export const changeRelationshipStatus = createAsyncThunk(
  CHANGE_RELATIONSHIP_STATUS,
  changeRelationshipStatusService,
);

const SELECT_RELATIONSHIP = 'SELECT_RELATIONSHIP';
export const selectRelationship = createAction<string>(SELECT_RELATIONSHIP);

const DELETE_INVO = 'DELETE_INVO';
export const deleteInvo = createAsyncThunk(DELETE_INVO, deleteInvoService);

const UPDATE_RELATIONSHIP_DATE = 'UPDATE_RELATIONSHIP_DATE';
export const updateRelationshipDate = createAction<string>(
  UPDATE_RELATIONSHIP_DATE,
);

const GET_ACTIVE_COHORT_INFORMATION = 'GET_ACTIVE_COHORT_INFORMATION';
export const getActiveCohortInformation = createAsyncThunk(
  GET_ACTIVE_COHORT_INFORMATION,
  getActiveCohortInformationService,
);

const GET_PROFILE_VISIBILITY_ACHIEVEMENTS =
  'GET_PROFILE_VISIBILITY_ACHIEVEMENTS';
export const getProfileVisibilityAchievements = createAsyncThunk(
  GET_PROFILE_VISIBILITY_ACHIEVEMENTS,
  getProfileVisibilityAchievementsService,
);
