import * as React from 'react';

type SpeakerIconProps = {
  width: number | string;
  height: number | string;
  color?: string;
};

const SpeakerIcon = ({ width, height, color = '#FFFF' }: SpeakerIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill={color}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14 3.22998V5.28998C16.89 6.14998 19 8.82998 19 12C19 15.17 16.89 17.84 14 18.7V20.77C18 19.86 21 16.28 21 12C21 7.71998 18 4.13998 14 3.22998ZM16.5 12C16.5 10.23 15.5 8.70998 14 7.96998V16C15.5 15.29 16.5 13.76 16.5 12ZM3 8.99998V15H7L12 20V3.99998L7 8.99998H3Z"
        fill={color}
      />
    </svg>
  );
};

export default SpeakerIcon;
