import React from 'react';

import { EmbeddedEntryInlineProps } from '.';
import MadlibInputCompleted from './madlib-input-content/MadlibInputCompleted';
import MadlibInputEditable from './madlib-input-content/MadlibInputEditable';

const MadlibInputContent = ({
  node,
  madlibAnswers,
  isMadlibEditMode,
  appletId,
  madlibAppletContentfulId,
  quizAppletContentfulId,
  quizContentfulId,
  quizSectionContentfulId,
  quizQuestionContentfulId,
}: EmbeddedEntryInlineProps) => {
  const inputId = node.data.target.sys.id;
  const inputType = node.data.target.fields.type;
  const options = node.data.target.fields.options;
  const placeholder = node.data.target.fields.placeholder;

  return madlibAnswers ? (
    <MadlibInputCompleted
      appletId={appletId}
      inputId={inputId}
      inputType={inputType}
      isMadlibEditMode={isMadlibEditMode}
      madlibAnswers={madlibAnswers}
      placeholder={placeholder}
    />
  ) : (
    <MadlibInputEditable
      appletId={appletId}
      inputId={inputId}
      inputType={inputType}
      options={options}
      isMadlibEditMode={isMadlibEditMode}
      placeholder={placeholder}
      madlibAppletContentfulId={madlibAppletContentfulId}
      quizAppletContentfulId={quizAppletContentfulId}
      quizContentfulId={quizContentfulId}
      quizSectionContentfulId={quizSectionContentfulId}
      quizQuestionContentfulId={quizQuestionContentfulId}
    />
  );
};

export default MadlibInputContent;
