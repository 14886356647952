import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { FeedbackMode } from 'models/FeedbackMode';
import {
  getUserAchievements as getUserAchievementsService,
  getUserShowcases as getUserShowcasesService,
  giveFeedback as giveFeedbackService,
  giveExternalFeedback as giveExternalFeedbackService,
} from './services';
import { Feedback } from 'models/Feedback';
import { FeedbackPayload } from './typings';

const SET_FEEDBACK_MODE = 'SET_FEEDBACK_MODE';
export const setFeedbackMode = createAction<FeedbackMode | undefined>(
  SET_FEEDBACK_MODE,
);

const GET_USER_ACHIEVEMENTS = 'GET_USER_ACHIEVEMENTS';
export const getUserAchievements = createAsyncThunk(
  GET_USER_ACHIEVEMENTS,
  getUserAchievementsService,
);

const GET_USER_SHOWCASES = 'GET_USER_SHOWCASES';
export const getUserShowcases = createAsyncThunk(
  GET_USER_SHOWCASES,
  getUserShowcasesService,
);

const SELECT_USER_CHALLENGE = 'SELECT_USER_CHALLENGE';
export const selectAchievement = createAction<string>(SELECT_USER_CHALLENGE);

const UNSELECT_USER_CHALLENGE = 'UNSELECT_USER_CHALLENGE';
export const unselectUserChallenge = createAction<void>(
  UNSELECT_USER_CHALLENGE,
);

const GIVE_FEEDBACK = 'GIVE_FEEDBACK';
export const giveFeedback = createAsyncThunk<Feedback, FeedbackPayload>(
  GIVE_FEEDBACK,
  giveFeedbackService,
);

const GIVE_EXTERNAL_FEEDBACK = 'GIVE_EXTERNAL_FEEDBACK';
export const giveExternalFeedback = createAsyncThunk(
  GIVE_EXTERNAL_FEEDBACK,
  giveExternalFeedbackService,
);

const REMOVE_USER_ACHIEVEMENTS = 'REMOVE_USER_ACHIEVEMENTS';
export const removeUserAchivements = createAction<void>(
  REMOVE_USER_ACHIEVEMENTS,
);
