import styled from '@emotion/styled';

type RadioButtonContainerProps = {
  disabled?: boolean;
};

export const RadioButtonContainer = styled.div<RadioButtonContainerProps>`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${(props) => props?.theme?.typography.large10} 0;
  cursor: ${(props) => (props.disabled ? 'cursor' : 'pointer')};
`;

export const IconContainer = styled.div`
  margin-right: ${(props) => props?.theme?.typography.large24};
  display: flex;
  align-items: center;
`;

export const LabelContainer = styled.div`
  font-size: ${(props) => props?.theme?.typography.large24};
  font-weight: ${(props) => props?.theme?.typography.semiBold};
  color: ${(props) => props?.theme?.colors.grey[100]};
`;
