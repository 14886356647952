import React, { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import localizedStrings from 'localization';
import {
  FieldErrorMessage,
  PublishShowcaseAppletsContainer,
  PublishShowcaseDescription,
  PublishShowcaseSelectAll,
} from 'modules/profile/components';
import { selectQuestPortfolioApplets } from 'modules/applets/selectors';
import AppletPreview from 'modules/profile/portfolio/quest-applets/AppletPreview';
import { SetFieldValueType, StepProps } from '../typings';
import { buildQuestAppletsList } from 'helpers/AppletHelpers';
import useHandleAppletSelect from 'hooks/useHandleAppletSelect';
import useIsAppletSelected from 'hooks/useIsAppletSelected';

const SelectArtifacts = ({
  values,
  setFieldValue,
  setNextButtonText,
}: StepProps) => {
  const questPortfolioApplets = useSelector(selectQuestPortfolioApplets);

  const applets = useMemo(
    () =>
      buildQuestAppletsList(
        questPortfolioApplets.filter(
          (applet) => applet.appletType !== 'inline-text',
        ),
      ),
    [questPortfolioApplets],
  );

  const selectedApplets = useMemo(() => values.applets, [values.applets]);

  const selectAll = useCallback(
    (setFieldValue: SetFieldValueType) => {
      const allIds = applets
        .map((app) =>
          app.appletType === 'group-applet'
            ? app.applets?.map((groupApp) => groupApp.id)
            : app.id,
        )
        .flat();
      setFieldValue('applets', allIds);
    },
    [applets],
  );

  const hasSelectedAll = useCallback(
    () =>
      selectedApplets.length ===
      applets
        .map((app) =>
          app.appletType === 'group-applet'
            ? app.applets?.map((groupApp) => groupApp.id)
            : app.id,
        )
        .flat().length,
    [applets, selectedApplets],
  );

  const deselectAll = useCallback((setFieldValue: SetFieldValueType) => {
    setFieldValue('applets', []);
  }, []);

  const handleSelectDeselect = useCallback(() => {
    if (hasSelectedAll()) {
      deselectAll(setFieldValue);
    } else {
      selectAll(setFieldValue);
    }
  }, [deselectAll, hasSelectedAll, selectAll, setFieldValue]);

  const handleAppletSelect = useHandleAppletSelect(
    setFieldValue,
    selectedApplets,
    'applets',
  );

  const isSelected = useIsAppletSelected(selectedApplets);

  useEffect(() => {
    if (selectedApplets.length >= 3) {
      setNextButtonText(localizedStrings.next);
    } else {
      setNextButtonText(localizedStrings.selectAtLeast3Artifacts);
    }
  }, [selectedApplets.length, setNextButtonText]);

  return (
    <>
      <PublishShowcaseDescription>
        {localizedStrings.selectShowcaseArtifacts}
      </PublishShowcaseDescription>
      {applets.length === 0 ? (
        <FieldErrorMessage>
          {localizedStrings.showcaseAppletError}
        </FieldErrorMessage>
      ) : (
        <>
          <PublishShowcaseSelectAll
            type="button"
            onClick={handleSelectDeselect}>
            {hasSelectedAll()
              ? localizedStrings.deselectAll
              : localizedStrings.selectAll}
          </PublishShowcaseSelectAll>
          <PublishShowcaseAppletsContainer>
            {applets.map((applet) => (
              <AppletPreview
                key={applet.id}
                applet={applet}
                onAppletClick={handleAppletSelect}
                showTitle={true}
                height="168px"
                width="178px"
                selected={isSelected(applet)}
              />
            ))}
          </PublishShowcaseAppletsContainer>
        </>
      )}
    </>
  );
};

export default SelectArtifacts;
