import styled from '@emotion/styled';

import ButtonPrimary from 'components/button-primary';

export const DialogConfirmPrompt = styled.div`
  font-size: ${(props) => props?.theme?.typography.large20};
  margin-bottom: 2rem;
`;

export const DialogConfirmButtons = styled.div`
  display: flex;
  justify-content: center;
`;

export const DialogNoButton = styled(ButtonPrimary)`
  background-color: ${(props) => props?.theme?.colors.grey[90]};
  width: 10rem;
  margin: 0 1rem 0 0;
`;

export const DialogYesButton = styled(ButtonPrimary)`
  width: 10rem;
  margin: 0;
`;
