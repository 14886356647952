import React from 'react';
import {
  DecisionTreeImagePreviewContainer,
  DecisionTreeImageTag,
  DecisionTreeImageDescription,
} from '../components';

type DecisionTreeImagePreviewProps = {
  url: string;
  description: string;
};

const DecisionTreeImagePreview = ({
  url,
  description,
}: DecisionTreeImagePreviewProps) => {
  return (
    <DecisionTreeImagePreviewContainer>
      <DecisionTreeImageTag src={url} alt={description} />
      <DecisionTreeImageDescription>{description}</DecisionTreeImageDescription>
    </DecisionTreeImagePreviewContainer>
  );
};

export default DecisionTreeImagePreview;
