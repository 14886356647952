import styled from '@emotion/styled';

export type DarkModeProps = {
  darkMode: boolean;
};

export const Heading1 = styled.div<DarkModeProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props?.theme?.colors.blue1[100]};
  font-size: ${(props) => props?.theme?.typography.large48};
  font-weight: ${(props) => props?.theme?.typography.bold};
  margin-top: ${(props) => props?.theme?.typography.large48};
  margin-bottom: ${(props) => props?.theme?.typography.large24};
  width: 100%;
`;

export const Heading2 = styled.div<DarkModeProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props?.theme?.colors.blue1[100]};
  font-size: ${(props) => props?.theme?.typography.large32};
  font-weight: ${(props) => props?.theme?.typography.bold};
  margin-bottom: ${(props) => props?.theme?.typography.large24};
  margin-top: ${(props) => props?.theme?.typography.large24};
  width: 100%;
`;

export const Heading3 = styled.div<DarkModeProps>`
  display: flex;
  align-items: center;
  color: ${(props) => props?.theme?.colors.blue1[100]};
  font-size: ${(props) => props?.theme?.typography.large24};
  font-weight: ${(props) => props?.theme?.typography.bold};
  margin-bottom: ${(props) => props?.theme?.typography.large24};
  margin-top: ${(props) => props?.theme?.typography.large24};
  width: 100%;
`;

export const Heading4 = styled.div<DarkModeProps>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: ${(props) =>
    props.darkMode
      ? props?.theme?.colors.white[100]
      : props?.theme?.colors.dark[100]};
  font-size: ${(props) => props?.theme?.typography.large18};
  font-weight: ${(props) => props?.theme?.typography.semiBold};
  margin-bottom: ${(props) => props?.theme?.typography.large24};
  margin-top: ${(props) => props?.theme?.typography.large24};
  text-decoration-line: underline;
  width: 100%;
`;

export const Heading5 = styled.div<DarkModeProps>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: ${(props) =>
    props.darkMode
      ? props?.theme?.colors.white[100]
      : props?.theme?.colors.dark[100]};
  font-size: ${(props) => props?.theme?.typography.large16};
  font-weight: ${(props) => props?.theme?.typography.semiBold};
  margin-bottom: ${(props) => props?.theme?.typography.large24};
  margin-top: ${(props) => props?.theme?.typography.large24};
  width: 100%;
`;

export const Heading6 = styled.div<DarkModeProps>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: ${(props) =>
    props.darkMode
      ? props?.theme?.colors.white[100]
      : props?.theme?.colors.dark[100]};
  font-size: ${(props) => props?.theme?.typography.large13};
  font-weight: ${(props) => props?.theme?.typography.semiBold};
  margin-bottom: ${(props) => props?.theme?.typography.large24};
  margin-top: ${(props) => props?.theme?.typography.large24};
  width: 100%;
`;

export const Paragraph = styled.div<DarkModeProps>`
  color: ${(props) => (props.darkMode ? 'white' : 'black')};
  font-size: ${(props) => props?.theme?.typography.large18};
  line-height: ${(props) => props?.theme?.typography.large24};
  text-align: left;
  flex-shrink: 1;
  margin-bottom: 0.7rem;
`;

export const Quote = styled.div<DarkModeProps>`
  color: ${(props) => (props.darkMode ? 'white' : 'black')};
  font-style: italic;
  font-size: ${(props) => props?.theme?.typography.large24};
  line-height: ${(props) => props?.theme?.typography.large32};
  margin-bottom: 1rem;
`;

export const Hr = styled.div`
  background-color: ${(props) => props?.theme?.colors.grey[100]};
  height: 2px;
  width: 100%;
  margin-bottom: 1rem;
`;

export const Bold = styled.span`
  font-weight: ${(props) => props?.theme?.typography.semiBold};
`;

export const Italic = styled.span`
  font-style: italic;
`;

export const Underline = styled.span`
  text-decoration-line: underline;
`;

export const ErrorMessage = styled.div`
  font-size: ${(props) => props?.theme?.typography.large14};
  color: red;
`;

export const Image = styled.img`
  max-height: 27rem;
  max-width: 100%;
  align-self: flex-start;
  margin-bottom: 1rem;
  border-radius: 0.6rem;
`;

export const ImageCaption = styled.div`
  color: ${(props) => props?.theme?.colors.grey[90]};
  font-size: ${(props) => props?.theme?.typography.large16};
  line-height: ${(props) => props?.theme?.typography.large24};
  margin-bottom: 2rem;
`;

export const ImageDescription = styled.span`
  font-style: normal;
`;

export const ImageTitle = styled.span`
  font-style: italic;
`;

export const AudioTitle = styled.div`
  font-size: ${(props) => props?.theme?.typography.large16};
  line-height: ${(props) => props?.theme?.typography.large24};
  font-weight: ${(props) => props?.theme?.typography.semiBold};
  color: ${(props) => props?.theme?.colors.blue2[100]};
  margin-bottom: 0.5rem;
`;

export const TextBesideImage = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${(props) => props?.theme?.breakpoints?.largeTablet}px) {
    flex-direction: row;
  }
`;
export const ImageContainer = styled.div`
  max-width: 100%;

  @media (min-width: ${(props) => props?.theme?.breakpoints?.largeTablet}px) {
    max-width: 50%;
    margin-right: 1rem;
  }
`;
export const TextContainer = styled.div`
  flex: 1;
`;

export type LinkProps = {
  color?: string;
  hoverColor?: string;
  visitedColor?: string;
  focusColor?: string;
  bold?: boolean;
};

export const LinkText = styled.span<LinkProps>`
  cursor: pointer;
  color: ${(props) => props?.color || props?.theme?.colors?.link};
  text-decoration-line: underline;
  font-weight: ${(props) => (props.bold ? 'bold' : 'normal')};
  &:hover {
    color: ${(props) => props?.hoverColor || props?.theme?.colors?.linkHover};,
  }
  &:visited {
    color: ${(props) =>
      props?.visitedColor || props?.theme?.colors?.linkHover};,
  }
  &:focus {
    color: ${(props) => props?.focusColor || props?.theme?.colors?.linkFocus};,
  }
  &:active {
    color: ${(props) => props?.focusColor || props?.theme?.colors?.linkFocus};,
  }
`;

export const QuizSectionTitle = styled.div`
  color: ${(props) => props?.theme?.colors.blue2[100]};
  font-weight: ${(props) => props?.theme?.typography.semiBold};
  margin-bottom: 0.5rem;
  margin-top: 1rem;
`;

export const QuizSectionDescription = styled.div`
  margin-bottom: 1rem;
`;

export const QuizSectionSeparator = styled.div`
  background-color: ${(props) => props?.theme?.colors.grey[80]};
  height: 1px;
  width: 25rem;
  max-width: 100%;
  margin-bottom: 1rem;
`;

export const Link = styled.a<LinkProps>`
  color: ${(props) => props?.color || props?.theme?.colors.link};
  text-decoration-line: underline;
  font-weight: ${(props) => (props.bold ? 'bold' : 'normal')};
  &:hover {
    color: ${(props) => props?.hoverColor || props?.theme?.colors?.linkHover};,
  }
  &:visited {
    color: ${(props) =>
      props?.visitedColor || props?.theme?.colors?.linkHover};,
  }
  &:focus {
    color: ${(props) => props?.focusColor || props?.theme?.colors?.linkFocus};,
  }
  &:active {
    color: ${(props) => props?.focusColor || props?.theme?.colors?.linkFocus};,
  }
`;

export const MadlibSelectionOption = styled.div<{ disabled?: boolean }>`
  font-size: ${(props) => props?.theme?.typography.large18};
  line-height: ${(props) => props?.theme?.typography.large24};\
  color: ${(props) =>
    props?.disabled ? props?.theme?.colors.grey[90] : 'black'};
`;
