import React, { useMemo } from 'react';

import BlueRibbonMedalIcon from './BlueRibbonMedalIcon';
import RedRibbonMedalIcon from './RedRibbonMedalIcon';
import GreenRibbonMedalIcon from './GreenRibbonMedalIcon';

type MedalProps = {
  width?: number;
  height?: number;
  ribbonColor?: 'blue' | 'red' | 'green';
};

const Medal = ({
  width = 70,
  height = 70,
  ribbonColor = 'blue',
}: MedalProps) => {
  const selectedMedal = useMemo(() => {
    switch (ribbonColor) {
      case 'blue':
        return <BlueRibbonMedalIcon width={width} height={height} />;
      case 'red':
        return <RedRibbonMedalIcon width={width} height={height} />;
      case 'green':
        return <GreenRibbonMedalIcon width={width} height={height} />;
      default:
        return <BlueRibbonMedalIcon width={width} height={height} />;
    }
  }, [width, height, ribbonColor]);
  return selectedMedal;
};

export default Medal;
