import React, { useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/typings';

import Modal from 'components/modal';
import PdfPreview from 'components/pdf-preview';
import { Image } from 'components/contentful-renders/components';
import { Video } from './components';
import {
  selectModalAssetHyperlinkInformation,
  selectModalIsAssetHyperlinkModalOpen,
} from '../selectors';
import { closeAssetHyperlinkModal } from '../actions';

const AssetHyperLinkModal = () => {
  const dispatch = useDispatch<AppDispatch>();
  const isModalOpen = useSelector(selectModalIsAssetHyperlinkModalOpen);
  const modalInfo = useSelector(selectModalAssetHyperlinkInformation);

  const [isImage, isVideo, isPdf, name] = useMemo(() => {
    const isImage = modalInfo?.isImage;
    const isVideo = modalInfo?.isVideo;
    const isPdf = modalInfo?.isPdf;
    const fileTitle = modalInfo?.title || '';

    return [isImage, isVideo, isPdf, fileTitle];
  }, [modalInfo]);

  const [url, description] = useMemo(() => {
    const desc: string = modalInfo?.description || '';
    const assetUrl: string = modalInfo?.url || '';

    return [assetUrl, desc];
  }, [modalInfo]);

  const closeModal = useCallback(() => {
    dispatch(closeAssetHyperlinkModal());
  }, [dispatch]);

  return (
    <>
      <Modal open={isModalOpen} closeModal={closeModal}>
        {isImage ? <Image src={url} alt={description} /> : null}
        {isVideo ? <Video src={url} controls autoPlay /> : null}
        {isPdf ? <PdfPreview file={{ uri: url, type: 'pdf', name }} /> : null}
      </Modal>
    </>
  );
};

export default AssetHyperLinkModal;
