import {
  combineReducers,
  configureStore,
  getDefaultMiddleware,
  Action,
} from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import localForage from 'localforage';

import { logout } from 'modules/auth/actions';
import auth from 'modules/auth/reducer';
import status from 'modules/status/reducer';
import error from 'modules/error/reducer';
import appOnboardings from 'modules/onboarding/reducer';
import appletUpload from 'modules/applet-upload/reducer';
import files from 'modules/files/reducer';
import profile from 'modules/profile/reducer';
import showcase from 'modules/showcase/reducer';
import relationships from 'modules/relationships/reducer';
import chat from 'modules/relationships/home/chat/reducer';
import schedules from 'modules/loading-screen/schedules/reducer';
import scheduleCommit from 'modules/loading-screen/schedule-commit/reducer';
import relatedUserProfile from 'modules/relationships/profile/reducer';
import notifications from 'modules/avatar-options-menu/reducer';
import help from 'modules/settings/components/get-help/reducer';
import externalFeedback from 'modules/external-feedback/reducer';
import playlist from 'modules/home/playlist/reducer';
import tags from 'modules/tags/reducer';
import quests from 'modules/loading-screen/quests/reducer';
import questsContentful from 'modules/loading-screen/contentful/quests/reducer';
import questsOnboardings from 'modules/loading-screen/contentful/quests-onboardings/reducer';
import questsOffboardings from 'modules/loading-screen/contentful/quests-offboardings/reducer';
import weeks from 'modules/loading-screen/weeks/reducer';
import weeksContentful from 'modules/loading-screen/contentful/weeks/reducer';
import weeksOnboardings from 'modules/loading-screen/contentful/weeks-onboardings/reducer';
import contents from 'modules/loading-screen/contents/reducer';
import contentsContentful from 'modules/loading-screen/contentful/contents/reducer';
import stepsContentful from 'modules/loading-screen/contentful/steps/reducer';
import navigationInformation from 'modules/loading-screen/navigation-information/reducer';
import applets from 'modules/applets/reducer';
import appletsContentful from 'modules/loading-screen/contentful/applets/reducer';
import settings from 'modules/settings/reducer';
import overview from 'modules/overview/reducer';
import modal from 'modules/modal/reducer';

const appReducer = combineReducers({
  auth,
  status,
  error,
  appOnboardings,
  appletUpload,
  files,
  profile,
  showcase,
  relationships,
  relatedUserProfile,
  chat,
  schedules,
  scheduleCommit,
  notifications,
  help,
  externalFeedback,
  playlist,
  quests,
  weeks,
  navigationInformation,
  contents,
  applets,
  settings,
  overview,
  modal,
  tags,
  contentful: combineReducers({
    quests: questsContentful,
    questsOnboardings,
    questsOffboardings,
    weeks: weeksContentful,
    weeksOnboardings,
    steps: stepsContentful,
    contents: contentsContentful,
    applets: appletsContentful,
  }),
});

export const rootReducer = (state: any, action: Action<string>) => {
  if (action.type === logout.type) {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

const persistConfig = {
  key: 'root',
  storage: localForage,
  whitelist: ['auth', 'contentful', 'overview'],
};

const store = configureStore({
  reducer: persistReducer(persistConfig, rootReducer),
  middleware: getDefaultMiddleware({
    serializableCheck: false,
    immutableCheck: false,
  }),
  devTools: {
    stateSanitizer: (state) => ({ ...state, contentful: '' }),
  },
});

export const persistor = persistStore(store);
export default store;
