import { useMemo } from 'react';
import Rating from 'react-rating';
import Modal from 'components/modal';
import localizedStrings from 'localization';
import {
  ReviewsModalChallengeContainer,
  ReviewsModalChallengeImage,
  ReviewsModalChallengeTitleAndRating,
  ReviewsModalChallengeTitle,
  ReviewsModalSubTitle,
  ReviewsModalList,
  ReflectAnswers,
} from 'modules/profile/components';
import StarFilled from './StartFilled';
import StarEmpty from './StarEmpty';
import ChallengeFeedbacks from './ChallengeFeedbacks';
import { Achievement } from 'modules/profile/typings';
import { ContentContentful } from 'models/ContentContentful';
import { calculateFeedbacksAverage } from 'helpers/helperFunctions';
import { useSelector } from 'react-redux';
import { useAppletByFilterQuest } from 'hooks/useAppletByFilterQuest';
import { selectAvailableApplets } from 'modules/applets/selectors';
import { QuestItems } from 'modules/profile/portfolio/quest-applets/QuestItems';
import { QuestApplet } from 'models/QuestApplet';
import ButtonPrimary from 'components/button-primary';
import { useHistory } from 'react-router';
import { CHALLENGE } from 'routes';
import { selectContentsByContentfulId } from 'modules/loading-screen/contents/selectors';

type ReviewsModalProps = {
  achievement?: Achievement;
  content?: ContentContentful;
  isOpen: boolean;
  closeModal: () => void;
  openAppletPreviewModal?: (applet: QuestApplet) => void;
};

const ReviewsModal = ({
  achievement,
  content,
  isOpen,
  closeModal,
  openAppletPreviewModal,
}: ReviewsModalProps) => {
  const history = useHistory()
  const questPortfolios = useSelector(selectAvailableApplets);
  const { appletsByChallenge } = useAppletByFilterQuest(questPortfolios);
  const dataApplets = achievement?.appletIds ? appletsByChallenge(achievement.appletIds) : []
  const contents = useSelector(selectContentsByContentfulId(achievement?.contentContentfulId))

  const renderModalContent = useMemo(() => {
    if (achievement) {
      const feedbacks = achievement.feedbacks ?? [];
      const feedbackAverage = calculateFeedbacksAverage(feedbacks);
      return (
        <>
          <ReviewsModalChallengeContainer>
            <ReviewsModalChallengeImage
              src={content?.imageUrl}
              alt={content?.title}
            />
            <ReviewsModalChallengeTitleAndRating>
              <ReviewsModalChallengeTitle>
                {content?.title}
              </ReviewsModalChallengeTitle>
              <Rating
                readonly={true}
                initialRating={feedbackAverage}
                fullSymbol={<StarFilled size="26px" />}
                emptySymbol={<StarEmpty size="26px" />}
              />
            </ReviewsModalChallengeTitleAndRating>
          </ReviewsModalChallengeContainer>
          <ReviewsModalSubTitle>
            {localizedStrings.reflect}
          </ReviewsModalSubTitle>
          <ReflectAnswers>{achievement.reflectAnswers}</ReflectAnswers>
          <ReviewsModalSubTitle>
            {localizedStrings.reviews}
          </ReviewsModalSubTitle>
          <ReviewsModalList>
            <ChallengeFeedbacks feedbacks={feedbacks} />
          </ReviewsModalList>
          {contents?.id && <ButtonPrimary onClick={() => history.push(`${CHALLENGE}/${contents.id}/0`)}>Return to Challenge</ButtonPrimary >}
        </>
      );
    } else {
      return undefined;
    }
  }, [achievement, content?.imageUrl, content?.title, history, contents?.id]);

  return (
    <Modal width="516px" open={isOpen} closeModal={closeModal}>
      {renderModalContent}
      {dataApplets && <QuestItems applets={dataApplets} onAppletClick={openAppletPreviewModal} />}
    </Modal>
  );
};

export default ReviewsModal;
