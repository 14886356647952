import { createReducer } from '@reduxjs/toolkit';
import { arrayToHash } from 'helpers/helperFunctions';
import { WeekOnboarding } from 'models/WeekOnboarding';
import { getWeeksOnboardings } from './actions';
import { WeeksOnboardingsState } from './typings';

const initialState: WeeksOnboardingsState = {
  weeksOnboardings: {},
};

export default createReducer<WeeksOnboardingsState>(initialState, (builder) =>
  builder.addCase(getWeeksOnboardings.fulfilled, (state, action) => ({
    weeksOnboardings: arrayToHash<WeekOnboarding>(
      action.payload,
      (weekOnboarding) => weekOnboarding.id,
      state.weeksOnboardings,
    ),
    lastUpdatedAt: new Date().toISOString(),
  })),
);
