import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';

import {
  Container,
  ContainerRight,
  ContainerLeft,
  Logo,
  FormContainer,
  FormTitle,
  ButtonSecondary,
  AuthErrorMessage,
  FormSubtitle,
  FormMessage,
  FormLink,
  FormContent,
} from 'modules/auth/components';
import { registerSchema } from 'modules/auth/validationSchemas';
import { AppDispatch, RootState } from 'redux/typings';
import logo from 'assets/images/Logo.webp';
import { AUTH } from 'routes';
import { AUTH_LOGIN, AUTH_EMAIL_VERIFICATION } from 'modules/auth/routes';
import localizedStrings from 'localization';
import * as StatusSelectors from 'modules/status/selectors';
import { getLegalDoc, signup } from 'modules/auth/actions';
import {
  selectPrivacyPolicy,
  selectTermsOfService,
  userIdSelector,
  verifiedSelector,
} from 'modules/auth/selectors';
import { SignupForm } from 'modules/auth/typings';
import Modal from 'components/modal';
import ButtonPrimary from 'components/button-primary';
import useContentfulRenderer from 'hooks/useContentfulRenderer';
import InputField from 'components/input-field';
import useErrorMessage from 'hooks/useErrorMessage';
import useCohortConfirmationDate from 'hooks/useCohortConfirmationDate';
import Dialog from 'components/dialog';
import { getCohortStartingDate } from 'modules/settings/actions';
import { UserLocalDate } from 'models/UserLocalDate';
import useActionStatus from 'hooks/useActionStatus';

type ModalState = {
  open?: boolean;
  document?: ReactNode;
};

const Register = () => {
  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();

  const [modalState, setModalState] = useState<ModalState>({});
  const [errorMessage, setErrorMessage] = useErrorMessage();

  const isLoading = useSelector((state: RootState) =>
    StatusSelectors.isPendingSelector(state, signup.typePrefix),
  );
  const verified = useSelector<RootState, boolean>(verifiedSelector);
  const userId = useSelector<RootState, string>(userIdSelector);
  const privacyPolicy = useSelector(selectPrivacyPolicy);
  const privacyPolicyContent = useContentfulRenderer(privacyPolicy);
  const termsOfService = useSelector(selectTermsOfService);
  const termsOfServiceContent = useContentfulRenderer(termsOfService);
  const [isPending] = useActionStatus(getCohortStartingDate);

  const onFinalSubmit = useCallback(
    (valuesToSubmit: SignupForm, userLocalDate: UserLocalDate) => {
      dispatch(signup({ ...valuesToSubmit, userLocalDate }));
    },
    [dispatch],
  );

  const [
    dateDialogOpen,
    ,
    setHasInitiallySubmitted,
    setValuesToSubmit,
    handleDateDialog,
  ] = useCohortConfirmationDate<SignupForm>(onFinalSubmit);

  const handleSubmit = useCallback(
    (values: SignupForm) => {
      setErrorMessage('');
      dispatch(getCohortStartingDate(values.code ?? '')).catch(() =>
        setHasInitiallySubmitted(false),
      );
      setHasInitiallySubmitted(true);
      setValuesToSubmit(values);
    },
    [dispatch, setErrorMessage, setHasInitiallySubmitted, setValuesToSubmit],
  );

  const goToLogin = useCallback(() => {
    history.push(AUTH + AUTH_LOGIN);
  }, [history]);

  const showTermsOfService = useCallback(() => {
    setModalState({ open: true, document: termsOfServiceContent });
  }, [termsOfServiceContent]);

  const showPrivacyPolicy = useCallback(() => {
    setModalState({ open: true, document: privacyPolicyContent });
  }, [privacyPolicyContent]);

  const hideModal = useCallback(() => {
    setModalState({});
  }, []);

  useEffect(() => {
    if (userId) {
      if (verified) {
        goToLogin();
      } else {
        history.push(AUTH + AUTH_EMAIL_VERIFICATION);
      }
    }
  }, [userId, verified, history, goToLogin]);

  useEffect(() => {
    if (!privacyPolicy) {
      dispatch(getLegalDoc('privacy-policy'));
    }
    if (!termsOfService) {
      dispatch(getLegalDoc('terms-of-service'));
    }
  }, [privacyPolicy, termsOfService, dispatch]);

  return (
    <Container>
      <Modal
        open={modalState.open ?? false}
        closeModal={hideModal}
        acceptButtonText={localizedStrings.signupAcceptTerms}
        onAcceptButtonClick={hideModal}>
        {modalState.document}
      </Modal>
      <Dialog
        onNoClick={handleDateDialog(false)}
        onYesClick={handleDateDialog(true)}
        open={dateDialogOpen}
        title={localizedStrings.startOnSameWeek}
        prompt={localizedStrings.startOnSameWeekPrompt}
        yesText={localizedStrings.startLate}
        noText={localizedStrings.reschedule}
      />
      <ContainerLeft>
        <Logo src={logo}></Logo>
      </ContainerLeft>
      <ContainerRight>
        <FormContainer>
          <FormTitle>{localizedStrings.signupHeader}</FormTitle>
          <FormSubtitle>{localizedStrings.signupSubtitle}</FormSubtitle>
          <Formik
            initialValues={{
              displayName: '',
              email: '',
              password: '',
              code: '',
            }}
            onSubmit={handleSubmit}
            validationSchema={registerSchema}>
            <FormContent>
              <InputField
                name="displayName"
                placeholder={localizedStrings.namePlaceholder}
              />
              <InputField
                type="email"
                name="email"
                placeholder={localizedStrings.emailPlaceholder}
              />
              <InputField
                type="password"
                name="password"
                placeholder={localizedStrings.passwordPlaceholder}
              />
              <InputField
                name="code"
                placeholder={localizedStrings.signupCodePlaceholder}
              />
              {errorMessage && (
                <AuthErrorMessage>{errorMessage}</AuthErrorMessage>
              )}
              <FormMessage>
                {localizedStrings.signupAcknowledgement1 + ' '}
                <FormLink onClick={showTermsOfService}>
                  {localizedStrings.termsOfService}
                </FormLink>
                {' ' + localizedStrings.signupAcknowledgement3 + ' '}
                <FormLink onClick={showPrivacyPolicy}>
                  {localizedStrings.privacyPolicy}
                </FormLink>
              </FormMessage>
              <ButtonPrimary type="submit" isLoading={isPending || isLoading}>
                {localizedStrings.signupRegisterBtn}
              </ButtonPrimary>
              <ButtonSecondary type="button" onClick={goToLogin}>
                {localizedStrings.alreadyHaveAnAccount}
              </ButtonSecondary>
            </FormContent>
          </Formik>
        </FormContainer>
      </ContainerRight>
    </Container>
  );
};

export default Register;
