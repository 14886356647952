import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import format from 'date-fns/format';
import isSameDay from 'date-fns/isSameDay';

import { ChatMessage } from './typings';
import { formatDateRelative } from 'helpers/dateHelpers';
import { selectUserId } from 'modules/auth/selectors';
import { ChatDate, MessageHour, MessageText } from '../../components';

type RelationshipChatMessageProps = {
  message: ChatMessage;
  previousMessage: ChatMessage | undefined;
};

const RelationshipChatMessage = ({
  message,
  previousMessage,
}: RelationshipChatMessageProps) => {
  const userId = useSelector(selectUserId);

  const renderDate = useMemo(() => {
    let ret = false;
    if (!previousMessage) {
      ret = true;
    } else if (!isSameDay(previousMessage.createdAt, message.createdAt)) {
      ret = true;
    }
    return ret;
  }, [message, previousMessage]);

  const right = useMemo(() => message.senderId === userId, [message, userId]);

  return (
    <>
      {renderDate && (
        <ChatDate>{formatDateRelative(message.createdAt)}</ChatDate>
      )}
      <MessageText right={right}>
        {message.text}
        <MessageHour>{format(message.createdAt, 'p')}</MessageHour>
      </MessageText>
    </>
  );
};

export default RelationshipChatMessage;
