import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectQuestsContentful } from 'modules/loading-screen/contentful/quests/selectors';
import { selectActiveQuest } from 'modules/loading-screen/quests/selectors';
import { MIN_HOURS_PER_WEEK } from 'modules/loading-screen/schedule-commit/constants';

const useMinHoursPerWeek = () => {
  const activeQuest = useSelector(selectActiveQuest);
  const questsContentful = useSelector(selectQuestsContentful);

  const minHoursPerWeek = useMemo(() => {
    if (activeQuest) {
      const questContentful = questsContentful[activeQuest.contentfulId];
      if (questContentful) {
        return questContentful.hoursPerWeek;
      }
    }
  }, [activeQuest, questsContentful]);

  return minHoursPerWeek || MIN_HOURS_PER_WEEK;
};

export default useMinHoursPerWeek;
