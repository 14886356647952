import React, { useCallback, useState } from 'react';
import { NavLink } from 'react-router-dom';

import Logo_yellow from 'assets/images/Logo_yellow.webp';
import Line from 'assets/images/Line.webp';
import {
  NavBarContainer,
  NavBarLeft,
  NavBarLine,
  NavBarLogo,
  NavBarName,
  NavBarRight,
} from './components';
import { HOME, PROFILE, RELATIONSHIPS, SETTINGS } from 'routes';
import { useSelector } from 'react-redux';
import {
  selectDisplayName,
  selectProfilePictureUrl,
} from 'modules/auth/selectors';
import Avatar from 'components/avatar';
import AvatarOptionsMenu from '../avatar-options-menu';
import localizedStrings from 'localization';
import theme from 'utils/theme';

type RecentActivityState = {
  open: boolean;
  xPosition?: number;
  yPosition?: number;
};

type NavBarProps = {
  hideControls?: boolean;
};

const NavBar = ({ hideControls }: NavBarProps) => {
  const [avatarOptionsMenu, setAvatarOptionsMenu] =
    useState<RecentActivityState>({ open: false });
  const userName = useSelector(selectDisplayName);
  const userPictureUrl = useSelector(selectProfilePictureUrl);

  const handleOpenAvatarOptionsMenu = useCallback((evt) => {
    setAvatarOptionsMenu({
      open: true,
      xPosition: evt.target.offsetLeft + evt.target.clientWidth / 2,
      yPosition: evt.target.offsetTop + evt.target.clientHeight,
    });
  }, []);

  const handleCloseRecentActivity = useCallback(() => {
    setAvatarOptionsMenu({ open: false });
  }, []);

  return (
    <NavBarContainer>
      <NavBarLeft>
        <NavLink to={HOME}>
          <NavBarLogo src={Logo_yellow} alt="mynga" />
        </NavLink>
        {!hideControls && (
          <>
            <NavBarLine src={Line} />
            <NavLink
              to={HOME}
              activeStyle={{
                fontWeight: 'bold',
                color: theme.colors.blue3[80],
              }}>
              <NavBarName>{localizedStrings.journey}</NavBarName>
            </NavLink>
            <NavLink
              to={RELATIONSHIPS}
              activeStyle={{
                fontWeight: 'bold',
                color: theme.colors.blue3[80],
              }}>
              <NavBarName>{localizedStrings.relationships}</NavBarName>
            </NavLink>
            <NavLink
              to={PROFILE}
              activeStyle={{
                fontWeight: 'bold',
                color: theme.colors.blue3[80],
              }}>
              <NavBarName>{localizedStrings.profile}</NavBarName>
            </NavLink>
            <NavLink
              to={SETTINGS}
              activeStyle={{
                fontWeight: 'bold',
                color: theme.colors.blue3[80],
              }}>
              <NavBarName>{localizedStrings.settings}</NavBarName>
            </NavLink>
          </>
        )}
      </NavBarLeft>

      {!hideControls && (
        <NavBarRight>
          <NavLink to={PROFILE}>
            <NavBarName>{userName}</NavBarName>
          </NavLink>
          <div onClick={handleOpenAvatarOptionsMenu}>
            <Avatar
              displayName={userName ?? ''}
              uri={userPictureUrl}
              size={50}
            />
          </div>
          <AvatarOptionsMenu
            open={avatarOptionsMenu.open}
            close={handleCloseRecentActivity}
            xPosition={avatarOptionsMenu.xPosition}
            yPosition={avatarOptionsMenu.yPosition}
          />
        </NavBarRight>
      )}

    </NavBarContainer>
  );
};

export default NavBar;
