import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch } from 'redux/typings';
import { getHelp } from './actions';
import localizedStrings from 'localization';
import { selectHelpQuestions } from './selectors';
import HelpCollapsible from './HelpCollapsible';
import {
  ContentPanel,
  FormSubtitle,
  FormTitleHeader,
} from 'modules/settings/components';

const GetHelp = () => {
  const dispatch = useDispatch<AppDispatch>();
  const helpQuestions = useSelector(selectHelpQuestions);

  useEffect(() => {
    if (helpQuestions.length === 0) {
      dispatch(getHelp());
    }
  }, [helpQuestions, dispatch]);

  return (
    <ContentPanel>
      <FormTitleHeader>{localizedStrings.getHelpTitle}</FormTitleHeader>
      <FormSubtitle>{localizedStrings.getHelpSubtitle}</FormSubtitle>
      {helpQuestions.map((helpQuestion) => (
        <HelpCollapsible helpQuestion={helpQuestion} key={helpQuestion.id} />
      ))}
    </ContentPanel>
  );
};

export default GetHelp;
