import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactSortable } from 'react-sortablejs';
import { Document } from '@contentful/rich-text-types';
import { IRankOptions } from 'models/contentful';
import RankOption from './RankOption';
import { RankAppletContainer } from '../components';
import { AppDispatch } from 'redux/typings';
import { uploadResponse } from 'modules/applet-upload/actions';
import useExistingApplet from 'hooks/useExistingApplet';
import useIsAppletDisabled from 'hooks/useIsAppletDisabled';
import { selectChallengeAppletByContentfulId } from 'modules/loading-screen/contents/selectors';
import { RankOption as RankOptionModel } from 'models/RankOption';
import UserOptionsModal from './UserOptionsModal';
import localizedStrings from 'localization';
import useContentfulRenderer from 'hooks/useContentfulRenderer';
import { selectAppletById } from 'modules/applet-upload/selectors';
import {
  mapOptions,
  mapOptionsContentful,
  sortOptions,
} from 'helpers/AppletHelpers';
import { ButtonContainer, SaveButton } from 'common/CommonComponents';

type RankAppletProps = {
  appletId: string;
  optionsContentful?: IRankOptions[];
  options?: RankOptionModel[];
  uploadPrompt?: Document;
  forcedDisabled?: boolean;
};

export type SortableItemType = {
  id: string;
  contentfulId?: string;
  content?: Document;
  userInput?: string;
};

const RankApplet = ({
  appletId,
  optionsContentful,
  options,
  forcedDisabled,
  uploadPrompt,
}: RankAppletProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const id = useExistingApplet(appletId);
  const isAppletDisabled = useIsAppletDisabled();
  const [hasInitiallySetAnswers, setHasInitiallySetAnswers] = useState(false);
  const [userOptions, setUserOptions] = useState<SortableItemType[]>([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const renderedUploadPrompt = useContentfulRenderer(uploadPrompt);
  const [sortableItems, setSortableItems] = useState<SortableItemType[]>(() => {
    if (optionsContentful) {
      const sortableOptions = optionsContentful.map(mapOptionsContentful);
      return [...sortableOptions]
        .sort(sortOptions)
        .map(mapOptions(optionsContentful));
    } else {
      return userOptions;
    }
  });

  const challengeApplet = useSelector(
    selectChallengeAppletByContentfulId(appletId),
  );
  const modifiedApplet = useSelector(selectAppletById(appletId));

  const disabled = useMemo(() => isAppletDisabled, [isAppletDisabled]);

  const handleSetList = useCallback(
    (newState: SortableItemType[]) => {
      setSortableItems(newState);
      const modified = hasInitiallySetAnswers ? true : undefined;
      dispatch(
        uploadResponse({
          applet: {
            id,
            appletId,
            appletType: 'rank',
            options: newState.map((item, index) => ({
              id: item.id,
              contentfulId: item.contentfulId,
              order: index,
              userInput: item.userInput,
            })),
            untouched: false,
          },
          modified,
        }),
      );
    },
    [dispatch, id, appletId, hasInitiallySetAnswers],
  );

  const openModal = useCallback(() => {
    setIsEditModalOpen(true);
  }, []);

  useEffect(() => {
    if (!hasInitiallySetAnswers) {
      let items: SortableItemType[] = [];

      if (options) {
        items = [...options]
          .sort(sortOptions)
          .map(mapOptions(optionsContentful));
      } else if (
        challengeApplet?.options &&
        challengeApplet.options.length > 0
      ) {
        items = [...challengeApplet.options]
          .sort(sortOptions)
          .map(mapOptions(optionsContentful));
      } else if (modifiedApplet?.options && modifiedApplet.options.length > 0) {
        items = [...modifiedApplet.options]
          .sort(sortOptions)
          .map(mapOptions(optionsContentful));
      } else if (optionsContentful) {
        const sortableOptions = optionsContentful.map(mapOptionsContentful);
        items = [...sortableOptions]
          .sort(sortOptions)
          .map(mapOptions(optionsContentful));
      }
      if (
        [options, challengeApplet, modifiedApplet, optionsContentful].some(
          (obj) => obj,
        )
      ) {
        setHasInitiallySetAnswers(true);
        setSortableItems(items);
        const untouched = modifiedApplet?.untouched ?? true;
        dispatch(
          uploadResponse({
            applet: {
              id,
              appletId,
              appletType: 'rank',
              options: items.map((item, index) => ({
                id: item.id,
                contentfulId: item.contentfulId,
                order: index,
                userInput: item.userInput,
              })),
              untouched,
            },
          }),
        );
        setUserOptions(items.filter((item) => item.userInput));
      }
    }
  }, [
    challengeApplet,
    dispatch,
    hasInitiallySetAnswers,
    modifiedApplet,
    options,
    optionsContentful,
    id,
    appletId,
  ]);

  return (
    <RankAppletContainer>
      {renderedUploadPrompt && !optionsContentful && !disabled
        ? renderedUploadPrompt
        : null}

      {disabled ? (
        <ReactSortable
          list={sortableItems}
          setList={handleSetList}
          disabled={true}>
          {sortableItems.map((item, index) => (
            <RankOption
              key={item.id}
              content={item.content}
              index={index}
              userInput={item.userInput}
            />
          ))}
        </ReactSortable>
      ) : null}

      {!disabled ? (
        <ReactSortable
          list={sortableItems}
          setList={handleSetList}
          disabled={false}>
          {sortableItems.map((item, index) => (
            <RankOption
              key={item.id}
              content={item.content}
              index={index}
              userInput={item.userInput}
            />
          ))}
        </ReactSortable>
      ) : null}
      {!optionsContentful && !disabled && (
        <ButtonContainer>
          <SaveButton onClick={openModal}>
            {localizedStrings.addOptionsButton}
          </SaveButton>
        </ButtonContainer>
      )}
      <UserOptionsModal
        isEditModalOpen={isEditModalOpen}
        setIsEditModalOpen={setIsEditModalOpen}
        userOptions={userOptions}
        setUserOptions={setUserOptions}
        onListUpdate={handleSetList}
      />
    </RankAppletContainer>
  );
};

export default RankApplet;
