import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import localizedStrings from 'localization';
import { VisibilityPermissionsToggle } from './visibility-permission-toggle';
import { VisibilityAchievementsPayload } from 'modules/auth/typings';
import {
  FormSubtitle,
  InnerContentPanel,
  FormTitleHeader,
  GiveFeedbackTitle,
  GiveFeedbackPrompt,
  DeleteAccountContent,
  SectionPanel,
  SectionContentPanel,
  DeleteAccountButton,
  LineBreak,
} from '../components';
import Modal from 'components/modal';
import { AcceptModalButton, ModalContainer } from 'components/modal/components';
import { Row } from 'modules/profile/components';
import { logout } from 'modules/auth/actions';
import { deleteUser } from 'modules/auth/services';
import { selectUserId } from 'modules/auth/selectors';
import { toast } from 'react-toastify';
import { BgContainer, TxtMessage } from 'common/CommonComponents';
import logo from 'assets/images/Logo.webp';
import LoadingSpinner from 'components/loading-spinner';

const VisibilityPermissions = () => {
  const dispatch = useDispatch();
  const userId = useSelector(selectUserId);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const runningPartner: VisibilityAchievementsPayload = {
    visibilityAchievements: 'RUNNING PARTNER',
  };
  const fellowTraveller: VisibilityAchievementsPayload = {
    visibilityAchievements: 'FELLOW TRAVELER',
  };
  const openModal = useCallback(() => setModalOpen(true), []);
  const closeModal = useCallback(() => setModalOpen(false), []);

  const errorAccountDelete = () => {
    setLoadingDelete(false);
    toast(localizedStrings.errorAccountDeleted, { type: 'error' });
  };

  const handleDeleteAccount = async () => {
    setLoadingDelete(true);
    try {
      if (userId) {
        await deleteUser(userId).then(() => {
          toast(localizedStrings.accountDeleted, { type: 'success' });
          dispatch(logout());
        });
      } else {
        errorAccountDelete();
      }
    } catch (error) {
      errorAccountDelete();
    }
  };

  const ButtonsCustom = () => {
    return (
      <Row>
        <AcceptModalButton onClick={closeModal}>
          {localizedStrings.no}
        </AcceptModalButton>
        <AcceptModalButton backgroundColor="red" onClick={handleDeleteAccount}>
          {localizedStrings.deleteAccount}
        </AcceptModalButton>
      </Row>
    );
  };

  if (loadingDelete) {
    return (
      <ModalContainer visible>
        <BgContainer>
          <img src={logo} alt="brand logo" />
          <TxtMessage>{localizedStrings.loadingDelete}</TxtMessage>
          <LoadingSpinner size={64} />
        </BgContainer>
      </ModalContainer>
    );
  }

  return (
    <>
      <Modal
        acceptButtonCustom={<ButtonsCustom />}
        open={modalOpen}
        closeModal={closeModal}
        title={localizedStrings.deleteAccount}
        width="516px">
        {localizedStrings.sureDeleteAccount}
      </Modal>
      <SectionContentPanel>
        <SectionPanel>
          <FormTitleHeader>{localizedStrings.managePrivacy}</FormTitleHeader>
          <GiveFeedbackTitle>
            {localizedStrings.visibilityPermissions}
          </GiveFeedbackTitle>

          <InnerContentPanel>
            <VisibilityPermissionsToggle
              visibilityPermissionForUpdate={runningPartner}
              title={
                <FormSubtitle>{localizedStrings.runningPartner}</FormSubtitle>
              }
            />
            <VisibilityPermissionsToggle
              visibilityPermissionForUpdate={fellowTraveller}
              title={
                <FormSubtitle>{localizedStrings.fellowTravelers}</FormSubtitle>
              }
            />
          </InnerContentPanel>
        </SectionPanel>
        <LineBreak />
        <SectionPanel>
          <DeleteAccountContent>
            <GiveFeedbackTitle>
              {localizedStrings.deleteAccount}
            </GiveFeedbackTitle>
            <GiveFeedbackPrompt>
              {localizedStrings.messageDeleteAccount}
            </GiveFeedbackPrompt>
            <GiveFeedbackPrompt>
              {localizedStrings.sureDeleteAccount}
            </GiveFeedbackPrompt>

            <DeleteAccountButton onClick={openModal} backgroundColor="red">
              {localizedStrings.deleteAccount}
            </DeleteAccountButton>
          </DeleteAccountContent>
        </SectionPanel>
      </SectionContentPanel>
    </>
  );
};

export default VisibilityPermissions;
