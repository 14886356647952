import React from 'react';

type AddIconProps = {
  disabled?: boolean;
};

const AddIcon = ({ disabled }: AddIconProps) => {
  return (
    <svg
      opacity={disabled ? 0.3 : 1}
      width="32px"
      height="32px"
      viewBox="0 0 32 32">
      <title>FB0211CE-9226-4FCA-8368-EC1E500F6B9D</title>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd">
        <g id="Relationships" transform="translate(-913.000000, -135.000000)">
          <g id="Icons/32/Add" transform="translate(913.000000, 135.000000)">
            <circle
              id="Oval"
              fill="#F57F17"
              fillRule="nonzero"
              cx="16"
              cy="16"
              r="16"></circle>
            <path
              d="M16.5,9 C16.9142136,9 17.25,9.33578644 17.25,9.75 L17.25,15.75 L23.25,15.75 C23.6642136,15.75 24,16.0857864 24,16.5 C24,16.9142136 23.6642136,17.25 23.25,17.25 L17.25,17.25 L17.25,23.25 C17.25,23.6642136 16.9142136,24 16.5,24 C16.0857864,24 15.75,23.6642136 15.75,23.25 L15.75,17.25 L9.75,17.25 C9.33578644,17.25 9,16.9142136 9,16.5 C9,16.0857864 9.33578644,15.75 9.75,15.75 L15.75,15.749 L15.75,9.75 C15.75,9.33578644 16.0857864,9 16.5,9 Z"
              id="Combined-Shape"
              fill="#FFFFFF"></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default AddIcon;
