import React from 'react';
import { Row } from './components';
import NavBar from 'modules/navbar';
import { MainContainer, PageBackground } from 'components/components';
import { ProfileSectionDashboard } from './dashboard/profile-section-dashboard';
import { ProfileSectionSelected } from './dashboard/profile-section-selected';
import useLoadAdditionalContent from 'hooks/useLoadAdditionalContent';
import useLoadLastContent from 'hooks/use-load-content/useLoadLastContent';

const Profile = () => {
  useLoadLastContent();
  useLoadAdditionalContent();

  return (
    <>
      <NavBar />
      <PageBackground>
        <MainContainer>
          <Row>
            <ProfileSectionDashboard />
            <ProfileSectionSelected />
          </Row>
        </MainContainer>
      </PageBackground>
    </>
  );
};

export default Profile;
