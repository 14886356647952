import { createReducer } from '@reduxjs/toolkit';

import {
  getQuestApplets,
  getRestQuestApplets,
  uploadGenericFileApplet,
  uploadGenericRichText,
} from './actions';
import { QuestAppletsState } from './typings';

const initialState: QuestAppletsState = {
  list: [],
  appletsActiveQuestFilled: false,
  appletsRestQuestFilled: false
};

export default createReducer<QuestAppletsState>(initialState, (builder) =>
  builder
    .addCase(getQuestApplets.rejected, (state) => ({
      ...state,
      appletsActiveQuestFilled: false,
      appletsRestQuestFilled: false
    }))
    .addCase(getQuestApplets.fulfilled, (state, action) => ({
      ...state,
      list: action.payload,
      appletsActiveQuestFilled: true,
      appletsRestQuestFilled: false
    }))
    .addCase(getRestQuestApplets.rejected, (state) => ({
      ...state,
      appletsRestQuestFilled: false
    }))
    .addCase(getRestQuestApplets.fulfilled, (state, action) => ({
      ...state,
      list: [...state.list, ...action.payload],
      appletsRestQuestFilled: true
    }))
    .addCase(uploadGenericFileApplet.fulfilled, (state, action) => ({
      ...state,
      list: state.list.map((quest) => {
        if (quest.id === action.meta.arg.questId) {
          return {
            ...quest,
            applets: [...quest.applets, action.payload],
          };
        } else {
          return quest;
        }
      }),
    }))
    .addCase(uploadGenericRichText.fulfilled, (state, action) => ({
      ...state,
      list: state.list.map((quest) => {
        if (quest.id === action.meta.arg.questId) {
          return {
            ...quest,
            applets: [...quest.applets, action.payload],
          };
        } else {
          return quest;
        }
      }),
    })),
);
