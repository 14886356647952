import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt, useHistory } from 'react-router';
import { Location } from 'history';

import { selectAppletsModified } from 'modules/applet-upload/selectors';
import Dialog from 'components/dialog';
import localizedStrings from 'localization';
import { resetState } from 'modules/applet-upload/actions';
import { selectSelectedStepIsCelebrate } from '../../loading-screen/contents/selectors';

const NavigationWarning = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const [nextLocation, setNextLocation] = useState<Location | undefined>();
  const isCelebrate = useSelector(selectSelectedStepIsCelebrate);

  const appletsModified = useSelector(selectAppletsModified);

  const onPromptMessage = useCallback(
    (nextLocation: Location): boolean => {
      if (appletsModified && !confirmedNavigation) {
        setIsDialogOpen(true);
        setNextLocation(nextLocation);
        return false;
      }

      return true;
    },
    [appletsModified, confirmedNavigation],
  );

  const onDialogButtonClick = useCallback(
    (yes: boolean) => () => {
      if (yes) {
        dispatch(resetState());
        setConfirmedNavigation(true);
      }
      setIsDialogOpen(false);
    },
    [dispatch],
  );

  useEffect(() => {
    if (confirmedNavigation && nextLocation) {
      setConfirmedNavigation(false);
      setNextLocation(undefined);
      history.push(nextLocation.pathname);
    }
  }, [
    history,
    confirmedNavigation,
    nextLocation,
    isCelebrate,
    onDialogButtonClick,
    isDialogOpen,
  ]);

  return (
    <>
      <Prompt
        when={appletsModified && !isCelebrate}
        message={onPromptMessage}
      />
      <Dialog
        open={isDialogOpen && !isCelebrate}
        title={localizedStrings.areYouSure}
        prompt={localizedStrings.loseChanges}
        onNoClick={onDialogButtonClick(false)}
        onYesClick={onDialogButtonClick(true)}
      />
    </>
  );
};

export default NavigationWarning;
