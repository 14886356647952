import { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { AppDispatch } from 'redux/typings';
import useActionStatus from './useActionStatus';
import { selectLoadingFirstFinished } from 'modules/loading-screen/quests/selectors';
import { getQuests } from 'modules/loading-screen/contentful/quests/actions';
import { getQuestsOnboardings } from 'modules/loading-screen/contentful/quests-onboardings/actions';
import { getQuestsOffboardings } from 'modules/loading-screen/contentful/quests-offboardings/actions';
import { getWeeks } from 'modules/loading-screen/contentful/weeks/actions';
import { getWeeksOnboardings } from 'modules/loading-screen/contentful/weeks-onboardings/actions';
import { selectQuestsContentfulLastUpdatedAt } from 'modules/loading-screen/contentful/quests/selectors';
import { selectQuestsOnboardingsLastUpdatedAt } from 'modules/loading-screen/contentful/quests-onboardings/selectors';
import { selectQuestsOffboardingsLastUpdatedAt } from 'modules/loading-screen/contentful/quests-offboardings/selectors';
import { selectWeeksContentfulLastUpdatedAt } from 'modules/loading-screen/contentful/weeks/selectors';
import { selectWeeksOnboardingsLastUpdatedAt } from 'modules/loading-screen/contentful/weeks-onboardings/selectors';

const useLoadContentfulFirstContent = () => {
  const dispatch = useDispatch<AppDispatch>();
  const loadingFinished = useSelector(selectLoadingFirstFinished);

  const questsLastUpdatedAt = useSelector(selectQuestsContentfulLastUpdatedAt);
  const questOnboardingsLastUpdatedAt = useSelector(selectQuestsOnboardingsLastUpdatedAt);
  const questOffboardingsLastUpdatedAt = useSelector(selectQuestsOffboardingsLastUpdatedAt);
  const weeksLastUpdatedAt = useSelector(selectWeeksContentfulLastUpdatedAt);
  const weekOnboardingsLastUpdatedAt = useSelector(selectWeeksOnboardingsLastUpdatedAt);

  const [, getQuestsWasFulfilled] = useActionStatus(getQuests);
  const [, getQuestsOnboardingsWasFulfilled] = useActionStatus(getQuestsOnboardings);
  const [, getQuestsOffboardingsWasFulfilled] = useActionStatus(getQuestsOffboardings);
  const [, getWeeksWasFulfilled] = useActionStatus(getWeeks);
  const [, getWeeksOnboardingsWasFulfilled] = useActionStatus(getWeeksOnboardings);

  useEffect(() => {
    if (!loadingFinished) {
      dispatch(getQuests(questsLastUpdatedAt));
      dispatch(getQuestsOnboardings(questOnboardingsLastUpdatedAt));
      dispatch(getQuestsOffboardings(questOffboardingsLastUpdatedAt));
      dispatch(getWeeks(weeksLastUpdatedAt));
      dispatch(getWeeksOnboardings(weekOnboardingsLastUpdatedAt));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hasLoadedFirstContent = useMemo(() => {
    return (
      getQuestsWasFulfilled &&
      getQuestsOnboardingsWasFulfilled &&
      getQuestsOffboardingsWasFulfilled &&
      getWeeksWasFulfilled &&
      getWeeksOnboardingsWasFulfilled
    );
  }, [
    getQuestsWasFulfilled,
    getQuestsOnboardingsWasFulfilled,
    getQuestsOffboardingsWasFulfilled,
    getWeeksWasFulfilled,
    getWeeksOnboardingsWasFulfilled
  ]);

  return [hasLoadedFirstContent];
};

export default useLoadContentfulFirstContent;
