import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import StepButton from './step-button';
import { Content } from 'models/Content';
import { StepListContainer } from './components';
import { useHistory, useParams } from 'react-router';
import { CHALLENGE } from 'routes';
import { ChallengeRouteParams } from '..';
import { selectSelectedContentTotalSteps } from 'modules/loading-screen/contents/selectors';
import { selectSelectedStepIndex } from 'modules/loading-screen/contents/selectors';

type StepListProps = {
  challenge: Content;
};

const StepList = ({ challenge }: StepListProps) => {
  const history = useHistory();
  const { id } = useParams<ChallengeRouteParams>();

  const completedSteps = challenge.completedSteps;
  const isChallengeCompleted = challenge.completed;
  const selectedContentTotalSteps = useSelector(
    selectSelectedContentTotalSteps,
  );
  const selectedStepIndex = useSelector(selectSelectedStepIndex);

  const goToStep = useCallback(
    (stepNumber: number) => () => {
      history.push(`${CHALLENGE}/${id}/${stepNumber}`);
    },
    [history, id],
  );

  const stepArray = useMemo(() => {
    if (selectedContentTotalSteps > 0) {
      return Array.from(Array(selectedContentTotalSteps + 1).keys());
    }
    return [];
  }, [selectedContentTotalSteps]);

  const isStepCompleted = useCallback(
    (stepNumber: number) =>
      stepNumber < selectedContentTotalSteps
        ? completedSteps
          ? completedSteps.some((completedStep) => completedStep === stepNumber)
          : false
        : isChallengeCompleted,
    [selectedContentTotalSteps, completedSteps, isChallengeCompleted],
  );

  return (
    <StepListContainer>
      {stepArray.map((step) => (
        <StepButton
          key={step}
          completed={isStepCompleted(step)}
          currentStep={step === selectedStepIndex}
          onStepClick={goToStep(step)}
          isLast={step === stepArray.length - 1}
        />
      ))}
    </StepListContainer>
  );
};

export default StepList;
