import { createAction } from '@reduxjs/toolkit';
import {
  ShareAppletModalInformation,
  ShareStepModalInformation,
} from 'modules/applets/typings';

import { OpenAssetHyperlinkModalPayload } from './typings';
import { IReadingFields } from '../../models/contentful';

export const OPEN_READING_MODAL = 'OPEN_READING_MODAL';
export const openReadingModal =
  createAction<IReadingFields>(OPEN_READING_MODAL);

export const CLOSE_READING_MODAL = 'CLOSE_READING_MODAL';
export const closeReadingModal = createAction(CLOSE_READING_MODAL);

export const OPEN_ARTIFACT_MODAL = 'OPEN_ARTIFACT_MODAL';
export const openArtifactModal = createAction<string>(OPEN_ARTIFACT_MODAL);

export const CLOSE_ARTIFACT_MODAL = 'CLOSE_ARTIFACT_MODAL';
export const closeArtifactModal = createAction(CLOSE_ARTIFACT_MODAL);

export const OPEN_ARTIFACT_CONFIRM_MODAL = 'OPEN_ARTIFACT_CONFIRM_MODAL';
export const openArtifactConfirmModal = createAction(
  OPEN_ARTIFACT_CONFIRM_MODAL,
);

export const CLOSE_ARTIFACT_CONFIRM_MODAL = 'CLOSE_ARTIFACT_CONFIRM_MODAL';
export const closeArtifactConfirmModal = createAction(
  CLOSE_ARTIFACT_CONFIRM_MODAL,
);

export const OPEN_ASSET_HYPERLINK_MODAL = 'OPEN_ASSET_HYPERLINK_MODAL';
export const openAssetHyperlinkModal =
  createAction<OpenAssetHyperlinkModalPayload>(OPEN_ASSET_HYPERLINK_MODAL);

export const CLOSE_ASSET_HYPERLINK_MODAL = 'CLOSE_ASSET_HYPERLINK_MODAL';
export const closeAssetHyperlinkModal = createAction(
  CLOSE_ASSET_HYPERLINK_MODAL,
);

export const OPEN_SHARE_APPLET_MODAL = 'OPEN_SHARE_APPLET_MODAL';
export const openShareAppletModal = createAction<ShareAppletModalInformation>(
  OPEN_SHARE_APPLET_MODAL,
);

export const CLOSE_SHARE_APPLET_MODAL = 'CLOSE_SHARE_APPLET_MODAL';
export const closeShareAppletModal = createAction(CLOSE_SHARE_APPLET_MODAL);

const OPEN_SHARE_STEP_MODAL = 'OPEN_SHARE_STEP_MODAL';
export const openShareStepModal = createAction<ShareStepModalInformation>(
  OPEN_SHARE_STEP_MODAL,
);

const CLOSE_SHARE_STEP_MODAL = 'CLOSE_SHARE_STEP_MODAL';
export const closeShareStepModal = createAction(CLOSE_SHARE_STEP_MODAL);

const OPEN_CELEBRATE_MODAL = 'OPEN_CELEBRATE_MODAL';
export const openCelebrateModal = createAction(OPEN_CELEBRATE_MODAL);

const CLOSE_CELEBRATE_MODAL = 'CLOSE_CELEBRATE_MODAL';
export const closeCelebrateModal = createAction(CLOSE_CELEBRATE_MODAL);
