import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'redux/typings';

const selectQuestState = (state: RootState) => state.contentful.quests;

export const selectQuestsContentful = createSelector(
  selectQuestState,
  (questsState) => questsState.quests,
);

export const selectQuestsContentfulLastUpdatedAt = createSelector(
  selectQuestState,
  (questsState) => questsState.lastUpdatedAt,
);
