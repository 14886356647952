import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { PROFILE, RELATIONSHIPS } from 'routes';
import RelationshipsHome from './home';
import RelationshipProfile from './profile';
import useLoadAdditionalContent from 'hooks/useLoadAdditionalContent';
import useLoadLastContent from 'hooks/use-load-content/useLoadLastContent';

const Relationships = () => {
  useLoadLastContent();
  useLoadAdditionalContent();

  return (
    <Switch>
      <Route exact path={RELATIONSHIPS} component={RelationshipsHome} />
      <Route
        exact
        path={RELATIONSHIPS + '/:id' + PROFILE + '/:achievementId?'}
        component={RelationshipProfile}
      />
    </Switch>
  );
};

export default Relationships;
