import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectWeeksProgress } from 'modules/loading-screen/weeks/selectors';
import WeekProgressIndicator from 'components/week-progress-timeline/week-progress-indicator';
import {
  WeeklyTabInnerContainer,
  WeeksContainer,
  WeeksProgressContainer,
} from '../components';
import WeeklyTabChallenges from './WeeklyTabChallenges';
import FilterSection from '../FilterSection';
import useCurrentWeekInformation from 'hooks/useCurrentWeekInformation';

const WeeklyTab = () => {
  const weeksProgress = useSelector(selectWeeksProgress);
  const [weekNumber] = useCurrentWeekInformation();

  const renderWeeksProgress = useMemo(
    () =>
      weeksProgress.map((weekProgress, index) => (
        <WeekProgressIndicator
          progress={weekProgress.progress}
          weekName={weekProgress.weekName}
          weekNumber={weekProgress.weekNumber}
          isCurrentWeek={weekProgress.weekNumber === weekNumber}
          isLast={index === weeksProgress.length - 1}
          key={index}
        />
      )),
    [weeksProgress, weekNumber],
  );

  return (
    <>
      <WeeklyTabInnerContainer>
        <WeeksContainer>
          <WeeksProgressContainer>{renderWeeksProgress}</WeeksProgressContainer>
        </WeeksContainer>
        <WeeklyTabChallenges />
        <FilterSection />
      </WeeklyTabInnerContainer>
    </>
  );
};

export default WeeklyTab;
