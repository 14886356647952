import React, { useCallback } from 'react';

import { AppletPreviewModalContent } from '../../components';
import Modal from 'components/modal';
import { QuestApplet } from 'models/QuestApplet';
import AppletPreviewSwitch from '../../../../common/applet-preview-switch/index';

type AppletPreviewModalProps = {
  open: boolean;
  applet: QuestApplet;
  closeModal: () => void;
};

const AppletPreviewModal = ({
  open,
  applet,
  closeModal,
}: AppletPreviewModalProps) => {
  const renderPreview = useCallback(() => {
    if (applet) {
      return <AppletPreviewSwitch applet={applet} />;
    }
  }, [applet]);

  return (
    <Modal open={open} closeModal={closeModal} width="auto">
      <AppletPreviewModalContent>{renderPreview()}</AppletPreviewModalContent>
    </Modal>
  );
};

export default AppletPreviewModal;
