export const databaseToRichText = (content: string) => {
  content = translateOL(content);
  content = translateUL(content);
  content = translateIndents(content);
  content = translateTextAlign(content);
  content = content.replaceAll('<b>', '<strong>');
  content = content.replaceAll('</b>', '</strong>');
  content = content.replaceAll('<i>', '<em>');
  content = content.replaceAll('</i>', '</em>');
  return content;
};

function translateOL(content: string) {
  const orderedLists = content.match(/<div><ol>.*?<\/ol><\/div>/g);
  orderedLists?.forEach((ol) => {
    let translatedOl = ol.replaceAll(
      '<li>',
      '<li data-list="ordered"><span class="ql-ui" contenteditable="false"></span>',
    );
    translatedOl = translatedOl.slice(5, -6);
    content = content.replace(ol, translatedOl);
  });
  return content;
}

function translateUL(content: string) {
  const unorderedLists = content.match(/<div><ul>.*?<\/ul><\/div>/g);
  unorderedLists?.forEach((ul) => {
    let translatedUl = ul.replaceAll(
      '<li>',
      '<li data-list="bullet"><span class="ql-ui" contenteditable="false"></span>',
    );
    translatedUl = translatedUl.replaceAll('<ul>', '<ol>');
    translatedUl = translatedUl.replaceAll('</ul>', '</ol>');
    translatedUl = translatedUl.slice(5, -6);
    content = content.replace(ul, translatedUl);
  });
  return content;
}

function translateIndents(content: string) {
  const indents = content.match(
    /(<blockquote style="margin: 0 0 0 40px; border: none; padding: 0px;">)+<div>/g,
  );
  indents?.forEach((i) => {
    const indentNumber = i.match(/blockquote/g)?.length;
    const translatedIndent = `<p class="ql-indent-${indentNumber}">`;
    content = content.replace(i, translatedIndent);
  });
  content = content.replaceAll(/<\/div>(<\/blockquote>)+/g, '</p>');
  return content;
}

function translateTextAlign(content: string) {
  content = content.replaceAll(
    '<div style="text-align: left;">',
    '<p class="ql-align-left">',
  );
  content = content.replaceAll(
    '<div style="text-align: right;">',
    '<p class="ql-align-right">',
  );
  content = content.replaceAll(
    '<div style="text-align: center;">',
    '<p class="ql-align-center">',
  );
  return content;
}

export const RichTextToDatabase = (content: string) => {
  content = translateOLtoDatabase(content);
  content = translateULtoDatabase(content);
  content = translateIndentsToDatabase(content);
  content = translateTextAlignToDatabase(content);
  content = content.replaceAll('<strong>', '<b>');
  content = content.replaceAll('</strong>', '</b>');
  content = content.replaceAll('<em>', '<i>');
  content = content.replaceAll('</em>', '</i>');
  return content;
};

function translateOLtoDatabase(content: string) {
  const orderedLists = content.match(/<ol><li data-list="ordered">.*?<\/ol>/g);
  orderedLists?.forEach((ol) => {
    let translatedOl = ol.replaceAll(
      '<li data-list="ordered"><span class="ql-ui" contenteditable="false"></span>',
      '<li>',
    );
    translatedOl = `<p>${translatedOl}</p>`;
    content = content.replace(ol, translatedOl);
  });
  return content;
}

function translateULtoDatabase(content: string) {
  const unorderedLists = content.match(/<ol><li data-list="bullet">.*?<\/ol>/g);
  unorderedLists?.forEach((ul) => {
    let translatedUl = ul.replaceAll(
      '<li data-list="bullet"><span class="ql-ui" contenteditable="false"></span>',
      '<li>',
    );
    translatedUl = translatedUl.replaceAll('<ol>', '<ul>');
    translatedUl = translatedUl.replaceAll('</ol>', '</ul>');
    translatedUl = `<p>${translatedUl}</p>`;
    content = content.replace(ul, translatedUl);
  });
  return content;
}

function translateIndentsToDatabase(content: string) {
  const indents = content.matchAll(
    /<p class="ql-indent-(?<indent>\d)">(?<content>.*?)<\/p>/g,
  );
  for (let indent of indents) {
    const blockquote =
      '<blockquote style="margin: 0 0 0 40px; border: none; padding: 0px;">'.repeat(
        parseInt(indent[1]),
      ) +
      '<p>' +
      indent[2] +
      '</p>' +
      '</blockquote>'.repeat(parseInt(indent[1]));
    content = content.replace(indent[0], blockquote);
  }
  return content;
}

function translateTextAlignToDatabase(content: string) {
  content = content.replaceAll(
    '<p class="ql-align-left">',
    '<div style="text-align: left">',
  );
  content = content.replaceAll(
    '<p class="ql-align-right">',
    '<div style="text-align: right;">',
  );
  content = content.replaceAll(
    '<p class="ql-align-center">',
    '<div style="text-align: center;">',
  );
  return content;
}
