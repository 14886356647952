import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import localizedStrings from 'localization';
import { ContentSection, SettingsContainer } from './components';
import NavBar from 'modules/navbar';
import {
  selectPrivacyPolicy,
  selectTermsOfService,
} from 'modules/auth/selectors';
import { AppDispatch } from 'redux/typings';
import { getLegalDoc } from 'modules/auth/actions';
import GiveFeedback from './components/GiveFeedback';
import {
  SETTINGS,
  SETTINGS_CHANGE_PASSWORD,
  SETTINGS_GET_HELP,
  SETTINGS_GIVE_FEEDBACK,
  SETTINGS_PRIVACY_POLICY,
  SETTINGS_TERMS_OF_SERVICE,
  SETTINGS_VISIBILITY_PERMISSIONS,
} from 'routes';
import GetHelp from './components/get-help';
import LegalDocRender from './components/LegalDocRender';
import ChangePassword from './components/ChangePassword';
import VisibilityPermissions from './components/VisibilityPermissions';
import { VerticalNavSettingsContent } from 'components/vertical-navbar/VerticalNavSettingsContent';
import useLoadAdditionalContent from 'hooks/useLoadAdditionalContent';
import useLoadLastContent from 'hooks/use-load-content/useLoadLastContent';
import SignOutModal from './components/SignOutModal';

type SettingsTabNames =
  | 'get_help'
  | 'visibility_permissions'
  | 'give_us_feedback'
  | 'terms_of_service'
  | 'privacy_policy'
  | 'change_password'
  | 'logout';

type SettingsTabType = {
  name: SettingsTabNames;
  description: string;
};

const settingsTabs: SettingsTabType[] = [
  { name: 'get_help', description: localizedStrings.getHelp },
  {
    name: 'visibility_permissions',
    description: localizedStrings.managePrivacy,
  },
  { name: 'give_us_feedback', description: localizedStrings.sendFeedback },
  { name: 'terms_of_service', description: localizedStrings.termsOfService },
  { name: 'privacy_policy', description: localizedStrings.privacyPolicy },
  { name: 'change_password', description: localizedStrings.changePassword },
  { name: 'logout', description: localizedStrings.signOut },
];

const Settings = () => {
  const [openLogoutModal, setOpenLogoutModal] = useState(false)
  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();
  const location = useLocation();
  useLoadLastContent();
  useLoadAdditionalContent();

  const privacyPolicy = useSelector(selectPrivacyPolicy);
  const termsOfService = useSelector(selectTermsOfService);

  const selectedTab = useMemo(() => {
    const path = location.pathname.substring(
      location.pathname.lastIndexOf('/') + 1,
    );
    return path === 'settings' ? 'my_schedule' : path;
  }, [location]);

  const handleCloseModal = () => {
    setOpenLogoutModal(false)
  }

  const handleNavItemClick = useCallback(
    (name: string) => {
      switch (name as SettingsTabNames) {
        case 'get_help':
          history.push(SETTINGS_GET_HELP);
          break;
        case 'visibility_permissions':
          history.push(SETTINGS_VISIBILITY_PERMISSIONS);
          break;
        case 'give_us_feedback':
          history.push(SETTINGS_GIVE_FEEDBACK);
          break;
        case 'terms_of_service':
          history.push(SETTINGS_TERMS_OF_SERVICE);
          break;
        case 'privacy_policy':
          history.push(SETTINGS_PRIVACY_POLICY);
          break;
        case 'change_password':
          history.push(SETTINGS_CHANGE_PASSWORD);
          break;
        case 'logout':
          setOpenLogoutModal(true);
          break;
        default:
          break;
      }
    },
    [history],
  );

  useEffect(() => {
    if (!privacyPolicy) {
      dispatch(getLegalDoc('privacy-policy'));
    }
    if (!termsOfService) {
      dispatch(getLegalDoc('terms-of-service'));
    }
  }, [privacyPolicy, termsOfService, dispatch]);

  return (
    <>
      <NavBar />
      <SettingsContainer>
        <VerticalNavSettingsContent
          title={localizedStrings.settings}
          items={settingsTabs}
          selectedItem={selectedTab}
          onItemClick={handleNavItemClick}
        />
        <ContentSection>
          <Switch>
            <Route exact path={SETTINGS} component={GetHelp} />
            <Route exact path={SETTINGS_GET_HELP} component={GetHelp} />
            <Route
              exact
              path={SETTINGS_VISIBILITY_PERMISSIONS}
              component={VisibilityPermissions}
            />
            <Route
              exact
              path={SETTINGS_GIVE_FEEDBACK}
              component={GiveFeedback}
            />
            <Route
              exact
              path={SETTINGS_TERMS_OF_SERVICE}
              render={(props) => (
                <LegalDocRender {...props} document={termsOfService} />
              )}
            />
            <Route
              exact
              path={SETTINGS_PRIVACY_POLICY}
              render={(props) => (
                <LegalDocRender {...props} document={privacyPolicy} />
              )}
            />
            <Route
              exact
              path={SETTINGS_CHANGE_PASSWORD}
              component={ChangePassword}
            />
          </Switch>
        </ContentSection>
        <SignOutModal
          open={openLogoutModal}
          close={handleCloseModal}
        />
      </SettingsContainer>
    </>
  );
};

export default Settings;
