import RightArrowIcon from 'components/icons/RightArrowIcon';
import {
  ProfileCountersItemContainer,
  ProfileCountersItemImg,
  ProfileCountersItemImgContainer,
  ProfileCountersItemStack,
  ProfileCountersItemStackRight,
  ProfileCountersItemText,
} from 'modules/profile/components';
import { CounterItem } from '.';
import { useDispatch, useSelector } from 'react-redux';
import { updateProfileMenuSelected } from 'modules/profile/actions';
import { selectProfileMenuSelected } from 'modules/profile/selectors';

export const ProfileCountersItem = ({ item }: { item: CounterItem }) => {
  const dispatch = useDispatch();
  const menuSelected = useSelector(selectProfileMenuSelected);
  const activeMenuItem = item.link === menuSelected;
  const handleNavigate = () => dispatch(updateProfileMenuSelected(item.link));

  return (
    <ProfileCountersItemContainer onClick={handleNavigate}>
      <ProfileCountersItemStack>
        <ProfileCountersItemImgContainer>
          <ProfileCountersItemImg src={item.icon} alt={'icon ' + item.title} />
        </ProfileCountersItemImgContainer>
        <ProfileCountersItemText selected={activeMenuItem}>
          {item.title}
        </ProfileCountersItemText>
      </ProfileCountersItemStack>

      <ProfileCountersItemStackRight>
        <ProfileCountersItemText>{item.quantity}</ProfileCountersItemText>
        <RightArrowIcon color="black" />
      </ProfileCountersItemStackRight>
    </ProfileCountersItemContainer>
  );
};
