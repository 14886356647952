import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'redux/typings';

const selectModalState = (state: RootState) => state.modal;

export const selectPlaylistArtifactId = createSelector(
  selectModalState,
  (modalState) => modalState.playlistArtifactId,
);

export const selectIsPlaylistModalOpen = createSelector(
  selectModalState,
  (modalState) =>
    Boolean(modalState.playlistArtifactId) &&
    !Boolean(modalState.playlistArtifactConfirmModal),
);

export const selectIsPlaylistArtifactConfirmModal = createSelector(
  selectModalState,
  (modalState) => modalState.playlistArtifactConfirmModal,
);

export const selectModalIsAssetHyperlinkModalOpen = createSelector(
  selectModalState,
  (modalState) => Boolean(modalState.assetHyperlinkModalOpen),
);

export const selectModalAssetHyperlinkInformation = createSelector(
  selectModalState,
  (modalState) => modalState.assetHyperlinkModalInformation,
);

export const selectIsShareAppletModalOpen = createSelector(
  selectModalState,
  (modalState) => modalState.shareAppletModalOpen ?? false,
);

export const selectShareAppletModalInformation = createSelector(
  selectModalState,
  (modalState) => modalState.shareAppletModalInformation,
);

export const selectIsShareStepModalOpen = createSelector(
  selectModalState,
  (modalState) => modalState.shareStepModalOpen,
);

export const selectShareStepModalInformation = createSelector(
  selectModalState,
  (modalState) => modalState.shareStepModalInformation,
);

export const selectIsCelebrateModalOpen = createSelector(
  selectModalState,
  (modalState) => modalState.celebrateModalOpen ?? false,
);

export const selectReading = createSelector(
  selectModalState,
  (modalState) => modalState.reading,
);

export const selectIsReadingModalOpen = createSelector(
  selectReading,
  (readingStateId) => Boolean(readingStateId),
);
