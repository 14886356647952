import React, { useEffect } from 'react';

import localizedStrings from 'localization';
import {
  PublishShowcaseDescription,
  ShowcaseInput,
} from 'modules/profile/components';
import { StepProps } from '../typings';

const GeneralInfo = ({ setNextButtonText }: StepProps) => {
  useEffect(() => {
    setNextButtonText(localizedStrings.next);
  }, [setNextButtonText]);

  return (
    <>
      <PublishShowcaseDescription>
        {localizedStrings.selectShowcaseName}
      </PublishShowcaseDescription>
      <ShowcaseInput placeholder={localizedStrings.showcaseName} name="name" />
      <PublishShowcaseDescription>
        {localizedStrings.selectShowcaseCallToAction}
      </PublishShowcaseDescription>
      <ShowcaseInput
        placeholder={localizedStrings.showcaseCallToAction}
        name="callToAction"
      />
    </>
  );
};

export default GeneralInfo;
