import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { UploadFile } from 'components/applets/app-upload';
import { uploadFiles } from 'modules/files/actions';
import {
  login as loginService,
  signup as signupService,
  verifyCode as verifyCodeService,
  resendCode as resendCodeService,
  forgotPassword as forgotPasswordService,
  resetPassword as resetPasswordService,
  getLegalDoc as getLegalDocService,
  updateProfilePicture as updateProfilePictureService,
  updateProfileDisplayName as updateProfileDisplayNameService,
  updateProfileVisibilityAchievements as updateProfileVisibilityAchievementsService,
} from './services';
import {
  LoginData,
  UpdateProfileDisplayNamePayload,
  VisibilityAchievementsPayload,
} from './typings';

const LOGIN = 'LOGIN';
export const login = createAsyncThunk(LOGIN, loginService);

const REFRESH = 'REFRESH';
export const refresh = createAction<LoginData>(REFRESH);

const LOGOUT = 'LOGOUT';
export const logout = createAction(LOGOUT);

const CLEAR_JWT = 'CLEAR_JWT';
export const clearJwt = createAction(CLEAR_JWT);

const SIGNUP = 'SIGNUP';
export const signup = createAsyncThunk(SIGNUP, signupService);

const VERIFY_CODE = 'VERIFY_CODE';
export const verifyCode = createAsyncThunk(VERIFY_CODE, verifyCodeService);

const RESEND_CODE = 'RESEND_CODE';
export const resendCode = createAsyncThunk(RESEND_CODE, resendCodeService);

const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const forgotPassword = createAsyncThunk(
  FORGOT_PASSWORD,
  forgotPasswordService,
);

const RESET_PASSWORD = 'RESET_PASSWORD';
export const resetPassword = createAsyncThunk(
  RESET_PASSWORD,
  resetPasswordService,
);

const CLEAR_SIGNUP = 'CLEAR_SIGNUP';
export const clearSignup = createAction<undefined>(CLEAR_SIGNUP);

const GET_LEGAL_DOC = 'GET_LEGAL_DOC';
export const getLegalDoc = createAsyncThunk(GET_LEGAL_DOC, getLegalDocService);

const UPDATE_PROFILE_PICTURE = 'UPDATE_PROFILE_PICTURE';
export const updateProfilePicture = createAsyncThunk<
  string,
  UploadFile,
  { dispatch: any }
>(UPDATE_PROFILE_PICTURE, async (profilePicture, { dispatch }) => {
  let token: string;
  const id = 'Profile-picture-upload-id';

  const [uploadedProfilePicture] = await uploadFiles(
    [{ file: profilePicture, id }],
    dispatch,
  );
  token = await updateProfilePictureService({
    profilePicture: uploadedProfilePicture.fileUrl,
  });
  return token;
});

const UPDATE_PROFILE_DISPLAY_NAME = 'UPDATE_PROFILE_DISPLAY_NAME';
export const updateProfileDisplayName = createAsyncThunk<
  string,
  UpdateProfileDisplayNamePayload
>(UPDATE_PROFILE_DISPLAY_NAME, updateProfileDisplayNameService);

const UPDATE_PROFILE_VISIBILITY_ACHIEVEMENTS =
  'UPDATE_PROFILE_VISIBILITY_ACHIEVEMENTS';
export const updateProfileVisibilityAchievements = createAsyncThunk<
  string,
  VisibilityAchievementsPayload
>(
  UPDATE_PROFILE_VISIBILITY_ACHIEVEMENTS,
  updateProfileVisibilityAchievementsService,
);
