import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'redux/typings';
import { HelpState } from './typings';
import { HelpQuestion } from 'models/HelpQuestion';

const selectHelpState = (state: RootState) => state.help;

export const selectHelpQuestions = createSelector<
  RootState,
  HelpState,
  HelpQuestion[]
>(selectHelpState, (help) => help.questions);
