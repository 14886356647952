import { createAsyncThunk } from '@reduxjs/toolkit';

import { getApplets as getAppletsService } from './services';
import { AppletContentful } from 'models/AppletContentful';

const GET_APPLETS = 'GET_APPLETS';
export const getApplets = createAsyncThunk<
  AppletContentful[],
  string | undefined
>(GET_APPLETS, getAppletsService);
