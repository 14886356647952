import { useSelector } from 'react-redux';
import { RootState } from 'redux/typings';
import { AsyncThunk } from '@reduxjs/toolkit';

import * as statusSelector from 'modules/status/selectors';

const useActionStatus = (action: AsyncThunk<any, any, any>) => {
  const isPending = useSelector((state: RootState) =>
    statusSelector.isPendingSelector(state, action.typePrefix),
  );
  const wasFulfilled = useSelector((state: RootState) =>
    statusSelector.wasFulfilledSelector(state, action.typePrefix),
  );

  return [isPending, wasFulfilled];
};

export default useActionStatus;
