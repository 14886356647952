import React from 'react';

import { HelpQuestion } from 'models/HelpQuestion';
import useContentfulRenderer from 'hooks/useContentfulRenderer';
import Collapsible from 'components/collapsible';

type HelpCollapsibleProps = {
  helpQuestion: HelpQuestion;
};

const HelpCollapsible = ({
  helpQuestion: { question, answer },
}: HelpCollapsibleProps) => {
  const questionRendered = useContentfulRenderer(question, { darkMode: true });
  const answerRendered = useContentfulRenderer(answer, { darkMode: true });

  return <Collapsible title={questionRendered}>{answerRendered}</Collapsible>;
};

export default HelpCollapsible;
