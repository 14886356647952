import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectPlaylistArtifactById } from 'modules/home/playlist/selectors';
import ConfirmModal from './ConfirmModal';
import PlaylistModal from './PlaylistModal';
import {
  selectIsPlaylistArtifactConfirmModal,
  selectIsPlaylistModalOpen,
  selectPlaylistArtifactId,
} from './selectors';
import {
  closeArtifactConfirmModal,
  closeArtifactModal,
  openArtifactConfirmModal,
} from './actions';
import { Artifact } from 'modules/home/playlist/typings';
import AssetHyperLinkModal from './asset-hyperlink-modal';
import ShareAppletModal from './ShareAppletModal';
import ShareStepModal from './ShareStepModal';

const Modal = () => {
  const dispatch = useDispatch();

  const artifactId = useSelector(selectPlaylistArtifactId);
  const artifact = useSelector(selectPlaylistArtifactById(artifactId));
  const playlistModalOpen = useSelector(selectIsPlaylistModalOpen);
  const confirmModalOpen = useSelector(selectIsPlaylistArtifactConfirmModal);

  const closePlaylistModal = useCallback(() => {
    dispatch(closeArtifactModal());
  }, [dispatch]);
  const openConfirmModal = useCallback(() => {
    dispatch(openArtifactConfirmModal());
  }, [dispatch]);
  const closeConfirmModal = useCallback(() => {
    dispatch(closeArtifactConfirmModal());
    dispatch(closeArtifactModal());
  }, [dispatch]);

  const handlePlaylistModalClose = useCallback(
    (artifact: Artifact | undefined) => {
      if (!artifact?.completed) {
        openConfirmModal();
      } else {
        closePlaylistModal();
      }
    },
    [closePlaylistModal, openConfirmModal],
  );
  return (
    <>
      <PlaylistModal
        open={playlistModalOpen}
        close={handlePlaylistModalClose}
        artifact={artifact}
      />
      <ConfirmModal
        open={confirmModalOpen}
        close={closeConfirmModal}
        artifact={artifact}
      />
      <AssetHyperLinkModal />
      <ShareAppletModal />
      <ShareStepModal />
    </>
  );
};

export default Modal;
