import { useCallback, useRef, RefObject } from 'react';
import { FormikProps } from 'formik';

const useFormikRef = <FormikFormType>(): [
  RefObject<FormikProps<FormikFormType | null>>,
  (instance: any) => void,
] => {
  const formikRef = useRef<FormikProps<FormikFormType | null>>(null);

  const assignRef = useCallback(
    (instance: any) => {
      // the current typing for React refs is readlonly so it throw typing error without casting to any
      (formikRef as any).current = instance;
    },
    [formikRef],
  );

  return [formikRef, assignRef];
};

export default useFormikRef;
