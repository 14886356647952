import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { calculateFeedbacksAverage } from 'helpers/helperFunctions';
import { ContentContentful } from 'models/ContentContentful';
import { selectContentsContentful } from 'modules/loading-screen/contentful/contents/selectors';
import { Achievement, ACHIEVEMENT_TYPE } from 'modules/profile/typings.d';
import ChallengeAchievementCard from './ChallengeAchievementCard';
import OtherAchievementCard from './OtherAchievementCard';

type AchievementCardProps = {
  achievement: Achievement;
  onAchievementClick: (
    achievement: Achievement,
    content: ContentContentful,
  ) => void;
};

const AchievementCard = ({
  achievement,
  onAchievementClick,
}: AchievementCardProps) => {
  const contentsContentful = useSelector(selectContentsContentful);

  const handleClick = useCallback(
    (achievement: Achievement, content: ContentContentful) => () => {
      onAchievementClick(achievement, content);
    },
    [onAchievementClick],
  );

  const renderAchievement = useMemo(() => {
    if (achievement.type === ACHIEVEMENT_TYPE.CHALLENGE) {
      const content = contentsContentful[achievement.contentContentfulId!];
      const feedbacks = achievement.feedbacks;
      const feedbackAverage = calculateFeedbacksAverage(feedbacks);
      return (
        <ChallengeAchievementCard
          xp={achievement.xp}
          bp={achievement.bp}
          feedbackAverage={feedbackAverage}
          contentContentful={content}
          feedbacksLength={achievement.feedbacks?.length ?? 0}
          onClick={handleClick(achievement, content)}
        />
      );
    }
    return (
      <OtherAchievementCard
        bp={achievement.bp}
        xp={achievement.xp}
        type={achievement.type}
        questContentfulId={achievement.questContentfulId}
        weekContentfulId={achievement.weekContentfulId}
        contentContentfulId={achievement.contentContentfulId}
        feedbacks={achievement.feedbacks}
      />
    );
  }, [achievement, contentsContentful, handleClick]);

  return renderAchievement;
};

export default AchievementCard;
