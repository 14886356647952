import React, { useCallback } from 'react';

import { StyledButton } from 'components/components';
import CirclePlus from 'components/icons/CirclePlus';
import CircleMinus from 'components/icons/CircleMinus';

import {
  ScheduleDateInputContainer,
  ScheduleDateInputDay,
  ScheduleDateInputControls,
  ScheduleDateInputHours,
} from '../components';

type ScheduleDateInputProps = {
  day: string;
  dayHours: number;
  active: boolean;
  onPressAddHour: () => void;
  onPressRemoveHour: () => void;
};

const ScheduleDateInput = ({
  day,
  dayHours,
  active,
  onPressAddHour,
  onPressRemoveHour,
}: ScheduleDateInputProps) => {
  const handleChangeHours = useCallback(
    (action: 'add' | 'remove') => () => {
      if (action === 'add') {
        if (dayHours < 12) {
          onPressAddHour();
        }
      }
      if (action === 'remove') {
        if (dayHours > 0) {
          onPressRemoveHour();
        }
      }
    },
    [dayHours, onPressAddHour, onPressRemoveHour],
  );

  return (
    <ScheduleDateInputContainer active={active}>
      <ScheduleDateInputDay>{day}</ScheduleDateInputDay>
      <ScheduleDateInputControls>
        <StyledButton onClick={handleChangeHours('remove')} disabled={!active}>
          <CircleMinus disabled={!active} />
        </StyledButton>
        <ScheduleDateInputHours>{dayHours}</ScheduleDateInputHours>
        <StyledButton onClick={handleChangeHours('add')}>
          <CirclePlus />
        </StyledButton>
      </ScheduleDateInputControls>
    </ScheduleDateInputContainer>
  );
};

export default ScheduleDateInput;
