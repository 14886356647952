import styled from '@emotion/styled';

export const NavigationSection = styled.div`
  height: 100%;
  width: 19rem;
  margin-right: 1.6rem;
  border-radius: 0.6rem;
  background-color: rgba(26, 26, 26, 0.8);
`;

export const NavigationTitle = styled.div`
  color: #ffffff;
  display: flex;
  font-size: ${(props) => props?.theme?.typography.large24};
  font-weight: ${(props) => props?.theme?.typography.semiBold};
  justify-content: space-between;
  line-height: ${(props) => props?.theme?.typography.large32};
  margin: 1.8rem 0 4.2rem 1.1rem;
  padding-right: 1rem;
`;

export const NavigationButtonContainer = styled.button`
  background-color: transparent;
  border: none;
  padding: 0;
  display: flex;
  align-items: center;
  width: 100%;
`;

type NavigationSelectedIndicatorProps = {
  visible: boolean;
};

export const NavigationSelectedIndicator = styled.div<NavigationSelectedIndicatorProps>`
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
  height: 3.3rem;
  width: 0.4rem;
  border-radius: 0.2rem;
  background-color: ${(props) => props?.theme?.colors.blue2[100]};
`;

type NavigationTextProps = { selected: boolean };

export const NavigationText = styled.div<NavigationTextProps>`
  color: #ffffff;
  font-size: ${(props) =>
    props.selected
      ? props?.theme?.typography.large24
      : props?.theme?.typography.large18};
  font-weight: ${(props) =>
    props.selected ? props?.theme?.typography.semiBold : 'normal'};
  line-height: ${(props) =>
    props.selected
      ? props?.theme?.typography.large32
      : props?.theme?.typography.large24};
  margin-left: 0.7rem;
`;

export const ButtonBackPlaylist = styled.button`
  background-color: ${(props) => props?.theme?.colors.white[100]};
  font-size: ${(props) => props?.theme?.typography.large18};
  color: ${(props) => props?.theme?.colors.blue2[100]};
  padding: 0.4rem 0;
  border: 1px solid ${(props) => props?.theme?.colors.blue2[100]};
  border-radius: 0.4rem;
  width: 90%;
  align-self: center;
  margin: 0 5%;
`;
