import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import {
  addHour,
  getScheduleCommit,
  removeHour,
  submitScheduleCommit,
} from 'modules/loading-screen/schedule-commit/actions';
import {
  selectEditingScheduleCommit,
  selectTotalHours,
} from 'modules/loading-screen/schedule-commit/selectors';
import { AppDispatch } from 'redux/typings';
import { getSchedules } from 'modules/loading-screen/schedules/actions';
import {
  selectActiveQuestId,
  selectActiveQuestWeekIds,
} from 'modules/loading-screen/quests/selectors';
import { LOADING_SCREEN, DAILY_REMINDER_TIME } from 'routes';
import localizedStrings from 'localization';
import ButtonPrimary from 'components/button-primary';
import {
  ScheduleCommitTitle,
  ScheduleCommitPrompt,
  ScheduleCommitDescription,
  ScheduleCommitBackground,
  ScheduleCommitContainer,
  ScheduleCommitContainerSelectedHours,
  ScheduleCommitControls,
} from '../components';
import ScheduleWeekEdit from './ScheduleWeekEdit';
import { MAX_HOURS_PER_WEEK } from './constants';
import useActionStatus from 'hooks/useActionStatus';
import useMinHoursPerWeek from 'hooks/useMinHoursPerWeek';
import useScheduleHoursDefault from 'hooks/useScheduleHoursDefault';

const ScheduleCommit = () => {
  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [hasRequestedSchedules, setHasRequestedSchedules] = useState(false);
  const schedule = useSelector(selectEditingScheduleCommit);
  const weeksIds = useSelector(selectActiveQuestWeekIds);
  const questId = useSelector(selectActiveQuestId);
  const totalHours = useSelector(selectTotalHours);
  const minHoursPerWeek = Math.abs(useMinHoursPerWeek());

  const [isSubmitSchedulePending, wasSubmitScheduleFulfilled] =
    useActionStatus(submitScheduleCommit);
  const [isGetSchedulePending, wasGetScheduleFulfilled] =
    useActionStatus(getSchedules);

  const handleAddHour = useCallback(
    (day) => () => {
      if (totalHours < MAX_HOURS_PER_WEEK) {
        dispatch(addHour(day));
      }
    },
    [dispatch, totalHours],
  );

  const handleRemoveHour = useCallback(
    (day) => () => {
      if (totalHours > minHoursPerWeek) {
        dispatch(removeHour(day));
      }
    },
    [dispatch, totalHours, minHoursPerWeek],
  );

  const handlePressScheduleCommit = useCallback(() => {
    if (questId !== undefined) {
      if (totalHours < minHoursPerWeek) {
        alert(localizedStrings.scheduleAlertContent(minHoursPerWeek));
      } else {
        dispatch(
          submitScheduleCommit({
            questId,
            mondayHours: schedule.mondayHours,
            tuesdayHours: schedule.tuesdayHours,
            wednesdayHours: schedule.wednesdayHours,
            thursdayHours: schedule.thursdayHours,
            fridayHours: schedule.fridayHours,
            saturdayHours: schedule.saturdayHours,
            sundayHours: schedule.sundayHours,
          }),
        );
        setHasSubmitted(true);
      }
    }
  }, [dispatch, totalHours, questId, schedule, minHoursPerWeek]);

  useEffect(() => {
    if (questId === undefined) {
      history.push(LOADING_SCREEN);
    }
  }, [history, questId]);

  useEffect(() => {
    if (hasSubmitted && wasSubmitScheduleFulfilled) {
      setHasSubmitted(false);
      dispatch(getScheduleCommit({ questId }));
      dispatch(getSchedules({ questId }));
      setHasRequestedSchedules(true);
    }
  }, [dispatch, hasSubmitted, wasSubmitScheduleFulfilled, weeksIds, questId]);

  useEffect(() => {
    if (hasRequestedSchedules && wasGetScheduleFulfilled) {
      setHasRequestedSchedules(false);
      history.push(DAILY_REMINDER_TIME);
    }
  }, [hasRequestedSchedules, history, wasGetScheduleFulfilled]);

  useScheduleHoursDefault();

  return (
    <ScheduleCommitBackground>
      <ScheduleCommitContainer>
        <ScheduleCommitTitle>
          {localizedStrings.setupScheduleCommit}
        </ScheduleCommitTitle>
        <ScheduleCommitPrompt>
          {localizedStrings.daysWorkInTheApp}
        </ScheduleCommitPrompt>
        <ScheduleCommitDescription>
          {localizedStrings.finishChallengeScheduleCommit(minHoursPerWeek)}
        </ScheduleCommitDescription>
        <ScheduleCommitControls>
          <ScheduleWeekEdit
            schedule={schedule}
            onAddHour={handleAddHour}
            onRemoveHour={handleRemoveHour}
          />
          <ScheduleCommitContainerSelectedHours>
            {localizedStrings.hoursSelected(totalHours)}
          </ScheduleCommitContainerSelectedHours>
          <ButtonPrimary
            isLoading={isSubmitSchedulePending || isGetSchedulePending}
            disabled={totalHours < minHoursPerWeek}
            onClick={handlePressScheduleCommit}>
            {localizedStrings.start}
          </ButtonPrimary>
        </ScheduleCommitControls>
      </ScheduleCommitContainer>
    </ScheduleCommitBackground>
  );
};

export default ScheduleCommit;
