import React from 'react'
import { LoadWeekProgressLinesk } from './components';
import './styleLoader.css';

export const LoadWeekProgressLine = () => {
  return (
    <div>
      <LoadWeekProgressLinesk className='loader' />
    </div>
  )
}
