import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { getSchedules as getSchedulesService } from './services';

const GET_SCHEDULES = 'GET_SCHEDULES';
export const getSchedules = createAsyncThunk(
  GET_SCHEDULES,
  async ({ questId }: { questId: string }, thunkApi) => {
    const response = await getSchedulesService({ questId });
    if (response.status === 'success') {
      return response.data;
    } else {
      const { errorCode, errorInformation } = response;
      return thunkApi.rejectWithValue({ errorCode, errorInformation });
    }
  },
);

const SELECT_SCHEDULE_DATE = 'SELECT_SCHEDULE_DATE';
export const selectScheduleDate = createAction<string>(SELECT_SCHEDULE_DATE);

const UNSELECT_SCHEDULE_DATE = 'UNSELECT_SCHEDULE_DATE';
export const unselectScheduleDate = createAction<void>(UNSELECT_SCHEDULE_DATE);
