import { User } from './User';

export interface Relationship {
  id: string;
  relatedUser: User;
  quests: { id: string; title: string }[];
  chatId: string;
  unreadMessages: number;
  relationshipType: RelationshipType;
  relationshipStatus: RelationshipStatus;
  inviteeEmail: string;
  inviteMessage: string;
  inviteeName: string;
  profilePictureUrl?: string;
  createdAt: Date;
  updatedAt?: Date;
}

export enum RelationshipActions {
  ACCEPT = 'ACCEPT',
  INVO_SENT = 'INVO_SENT',
  CANCEL = 'CANCEL',
  CLOSE_MODAL = 'CLOSE_MODAL',
  DECLINE = 'DECLINE',
}

export enum RelationshipStatus {
  REQUESTED = 'Requested',
  CONFIRMED = 'Confirmed',
  RECEIVED = 'Invitation received',
  SENT = 'Invitation sent',
}

export enum RelationshipType {
  FELLOW_TRAVELER = 'Fellow Traveler',
  MENTOR = 'Mentor',
  EXTERNAL_MENTOR = 'External Mentor',
  EXTERNAL_RUNNING_PARTNER = 'External Running Partner',
  RUNNING_PARTNER = 'Running Partner',
  GUIDE = 'Guide',
}
