import React from 'react';

type ChevronRightProps = {
  size?: number;
  color?: string;
};

const ChevronRight = ({ size = 15, color = '#021C32' }: ChevronRightProps) => {
  return (
    <svg height={size} width={size} viewBox="0 0 407.436 407.436">
      <polygon
        fill={color}
        points="112.814,0 91.566,21.178 273.512,203.718 91.566,386.258 112.814,407.436 315.869,203.718 "
      />
    </svg>
  );
};

export default ChevronRight;
