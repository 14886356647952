import React, { useCallback, useMemo } from 'react';
import {
  IQuizQuestionOption,
  IQuizQuestion,
} from '../../../../models/contentful';
import Option from './Option';
import QuizQuestionContentfulOption from './QuizQuestionContentfulOption';
import { useDispatch, useSelector } from 'react-redux';
import { updateQuizQuestionValue } from '../../../../modules/applet-upload/actions';
import useQuizChallengeLogic from 'hooks/useQuizChallengeLogic';
import { QuizQuestionAnswerType } from '../../../../models/QuizQuestionAnswerType';
import { selectQuizQuestionValue } from '../../../../modules/applet-upload/selectors';
import { selectChallengeQuizQuestion } from '../../../../modules/loading-screen/contents/selectors';
import { selectQuestQuizQuestion } from '../../../../modules/applets/selectors';

type QuizQuestionOptionRendererProps = {
  option: IQuizQuestionOption;
  question: IQuizQuestion;
  appletContentfulId: string;
  quizQuestionContentfulId: string;
  quizSectionContentfulId: string;
  quizContentfulId: string;
};

const QuizQuestionOptionRenderer = ({
  option,
  question,
  appletContentfulId,
  quizQuestionContentfulId,
  quizSectionContentfulId,
  quizContentfulId,
}: QuizQuestionOptionRendererProps) => {
  const dispatch = useDispatch();

  const {
    id: appletUserId,
    quizQuestions,
    isAppletDisabled,
  } = useQuizChallengeLogic(appletContentfulId);

  const questionToUploadValue = useSelector(
    selectQuizQuestionValue(appletContentfulId, question.sys.id),
  );
  const challengeQuestionValue = useSelector(
    selectChallengeQuizQuestion(appletContentfulId, question.sys.id),
  );
  const questQuestionValue = useSelector(
    selectQuestQuizQuestion(quizQuestionContentfulId, question.sys.id),
  );

  const onValueChange = useCallback(() => {
    dispatch(
      updateQuizQuestionValue({
        applet: {
          id: appletUserId,
          appletId: appletContentfulId,
          quizContentfulId,
          quizSectionContentfulId,
          contentfulId: question.sys.id,
          answer: option.fields.result || option.fields.title,
          answerType: QuizQuestionAnswerType.OPTION,
          madlibAnswers: undefined,
          madlibAppletContentfulId: undefined,
        },
        modified: true,
      }),
    );
  }, [
    appletContentfulId,
    appletUserId,
    dispatch,
    option.fields.result,
    option.fields.title,
    question.sys.id,
    quizContentfulId,
    quizSectionContentfulId,
  ]);

  let answer = useMemo(() => {
    if (quizQuestions) {
      const foundQuestion = quizQuestions.find(
        (q) => q.quizQuestionContentfulId === question.sys.id,
      )?.answer;
      return foundQuestion;
    }
    return (
      questionToUploadValue?.answer ??
      challengeQuestionValue?.answer ??
      questQuestionValue?.answer ??
      ''
    );
  }, [
    questionToUploadValue,
    challengeQuestionValue,
    questQuestionValue,
    question,
    quizQuestions,
  ]);

  return (
    <>
      <Option
        renderedOption={
          <QuizQuestionContentfulOption
            option={option as IQuizQuestionOption}
          />
        }
        key={option.sys.id}
        disabled={isAppletDisabled}
        value={option.fields.result || option.fields.title}
        answer={answer}
        result={option.fields.result}
        onValueChange={onValueChange}
      />
    </>
  );
};

export default QuizQuestionOptionRenderer;
