import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { parseISO, startOfWeek } from 'date-fns';

import Modal from 'components/modal';
import localizedStrings from 'localization';
import ScheduleWeekEdit from 'modules/loading-screen/schedule-commit/ScheduleWeekEdit';
import { AppDispatch } from 'redux/typings';
import {
  selectEditedWeekId,
  selectEditingScheduleCommit,
  selectTotalHours,
} from 'modules/loading-screen/schedule-commit/selectors';
import { selectActiveQuestId } from 'modules/loading-screen/quests/selectors';
import useActionStatus from 'hooks/useActionStatus';
import {
  addHour,
  editSchedule,
  getScheduleCommit,
  removeHour,
  selectWeekConfigByDate,
} from 'modules/loading-screen/schedule-commit/actions';
import {
  EditScheduleContainer,
  EditScheduleDescription,
  EditSchedulePrompt,
  EditScheduleSelectedHours,
  EditScheduleSelectors,
} from '../components';
import { MAX_HOURS_PER_WEEK } from 'modules/loading-screen/schedule-commit/constants';
import Dialog from 'components/dialog';
import useMinHoursPerWeek from 'hooks/useMinHoursPerWeek';

type ScheduleEditProps = {
  open: boolean;
  close: () => void;
  selectedDay: string;
};

const ScheduleEdit = ({ open, close, selectedDay }: ScheduleEditProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const schedule = useSelector(selectEditingScheduleCommit);
  const questId = useSelector(selectActiveQuestId);
  const editedWeekId = useSelector(selectEditedWeekId);
  const totalHours = useSelector(selectTotalHours);
  const [isPending, wasFulfilled] = useActionStatus(editSchedule);
  const minHoursPerWeek = Math.abs(useMinHoursPerWeek());

  const startingDate = useMemo(() => {
    if (selectedDay) {
      const startingDay = startOfWeek(parseISO(selectedDay), {
        weekStartsOn: 1,
      });
      return startingDay;
    }
    return undefined;
  }, [selectedDay]);

  const handleAddHour = useCallback(
    (day) => () => {
      if (totalHours < MAX_HOURS_PER_WEEK) {
        dispatch(addHour(day));
      }
    },
    [dispatch, totalHours],
  );

  const handleRemoveHour = useCallback(
    (day) => () => {
      dispatch(removeHour(day));
    },
    [dispatch],
  );

  const handleEditSchedule = useCallback(() => {
    if (editedWeekId !== undefined && questId !== undefined) {
      if (totalHours < minHoursPerWeek) {
        alert(localizedStrings.scheduleAlertContent(minHoursPerWeek));
      } else {
        setEditDialogOpen(true);
      }
    }
  }, [questId, editedWeekId, totalHours, minHoursPerWeek]);

  const handleEdit = useCallback(
    (allWeeks: boolean) => () => {
      setHasSubmitted(true);
      setEditDialogOpen(false);
      dispatch(
        editSchedule({
          ...schedule,
          weekId: editedWeekId!,
          questId,
          shouldApplyForAllWeeksNotStarted: allWeeks,
        }),
      ).then(() => dispatch(getScheduleCommit({ questId })));
    },
    [dispatch, editedWeekId, questId, schedule],
  );

  useEffect(() => {
    if (startingDate) {
      dispatch(selectWeekConfigByDate(startingDate));
    }
  }, [dispatch, startingDate]);

  useEffect(() => {
    if (hasSubmitted && wasFulfilled) {
      close();
    }
  }, [hasSubmitted, wasFulfilled, close]);

  return (
    <Modal
      open={open}
      closeModal={close}
      title={localizedStrings.editSchedule}
      acceptButtonText="Edit"
      acceptButtonDisabled={totalHours < minHoursPerWeek}
      acceptButtonisLoading={isPending}
      onAcceptButtonClick={handleEditSchedule}>
      <Dialog
        open={editDialogOpen}
        title={localizedStrings.editSchedule}
        prompt={localizedStrings.editSchedulePrompt}
        onNoClick={handleEdit(false)}
        onYesClick={handleEdit(true)}
      />
      <EditScheduleContainer>
        <EditScheduleSelectors>
          <EditSchedulePrompt>
            {localizedStrings.daysWorkInTheApp}
          </EditSchedulePrompt>
          <EditScheduleDescription>
            {localizedStrings.finishChallengeScheduleCommit(minHoursPerWeek)}
          </EditScheduleDescription>
          <ScheduleWeekEdit
            schedule={schedule}
            onAddHour={handleAddHour}
            onRemoveHour={handleRemoveHour}
          />
          <EditScheduleSelectedHours>
            {totalHours} hours selected
          </EditScheduleSelectedHours>
        </EditScheduleSelectors>
      </EditScheduleContainer>
    </Modal>
  );
};

export default ScheduleEdit;
