import { useState, useCallback } from 'react';

const useIsModalOpen = (
  initialState = false,
): [boolean, () => void, () => void] => {
  const [isModalOpen, setIsModalOpen] = useState(initialState);

  const openModal = useCallback(() => {
    setIsModalOpen(true);
  }, [setIsModalOpen]);

  const closeModal = useCallback(() => {
    setIsModalOpen(false);
  }, [setIsModalOpen]);

  return [isModalOpen, openModal, closeModal];
};

export default useIsModalOpen;
