export enum NotificationType {
  OTHER = 'OTHER',
  CHAT = 'CHAT',
  CHALLENGE_COMPLETED = 'CHALLENGE_COMPLETED',
  WEEK_COMPLETED = 'WEEK_COMPLETED',
  QUEST_COMPLETED = 'QUEST_COMPLETED',
  FEEDBACK_RECEIVED = 'FEEDBACK_RECEIVED',
  FEEDBACK_REQUESTED = 'FEEDBACK_REQUESTED',
  INVITATION_RECEIVED = 'INVITATION_RECEIVED',
  INVITATION_ACCEPTED = 'INVITATION_ACCEPTED',
  RELATIONSHIP_SWITCHED = 'RELATIONSHIP_SWITCH',
}

