import { useMemo } from 'react';

import { IQuiz } from 'models/contentful';
import { QuizQuestion } from 'models/Applet';
import { useSelector } from 'react-redux';
import { selectChallengeAppletByContentfulId } from 'modules/loading-screen/contents/selectors';
import { selectQuestChallengeApplet } from 'modules/applets/selectors';
import { selectAppletById } from 'modules/applet-upload/selectors';

const useQuizChartInfo = (
  appletId: string,
  quiz?: IQuiz,
  quizQuestions?: QuizQuestion[],
) => {
  const appletAnswer = useSelector(
    selectChallengeAppletByContentfulId(appletId),
  );
  const questAnswer = useSelector(selectQuestChallengeApplet(appletId));

  const appletToUpload = useSelector(selectAppletById(appletId));

  const appletsToUploadQuizResults =
    appletToUpload?.quizInformation?.questionsMap;

  const chartInfo = useMemo(() => {
    const results = appletsToUploadQuizResults
      ? Object.values(appletsToUploadQuizResults)
      : quizQuestions ||
        appletAnswer?.quizQuestions ||
        questAnswer?.quizQuestions;

    const totalFields = results?.length;

    let quizAnswers;
    let chartLabels = [];
    if (quiz?.fields.rewards) {
      for (quizAnswers of quiz.fields.rewards) {
        chartLabels.push({
          title: quizAnswers.fields.title,
          count: 0,
          result: quizAnswers.fields.associatedResult,
        });
      }
    }

    let resultFields;
    if (results && totalFields) {
      for (resultFields of results) {
        for (let i = 0; i < chartLabels.length; i++) {
          if (
            chartLabels[i].result.toLowerCase() ===
            resultFields.answer?.toLowerCase()
          ) {
            chartLabels[i].count = Math.round(
              chartLabels[i].count + (1 / totalFields) * 100,
            );
          }
        }
      }
    }

    return chartLabels;
  }, [
    appletAnswer?.quizQuestions,
    appletsToUploadQuizResults,
    questAnswer?.quizQuestions,
    quiz?.fields.rewards,
    quizQuestions,
  ]);

  return chartInfo;
};

export default useQuizChartInfo;
