import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { StepCardInformation } from 'models/Schedule';
import OvalIcon from 'components/icons/OvalIcon';
import ChallengeBasicInformation from 'modules/home/challenge-basic-information';
import {
  StepCardContainer,
  StepCardStatusContainer,
  AStepCardInfo,
  StepCardTitlesContainer,
  StepCardTitle,
  StepCardStepTitle,
  StepCardStatus,
} from '../components';
import { selectContents } from 'modules/loading-screen/contents/selectors';
import { selectStepsContentful } from 'modules/loading-screen/contentful/steps/selectors';
import theme from 'utils/theme';
import CheckIcon from 'components/icons/CheckIcon';
import { selectContentsContentful } from '../../loading-screen/contentful/contents/selectors';

type StepCardProps = {
  step: StepCardInformation;
  onClick: (step: StepCardInformation) => void;
  day: string;
};

const StepCard = ({ step, onClick, day }: StepCardProps) => {
  const challengesMap = useSelector(selectContents);
  const stepsContentful = useSelector(selectStepsContentful);
  const contentsContentful = useSelector(selectContentsContentful);

  const stepInformation = useMemo(() => {
    const { id, challengeId } = step;
    const challenge = challengesMap[challengeId];
    const totalSteps = challenge?.stepIds.length + 1 || 1;
    const stepContentful = stepsContentful[id];
    const stepNumber =
      step.type === 'step' ? (stepContentful?.stepNumber || 0) + 1 : totalSteps;

    return `${stepNumber}/${totalSteps}`;
  }, [challengesMap, step, stepsContentful]);

  const handelClick = useCallback(() => {
    onClick(step);
  }, [onClick, step]);

  const challengeUser = challengesMap[step.challengeId || ''];
  const challengeContentful = contentsContentful[challengeUser?.contentfulId];
  const challengeLocation = challengeContentful?.location;

  return (
    <StepCardContainer
      imageUrl={step.challengeImage}
      completed={step.completed}
      onClick={handelClick}>
      <StepCardStatusContainer>
        <StepCardTitlesContainer>
          <StepCardTitle>{step.challengeTitle}</StepCardTitle>
          <StepCardStepTitle>{step.stepTitle}</StepCardStepTitle>
        </StepCardTitlesContainer>

        <AStepCardInfo>
          <StepCardStatus>{stepInformation}</StepCardStatus>
          {step.completed ? (
            <CheckIcon
              width={theme.typography.large41}
              height={theme.typography.large41}
            />
          ) : null}
          {!step.completed && !step.isLate ? (
            <OvalIcon
              width={theme.typography.large41}
              height={theme.typography.large41}
            />
          ) : null}
        </AStepCardInfo>
      </StepCardStatusContainer>

      <ChallengeBasicInformation
        time={step.stepDuration}
        xp={step.stepPoints}
        location={step.location || challengeLocation || ''}
        bonusChallenge={challengeContentful?.bonusChallenge}
      />
    </StepCardContainer>
  );
};

export default StepCard;
