import React, { PropsWithChildren } from 'react';

type NestingContextType = {
  olNestingLevel?: number;
  ulNestingLevel?: number;
};

const NestingContext = React.createContext<NestingContextType>({});
const { Provider } = NestingContext;

const NestingProvider = ({
  olNestingLevel,
  ulNestingLevel,
  children,
}: PropsWithChildren<NestingContextType>) => {
  return (
    <Provider value={{ olNestingLevel, ulNestingLevel }}>{children}</Provider>
  );
};

export { NestingProvider, NestingContext };
