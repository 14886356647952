import React from 'react';
import theme from 'utils/theme';

const ArrowIcon = () => (
  <svg
    width={theme.typography.large20}
    height={theme.typography.large20}
    viewBox="0 0 8 12">
    <title>D1E2F528-D37D-41AE-BE87-1FEFC9723DB6</title>
    <g id="V3" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Profile---Achievements"
        transform="translate(-343.000000, -242.000000)"
        fill={theme.colors.white[100]}>
        <g id="Icons/24/Arrow" transform="translate(335.000000, 236.000000)">
          <polygon
            id="Arrow"
            points="8 7.26511798 9.39308733 6 16 12 9.39308733 18 8 16.734882 13.2138253 12"></polygon>
        </g>
      </g>
    </g>
  </svg>
);
export default ArrowIcon;
