import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'redux/typings';

const selectChatState = (state: RootState) => state.chat;

export const selectChatroomId = createSelector(
  selectChatState,
  (chat) => chat.chatroomId,
);

export const selectMessages = createSelector(
  selectChatState,
  (chat) => chat.messages,
);

export const selectSubscription = createSelector(
  selectChatState,
  (chat) => chat.subscription,
);

export const selectLoading = createSelector(
  selectChatState,
  (chat) => chat.loading,
);
