import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'redux/typings';
import {
  selectAvailableWeeksIds,
  selectSelectedWeekId,
  selectWeeksHash,
} from 'modules/loading-screen/weeks/selectors';
import { DaySchedule, NextUpPayloadBonus } from './typings';
import { addMinutes, isBefore, isSameDay, parseISO } from 'date-fns';
import { formatUTCDate } from 'helpers/dateHelpers';
import { selectContents } from '../contents/selectors';
import { startOfDay, parse } from 'date-fns';
import { selectContentsContentful } from '../contentful/contents/selectors';

const selectSchedules = (state: RootState) => state.schedules;

export const selectSchedulesMap = createSelector(
  selectSchedules,
  (schedules) => schedules.map,
);

export const selectWeekSchedule = createSelector(
  selectSelectedWeekId,
  selectSchedulesMap,
  (weekId, scheduleMap) =>
    weekId && scheduleMap ? scheduleMap[weekId] : undefined,
);

export const selectWeekScheduleArray = createSelector(
  selectSelectedWeekId,
  selectWeekSchedule,
  (weekId, weekScheduleMap) => {
    const scheduleArray: DaySchedule[] = [];
    if (weekId && weekScheduleMap) {
      const weekDays = Object.keys(weekScheduleMap);
      for (const day of weekDays) {
        const daySchedule = weekScheduleMap[day];
        if (Object.keys(daySchedule).length > 0) {
          const currentDay = parseISO(day);
          const date = formatUTCDate(currentDay)!!;
          scheduleArray.push({
            weekId,
            dateId: day,
            date,
            steps: Object.values(daySchedule),
          });
        }
      }
    }

    return scheduleArray;
  },
);

export const selectNextUp = ({ includeBonus = false }: NextUpPayloadBonus) => createSelector(
  selectSchedulesMap,
  selectContents,
  selectAvailableWeeksIds,
  selectWeeksHash,
  selectContentsContentful,
  (scheduleMap, contentMap, weekIds, weekMap, contentContentful) => {
    if (scheduleMap) {
      const weeks = Object.keys(scheduleMap);
      for (const week of weeks) {
        if (weekIds.includes(week)) {
          const weekSchedule = scheduleMap[week];
          const days = Object.keys(weekSchedule);
          for (const day of days) {
            const dayDate = parseISO(day);
            const dayDateString = formatUTCDate(dayDate);
            const daySteps = Object.values(weekSchedule[day]);

            const steps = daySteps.filter((step) => {
              let validStep = false;
              const { challengeId } = step;
              const content = contentMap?.[challengeId];
              const { contentfulId } = content || { contentfulId: '' };
              const weekInformation = weekMap[week];
              const contentDefinition = contentContentful[contentfulId];
              if (weekInformation && contentDefinition) {
                if (contentDefinition.bonusChallenge && !includeBonus) {
                  validStep = false;
                } else {
                  validStep = !step.completed && !content.completed;
                }
              }

              return validStep;
            });

            if (steps.length > 0) {
              return {
                weekId: week,
                dateId: day,
                date: dayDateString,
                steps,
              } as DaySchedule;
            }
          }
        }
      }
    }
    return undefined;
  },
);

export const selectStepDate = (stepId?: string) =>
  createSelector(selectWeekSchedule, (scheduleMap) => {
    if (scheduleMap && stepId) {
      const weekDays = Object.keys(scheduleMap);
      for (const day of weekDays) {
        const dayScheduleStepIds = Object.keys(scheduleMap[day]);
        const stepIdFound = dayScheduleStepIds.some(
          (dayStepId) => dayStepId === stepId,
        );
        if (stepIdFound) {
          return day;
        }
      }
    }
    return undefined;
  });

export const selectWeeksScheduleByDate = createSelector(
  selectSchedulesMap,
  (schedules) => (schedules ? Object.values(schedules) : []),
);

export const selectIsLate = createSelector(
  selectSchedulesMap,
  selectWeeksHash,
  (scheduleMap, weeksMap) => {
    const weeks = Object.keys(scheduleMap || {});
    const today = new Date();

    for (const weekId of weeks) {
      const week = weeksMap[weekId];
      if (week) {
        const weekStartingDate = parseISO(week.startingDate.toString());
        if (isBefore(weekStartingDate, today)) {
          const days = Object.keys(scheduleMap?.[weekId] || {});
          for (const day of days) {
            const currentDay = parseISO(day);
            const currentIsoDay = addMinutes(
              currentDay,
              currentDay.getTimezoneOffset(),
            );
            if (
              isBefore(currentIsoDay, today) &&
              !isSameDay(currentIsoDay, today)
            ) {
              const steps = Object.keys(scheduleMap?.[weekId]?.[day] || {});
              for (const step of steps) {
                if (!scheduleMap?.[weekId]?.[day]?.[step]?.completed) {
                  return true;
                }
              }
            }
          }
        }
      }
    }

    return false;
  },
);

export const selectChallengeLateMap = createSelector(
  selectSchedulesMap,
  (map = {}) => {
    const challengeLateMap: Record<string, boolean> = {};
    const today = startOfDay(new Date());

    for (const weekId of Object.keys(map)) {
      const weekSchedule = map[weekId];
      for (const day of Object.keys(weekSchedule)) {
        const dayStepMap = weekSchedule[day];
        for (const stepId of Object.keys(dayStepMap)) {
          const stepInfo = dayStepMap[stepId];
          const dayString = day.split('T')[0];
          const stepScheduledDate = parse(dayString, 'yyyy-MM-dd', new Date());
          const existingValue = challengeLateMap[stepInfo.challengeId] ?? false;
          const isStepLate =
            isBefore(stepScheduledDate, today) && !stepInfo.completed;
          challengeLateMap[stepInfo.challengeId] = existingValue || isStepLate;
        }
      }
    }

    return challengeLateMap;
  },
);
