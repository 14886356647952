import React from 'react';

import ScheduleDateInput from './ScheduleDateInput';
import { EditScheduleCommitState } from './typings';

type ScheduleWeekEditProps = {
  schedule: EditScheduleCommitState;
  onAddHour: (dayHours: string) => () => void;
  onRemoveHour: (dayHours: string) => () => void;
};

const ScheduleWeekEdit = ({
  schedule,
  onAddHour,
  onRemoveHour,
}: ScheduleWeekEditProps) => {
  return (
    <>
      <ScheduleDateInput
        onPressAddHour={onAddHour('mondayHours')}
        onPressRemoveHour={onRemoveHour('mondayHours')}
        key="monday"
        day="Monday"
        dayHours={schedule.mondayHours}
        active={schedule.mondayHours > 0}
      />
      <ScheduleDateInput
        onPressAddHour={onAddHour('tuesdayHours')}
        onPressRemoveHour={onRemoveHour('tuesdayHours')}
        key="tuesday"
        day="Tuesday"
        dayHours={schedule.tuesdayHours}
        active={schedule.tuesdayHours > 0}
      />
      <ScheduleDateInput
        onPressAddHour={onAddHour('wednesdayHours')}
        onPressRemoveHour={onRemoveHour('wednesdayHours')}
        key="wednesday"
        day="Wednesday"
        dayHours={schedule.wednesdayHours}
        active={schedule.wednesdayHours > 0}
      />
      <ScheduleDateInput
        onPressAddHour={onAddHour('thursdayHours')}
        onPressRemoveHour={onRemoveHour('thursdayHours')}
        key="thursday"
        day="Thursday"
        dayHours={schedule.thursdayHours}
        active={schedule.thursdayHours > 0}
      />
      <ScheduleDateInput
        onPressAddHour={onAddHour('fridayHours')}
        onPressRemoveHour={onRemoveHour('fridayHours')}
        key="friday"
        day="Friday"
        dayHours={schedule.fridayHours}
        active={schedule.fridayHours > 0}
      />
      <ScheduleDateInput
        onPressAddHour={onAddHour('saturdayHours')}
        onPressRemoveHour={onRemoveHour('saturdayHours')}
        key="saturday"
        day="Saturday"
        dayHours={schedule.saturdayHours}
        active={schedule.saturdayHours > 0}
      />
      <ScheduleDateInput
        onPressAddHour={onAddHour('sundayHours')}
        onPressRemoveHour={onRemoveHour('sundayHours')}
        key="sunday"
        day="Sunday"
        dayHours={schedule.sundayHours}
        active={schedule.sundayHours > 0}
      />
    </>
  );
};

export default ScheduleWeekEdit;
