import React from 'react';

import localizedStrings from 'localization';
import { ButtonContainer, SaveButton } from 'common/CommonComponents';

type QuizAppletProps = {
  quizIntroduction?: React.ReactNode;
  quizSections: React.ReactNode;
  quizResult?: React.ReactNode;
  showResultsButton?: boolean;
  openResultsModal?: () => void;
  disabled?: boolean;
};

const QuizApplet = ({
  quizIntroduction,
  quizSections,
  quizResult,
  showResultsButton,
  openResultsModal,
  disabled,
}: QuizAppletProps) => {
  return (
    <>
      {quizIntroduction}
      {quizSections}
      {quizResult}
      {!disabled && showResultsButton && openResultsModal ? (
        <ButtonContainer>
          <SaveButton onClick={openResultsModal}>
            {localizedStrings.seeResults}
          </SaveButton>
        </ButtonContainer>
      ) : null}
    </>
  );
};

export default QuizApplet;
