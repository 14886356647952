import React from 'react';

import MadlibApplet from 'components/applets/madlib';
import useContentfulRenderer from 'hooks/useContentfulRenderer';
import { ChallengeApplet } from 'models/ChallengeApplet';

type MadlibAppletSubmissionProps = {
  applet: ChallengeApplet;
};

const MadlibAppletSubmission = ({ applet }: MadlibAppletSubmissionProps) => {
  const renderedMadlibSentence = useContentfulRenderer(applet.madlibSentence, {
    madlibAnswers: applet.madlibAnswers,
  });

  return <MadlibApplet madlibSentence={renderedMadlibSentence} disabled />;
};

export default MadlibAppletSubmission;
