import React from 'react';

import useContentfulRenderer from 'hooks/useContentfulRenderer';
import { HeadlineApplet } from '../typings';
import { ShowcaseSubtitle, ShowcaseTitle } from './ShowcaseHomeComponents';

type ShowcaseHomeItemProps = {
  applet: HeadlineApplet;
};

const ShowcaseHomeItem = ({ applet }: ShowcaseHomeItemProps) => {
  const isBigScreen = window.matchMedia('(min-width: 850px)').matches;
  const renderTitle = useContentfulRenderer(applet.title, {
    darkMode: true,
    additionalStyles: {
      paragraph: {
        fontSize: isBigScreen ? '4rem' : '1.5rem',
        lineHeight: isBigScreen ? '5rem' : '2rem',
      },
    },
  });
  return (
    <>
      <ShowcaseTitle>{renderTitle}</ShowcaseTitle>
      <ShowcaseSubtitle>{applet.body}</ShowcaseSubtitle>
    </>
  );
};

export default ShowcaseHomeItem;
