import styled from '@emotion/styled';

export const CollapsibleContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const CollapsibleSummaryContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const CollapsibleSummaryRightSide = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const CollapsibleProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: ${(props) => props?.theme?.typography.large18};
`;

export const CollapsibleInformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const CollapsibleTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  flex: 1;
`;

type CollapsibleSeparatorProps = {
  color?: string;
};

export const CollapsibleSeparator = styled.div<CollapsibleSeparatorProps>`
  box-sizing: border-box;
  height: 1px;
  border: 1px solid ${(props) => props.color || 'rgba(199, 199, 204, 0.3)'};
`;

type CollapsibleDetailProps = {
  collapsed: boolean;
};

export const CollapsibleDetail = styled.div<CollapsibleDetailProps>`
  display: flex;
  flex-direction: row;
  max-height: ${(props) => (props.collapsed ? '2.8rem' : '100%')};
  overflow: hidden;
  transition: max-height 0.5s cubic-bezier(0.4, 0, 0.2, 1) 0ms;
`;

export const DashedLineContainer = styled.div`
  display: flex;
  justify-content: center;
  width: ${(props) => props?.theme?.typography.large32};
  margin-right: ${(props) => props?.theme?.typography.large18};
  overflow: hidden;
`;

export const HidingContainer = styled.div`
  overflow: hidden;
  border-radius: 4px;
`;

export const FullDashContainer = styled.div`
  width: 4px;
  overflow: hidden;
  min-height: 10rem;
  max-height: 100%;
`;

type DetailContentProps = {
  collapsed: boolean;
};

export const DetailContent = styled.div<DetailContentProps>`
  display: flex;
  flex-direction: column;
  flex: 1;
  opacity: ${(props) => (props.collapsed ? '0%' : '100%')};
  transition: opacity 0.5s cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  padding: ${(props) => props?.theme?.typography.large18} 0;
  pointer-events: ${(props) => (props.collapsed ? 'none' : 'auto')}; ;
`;
