import * as React from 'react';

type CheckProps = {
  color?: string;
};

const Check = ({ color = '#8E8E93' }: CheckProps) => {
  return (
    <svg width="24px" height="24px" viewBox="0 0 24 24">
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd">
        <g id="Icons/24/Check" fill={color}>
          <path
            d="M10.7753906,18.5170898 C11.090332,18.5170898 11.3393555,18.3779297 11.5151367,18.1069336 L18.4365234,7.20849609 C18.5683594,6.99609375 18.6196289,6.83496094 18.6196289,6.66650391 C18.6196289,6.26367188 18.355957,6 17.953125,6 C17.6601562,6 17.4990234,6.09521484 17.3232422,6.37353516 L10.7460938,16.8544922 L7.33300781,12.3867188 C7.14990234,12.1303711 6.96679688,12.027832 6.703125,12.027832 C6.28564453,12.027832 6,12.3134766 6,12.7163086 C6,12.8847656 6.07324219,13.0751953 6.21240234,13.2509766 L10.0136719,18.0922852 C10.2333984,18.3779297 10.4604492,18.5170898 10.7753906,18.5170898 Z"
            id="Mask"
          />
        </g>
      </g>
    </svg>
  );
};

export default Check;
