import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch } from 'redux/typings';
import localizedStrings from 'localization';
import { deleteInvo } from 'modules/relationships/actions';
import { selectDisplayName } from 'modules/auth/selectors';
import { selectRunningPartner } from 'modules/relationships/selectors';
import {
  DoneButton,
  SwitchRunningPartnerDescription,
  SwitchRunningPartnerModalContainer,
  SwitchRunningPartnerPromp,
} from 'modules/relationships/components';
import Modal from 'components/modal';
import RadioButton from './RadioButton';

type SwitchRunningPartnerModalProps = {
  open: boolean;
  closeModal: () => void;
  openAddRunningPartner: () => void;
};

type SwitchOptions = {
  [key: string]: { text: string; value: string; notification: string };
};

const switchOptions: SwitchOptions = {
  unresponsive: {
    text: localizedStrings.runningPartnerIsUnresponsive,
    value: 'unresponsive',
    notification: localizedStrings.runningPartnerIsUnresponsiveNotification,
  },
  notHelpful: {
    text: localizedStrings.runningPartnerIsNotHelpful,
    value: 'notHelpful',
    notification: localizedStrings.runningPartnerIsNotHelpfulNotification,
  },
  newPartner: {
    text: localizedStrings.foundNewRunningPartner,
    value: 'newPartner',
    notification: localizedStrings.foundNewRunningPartnerNotification,
  },
};

const SwitchRunningPartnerModal = ({
  open,
  closeModal,
  openAddRunningPartner,
}: SwitchRunningPartnerModalProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const [selectedOption, setSelectedOption] = useState('');
  const runningPartner = useSelector(selectRunningPartner);
  const displayName = useSelector(selectDisplayName);

  const handleOptionClick = useCallback((value: string) => {
    setSelectedOption(value);
  }, []);

  const handleDoneClick = useCallback(() => {
    if (!runningPartner || !displayName) {
      return;
    }

    dispatch(deleteInvo({ relationshipId: runningPartner.relationshipId }));
    openAddRunningPartner();
  }, [runningPartner, displayName, dispatch, openAddRunningPartner]);

  return (
    <Modal
      open={open}
      closeModal={closeModal}
      width={'516px'}
      title={localizedStrings.switchRunningPartner}>
      <SwitchRunningPartnerModalContainer>
        <SwitchRunningPartnerDescription>
          {localizedStrings.switchRunningPartnerDescription}
        </SwitchRunningPartnerDescription>
        <SwitchRunningPartnerPromp>
          {localizedStrings.switchRunningPartnerPrompt}
        </SwitchRunningPartnerPromp>
        <RadioButton
          text={switchOptions.unresponsive.text}
          onClick={handleOptionClick}
          value={switchOptions.unresponsive.value}
          selectedValue={selectedOption}
        />
        <RadioButton
          text={switchOptions.notHelpful.text}
          onClick={handleOptionClick}
          value={switchOptions.notHelpful.value}
          selectedValue={selectedOption}
        />
        <RadioButton
          text={switchOptions.newPartner.text}
          onClick={handleOptionClick}
          value={switchOptions.newPartner.value}
          selectedValue={selectedOption}
        />
        <DoneButton onClick={handleDoneClick}>
          {localizedStrings.done}
        </DoneButton>
      </SwitchRunningPartnerModalContainer>
    </Modal>
  );
};

export default SwitchRunningPartnerModal;
