import React from 'react';

import Modal from 'components/modal';
import localizedStrings from 'localization';
import {
  DialogConfirmButtons,
  DialogConfirmPrompt,
  DialogNoButton,
  DialogYesButton,
} from './components';

type DialogProps = {
  open?: boolean;
  closeModal?: () => void;
  title: string;
  prompt?: string;
  onYesClick: () => void;
  onNoClick?: () => void;
  yesText?: string;
  noText?: string;
  width?: string
};

const Dialog = ({
  open = false,
  closeModal = () => { },
  title,
  prompt,
  onYesClick,
  onNoClick,
  yesText = localizedStrings.yes,
  noText = localizedStrings.no,
  width = "30rem"
}: DialogProps) => {
  return (
    <Modal
      open={open}
      closeModal={closeModal}
      width={width}
      hideCloseButton
      title={title}>
      {prompt && <DialogConfirmPrompt>{prompt}</DialogConfirmPrompt>}
      <DialogConfirmButtons>
        {onNoClick && <DialogNoButton onClick={onNoClick}>{noText}</DialogNoButton>}
        <DialogYesButton onClick={onYesClick}>{yesText}</DialogYesButton>
      </DialogConfirmButtons>
    </Modal>
  );
};

export default Dialog;
