import { createSelector } from '@reduxjs/toolkit';
import { addDays } from 'date-fns';
import { formatUTCDate } from 'helpers/dateHelpers';
import { RootState } from 'redux/typings';
import { selectWeeksContentful } from '../contentful/weeks/selectors';
import {
  selectActiveQuestContentful,
  selectActiveQuestWeekIds,
} from '../quests/selectors';
import { selectContents } from '../contents/selectors';
import { calculateProgress } from 'helpers/helperFunctions';
import { selectContentsContentful } from '../contentful/contents/selectors';

const FIRST_WEEK_POSITION = 0

const selectWeeksState = (state: RootState) => state.weeks;

export const selectWeeksHash = createSelector(
  selectWeeksState,
  (weekState) => weekState.weeks,
);

export const selectAvailableWeeks = createSelector(
  selectWeeksState,
  (weekState) => Object.values(weekState.weeks),
);

export const selectWeeksSelected = createSelector(
  selectWeeksState,
  (weekState) => weekState.weeksSelected || [0],
);

export const selectWeeksSelectedId = createSelector(
  selectWeeksState,
  (weekState) => weekState.selectedId,
);

export const selectAvailableWeeksIds = createSelector(
  selectWeeksState,
  (weekState) => Object.keys(weekState.weeks),
);

export const selectAvailableWeekNumbers = createSelector(
  selectAvailableWeeks,
  selectWeeksContentful,
  (weeks, weeksContentful) =>
    weeks.map((week) => weeksContentful[week.contentfulId].weekNumber),
);

export const selectSelectedWeek = createSelector(
  selectWeeksState,
  (weekState) =>
    weekState.selectedId ? weekState.weeks[weekState.selectedId] : undefined,
);

const selectSelectedWeekContentful = createSelector(
  selectSelectedWeek,
  selectWeeksContentful,
  (week, weeksContentful) =>
    week ? weeksContentful[week.contentfulId] : undefined,
);

export const selectSelectedWeekTitle = createSelector(
  selectSelectedWeekContentful,
  (weekContentful) => weekContentful?.title,
);

export const selectSelectedWeekStartingDate = createSelector(
  selectSelectedWeek,
  (week) => (week ? new Date(week.startingDate) : undefined),
);

export const selectSelectedWeekEndDate = createSelector(
  selectSelectedWeekStartingDate,
  (startingDate) => {
    if (startingDate) {
      return addDays(startingDate, 6);
    }
    return undefined;
  },
);

export const selectSelectedWeekDays = createSelector(
  selectSelectedWeekStartingDate,
  (startingDate) => {
    if (startingDate) {
      return [...Array(7).keys()].map((index) =>
        formatUTCDate(addDays(startingDate, index)),
      );
    }
    return [];
  },
);

export const selectSelectedWeekId = createSelector(
  selectSelectedWeek,
  (week) => week?.id,
);

export const selectWeekNameById = (weekId: string) =>
  createSelector(
    selectWeeksState,
    selectWeeksContentful,
    (weeksState, weeksContentful) => {
      const contentfulId = weeksState.weeks[weekId]?.contentfulId;
      return weeksContentful[contentfulId]?.title;
    },
  );

export const selectHasCompletedWeek = createSelector(
  selectWeeksState,
  (weeksState) => weeksState.hasCompletedWeek ?? false,
);

export const selectWeeksProgress = createSelector(
  selectActiveQuestContentful,
  selectAvailableWeeks,
  selectContents,
  selectContentsContentful,
  selectWeeksContentful,
  (
    questContentful,
    availableWeeks,
    contents,
    contentsContenful,
    weeksContentful,
  ) => {
    const ret: {
      progress: number;
      weekNumber: number;
      weekName: string;
    }[] = [];
    for (const weekId of questContentful?.weekIds || []) {
      const week = availableWeeks.find((week) => week.contentfulId === weekId);
      const weekNumber = weeksContentful[weekId]?.weekNumber || 0;
      const weekName = weeksContentful[weekId]?.weekName || '';

      if (week) {
        const filteredContentIds = week.contentIds.filter(
          (id) => !contentsContenful[contents[id].contentfulId].bonusChallenge,
        );
        const weekContentsLength = filteredContentIds.length;
        const progressSum = filteredContentIds.reduce((acc, contentId) => {
          const content = contents[contentId];
          return acc + calculateProgress(content);
        }, 0);
        const progress =
          weekContentsLength > 0 ? progressSum / weekContentsLength : 0;
        ret.push({ progress, weekNumber, weekName });
      } else {
        ret.push({ progress: 0, weekNumber, weekName });
      }
    }
    return ret;
  },
);

export const selectIsContentUnlocked = createSelector(
  selectAvailableWeeks,
  selectWeeksContentful,
  (availableWeeks, weeksContentful) =>
    availableWeeks.every(week => weeksContentful[week?.contentfulId]?.unlockAfterDays === 0)
);

export const selectLastAvailableWeekIdx = createSelector(
  selectAvailableWeeks,
  selectIsContentUnlocked,
  (availableWeeks, isContentUnlocked) =>
    isContentUnlocked ? FIRST_WEEK_POSITION : availableWeeks.length - 1
);
export const selectLastAvailableWeek = createSelector(
  selectAvailableWeeks,
  selectLastAvailableWeekIdx,
  (availableWeeks, lastWeekIdx) =>
    lastWeekIdx > -1 ? availableWeeks[lastWeekIdx] : undefined,
);

export const selectCompletedWeekId = createSelector(
  selectWeeksState,
  (weeksState) => weeksState.completedWeekId,
);

export const selectCompletedWeek = createSelector(
  selectAvailableWeeks,
  selectCompletedWeekId,
  (availableWeeks, completedWeekId) =>
    availableWeeks.find((week) => week.id === completedWeekId),
);

export const selectFirstWeek = createSelector(
  selectActiveQuestWeekIds,
  selectWeeksHash,
  selectWeeksContentful,
  (activeQuestWeekIds, weeksHash, weeksContentful) => {
    let minimumWeekId = '';
    let minimumWeekNumber = 1000;
    for (const weekId of activeQuestWeekIds) {
      const week = weeksHash[weekId];
      if (week) {
        const weekContentful = weeksContentful[week.contentfulId];
        if (weekContentful && weekContentful.weekNumber < minimumWeekNumber) {
          minimumWeekId = weekId;
          minimumWeekNumber = weekContentful.weekNumber;
        }
      }
    }
    return weeksHash[minimumWeekId];
  },
);

export const selectHasCompletedAllActiveQuestWeeks = createSelector(
  selectActiveQuestWeekIds,
  selectWeeksHash,
  (availableWeekIds, weeksHash) =>
    availableWeekIds.every((weekId) => Boolean(weeksHash[weekId]?.completedAt)),
);
