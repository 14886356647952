import React from 'react';

import RandomSelectionOption from 'components/applets/random-selection-option';
import { QuestApplet } from 'models/QuestApplet';
import RandomSelectionApplet from 'components/applets/random-selection';
import useRandomSelectionOptions from 'hooks/useRandomSelectionOptions';

type RandomSelectionAppletProfileProps = {
  applet: QuestApplet;
};

const RandomSelectionAppletProfile = ({
  applet,
}: RandomSelectionAppletProfileProps) => {
  const options = useRandomSelectionOptions(
    applet.optionsContentful,
    applet.options,
  );
  return (
    <RandomSelectionApplet
      options={options.map((opt) => (
        <RandomSelectionOption
          disabled
          option={opt}
          selected={applet?.text === opt.id}
          key={opt.id}
        />
      ))}
      disabled
      key={applet.id}
    />
  );
};

export default RandomSelectionAppletProfile;
