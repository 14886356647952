import React from 'react';
import { useSelector } from 'react-redux';

import localizedStrings from 'localization';
import {
  selectDisplayName,
  selectProfilePictureUrl,
} from 'modules/auth/selectors';
import {
  InvitationAcceptedContainer,
  InvitationAcceptedImage,
  PairedUsersContainer,
  InvitationAcceptedTitle,
  InvitationAcceptedDescription,
  InvitationAcceptedButton,
} from 'modules/relationships/components';
import Avatar from 'components/avatar';
import { ModalContainer } from 'components/modal/components';
import art from 'assets/images/Art.webp';
import { RelationshipType } from 'models/Relationship.d';

type InvitationAcceptedModalProps = {
  open: boolean;
  closeModal: () => void;
  pairedUserDisplayName?: string;
  pairedUserProfilePicture?: string | null;
  pairedUserRelationshipType?: RelationshipType;
};

const InvitationAcceptedModal = ({
  open,
  closeModal,
  pairedUserDisplayName = '',
  pairedUserProfilePicture,
  pairedUserRelationshipType = RelationshipType.RUNNING_PARTNER,
}: InvitationAcceptedModalProps) => {
  const userDisplayName = useSelector(selectDisplayName);
  const userProfilePicture = useSelector(selectProfilePictureUrl);

  return (
    <ModalContainer visible={open}>
      <InvitationAcceptedContainer>
        <InvitationAcceptedImage src={art} />
        <PairedUsersContainer>
          <Avatar
            displayName={userDisplayName ?? ''}
            uri={userProfilePicture}
            size={100}
          />
          <Avatar
            displayName={pairedUserDisplayName}
            uri={pairedUserProfilePicture}
            size={100}
          />
        </PairedUsersContainer>
        <InvitationAcceptedTitle>
          {pairedUserRelationshipType === RelationshipType.RUNNING_PARTNER ||
            pairedUserRelationshipType ===
            RelationshipType.EXTERNAL_RUNNING_PARTNER
            ? localizedStrings.pairedWithRunningPartnerTitle
            : localizedStrings.mentoringFellowTravelerTitle}
        </InvitationAcceptedTitle>
        <InvitationAcceptedDescription>
          {pairedUserDisplayName}{' '}
          {pairedUserRelationshipType === RelationshipType.RUNNING_PARTNER ||
            pairedUserRelationshipType ===
            RelationshipType.EXTERNAL_RUNNING_PARTNER
            ? localizedStrings.pairedWithRunningPartnerSubtitle
            : localizedStrings.mentoringFellowTravelerSubtitle}
        </InvitationAcceptedDescription>
        <InvitationAcceptedButton onClick={closeModal}>
          {pairedUserRelationshipType === RelationshipType.RUNNING_PARTNER ||
            pairedUserRelationshipType ===
            RelationshipType.EXTERNAL_RUNNING_PARTNER
            ? localizedStrings.pairedWithRunningPartnerBtnText
            : localizedStrings.mentoringFellowTravelerBtnText}
        </InvitationAcceptedButton>
      </InvitationAcceptedContainer>
    </ModalContainer>
  );
};

export default InvitationAcceptedModal;
