import React from 'react';
import { useSelector } from 'react-redux';

import { selectShowcaseTitle } from 'modules/showcase/selectors';

import {
  ShowCaseVideo,
  ShowCaseVideoContainer,
  ShowCaseVideoTitle,
} from '../ShowcaseResumeComponents';

type ResumeVideoProps = {
  videoSource: string;
};

const ResumeVideo = ({ videoSource }: ResumeVideoProps) => {
  const showcaseTitle = useSelector(selectShowcaseTitle);
  return (
    <ShowCaseVideoContainer>
      <ShowCaseVideo loop autoPlay controls>
        <source src={videoSource} type="video/mp4" />
      </ShowCaseVideo>
      <ShowCaseVideoTitle>{showcaseTitle}</ShowCaseVideoTitle>
    </ShowCaseVideoContainer>
  );
};

export default ResumeVideo;
