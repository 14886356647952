import { ScheduleMap } from 'models/Schedule';

export const updateMapCompletedStep = (
  map: ScheduleMap,
  weekId: string,
  stepId: string,
  dateString?: string,
) => {
  if (!dateString) {
    return {
      ...map,
    };
  }
  return Object.assign({}, map, {
    [weekId]: Object.assign({}, map[weekId], {
      [dateString]: Object.assign({}, map[weekId][dateString], {
        [stepId]: Object.assign({}, map[weekId][dateString][stepId], {
          completed: true,
        }),
      }),
    }),
  });
};

export const updateMapWeeks = (map: ScheduleMap, updatedWeeks: ScheduleMap) => {
  let ret = map;
  const updatedWeekIds = Object.keys(updatedWeeks);
  for (const weekId of updatedWeekIds) {
    ret = Object.assign({}, ret, {
      [weekId]: updatedWeeks[weekId],
    });
  }
  return ret;
};
