import styled from '@emotion/styled';

export const DraggableCarouselContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  user-select: none;
  overflow-x: scroll;
  overflow-y: hidden;
  ::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
  -ms-overflow-style: none;
`;
