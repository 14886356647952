import { createAsyncThunk } from '@reduxjs/toolkit';

import { getQuestsOffboardings as getQuestsOffboardingsService } from './services';
import { QuestOffboarding } from 'models/QuestOffboarding';

const GET_QUESTS_OFFBOARDINGS = 'GET_QUESTS_OFFBOARDINGS';
export const getQuestsOffboardings = createAsyncThunk<
  QuestOffboarding[],
  string | undefined
>(GET_QUESTS_OFFBOARDINGS, getQuestsOffboardingsService);
