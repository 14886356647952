import {
  ShareStepEmailPayload,
  ShareStepModalInformation,
} from 'modules/applets/typings';

export const shareStepInitialValues = (args?: ShareStepModalInformation) =>
  ({
    receiver: '',
    subject: '',
    message: '',
    stepId: args?.stepId || '',
    applets: args?.applets,
  } as ShareStepEmailPayload);
