import { AppletContentful } from 'models/AppletContentful';
import { baseService } from 'modules/baseService';
import HttpClient from 'services/HttpClient';
import { getLastUpdatedAtQuery } from 'helpers/helperFunctions';

export const getApplets = baseService<string | undefined, AppletContentful[]>(
  (lastUpdatedAt) =>
    HttpClient.get(
      `/contentful/applets${getLastUpdatedAtQuery(lastUpdatedAt)}`,
    ),
);
