import { createReducer } from '@reduxjs/toolkit';
import { getSchedules } from './actions';
import { SchedulesState } from './typings';
import { submitPost } from 'modules/loading-screen/contents/actions';
import {
  updateMapCompletedStep,
  updateMapWeeks,
} from 'helpers/SchedulesHelpers';
import { editSchedule } from '../schedule-commit/actions';
import { completeStep } from '../contents/actions';

const initialState: SchedulesState = {
  map: undefined,
};

export default createReducer<SchedulesState>(initialState, (builder) =>
  builder
    .addCase(getSchedules.fulfilled, (state, action) => ({
      ...state,
      map: action.payload,
    }))
    .addCase(
      completeStep.fulfilled,
      (
        state,
        {
          meta: {
            arg: { weekId, stepScheduledDate, stepId },
          },
        },
      ) => ({
        ...state,
        map: updateMapCompletedStep(
          state.map!,
          weekId,
          stepId,
          stepScheduledDate,
        ),
      }),
    )
    .addCase(
      submitPost.fulfilled,
      (
        state,
        {
          meta: {
            arg: { weekId, postScheduledDay, postId },
          },
        },
      ) => ({
        ...state,
        map: updateMapCompletedStep(
          state.map!,
          weekId,
          postId,
          postScheduledDay,
        ),
      }),
    )
    .addCase(editSchedule.fulfilled, (state, action) => ({
      ...state,
      map: updateMapWeeks(state.map!!, action.payload),
    })),
);
