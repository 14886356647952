import React, { useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { PreviewContainer } from 'components/components';

type TxtPreviewProps = {
  file: { uri: string; type: string; name: string };
};

const TxtPreview = ({ file }: TxtPreviewProps) => {
  const [fileText, setFileText] = useState('');

  useEffect(() => {
    const fetchTxt = async () => {
      const response = await axios.get(file.uri);
      setFileText(response.data);
    };
    fetchTxt();
  }, [file.uri]);

  const renderText = useMemo(() => {
    const lines = fileText.split(/\r\n?|\n/);
    return lines.map((line, index) =>
      line ? (
        <div key={`${file.uri}_${index}`}>{line}</div>
      ) : (
        <br key={`${file.uri}_${index}`} />
      ),
    );
  }, [file, fileText]);

  return <PreviewContainer>{renderText}</PreviewContainer>;
};

export default TxtPreview;
