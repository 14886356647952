import styled from '@emotion/styled';
import { Select } from '@material-ui/core';

type MadlibNonEditableTextProps = {
  value?: string;
};

export const MadlibNonEditableText = styled.span<MadlibNonEditableTextProps>`
  color: ${(props) => props?.theme?.colors.grey[100]};
  font-weight: ${(props) => (props.value ? 600 : 'normal')};
  font-style: ${(props) => (props.value ? 'italic' : 'normal')};
  word-break: break-word;
`;

export const MadlibNonEditablePlaceholderText = styled.span<MadlibNonEditableTextProps>`
  color: ${(props) => props?.theme?.colors.grey[90]};
  font-weight: 400;
  font-style: normal;
  text-decoration: underline;
  text-underline-position: under;
`;

export const MadlibInputShort = styled.input`
  font-size: ${(props) => props?.theme?.typography.large18};
  line-height: ${(props) => props?.theme?.typography.large24};
  border: 1px solid ${(props) => props?.theme?.colors.grey[90]};
  border-radius: 0.7rem;
  padding: 0.5rem 0.7rem 0.5rem 0.8rem;
  margin-bottom: 0.5rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  &:focus {
    outline: none;
    border: 1px solid ${(props) => props?.theme?.colors.grey[90]};
  }
  ::placeholder {
    color: ${(props) => props?.theme?.colors.grey[90]};
    opacity: 1;
  }
  &:disabled {
    background-color: ${(props) => props?.theme?.colors.grey[80]};
    color: ${(props) => props?.theme?.colors.grey[90]};
  }
`;

export const MadlibSelection = styled(Select)`
  border: 1px solid ${(props) => props?.theme?.colors.grey[90]};
  min-width: 10rem;
  border-radius: 0.7rem;
  padding: 0.5rem 0.7rem 0.5rem 0.8rem;
  margin-bottom: 0.5rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;

  & .MuiInputBase-input {
    padding: 0px 0px 0px 0px;
  }

  .MuiInputBase-root&.Mui-disabled {
    background-color: ${(props) => props?.theme?.colors.grey[80]};
    color: ${(props) => props?.theme?.colors.grey[90]};
  }
`;

export const MadlibSelectionPlaceholder = styled.div`
  font-size: ${(props) => props?.theme?.typography.large18};
  line-height: ${(props) => props?.theme?.typography.large24};
  color: ${(props) => props?.theme?.colors.grey[90]};
`;
