import React, { useCallback } from 'react';
import { useState } from 'react';

import { RelationshipType } from 'models/Relationship.d';
import AddModal, { AddOptions } from './AddModal';
import AddRelationshipModal from './AddRelationshipModal';
import InvitationAcceptedModal from './InvitationAcceptedModal';
import InvitationSentModal from './InvitationSentModal';
import InviteSomeoneElseModal from './InviteSomeoneElseModal';
import SwitchRunningPartnerModal from './SwitchRunningPartnerModal';

export type ModalState = {
  addModalOpen?: boolean;
  addRelationshipOpen?: boolean;
  inviteSomeoneElseOpen?: boolean;
  switchRunningPartnerOpen?: boolean;
  invitationSentOpen?: boolean;
  invitedUserName?: string;
  invitationAcceptedOpen?: boolean;
  pairedUserDisplayName?: string;
  pairedUserProfilePicture?: string | null;
  pairedUserRelationshipType?: RelationshipType;
};

type RelationshipModalsProps = {
  modalState: ModalState;
  setModalState: React.Dispatch<React.SetStateAction<ModalState>>;
};

const RelationshipModals = ({
  modalState,
  setModalState,
}: RelationshipModalsProps) => {
  const [optionSelected, setOptionSelected] = useState<AddOptions>();

  const closeModal = useCallback(() => {
    setModalState({});
  }, [setModalState]);

  const handleOptionSelected = useCallback(
    (option: AddOptions) => {
      setOptionSelected(option);
      switch (option) {
        case AddOptions.ADD_RUNNING_PARTNER:
          setModalState({ addRelationshipOpen: true });
          break;
        case AddOptions.ADD_MENTOR:
          setModalState({ addRelationshipOpen: true });
          break;
        case AddOptions.SWITCH_RUNNING_PARTNER:
          setModalState({ switchRunningPartnerOpen: true });
          break;
        default:
          break;
      }
    },
    [setModalState],
  );

  const handleInviteSent = useCallback(
    (invitedUserName: string) => {
      setModalState({ invitationSentOpen: true, invitedUserName });
    },
    [setModalState],
  );

  const handleAddSomeoneElse = useCallback(() => {
    setModalState({ inviteSomeoneElseOpen: true });
  }, [setModalState]);

  const openAddRunningPartner = useCallback(() => {
    setOptionSelected(AddOptions.ADD_RUNNING_PARTNER);
    setModalState({ addRelationshipOpen: true });
  }, [setModalState]);

  return (
    <>
      <AddModal
        open={modalState.addModalOpen ?? false}
        closeModal={closeModal}
        onOptionClick={handleOptionSelected}
      />
      <AddRelationshipModal
        open={modalState.addRelationshipOpen ?? false}
        closeModal={closeModal}
        option={optionSelected}
        onInvitationSent={handleInviteSent}
        onAddSomeoneElseClick={handleAddSomeoneElse}
      />
      <InviteSomeoneElseModal
        open={modalState.inviteSomeoneElseOpen ?? false}
        closeModal={closeModal}
        option={optionSelected}
        onInvitationSent={handleInviteSent}
      />
      <SwitchRunningPartnerModal
        open={modalState.switchRunningPartnerOpen ?? false}
        closeModal={closeModal}
        openAddRunningPartner={openAddRunningPartner}
      />
      <InvitationSentModal
        open={modalState.invitationSentOpen ?? false}
        closeModal={closeModal}
        option={optionSelected}
        invitedUserName={modalState.invitedUserName}
      />
      <InvitationAcceptedModal
        open={modalState.invitationAcceptedOpen ?? false}
        closeModal={closeModal}
        pairedUserDisplayName={modalState.pairedUserDisplayName}
        pairedUserProfilePicture={modalState.pairedUserProfilePicture}
        pairedUserRelationshipType={modalState.pairedUserRelationshipType}
      />
    </>
  );
};

export default RelationshipModals;
