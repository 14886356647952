import styled from '@emotion/styled';
import ButtonPrimary from 'components/button-primary';
import { ScrollableArea, ScrollablePanel } from 'components/components';
import InputField from 'components/input-field';
import { Field, Form } from 'formik';

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const UserProfileSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`;

export const UserAvatarContainer = styled.div`
  position: relative;
`;

export const EditButton = styled.div`
  position: absolute;
  bottom: 0px;
  right: 3px;
  cursor: pointer;
`;

export const LoadingOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const UserName = styled.div`
  font-size: ${(props) => props?.theme?.typography.large24};
  line-height: ${(props) => props?.theme?.typography.large32};
  font-weight: ${(props) => props?.theme?.typography.semiBold};
  color: ${(props) => props?.theme?.colors.blue1[100]};
  margin-top: ${(props) => props?.theme?.typography.large24};
`;

export const QuestSectionTitleQuest = styled.div`
  color: ${(props) => props?.theme?.colors.blue3[80]};
  font-size: ${(props) => props?.theme?.typography.large24};
  line-height: ${(props) => props?.theme?.typography.large32};
  font-weight: ${(props) => props?.theme?.typography.semiBold};
  margin-bottom: 20px;
`;

export const QuestSectionTitle = styled.div`
  color: ${(props) => props?.theme?.colors.blue2[100]};
  font-size: ${(props) => props?.theme?.typography.large18};
  line-height: ${(props) => props?.theme?.typography.large24};
`;

export const QuestSectionApplets = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  grid-auto-rows: minmax(100px, auto);
`;

export const ProfileSection = styled.div`
  display: flex;
  flex-direction: column;
  margin: 41px 10px 0;
`;

export const ProfileSectionDashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 0 20px;
`;
export const ProfileSectionContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 2;
`;

export const ProfileSectionTitle = styled.div`
  color: ${(props) => props?.theme?.colors.blue1[100]};
  font-size: ${(props) => props?.theme?.typography.large24};
  font-weight: ${(props) => props?.theme?.typography.semiBold};
  line-height: ${(props) => props?.theme?.typography.large32};
  margin-bottom: 20px;
`;

export const ProfileSectionPanel = styled(ScrollablePanel)`
  margin-bottom: 20px;
  padding: 25px;
  max-height: calc(100vh - 20rem);
`;

export const ExperienceSummaryContainer = styled.div`
  display: flex;
  align-items: center;
  align-self: center;
  height: 50px;
  border-radius: 15px;
  background-color: #673ab7;
  margin-top: 16px;
`;

export const ExperienceMedal = styled.div`
  align-self: flex-end;
  margin-left: -24px;
  margin-bottom: -1px;
  transform-style: preserve-3d;
  transition: all 0.5s linear;
  transform: rotateY(0deg);
  &:hover {
    transform-style: preserve-3d;
    transition: all 0.5s linear;
    transform: rotateY(180deg);
  }
`;

export const ExperienceTotal = styled.div`
  color: #ffffff;
  font-size: ${(props) => props?.theme?.typography.large20};
  font-weight: ${(props) => props?.theme?.typography.semiBold};
  line-height: ${(props) => props?.theme?.typography.large18};
  margin-left: 12px;
`;

export const ExperienceSeparator = styled.div`
  height: 1rem;
  width: 1px;
  border-radius: 0.5px;
  background-color: rgba(255, 255, 255, 0.3);
  margin-left: 17px;
`;

export const ExperienceBp = styled.div`
  color: #ffffff;
  font-size: ${(props) => props?.theme?.typography.large20};
  font-weight: 300;
  line-height: ${(props) => props?.theme?.typography.large24};
  margin-left: 17px;
`;

export const ExperienceXp = styled.div`
  color: #ffffff;
  font-size: ${(props) => props?.theme?.typography.large20};
  font-weight: 300;
  line-height: ${(props) => props?.theme?.typography.large24};
  margin-left: 17px;
  margin-right: 17px;
`;

export const AchievementCardContainer = styled.button`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  border: 0.5px solid #efeff4;
  border-radius: 12px;
  background-color: #ffffff;
  box-shadow: 0 0 12px 0 rgba(26, 26, 26, 0.08);
  margin-top: 20px;
  padding: 10px;
`;

export const AchievementCardImage = styled.img`
  height: 96px;
  width: 96px;
  border-radius: 12px;
  object-fit: cover;
`;

export const AchievementCardTitleAndRating = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  padding: 0 1rem 0 1rem;
  text-align: left;
`;

export const AchievementCardTitle = styled.div`
  color: ${(props) => props?.theme?.colors.grey[100]};
  font-size: ${(props) => props?.theme?.typography.large18};
  font-weight: ${(props) => props?.theme?.typography.semiBold};
  line-height: ${(props) => props?.theme?.typography.large24};
`;

export const AchievementCardSubTitle = styled.div`
  color: ${(props) => props?.theme?.colors.grey[100]};
  font-size: ${(props) => props?.theme?.typography.large16};
  font-weight: 300;
  line-height: ${(props) => props?.theme?.typography.large20};
  margin-top: 5px;
`;

export const AchievementCardRating = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
`;

export const AchievementCardReviews = styled.div`
  color: ${(props) => props?.theme?.colors.grey[100]};
  font-size: ${(props) => props?.theme?.typography.large16};
  font-weight: 300;
  line-height: ${(props) => props?.theme?.typography.large20};
  margin-left: 18px;
`;

export const AchievementCardMedal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const AchievementCardExperience = styled.div`
  color: ${(props) => props?.theme?.colors.grey[100]};
  font-size: ${(props) => props?.theme?.typography.large16};
  line-height: ${(props) => props?.theme?.typography.large20};
`;

export const ReviewsModalChallengeContainer = styled.div`
  display: flex;
  margin-bottom: 40px;
`;

export const ReviewsModalChallengeImage = styled.img`
  height: 100px;
  width: 100px;
  border-radius: 12px;
  object-fit: cover;
`;

export const ReviewsModalChallengeTitleAndRating = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 23px;
`;

export const ReviewsModalChallengeTitle = styled.div`
  color: ${(props) => props?.theme?.colors.grey[100]};
  font-size: ${(props) => props?.theme?.typography.large24};
  line-height: ${(props) => props?.theme?.typography.large32};
  font-weight: ${(props) => props?.theme?.typography.semiBold};
`;

export const ReviewsModalSubTitle = styled.div`
  color: ${(props) => props?.theme?.colors.grey[100]};
  font-size: ${(props) => props?.theme?.typography.large16};
  line-height: ${(props) => props?.theme?.typography.large20};
  margin-bottom: 1rem;
`;

export const ReflectAnswers = styled.div`
  color: ${(props) => props?.theme?.colors.grey[100]};
  font-size: ${(props) => props?.theme?.typography.large16};
  line-height: ${(props) => props?.theme?.typography.large20};
  margin-bottom: 3rem;
`;

export const ReviewsModalList = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FeedbackContainer = styled.div`
  display: flex;
  margin-bottom: 0.5rem;
`;

export const FeedbackSeparator = styled.div`
  height: 2px;
  background-color: ${(props) => props?.theme?.colors.grey[80]};
  margin-bottom: 1rem;
`;

export const FeedbackUserAndComment = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 15px;
`;

export const FeedbackUsername = styled.div`
  color: ${(props) => props?.theme?.colors.grey[100]};
  font-size: ${(props) => props?.theme?.typography.large18};
  line-height: ${(props) => props?.theme?.typography.large24};
  font-weight: ${(props) => props?.theme?.typography.semiBold};
`;

export const FeedbackUserComment = styled.div`
  color: #8e8e93;
  font-size: ${(props) => props?.theme?.typography.large16};
  line-height: ${(props) => props?.theme?.typography.large20};
`;

export const ImageAppletPreview = styled.img`
  height: 100%;
  width: 100%;
  border-radius: 12px;
  object-fit: cover;
`;

export const VideoAppletPreview = styled.video`
  height: 100%;
  width: 100%;
  border-radius: 12px;
  object-fit: cover;
`;

type AppletPreviewButtonProps = {
  height: string;
  width: string;
};

type AppletPreviewContainerProps = {
  width: string;
  margin: string;
};

export const AppletPreviewContainer = styled.div<AppletPreviewContainerProps>`
  display: flex;
  flex-direction: column;
  width: ${(props) => props.width};
  margin: ${(props) => props.margin};
`;

export const ContainerIcon = styled.div`
  width: 100%;
`;

export const StackContainerIcon = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 20px;
`;

export const ButtonIcon = styled.div`
  margin: 0 5px;
  &:hover {
    cursor: pointer;
  }
`;

export const AppletPreviewButton = styled.button<AppletPreviewButtonProps>`
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  border: none;
  padding: 0;
  background-color: transparent;
  position: relative;
`;

export const AppletPreviewTitle = styled.div`
  color: ${(props) => props?.theme?.colors.grey[100]};
  font-size: ${(props) => props?.theme?.typography.large16};
  line-height: ${(props) => props?.theme?.typography.large20};
  margin-top: 15px;
  text-align: left;
`;

export const AppletPreviewSelected = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 12px;
  height: 100%;
  width: 100%;
`;

export const AppletPreviewSelectedIcon = styled.img`
  position: absolute;
  top: 13px;
  right: 16px;
`;

type PreviewCardProps = { backgroundUrl?: string };

export const PreviewCard = styled.div<PreviewCardProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 10px;
  border-radius: 12px;
  color: ${(props) => props?.theme?.colors.white[100]};
  font-size: ${(props) => props?.theme?.typography.large18};
  line-height: ${(props) => props?.theme?.typography.large24};
  background-color: ${(props) => props?.theme?.colors.grey[90]};
  background-image: url(${(props) => props.backgroundUrl});
  background-size: cover;
  background-position: center;
`;
export const PreviewQuizCard = styled.div<PreviewCardProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  border-radius: 12px;
  color: ${(props) => props?.theme?.colors.white[100]};
  background-color: ${(props) => props?.theme?.colors.grey[90]};
  background-image: url(${(props) => props.backgroundUrl});
  background-size: cover;
  background-position: center;
`;

export const QuizTitle = styled.div`
  position: absolute;
`;

export const GroupedAppletPreview = styled.div<PreviewCardProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 2rem 1rem 1rem 1rem;
  border-radius: 0.6rem;
  background-color: #f2f2f7;
  background-image: url(${(props) => props.backgroundUrl});
  background-size: cover;
`;

export const AddAppletContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 139px;
  width: 146px;
  border-radius: 12px;
  background-color: #f2f2f7;
`;

export const AppletPreviewModalContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const QuizAppletModalPreview = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1.5rem 0 1rem;
`;

export const ImageAppletModalPreview = styled.img`
  max-width: 100%;
  max-height: 50vh;
  border-radius: 12px;
`;

export const VideoAppletModalPreview = styled.video`
  max-width: 100%;
  max-height: 50vh;
  border-radius: 12px;
`;

export const AppletPreviewBackground = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  border-radius: 12px;
  background-color: #f2f2f7;
  overflow: hidden;
`;

export const AddAppletModalContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AddAppletButton = styled(ButtonPrimary)`
  width: 70%;
  align-self: center;
`;

export const PublishedShowcasesTitle = styled.div`
  color: ${(props) => props?.theme?.colors.blue2[100]};
  font-size: ${(props) => props?.theme?.typography.large18};
  line-height: ${(props) => props?.theme?.typography.large24};
  margin-bottom: 1rem;
`;

export const PublishedShowcasesContainer = styled(ScrollableArea)`
  margin-bottom: 1rem;
  padding-right: 0.5rem;
`;

export const PublishedShowcaseEmpty = styled.div`
  margin-top: 20px;
  font-size: ${(props) => props?.theme?.typography.large16};
  line-height: ${(props) => props?.theme?.typography.large20};
`;

export const PublishShowcaseContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PublishShowcaseTitle = styled.div`
  color: ${(props) => props?.theme?.colors.grey[100]};
  font-size: ${(props) => props?.theme?.typography.large24};
  font-weight: ${(props) => props?.theme?.typography.semiBold};
  line-height: ${(props) => props?.theme?.typography.large32};
  align-self: center;
  margin-bottom: 1rem;
`;

export const PublishShowcaseSubtitle = styled.div`
  color: ${(props) => props?.theme?.colors.grey[100]};
  font-size: ${(props) => props?.theme?.typography.large18};
  font-weight: ${(props) => props?.theme?.typography.semiBold};
  line-height: ${(props) => props?.theme?.typography.large24};
  margin-top: 47px;
`;

export const ShowcaseForm = styled(Form)`
  display: flex;
  flex-direction: column;
`;

export const ShowcaseFormStep = styled.div`
  display: flex;
  flex-direction: column;
  height: 50vh;
`;

export const ShowcaseFormButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

export const ShowcaseBackButton = styled(ButtonPrimary)`
  background-color: ${(props) => props?.theme?.colors.grey[90]};
  width: 20rem;
  margin: 0;
`;

export const ShowcaseNextButton = styled(ButtonPrimary)`
  width: 20rem;
  margin: 0;
`;

export const PublishShowcaseDescription = styled.div`
  color: ${(props) => props?.theme?.colors.grey[100]};
  font-size: ${(props) => props?.theme?.typography.large16};
  line-height: ${(props) => props?.theme?.typography.large20};
  margin-bottom: 0.5rem;
`;

export const ShowcaseInput = styled(InputField)`
  max-width: 400px;
  margin-top: 0;
`;

export const PublishShowcaseSelectAll = styled.button`
  border: none;
  padding: 0;
  background-color: transparent;
  color: ${(props) => props?.theme?.colors.blue2[100]};
  font-size: ${(props) => props?.theme?.typography.large16};
  line-height: ${(props) => props?.theme?.typography.large20};
  width: fit-content;
  margin-bottom: 1rem;
`;

export const PublishShowcaseAppletsContainer = styled(ScrollableArea)`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;
`;

export const PublishedShowcaseContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

export const PublishedShowcaseTitleAndSubtitle = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PublishedShowcaseTitle = styled.div`
  color: ${(props) => props?.theme?.colors.grey[100]};
  font-size: ${(props) => props?.theme?.typography.large18};
  font-weight: ${(props) => props?.theme?.typography.semiBold};
  line-height: ${(props) => props?.theme?.typography.large24};
`;

export const PublishedShowcaseSubtitle = styled.div`
  color: ${(props) => props?.theme?.colors.grey[90]};
  font-size: ${(props) => props?.theme?.typography.large16};
  line-height: ${(props) => props?.theme?.typography.large20};
  margin-top: 5px;
`;

export const PublishedShowcaseShareAndView = styled.div`
  display: flex;
`;

export const PublishedShowcaseTextButton = styled.button`
  border: none;
  padding: 0;
  background-color: transparent;
  color: ${(props) => props?.theme?.colors.grey[90]};
  font-size: ${(props) => props?.theme?.typography.large18};
  font-weight: ${(props) => props?.theme?.typography.semiBold};
  line-height: ${(props) => props?.theme?.typography.large24};
  margin-left: 1rem;
`;

export const PublishedShowcaseSeparator = styled.div`
  box-sizing: border-box;
  height: 1px;
  border: 1px solid rgba(199, 199, 204, 0.3);
  margin-bottom: 1rem;
`;

export const ShareShowcaseContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ShareShowcaseDescription = styled.div`
  color: ${(props) => props?.theme?.colors.grey[100]};
  font-size: ${(props) => props?.theme?.typography.large16};
  line-height: ${(props) => props?.theme?.typography.large20};
`;

export const FormContent = styled(Form)`
  display: flex;
  flex-direction: column;
`;

export const FormField = styled(Field)`
  font-size: ${(props) => props?.theme?.typography.large18};
  line-height: ${(props) => props?.theme?.typography.large24};
  background-color: ${(props) => props?.theme?.colors.white[100]};
  border: 1px solid ${(props) => props?.theme?.colors.grey[90]};
  padding: 0.7rem 1.2rem 0.5rem 0.8rem;
  border-radius: 0.7rem;
  margin-top: 1rem;
  outline: none;
`;

export const FieldErrorMessage = styled.div`
  color: ${(props) => props?.theme?.colors.red[80]};
  font-size: ${(props) => props?.theme?.typography.large16};
  line-height: ${(props) => props?.theme?.typography.large16};
  margin-top: 0.2rem;
  padding-left: 0.7rem;
`;

export const ShareShowcaseButton = styled(ButtonPrimary)`
  width: 50%;
  align-self: center;
  margin: 2rem 0 0 0;
`;

export const UserNameContainer = styled.div`
  position: relative;
`;

export const EditIconContainer = styled.div`
  position: absolute;
  right: -2rem;
  top: ${(props) => props?.theme?.typography.large24};
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const DisplayNameForm = styled(Form)`
  max-width: 50%;
  margin-top: ${(props) => props?.theme?.typography.large24};
`;

export const CheckIconContainer = styled.button`
  cursor: pointer;
  border: 0px;
  background-color: transparent;
`;

export const ProfileCountersItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom-color: ${(props) => props?.theme?.colors.grey[60]};
  border-bottom-width: 0.5px;
  border-bottom-style: solid;
  cursor: pointer;

  &:hover {
    border-bottom-width: 3px;
  }
`;
export const ProfileCountersItemStack = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
`;
export const ProfileCountersItemStackRight = styled(ProfileCountersItemStack)`
  justify-content: flex-end;
`;
type ProfileCountersItemTextProps = {
  selected?: boolean;
};
export const ProfileCountersItemText = styled.p<ProfileCountersItemTextProps>`
  margin: 10px;
  color: ${(props) =>
    props.selected
      ? props?.theme?.colors.blue1[100]
      : props?.theme?.colors.grey[100]};
  font-weight: ${(props) => (props.selected ? 'bold' : 'normal')};
`;
export const ProfileCountersItemImgContainer = styled.div`
  min-width: 40px;
`;
export const ProfileCountersItemImg = styled.img`
  width: auto;
  max-height: 40px;
`;
