import { createSelector } from '@reduxjs/toolkit';
import { PENDING, FULFILLED, REJECTED } from './reducer';
import { RootState } from '../../redux/typings';

export const statusSelector = (state: RootState, action: string) =>
  state.status[action];

export const isPendingSelector = createSelector(
  statusSelector,
  (status) => status === PENDING,
);

export const wasFulfilledSelector = createSelector(
  statusSelector,
  (status) => status === FULFILLED,
);

export const wasRejectedSelector = createSelector(
  statusSelector,
  (status) => status === REJECTED,
);
