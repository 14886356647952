import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ErrorMessage, Formik } from 'formik';
import { toast } from 'react-toastify';
import { useTheme } from '@emotion/react';

import { AppDispatch } from 'redux/typings';
import { shareShowcaseSchema } from './validationSchema';
import localizedStrings from 'localization';
import {
  FieldErrorMessage,
  FormContent,
  FormField,
  ShareShowcaseButton,
  ShareShowcaseContent,
  ShareShowcaseDescription,
} from 'modules/profile/components';
import Modal from 'components/modal';
import { shareShowcase } from 'modules/profile/actions';
import { TextArea } from 'components/components';
import useActionStatus from 'hooks/useActionStatus';

type ShareShowcaseModalProps = {
  open: boolean;
  closeModal: () => void;
  showcaseId: string;
};

const ShareShowcaseModal = ({
  open,
  closeModal,
  showcaseId,
}: ShareShowcaseModalProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const theme = useTheme();
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [isPending, wasFulfilled] = useActionStatus(shareShowcase);

  const handleSubmit = useCallback(
    (
      values: { name: string; email: string; message: string },
      { resetForm },
    ) => {
      setHasSubmitted(true);
      dispatch(shareShowcase({ showcaseId, data: values }));
      resetForm({});
    },
    [setHasSubmitted, dispatch, showcaseId],
  );

  useEffect(() => {
    if (hasSubmitted && wasFulfilled) {
      setHasSubmitted(false);
      closeModal();
      toast.success(localizedStrings.showcaseShared, {
        autoClose: 3000,
        style: { backgroundColor: theme?.colors.success },
      });
    }
  }, [hasSubmitted, wasFulfilled, closeModal, theme?.colors.success]);

  return (
    <Modal
      open={open}
      closeModal={closeModal}
      title={localizedStrings.share}
      width="516px">
      <ShareShowcaseContent>
        <ShareShowcaseDescription>
          {localizedStrings.shareDescription}
        </ShareShowcaseDescription>
        <Formik
          initialValues={{ name: '', email: '', message: '' }}
          onSubmit={handleSubmit}
          validationSchema={shareShowcaseSchema}>
          <FormContent>
            <FormField
              type="text"
              name="name"
              placeholder={localizedStrings.namePlaceholder}
            />
            <ErrorMessage name="name" component={FieldErrorMessage} />
            <FormField
              type="email"
              name="email"
              placeholder={localizedStrings.emailPlaceholder}
            />
            <ErrorMessage name="email" component={FieldErrorMessage} />
            <FormField
              as={TextArea}
              rows={5}
              name="message"
              placeholder={localizedStrings.shareMessagePlaceholder}
            />
            <ErrorMessage name="message" component={FieldErrorMessage} />
            <ShareShowcaseButton type="submit" isLoading={isPending}>
              {localizedStrings.share}
            </ShareShowcaseButton>
          </FormContent>
        </Formik>
      </ShareShowcaseContent>
    </Modal>
  );
};

export default ShareShowcaseModal;
