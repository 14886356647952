import { createReducer } from '@reduxjs/toolkit';
import { NavigationInformationState } from './typings';
import { setIsComingfromLoadingScreen } from './actions';

const initialState: NavigationInformationState = {};

export default createReducer<NavigationInformationState>(
  initialState,
  (builder) =>
    builder.addCase(setIsComingfromLoadingScreen, (state, action) => ({
      ...state,
      isComingFromLoadingScreen: action.payload,
    })),
);
