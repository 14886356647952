import { createReducer } from '@reduxjs/toolkit';

import { RelationshipsState } from './typings';
import {
  filterFellowTravelerByEmail,
  modifyRelationshipListStatus,
  reflectDeleteInvo,
  findRunningPartner,
} from 'helpers/relationshipHelpers';
import {
  getRelationships,
  invite,
  changeRelationshipStatus,
  selectRelationship,
  deleteInvo,
  updateRelationshipDate,
  getActiveCohortInformation,
  getProfileVisibilityAchievements,
} from './actions';
import { arrayToHash } from 'helpers/helperFunctions';
import { Relationship } from 'models/Relationship.d';

const initialState: RelationshipsState = {
  hash: {},
};

export default createReducer<RelationshipsState>(initialState, (builder) =>
  builder
    .addCase(getProfileVisibilityAchievements.fulfilled, (state, action) => ({
      ...state,
      visibilityPermissions: action.payload,
    }))
    .addCase(getRelationships.fulfilled, (state, action) => ({
      ...state,
      hash: arrayToHash<Relationship>(
        action.payload,
        (rel) => rel.relatedUser.id,
      ),
      runningPartner: findRunningPartner(action.payload),
    }))
    .addCase(invite.pending, (state, action) => ({
      ...state,
      hash: filterFellowTravelerByEmail(state.hash, action.meta.arg),
    }))
    .addCase(invite.fulfilled, (state, action) => ({
      ...state,
      hash: { ...state.hash, [action.payload.relatedUser.id]: action.payload },
    }))
    .addCase(changeRelationshipStatus.pending, (state, action) => ({
      hash: modifyRelationshipListStatus(
        state.hash,
        action.meta.arg.relationshipRelatedUserId,
        action.meta.arg.relationshipStatus,
      ),
      backupHash: state.hash,
    }))
    .addCase(changeRelationshipStatus.rejected, (state) => ({
      ...state,
      hash: state.backupHash!!,
    }))
    .addCase(deleteInvo.fulfilled, (state, action) => ({
      ...state,
      hash: reflectDeleteInvo(state.hash, action.meta.arg.relationshipId),
    }))
    .addCase(selectRelationship, (state, action) =>
      state.hash[action.payload]
        ? {
            ...state,
            hash: {
              ...state.hash,
              [action.payload]: {
                ...state.hash[action.payload],
                unreadMessages: 0,
              },
            },
            selectedRelationshipId: action.payload,
          }
        : { ...state, selectedRelationshipId: action.payload },
    )
    .addCase(updateRelationshipDate, (state, action) => ({
      ...state,
      hash: {
        ...state.hash,
        [action.payload]: {
          ...state.hash[action.payload],
          updatedAt: new Date(),
        },
      },
    }))
    .addCase(getActiveCohortInformation.fulfilled, (state, action) => ({
      ...state,
      cohortInformation: action.payload,
    })),
);
