import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Formik, FormikProps } from 'formik';

import localizedStrings from 'localization';
import {
  GiveFeedbackFormContent,
  GiveFeedbackTitle,
  GiveFeedbackFormTextArea,
  GiveFeedbackPrompt,
  GiveFeedbackButton,
  ContentPanel,
} from '../components';
import { giveFeedbackSchema } from '../validationSchema';
import Rating from 'react-rating';
import StarFilled from 'modules/profile/achievements/achievements-list/StartFilled';
import StarEmpty from 'modules/profile/achievements/achievements-list/StarEmpty';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/typings';
import { sendFeedback } from '../actions';
import { ValidationError } from 'components/components';
import Modal from 'components/modal';
import useActionStatus from 'hooks/useActionStatus';

const GiveFeedback = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [modalOpen, setModalOpen] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const formikRef =
    useRef<FormikProps<{ rating: number; comment: string }>>(null);

  const [isPending, wasFulfilled] = useActionStatus(sendFeedback);

  const closeModal = useCallback(() => setModalOpen(false), []);

  const handleSubmit = useCallback(
    (values) => {
      dispatch(sendFeedback(values));
      setHasSubmitted(true);
    },
    [dispatch],
  );

  useEffect(() => {
    if (hasSubmitted && wasFulfilled) {
      setModalOpen(true);
      setHasSubmitted(false);
      formikRef.current?.resetForm();
    }
  }, [hasSubmitted, wasFulfilled]);

  return (
    <>
      <Modal
        open={modalOpen}
        closeModal={closeModal}
        title={localizedStrings.feedbackSent}
        width="516px">
        {localizedStrings.feedbackSentMessage}
      </Modal>
      <ContentPanel>
        <GiveFeedbackTitle>{localizedStrings.sendFeedback}</GiveFeedbackTitle>
        <Formik
          initialValues={{ rating: 1, comment: '' }}
          validationSchema={giveFeedbackSchema}
          onSubmit={handleSubmit}
          innerRef={formikRef}>
          {({ values, errors, submitCount, handleChange, setFieldValue }) => (
            <GiveFeedbackFormContent>
              <Rating
                initialRating={values.rating}
                fullSymbol={<StarFilled />}
                emptySymbol={<StarEmpty />}
                onChange={(value) => setFieldValue('rating', value)}
              />
              <GiveFeedbackPrompt>
                {localizedStrings.feedbackPrompt}
              </GiveFeedbackPrompt>
              <GiveFeedbackFormTextArea
                rows={10}
                value={values.comment}
                onChange={handleChange('comment')}
                placeholder={localizedStrings.writeYourFeedback}
              />
              {submitCount > 0 && errors.comment !== undefined && (
                <ValidationError>{errors.comment}</ValidationError>
              )}

              <GiveFeedbackButton type="submit" isLoading={isPending}>
                {localizedStrings.submit}
              </GiveFeedbackButton>
            </GiveFeedbackFormContent>
          )}
        </Formik>
      </ContentPanel>
    </>
  );
};

export default GiveFeedback;
