import { createReducer } from '@reduxjs/toolkit';
import { getSubmission } from './actions';

import { ExternalFeedbackState } from './typings';

export default createReducer<ExternalFeedbackState>({}, (builder) =>
  builder.addCase(getSubmission.fulfilled, (_, action) => ({
    submission: action.payload,
  })),
);
