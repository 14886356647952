import React from 'react';

import { ChallengeApplet } from 'models/ChallengeApplet';
import RandomSelectionApplet from 'components/applets/random-selection';
import RandomSelectionOption from 'components/applets/random-selection-option';
import useRandomSelectionOptions from 'hooks/useRandomSelectionOptions';

type RandomSelectionAppletSubmissionProps = {
  applet: ChallengeApplet;
};

const RandomSelectionAppletSubmission = ({
  applet,
}: RandomSelectionAppletSubmissionProps) => {
  const options = useRandomSelectionOptions(
    applet?.optionsContentful,
    applet?.options,
  );
  return (
    <RandomSelectionApplet
      options={options.map((opt) => (
        <RandomSelectionOption
          option={opt}
          disabled
          selected={opt.id === applet.text}
          key={opt.id}
        />
      ))}
      disabled
    />
  );
};

export default RandomSelectionAppletSubmission;
