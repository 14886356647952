import React, { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import Mountain from '../../../assets/images/Mountain.webp';
import {
  MountainImage,
  ShowCaseBackItems,
  ShowCaseImagesText,
} from './ShowcaseBottomComponents';
import { ShowCaseActionButton } from '../resume/ShowcaseResumeComponents';
import { SHOWCASE, SHOWCASE_CONTACT } from '../../../routes';
import { useSelector } from 'react-redux';
import { selectCallToAction } from '../selectors';

const ShowcaseBottomItems = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  const callToAction = useSelector(selectCallToAction);

  const goToContact = useCallback(
    () => history.push(`${SHOWCASE}/${id + SHOWCASE_CONTACT}`),
    [history, id],
  );

  return (
    <ShowCaseBackItems>
      <ShowCaseImagesText>{callToAction}</ShowCaseImagesText>
      <ShowCaseActionButton onClick={goToContact}>
        Let's get in touch
      </ShowCaseActionButton>
      <MountainImage src={Mountain} alt="mynga" />
    </ShowCaseBackItems>
  );
};

export default ShowcaseBottomItems;
