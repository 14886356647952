import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectAppletContentfulById } from 'modules/loading-screen/contentful/applets/selectors';
import useContentfulRenderer from 'hooks/useContentfulRenderer';
import MadlibApplet from 'components/applets/madlib';
import FileUploadApplet from 'components/applets/file-upload';
import { ShareStepAppletInformation } from 'modules/applets/typings';
import RichTextApplet from 'components/applets/rich-text';

const ShareAppletModalPreview = ({
  appletContentfulId = '',
  type,
  richText,
  file,
  madlibAnswers,
}: Partial<ShareStepAppletInformation>) => {
  const appletContentful = useSelector(
    selectAppletContentfulById(appletContentfulId),
  );

  const renderedMadlibAppletSentences = useContentfulRenderer(
    appletContentful?.madlibSentence,
    {
      appletId: appletContentfulId,
      madlibAnswers,
    },
  );

  const applet = useMemo(() => {
    switch (type) {
      case 'upload-file':
      case 'upload-video':
      case 'upload-image':
        return (
          <FileUploadApplet
            appletId={appletContentfulId}
            fileUrl={file?.uri}
            appletType={appletContentful?.type}
            supportedTypes={appletContentful?.supportedTypes}
            disabled
          />
        );
      case 'madlib':
        return (
          <MadlibApplet
            disabled
            madlibSentence={renderedMadlibAppletSentences}
          />
        );
      case 'rich-text-entry':
        return <RichTextApplet initialContent={richText} disabled />;
      default:
        return null;
    }
  }, [
    type,
    appletContentfulId,
    file,
    appletContentful,
    renderedMadlibAppletSentences,
    richText,
  ]);

  return applet;
};

export default ShareAppletModalPreview;
