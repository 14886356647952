import React from 'react';

type MailIconProps = {
  color?: string;
};

const MailIcon = ({
  color = '#FFFFFF',
}: MailIconProps) => {
  return (
    <svg width="24px" height="24px" viewBox="0 0 24 24">
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Icons/24/Feedback" fill={color}>
          <path d="M1.7005814,3.27906698 C0.774452149,3.27906698 5.58139543e-08,4.02802047 5.58139543e-08,4.95348558 L5.58139543e-08,17.2325637 C5.58139543e-08,18.1580149 0.774452149,18.9069823 1.7005814,18.9069823 L22.2994186,18.9069823 C23.2255479,18.9069823 24.0000001,18.1580149 24.0000001,17.2325637 L24.0000001,4.95348558 C24.0000001,4.02802047 23.2255479,3.27906698 22.2994186,3.27906698 L1.7005814,3.27906698 Z M1.98837209,4.39534605 L22.0116279,4.39534605 L12.4883721,12.9069823 C12.2508949,13.1192149 11.7491051,13.1192149 11.5116279,12.9069823 L1.98837209,4.39534605 Z M1.11627907,5.11046233 L8.08430233,11.3372149 L1.11627907,17.1104623 L1.11627907,5.11046233 Z M22.8837209,5.11046233 L22.8837209,17.1104623 L15.9156977,11.3372149 L22.8837209,5.11046233 Z M8.93023256,12.0959219 L10.7703488,13.7354567 C11.4682074,14.3591498 12.5317926,14.3591498 13.2296512,13.7354567 L15.0697674,12.0959219 L21.9505814,17.7907033 L2.0494186,17.7907033 L8.93023256,12.0959219 Z" id="Shape"></path>
        </g>
      </g>
    </svg>
  );
};

export default MailIcon;
