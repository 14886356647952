import React, { useEffect } from 'react';
import { ProfileSection, ProfileSectionTitle } from '../components';
import localizedStrings from 'localization';
import QuestsApplets from './quest-applets';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/typings';
import { getGenericApplets, getPublishedShowcases } from '../actions';

const Portfolio = () => {
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(getPublishedShowcases());
    dispatch(getGenericApplets());
  }, [dispatch]);

  return (
    <ProfileSection>
      <ProfileSectionTitle>{localizedStrings.artifacts}</ProfileSectionTitle>
      <QuestsApplets />
    </ProfileSection>
  );
};

export default Portfolio;
