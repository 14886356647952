import * as React from 'react';

type MobileIconProps = {
  width: number | string;
  height: number | string;
  color?: string;
};

const MobileIcon = ({ width, height, color = '#FFFF' }: MobileIconProps) => {
  return (
    <svg width={width} height={height} viewBox="0 0 8 13">
      <g id="V3" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="Quest---Active-user-1"
          transform="translate(-84.000000, -617.000000)"
          stroke={color}
          strokeWidth="0.75">
          <g id="Cards/Day" transform="translate(16.000000, 372.000000)">
            <g
              id="Icons/Misc/Mobile"
              transform="translate(66.000000, 245.000000)">
              <g id="Mobile" transform="translate(3.000000, 1.000000)">
                <rect id="Rectangle" x="0" y="0" width="6" height="11" rx="1" />
                <line
                  x1="2"
                  y1="9"
                  x2="4"
                  y2="9"
                  id="Path-13"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default MobileIcon;
