import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Step, StepLabel, Stepper } from '@material-ui/core';
import { Formik, FormikProps } from 'formik';
import { toast } from 'react-toastify';
import { useTheme } from '@emotion/react';

import localizedStrings from 'localization';
import { publishShowcaseSchema } from '../validationSchema';
import {
  FieldErrorMessage,
  PublishShowcaseContent,
  PublishShowcaseTitle,
  ShowcaseBackButton,
  ShowcaseForm,
  ShowcaseFormButtons,
  ShowcaseFormStep,
  ShowcaseNextButton,
} from 'modules/profile/components';
import Modal from 'components/modal';
import GeneralInfo from './steps/GeneralInfo';
import SelectArtifacts from './steps/SelectArtifacts';
import SelectFeaturedArtifacts from './steps/SelectFeaturedArtifacts';
import SelectBackgroundImage from './steps/SelectBackgroundImage';
import SelectFeaturedVideo from './steps/SelectFeaturedVideo';
import SelectHeadline from './steps/SelectHeadline';
import { InitialValuesType, StepProps } from './typings';
import { PublishShowcaseArgs } from 'modules/profile/typings';
import { selectExistsAppletImagesToAddShowcase, selectExistsAppletVideosToAddShowcase, selectQuestPortfolioApplets } from 'modules/applets/selectors';
import { buildQuestAppletsList } from 'helpers/AppletHelpers';
import useActionStatus from 'hooks/useActionStatus';
import { publishShowcase } from 'modules/profile/actions';
import { AppDispatch } from 'redux/typings';
import { QuestApplet } from 'models/QuestApplet';
import StepIcon from './StepIcon';

const steps = [
  'General Info',
  'Artifacts',
  'Featured Artifacts',
  'Background Image',
  'Featured Video',
  'Headline',
];

const initialValues = {
  name: '',
  callToAction: '',
  applets: [],
  featuredApplets: [],
  headlineApplet: '',
  backgroundApplet: '',
  videoApplet: '',
} as InitialValuesType;

type PublishedShowcaseProps = {
  open: boolean;
  closeModal: () => void;
};

const PublishShowcaseModal = ({ open, closeModal }: PublishedShowcaseProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [nextButtonText, setNextButtonText] = useState('');
  const formikRef = useRef<FormikProps<InitialValuesType>>(null);
  const questPortfolioApplets = useSelector(selectQuestPortfolioApplets);
  const existsAppletImages = useSelector(selectExistsAppletImagesToAddShowcase);
  const existsAppletVideoToAdd = useSelector(selectExistsAppletVideosToAddShowcase)

  const [isPending, wasFulfilled] = useActionStatus(publishShowcase);

  const groupedApplets = useMemo(
    () => buildQuestAppletsList(questPortfolioApplets),
    [questPortfolioApplets],
  );

  const lastStep = useMemo(() => steps.length - 1, []);

  const renderStep = useCallback((activeStep, stepProps: StepProps) => {
    switch (activeStep) {
      case 0:
        return <GeneralInfo {...stepProps} />;
      case 1:
        return <SelectArtifacts {...stepProps} />;
      case 2:
        return <SelectFeaturedArtifacts {...stepProps} />;
      case 3:
        return <SelectBackgroundImage {...stepProps} />;
      case 4:
        return <SelectFeaturedVideo {...stepProps} />;
      case 5:
        return <SelectHeadline {...stepProps} />;
      default:
        return null;
    }
  }, []);

  const handleModalClose = useCallback(() => {
    formikRef.current?.resetForm();
    setActiveStep(0);
    closeModal();
  }, [closeModal]);

  const handleBack = useCallback(() => {
    setActiveStep((activeStep) =>
      activeStep > 0 ? activeStep - 1 : activeStep,
    );
  }, []);

  const submitForm = useCallback(
    (values: InitialValuesType) => {
      const headlineApplet = groupedApplets.find(
        (group) => group.id === values.headlineApplet,
      ) as QuestApplet;
      const showcase: PublishShowcaseArgs = {
        title: values.name,
        callToAction: values.callToAction,
        appletIds: values.applets,
        featuredAppletIds: values.featuredApplets,
        headlineAppletIds:
          headlineApplet.appletType === 'group-applet'
            ? headlineApplet.applets?.map((applet) => applet.id) ?? []
            : [headlineApplet.id],
        backgroundUrl:
          questPortfolioApplets.find(
            (applet) => applet.id === values.backgroundApplet,
          )?.fileUrl ?? '',
        videoUrl:
          questPortfolioApplets.find(
            (applet) => applet.id === values.videoApplet,
          )?.fileUrl ?? '',
      };
      dispatch(publishShowcase(showcase));
      setHasSubmitted(true);

    },
    [questPortfolioApplets, dispatch, groupedApplets],
  );

  const handleSubmit = useCallback(
    (values: InitialValuesType) => {
      if (activeStep === lastStep) {
        submitForm(values);
      } else {
        setActiveStep((activeStep) => activeStep + 1);
      }
    },
    [activeStep, lastStep, submitForm],
  );

  useEffect(() => {
    if (hasSubmitted && wasFulfilled) {
      setHasSubmitted(false);
      handleModalClose();
      toast.success(localizedStrings.showcaseCreated, {
        autoClose: 3000,
        style: { backgroundColor: theme?.colors.success },
      });
    }
  }, [hasSubmitted, handleModalClose, wasFulfilled, theme?.colors.success]);

  return (
    <Modal open={open} closeModal={handleModalClose} width="910px">
      <PublishShowcaseContent>
        <PublishShowcaseTitle>
          {localizedStrings.publishAShowcase}
        </PublishShowcaseTitle>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel StepIconComponent={StepIcon}>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {existsAppletImages && existsAppletVideoToAdd
          ? <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={publishShowcaseSchema[activeStep]}
            innerRef={formikRef}>
            {({ values, setFieldValue }) => (
              <ShowcaseForm>
                <ShowcaseFormStep>
                  {renderStep(activeStep, {
                    values,
                    setFieldValue,
                    setNextButtonText,
                  })}
                </ShowcaseFormStep>
                <ShowcaseFormButtons>
                  <ShowcaseBackButton
                    type="button"
                    onClick={handleBack}
                    disabled={activeStep === 0}>
                    {localizedStrings.back}
                  </ShowcaseBackButton>
                  <ShowcaseNextButton type="submit" isLoading={isPending}>
                    {nextButtonText}
                  </ShowcaseNextButton>
                </ShowcaseFormButtons>
              </ShowcaseForm>
            )}
          </Formik> : null}

        {!existsAppletImages ?
          <FieldErrorMessage>
            {localizedStrings.showcaseImageError}
          </FieldErrorMessage> : null}
        <br />
        {!existsAppletVideoToAdd ?
          <FieldErrorMessage>
            {localizedStrings.showcaseVideoError}
          </FieldErrorMessage> : null}
      </PublishShowcaseContent>
    </Modal>
  );
};

export default PublishShowcaseModal;
