import styled from '@emotion/styled';
import { Form } from 'formik';
import ButtonPrimary from 'components/button-primary';
import InputField from 'components/input-field';

export const SendButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const SendButton = styled(ButtonPrimary)`
  width: 30%;
`;

export const SendButtonText = styled.span`
  margin-left: 10px;
`;

export const SendForm = styled(Form)`
  display: flex;
  flex-direction: column;
`;

export const SendModalTitleContainer = styled.div`
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SendModalTitle = styled.div`
  color: ${(props) => props?.theme?.colors.blue2[100]};
  font-size: ${(props) => props?.theme?.typography.large24};
`;

export const EmptyIcon = styled.div`
  width: 22px;
  height: 100%;
`;

export const SendMailButton = styled.button`
  border: none;
  padding: 0;
  background-color: transparent;
`;

export const FieldsContainer = styled.div`
  padding-top: 25px;
`;

export const InputWithoutBorder = styled(InputField)`
  border: 0px;
  border-bottom: 1px solid ${(props) => props?.theme?.colors.grey[90]};
  padding: 0.7rem 1.2rem 0.5rem 0.8rem;
  border-radius: 0px;
`;
