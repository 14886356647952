import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { TimePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

import { AppDispatch } from 'redux/typings';
import { LOADING_SCREEN } from 'routes';
import localizedStrings from 'localization';
import ButtonPrimary from 'components/button-primary';
import {
  ScheduleCommitBackground,
  ScheduleCommitContainer,
  ScheduleCommitTitle,
  ScheduleCommitPrompt,
  ScheduleCommitControls,
  DailyReminderTimePickerContainer,
} from '../components';
import useActionStatus from 'hooks/useActionStatus';
import { getTodayEightAm } from 'helpers/dateHelpers';
import { setDailyReminderTime } from './actions';

const DailyReminderTime = () => {
  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();
  const [value, setValue] = useState<MaterialUiPickersDate>(getTodayEightAm);

  const [isSettingDailyReminderTime, wasSetDailyReminderTime] =
    useActionStatus(setDailyReminderTime);

  useEffect(() => {
    if (wasSetDailyReminderTime) {
      history.push(LOADING_SCREEN);
    }
  }, [wasSetDailyReminderTime, history]);

  const onNextClick = useCallback(() => {
    if (value) {
      const dateUtc = Date.UTC(
        value.getUTCFullYear(),
        value.getUTCMonth(),
        value.getUTCDate(),
        value.getUTCHours(),
      );
      dispatch(setDailyReminderTime(dateUtc));
    }
  }, [dispatch, value]);

  return (
    <ScheduleCommitBackground>
      <ScheduleCommitContainer>
        <ScheduleCommitTitle>
          {localizedStrings.dailyReminder}
        </ScheduleCommitTitle>
        <ScheduleCommitPrompt>
          {localizedStrings.dailyReminderMessage}
        </ScheduleCommitPrompt>
        <ScheduleCommitControls>
          <DailyReminderTimePickerContainer>
            <TimePicker
              value={value}
              onChange={setValue}
              minutesStep={60}
              views={['hours']}
              fullWidth
              inputProps={{ style: { textAlign: 'center' } }}
            />
          </DailyReminderTimePickerContainer>
          <ButtonPrimary
            isLoading={isSettingDailyReminderTime}
            onClick={onNextClick}>
            {localizedStrings.next}
          </ButtonPrimary>
        </ScheduleCommitControls>
      </ScheduleCommitContainer>
    </ScheduleCommitBackground>
  );
};

export default DailyReminderTime;
