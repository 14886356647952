import React from 'react';

type GoDeeperIconProps = {
  colorPrimary?: string;
  colorDisabled?: string;
  disabled?: boolean;
};

const GoDeeperIcon = ({
  colorPrimary = '#007DC2',
  colorDisabled = '#8E8E93',
  disabled = false,
}: GoDeeperIconProps) => {
  return (
    <svg width="20px" height="20px" viewBox="0 0 20 20">
      <title>6A92A1E3-09C7-4341-A5DF-6A95800B2863</title>
      <g id="V3" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="Challenge---03-"
          transform="translate(-300.000000, -439.000000)"
          fill={disabled ? colorDisabled : colorPrimary}>
          <g id="Group-4" transform="translate(277.000000, 437.000000)">
            <g
              id="Icons/24/Go-deeper"
              transform="translate(21.000000, 0.000000)">
              <path d="M11,2 C15.971,2 20,6.029 20,11 C20,13.125 19.264,15.078 18.032,16.617 L21.707,20.293 C22.098,20.683 22.098,21.317 21.707,21.707 C21.347,22.068 20.779,22.095 20.387,21.79 L20.293,21.707 L16.617,18.032 C15.078,19.264 13.125,20 11,20 C6.029,20 2,15.971 2,11 C2,6.029 6.029,2 11,2 Z M11,4 C7.134,4 4,7.134 4,11 C4,14.866 7.134,18 11,18 C14.866,18 18,14.866 18,11 C18,7.134 14.866,4 11,4 Z"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default GoDeeperIcon;
