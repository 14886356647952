import React, { useCallback, useEffect, useState } from 'react';
import { LottieProps } from 'react-lottie-player';

import dartTargetData from './dart-animation/dartTarget.json';
import useAudio from 'hooks/useAudio';
import dartSound from 'assets/audios/dartDrop.m4a';
import { AnimationContainer } from '../components';

const BOARD_CREATION_START = 0;
const BOARD_CREATION_END = 70;
const DART_SHOT_START = 70;
const ANIMATION_END = 108;
const SEGMENTS = [
  [BOARD_CREATION_START, BOARD_CREATION_END],
  [DART_SHOT_START, ANIMATION_END],
] as LottieProps['segments'];

const DartAnimation = () => {
  const [isPlayingAnim, setIsPlayingAnim] = useState(false);
  const [, playAudio, stopAudio] = useAudio(dartSound);

  useEffect(() => {
    if (!isPlayingAnim) {
      setIsPlayingAnim(true);
    }
  }, [isPlayingAnim]);

  const onSegmentStart = useCallback(
    (data: { firstFrame: number; lastFrame: number }) => {
      if (data.firstFrame === DART_SHOT_START) {
        playAudio();
      } else {
        stopAudio();
      }
    },
    [playAudio, stopAudio],
  );

  const onComplete = useCallback(() => {
    stopAudio();
  }, [stopAudio]);

  return (
    <AnimationContainer
      animationData={dartTargetData}
      play={isPlayingAnim}
      segments={SEGMENTS}
      onSegmentStart={onSegmentStart}
      loop={false}
      onComplete={onComplete}
    />
  );
};

export default DartAnimation;
