import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import {
  UpdateBranchDescriptionPayload,
  UpdateBranchFilePayload,
  UpdateQuizQuestionPayload,
  UploadAssetRichTextPayload,
  UpdateMadlibAnswerPayload,
  AppletUploadPayload,
} from './typings';
import { UploadFileResponse } from '../files/typings';
import { uploadFile } from '../files/services';

const UPLOAD_RESPONSE = 'UPLOAD_RESPONSE';
export const uploadResponse =
  createAction<AppletUploadPayload>(UPLOAD_RESPONSE);

const UPLOAD_ASSET_FOR_RICH_TEXT = 'UPLOAD_ASSET_FOR_RICH_TEXT';
export const uploadAssetForRichText = createAsyncThunk<
  UploadFileResponse,
  UploadAssetRichTextPayload
>(UPLOAD_ASSET_FOR_RICH_TEXT, async (args) => {
  const fileUrl = await uploadFile({ file: args.file, id: 'id-1' }, () => {});
  return fileUrl;
});

const CLEAN_RICH_TEXT_ASSET = 'CLEAN_RICH_TEXT_ASSET';
export const cleanRichTextAsset = createAction<void>(CLEAN_RICH_TEXT_ASSET);

const SAVE_RICH_TEXT_APPLET = 'SAVE_RICH_TEXT_APPLET';
export const saveRichTextApplet = createAction<AppletUploadPayload>(
  SAVE_RICH_TEXT_APPLET,
);

const UPDATE_QUIZ_QUESTION_VALUE = 'UPDATE_QUIZ_QUESTION_VALUE';
export const updateQuizQuestionValue = createAction<UpdateQuizQuestionPayload>(
  UPDATE_QUIZ_QUESTION_VALUE,
);

const UPDATE_BRANCH_FILE = 'UPDATE_BRANCH_FILE';
export const updateBranchFile =
  createAction<UpdateBranchFilePayload>(UPDATE_BRANCH_FILE);

const UPDATE_BRANCH_DESCRIPTION = 'UPDATE_BRANCH_DESCRIPTION';
export const updateBranchDescription =
  createAction<UpdateBranchDescriptionPayload>(UPDATE_BRANCH_DESCRIPTION);

const UPDATE_MADLIB_ANSWER = 'UPDATE_MADLIB_ANSWER';
export const updateMadlibAnswer =
  createAction<UpdateMadlibAnswerPayload>(UPDATE_MADLIB_ANSWER);

const RESET_MODIFIED = 'RESET_MODIFIED';
export const resetModified = createAction<void>(RESET_MODIFIED);

export const RESET_STATE = 'RESET_STATE';
export const resetState = createAction(RESET_STATE);
