import { createReducer } from '@reduxjs/toolkit';

import { setFilter, setActiveTab, setWeekNumber } from './actions';
import { OverviewState, FILTER_OPTIONS } from './typings.d';

const initialState: OverviewState = {
  filter: FILTER_OPTIONS.ALL,
  activeTab: 0,
  weekNumber: undefined,
};

export default createReducer<OverviewState>(initialState, (builder) =>
  builder
    .addCase(setFilter, (state, action) => ({
      ...state,
      filter: action.payload,
    }))
    .addCase(setActiveTab, (state, action) => ({
      ...state,
      activeTab: action.payload,
    }))
    .addCase(setWeekNumber, (state, action) => ({
      ...state,
      weekNumber: action.payload,
    })),
);
