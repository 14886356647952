import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Document } from '@contentful/rich-text-types';

import AppUpload, { FileType } from '../app-upload';
import {
  selectAppletById,
  selectAppletsForShareModal,
} from 'modules/applet-upload/selectors';
import { uploadResponse } from 'modules/applet-upload/actions';
import useIsAppletDisabled from 'hooks/useIsAppletDisabled';
import useContentfulRenderer from 'hooks/useContentfulRenderer';
import useExistingApplet from 'hooks/useExistingApplet';
import { IAppletFields } from 'models/contentful';
import ShareAppletButton from '../share-applet-button';
import theme from 'utils/theme';

type FileUploadAppletProps = {
  appletId: string;
  appletType: IAppletFields['type'];
  supportedTypes?: IAppletFields['supportedTypes'];
  fileUrl?: string;
  prompt?: Document;
  style?: React.CSSProperties;
  sendButton?: boolean;
  disabled?: boolean;
};

const FileUploadApplet = ({
  appletId,
  appletType,
  supportedTypes,
  fileUrl,
  prompt,
  style,
  sendButton,
  disabled,
}: FileUploadAppletProps) => {
  const appletToUpload = useSelector(selectAppletById(appletId));
  const shareModalApplets = useSelector(selectAppletsForShareModal);
  const dispatch = useDispatch();

  const showShareButton = useMemo(
    () => sendButton && shareModalApplets.length <= 1,
    [sendButton, shareModalApplets],
  );

  const appletDisabled = useIsAppletDisabled();
  const id = useExistingApplet(appletId);
  const renderPrompt = useContentfulRenderer(prompt, {
    additionalStyles: { paragraph: { color: theme.colors.grey[90] } },
  });

  const supportedFileType = useMemo(() => {
    switch (appletType) {
      case 'upload-file':
        return supportedTypes?.map((type) => FileType[type]);
      case 'upload-image':
        return FileType.images;
      case 'upload-video':
        return FileType.videos;
      default:
        return FileType.all;
    }
  }, [appletType, supportedTypes]);

  const handleChangeFile = useCallback(
    (file) => {
      dispatch(
        uploadResponse({
          applet: {
            id,
            appletType: 'upload-file',
            appletId,
            fileUrl,
            fileType: file ? file.type : undefined,
            file,
            fileUploaded: false,
          },
          modified: true,
        }),
      );
    },
    [dispatch, id, appletId, fileUrl],
  );

  return (
    <div style={style}>
      {renderPrompt}
      <AppUpload
        value={appletToUpload?.file}
        onChangeFile={handleChangeFile}
        disabled={disabled || appletDisabled}
        fileType={supportedFileType}
      />
      {showShareButton ? (
        <ShareAppletButton
          appletContentfulId={appletId}
          type={appletType}
          file={appletToUpload?.file}
          disabled={!Boolean(appletToUpload?.file?.uri)}
        />
      ) : null}
    </div>
  );
};
export default FileUploadApplet;
