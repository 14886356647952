import React, { useCallback, ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import NavBar from 'modules/navbar';
import { Tab, TabBar, TabPanel } from './components';
import localizedStrings from 'localization/en';
import WeeklyTab from './weekly-tab';
import DailyTab from './daily-tab';
import AllTab from './all-tab';
import { setActiveTab } from './actions';
import { selectActiveTab } from './selectors';
import SyncContent from 'components/sync-content';
import useMinHoursPerWeek from 'hooks/useMinHoursPerWeek';

const Overview = () => {
  const dispatch = useDispatch();
  const activeTab = useSelector(selectActiveTab);
  const minHoursPerWeek = useMinHoursPerWeek();
  const isModuleSchedule = minHoursPerWeek < 0 ? true : false;

  const handleChange = useCallback(
    (_: ChangeEvent<{}>, value: number) => {
      dispatch(setActiveTab(value));
    },
    [dispatch],
  );

  return (
    <>
      <NavBar />
      <SyncContent />
      {!isModuleSchedule ? (
        <TabBar value={activeTab} onChange={handleChange} variant="fullWidth">
          <Tab label={localizedStrings.all} />
          <Tab label={localizedStrings.weekly} />
          <Tab label={localizedStrings.daily} />
        </TabBar>
      ) : null}
      <TabPanel value={activeTab} index={0}>
        <AllTab />
      </TabPanel>
      <TabPanel value={activeTab} index={1}>
        <WeeklyTab />
      </TabPanel>
      <TabPanel value={activeTab} index={2}>
        <DailyTab />
      </TabPanel>
    </>
  );
};

export default Overview;
