import React from 'react';

type PromiseKeptMedalIconProps = {
  width?: number;
  height?: number;
};

const PromiseKeptMedalIcon = ({
  width = 96,
  height = 96,
}: PromiseKeptMedalIconProps) => {
  return (
    <svg width={width} height={height} viewBox="0 0 60 60">
      <defs>
        <polygon
          id="path-1"
          points="0 1.0628657 60 1.0628657 60 59.4419807 0 59.4419807"></polygon>
      </defs>
      <g id="V3" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="Profile---Achievements"
          transform="translate(-24.000000, -622.000000)">
          <g id="Achievements-2" transform="translate(16.000000, 614.000000)">
            <g id="Icons/60/Badge" transform="translate(8.000000, 8.000000)">
              <path
                d="M30,1.11111111 C46.5651818,1.11111111 60,14.2971133 60,30.5555234 C60,46.8139604 46.5651818,60 30,60 C13.4347909,60 0,46.8139604 0,30.5555234 C0,14.2971133 13.4347909,1.11111111 30,1.11111111 Z"
                id="Path"
                fill="#FAD95B"></path>
              <g id="Clipped">
                <mask id="mask-2" fill="white">
                  <use xlinkHref="#path-1"></use>
                </mask>
                <g id="Path"></g>
                <path
                  d="M37.3219091,26.4932609 C37.1828182,26.1533768 36.9035455,25.9494517 36.6256364,25.8814589 L32.8655455,25.3376232 L31.2638182,21.9386763 C31.1247273,21.5987923 30.7764545,21.3948671 30.4284545,21.3948671 C30.0801818,21.3948671 29.7319091,21.5987923 29.592,21.9386763 L27.9910909,25.3376232 L24.2309727,25.8814589 C23.8828091,25.9494517 23.6042727,26.1533768 23.5346455,26.4932609 C23.3946818,26.8330918 23.5346455,27.1731884 23.7428455,27.3769807 L26.4585273,30.0961594 L25.8318273,33.9028502 C25.7622,34.2429469 25.9021636,34.5827778 26.1799909,34.78657 C26.4585273,34.990628 26.8066909,34.990628 27.1548818,34.8545894 L30.4284545,33.0871498 L33.7011818,34.8545894 C33.9788182,34.990628 34.3974545,34.990628 34.6753636,34.78657 C34.9543636,34.5827778 35.0937273,34.2429469 35.0233636,33.9028502 L34.3974545,30.0961594 L37.1124545,27.3769807 C37.3917273,27.1731884 37.4604545,26.7650725 37.3219091,26.4932609 Z"
                  id="Path"
                  fill="#E0AA1E"
                  fillRule="nonzero"
                  mask="url(#mask-2)"></path>
              </g>
              <path
                d="M30.5768818,54.4444444 C30.4819491,54.4444444 30.3872892,54.4239671 30.3002676,54.3840761 C17.9745723,48.8057207 10.9821824,41.8939426 9.51940115,33.8452714 C7.52025045,22.8469544 16.5473635,13.8963746 16.9334775,13.5212398 C17.1904504,13.2706978 17.6082086,13.2706978 17.8638721,13.5212398 C18.120845,13.7730317 18.1221544,14.178989 17.8651815,14.4308075 C17.7755684,14.5181687 8.9342832,23.2953025 10.8187782,33.6295942 C12.1945924,41.179894 18.8430151,47.7265365 30.5768818,53.0929379 C42.3160679,47.7254728 48.9646271,41.1745752 50.337877,33.6205522 C52.2168887,23.2747453 43.3783316,14.5181687 43.288582,14.4308075 C43.0316091,14.178989 43.0316091,13.7730317 43.288582,13.5212398 C43.5469188,13.2706978 43.9634768,13.2706978 44.2218136,13.5212398 C44.6064545,13.8963746 53.6335131,22.8469544 51.6344715,33.8452714 C50.1717448,41.8939426 43.1791912,48.8057207 30.8537687,54.3840761 C30.7667471,54.4239671 30.6718144,54.4444444 30.5768818,54.4444444 Z"
                id="Path"
                fill="#FCF5AD"></path>
              <path
                d="M50.9796944,29.5064236 L47.9460348,29.959891 C46.2833812,30.2132586 44.6840493,29.239808 44.3792645,27.78667 L43.8461538,25.1599816 L46.207185,24.8119737 C47.5017321,24.6133092 48.8091537,25.1599816 49.4436841,26.1732866 L50.7130077,28.1865934 L51.0939887,25.9186218 C51.2842164,24.7866369 52.287116,23.8933366 53.5942749,23.6933472 L55.9553061,23.3333333 L56.4884167,25.9599665 C56.7929388,27.4132425 55.6760076,28.7866166 54.0004795,29.0399843 L50.9796944,29.5064236 Z"
                id="Path"
                fill="#ED9B40"></path>
              <path
                d="M50.1696849,13.1608568 C51.091132,14.2857396 50.9353188,15.9842925 49.806479,16.9604345 L47.7817132,18.7102149 L45.757216,20.4587792 C44.6283762,21.4348954 42.9799799,21.2961673 42.0585328,20.1725522 L40.3846154,18.1215388 L41.9677313,16.7604404 C42.8373302,15.9980307 44.0701351,15.8980596 45.0305355,16.4979902 L46.9381727,17.6728327 L46.9513362,17.685355 L46.9381727,17.6728327 L46.1335855,15.5355864 C45.7314263,14.460715 46.042784,13.235861 46.9123829,12.4734514 L48.4954989,11.1111111 L50.1696849,13.1608568 Z"
                id="Path"
                fill="#ED9B40"></path>
              <path
                d="M42.8056997,9.89075942 C44.3330223,11.3614483 43.7278793,14.3305414 43.7278793,14.3305414 C43.7278793,14.3305414 40.6446023,14.9132797 39.1172797,13.4425908 C37.5902553,11.9719018 38.1951001,9.00278004 38.1951001,9.00278004 C38.1951001,9.00278004 41.2783771,8.4200705 42.8056997,9.89075942 Z"
                id="Path"
                fill="#ED9B40"></path>
              <path
                d="M41.9414442,46.2786093 L40.0195992,44.4282026 C38.9678259,43.4153838 38.9678259,41.7523732 40.0195992,40.7382602 L41.9414442,38.8888889 L43.4345699,40.3256783 C44.2656671,41.1270294 44.4605395,42.3399271 43.9409693,43.3403219 L42.9023667,45.3532765 L44.9927427,44.3531406 C46.0316141,43.8528139 47.2911618,44.0404687 48.1222589,44.8407845 L49.6153846,46.2786093 L47.6935396,48.1292748 C46.6417663,49.1420936 44.9147937,49.1420936 43.8630204,48.1292748 L41.9414442,46.2786093 Z"
                id="Path"
                fill="#ED9B40"></path>
              <path
                d="M50.7883303,34.9943081 C51.3336705,33.9412576 52.411181,33.3550033 53.4628893,33.5268793 L55.3846154,33.8480087 L54.8911484,36.5536065 C54.6186127,38.0461408 53.2940999,39.0845694 51.9308839,38.8577925 L49.4769874,38.4461731 L47.0099212,38.0461408 C45.6596062,37.8196397 44.7766873,36.4189749 45.0494918,34.9275441 L45.54269,32.2222222 L47.4644161,32.5419722 C48.5161244,32.7152275 49.3210992,33.6082652 49.4509165,34.7810495 L49.7237209,37.0998575 L50.7883303,34.9943081 Z"
                id="Path"
                fill="#ED9B40"></path>
              <path
                d="M35.0768705,51.3335049 L37.0949548,50.4535827 C38.4911402,49.6001574 40.0651986,49.693449 41.0042213,50.666663 L42.6923077,52.4535565 L39.9761307,55.2934312 C38.9735256,56.3469642 37.6661454,56.8123183 36.5492497,56.6268391 C35.7368663,56.4935259 35.0897447,55.853457 34.7723581,55.0668263 L34.2140416,53.6536513 C33.9473634,53.0268309 33.4649779,52.5200751 32.8685645,52.2402002 L31.5230874,51.6534014 C30.7742865,51.3202564 30.1649989,50.6387859 30.0380969,49.7867407 C30.0126114,49.6134059 30,49.4400712 30,49.2667364 C30,48.2267279 30.4442886,47.0934278 31.3073802,46.1867325 L34.0109458,43.3333333 L35.7116436,45.1067024 C36.6380549,46.0934408 36.7271228,47.7467452 35.9147394,49.2134664 L35.0768705,51.3335049 Z"
                id="Path"
                fill="#ED9B40"></path>
              <path
                d="M49.8452966,17.1167373 C50.9286222,16.1145113 51.0781531,14.3705705 50.1938583,13.2156291 L48.5871745,11.1111111 L47.0678889,12.5098552 C46.2333517,13.2926376 45.9345477,14.5502215 46.3204922,15.6538151 L47.0926388,17.8481719 L47.1052716,17.8610288 L47.0926388,17.8481719 L46.9482642,17.7532328 L47.928465,18.8888889 L49.8452966,17.1167373 Z"
                id="Path"
                fill="#F1AC42"></path>
              <path
                d="M41.8424928,9.91173027 C40.5952605,8.41025733 38.0769231,9.00516397 38.0769231,9.00516397 L42.5957042,14.4444444 C42.5957042,14.4444444 43.0899687,11.4132032 41.8424928,9.91173027 Z"
                id="Path"
                fill="#F1AC42"></path>
              <path
                d="M56.4885734,25.6936192 L55.9571324,23.3333333 L53.6034956,23.6568409 C52.3004305,23.8365508 51.3006718,24.6392682 51.1110398,25.6564669 L50.7692308,27.4858819 L50.9350278,28.8888889 L50.9971035,28.8804565 L54.008428,28.4613153 C55.6787087,28.2336397 56.7921418,26.9995293 56.4885734,25.6936192 Z"
                id="Path"
                fill="#F1AC42"></path>
              <path
                d="M53.4992459,33.3638983 C52.4674345,33.1867981 51.4103091,33.7908719 50.8752861,34.8759304 L49.8305541,37.045479 L49.6153846,38.4366963 L51.996224,38.8568473 C53.3336498,39.0905173 54.6331045,38.020525 54.9004841,36.4826244 L55.3846154,33.6947886 L53.4992459,33.3638983 Z"
                id="Path"
                fill="#F1AC42"></path>
              <path
                d="M48.0435673,44.0390306 C47.1691095,43.3001143 45.8434191,43.1268 44.7499933,43.5886526 L42.5495622,44.5125969 L41.5384615,45.3672155 L43.5609457,47.0762136 C44.667951,48.0116325 46.4856123,48.0116325 47.5926175,47.0762136 L49.6153846,45.3672155 L48.0435673,44.0390306 Z"
                id="Path"
                fill="#F1AC42"></path>
              <path
                d="M40.9660389,50.7811856 C40.0057766,49.8265475 38.3961149,49.7350365 36.9683495,50.5719016 L34.9046186,51.4352995 L33.4615385,52.8897282 C33.693678,53.1250035 33.8857841,53.4035975 34.0220049,53.7108902 L34.5932185,55.0973626 C34.9177839,55.8689788 35.5795427,56.4968306 36.4103012,56.6275993 C37.5524598,56.8095382 38.8894113,56.3530664 39.9146941,55.3196422 L42.6923077,52.5339726 L40.9660389,50.7811856 Z"
                id="Path"
                fill="#F1AC42"></path>
              <path
                d="M9.01928825,29.5064236 L12.0541048,29.959891 C13.7168495,30.2132586 15.3148805,29.239808 15.6207663,27.78667 L16.1538462,25.1599816 L13.7930188,24.8119737 C12.4983511,24.6133092 11.1897581,25.1599816 10.5551017,26.1732866 L9.28584133,28.1865934 L8.90633491,25.9186218 C8.71595111,24.7866369 7.71321757,23.8933366 6.40459828,23.6933472 L4.04503206,23.3333333 L3.51195217,25.9599665 C3.20604015,27.4132425 4.32427564,28.7866166 5.99971085,29.0399843 L9.01928825,29.5064236 Z"
                id="Path"
                fill="#F1AC42"></path>
              <path
                d="M9.83040184,13.1608568 C8.90900244,14.2857396 9.06473153,15.9842925 10.1924713,16.9604345 L12.2169496,18.7102149 L14.2414279,20.4587792 C15.3717466,21.4348954 17.0198907,21.2961673 17.9412901,20.1725522 L19.6153846,18.1215388 L18.0308269,16.7604404 C17.1613552,15.9980307 15.929787,15.8980596 14.9681726,16.4979902 L13.0617873,17.6728327 L13.0488121,17.685355 L13.0617873,17.6728327 L13.8650669,15.5355864 C14.2673782,14.460715 13.9572095,13.235861 13.0864482,12.4734514 L11.5044964,11.1111111 L9.83040184,13.1608568 Z"
                id="Path"
                fill="#F1AC42"></path>
              <path
                d="M17.1931502,9.89075942 C15.6670125,11.3614483 16.2722709,14.3305414 16.2722709,14.3305414 C16.2722709,14.3305414 19.3548555,14.9132797 20.882455,13.4425908 C22.4100545,11.9719018 21.8047662,9.00278004 21.8047662,9.00278004 C21.8047662,9.00278004 18.7207497,8.4200705 17.1931502,9.89075942 Z"
                id="Path"
                fill="#F1AC42"></path>
              <path
                d="M18.0586493,46.2786093 L19.9803967,44.4282026 C21.0321755,43.4153838 21.0321755,41.7523732 19.9803967,40.7382602 L18.0586493,38.8888889 L16.5640912,40.3256783 C15.7343606,41.1270294 15.5382777,42.3399271 16.0589794,43.3403219 L17.0964585,45.3532765 L15.0072006,44.3531406 C13.9671142,43.8528139 12.708904,44.0404687 11.8765661,44.8407845 L10.3846154,46.2786093 L12.3063628,48.1292748 C13.3581416,49.1420936 15.0851231,49.1420936 16.1369019,48.1292748 L18.0586493,46.2786093 Z"
                id="Path"
                fill="#F1AC42"></path>
              <path
                d="M9.21187085,34.9943081 C8.66651318,33.9412576 7.58880687,33.3550033 6.53709173,33.5268793 L4.61538462,33.8480087 L5.10749667,36.5536065 C5.3801755,38.0461408 6.70456948,39.0845694 8.06922693,38.8577925 L10.5219925,38.4461731 L12.9903206,38.0461408 C14.3393887,37.8196397 15.2223359,36.4189749 14.9509741,34.9275441 L14.4575451,32.2222222 L12.5358648,32.5419722 C11.4841228,32.7152275 10.6778052,33.6082652 10.5479568,34.7810495 L10.275278,37.0998575 L9.21187085,34.9943081 Z"
                id="Path"
                fill="#F1AC42"></path>
              <path
                d="M24.9231137,51.3335049 L22.9050073,50.4535827 C21.5088511,49.6001574 19.933745,49.693449 18.9957752,50.666663 L17.3076923,52.4535565 L20.0238637,55.2934312 C21.0265455,56.3469642 22.3338704,56.8123183 23.449529,56.6268391 C24.263093,56.4935259 24.9091361,55.853457 25.2264432,55.0668263 L25.7861773,53.6536513 C26.0527236,53.0268309 26.5350292,52.5200751 27.1303116,52.2402002 L28.475602,51.6534014 C29.2257151,51.3202564 29.8350014,50.6387859 29.9605895,49.7867407 C29.986075,49.6134059 30,49.4400712 30,49.2667364 C30,48.2267279 29.5546614,47.0934278 28.6915716,46.1867325 L25.9879853,43.3333333 L24.2884996,45.1067024 C23.3619326,46.0934408 23.2731014,47.7467452 24.0841431,49.2134664 L24.9231137,51.3335049 Z"
                id="Path"
                fill="#F1AC42"></path>
              <path
                d="M10.1538946,17.1167373 C9.07139179,16.1145113 8.92190945,14.3705705 9.80634881,13.2156291 L11.4132905,11.1111111 L12.9317856,12.5098552 C13.7676181,13.2926376 14.065345,14.5502215 13.6791715,15.6538151 L12.9081139,17.8481719 L12.8956592,17.8610288 L12.9081139,17.8481719 L13.051356,17.7532328 L12.0709921,18.8888889 L10.1538946,17.1167373 Z"
                id="Path"
                fill="#ED9B40"></path>
              <path
                d="M18.1565431,9.91173027 C19.4042088,8.41025733 21.9230769,9.00516397 21.9230769,9.00516397 L17.4044156,14.4444444 C17.4044156,14.4444444 16.9100713,11.4132032 18.1565431,9.91173027 Z"
                id="Path"
                fill="#ED9B40"></path>
              <path
                d="M3.51180405,25.6936192 L4.04331769,23.3333333 L6.39595124,23.6568409 C7.70072566,23.8365508 8.70051306,24.6392682 8.89033748,25.6564669 L9.23076923,27.4858819 L9.06625123,28.8888889 L9.00295896,28.8804565 L5.99225342,28.4613153 C4.32174082,28.2336397 3.20679083,26.9995293 3.51180405,25.6936192 Z"
                id="Path"
                fill="#ED9B40"></path>
              <path
                d="M6.50110598,33.3638983 C7.53312679,33.1867981 8.59065206,33.7908719 9.1257974,34.8759304 L10.1692913,37.045479 L10.3846154,38.4366963 L8.00455054,38.8568473 C6.66544756,39.0905173 5.36585409,38.020525 5.09828142,36.4826244 L4.61538462,33.6947886 L6.50110598,33.3638983 Z"
                id="Path"
                fill="#ED9B40"></path>
              <path
                d="M11.954894,44.0390306 C12.8309299,43.3001143 14.1551965,43.1268 15.2498878,43.5886526 L17.4488324,44.5125969 L18.4615385,45.3672155 L16.4388987,47.0762136 C15.3319012,48.0116325 13.5142526,48.0116325 12.4072551,47.0762136 L10.3846154,45.3672155 L11.954894,44.0390306 Z"
                id="Path"
                fill="#ED9B40"></path>
              <path
                d="M19.0339159,50.7811856 C19.9930783,49.8265475 21.6037726,49.7350365 23.0315006,50.5719016 L25.0951774,51.4352995 L26.5384615,52.8897282 C26.3048236,53.1250035 26.1140389,53.4035975 25.9777411,53.7108902 L25.4053603,55.0973626 C25.080884,55.8689788 24.4202441,56.4968306 23.5882984,56.6275993 C22.4474325,56.8095382 21.1105697,56.3530664 20.0852331,55.3196422 L17.3076923,52.5339726 L19.0339159,50.7811856 Z"
                id="Path"
                fill="#ED9B40"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default PromiseKeptMedalIcon;
