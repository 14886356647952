import React, { ReactNode, useContext, useMemo } from 'react';

import { NestingContext } from 'components/contentful-renders/blocks/NestingProvider';
import {
  FifthLevelOrderedListItem,
  FifthLevelUnorderedListItem,
  FirstLevelOrderedListItem,
  FirstLevelUnorderedListItem,
  FourthLevelOrderedListItem,
  FourthLevelUnorderedListItem,
  SecondLevelOrderedListItem,
  SecondLevelUnorderedListItem,
  SixthLevelOrderedListItem,
  SixthLevelUnorderedListItem,
  ThirdLevelOrderedListItem,
  ThirdLevelUnorderedListItem,
} from './components';

export type AppListItemProps = {
  listType: 'ordered' | 'unordered';
  darkMode: boolean;
  children: ReactNode;
};

const AppListItem = ({ listType, children, darkMode }: AppListItemProps) => {
  const nestingContext = useContext(NestingContext);

  const UnorderedListItem = useMemo(() => {
    switch (nestingContext.ulNestingLevel) {
      case 1:
        return FirstLevelUnorderedListItem;
      case 2:
        return SecondLevelUnorderedListItem;
      case 3:
        return ThirdLevelUnorderedListItem;
      case 4:
        return FourthLevelUnorderedListItem;
      case 5:
        return FifthLevelUnorderedListItem;
      case 6:
        return SixthLevelUnorderedListItem;
      default:
        return FirstLevelUnorderedListItem;
    }
  }, [nestingContext]);

  const OrderedListItem = useMemo(() => {
    switch (nestingContext.olNestingLevel) {
      case 1:
        return FirstLevelOrderedListItem;
      case 2:
        return SecondLevelOrderedListItem;
      case 3:
        return ThirdLevelOrderedListItem;
      case 4:
        return FourthLevelOrderedListItem;
      case 5:
        return FifthLevelOrderedListItem;
      case 6:
        return SixthLevelOrderedListItem;
      default:
        return FirstLevelOrderedListItem;
    }
  }, [nestingContext]);

  return (
    <>
      {listType === 'ordered' ? (
        <OrderedListItem darkMode={darkMode}>{children}</OrderedListItem>
      ) : (
        <UnorderedListItem darkMode={darkMode}>{children}</UnorderedListItem>
      )}
    </>
  );
};

export default AppListItem;
