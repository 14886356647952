import React from 'react';
import { Document } from '@contentful/rich-text-types';

import useContentfulRenderer from 'hooks/useContentfulRenderer';
import {
  RankIndex,
  RankOptionBurgerContainer,
  RankOptionBurgerLine,
  RankOptionBurgerSpace,
  RankOptionContainer,
  RankOptionTextContainer,
} from '../components';
import theme from 'utils/theme';

type RankOptionProps = {
  index: number;
  content: Document | undefined;
  userInput?: string;
};

const RankOption = ({ content, index, userInput }: RankOptionProps) => {
  const renderContent = useContentfulRenderer(content, {
    additionalStyles: {
      paragraph: {
        fontSize: theme.typography.large18,
        margin: 0,
        color: theme.colors.grey[100],
      },
    },
  });

  return (
    <RankOptionContainer>
      <RankIndex>{index + 1}</RankIndex>
      <RankOptionTextContainer>
        {renderContent ? renderContent : userInput}
        <RankOptionBurgerContainer>
          <RankOptionBurgerLine />
          <RankOptionBurgerSpace />
          <RankOptionBurgerLine />
        </RankOptionBurgerContainer>
      </RankOptionTextContainer>
    </RankOptionContainer>
  );
};

export default RankOption;
