import React, { useMemo } from 'react';

import { ChallengeApplet } from 'models/ChallengeApplet';
import { groupChallengeApplets } from 'helpers/AppletHelpers';
import useRenderSubmissionApplet from 'hooks/useRenderSubmissionApplet';

type SubmissionAppletsProps = {
  applets: ChallengeApplet[];
};

const SubmissionApplets = ({ applets }: SubmissionAppletsProps) => {
  const groupedApplets = useMemo(
    () => groupChallengeApplets(applets),
    [applets],
  );
  const renderApplet = useRenderSubmissionApplet();
  return <>{groupedApplets.map((applet) => renderApplet(applet))}</>;
};

export default SubmissionApplets;
