import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

import localizedStrings from 'localization';
import {
  FieldErrorMessage,
  PublishShowcaseAppletsContainer,
  PublishShowcaseDescription,
} from 'modules/profile/components';
import { selectAppletsVideoToAddShowcase, selectExistsAppletVideosToAddShowcase } from 'modules/applets/selectors';
import { QuestApplet } from 'models/QuestApplet';
import AppletPreview from 'modules/profile/portfolio/quest-applets/AppletPreview';
import { StepProps } from '../typings';

const SelectFeaturedVideo = ({
  values,
  setFieldValue,
  setNextButtonText,
}: StepProps) => {
  const applets = useSelector(selectAppletsVideoToAddShowcase);
  const existsAppletVideoToAdd = useSelector(selectExistsAppletVideosToAddShowcase)

  const handleAppletSelect = useCallback(
    (applet: QuestApplet) => {
      setFieldValue('videoApplet', applet.id);
    },
    [setFieldValue],
  );

  useEffect(() => {
    if (values.videoApplet !== '') {
      setNextButtonText(localizedStrings.continue);
    } else {
      setNextButtonText(localizedStrings.selectAnArtifact);
    }
  }, [setNextButtonText, values.videoApplet]);

  return (
    <>
      <PublishShowcaseDescription>
        {localizedStrings.selectShowcaseVideo}
      </PublishShowcaseDescription>
      {!existsAppletVideoToAdd ? (
        <FieldErrorMessage>
          {localizedStrings.showcaseVideoError}
        </FieldErrorMessage>
      ) : (
        <PublishShowcaseAppletsContainer>
          {applets.map((applet) => (
            <AppletPreview
              key={applet.id}
              applet={applet}
              onAppletClick={handleAppletSelect}
              showTitle={true}
              height="168px"
              width="178px"
              selected={values.videoApplet === applet.id}
            />
          ))}
        </PublishShowcaseAppletsContainer>
      )}
    </>
  );
};

export default SelectFeaturedVideo;
