import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import {
  selectNewOnboardingWeekId,
  selectNewWeekForOnboarding,
  selectQuestOffboardingSeen,
} from 'modules/onboarding/selectors';
import { AppDispatch } from 'redux/typings';
import { resetHasCompletedWeeks } from 'modules/loading-screen/weeks/actions';
import { OFFBOARDING_QUEST, ONBOARDING } from 'routes';
import { ONBOARDING_WEEK } from 'modules/onboarding/routes';
import { ModalContainer } from 'components/modal/components';
import localizedStrings from 'localization';
import { LoadingWeekText } from './components';
import LoadingSpinner from 'components/loading-spinner';

import {
  selectIsQuestCompleted,
  selectActiveQuestId,
  selectActiveQuestOffboardings,
} from 'modules/loading-screen/quests/selectors';
import {
  selectHasCompletedAllActiveQuestWeeks,
  selectHasCompletedWeek,
} from 'modules/loading-screen/weeks/selectors';
import { getActiveQuest } from 'modules/loading-screen/quests/actions';
import {
  notifyQuestOffboardingSeen,
  setNewOnboardingWeekId,
} from 'modules/onboarding/actions';
import { selectIsCelebrateModalOpen } from 'modules/modal/selectors';

const LoadingNewWeek = () => {
  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();
  const [hasRequestedQuest, setHasRequestedQuest] = useState(false);
  const hasCompletedWeek = useSelector(selectHasCompletedWeek);
  const newWeekForOnboarding = useSelector(selectNewWeekForOnboarding);
  const newOnboardingWeekId = useSelector(selectNewOnboardingWeekId);
  const questOffboardings = useSelector(selectActiveQuestOffboardings);
  const isQuestCompleted = useSelector(selectIsQuestCompleted);
  const areAllWeeksCompleted = useSelector(
    selectHasCompletedAllActiveQuestWeeks,
  );
  const questId = useSelector(selectActiveQuestId);
  const hasSeenQuestOffboarding = useSelector(
    selectQuestOffboardingSeen(questId),
  );
  const isCelebrateModalOpen = useSelector(selectIsCelebrateModalOpen);

  useEffect(() => {
    if (hasCompletedWeek) {
      dispatch(getActiveQuest());
      setHasRequestedQuest(true);
    }
  }, [dispatch, hasCompletedWeek]);

  useEffect(() => {
    if (newWeekForOnboarding) {
      dispatch(setNewOnboardingWeekId(newWeekForOnboarding.id));
      dispatch(resetHasCompletedWeeks());
    }
  }, [newWeekForOnboarding, dispatch]);

  useEffect(() => {
    if (newOnboardingWeekId && !isCelebrateModalOpen) {
      setHasRequestedQuest(false);
      history.push(ONBOARDING + ONBOARDING_WEEK);
    }
  }, [newOnboardingWeekId, history, isCelebrateModalOpen]);

  useEffect(() => {
    if (
      (isQuestCompleted || areAllWeeksCompleted) &&
      !hasSeenQuestOffboarding
    ) {
      dispatch(resetHasCompletedWeeks());
      setHasRequestedQuest(false);
      if ((!questOffboardings || questOffboardings.length === 0) && questId) {
        dispatch(notifyQuestOffboardingSeen({ questId }));
      } else {
        history.push(OFFBOARDING_QUEST);
      }
    }
  }, [
    isQuestCompleted,
    areAllWeeksCompleted,
    hasSeenQuestOffboarding,
    questOffboardings,
    questId,
    history,
    dispatch,
  ]);

  return (
    <ModalContainer
      visible={
        (hasCompletedWeek || hasRequestedQuest) &&
        Boolean(newWeekForOnboarding?.id)
      }>
      <LoadingWeekText>{localizedStrings.loadingNewWeek}</LoadingWeekText>
      <LoadingSpinner size={48} />
    </ModalContainer>
  );
};

export default LoadingNewWeek;
