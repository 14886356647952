import React from 'react';
import { IApplet, IQuizQuestionOption } from '../../../../models/contentful';

import Option from './Option';
import { MadlibAnswer } from 'models/Applet';
import { MadlibQuestionContainer } from 'modules/challenge/components';
import MadlibApplet from 'components/applets/madlib';
import useMadlibSentenceRenderer from '../../../../hooks/useMadlibSentenceRenderer';

type QuizOptionProfileAndSubmissionProps = {
  option: IQuizQuestionOption | IApplet;
  answer?: string;
  madlibAnswers?: MadlibAnswer[];
  questionType?: string;
};

const QuizOptionProfileAndSubmission = ({
  option,
  answer,
  madlibAnswers,
  questionType,
}: QuizOptionProfileAndSubmissionProps) => {
  const madlibAnswersControlled =
    questionType === 'OPTION' ? undefined : madlibAnswers;

  const regularOption =
    option.sys.contentType.sys.id === 'quizQuestionOption'
      ? (option as IQuizQuestionOption)
      : undefined;

  const madlibOption =
    option.sys.contentType.sys.id === 'applet'
      ? (option as IApplet)
      : undefined;

  const value =
    option.sys.contentType.sys.id === 'applet'
      ? madlibOption?.sys.id
      : regularOption?.fields.result || regularOption?.fields.title;

  const renderedMadlibSentence = useMadlibSentenceRenderer(
    madlibOption?.fields?.madlibSentence,
    { madlibAnswers: madlibAnswersControlled },
  );

  const renderedRegularOption = useMadlibSentenceRenderer(
    regularOption?.fields.option,
  );

  return (
    <Option
      renderedOption={
        option.sys.contentType.sys.id === 'applet' ? (
          <MadlibQuestionContainer>
            <MadlibApplet disabled madlibSentence={renderedMadlibSentence} />
          </MadlibQuestionContainer>
        ) : (
          renderedRegularOption
        )
      }
      disabled
      value={value}
      answer={answer}
    />
  );
};

export default QuizOptionProfileAndSubmission;
