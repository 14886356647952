import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'redux/typings';

const selectScheduleCommitState = (state: RootState) => state.scheduleCommit;

export const selectEditingScheduleCommit = createSelector(
  selectScheduleCommitState,
  (scheduleCommit) => scheduleCommit.editingScheduleCommit,
);

export const selectTotalHours = createSelector(
  selectEditingScheduleCommit,
  (editingScheduleCommit) =>
    Object.values(editingScheduleCommit).reduce((acc, value) => acc + value),
);

export const selectHasAlreadyAScheduleCommit = createSelector(
  selectScheduleCommitState,
  (schedule) => schedule.hasCommittedSchedule,
);

export const selectEditedWeekId = createSelector(
  selectScheduleCommitState,
  (schedule) => schedule.editedWeekId,
);
