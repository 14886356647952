import React from 'react';
import { useSelector } from 'react-redux';
import { selectIsBackendDown } from '../../modules/error/selectors';
import LocalizedStrings from '../../localization';
import { TxtMessage } from 'common/CommonComponents';
import ErrorActions from 'components/error-actions';
const BackendDownError = () => {
  const backendDown = useSelector(selectIsBackendDown);
  return backendDown === true ? (
    <>
      <TxtMessage>{LocalizedStrings.errorBackendDown}</TxtMessage>
      <ErrorActions hasQuitButton />
    </>
  ) : null;
};
export default BackendDownError;
