import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Modal from 'components/modal';
import { Artifact } from '../home/playlist/typings';
import ButtonPrimary from 'components/button-primary';
import {
  ButtonsPlaylistRow,
  CancelPlaylistButton,
  ConfirmModalPropmt,
  ConfirmPlaylistButton,
} from './components';
import localizedStrings from 'localization';
import { AppDispatch } from 'redux/typings';
import {
  markArtifactCompleted,
  submitCompletedArtifact,
  updatePlaylistSelected,
} from '../home/playlist/actions';
import { selectPlaylistListSelected } from 'modules/home/playlist/selectors';
import { setSelectedArtifact } from '../home/playlist/actions';

type ConfirmModalProps = {
  open?: boolean;
  close: () => void;
  artifact?: Artifact;
};

const ConfirmModal = ({ open = false, close, artifact }: ConfirmModalProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const listSelected = useSelector(selectPlaylistListSelected);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const addToSelectedContent = () => {
    if (artifact) {
      if (!listSelected.some((el: string) => el === artifact.id)) {
        dispatch(setSelectedArtifact({ idArtifactSelected: artifact.id }));
        dispatch(updatePlaylistSelected([...listSelected, artifact.id]));
      }
    }
  };

  const handleYes = useCallback(() => {
    if (artifact) {
      const payload = {
        id: artifact.id,
        weekId: artifact.weekId,
      };
      dispatch(markArtifactCompleted(payload));
      dispatch(submitCompletedArtifact(payload));
      addToSelectedContent();
      close();
    }
  }, [artifact, close, dispatch, addToSelectedContent]);

  const handleLater = () => {
    addToSelectedContent();
    close();
  };

  return artifact ? (
    <Modal open={open} closeModal={close} hideCloseButton width="25rem">
      <ConfirmModalPropmt>
        {localizedStrings.artifactReadPrompt(artifact.title)}
      </ConfirmModalPropmt>
      <ButtonsPlaylistRow>
        <CancelPlaylistButton onClick={close}>
          {localizedStrings.cancel}
        </CancelPlaylistButton>
        <ButtonPrimary onClick={handleLater}>
          {localizedStrings.later}
        </ButtonPrimary>
        <ConfirmPlaylistButton onClick={handleYes}>
          {localizedStrings.done}
        </ConfirmPlaylistButton>
      </ButtonsPlaylistRow>
    </Modal>
  ) : null;
};

export default ConfirmModal;
