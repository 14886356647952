import styled from '@emotion/styled';

import background from 'assets/images/MapBackground.webp';
import { MapBackground, Panel } from 'components/components';

export const LoadingContainer = styled.div`
  ${(props) => `
    font-family: ${props?.theme?.typography.normal};
    width: 100vw;
    height: 100vh;
    background-image: url(${background});
    background-position: center;
    background-size: cover;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `}
`;

export const LoadingMessage = styled.div`
  ${(props) => `
    color: ${props?.theme?.colors.white[100]};
    font-size: ${props?.theme?.typography.large32};
    margin: 20px;
  `}
`;

export const ScheduleCommitBackground = styled(MapBackground)`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 3rem;
`;

export const ScheduleCommitContainer = styled(Panel)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 55rem;
  max-width: 80%;
`;

export const ScheduleCommitTitle = styled.div`
  font-size: ${(props) => props?.theme?.typography.large24};
  line-height: ${(props) => props?.theme?.typography.large32};
  color: ${(props) => props?.theme?.colors.blue1[100]};
  margin-bottom: 1rem;
`;

export const ScheduleCommitPrompt = styled.div`
  font-size: ${(props) => props?.theme?.typography.large18};
  line-height: ${(props) => props?.theme?.typography.large24};
  color: ${(props) => props?.theme?.colors.grey[100]};
  margin-bottom: 0.5rem;
`;

export const ScheduleCommitDescription = styled.div`
  font-size: ${(props) => props?.theme?.typography.large13};
  line-height: ${(props) => props?.theme?.typography.large18};
  color: ${(props) => props?.theme?.colors.grey[100]};
  margin-bottom: 2rem;
`;

export const ScheduleCommitControls = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 19rem;
  max-width: 80%;
`;

export const ScheduleCommitContainerSelectedHours = styled.div`
  font-size: ${(props) => props?.theme?.typography.large13};
  line-height: ${(props) => props?.theme?.typography.large18};
  color: ${(props) => props?.theme?.colors.grey[100]};
  align-self: flex-start;
  margin-bottom: 2rem;
`;

type ScheduleDateInputContainerProps = {
  active: boolean;
};

export const ScheduleDateInputContainer = styled.div<
  ScheduleDateInputContainerProps
>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.4rem 0.7rem 0.4rem 0.7rem;
  background-color: ${(props) =>
    props.active ? 'rgba(26, 26, 26, 0.8)' : 'rgba(26,26,26,0.3)'};
  border-radius: 0.5rem;
  margin-bottom: 0.8rem;
`;

export const ScheduleDateInputDay = styled.div`
  font-size: ${(props) => props?.theme?.typography.large16};
  line-height: ${(props) => props?.theme?.typography.large20};
  color: #ffffff;
`;

export const ScheduleDateInputControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 5rem;
`;

export const ScheduleDateInputHours = styled.div`
  font-size: ${(props) => props?.theme?.typography.large20};
  line-height: ${(props) => props?.theme?.typography.large20};
  font-weight: ${(props) => props?.theme?.typography.semiBold};
  color: #ffffff;
`;

export const DailyReminderTimePickerContainer = styled.div`
  padding-top: 1rem;
  padding-bottom: 1rem;
`;
