import React from 'react';

import theme from 'utils/theme';
import { TopAlignedSvg } from 'components/components';

type WarningIconProps = {
  width?: string;
  height?: string;
};

const WarningIcon = ({
  width = theme.typography.large24,
  height = theme.typography.large24,
}: WarningIconProps) => (
  <TopAlignedSvg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ overflow: 'visible' }}>
    <path
      d="M8 0C10.1217 0 12.1566 0.842855 13.6569 2.34315C15.1571 3.84344 16 5.87827 16 8C16 10.1217 15.1571 12.1566 13.6569 13.6569C12.1566 15.1571 10.1217 16 8 16C5.87827 16 3.84344 15.1571 2.34315 13.6569C0.842855 12.1566 0 10.1217 0 8C0 5.87827 0.842855 3.84344 2.34315 2.34315C3.84344 0.842855 5.87827 0 8 0ZM8 3.42857C7.8549 3.42845 7.71138 3.45859 7.57858 3.51706C7.44579 3.57554 7.32666 3.66107 7.22879 3.76819C7.13092 3.87531 7.05646 4.00167 7.01018 4.13918C6.9639 4.2767 6.94681 4.42236 6.96 4.56686L7.37714 9.14514C7.39332 9.29907 7.46591 9.44155 7.58093 9.54511C7.69594 9.64868 7.84523 9.70599 8 9.70599C8.15477 9.70599 8.30406 9.64868 8.41908 9.54511C8.53409 9.44155 8.60668 9.29907 8.62286 9.14514L9.03886 4.56686C9.05204 4.42246 9.03498 4.27689 8.98877 4.13945C8.94256 4.00201 8.8682 3.87572 8.77046 3.76861C8.67271 3.66151 8.55371 3.57596 8.42106 3.51741C8.2884 3.45886 8.145 3.42861 8 3.42857ZM8 12.5714C8.24248 12.5714 8.47504 12.4751 8.6465 12.3036C8.81796 12.1322 8.91429 11.8996 8.91429 11.6571C8.91429 11.4147 8.81796 11.1821 8.6465 11.0106C8.47504 10.8392 8.24248 10.7429 8 10.7429C7.75752 10.7429 7.52496 10.8392 7.3535 11.0106C7.18204 11.1821 7.08571 11.4147 7.08571 11.6571C7.08571 11.8996 7.18204 12.1322 7.3535 12.3036C7.52496 12.4751 7.75752 12.5714 8 12.5714Z"
      fill="#E53935"
    />
  </TopAlignedSvg>
);
export default WarningIcon;
