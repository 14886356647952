import axios from 'axios';
import { apiUrl } from '../config';
import store from '../redux/store';
import { refresh } from '../modules/auth/actions';
import { selectIdToken } from '../modules/auth/selectors';

const client = axios.create({
  baseURL: apiUrl,
  timeout: 100000,
  headers: {
    'content-type': 'application/json',
  },
});

client.interceptors.request.use(
  async (config) => {
    const token = selectIdToken(store.getState());
    config.headers.authorization = `Bearer ${token}`;
    return config;
  },
  (error) => Promise.reject(error),
);

client.interceptors.response.use(
  async (res) => {
    const refreshedToken = res.headers['x-refreshed-id-token'];

    if (refreshedToken) {
      store.dispatch(refresh({ jwt: refreshedToken }));
    }

    return res;
  },
  (error) => Promise.reject(error),
);

export default client;
