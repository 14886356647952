import React, { useMemo } from 'react';

import { BlockNodeProps } from 'components/contentful-renders/typings';
import MissingContentfulRender from 'components/contentful-renders/missing-contentful-render';
import PlaylistArtifactLink from './PlaylistArtifactLink';
import ResourceLink from './ResourceLink';
import { LinkProps } from 'components/contentful-renders/components';
import ReadingLink from './ReadingLink';

type EntryHyperlinkProps = BlockNodeProps & LinkProps;

const EntryHyperlink = ({
  node,
  children,
  ...linkProps
}: EntryHyperlinkProps) => {
  const renderHyperLink = useMemo(() => {
    switch (node.data.target.sys.contentType.sys.id) {
      case 'reading':
        return <ReadingLink node={node} children={children} {...linkProps} />;
      case 'resourceLink':
        return <ResourceLink node={node} children={children} {...linkProps} />;
      case 'playlistArtifact':
        return (
          <PlaylistArtifactLink
            node={node}
            children={children}
            {...linkProps}
          />
        );
      default:
        return (
          <MissingContentfulRender
            componentType={`Entry HiperLink of type ${node.data.target.sys.contentType.sys.id}`}
          />
        );
    }
  }, [children, node, linkProps]);

  return renderHyperLink;
};

export default EntryHyperlink;
