import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { StepSchedule } from 'models/Schedule';
import { selectContentsContentful } from 'modules/loading-screen/contentful/contents/selectors';
import { selectStepsContentful } from 'modules/loading-screen/contentful/steps/selectors';
import { selectContents } from 'modules/loading-screen/contents/selectors';

const useStepCardInformation = (step: StepSchedule) => {
  const contents = useSelector(selectContents);
  const contentsContentful = useSelector(selectContentsContentful);
  const steps = useSelector(selectStepsContentful);

  const [challengeSubtitle, stepNumber] = useMemo(() => {
    const challengeUser = contents[step.challengeId || ''];
    const totalSteps = challengeUser?.stepIds.length + 1;
    const challengeContentful = contentsContentful[challengeUser?.contentfulId];
    const subtitle = challengeContentful?.subtitle;
    const stepNumber = steps[step.id]?.stepNumber;
    const currentStepNumber =
      step.type === 'step' ? Number(stepNumber) + 1 : totalSteps;

    return [subtitle, `${currentStepNumber}/${totalSteps}`];
  }, [contents, contentsContentful, step, steps]);

  return [challengeSubtitle, stepNumber];
};

export default useStepCardInformation;
