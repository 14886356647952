import React, { useMemo } from 'react';
import { getAvatarColors, getUserInitials } from 'helpers/AvatarHelpers';
import { AvatarImage, AvatarInitials } from './components';

type AvatarProps = {
  size?: number;
  displayName: string;
  uri: string | null | undefined;
};

const Avatar = ({ size = 80, uri, displayName }: AvatarProps) => {
  const defaultAvatarColors = useMemo(
    () => getAvatarColors(displayName),
    [displayName],
  );
  const userInitials = useMemo(
    () => getUserInitials(displayName),
    [displayName],
  );

  return (
    <>
      {uri ? (
        <AvatarImage src={uri} size={size} />
      ) : (
        <AvatarInitials
          size={size}
          color={defaultAvatarColors?.text}
          backgroundColor={defaultAvatarColors?.background}>
          {userInitials}
        </AvatarInitials>
      )}
    </>
  );
};

export default Avatar;
