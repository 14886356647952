import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  selectSelectedContentId,
  selectSelectedContentPostButtonColor,
  selectSelectedContentPostShare,
  selectSelectedContentPostUserId,
} from 'modules/loading-screen/contents/selectors';
import useContentfulRenderer from 'hooks/useContentfulRenderer';
import localizedStrings from 'localization';
import { CelebrateButton } from '../components';
import ShareModal from './ShareModal';

type ShareProps = {
  withShareMessage?: boolean;
};

const Share = ({ withShareMessage }: ShareProps) => {
  const [shareModalOpen, setShareModalOpen] = useState(false);

  const challengeId = useSelector(selectSelectedContentId);
  const postUserId = useSelector(selectSelectedContentPostUserId);
  const shareDoc = useSelector(selectSelectedContentPostShare);
  const postButtonColor = useSelector(selectSelectedContentPostButtonColor);

  const openShareModal = useCallback(() => setShareModalOpen(true), []);
  const closeShareModal = useCallback(() => setShareModalOpen(false), []);

  const share = useContentfulRenderer(shareDoc as any);

  return (
    <>
      <ShareModal
        open={shareModalOpen}
        close={closeShareModal}
        challengeId={challengeId}
        postId={postUserId ?? ''}
      />
      {withShareMessage ? share : null}
      <CelebrateButton
        backgroundColor={postButtonColor}
        small={withShareMessage}
        onClick={openShareModal}
        type="button">
        {localizedStrings.share}
      </CelebrateButton>
    </>
  );
};

export default Share;
