import styled from '@emotion/styled';

type WeekNumberTextProps = {
  isCurrentWeek?: boolean;
};

export const WeekNumberText = styled.div<WeekNumberTextProps>`
  color: ${(props) =>
    props.isCurrentWeek
      ? props?.theme?.colors.white[100]
      : props?.theme?.colors.grey[100]};
  font-size: ${(props) => props?.theme?.typography.large32};
  line-height: ${(props) => props?.theme?.typography.large24};
  padding-top: ${(props) => props?.theme?.typography.large16};
  display: flex;
  justify-content: center;
  font-weight: ${(props) =>
    props.isCurrentWeek ? props?.theme?.typography.semiBold : 'normal'};
`;

export const WeekProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SeparatorContainer = styled.div`
  padding: 0 0.3rem;
  margin-top: 1rem;
  flex-grow: 1;
`;

export const WeekProgressTimelineContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  padding: ${(props) => props?.theme?.typography.large32};
  background-color: ${(props) => props?.theme?.colors.weekProgressBackground};
  margin-top: ${(props) => props?.theme?.typography.large32};
  cursor: pointer;
`;

export const WeekProgressTimelineTopRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: ${(props) => props?.theme?.typography.large32};
`;

export const RowElement = styled.div`
  display: flex;
  align-items: center;
`;

export const QuestTitle = styled.span`
  font-size: ${(props) => props?.theme?.typography.large24};
  font-weight: ${(props) => props?.theme?.typography.semiBold};
  line-height: ${(props) => props?.theme?.typography.large24};
  color: ${(props) => props?.theme?.colors.white[100]};
`;

export const Overview = styled.span`
  font-size: ${(props) => props?.theme?.typography.large24};
  line-height: ${(props) => props?.theme?.typography.large24};
  color: ${(props) => props?.theme?.colors.gold[100]};
  margin: 0 ${(props) => props?.theme?.typography.large13};
`;

export const WeeksProgressContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const WeekTitle = styled.div`
  font-weight: ${(props) => props?.theme?.typography.semiBold};
  color: ${(props) => props?.theme?.colors.white[100]};
  font-size: ${(props) => props?.theme?.typography.large20};
  margin-top: 1rem;
`;
