import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import {
  ShareStepAppletInformation,
  ShareStepEmailPayload,
} from 'modules/applets/typings';
import useActionStatus from 'hooks/useActionStatus';
import LocalizedStrings from 'localization';
import { shareStep } from 'modules/applets/actions';
import {
  selectIsShareStepModalOpen,
  selectShareStepModalInformation,
} from './selectors';
import { closeShareStepModal } from './actions';
import { shareStepInitialValues } from './share-step-modal/shareStepInitialValues';
import theme from 'utils/theme';
import ShareByEmailModal from './ShareByEmailModal';
import shareStepValidationSchema from './share-step-modal/shareStepValidationSchema';
import useSetValuesOnInitialValuesChange from 'hooks/useSetValuesOnInitialValuesChange';
import { uploadFile } from 'modules/files/services';
import ShareStepModalPreview from './share-step-modal/ShareStepModalPreview';

const ShareStepModal = () => {
  const dispatch = useDispatch();
  const [isPreparingMail, setIsPreparingMail] = useState(false);
  const [hasSentMail, setHasSentMail] = useState(false);

  const isShareStepModalOpen = useSelector(selectIsShareStepModalOpen);
  const shareStepModalInformation = useSelector(
    selectShareStepModalInformation,
  );
  const [shareStepPending, shareStepFulfilled] = useActionStatus(shareStep);

  const domain = window.location.host;

  const closeModal = useCallback(() => {
    dispatch(closeShareStepModal());
  }, [dispatch]);

  const initialValues = useMemo(
    () => shareStepInitialValues(shareStepModalInformation),
    [shareStepModalInformation],
  );

  const [formikRef, assignRef] =
    useSetValuesOnInitialValuesChange<ShareStepEmailPayload>(initialValues);

  const onValidSubmit = useCallback(
    async (values: ShareStepEmailPayload) => {
      if (values.applets) {
        const appletsMap = values.applets.reduce(
          (acc, val) => ({
            ...acc,
            [val.appletContentfulId]: val,
          }),
          {} as Record<string, ShareStepAppletInformation>,
        );
        for (const applet of values.applets) {
          if (
            applet.file &&
            applet.file.uri &&
            applet.file.uri.includes(domain)
          ) {
            setIsPreparingMail(true);
            const uploadedFile = await uploadFile(
              {
                file: applet.file,
                id: '',
                appletId: applet.appletContentfulId,
              },
              () => {},
            );
            const existingApplet = appletsMap[applet.appletContentfulId];
            if (existingApplet) {
              appletsMap[applet.appletContentfulId] = {
                ...existingApplet,
                file: {
                  uri: uploadedFile.fileUrl,
                  type:
                    uploadedFile.fileType ?? existingApplet.file?.type ?? '',
                  name: existingApplet.appletContentfulId,
                },
              };
            }
          }
        }
        setIsPreparingMail(false);
        dispatch(
          shareStep({
            ...values,
            applets: Object.values(appletsMap),
          }),
        );
        setHasSentMail(true);
      }
    },
    [dispatch, domain],
  );

  const isSendButtonDisabled = useCallback(
    (values: ShareStepEmailPayload, isValid: boolean) => {
      const valuesCheck =
        !values.receiver || !values.subject || !values.stepId || !isValid;

      let appletSpecificCheck: boolean[] = [];
      if (values.applets) {
        for (const applet of values.applets) {
          switch (applet.type) {
            case 'rich-text-entry':
              appletSpecificCheck.push(!applet.richText);
              break;
            case 'madlib':
              appletSpecificCheck.push(
                applet.madlibAnswers
                  ? applet.madlibAnswers.length === 0
                  : !applet.madlibAnswers,
              );
              break;
            case 'upload-file':
            case 'upload-image':
            case 'upload-video':
              appletSpecificCheck.push(!applet?.file);
              break;
            default:
              appletSpecificCheck.push(true);
          }
        }
      }

      const ret = valuesCheck || appletSpecificCheck.some((val) => val);

      return ret;
    },
    [],
  );

  useEffect(() => {
    if (shareStepFulfilled && hasSentMail) {
      setHasSentMail(false);
      closeModal();
      formikRef.current?.resetForm();
      toast.success(LocalizedStrings.emailSuccess, {
        autoClose: 5000,
        style: {
          backgroundColor: theme.colors.success,
        },
      });
    }
  }, [shareStepFulfilled, hasSentMail, closeModal, dispatch, formikRef]);

  return (
    <ShareByEmailModal
      isOpen={isShareStepModalOpen}
      closeModal={closeModal}
      initialValues={initialValues}
      assignRef={assignRef}
      validationSchema={shareStepValidationSchema}
      onValidSubmit={onValidSubmit}
      loading={shareStepPending || isPreparingMail}
      preview={
        <ShareStepModalPreview applets={shareStepModalInformation?.applets} />
      }
      sendButtonDisabled={isSendButtonDisabled}
    />
  );
};

export default ShareStepModal;
