import React, { useEffect, useMemo } from 'react';
import { useParams, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import { PageBackground } from 'components/components';
import NavBar from 'modules/navbar';
import {
  RelatedUserDetailsSection,
  RelatedUserInfoSection,
  RelatedUserProfileContainer,
} from 'modules/relationships/components';
import RelatedUserInfo from 'modules/relationships/profile/components/RelatedUserInfo';
import RelatedUserChallengeDetails from 'modules/relationships/profile/components/RelatedUserChallengeDetails';
import { AppDispatch } from 'redux/typings';
import { getSubmission } from './actions';
import { selectSubmission } from './selectors';
import { RelationshipType } from 'models/Relationship.d';
import localizedStrings from 'localization';

const ExternalFeedback = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { id } = useParams<{ id: string }>();
  const { search } = useLocation();
  const submission = useSelector(selectSubmission);

  const token = useMemo(() => {
    const urlParams = new URLSearchParams(search);
    return urlParams.get('authToken');
  }, [search]);

  const relationshipType = useMemo(
    () =>
      submission?.relationshipType === RelationshipType.EXTERNAL_MENTOR
        ? localizedStrings.ngaParticipant
        : RelationshipType.RUNNING_PARTNER,
    [submission],
  );

  useEffect(() => {
    if (id && token) {
      dispatch(getSubmission({ id, token }));
    }
  }, [dispatch, id, token]);

  return (
    <>
      <NavBar hideControls />
      <PageBackground>
        <RelatedUserProfileContainer>
          <RelatedUserInfoSection>
            <RelatedUserInfo
              displayName={submission?.authorDisplayName ?? ''}
              profilePictureUrl={submission?.authorProfilePictureUrl}
              relationshipType={relationshipType}
              hideChat
            />
          </RelatedUserInfoSection>
          <RelatedUserDetailsSection>
            <RelatedUserChallengeDetails
              submission={submission}
              external
              token={token}
            />
          </RelatedUserDetailsSection>
        </RelatedUserProfileContainer>
      </PageBackground>
    </>
  );
};

export default ExternalFeedback;
