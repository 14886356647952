import React from 'react';

import theme from 'utils/theme';

type OnTimeIconProps = {
  width?: string;
  height?: string;
};

const OnTimeIcon = ({
  width = theme.typography.large24,
  height = theme.typography.large24,
}: OnTimeIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.9998 8.00369C15.9998 12.3982 12.3906 15.9998 8.00369 15.9998C3.60921 15.9998 0 12.3982 0 8.00369C0 3.60921 3.60166 0 7.99614 0C12.3906 0 15.9998 3.60921 15.9998 8.00369ZM6.14558 11.6583C6.3721 11.9226 6.64392 12.0887 6.99125 12.0887C7.32348 12.0887 7.61796 11.915 7.82183 11.6205L11.6425 5.79143C11.7708 5.59512 11.8539 5.3837 11.8539 5.19493C11.8539 4.71169 11.4235 4.37191 10.9629 4.37191C10.6533 4.37191 10.4042 4.53802 10.1927 4.8627L6.9686 9.9745L5.28481 7.93582C5.08094 7.7093 4.86952 7.58849 4.59015 7.58849C4.12201 7.58849 3.72182 7.96602 3.72182 8.44926C3.72182 8.67578 3.79733 8.87965 3.97099 9.08352L6.14558 11.6583Z"
      fill="#007DC2"
    />
    <mask
      id="mask0_144_12976"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="16">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9998 8.00369C15.9998 12.3982 12.3906 15.9998 8.00369 15.9998C3.60921 15.9998 0 12.3982 0 8.00369C0 3.60921 3.60166 0 7.99614 0C12.3906 0 15.9998 3.60921 15.9998 8.00369ZM6.14558 11.6583C6.3721 11.9226 6.64392 12.0887 6.99125 12.0887C7.32348 12.0887 7.61796 11.915 7.82183 11.6205L11.6425 5.79143C11.7708 5.59512 11.8539 5.3837 11.8539 5.19493C11.8539 4.71169 11.4235 4.37191 10.9629 4.37191C10.6533 4.37191 10.4042 4.53802 10.1927 4.8627L6.9686 9.9745L5.28481 7.93582C5.08094 7.7093 4.86952 7.58849 4.59015 7.58849C4.12201 7.58849 3.72182 7.96602 3.72182 8.44926C3.72182 8.67578 3.79733 8.87965 3.97099 9.08352L6.14558 11.6583Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_144_12976)">
      <rect x="-2.82788" y="-2.82812" width="16" height="16" fill="#007DC2" />
    </g>
  </svg>
);
export default OnTimeIcon;
