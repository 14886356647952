import React from 'react';
import {
  IQuizQuestion,
  IQuizQuestionOption,
  IApplet,
} from '../../../../models/contentful';
import QuizQuestionOptionRenderer from './QuizQuestionOptionRenderer';
import QuizQuestionMadlibChallenge from './QuizQuestionMadlibChallenge';

type QuizQuestionOptionsListChallengeProps = {
  question: IQuizQuestion;
  appletId: string;
  quizQuestionContentfulId: string;
  quizSectionContentfulId: string;
  quizContentfulId: string;
};

const QuizQuestionOptionsListChallenge = ({
  question,
  appletId: appletContentfulId,
  quizQuestionContentfulId,
  quizSectionContentfulId,
  quizContentfulId,
}: QuizQuestionOptionsListChallengeProps) => {
  return (
    <>
      {question.fields.options?.map((option) =>
        option.sys.contentType.sys.id === 'applet' ? (
          <QuizQuestionMadlibChallenge
            key={option.sys.id}
            option={option as IApplet}
            appletContentfulId={appletContentfulId}
            quizContentfulId={quizContentfulId}
            quizQuestionContentfulId={quizQuestionContentfulId}
            quizSectionContentfulId={quizSectionContentfulId}
            question={question}
          />
        ) : (
          <QuizQuestionOptionRenderer
            key={option.sys.id}
            option={option as IQuizQuestionOption}
            appletContentfulId={appletContentfulId}
            quizContentfulId={quizContentfulId}
            quizQuestionContentfulId={quizQuestionContentfulId}
            quizSectionContentfulId={quizSectionContentfulId}
            question={question}
          />
        ),
      )}
    </>
  );
};

export default QuizQuestionOptionsListChallenge;
