import React from 'react';
import useContentfulRenderer from 'hooks/useContentfulRenderer';
import { IQuizQuestionOption } from '../../../../models/contentful';

type QuizQuestionContentfulOptionProps = {
  option: IQuizQuestionOption;
};

const QuizQuestionContentfulOption = ({
  option,
}: QuizQuestionContentfulOptionProps) => {
  const renderedOption = useContentfulRenderer(option.fields.option, {
    additionalStyles: {
      paragraph: {
        marginBottom: 0,
        fontWeight: 'normal',
      },
    },
  });

  return <>{renderedOption}</>;
};

export default QuizQuestionContentfulOption;
