import styled from '@emotion/styled';

import { UnstyledButton } from '../../../common/CommonComponents';

type ShowCaseBackgorundImageProps = {
  slideOpacity: number;
};

export const ShowCaseArrow = styled(UnstyledButton)`
  ${(props) => `
    height: 4.5rem;
    margin-left: 1rem;
    margin-right: 1rem;
    @media (min-width: ${props?.theme?.breakpoints?.largeTablet}px) {
      margin-left: 2rem;
      margin-right: 2rem;
    }
    @media (min-width: ${props?.theme?.breakpoints?.desktopLarge}px) {
      margin-left: 3.3rem;
      margin-right: 3.3rem;
    }
  `}
`;

export const ShowCaseArrowImage = styled.img`
  ${(props) => `
    height: 1.5rem;
    width: 1.5rem;
    
    @media (min-width: ${props?.theme?.breakpoints?.desktopLarge}px) {
      height: 4.16rem;
      width: 4.16rem;
    }
    @media (min-width: ${props?.theme?.breakpoints?.largeTablet}px) {
      height: 3rem;
      width: 3rem;
    }
  `}
`;

export const ShowCaseBackgorundImage = styled.img<ShowCaseBackgorundImageProps>`
  ${(props) => `
    height: 100%;
    object-fit: cover;
    position: absolute;
    width: 100%;
    z-index: -1;
    opacity: ${props.slideOpacity};
  `}
`;

export const ShowcaseContainer = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  position: relative;
  width: 100%;
`;

export const ShowcaseItemsContainer = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: space-between;
`;

export const ShowcaseTextContainer = styled.div`
  ${(props) => `
    color: ${props?.theme?.colors.white[100]};
    font-size: ${props?.theme?.typography.large20};
    text-align: center;
  `}
`;

export const ShowcaseTitle = styled.div`
  margin-bottom: 1rem;
`;

export const ShowcaseSubtitle = styled.p`
  ${(props) => `
    font-size: ${props?.theme?.typography.large20};
    min-height: 60%;
    @media (min-width: ${props?.theme?.breakpoints?.largeTablet}px) {
      font-size: ${props?.theme?.typography.large24};
    }
    @media (min-width: ${props?.theme?.breakpoints?.desktopLarge}px) {
      font-size: ${props?.theme?.typography.large32};
    }
  `}
`;
