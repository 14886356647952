import * as React from 'react';

type StartIconProps = {
  width: number | string;
  height: number | string;
  color?: string;
};

const StarIcon = ({ width, height, color = '#FFF' }: StartIconProps) => {
  return (
    <svg width={width} height={height} viewBox="0 0 14 14">
      <g id="V3" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="Quest---Active-user-1"
          transform="translate(-23.000000, -616.000000)"
          stroke={color}>
          <g id="Cards/Day" transform="translate(16.000000, 372.000000)">
            <g id="Icons/12/Star" transform="translate(8.000000, 245.000000)">
              <path d="M11.5623819,4.1348622 L7.63727224,4.12454235 L6.39962505,0.310886337 C6.33785459,0.120438078 6.17626666,-0.00762195745 5.98110783,0.000352477005 C5.78908228,0.0026978989 5.62704673,0.127474344 5.56930478,0.319329855 L4.4104373,4.12454235 L0.43788076,4.1348622 C0.24585521,4.1348622 0.0829244398,4.25870048 0.0229444312,4.44961782 C-0.0374831894,4.64100425 0.0238396552,4.84224145 0.178713409,4.96138888 L3.28737878,7.36356999 L2.06808368,11.4009792 C2.00989412,11.5928348 2.0725598,11.7926647 2.22832878,11.910874 C2.30666088,11.9699786 2.39618328,12 2.48570568,12 C2.57433286,12 2.66251242,11.9709168 2.73905408,11.9132194 L6.01646917,9.49274399 L9.2612086,11.9113431 C9.41563474,12.0295523 9.61795536,12.0290832 9.77327673,11.910874 C9.92904571,11.7931338 9.99171139,11.5933038 9.93396944,11.4014483 L8.71288389,7.36356999 L11.8211017,4.96185796 C11.9759754,4.84224145 12.0372982,4.64100425 11.9773182,4.45008691 C11.9173382,4.25870048 11.7544075,4.1348622 11.5623819,4.1348622 Z" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default StarIcon;
