import { Document } from '@contentful/rich-text-types';
import { IPlaylistArtifactFields } from 'models/contentful.d';

export interface PlaylistState {
  list: Record<string, Record<string, Artifact>>;
  filter: PLAYLIST_FILTERS;
  availableList?: boolean;
  listSelected: string[];
}

export interface Artifact {
  id: string;
  contentfulId: string;
  weekId: string;
  title: string;
  type: IPlaylistArtifactFields['type'];
  duration: number;
  completed: boolean;
  link?: string;
  reading?: Reading;
}

export interface Reading {
  id: string;
  title: string;
  byLine: string;
  content?: Document;
  contentUrl?: string;
  audioUrl?: string;
}

export enum PLAYLIST_FILTERS {
  LISTEN = 'listen',
  WATCH = 'watch',
  READ = 'read',
  ALL = 'all',
  ASK = 'ask',
}

export interface SubmitCompletedArtifactPayload {
  id: string;
  weekId: string;
}

export interface SetSelectedArtifactPayload {
  idArtifactSelected: string;
}
