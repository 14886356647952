import * as React from 'react';

import theme from 'utils/theme';

type BadgeIconProps = {
  height?: number | string;
  width?: number | string;
  color?: string;
};

const BadgeIcon = ({
  width = 32,
  height = 32,
  color = theme.colors.white[100],
}: BadgeIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      fill={color}
      style={{ cursor: 'pointer' }}
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0085 8.1654C7.86545 8.1654 4.55871 11.3893 4.55871 15.3033C4.55871 19.2174 7.86545 22.4413 12.0085 22.4413C16.1516 22.4413 19.4583 19.2174 19.4583 15.3033C19.4583 11.3893 16.1516 8.1654 12.0085 8.1654ZM3 15.3033C3 10.4722 7.0619 6.60669 12.0085 6.60669C16.9551 6.60669 21.017 10.4722 21.017 15.3033C21.017 20.1345 16.9551 24 12.0085 24C7.0619 24 3 20.1345 3 15.3033Z"
      />
      <path d="M15.663 14.412C15.5891 14.2316 15.4409 14.1234 15.2934 14.0873L13.298 13.7986L12.4481 11.9947C12.3742 11.8143 12.1894 11.7061 12.0046 11.7061C11.8199 11.7061 11.6352 11.8143 11.5608 11.9947L10.7113 13.7986L8.71585 14.0873C8.53109 14.1234 8.38328 14.2316 8.34633 14.412C8.27205 14.5924 8.34633 14.7728 8.45681 14.8811L9.89797 16.3242L9.5654 18.3447C9.52845 18.5251 9.60272 18.7055 9.75016 18.8137C9.89797 18.922 10.0827 18.922 10.2675 18.8498L12.0046 17.9117L13.7414 18.8498C13.8889 18.922 14.111 18.922 14.2584 18.8137C14.4066 18.7055 14.4805 18.5251 14.4432 18.3447L14.111 16.3242L15.5518 14.8811C15.7 14.7728 15.7365 14.5563 15.663 14.412Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.7155 1.46808C13.708 1.47196 13.7048 1.47587 13.7038 1.47729L10.3593 6.32205C10.3611 6.32404 10.3633 6.32591 10.3658 6.32744C10.3679 6.3287 10.3708 6.33007 10.375 6.33123C10.379 6.33235 10.3862 6.33381 10.3975 6.33381H14.674L18.0173 1.47307C18.0155 1.47108 18.0133 1.4692 18.0108 1.46766C18.0086 1.4664 18.0058 1.46502 18.0016 1.46387C17.9976 1.46275 17.9904 1.46129 17.9791 1.46129H13.7451C13.7332 1.46129 13.7231 1.46417 13.7155 1.46808ZM12.5012 0.647148C12.7849 0.236179 13.2543 0 13.7451 0H17.9791C19.1293 0 19.9271 1.27512 19.2245 2.29656L15.4424 7.7951H10.3975C9.2462 7.7951 8.44854 6.51772 9.15354 5.49647L12.5012 0.647148C12.5012 0.647135 12.5012 0.647162 12.5012 0.647148Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.99457 1.46129L9.13285 6.00732C9.3077 5.7388 9.51995 5.40544 9.73929 5.04365C10.2882 4.13827 10.8168 3.158 10.9833 2.55458C10.9802 2.53793 10.9721 2.50334 10.9508 2.44721C10.905 2.32651 10.8257 2.17949 10.7261 2.02424C10.629 1.87287 10.5256 1.73387 10.4452 1.63144C10.4054 1.58079 10.3723 1.5404 10.3498 1.51344C10.3386 1.49998 10.3301 1.48995 10.3248 1.48374L10.3193 1.47737L10.3186 1.47657L10.3052 1.46129H5.99457ZM11.4434 0.543501C11.1863 0.197106 10.7789 0 10.3548 0H5.75317C4.72499 0 4.0009 1.14548 4.63777 2.06805L8.19038 7.21427C8.30338 7.37796 8.44947 7.47927 8.54007 7.53447C8.64053 7.59569 8.74159 7.64093 8.8207 7.67251C8.90216 7.70503 8.97622 7.72932 9.0292 7.74541C9.05617 7.7536 9.07889 7.76004 9.09586 7.76467L9.1171 7.77035L9.12433 7.77221L9.12705 7.7729L9.12818 7.77318C9.12829 7.77321 9.12915 7.77343 9.30578 7.06445L9.12915 7.77343L9.61915 7.8955L9.90655 7.48029L9.30578 7.06445C9.90655 7.48029 9.90651 7.48035 9.90655 7.48029L9.90849 7.47748L9.91333 7.47046L9.93134 7.44423C9.94695 7.42146 9.96959 7.38828 9.99839 7.34578C10.056 7.26078 10.1382 7.13838 10.2378 6.98722C10.4369 6.68527 10.7067 6.26672 10.9889 5.80123C11.5333 4.90324 12.1916 3.72307 12.4082 2.8821C12.5027 2.51523 12.4051 2.16086 12.3171 1.92888C12.221 1.67552 12.083 1.43294 11.9559 1.23496C11.8264 1.03312 11.6936 0.855269 11.5947 0.729161C11.5447 0.665546 11.5023 0.613739 11.4717 0.57708C11.4607 0.563928 11.4512 0.552685 11.4434 0.543501Z"
      />
    </svg>
  );
};

export default BadgeIcon;
