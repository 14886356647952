import { baseService } from 'modules/baseService';
import HttpClient from 'services/HttpClient';
import { AppOnboarding } from 'models/AppOnboarding';
import { AddTagToContactPayload, ContactCreatedResponse, ContactTagsCreatedResponse, ContactsResponse, CreateContactPayload } from './typings';

const baseUrl = '/app-onboarding';

export const getAppOnboardings = baseService<void, AppOnboarding[]>(() =>
  HttpClient.get(baseUrl),
);

export const getAppOnboardingSeen = baseService<void, boolean>(() =>
  HttpClient.get(`${baseUrl}/visualizations`),
);

export const notifyAppOnboardingSeen = baseService<void, void>(() =>
  HttpClient.post(`${baseUrl}/visualizations`),
);

export const getQuestOnboardingSeen = baseService<{ questId: string }, boolean>(
  ({ questId }) =>
    HttpClient.get(
      `quest-boarding/${questId}/visualizations?isOffboarding=${false}`,
    ),
);

export const getQuestOffboardingSeen = baseService<
  { questId: string },
  boolean
>(({ questId }) =>
  HttpClient.get(
    `quest-boarding/${questId}/visualizations?isOffboarding=${true}`,
  ),
);

export const notifyQuestOnboardingSeen = baseService<{ questId: string }, void>(
  ({ questId }) =>
    HttpClient.post(
      `quest-boarding/${questId}/visualizations?isOffboarding=${false}`,
    ),
);

export const notifyQuestOffboardingSeen = baseService<
  { questId: string },
  void
>(({ questId }) =>
  HttpClient.post(
    `quest-boarding/${questId}/visualizations?isOffboarding=${true}`,
  ),
);

export const getWeekOnboardingSeen = baseService<{ weekId: string }, boolean>(
  ({ weekId }) => HttpClient.get(`week-onboarding/${weekId}/visualizations`),
);

export const notifyWeekOnboardingSeen = baseService<{ weekId: string }, void>(
  ({ weekId }) => HttpClient.post(`week-onboarding/${weekId}/visualizations`),
);




// ACTVE CAMPAIGN

// Create a new contact in active campaign
export const createContactActiveCampaignSv = baseService<CreateContactPayload, ContactCreatedResponse>(
  (contact) =>
    HttpClient.post("/active-campaign/create-contact",
      { contact },
      {
        headers: {
          "Content-Type": "application/json",
        }
      }
    ),
);


// Add a tag with id user and id TAG (you need to know previously the id tag)
export const addTagToContactActiveCampaignSv = baseService<AddTagToContactPayload, ContactTagsCreatedResponse>(
  (contactTag) =>
    HttpClient.post("/active-campaign/add-tag",
      { contactTag },
      {
        headers: {
          "Content-Type": "application/json",
        }
      }
    )
);

export const getToContactActiveCampaignSv = baseService<string, ContactsResponse>(
  (email) =>
    HttpClient.get(`/active-campaign/contact-by-email/${encodeURIComponent(email)}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    ),
);

