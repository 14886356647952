import React from 'react'
import { WeekItemDetail, WeekItemStatusContainer, WeekItemStatusIconContainer, WeekItemText } from '../components'
import CompletedIconEmpty from 'components/icons/CompletedIconEmpty'
import ProgressIndicator from 'components/icons/ProgressIndicator'
import theme from 'utils/theme';
import { Content } from 'models/Content';
import { ContentContentful } from 'models/ContentContentful';

type WeekSummaryItemToRenderType = {
    isNavigationEnabled: boolean,
    content: Content,
    challengeProgress: number,
    contentContentful: ContentContentful,
    handleChallengeClick: any, // hotfix
    renderProgress: (challengeProgress: number, bonusChallenge?: boolean) => string
}


export const WeekSummaryItemToRender = ({ content, isNavigationEnabled, renderProgress, challengeProgress, contentContentful, handleChallengeClick }: WeekSummaryItemToRenderType) => {
    return (
        <WeekItemDetail key={content?.id}>
            <WeekItemText
                isLink={isNavigationEnabled}
                onClick={handleChallengeClick(content?.id)}>
                {contentContentful.title}
            </WeekItemText>
            <WeekItemStatusContainer>
                <WeekItemText>
                    {renderProgress(challengeProgress, contentContentful.bonusChallenge)}
                </WeekItemText>
                <WeekItemStatusIconContainer>
                    {challengeProgress >= 100 ? (
                        <CompletedIconEmpty
                            height={theme.typography.large20}
                            width={theme.typography.large20}
                            alternativeIcon={contentContentful?.bonusChallenge}
                        />
                    ) : (
                        <ProgressIndicator
                            height={theme.typography.large20}
                            width={theme.typography.large20}
                            progress={challengeProgress}
                        />
                    )}
                </WeekItemStatusIconContainer>
            </WeekItemStatusContainer>
        </WeekItemDetail>
    )
}
