import React from 'react';
import ContentLoader from 'react-content-loader';

const PageLoadingPlaceholder = () => {
  return (
    <ContentLoader
      width={400}
      height={500}
      viewBox="0 0 400 500"
      backgroundColor="#f5f5f5"
      foregroundColor="#dbdbdb">
      <rect x="50" y="47" rx="3" ry="3" width="300" height="6" />
      <rect x="50" y="69" rx="3" ry="3" width="300" height="6" />
      <rect x="50" y="95" rx="3" ry="3" width="300" height="6" />
      <rect x="50" y="147" rx="3" ry="3" width="300" height="6" />
      <rect x="50" y="169" rx="3" ry="3" width="300" height="6" />
      <rect x="50" y="195" rx="3" ry="3" width="300" height="6" />
      <rect x="50" y="243" rx="3" ry="3" width="300" height="6" />
      <rect x="50" y="265" rx="3" ry="3" width="300" height="6" />
      <rect x="50" y="291" rx="3" ry="3" width="300" height="6" />
      <rect x="50" y="343" rx="3" ry="3" width="300" height="6" />
      <rect x="50" y="365" rx="3" ry="3" width="300" height="6" />
      <rect x="50" y="391" rx="3" ry="3" width="300" height="6" />
      <rect x="50" y="436" rx="3" ry="3" width="300" height="6" />
      <rect x="50" y="458" rx="3" ry="3" width="300" height="6" />
      <rect x="50" y="484" rx="3" ry="3" width="300" height="6" />
    </ContentLoader>
  );
};

export default PageLoadingPlaceholder;
