import styled from '@emotion/styled';
import { Field } from 'formik';

export const InputFieldContainer = styled.div`
  margin-bottom: 1rem;
  width: 100%;
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${(props) => props?.theme?.colors.grey[90]};
  padding: 0.7rem 1.2rem 0.5rem 0.8rem;
  border-radius: 0.7rem;
  outline: none;
`;

export const FormField = styled(Field)`
  font-size: ${(props) => props?.theme?.typography.large18};
  line-height: ${(props) => props?.theme?.typography.large24};
  outline: none;
  border: none;
  flex: 1;
  background-color: transparent;
  color: inherit;
`;

export const FieldErrorMessage = styled.div`
  color: ${(props) => props?.theme?.colors.red[80]};
  font-size: ${(props) => props?.theme?.typography.large16};
  line-height: ${(props) => props?.theme?.typography.large16};
  margin-top: 0.2rem;
  padding-left: 0.7rem;
`;

export const ShowPasswordButton = styled.button`
  padding: 0;
  background-color: transparent;
  border: none;

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  :active {
    animation: fade-in 0.8s;
  }
`;

export const TextArea = styled.textarea`
  font-size: ${(props) => props?.theme?.typography.large18};
  line-height: ${(props) => props?.theme?.typography.large24};
  background-color: transparent;
  border: 1px solid ${(props) => props?.theme?.colors.grey[90]};
  border-radius: 0.7rem;
  resize: none;
  outline: none;
  padding: 0.7rem 1.2rem 0.5rem 0.8rem;
  width: 100%;
`;
