import React, { ReactNode, useCallback, useMemo } from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import {
  BLOCKS,
  MARKS,
  INLINES,
  Block,
  Inline,
  Document,
} from '@contentful/rich-text-types';

import {
  Quote,
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  Heading5,
  Heading6,
  Paragraph,
  Hr,
  Bold,
  Italic,
  Underline,
  TextBesideImage,
  ImageContainer,
  TextContainer,
  LinkProps,
} from 'components/contentful-renders/components';
import EmbeddedEntry from 'components/contentful-renders/blocks/embedded-entry';
import EmbeddedAsset from 'components/contentful-renders/blocks/embedded-asset';
import EmbeddedEntryInline from 'components/contentful-renders/inlines/embedded-entry-inline';
import Hyperlink from 'components/contentful-renders/inlines/hyperlink';
import EntryHyperlink from 'components/contentful-renders/inlines/entry-hyperlink';
import AssetHyperlink from 'components/contentful-renders/inlines/asset-hyperlink';
import MissingContentfulRender from 'components/contentful-renders/missing-contentful-render';
import AppListItem from 'components/app-list-item';
import UnorderedList from 'components/contentful-renders/blocks/unordered-list/index.jsx';
import OrderedList from 'components/contentful-renders/blocks/ordered-list/index.jsx';
import { MadlibAnswer } from 'models/Applet';

export type AdditionalStyles = {
  [key: string]: React.CSSProperties;
};

export type ContentfulRendererOptions = {
  darkMode?: boolean;
  additionalStyles?: AdditionalStyles;
  textBesideImage?: boolean;
  appletId?: string;
  madlibAppletContentfulId?: string;
  quizAppletContentfulId?: string;
  quizContentfulId?: string;
  quizSectionContentfulId?: string;
  quizQuestionContentfulId?: string;
  isMadlibEditMode?: boolean;
  linkColors?: LinkProps;
  madlibAnswers?: MadlibAnswer[];
};

const options: any = (
  additionalStyles: AdditionalStyles = {},
  darkMode: boolean = false,
  appletId?: string,
  isMadlibEditMode?: boolean,
  linkColors?: LinkProps,
  madlibAnswers?: MadlibAnswer[],
  madlibAppletContentfulId?: string,
  quizAppletContentfulId?: string,
  quizContentfulId?: string,
  quizSectionContentfulId?: string,
  quizQuestionContentfulId?: string,
) => ({
  renderNode: {
    [BLOCKS.QUOTE]: (node: Block | Inline, children: ReactNode) => (
      <Quote
        children={children}
        style={additionalStyles.quote}
        darkMode={darkMode}
      />
    ),
    [BLOCKS.PARAGRAPH]: (node: Block | Inline, children: ReactNode) => (
      <Paragraph
        children={children}
        style={additionalStyles.paragraph}
        darkMode={darkMode}
      />
    ),
    [BLOCKS.EMBEDDED_ENTRY]: (node: Block | Inline, children: ReactNode) => (
      <EmbeddedEntry node={node} />
    ),
    [BLOCKS.HEADING_1]: (node: Block | Inline, children: ReactNode) => (
      <Heading1
        children={children}
        style={additionalStyles.heading1}
        darkMode={darkMode}
      />
    ),
    [BLOCKS.HEADING_2]: (node: Block | Inline, children: ReactNode) => (
      <Heading2
        children={children}
        style={additionalStyles.heading2}
        darkMode={darkMode}
      />
    ),
    [BLOCKS.HEADING_3]: (node: Block | Inline, children: ReactNode) => (
      <Heading3
        children={children}
        style={additionalStyles.heading3}
        darkMode={darkMode}
      />
    ),
    [BLOCKS.HEADING_4]: (node: Block | Inline, children: ReactNode) => (
      <Heading4
        children={children}
        style={additionalStyles.heading4}
        darkMode={darkMode}
      />
    ),
    [BLOCKS.HEADING_5]: (node: Block | Inline, children: ReactNode) => (
      <Heading5
        children={children}
        style={additionalStyles.heading5}
        darkMode={darkMode}
      />
    ),
    [BLOCKS.HEADING_6]: (node: Block | Inline, children: ReactNode) => (
      <Heading6
        children={children}
        style={additionalStyles.heading6}
        darkMode={darkMode}
      />
    ),
    [BLOCKS.HR]: () => <Hr />,
    [BLOCKS.OL_LIST]: (node: Block | Inline, children: ReactNode) => (
      <OrderedList node={node} children={children} />
    ),
    [BLOCKS.UL_LIST]: (node: Block | Inline, children: ReactNode) => (
      <UnorderedList node={node} children={children} />
    ),
    [BLOCKS.LIST_ITEM]:
      (node: Block | Inline, children: ReactNode) =>
      (props: { listType: 'ordered' | 'unordered' }) =>
        <AppListItem children={children} darkMode={darkMode} {...props} />,
    [BLOCKS.EMBEDDED_ASSET]: (node: Block | Inline) => (
      <EmbeddedAsset node={node} style={additionalStyles.embeddedAsset} />
    ),
    [INLINES.HYPERLINK]: (node: Block | Inline, children: ReactNode) => (
      <Hyperlink node={node} children={children} {...linkColors} />
    ),
    [INLINES.EMBEDDED_ENTRY]: (node: Block | Inline, children: ReactNode) => (
      <EmbeddedEntryInline
        node={node}
        children={children}
        isMadlibEditMode={isMadlibEditMode}
        appletId={appletId}
        madlibAnswers={madlibAnswers}
        madlibAppletContentfulId={madlibAppletContentfulId}
        quizAppletContentfulId={quizAppletContentfulId}
        quizContentfulId={quizContentfulId}
        quizSectionContentfulId={quizSectionContentfulId}
        quizQuestionContentfulId={quizQuestionContentfulId}
      />
    ),
    [INLINES.ENTRY_HYPERLINK]: (node: Block | Inline, children: ReactNode) => (
      <EntryHyperlink node={node} children={children} {...linkColors} />
    ),
    [INLINES.ASSET_HYPERLINK]: (node: Block | Inline, children: ReactNode) => (
      <AssetHyperlink node={node} children={children} {...linkColors} />
    ),
  },
  renderMark: {
    [MARKS.BOLD]: (text: string) => <Bold>{text}</Bold>,
    [MARKS.ITALIC]: (text: string) => <Italic>{text}</Italic>,
    [MARKS.UNDERLINE]: (text: string) => <Underline>{text}</Underline>,
    [MARKS.CODE]: () => <MissingContentfulRender componentType={MARKS.CODE} />,
  },
  renderText: (text: string) => {
    return text.split('\n').reduce((children, textSegment, index) => {
      return [...children, index > 0 && <br key={index} />, textSegment];
    }, [] as ReactNode[]);
  },
});

const useContentfulRenderer: (
  document: Document | undefined,
  rendererOptions?: ContentfulRendererOptions,
) => ReactNode = (
  document,
  {
    darkMode,
    additionalStyles,
    textBesideImage,
    appletId,
    isMadlibEditMode,
    linkColors,
    madlibAnswers,
    madlibAppletContentfulId,
    quizAppletContentfulId,
    quizContentfulId,
    quizSectionContentfulId,
    quizQuestionContentfulId,
  } = {},
) => {
  const renderTextBesideImage = useCallback(
    (
      document: Document,
      additionalStyles?: AdditionalStyles,
      darkMode?: boolean,
      appletId?: string,
      isMadlibEditMode?: boolean,
      linkColors?: LinkProps,
      madlibAnswers?: MadlibAnswer[],
      madlibAppletContentfulId?: string,
      quizAppletContentfulId?: string,
      quizContentfulId?: string,
      quizSectionContentfulId?: string,
      quizQuestionContentfulId?: string,
    ) => {
      const indexOfImage = document.content.findIndex(
        (content) =>
          content.nodeType === 'embedded-asset-block' &&
          content.data.target.fields.file.contentType.includes('image'),
      );

      if (indexOfImage === -1) {
        return documentToReactComponents(
          document,
          options(
            additionalStyles,
            darkMode,
            appletId,
            isMadlibEditMode,
            linkColors,
            madlibAnswers,
            madlibAppletContentfulId,
            quizAppletContentfulId,
            quizContentfulId,
            quizSectionContentfulId,
            quizQuestionContentfulId,
          ),
        );
      }

      const preImg = document.content.slice(0, indexOfImage);
      const img = document.content[indexOfImage];
      const postImg = document.content.slice(indexOfImage + 1);

      const preImgRender = documentToReactComponents(
        { nodeType: 'document', data: {}, content: preImg } as Document,
        options(
          additionalStyles,
          darkMode,
          appletId,
          isMadlibEditMode,
          linkColors,
          madlibAnswers,
          madlibAppletContentfulId,
          quizAppletContentfulId,
          quizContentfulId,
          quizSectionContentfulId,
          quizQuestionContentfulId,
        ),
      );
      const imgRender = documentToReactComponents(
        { nodeType: 'document', data: {}, content: [img] } as Document,
        options(
          additionalStyles,
          darkMode,
          appletId,
          isMadlibEditMode,
          linkColors,
          madlibAnswers,
          madlibAppletContentfulId,
          quizAppletContentfulId,
          quizContentfulId,
          quizSectionContentfulId,
          quizQuestionContentfulId,
        ),
      );
      const postImgRender = documentToReactComponents(
        { nodeType: 'document', data: {}, content: postImg } as Document,
        options(
          additionalStyles,
          darkMode,
          appletId,
          isMadlibEditMode,
          linkColors,
          madlibAnswers,
          madlibAppletContentfulId,
          quizAppletContentfulId,
          quizContentfulId,
          quizSectionContentfulId,
          quizQuestionContentfulId,
        ),
      );

      return (
        <>
          {preImgRender}
          <TextBesideImage>
            <ImageContainer>{imgRender}</ImageContainer>
            <TextContainer>{postImgRender}</TextContainer>
          </TextBesideImage>
        </>
      );
    },
    [],
  );

  return useMemo(() => {
    if (document) {
      if (textBesideImage) {
        return renderTextBesideImage(
          document,
          additionalStyles,
          darkMode,
          appletId,
          isMadlibEditMode,
          linkColors,
          madlibAnswers,
          madlibAppletContentfulId,
          quizAppletContentfulId,
          quizContentfulId,
          quizSectionContentfulId,
          quizQuestionContentfulId,
        );
      } else {
        return documentToReactComponents(
          document,
          options(
            additionalStyles,
            darkMode,
            appletId,
            isMadlibEditMode,
            linkColors,
            madlibAnswers,
            madlibAppletContentfulId,
            quizAppletContentfulId,
            quizContentfulId,
            quizSectionContentfulId,
            quizQuestionContentfulId,
          ),
        );
      }
    }
    return null;
  }, [
    document,
    textBesideImage,
    renderTextBesideImage,
    additionalStyles,
    darkMode,
    appletId,
    isMadlibEditMode,
    linkColors,
    madlibAnswers,
    madlibAppletContentfulId,
    quizAppletContentfulId,
    quizContentfulId,
    quizSectionContentfulId,
    quizQuestionContentfulId,
  ]);
};

export default useContentfulRenderer;
