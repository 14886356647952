import { createAsyncThunk, createAction } from '@reduxjs/toolkit';

import { getAvailableWeeks as weeksService } from './services';

const GET_AVAILABLE_WEEKS = 'GET_AVAILABLE_WEEKS';
export const getAvailableWeeks = createAsyncThunk(
  GET_AVAILABLE_WEEKS,
  weeksService,
);

const SELECT_WEEK = 'SELECT_WEEK';
export const selectWeek = createAction<string>(SELECT_WEEK);

const SELECT_WEEK_HIGHLIGHTED = 'SELECT_WEEK_HIGHLIGHTED';
export const selectWeekHighlighted = createAction<number[]>(
  SELECT_WEEK_HIGHLIGHTED,
);

const RESET_HAS_COMPLETED_WEEKS = 'RESET_HAS_COMPLETED_WEEKS';
export const resetHasCompletedWeeks = createAction<void>(
  RESET_HAS_COMPLETED_WEEKS,
);
