import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import Calendar, { CalendarTileProperties } from 'react-calendar';
import { isAfter, parse, startOfDay, startOfWeek } from 'date-fns';

import { StepSchedule } from 'models/Schedule';
import { selectWeeksScheduleByDate } from 'modules/loading-screen/schedules/selectors';
import Agenda from './Agenda';
import {
  CalendarAndEditContainer,
  CalendarContainer,
  EditScheduleButton,
  CalendarPanel,
  MyScheduleContainer,
  AgendaPanel,
  UnavailableContentText,
  DailyTabContainer,
} from '../components';
import { compareStringDates } from 'helpers/dateHelpers';
import { useEffect } from 'react';
import ScheduleEdit from './ScheduleEdit';
import localizedStrings from 'localization';
import { selectEditedWeekId } from 'modules/loading-screen/schedule-commit/selectors';
import Tile from './Tile';
import FilterSection from '../FilterSection';
import { selectIsSampleQuest } from '../../loading-screen/quests/selectors';

type DailyStatus = {
  [date: string]: {
    progress?: number;
    isLate?: boolean;
    dayScheduled: boolean;
  };
};

export type DailySchedule = {
  [date: string]: StepSchedule[];
};

const DailyTab = () => {
  const [selectedDay, setSelectedDay] = useState('');
  const [scheduleEditOpen, setScheduleEditOpen] = useState(false);
  const scheduleByDate = useSelector(selectWeeksScheduleByDate);
  const editedWeekId = useSelector(selectEditedWeekId);
  const isSampleQuest = useSelector(selectIsSampleQuest);

  /**
   * Guardamos esta funciona ya que es la forma tradicional de mostrar contenido por dia
   * Hasta hacer el refactor de schedule por dia y modulo que requiere el cliente
   * Por el momento solo se implementa dailyScheduleDuplicated como parche para mostrar contenido
   * de varias semanas planteado para una misma fecha. (unlock after days in 0)
   */
  const dailySchedule = useMemo(
    () =>
      scheduleByDate.reduce(
        (acc, weekMap) => ({
          ...acc,
          ...Object.entries(weekMap).reduce(
            (acc, [day, stepsMap]) => ({
              ...acc,
              [day.split('T')[0]]: Object.values(stepsMap),
            }),
            {},
          ),
        }),
        {} as DailySchedule,
      ),
    [scheduleByDate],
  );

  const dailyScheduleDuplicated = useMemo(
    () =>
      scheduleByDate.reduce(
        (acc, weekMap, index) => ({
          ...acc,
          ...Object.entries(weekMap).reduce(
            (acc, [day, stepsMap]) => {

              return ({
                ...acc,
                [index + '&' + day.split('T')[0]]: Object.values(stepsMap),
              })
            },
            {},
          ),
        }),
        {} as DailySchedule,
      ),
    [scheduleByDate],
  );

  const dailyStatus = useMemo(() => {
    const today = startOfDay(new Date());
    return Object.entries(dailySchedule).reduce((acc, [day, steps]) => {
      if (steps.length > 0) {
        const stepsCompleted = steps.filter((step) => step.completed).length;
        const totalSteps = steps.length;
        const progress = Math.floor((stepsCompleted / totalSteps) * 100);
        const date = parse(day, 'yyyy-MM-dd', new Date());
        const isLate = isAfter(today, date) && progress < 100;
        return {
          ...acc,
          [day]: {
            progress,
            isLate,
            dayScheduled: true,
          },
        };
      } else {
        return {
          ...acc,
          [day]: {
            dayScheduled: false,
          },
        };
      }
    }, {} as DailyStatus);
  }, [dailySchedule]);

  const minDate = useMemo(() => {
    const date = Object.keys(dailySchedule).sort(compareStringDates)[0];
    return date;
  }, [dailySchedule]);

  const isEditDisabled = useMemo(() => {
    if (selectedDay) {
      const today = new Date();
      const selectedDate = parse(selectedDay, 'yyyy-MM-dd', new Date());
      const todayStartOfWeek = startOfWeek(today, { weekStartsOn: 1 });
      const selectedDateStartOfWeek = startOfWeek(selectedDate, {
        weekStartsOn: 1,
      });
      if (isAfter(selectedDateStartOfWeek, todayStartOfWeek) && editedWeekId) {
        return false;
      }
    }
    return true;
  }, [selectedDay, editedWeekId]);

  const tileContent = useCallback(
    (props: CalendarTileProperties) => {
      const status = dailyStatus[props.date.toISOString().split('T')[0]];
      return (
        <Tile
          {...props}
          dayScheduled={status?.dayScheduled}
          progress={status?.progress}
        />
      );
    },
    [dailyStatus],
  );

  const handleDayClick = useCallback((date: Date) => {
    setSelectedDay(date.toISOString().split('T')[0]);
  }, []);

  const openScheduleEdit = useCallback(() => setScheduleEditOpen(true), []);
  const closeScheduleEdit = useCallback(() => setScheduleEditOpen(false), []);

  useEffect(() => {
    setSelectedDay(minDate);
  }, [minDate]);

  return (
    <DailyTabContainer>
      {isSampleQuest ? (
        <UnavailableContentText>
          This feature is not available in the trial quests.
        </UnavailableContentText>
      ) : (
        <>
          <MyScheduleContainer>
            <ScheduleEdit
              open={scheduleEditOpen}
              close={closeScheduleEdit}
              selectedDay={selectedDay}
            />
            <CalendarPanel>
              <CalendarAndEditContainer>
                <CalendarContainer>
                  <Calendar
                    onClickDay={handleDayClick}
                    tileContent={tileContent}
                  />
                </CalendarContainer>
                <EditScheduleButton
                  disabled={isEditDisabled}
                  onClick={openScheduleEdit}>
                  {localizedStrings.edit}
                </EditScheduleButton>
              </CalendarAndEditContainer>
            </CalendarPanel>
            <AgendaPanel>
              <Agenda days={dailySchedule} visibleDay={selectedDay} dailyScheduleDuplicated={dailyScheduleDuplicated} />
            </AgendaPanel>
          </MyScheduleContainer>
          <FilterSection />
        </>
      )}
    </DailyTabContainer>
  );
};

export default DailyTab;
