import { createSelector } from '@reduxjs/toolkit';

import { selectAvailableWeeksIds } from 'modules/loading-screen/weeks/selectors';
import { RootState } from 'redux/typings';
import { Artifact, PlaylistState, PLAYLIST_FILTERS } from './typings';
import { getFilteredPlaylists } from '../../../helpers/helperFunctions';
import { filtersDefinitions, playlistTabs } from './utils';

const selectPlaylist = (state: RootState) => state.playlist as PlaylistState;

export const selectPlaylistFilter = createSelector(
  selectPlaylist,
  (playlist) => playlist.filter,
);

export const selectPlaylistList = createSelector(
  selectPlaylist,
  (playlist) => playlist.list,
);

export const selectPlaylistListSelected = createSelector(
  selectPlaylist,
  (playlist) => playlist.listSelected,
);

export const selectPlaylistArtifacts = createSelector(
  selectPlaylistList,
  selectPlaylistFilter,
  selectAvailableWeeksIds,
  (playlist) => {
    const artifactArray = Object.values(playlist).flatMap((weekPlaylist) =>
      Object.values(weekPlaylist),
    );

    const groupedArtifacts = artifactArray.reduce((acc, item) => {
      Object.values(PLAYLIST_FILTERS).forEach((filterType) => {
        getFilteredPlaylists(filterType, acc, item);
      });

      return acc;
    }, {} as Record<PLAYLIST_FILTERS, Artifact[]>);

    return groupedArtifacts;
  },
);

export const selectShownPlaylistTabs = createSelector(
  selectPlaylistArtifacts,
  (playlist) => {
    const playlistStrings = Object.keys(playlist);

    const shownPlaylistTabs = playlistTabs.filter((x) =>
      playlistStrings.includes(x.name),
    );

    return shownPlaylistTabs;
  },
);

export const selectPlaylistItems = (selectedTab: PLAYLIST_FILTERS) =>
  createSelector(selectPlaylistArtifacts, (playlist) => {
    const playlistItems = playlist[selectedTab];
    return playlistItems;
  });

export const selectPlaylistItemsSelected = (selectedTab: PLAYLIST_FILTERS) =>
  createSelector(
    selectPlaylistAllArtifactMap,
    selectPlaylistListSelected,
    (playlistAvailable, playlistSelected) => {
      return playlistAvailable
        .filter(
          (art: Artifact) => playlistSelected.includes(art.id) || art.completed,
        )
        .filter((elementArtifact: Artifact) =>
          filtersDefinitions[selectedTab].includes(elementArtifact.type),
        );
    },
  );

export const selectArtifactByContentfulIdAndWeek = (
  contentfulId: string,
  weekId?: string,
) =>
  createSelector(selectPlaylist, (playlist) => {
    if (weekId) {
      const weekArtifactMap = playlist.list[weekId];
      if (weekArtifactMap) {
        const playlistArtifacts = Object.values(weekArtifactMap);
        const artifact = playlistArtifacts.find(
          (art) => art.contentfulId === contentfulId,
        );
        return artifact;
      }
    }
    return undefined;
  });

export const selectPlaylistCount = createSelector(
  selectPlaylistList,
  (playlists) => Object.keys(playlists || {}).length,
);

export const selectPlaylistArtifactMap = createSelector(
  selectPlaylistList,
  (playlist) =>
    Object.values(playlist)
      .flatMap((weekPlaylist) => Object.values(weekPlaylist))
      .reduce(
        (acc, newValue) => ({
          ...acc,
          [newValue.contentfulId]: newValue,
        }),
        {} as Record<string, Artifact>,
      ),
);

export const selectPlaylistArtifactById = (artifactId = '') =>
  createSelector(
    selectPlaylistArtifactMap,
    (artifactMap) => artifactMap?.[artifactId],
  );

export const selectAvailable = createSelector(
  selectPlaylist,
  (playlist) => playlist.availableList || false,
);

export const selectPlaylistAllArtifactMap = createSelector(
  selectPlaylistList,
  (playlist) =>
    Object.values(playlist).flatMap((weekPlaylist) =>
      Object.values(weekPlaylist),
    ),
);
