import { baseService } from '../baseService';
import HttpClient from 'services/HttpClient';
import {
  InvitePayload,
  RelationshipStatusChangePayload,
  SendNotificationPayload,
  EmailExternalRunningPartnerPayload,
  SendPushNotificationPayload,
} from './typings';
import { Relationship } from 'models/Relationship.d';
import { RunningPartner } from 'models/RunningPartner';
import { CohortInformation } from 'models/CohortInformation';
import { VisibilityAchievementsType } from 'modules/auth/typings';

export const getRelationships = baseService<undefined, Relationship[]>(() =>
  HttpClient.get('/relationships'),
);

export const invite = baseService<InvitePayload, Relationship>((data) =>
  HttpClient.post('/relationships', data),
);

export const changeRelationshipStatus = baseService<
  RelationshipStatusChangePayload,
  void
>(({ relationshipId, ...body }) =>
  HttpClient.patch(`/relationships/${relationshipId}/status`, body),
);

export const deleteInvo = baseService<{ relationshipId: string }, void>(
  ({ relationshipId }) => HttpClient.delete(`/relationships/${relationshipId}`),
);

export const sendNotification = baseService<SendNotificationPayload, void>(
  (data) => HttpClient.post('/notifications', data),
);

export const sendPushNotification = baseService<
  SendPushNotificationPayload,
  void
>((data) => HttpClient.post('/notifications/push', data));

export const emailExternalRunningPartner = baseService<
  EmailExternalRunningPartnerPayload,
  void
>(({ challengeId, ...data }) =>
  HttpClient.post(`/challenges/${challengeId}/posts/share`, data),
);

export const getRunningPartner = baseService<
  string | undefined,
  RunningPartner
>((userId?: string) =>
  HttpClient.get(
    `/relationships/running-partner${userId ? `?userId=${userId}` : ''}`,
  ),
);

export const getActiveCohortInformation = baseService<void, CohortInformation>(
  () => HttpClient.get('/cohorts/active'),
);

export const getProfileVisibilityAchievements = baseService<
  string,
  VisibilityAchievementsType
>((data) =>
  HttpClient.get(`/users/profile/visibility-achievements?userId=${data}`),
);
