import React, { useEffect } from 'react';
import { Route, Switch, useParams } from 'react-router-dom';

import {
  SHOWCASE_CONTACT,
  SHOWCASE,
  SHOWCASE_RESUME,
  SHOWCASE_OVERVIEW,
  SHOWCASE_RESUME_VIDEO,
  SHOWCASE_PARAMS,
} from 'routes';
import ShowcaseContact from './contact';
import ShowcaseHome from './home';
import ShowcaseOverview from './overview';
import ShowCaseResume from './resume';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'redux/typings';
import { getShowcase } from './actions';
import { selectBackgroundUrl, selectVideoUrl } from './selectors';

const Showcase = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { id } = useParams<{ id: string }>();
  const backgroundUrl = useSelector(selectBackgroundUrl);
  const videoUrl = useSelector(selectVideoUrl);

  useEffect(() => {
    if (id && id !== '') {
      dispatch(getShowcase(id));
    }
  }, [dispatch, id]);

  return (
    <Switch>
      <Route exact path={SHOWCASE + SHOWCASE_PARAMS} component={ShowcaseHome} />
      <Route
        exact
        path={SHOWCASE + SHOWCASE_PARAMS + SHOWCASE_CONTACT}
        component={ShowcaseContact}
      />
      <Route
        exact
        path={SHOWCASE + SHOWCASE_PARAMS + SHOWCASE_RESUME}
        component={() => <ShowCaseResume image={backgroundUrl} />}
      />
      <Route
        exact
        path={SHOWCASE + SHOWCASE_PARAMS + SHOWCASE_OVERVIEW}
        component={ShowcaseOverview}
      />
      <Route
        exact
        path={SHOWCASE + SHOWCASE_PARAMS + SHOWCASE_RESUME_VIDEO}
        component={() => <ShowCaseResume video={videoUrl} />}
      />
    </Switch>
  );
};

export default Showcase;
