import React, { PropsWithChildren, useCallback } from 'react';
import {
  NavigationButtonContainer,
  NavigationSelectedIndicator,
  NavigationText,
} from './components';

type NavigationButtonProps = {
  name: string;
  selected: string;
  onClick: (name: string) => void;
};

const NavigationButton = ({
  name,
  selected,
  onClick,
  children,
}: PropsWithChildren<NavigationButtonProps>) => {
  const handleClick = useCallback(() => {
    onClick(name);
  }, [name, onClick]);

  return (
    <NavigationButtonContainer onClick={handleClick}>
      <NavigationSelectedIndicator visible={name === selected} />
      <NavigationText selected={name === selected}>{children}</NavigationText>
    </NavigationButtonContainer>
  );
};

export default NavigationButton;
