import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'redux/typings';

const selectNavigationInformation = (state: RootState) =>
  state.navigationInformation;

export const selectIsComingFromLoadingScreen = createSelector(
  selectNavigationInformation,
  (navInfo) => navInfo.isComingFromLoadingScreen,
);
