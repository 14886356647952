import { Block, Inline } from '@contentful/rich-text-types';
import { Next, NodeRenderer } from '@contentful/rich-text-html-renderer';

import { AppletContentful } from 'models/AppletContentful';
import { MadlibAnswer } from '../../../../models/Applet';

export const getInlineEmbeddedEntryHtml = (
  _matchedAppletContentful: AppletContentful,
  madlibAnswers?: MadlibAnswer[],
) =>
  ((node: Block | Inline, _next: Next) => {
    let ret = '';
    if (node.data?.target?.sys?.contentType?.sys?.id === 'madlibInput') {
      ret =
        madlibAnswers?.find(
          (ans) => ans.contentfulId === node.data?.target?.sys?.id,
        )?.answer || '';
    }
    return `<b>${ret}</b>`;
  }) as NodeRenderer;
