import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import localizedStrings from 'localization';
import {
  PublishShowcaseAppletsContainer,
  PublishShowcaseDescription,
} from 'modules/profile/components';
import { selectQuestPortfolioApplets } from 'modules/applets/selectors';
import AppletPreview from 'modules/profile/portfolio/quest-applets/AppletPreview';
import { StepProps } from '../typings';
import { buildQuestAppletsList } from 'helpers/AppletHelpers';
import useHandleAppletSelect from 'hooks/useHandleAppletSelect';
import useIsAppletSelected from 'hooks/useIsAppletSelected';
import { QuestApplet } from 'models/QuestApplet';

const SelectFeaturedArtifacts = ({
  values,
  setFieldValue,
  setNextButtonText,
}: StepProps) => {
  const questPortfolioApplets = useSelector(selectQuestPortfolioApplets);

  const applets = useMemo(
    () =>
      buildQuestAppletsList(
        questPortfolioApplets.filter((applet) =>
          values.applets.includes(applet.id),
        ),
      ),
    [questPortfolioApplets, values.applets],
  );

  const appletsMap = useMemo(
    () =>
      applets.reduce(
        (acc, app) =>
          app.appletType === 'group-applet'
            ? app.applets?.reduce(
                (childAcc, childApp) => ({
                  ...childAcc,
                  [childApp.id]: childApp,
                }),
                acc,
              ) || acc
            : {
                ...acc,
                [app.id]: app,
              },
        {} as Record<string, QuestApplet>,
      ),
    [applets],
  );

  const selectedApplets = useMemo(() => values.featuredApplets, [
    values.featuredApplets,
  ]);

  const handleAppletSelect = useHandleAppletSelect(
    setFieldValue,
    selectedApplets,
    'featuredApplets',
  );

  const isSelected = useIsAppletSelected(selectedApplets);

  useEffect(() => {
    const selectedAppletsMap: Record<string, boolean> = {};
    selectedApplets.forEach((app) => {
      const selectedApp = appletsMap[app];
      if (selectedApp?.groupId) {
        selectedAppletsMap[selectedApp?.groupId || ''] = true;
      } else {
        selectedAppletsMap[app] = true;
      }
    });
    if (Object.keys(selectedAppletsMap).length === 3) {
      setNextButtonText(localizedStrings.continue);
    } else {
      setNextButtonText(localizedStrings.select3Artifacts);
    }
  }, [appletsMap, selectedApplets, setNextButtonText]);

  return (
    <>
      <PublishShowcaseDescription>
        {localizedStrings.selectShowcaseFeatured}
      </PublishShowcaseDescription>
      <PublishShowcaseAppletsContainer>
        {applets.map((applet) => (
          <AppletPreview
            key={applet.id}
            applet={applet}
            onAppletClick={handleAppletSelect}
            showTitle={true}
            height="168px"
            width="178px"
            selected={isSelected(applet)}
          />
        ))}
      </PublishShowcaseAppletsContainer>
    </>
  );
};

export default SelectFeaturedArtifacts;
