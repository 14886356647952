import styled from '@emotion/styled';

export const ResumeCardDescription = styled.p`
  color: ${(props) => props?.theme?.colors?.white[100]};
  width: 100%;
  text-align: left;
  @media (min-width: ${(props) => props?.theme?.breakpoints?.hd}px) {
    max-width: 22.22rem;
  }
`;

export const ResumeCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: transparent;
  border: none;
  margin-left: 2.77rem;
  margin-right: 2.77rem;
  margin-bottom: 2.77rem;
  align-items: center;

  @media (min-width: ${(props) => props?.theme?.breakpoints.tablet}px) {
    margin-left: 1.38rem;
    margin-right: 1.38rem;
  }
  @media (min-width: ${(props) => props?.theme?.breakpoints.desktop}px) {
    margin-left: 2.77rem;
    margin-right: 2.77rem;
  }
`;

export const ResumeCardTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  width: 15rem;
  @media (min-width: ${(props) => props?.theme?.breakpoints.tablet}px) {
    align-items: flex-start;
  }
`;

export const ResumeCardText = styled.p`
  color: ${(props) => props?.theme?.colors.white[100]};
  font-size: ${(props) => props?.theme?.typography.large24};
  font-weight: ${(props) => props?.theme?.typography.semiBold};
  margin-bottom: 0.889rem;
  text-align: center;
`;
