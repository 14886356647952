import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import Modal from 'components/modal';
import localizedStrings from 'localization';
import { AddQuestPrompt } from 'modules/settings/components';
import { AppDispatch } from 'redux/typings';
import { useHistory } from 'react-router';
import { logout } from 'modules/auth/actions';
import { DialogConfirmButtons, DialogNoButton, DialogYesButton } from 'components/dialog/components';

type AddQuestModalProps = {
  open: boolean;
  close: () => void;
};

const SignOutModal = ({ open, close }: AddQuestModalProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();

  const handleClose = useCallback(() => {
    close();
  }, [close]);

  const handleYes = useCallback(() => {
    dispatch(logout());
    history.replace('/')
  }, [dispatch, history]);

  return (
    <Modal
      open={open}
      width={'500px'}
      title={localizedStrings.signOut}
      hideCloseButton>
      {(
        <>
          <AddQuestPrompt>
            {localizedStrings.logoutMsg}
          </AddQuestPrompt>
          <DialogConfirmButtons>
            <DialogNoButton onClick={handleClose}>
              {localizedStrings.no}
            </DialogNoButton>
            <DialogYesButton onClick={handleYes}>
              {localizedStrings.yes}
            </DialogYesButton>
          </DialogConfirmButtons>
        </>
      )}
    </Modal>
  );
};

export default SignOutModal;
