import * as yup from 'yup';

import localizedStrings from 'localization';

export const giveFeedbackSchema = yup.object().shape({
  rating: yup.number().min(1).max(5).required(),
  comment: yup.string().required(localizedStrings.shareMessageValidation),
});

export const changePasswordSchema = yup.object().shape({
  oldPassword: yup.string().required(localizedStrings.passwordRequiredMessage),
  newPassword: yup
    .string()
    .required(localizedStrings.passwordRequiredMessage)
    .min(8, localizedStrings.passwordMinLengthMessage),
  newPasswordConfirm: yup
    .string()
    .oneOf(
      [yup.ref('newPassword'), null],
      localizedStrings.passwordDifferentMessage,
    ),
});
