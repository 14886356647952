import React from 'react';
import ContentLoader from 'react-content-loader';

const NotificationsPlaceholder = () => {
  return (
    <ContentLoader
      style={{ marginTop: 25 }}
      width="100%"
      height="325"
      viewBox="0 0 100% 325"
      backgroundColor="#f5f5f5"
      foregroundColor="#dbdbdb">
      <circle cx="20" cy="20" r="20" />
      <rect x="50" y="5" rx="5" ry="5" width="300" height="10" />
      <rect x="50" y="25" rx="5" ry="5" width="300" height="10" />
      <rect x="0" y="50" width="100%" height="1" />
      <circle cx="20" cy="90" r="20" />
      <rect x="50" y="75" rx="5" ry="5" width="300" height="10" />
      <rect x="50" y="95" rx="5" ry="5" width="300" height="10" />
      <rect x="0" y="120" width="100%" height="1" />
      <circle cx="20" cy="160" r="20" />
      <rect x="50" y="145" rx="5" ry="5" width="300" height="10" />
      <rect x="50" y="165" rx="5" ry="5" width="300" height="10" />
      <rect x="0" y="190" width="100%" height="1" />
      <circle cx="20" cy="230" r="20" />
      <rect x="50" y="215" rx="5" ry="5" width="300" height="10" />
      <rect x="50" y="235" rx="5" ry="5" width="300" height="10" />
      <rect x="0" y="260" width="100%" height="1" />
      <circle cx="20" cy="300" r="20" />
      <rect x="50" y="285" rx="5" ry="5" width="300" height="10" />
      <rect x="50" y="305" rx="5" ry="5" width="300" height="10" />
      <rect x="0" y="330" width="100%" height="1" />
    </ContentLoader>
  );
};

export default NotificationsPlaceholder;
