import React, { useCallback, useEffect } from 'react';

import { ModalContainer } from 'components/modal/components';
import art from 'assets/images/Art.webp';
import greenBadge from 'assets/images/Badges_Green.webp';
import {
  CongratulationsArt,
  CongratulationsBadge,
  CongratulationsExperienceText,
  CongratulationsTopContent,
  CongratulationsBottomContent,
  CongratulationsNextText,
  CongratulationsSeparator,
  CongratulationsText,
  CongratulationsTitle,
  CongratulationsModalContent,
  ReturnToHomeButton,
  CongratulationsButtonsContainer,
  SingleButtonContainer,
} from '../components';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectSelectedContentCongratulations,
  selectSelectedContentTotalXp,
} from 'modules/loading-screen/contents/selectors';
import useContentfulRenderer from 'hooks/useContentfulRenderer';
import { useHistory } from 'react-router';
import { HOME } from 'routes';
import localizedStrings from 'localization';
import { selectNextUp } from '../../../modules/loading-screen/schedules/selectors';
import Share from '../share';
import CongratsNextUpCard from './CongratsNextUpCard';
import { CHALLENGE } from '../../../routes';
import { StepSchedule } from '../../../models/Schedule';
import { selectSelectedWeekId } from '../../loading-screen/weeks/selectors';
import { selectWeek } from '../../loading-screen/weeks/actions';
import { selectIsSampleQuest } from '../../loading-screen/quests/selectors';

type CongratulationsProps = {
  show: boolean;
  close: () => void;
};

const Congratulations = ({ show, close }: CongratulationsProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const congratulations = useSelector(selectSelectedContentCongratulations);
  const challengeXp = useSelector(selectSelectedContentTotalXp);
  const selectedWeekId = useSelector(selectSelectedWeekId);
  const isSampleQuest = useSelector(selectIsSampleQuest);
  const nextUp = useSelector(selectNextUp({ includeBonus: true }));

  const isBonus = nextUp?.steps[0].challengeTitle.includes('BONUS') || false;

  const renderedCongratulations = useContentfulRenderer(congratulations, {
    darkMode: true,
  });

  const navigateHome = useCallback(() => {
    history.push(HOME);
  }, [history]);

  const handleCardClick = useCallback(
    (step: StepSchedule) => () => {
      if (step?.weekId && selectedWeekId !== step?.weekId) {
        dispatch(selectWeek(step.weekId));
      }
      history.push(CHALLENGE + '/' + step.challengeId + '/' + step.stepNumber);
      close();
    },
    [selectedWeekId, close, history, dispatch],
  );

  useEffect(() => {
    return () => { close(); }
  }, [close])


  return (
    <ModalContainer visible={show}>
      <CongratulationsModalContent>
        <CongratulationsTopContent>
          <CongratulationsArt src={art} alt="background" />
          <CongratulationsBadge src={greenBadge} alt="background" />
          <CongratulationsTitle>
            {localizedStrings.congratulations}
          </CongratulationsTitle>
          <CongratulationsExperienceText>
            {localizedStrings.earned} {challengeXp} XP
          </CongratulationsExperienceText>
          <CongratulationsSeparator />
          <CongratulationsText>{renderedCongratulations}</CongratulationsText>
        </CongratulationsTopContent>

        {(nextUp?.steps?.length ?? 0) > 0 && nextUp && (
          <CongratulationsBottomContent
            onClick={handleCardClick(nextUp.steps[0])}>
            <CongratulationsNextText>
              {isBonus
                ? localizedStrings.bonusChallenge
                : localizedStrings.nextUp}
            </CongratulationsNextText>
            <CongratsNextUpCard
              nextUp={nextUp.steps[0]}
              onCardClick={handleCardClick(nextUp.steps[0])}
            />
          </CongratulationsBottomContent>
        )}
        <CongratulationsButtonsContainer>
          <SingleButtonContainer>
            <ReturnToHomeButton onClick={navigateHome} type="button">
              {localizedStrings.returnToHome}
            </ReturnToHomeButton>
          </SingleButtonContainer>
          {!isSampleQuest && (
            <SingleButtonContainer>
              <Share />
            </SingleButtonContainer>
          )}
        </CongratulationsButtonsContainer>
      </CongratulationsModalContent>
    </ModalContainer>
  );
};

export default Congratulations;
