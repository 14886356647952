import React from 'react';
import { pdfjs, Document, Page } from 'react-pdf';

import { AppletPreviewBackground } from 'modules/profile/components';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

type PdfAppletPreviewProps = {
  src: string;
};

const PdfAppletPreview = ({ src }: PdfAppletPreviewProps) => {
  return (
    <AppletPreviewBackground>
      <Document file={src}>
        <Page pageNumber={1} height={100} renderAnnotationLayer={false} />
      </Document>
    </AppletPreviewBackground>
  );
};

export default PdfAppletPreview;
