import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { SendButton, SendButtonContainer, SendButtonText } from './components';
import MailIcon from './MailIcon';
import localizedStrings from 'localization';
import { MadlibAnswer } from 'models/Applet';
import { IAppletFields } from 'models/contentful';
import { UploadFile } from '../app-upload';
import { openShareAppletModal } from 'modules/modal/actions';

type ShareAppletButtonProps = {
  appletContentfulId: string;
  type: IAppletFields['type'];
  richText?: string;
  madlibAnswers?: MadlibAnswer[];
  file?: UploadFile;
  disabled?: boolean;
};

const ShareAppletButton = ({
  appletContentfulId,
  type,
  richText,
  madlibAnswers,
  file,
  disabled,
}: ShareAppletButtonProps) => {
  const dispatch = useDispatch();
  const openModal = useCallback(() => {
    dispatch(
      openShareAppletModal({
        appletContentfulId,
        type,
        file,
        madlibAnswers,
        richText,
      }),
    );
  }, [dispatch, appletContentfulId, type, file, madlibAnswers, richText]);

  return (
    <SendButtonContainer>
      <SendButton disabled={disabled} onClick={openModal}>
        <MailIcon />
        <SendButtonText>{localizedStrings.send}</SendButtonText>
      </SendButton>
    </SendButtonContainer>
  );
};

export default ShareAppletButton;
