import React, { useCallback } from 'react';

import { Document } from '@contentful/rich-text-types';
import { IQuiz } from '../../../models/contentful';
import { QuizQuestion } from '../../../models/Applet';
import QuizResult from '../../../components/applets/quiz-applet/QuizResult';
import Modal from '../../../components/modal/index';

type QuizAppletResultModalProps = {
  isModalOpen: boolean;
  setIsModalOpen: (val: boolean) => void;
  quiz?: IQuiz;
  quizQuestions?: QuizQuestion[];
  hideRewardText?: boolean;
  mixedResultText?: Document;
  appletId: string;
};

const QuizAppletResultModal = ({
  isModalOpen,
  setIsModalOpen,
  quiz,
  quizQuestions,
  mixedResultText,
  appletId,
}: QuizAppletResultModalProps) => {
  const closeModal = useCallback(() => {
    setIsModalOpen(false);
  }, [setIsModalOpen]);

  return (
    <Modal open={isModalOpen} closeModal={closeModal}>
      {/*
          Added this conditional rendering because I noticed that all components inside the 
          chart have a zIndex: 1; which might break other components when presented inside a modal. 
      */}
      {isModalOpen ? (
        <QuizResult
          appletId={appletId}
          quiz={quiz}
          quizQuestions={quizQuestions}
          mixedResultText={mixedResultText}
        />
      ) : null}
    </Modal>
  );
};

export default QuizAppletResultModal;
