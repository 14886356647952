import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import {
  clearSignup,
  forgotPassword,
  login,
  refresh,
  resendCode,
  resetPassword,
  signup,
  verifyCode,
  getLegalDoc,
  clearJwt,
  updateProfilePicture,
  updateProfileDisplayName,
  updateProfileVisibilityAchievements,
} from './actions';
import { AuthState, LoginData, Signup } from './typings';

const initialState: AuthState = {
  signup: { id: '', displayName: '', email: '' },
  legalDocuments: { documents: {} },
};

export default createReducer<AuthState>(initialState, (auth) => {
  auth
    .addCase(login.fulfilled, (state, action) => updateJwt(state, action))
    .addCase(refresh, (state, action) => updateJwt(state, action))
    .addCase(clearJwt, (state, _) => ({ ...state, jwt: undefined }))
    .addCase(updateProfilePicture.fulfilled, (state, action) => ({
      ...state,
      jwt: action.payload,
    }))
    .addCase(updateProfileDisplayName.fulfilled, (state, action) => ({
      ...state,
      jwt: action.payload,
    }))
    .addCase(
      updateProfileVisibilityAchievements.fulfilled,
      (state, action) => ({
        ...state,
        jwt: action.payload,
      }),
    )
    .addCase(signup.fulfilled, (state, action) => updateSignup(state, action))
    .addCase(resendCode.fulfilled, (state, action) =>
      updateSignup(state, action),
    )
    .addCase(verifyCode.fulfilled, (state, action) => updateJwt(state, action))
    .addCase(forgotPassword.fulfilled, (state, action) =>
      updateSignup(state, action),
    )
    .addCase(resetPassword.fulfilled, (state, action) =>
      updateSignup(state, action),
    )
    .addCase(clearSignup, (state, _) => ({
      ...state,
      signup: { id: '', displayName: '', email: '' },
    }))
    .addCase(getLegalDoc.fulfilled, (state, action) => ({
      ...state,
      legalDocuments: {
        ...state.legalDocuments,
        documents: {
          ...state.legalDocuments.documents,
          [action.meta.arg]: action.payload,
        },
      },
    }));
});

function updateJwt(
  state: AuthState,
  action: PayloadAction<LoginData>,
): AuthState {
  return {
    ...state,
    jwt: action.payload.jwt,
  };
}

function updateSignup(
  state: AuthState,
  action: PayloadAction<Signup>,
): AuthState {
  return {
    ...state,
    signup: { ...state.signup, ...action.payload },
  };
}
