import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import OnboardingCarousel from 'modules/onboarding/onboarding-carousel';
import { HOME } from 'routes';
import { OnboardingContainer } from '../components';
import { AppDispatch } from 'redux/typings';
import { notifyWeekOnboardingSeen, setComeFromChallenge } from '../actions';
import {
  selectComeFromChallenge,
  selectNewOnboardings,
  selectNewOnboardingWeekId,
} from '../selectors';
import { selectUserCohortExist } from 'modules/settings/selectors';

const WeekOnboarding = () => {
  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();

  const onboardings = useSelector(selectNewOnboardings);
  const newOnboardingWeekId = useSelector(selectNewOnboardingWeekId);
  const existUserInCohort = useSelector(selectUserCohortExist);
  const comeFromChallenge = useSelector(selectComeFromChallenge);

  const handleGetStarted = useCallback(() => {
    if (newOnboardingWeekId) {
      dispatch(notifyWeekOnboardingSeen({ weekId: newOnboardingWeekId }));
    }
    if (comeFromChallenge) {
      dispatch(setComeFromChallenge(false));
      history.goBack();
    } else {
      history.push(HOME);
    }
  }, [dispatch, history, newOnboardingWeekId, comeFromChallenge]);

  useEffect(() => {
    if ((!onboardings || onboardings.length === 0) && newOnboardingWeekId) {
      dispatch(notifyWeekOnboardingSeen({ weekId: newOnboardingWeekId }));
      if (comeFromChallenge) {
        dispatch(setComeFromChallenge(false));
        history.goBack();
      } else {
        history.push(HOME);
      }
    }
  }, [onboardings, history, dispatch, newOnboardingWeekId, comeFromChallenge]);

  return !existUserInCohort?.exist ? (
    <OnboardingContainer>
      {onboardings && onboardings.length > 0 ? (
        <OnboardingCarousel
          onboardings={onboardings.filter((onb) => onb)}
          handleGetStarted={handleGetStarted}
        />
      ) : (
        <p>Loading...</p>
      )}
    </OnboardingContainer>
  ) : null;
};

export default WeekOnboarding;
