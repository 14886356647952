import React, { useCallback, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Formik, FormikProps } from 'formik';

import Modal from 'components/modal';
import { AddOptions } from './AddModal';
import { inviteSomeoneElseSchema } from './validationSchemas';
import InputField from 'components/input-field';
import {
  ButtonsRow,
  InviteButton,
  InviteCancelButton,
  InviteSomeoneElsePrompt,
} from 'modules/relationships/components';
import localizedStrings from 'localization';
import { AppDispatch } from 'redux/typings';
import { invite } from 'modules/relationships/actions';
import { getInvitePayload } from 'helpers/relationshipHelpers';
import { RelationshipType } from 'models/Relationship.d';

type InitialValuesType = {
  name: string;
  email: string;
  message: string;
};

type InviteSomeoneElseModalProps = {
  open: boolean;
  closeModal: () => void;
  option?: AddOptions;
  onInvitationSent: (invitedUserName: string) => void;
};

const InviteSomeoneElseModal = ({
  open,
  closeModal,
  option = AddOptions.ADD_RUNNING_PARTNER,
  onInvitationSent,
}: InviteSomeoneElseModalProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const formikRef = useRef<FormikProps<InitialValuesType>>(null);
  const title = useMemo(
    () =>
      option === AddOptions.ADD_RUNNING_PARTNER
        ? localizedStrings.inviteRunningPartnerTitle
        : localizedStrings.inviteMentorTitle,
    [option],
  );

  const prompt = useMemo(
    () =>
      option === AddOptions.ADD_RUNNING_PARTNER
        ? localizedStrings.inviteRunningPartner
        : localizedStrings.inviteMentor,
    [option],
  );

  const handleClose = useCallback(() => {
    formikRef.current?.resetForm();
    closeModal();
  }, [closeModal]);

  const handleSubmit = useCallback(
    ({ name, email, message }: InitialValuesType) => {
      dispatch(
        invite(
          getInvitePayload(
            name,
            email,
            message,
            option === AddOptions.ADD_RUNNING_PARTNER
              ? RelationshipType.EXTERNAL_RUNNING_PARTNER
              : RelationshipType.EXTERNAL_MENTOR,
          ),
        ),
      );
      formikRef.current?.resetForm();
      onInvitationSent(name);
    },
    [dispatch, onInvitationSent, option],
  );

  return (
    <Modal open={open} closeModal={handleClose} width={'516px'} title={title}>
      <InviteSomeoneElsePrompt>{prompt}</InviteSomeoneElsePrompt>
      <Formik
        initialValues={{
          name: '',
          email: '',
          message: '',
        }}
        onSubmit={handleSubmit}
        validationSchema={inviteSomeoneElseSchema}
        innerRef={formikRef}>
        <Form>
          <InputField id="name" name="name" placeholder="Name" autoComplete='off' />
          <InputField id="email" name="email" placeholder="Email" autoCapitalize="off" autoComplete='off' />
          <InputField id="message" name="message" placeholder="Message" textarea rows={10} autoComplete='off' />
          <ButtonsRow>
            <InviteCancelButton type="button" onClick={handleClose}>
              {localizedStrings.cancel}
            </InviteCancelButton>
            <InviteButton type="submit">{localizedStrings.invite}</InviteButton>
          </ButtonsRow>
        </Form>
      </Formik>
    </Modal>
  );
};

export default InviteSomeoneElseModal;
