import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { PageBackground } from 'components/components';
import NavBar from 'modules/navbar';
import { AppDispatch } from 'redux/typings';
import {
  RelatedUserProfileContainer,
  RelatedUserInfoSection,
  RelatedUserDetailsSection,
} from '../components';
import { selectSelectedRelationship } from '../selectors';
import {
  getUserAchievements,
  getUserShowcases,
  selectAchievement,
} from './actions';

import RelatedUserInfo from './components/RelatedUserInfo';
import RelatedUserDetails from './components/RelatedUserDetails';
import RelatedUserChallengeDetails from './components/RelatedUserChallengeDetails';
import { selectRelationship } from '../actions';

const RelationshipProfile = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { id, achievementId } = useParams<{
    id: string;
    achievementId: string;
  }>();
  const selectedRelationship = useSelector(selectSelectedRelationship);
  const userId = selectedRelationship?.relatedUser.id;

  useEffect(() => {
    if (selectedRelationship && userId) {
      dispatch(getUserAchievements({ userId }));
      dispatch(getUserShowcases({ userId }));
    } else {
      dispatch(selectRelationship(id));
    }
  }, [dispatch, userId, id, selectedRelationship]);

  useEffect(() => {
    if (achievementId) {
      dispatch(selectAchievement(achievementId));
    }
  }, [achievementId, dispatch]);

  return (
    <>
      <NavBar />
      {selectedRelationship && (
        <PageBackground>
          <RelatedUserProfileContainer>
            <RelatedUserInfoSection>
              <RelatedUserInfo
                displayName={selectedRelationship.relatedUser.displayName}
                profilePictureUrl={
                  selectedRelationship.relatedUser.profilePictureUrl
                }
                relationshipType={selectedRelationship.relationshipType}
              />
            </RelatedUserInfoSection>
            <RelatedUserDetailsSection>
              {achievementId ? (
                <RelatedUserChallengeDetails />
              ) : (
                <RelatedUserDetails userId={userId} />
              )}
            </RelatedUserDetailsSection>
          </RelatedUserProfileContainer>
        </PageBackground>
      )}
    </>
  );
};

export default RelationshipProfile;
