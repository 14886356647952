import { useSelector } from 'react-redux';
import { useCallback } from 'react';

import DecisionTreePreview from 'components/applets/decision-tree-applet/DecisionTreePreview';
import RankApplet from 'components/applets/rank-applet';
import { ChallengeApplet } from 'models/ChallengeApplet';
import FileUploadPreview from 'modules/profile/portfolio/quest-applets/FileUploadPreview';
import InlineTextPreview from 'modules/profile/portfolio/quest-applets/InlineTextPreview';
import {
  SubmissionImagePreview,
  SubmissionVideoPreview,
} from 'modules/relationships/components';
import SubmissionGroupApplet from 'modules/relationships/profile/components/SubmissionGroupApplet';
import { selectAppletsContentful } from 'modules/loading-screen/contentful/applets/selectors';
import RandomSelectionAppletSubmission from './use-render-submission-applet/RandomSelectionAppletSubmission';
import MadlibAppletSubmission from './use-render-submission-applet/MadlibAppletSubmission';
import QuizAppletSubmission from './use-render-submission-applet/QuizAppletSubmission';
import RichTextApplet from 'components/applets/rich-text';

const useRenderSubmissionApplet = () => {
  const appletsContentful = useSelector(selectAppletsContentful);
  const renderApplet = useCallback(
    (applet: ChallengeApplet) => {
      switch (applet.appletType ?? applet.type) {
        case 'upload-file':
          return (
            <FileUploadPreview
              fileUrl={applet.fileUrl!}
              fileType={applet.fileType!}
              title={applet.title}
            />
          );
        case 'upload-video':
          return (
            <SubmissionVideoPreview
              key={applet.id}
              src={applet.fileUrl}
              controls
              autoPlay
            />
          );
        case 'upload-image':
          return (
            <SubmissionImagePreview
              key={applet.id}
              src={applet.fileUrl}
              alt={applet.title}
            />
          );
        case 'rich-text-entry':
          return <RichTextApplet initialContent={applet.richText} disabled />;
        case 'decision-tree':
          return <DecisionTreePreview files={applet.files} />;
        case 'inline-text':
          return <InlineTextPreview applet={applet} />;
        case 'madlib':
          return <MadlibAppletSubmission applet={applet} />;
        case 'quiz':
          return <QuizAppletSubmission applet={applet} />;
        case 'rank':
          return (
            <RankApplet
              appletId={applet.appletId}
              optionsContentful={applet.optionsContentful!}
              options={applet.options}
              forcedDisabled
            />
          );
        case 'group-applet':
          return <SubmissionGroupApplet applet={applet} />;
        case 'random-selection':
          return appletsContentful?.[applet.appletId] ? (
            <RandomSelectionAppletSubmission applet={applet} />
          ) : null;
        default:
          return null;
      }
    },
    [appletsContentful],
  );

  return renderApplet;
};

export default useRenderSubmissionApplet;
