import localizedStrings from 'localization';
import { IPlaylistArtifactFields } from 'models/contentful';
import { PLAYLIST_FILTERS } from './typings';

export const filtersDefinitions = {
  [PLAYLIST_FILTERS.LISTEN]: ['podcast', 'reading'],
  [PLAYLIST_FILTERS.WATCH]: ['video'],
  [PLAYLIST_FILTERS.READ]: ['reading', 'web-article', 'book'],
  [PLAYLIST_FILTERS.ALL]: [
    'book',
    'podcast',
    'reading',
    'video',
    'web-article',
    'socratic-question',
  ],
  [PLAYLIST_FILTERS.ASK]: ['socratic-question'],
};

export const linkTypeArtifactModalDescription = (
  type?: IPlaylistArtifactFields['type'],
) => {
  switch (type) {
    case 'book':
      return localizedStrings.goToBook;
    case 'podcast':
      return localizedStrings.goToPodcast;
    case 'reading':
      return localizedStrings.goToReading;
    case 'web-article':
      return localizedStrings.goToWebArticle;
    case 'video':
      return localizedStrings.goToVideo;
    case 'socratic-question':
      return localizedStrings.goToSocraticQuestion;
    default:
      return '';
  }
};

type PlaylistTabType = {
  name: PLAYLIST_FILTERS;
  description: string;
};

export const playlistTabs: PlaylistTabType[] = [
  { name: PLAYLIST_FILTERS.ALL, description: localizedStrings.all },
  { name: PLAYLIST_FILTERS.WATCH, description: localizedStrings.video },
  { name: PLAYLIST_FILTERS.READ, description: localizedStrings.readings },
  { name: PLAYLIST_FILTERS.LISTEN, description: localizedStrings.audio },
  { name: PLAYLIST_FILTERS.ASK, description: localizedStrings.ask },
];
