import React, { useCallback, useMemo } from 'react';
import { IApplet, IQuizQuestion } from '../../../../models/contentful';
import MadlibQuizQuestionOption from './MadlibQuizQuestionOption';
import { useDispatch } from 'react-redux';
import { updateQuizQuestionValue } from '../../../../modules/applet-upload/actions';
import Option from './Option';
import useQuizChallengeLogic from 'hooks/useQuizChallengeLogic';
import { QuizQuestionAnswerType } from '../../../../models/QuizQuestionAnswerType';

type QuizQuestionMadlibChallengeProps = {
  question: IQuizQuestion;
  option: IApplet;
  appletContentfulId: string;
  quizQuestionContentfulId: string;
  quizSectionContentfulId: string;
  quizContentfulId: string;
};

const QuizQuestionMadlibChallenge = ({
  question,
  option,
  appletContentfulId,
  quizQuestionContentfulId,
  quizSectionContentfulId,
  quizContentfulId,
}: QuizQuestionMadlibChallengeProps) => {
  const {
    id: appletUserId,
    quizQuestions,
    isAppletDisabled,
  } = useQuizChallengeLogic(appletContentfulId);

  const dispatch = useDispatch();

  const onSelectMadlibOption = useCallback(() => {
    dispatch(
      updateQuizQuestionValue({
        applet: {
          id: appletUserId,
          appletId: appletContentfulId,
          quizContentfulId,
          quizSectionContentfulId,
          contentfulId: question.sys.id,
          madlibAppletContentfulId: option.sys.id,
          answerType: QuizQuestionAnswerType.MADLIB,
          answer: undefined,
        },
        modified: true,
      }),
    );
  }, [
    appletContentfulId,
    appletUserId,
    dispatch,
    option.sys.id,
    question.sys.id,
    quizContentfulId,
    quizSectionContentfulId,
  ]);

  let answer = useMemo(() => {
    if (quizQuestions) {
      const foundQuestion = quizQuestions.find((q) => {
        return q.quizQuestionContentfulId === question.sys.id;
      })?.madlibAppletContentfulId;
      return foundQuestion;
    }
  }, [quizQuestions, question.sys.id]);

  return (
    <>
      <Option
        renderedOption={
          <MadlibQuizQuestionOption
            appletContentfulId={appletContentfulId}
            madlibAppletContentfulId={option.sys.id}
            quizContentfulId={quizContentfulId}
            quizQuestionContentfulId={quizQuestionContentfulId}
            quizSectionContentfulId={quizSectionContentfulId}
            question={question}
          />
        }
        key={option.sys.id}
        disabled={isAppletDisabled}
        value={option.sys.id}
        answer={answer}
        onValueChange={onSelectMadlibOption}
      />
    </>
  );
};

export default QuizQuestionMadlibChallenge;
