import React, { useCallback, useState } from 'react';

import FilterModal from 'components/filter-modal';
import FilterIcon from 'components/icons/FilterIcon';
import { FilterButton } from './components';

const FilterSection = () => {
  const [filterModalOpen, setFilterModalOpen] = useState(false);

  const openFilterModal = useCallback(() => setFilterModalOpen(true), []);
  const closeFilterModal = useCallback(() => setFilterModalOpen(false), []);

  return (
    <>
      <FilterButton color="primary" onClick={openFilterModal}>
        <FilterIcon />
      </FilterButton>
      <FilterModal isOpen={filterModalOpen} close={closeFilterModal} />
    </>
  );
};

export default FilterSection;
