import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Formik, FormikProps } from 'formik';

import NavBar from '../navBar';
import {
  ShowcaseButtonsContainer,
  ShowcaseCancelButton,
  ShowcaseContactButton,
  ShowcaseContactContainer,
  ShowCaseContactItemsContainer,
  ShowcaseContactRow,
  ShowcaseContactTitle,
  ShowcaseContactSubtitle,
  ShowcaseForm,
  ShowcaseContactRowItem,
} from './ShowcaseContactComponents';
import { SHOWCASE, SHOWCASE_RESUME } from 'routes';
import { showcaseEmailSchema } from './validationSchemas';
import InputField from 'components/input-field';
import { AppDispatch } from 'redux/typings';
import { sendEmail } from '../actions';
import Modal from 'components/modal';
import localizedStrings from 'localization';
import useActionStatus from 'hooks/useActionStatus';

const ShowcaseContact = () => {
  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();
  const { id: showcaseId } = useParams<{ id: string }>();
  const [modalOpen, setModalOpen] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const formikRef = useRef<
    FormikProps<{
      firstName: string;
      lastName: string;
      email: string;
      message: string;
    }>
  >(null);

  const [isPending, wasFulfilled] = useActionStatus(sendEmail);

  const closeModal = useCallback(() => {
    setModalOpen(false);
    history.push(`${SHOWCASE}/${showcaseId}`);
  }, [history, showcaseId]);

  const goToResume = useCallback(() => {
    history.push(`${SHOWCASE}/${showcaseId + SHOWCASE_RESUME}`);
  }, [history, showcaseId]);

  const handleSubmit = useCallback(
    (values: {
      firstName: string;
      lastName: string;
      email: string;
      message: string;
    }) => {
      dispatch(sendEmail({ ...values, showcaseId }));
      setHasSubmitted(true);
    },
    [dispatch, showcaseId],
  );

  useEffect(() => {
    if (hasSubmitted && wasFulfilled) {
      setModalOpen(true);
      setHasSubmitted(false);
      formikRef.current?.resetForm();
    }
  }, [hasSubmitted, wasFulfilled]);

  return (
    <ShowcaseContactContainer>
      <NavBar />
      <Modal
        open={modalOpen}
        closeModal={closeModal}
        title={localizedStrings.emailSent}
        width="516px">
        {localizedStrings.emailSentMessage}
      </Modal>
      <ShowCaseContactItemsContainer>
        <ShowcaseContactTitle>Contact</ShowcaseContactTitle>
        <ShowcaseContactSubtitle>
          {localizedStrings.showcaseGetInTouch}
        </ShowcaseContactSubtitle>
        <Formik
          initialValues={{
            firstName: '',
            lastName: '',
            email: '',
            message: '',
          }}
          onSubmit={handleSubmit}
          validationSchema={showcaseEmailSchema}
          innerRef={formikRef}>
          <ShowcaseForm>
            <ShowcaseContactRow>
              <ShowcaseContactRowItem>
                <InputField name="firstName" placeholder="First Name" />
              </ShowcaseContactRowItem>
              <ShowcaseContactRowItem>
                <InputField name="lastName" placeholder="Last Name" />
              </ShowcaseContactRowItem>
            </ShowcaseContactRow>
            <InputField name="email" placeholder="Email" autoCapitalize="off" />
            <InputField
              name="message"
              placeholder="Message"
              textarea
              rows={10}
            />
            <ShowcaseButtonsContainer>
              <ShowcaseCancelButton onClick={goToResume}>
                {localizedStrings.cancel}
              </ShowcaseCancelButton>
              <ShowcaseContactButton type="submit" isLoading={isPending}>
                {localizedStrings.send}
              </ShowcaseContactButton>
            </ShowcaseButtonsContainer>
          </ShowcaseForm>
        </Formik>
      </ShowCaseContactItemsContainer>
    </ShowcaseContactContainer>
  );
};

export default ShowcaseContact;
