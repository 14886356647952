import React from 'react';

import {
  NextChallengeCardContainer,
  NextChallengeIconsContainer,
  NextChallengeInfoContainer,
  NextChallengeTitle,
  NextStepTitle,
  NextStepsContainer,
  TotalStepsContainer,
  ChallengeContentContainer,
  ChallengeSubtitle,
  NextChallengeContainerBackground,
} from '../components';
import { StepSchedule } from 'models/Schedule';
import CongratsNextUpInfo from './CongratsNextUpInfo';
import { selectContentsContentful } from '../../loading-screen/contentful/contents/selectors';
import { useSelector } from 'react-redux';
import { selectContents } from '../../loading-screen/contents/selectors';
import { selectStepsContentful } from '../../loading-screen/contentful/steps/selectors';

type NextChallengeCardProps = {
  nextUp: StepSchedule;
  onCardClick: () => void;
};

const CongratsNextUpCard = ({
  nextUp,
  onCardClick = () => { },
}: NextChallengeCardProps) => {
  const contents = useSelector(selectContents);
  const challengeUser = contents[nextUp.challengeId || ''];
  const totalSteps = challengeUser?.stepIds.length + 1;

  const contentsContentful = useSelector(selectContentsContentful);
  const challengeContentful = contentsContentful[challengeUser?.contentfulId];
  const challengeSubtitle = challengeContentful?.subtitle;
  const challengeLocation = challengeContentful?.location;

  const steps = useSelector(selectStepsContentful);
  const stepNumber = nextUp.id && steps[nextUp.id]?.stepNumber;
  const currentStepNumber =
    nextUp.type === 'step' ? Number(stepNumber) + 1 : totalSteps;

  return (
    <NextChallengeCardContainer
      imageUrl={nextUp.challengeImage}
      onClick={onCardClick}>
      <ChallengeContentContainer>
        <NextChallengeInfoContainer>
          <div>
            <ChallengeSubtitle>{challengeSubtitle}</ChallengeSubtitle>
          </div>
          <NextChallengeContainerBackground>
            <NextChallengeTitle>{nextUp.challengeTitle}</NextChallengeTitle>
            <NextStepsContainer>
              <NextStepTitle>{nextUp.stepTitle}</NextStepTitle>
              <TotalStepsContainer>
                {currentStepNumber} / {totalSteps}
              </TotalStepsContainer>
            </NextStepsContainer>
            <NextChallengeIconsContainer>
              <CongratsNextUpInfo
                time={nextUp.stepDuration}
                xp={nextUp.stepPoints}
                location={challengeLocation || ''}
              />
            </NextChallengeIconsContainer>
          </NextChallengeContainerBackground>
        </NextChallengeInfoContainer>
      </ChallengeContentContainer>
    </NextChallengeCardContainer>
  );
};

export default CongratsNextUpCard;
