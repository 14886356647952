import { createAsyncThunk } from '@reduxjs/toolkit';

import { getQuestsOnboardings as getQuestsOnboardingsService } from './services';
import { QuestOnboarding } from 'models/QuestOnboarding';

const GET_QUESTS_ONBOARDINGS = 'GET_QUESTS_ONBOARDINGS';
export const getQuestsOnboardings = createAsyncThunk<
  QuestOnboarding[],
  string | undefined
>(GET_QUESTS_ONBOARDINGS, getQuestsOnboardingsService);
