import React from 'react';

type TipsIconProps = {
  colorPrimary?: string;
  colorDisabled?: string;
  disabled?: boolean;
};

const TipsIcon = ({
  colorPrimary = '#007DC2',
  colorDisabled = '#8E8E93',
  disabled = false,
}: TipsIconProps) => {
  return (
    <svg width="20px" height="20px" viewBox="0 0 20 20">
      <title>828F20FD-215E-4D43-858E-61E21E97610C</title>
      <g id="V3" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="Challenge---03-"
          transform="translate(-216.000000, -439.000000)"
          fill={disabled ? colorDisabled : colorPrimary}>
          <g id="Group-3" transform="translate(213.500000, 437.000000)">
            <g id="Icons/24/Tips" transform="translate(0.500000, 0.000000)">
              <path d="M19,2 C20.595,2 21.903,3.252 21.995,4.824 L22,5 L22,15 C22,16.595 20.748,17.903 19.176,17.995 L19,18 L15.5,18 L12.8,21.6 C12.611,21.852 12.315,22 12,22 C11.724,22 11.463,21.887 11.276,21.69 L11.2,21.6 L8.5,18 L5,18 C3.405,18 2.097,16.748 2.005,15.176 L2,15 L2,5 C2,3.405 3.252,2.097 4.824,2.005 L5,2 L19,2 Z M19,4 L5,4 C4.488,4 4.065,4.387 4.007,4.884 L4,5 L4,15 C4,15.512 4.387,15.935 4.884,15.993 L5,16 L9,16 C9.276,16 9.537,16.113 9.724,16.31 L9.8,16.4 L12,19.333 L14.2,16.4 C14.365,16.179 14.613,16.039 14.883,16.007 L15,16 L19,16 C19.512,16 19.935,15.613 19.993,15.116 L20,15 L20,5 C20,4.488 19.613,4.065 19.116,4.007 L19,4 Z M17,11 C17.552,11 18,11.448 18,12 C18,12.513 17.614,12.935 17.117,12.993 L17,13 L7,13 C6.448,13 6,12.552 6,12 C6,11.487 6.386,11.065 6.883,11.007 L7,11 L17,11 Z M17,7 C17.552,7 18,7.448 18,8 C18,8.513 17.614,8.935 17.117,8.993 L17,9 L7,9 C6.448,9 6,8.552 6,8 C6,7.487 6.386,7.065 6.883,7.007 L7,7 L17,7 Z"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default TipsIcon;
