import React from 'react';

import RadioButton from 'components/radio-button';

type OptionProps = {
  answer?: string;
  renderedOption: React.ReactNode;
  onValueChange?: () => void;
  disabled: boolean;
  value?: string;
  result?: string;
};

const Option = ({
  answer = '',
  renderedOption,
  onValueChange,
  disabled,
  value,
  result,
}: OptionProps) => {
  let answerSelected =
    answer.length > 0 &&
    (answer ? answer === value : result ? result === value : false);

  return (
    <RadioButton
      label={renderedOption}
      value={value}
      selected={answerSelected}
      onSelect={onValueChange}
      disabled={disabled}
    />
  );
};

export default Option;
