import React from 'react';
import QuizSection from '../quiz-section/QuizSection';
import useQuizChallengeLogic from 'hooks/useQuizChallengeLogic';
import QuizSectionQuestionsListChallenge from '../quiz-section/QuizSectionQuestionListChallenge';
import QuizSectionDescriptionRenderer from '../quiz-section/QuizSectionDescriptionRenderer';

type QuizSectionListCelebrateProps = {
  appletId: string;
  showQuiz?: boolean;
  disabled: boolean;
};

const QuizSectionListCelebrate = ({
  appletId,
  showQuiz,
}: QuizSectionListCelebrateProps) => {
  const { quiz, isAppletDisabled } = useQuizChallengeLogic(appletId);

  return (
    <>
      {quiz?.fields?.sections?.map((item) => {
        return showQuiz ? (
          <QuizSection
            key={item.sys.id}
            title={item?.fields?.title}
            description={<QuizSectionDescriptionRenderer item={item} />}
            quizSectionQuestions={
              <QuizSectionQuestionsListChallenge
                quizSection={item}
                appletId={appletId}
                disabled={isAppletDisabled}
                quizContentfulId={quiz.sys.id}
              />
            }
          />
        ) : null;
      })}
    </>
  );
};

export default QuizSectionListCelebrate;
