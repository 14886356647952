import styled from '@emotion/styled';
import ButtonPrimary from 'components/button-primary';
import { ScrollableArea } from 'components/components';

type ModalProps = {
  visible: boolean;
};

export const ModalContainer = styled.div<ModalProps>`
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.8);
  height: 100%;
  width: 100%;
`;

type ModalContentProps = {
  width: string;
  height: string;
};

export const ModalPanel = styled.div<ModalContentProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  margin: auto;
  padding: 1rem 2rem 0rem 2rem;
  border-radius: 0.6rem;
  width: ${(props) => props.width};
  max-width: 80%;
  height: ${(props) => props.height};
  max-height: 90%;
`;

export const ModalTitle = styled.div`
  display: flex;  
  color: ${(props) => props?.theme?.colors.blue2[100]};
  font-size: ${(props) => props?.theme?.typography.large24};
  line-height: ${(props) => props?.theme?.typography.large24};
  margin-bottom: 1rem;
`;

export const ModalDescript = styled.p`
  text-align: center;
`;

export const ModalTitleIcon = styled.span`
  margin-right: 0.5rem;
`;

export const ModalContent = styled(ScrollableArea)`
  flex: 1;
  font-size: ${(props) => props?.theme?.typography.large18};
  line-height: ${(props) => props?.theme?.typography.large24};
  text-align: justify;
  margin-bottom: 2rem;  
  width: 100%;
  ::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
  -ms-overflow-style: none;
`;

export const CloseModalButton = styled.div`
  align-self: flex-end;
  margin: 0.5rem -0.25rem;
  cursor: pointer;
`;

export const AcceptModalButton = styled(ButtonPrimary)`
  width: 12rem;
  margin: 0 0.5rem 1rem 0.5rem;
`;
