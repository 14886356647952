import React, { useCallback, useEffect, useRef, useState } from 'react';

type VideoThumbnailProps = {
  file: string;
};

const VideoThumbnail = ({ file }: VideoThumbnailProps) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const handleVideoLoaded = useCallback(() => {
    if ((videoRef.current?.readyState ?? 0) > 1) {
      setVideoLoaded(true);
    }
  }, []);

  const handleResize = useCallback(() => {
    if (canvasRef.current) {
      setWidth(canvasRef.current.parentElement?.clientWidth ?? 0);
      setHeight(canvasRef.current.parentElement?.clientHeight ?? 0);
    }
  }, []);

  useEffect(() => {
    if (canvasRef.current) {
      setWidth(canvasRef.current.parentElement?.clientWidth ?? 0);
      setHeight(canvasRef.current.parentElement?.clientHeight ?? 0);
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  useEffect(() => {
    if (videoRef.current && videoLoaded && canvasRef.current) {
      const context = canvasRef.current.getContext('2d');
      const videoWidth = videoRef.current.videoWidth;
      const videoHeight = videoRef.current.videoHeight;
      const size = Math.min(videoWidth, videoHeight);
      const left = (videoWidth - size) / 2;
      const top = (videoHeight - size) / 2;

      context?.drawImage(
        videoRef.current,
        left,
        top,
        size,
        size,
        0,
        0,
        height,
        width,
      );
    }
  }, [height, videoLoaded, width, videoRef.current?.readyState]);

  return (
    <>
      <video
        width={0}
        height={0}
        ref={videoRef}
        src={file + '#t=1'}
        onCanPlay={handleVideoLoaded}
      />
      <canvas
        height={height}
        width={width}
        ref={canvasRef}
        style={{ borderRadius: 12 }}
      />
    </>
  );
};

export default VideoThumbnail;
