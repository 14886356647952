import React, { useMemo } from 'react';

import {
  AUDIO_MIMETYPE,
  getFileObject,
  IMAGE_MIMETYPE,
  PDF_MIMETYPE,
  TEXT_MIMETYPE,
  VIDEO_MIMETYPE,
} from 'helpers/FileHelpers';
import {
  ImageAppletModalPreview,
  VideoAppletModalPreview,
} from 'modules/profile/components';
import PdfPreview from 'components/pdf-preview';
import TxtPreview from 'components/applets/app-upload/file-preview/TxtPreview';

type FileUploadPreviewProps = {
  fileUrl: string;
  fileType: string;
  title: string;
};

const FileUploadPreview = ({
  fileUrl,
  fileType,
  title,
}: FileUploadPreviewProps) => {
  const Preview = useMemo(() => {
    const fileObject = getFileObject({ fileType, fileUrl });
    switch (fileObject?.type) {
      case IMAGE_MIMETYPE:
        return <ImageAppletModalPreview src={fileUrl} alt={title} />;
      case VIDEO_MIMETYPE:
        return <VideoAppletModalPreview src={fileUrl} controls autoPlay />;
      case PDF_MIMETYPE:
        return (
          <PdfPreview file={{ uri: fileUrl, type: 'string', name: 'string' }} />
        );
      case AUDIO_MIMETYPE:
        return <audio src={fileUrl} controls />;
      case TEXT_MIMETYPE:
        return (
          <TxtPreview file={{ uri: fileUrl, type: 'string', name: 'string' }} />
        );
      default:
        return null;
    }
  }, [fileType, fileUrl, title]);

  return Preview;
};

export default FileUploadPreview;
