import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  deleteArtifactSelected as deleteArtifactSelectedService,
  getPlaylist as getPlaylistService,
  getPlaylistArtifactSelected as getPlaylistArtifactSelectedService,
  setArtifactSelected as setArtifactSelectedService,
  submitCompletedArtifact as submitCompletedArtifactService,
} from './services';
import {
  Artifact,
  PLAYLIST_FILTERS,
  SubmitCompletedArtifactPayload,
} from './typings';
import { convertToQueryString } from 'helpers/helperFunctions';
import { selectActiveQuestWeekIds } from 'modules/loading-screen/quests/selectors';

const GET_PLAYLIST = 'GET_PLAYLIST';

export const getPlaylist = createAsyncThunk<
  Record<string, Record<string, Artifact>>,
  void,
  { state: any }
>(GET_PLAYLIST, (_, { getState }) => {
  const state = getState();
  const availableWeeksIds = selectActiveQuestWeekIds(state);
  return getPlaylistService({
    weekIds: convertToQueryString('weeksIds', availableWeeksIds),
  });
});

const UPDATE_PLAYLIST_FILTER = 'UPDATE_PLAYLIST_FILTER';
export const updatePlaylistFilter = createAction<PLAYLIST_FILTERS>(
  UPDATE_PLAYLIST_FILTER,
);

const SUBMIT_COMPLETED_ARTIFACT = 'SUBMIT_COMPLETED_ARTIFACT';
export const submitCompletedArtifact = createAsyncThunk<
  Record<string, Record<string, Artifact>>,
  SubmitCompletedArtifactPayload
>(SUBMIT_COMPLETED_ARTIFACT, submitCompletedArtifactService);

const SET_SELECTED_ARTIFACT = 'SET_SELECTED_ARTIFACT';
export const setSelectedArtifact = createAsyncThunk(
  SET_SELECTED_ARTIFACT,
  setArtifactSelectedService,
);

const DELETE_SELECTED_ARTIFACT = 'DELETE_SELECTED_ARTIFACT';
export const deleteSelectedArtifact = createAsyncThunk(
  DELETE_SELECTED_ARTIFACT,
  deleteArtifactSelectedService,
);

const MARK_ARTIFACT_COMPLETED = 'MARK_ARTIFACT_COMPLETED';
export const markArtifactCompleted =
  createAction<SubmitCompletedArtifactPayload>(MARK_ARTIFACT_COMPLETED);

const UPDATE_AVAILABLE_STATUS = 'UPDATE_AVAILABLE_STATUS';
export const updateAvailableList = createAction<boolean>(
  UPDATE_AVAILABLE_STATUS,
);

const UPDATE_PLAYLIST_SELECTED = 'UPDATE_PLAYLIST_SELECTED';
export const updatePlaylistSelected = createAction<string[]>(
  UPDATE_PLAYLIST_SELECTED,
);

const GET_PLAYLIST_SELECTED = 'GET_PLAYLIST_SELECTED';
export const getPlaylistSelected = createAsyncThunk(
  GET_PLAYLIST_SELECTED,
  getPlaylistArtifactSelectedService,
);
