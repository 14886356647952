import React from 'react';

import { ChallengeApplet } from 'models/ChallengeApplet';
import { QuestApplet } from 'models/QuestApplet';
import { GroupAppletContainer } from '../components';
import { CelebrateAppletTitle } from 'modules/challenge/components';
import SubmittedAppletsSwitch from 'modules/challenge/celebrate/SubmittedAppletsSwitch';

type GroupAppletProps = {
  applet: ChallengeApplet | QuestApplet;
};

const GroupApplet = ({ applet }: GroupAppletProps) => {
  return applet.applets ? (
    <GroupAppletContainer>
      <CelebrateAppletTitle>{applet.title}</CelebrateAppletTitle>
      {(applet.applets as ChallengeApplet[]).map((app) => (
        <SubmittedAppletsSwitch
          key={app.appletId}
          applet={app as ChallengeApplet}
          withoutTitle
        />
      ))}
    </GroupAppletContainer>
  ) : null;
};

export default GroupApplet;
