import * as yup from 'yup';
import LocalizedStrings from '../localization';

export const passwordField = yup
  .string()
  .min(8, LocalizedStrings.passwordMinLengthMessage)
  .required(LocalizedStrings.passwordRequiredMessage);

export const emailField = yup
  .string()
  .email(LocalizedStrings.emailValidMessage)
  .required(LocalizedStrings.emailRequiredMessage);

export const shareByEmailSchema = yup.object({
  receiver: yup
    .string()
    .required(LocalizedStrings.shareAppletToRequired)
    .email(LocalizedStrings.shareAppletToEmail),
  subject: yup.string().required(LocalizedStrings.shareAppletSubjectRequired),
  message: yup.string(),
});
