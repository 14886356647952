import React from 'react';
import useContentfulRenderer from 'hooks/useContentfulRenderer';
import { IQuizSection } from '../../../../models/contentful';

const QuizSectionDescriptionRenderer = ({ item }: { item: IQuizSection }) => {
  const description = useContentfulRenderer(item?.fields?.description);

  return <div>{description}</div>;
};

export default QuizSectionDescriptionRenderer;
