import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'redux/typings';

const selectAppletsState = (state: RootState) => state.contentful.applets;

export const selectAppletsContentful = createSelector(
  selectAppletsState,
  (appletsState) => appletsState.applets,
);

export const selectAppletContentfulById = (appletId: string) =>
  createSelector(
    selectAppletsContentful,
    (appletsContentful) => appletsContentful[appletId],
  );

export const selectAppletsContentfulLastUpdatedAt = createSelector(
  selectAppletsState,
  (appletsState) => appletsState.lastUpdatedAt,
);
