import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { selectChallengeAppletByContentfulId } from 'modules/loading-screen/contents/selectors';
import {
  saveRichTextApplet,
  updateBranchDescription,
  updateBranchFile,
  updateMadlibAnswer,
  updateQuizQuestionValue,
  uploadResponse,
} from 'modules/applet-upload/actions';
import { selectAppletsContentful } from 'modules/loading-screen/contentful/applets/selectors';
import { selectAllAppletsMap, selectLastAppletModifiedById } from 'modules/applets/selectors';
import { getRichTextAppletDefaultHtml } from 'helpers/AppletHelpers';
import useActionStatus from './useActionStatus';
import { getQuestApplets } from 'modules/applets/actions';
import { QuizQuestionAnswerType } from '../models/QuizQuestionAnswerType';
import { selectFirstName, selectLastName } from 'modules/auth/selectors';

const useExistingApplet = (appletId: string) => {
  const dispatch = useDispatch();
  const appletsContentfulMap = useSelector(selectAppletsContentful);
  const appletsUser = useSelector(selectAllAppletsMap);
  const challengeApplet = useSelector(
    selectChallengeAppletByContentfulId(appletId),
  );

  const firstName = useSelector(selectFirstName);
  const lastName = useSelector(selectLastName);
  const [isPendingQuestApplets, wasFulfilledQuestsApplets] = useActionStatus(getQuestApplets);
  const madlibSubmitedQuestApplet = useSelector(selectLastAppletModifiedById(appletId))

  useEffect(() => {
    const appletContentful = appletsContentfulMap[appletId];
    if (challengeApplet) {
      const {
        id,
        type,
        contentfulId,
        fileUrl,
        fileType,
        files,
        quizQuestions,
        richText,
        text,
        options,
        madlibAnswers,
      } = challengeApplet;
      switch (type) {
        case 'upload-file':
        case 'upload-image':
        case 'upload-video':
          dispatch(
            uploadResponse({
              applet: {
                id,
                appletType: type,
                appletId: contentfulId,
                file: {
                  type: fileType!,
                  uri: fileUrl!,
                  mime: fileType,
                  name: id,
                },
                fileUploaded: true,
                untouched: true,
              },
            }),
          );
          break;
        case 'rich-text-entry':
          dispatch(
            saveRichTextApplet({
              applet: {
                id,
                appletId: contentfulId,
                appletType: type,
                richText: richText ?? '',
                untouched: true,
              },
            }),
          );
          break;
        case 'inline-text':
        case 'random-selection':
          dispatch(
            uploadResponse({
              applet: {
                id,
                appletId: contentfulId,
                appletType: type,
                text,
                options,
                untouched: true,
              },
            }),
          );
          break;
        case 'quiz':
          quizQuestions?.forEach((qst) => {
            const {
              quizContentfulId,
              quizQuestionContentfulId,
              quizSectionContentfulId,
              value,
              answer,
              answerType,
              madlibAnswers: questionMadlibAnswers,
              madlibAppletContentfulId,
            } = qst;
            if (
              answerType === QuizQuestionAnswerType.MADLIB &&
              questionMadlibAnswers &&
              questionMadlibAnswers.length > 0
            ) {
              for (const madlibAns of questionMadlibAnswers) {
                dispatch(
                  updateQuizQuestionValue({
                    applet: {
                      id,
                      appletId: contentfulId,
                      quizContentfulId: quizContentfulId || '',
                      quizSectionContentfulId: quizSectionContentfulId || '',
                      contentfulId: quizQuestionContentfulId,
                      value,
                      answer: undefined,
                      untouched: true,
                      answerType: QuizQuestionAnswerType.MADLIB,
                      madlibAppletContentfulId,
                      madlibInputValue: madlibAns.answer,
                      madlibInputContentfulId: madlibAns.contentfulId,
                    },
                  }),
                );
              }
            } else {
              dispatch(
                updateQuizQuestionValue({
                  applet: {
                    id,
                    appletId: contentfulId,
                    quizContentfulId: quizContentfulId || '',
                    quizSectionContentfulId: quizSectionContentfulId || '',
                    contentfulId: quizQuestionContentfulId,
                    value,
                    answer,
                    untouched: true,
                    answerType,
                    madlibAnswers: undefined,
                    madlibAppletContentfulId: undefined,
                  },
                }),
              );
            }
          });
          break;
        case 'decision-tree':
          files?.forEach((file) => {
            const {
              contentfulId: fileId,
              description,
              fileOrder,
              fileType: branchFileType,
              fileUrl: branchFileUrl,
            } = file;
            dispatch(
              updateBranchDescription({
                applet: {
                  id,
                  appletId: contentfulId,
                  description,
                  fileId,
                  fileOrder,
                  untouched: true,
                },
              }),
            );
            dispatch(
              updateBranchFile({
                applet: {
                  id,
                  appletId: contentfulId,
                  file: {
                    uri: branchFileUrl,
                    type: branchFileType,
                    mime: branchFileType,
                    name: contentfulId,
                  },
                  fileId,
                  fileOrder,
                  fileUploaded: true,
                  untouched: true,
                },
              }),
            );
          });
          break;
        case 'madlib':
          madlibAnswers?.forEach((answer) => {
            dispatch(
              updateMadlibAnswer({
                applet: {
                  id,
                  appletId: contentfulId,
                  inputId: answer.contentfulId,
                  value: answer.answer,
                  untouched: true,
                },
              }),
            );
          });
          break;
      }
    } else {
      if (
        appletContentful?.type === 'rich-text-entry' &&
        !isPendingQuestApplets &&
        wasFulfilledQuestsApplets
      ) {
        dispatch(
          saveRichTextApplet({
            applet: {
              appletId,
              appletType: 'rich-text-entry',
              richText: getRichTextAppletDefaultHtml(
                appletContentful,
                appletsContentfulMap,
                appletsUser,
                firstName,
                lastName,
              ),
              untouched: true,
            },
          }),
        );
      } else if (madlibSubmitedQuestApplet && appletContentful?.type === 'madlib') {
        madlibSubmitedQuestApplet.madlibAnswers?.forEach((answer) => {
          dispatch(
            updateMadlibAnswer({
              applet: {
                appletId: madlibSubmitedQuestApplet.contentfulId,
                inputId: answer.contentfulId,
                value: answer.answer,
                untouched: true,
              },
            }),
          );
        });
      }
    }
  }, [
    dispatch,
    challengeApplet,
    appletsContentfulMap,
    appletId,
    appletsUser,
    isPendingQuestApplets,
    wasFulfilledQuestsApplets,
    firstName,
    lastName,
    madlibSubmitedQuestApplet
  ]);

  return challengeApplet?.id;
};

export default useExistingApplet;
