import React from 'react';

import theme from 'utils/theme';

type FilterIconProps = {
  width?: string;
  height?: string;
};

const FilterIcon = ({
  width = theme.typography.large24,
  height = theme.typography.large24,
}: FilterIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 17 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.9999 16.88C12.0399 17.18 11.9399 17.5 11.7099 17.71C11.6174 17.8027 11.5075 17.8762 11.3865 17.9264C11.2655 17.9766 11.1359 18.0024 11.0049 18.0024C10.8739 18.0024 10.7442 17.9766 10.6233 17.9264C10.5023 17.8762 10.3924 17.8027 10.2999 17.71L6.28989 13.7C6.18082 13.5934 6.09789 13.463 6.04759 13.319C5.99728 13.175 5.98095 13.0213 5.99989 12.87V7.75L1.20989 1.62C1.0475 1.41153 0.974221 1.14726 1.00607 0.88493C1.03793 0.622602 1.17232 0.383546 1.37989 0.22C1.56989 0.08 1.77989 0 1.99989 0H15.9999C16.2199 0 16.4299 0.08 16.6199 0.22C16.8275 0.383546 16.9618 0.622602 16.9937 0.88493C17.0256 1.14726 16.9523 1.41153 16.7899 1.62L11.9999 7.75V16.88ZM4.03989 2L7.99989 7.06V12.58L9.99989 14.58V7.05L13.9599 2H4.03989Z"
      fill="white"
    />
  </svg>
);
export default FilterIcon;
