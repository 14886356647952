import React from 'react';
import theme from 'utils/theme';
import {
  VictoryBar,
  VictoryChart,
  VictoryStack,
  VictoryPortal,
  VictoryLabel,
  VictoryAxis,
} from 'victory';

type ChartFields = {
  title: string;
  count: number;
};

type QuizChartsProps = {
  fields: ChartFields[];
};

const QuizChart = ({ fields }: QuizChartsProps) => {
  return (
    <VictoryChart maxDomain={{ y: 100 }} domainPadding={40}>
      <VictoryAxis crossAxis />
      <VictoryAxis
        style={{
          axis: { stroke: 'transparent' },
          grid: { stroke: theme.colors.grey[80] },
        }}
        dependentAxis
      />
      <VictoryStack
        colorScale={theme.colors.chartBars}
        style={{
          data: { width: 40 },
          labels: { padding: -30 },
        }}
        labelComponent={
          <VictoryPortal>
            <VictoryLabel style={{ fill: theme.colors.white[100] }} />
          </VictoryPortal>
        }>
        {fields.map((item: ChartFields) => {
          return (
            <VictoryBar
              key={item.title}
              data={[
                {
                  x: item.title,
                  y: item.count || 0,
                  label: item.count || '-',
                },
              ]}
            />
          );
        })}
      </VictoryStack>
    </VictoryChart>
  );
};

export default QuizChart;
