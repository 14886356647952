import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  ProfileSectionTitle,
  ProfileSectionPanel,
  ProfileSection,
} from '../components';
import AchievementsList from './achievements-list';
import localizedStrings from 'localization';
import { AppDispatch } from 'redux/typings';
import { getAchievements } from '../actions';

const Achievements = () => {
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(getAchievements());
  }, [dispatch]);

  return (
    <ProfileSection>
      <ProfileSectionTitle>{localizedStrings.challenges}</ProfileSectionTitle>
      <ProfileSectionPanel>
        <AchievementsList />
      </ProfileSectionPanel>
    </ProfileSection>
  );
};

export default Achievements;
