import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';

import {
  Container,
  ContainerLeft,
  ContainerRight,
  FormContainer,
  FormContent,
  FormTitle,
  Logo,
  AuthErrorMessage,
  FormSubtitle,
  FormLink,
  FormMessage,
} from 'modules/auth/components';
import logo from 'assets/images/Logo.webp';
import localizedStrings from 'localization';
import { emailVerificationSchema } from 'modules/auth/validationSchemas';
import { AppDispatch, RootState } from 'redux/typings';
import {
  displayNameSelector,
  emailSelector,
  selectDisplayName,
  selectEmail,
} from 'modules/auth/selectors';
import { clearSignup, resendCode, verifyCode } from 'modules/auth/actions';
import { LOADING_SCREEN } from 'routes';
import { EmailPayload, VerifyPayload } from 'modules/auth/typings';
import ButtonPrimary from 'components/button-primary';
import InputField from 'components/input-field';
import useActionStatus from 'hooks/useActionStatus';
import useErrorMessage from 'hooks/useErrorMessage';

type dataParamsType = {
  verificationCode: string | null;
  name: string | null;
  email: string | null;
};

const EmailVerification = () => {
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [hasVerified, setHasVerified] = useState(false);
  const [isVerifyingCode, hasVerifiedCode] = useActionStatus(verifyCode);
  const [errorMessage, setErrorMessage] = useErrorMessage();
  const [isResendingCode] = useActionStatus(resendCode);
  const dispatch = useDispatch<AppDispatch>();
  const { search } = useLocation();
  const history = useHistory();

  const signupDisplayName = useSelector<RootState, string>(displayNameSelector);
  const jwtDisplayName = useSelector(selectDisplayName);
  const signupEmail = useSelector<RootState, string>(emailSelector);
  const jwtEmail = useSelector(selectEmail);

  const dataParams: dataParamsType = useMemo(() => {
    const urlParams = new URLSearchParams(search);
    return {
      verificationCode: urlParams.get('verificationCode'),
      name: urlParams.get('name'),
      email: urlParams.get('email'),
    };
  }, [search]);
  const isDataParamsExist = useMemo(
    () =>
      Boolean(dataParams.verificationCode) &&
      Boolean(dataParams.email) &&
      Boolean(dataParams.name),
    [dataParams],
  );

  const onGoBack = useCallback(() => {
    dispatch(clearSignup());
    history.goBack();
  }, [dispatch, history]);

  const onResend = useCallback(() => {
    const email = signupEmail || jwtEmail;
    if (email) {
      const emailPayload: EmailPayload = { email };
      dispatch(resendCode(emailPayload));
    }
  }, [dispatch, signupEmail, jwtEmail]);

  const handleSubmit = useCallback(
    (values: { verificationCode: string }) => {
      setHasSubmitted(true);
      setErrorMessage('');
      const email = signupEmail || jwtEmail || dataParams.email;
      if (email && !hasVerified) {
        setHasVerified(true);
        const verifyPayload: VerifyPayload = {
          email: email.replaceAll(' ', '+'),
          ...values,
        };
        dispatch(verifyCode(verifyPayload));
      }
    },
    [
      setErrorMessage,
      signupEmail,
      jwtEmail,
      dispatch,
      dataParams.email,
      hasVerified,
    ],
  );

  useEffect(() => {
    if (hasSubmitted && hasVerifiedCode) {
      setHasSubmitted(false);
      history.push(LOADING_SCREEN);
    }
  }, [hasSubmitted, hasVerifiedCode, history]);

  useEffect(() => {
    if (isDataParamsExist) {
      handleSubmit({ verificationCode: dataParams.verificationCode! });
    }
  }, [dataParams, handleSubmit, isDataParamsExist]);

  if (isDataParamsExist) {
    return (
      <Container>
        <ContainerLeft>
          <Logo src={logo}></Logo>
        </ContainerLeft>
        <ContainerRight>
          <FormContainer>
            <FormTitle>
              {localizedStrings.codeVerificationHeaderBegin}{' '}
              {signupDisplayName || jwtDisplayName}
              {localizedStrings.codeVerificationHeaderEnd}
            </FormTitle>
            <FormSubtitle>
              {localizedStrings.codeVerificationSubtitle}
            </FormSubtitle>
            <FormSubtitle>{signupEmail || jwtEmail}</FormSubtitle>
            <Formik
              initialValues={{
                verificationCode: dataParams.verificationCode || '',
              }}
              onSubmit={handleSubmit}
              validationSchema={emailVerificationSchema}>
              <FormContent>
                <InputField
                  name="verificationCode"
                  placeholder={localizedStrings.codeVerificationPlaceHolder}
                />
                {errorMessage && (
                  <AuthErrorMessage>{errorMessage}</AuthErrorMessage>
                )}
                <ButtonPrimary
                  type="submit"
                  isLoading={isVerifyingCode || isResendingCode}>
                  {localizedStrings.codeVerificationBtn}
                </ButtonPrimary>
                <FormMessage>
                  {localizedStrings.codeVerificationAcknowledgment1 + ' '}
                  <FormLink onClick={onResend}>
                    {localizedStrings.codeVerificationAcknowledgment2}
                  </FormLink>
                  {' ' + localizedStrings.or + ' '}
                  <FormLink onClick={onGoBack}>
                    {localizedStrings.goBack}
                  </FormLink>
                </FormMessage>
              </FormContent>
            </Formik>
          </FormContainer>
        </ContainerRight>
      </Container>
    );
  }

  return (
    <Container>
      <ContainerLeft>
        <Logo src={logo}></Logo>
      </ContainerLeft>
      <ContainerRight>
        <FormContainer>
          <FormTitle>{localizedStrings.verifyEmailAccount}</FormTitle>
          <FormSubtitle>
            {localizedStrings.sentYouAnEmail(
              signupEmail || jwtEmail || 'your email',
            )}
          </FormSubtitle>
          <Formik
            initialValues={{
              verificationCode: dataParams.verificationCode || '',
            }}
            onSubmit={handleSubmit}
            validationSchema={emailVerificationSchema}>
            <FormContent>
              <FormMessage>
                {localizedStrings.codeVerificationAcknowledgment1 + ' '}
                <FormLink onClick={onResend}>
                  {localizedStrings.codeVerificationAcknowledgment2}
                </FormLink>
                {' ' + localizedStrings.or + ' '}
                <FormLink onClick={onGoBack}>
                  {localizedStrings.goBack}
                </FormLink>
              </FormMessage>
            </FormContent>
          </Formik>
        </FormContainer>
      </ContainerRight>
    </Container>
  );
};

export default EmailVerification;
