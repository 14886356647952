import React, { useMemo } from 'react';

import { BlockNodeProps } from 'components/contentful-renders/typings';
import FileUploadApplet from 'components/applets/file-upload';
import InlineTextApplet from 'components/applets/inline-text-applet';
import DecisionTreeApplet from 'components/applets/decision-tree-applet';
import RankApplet from 'components/applets/rank-applet';
import { IAppletFields } from 'models/contentful';
import RandomSelectionAppletChallenge from 'modules/challenge/random-selection-applet-challenge';
import MadlibAppletChallenge from 'modules/challenge/madlib-applet-challenge';
import QuizAppletChallenge from '../../../../../modules/challenge/quiz-applet-challenge/index';
import RichTextAppletChallenge from 'modules/challenge/rich-text-applet-challenge';

interface AppletProps extends Omit<BlockNodeProps, 'children'> {}

const Applet = ({ node }: AppletProps) => {
  const applet = useMemo(() => {
    switch (node?.data.target.fields?.type as IAppletFields['type']) {
      case 'upload-file':
      case 'upload-video':
      case 'upload-image':
        return (
          <FileUploadApplet
            appletId={node.data.target.sys.id}
            appletType={node.data.target.fields.type}
            prompt={node.data.target.fields.uploadPrompt}
            supportedTypes={node.data.target.fields.supportedTypes}
            sendButton={node.data.target.fields.sendButton}
          />
        );
      case 'rich-text-entry':
        return (
          <RichTextAppletChallenge
            key={node.data.target.sys.id}
            appletId={node.data.target.sys.id}
          />
        );
      case 'inline-text':
        return (
          <InlineTextApplet
            appletId={node.data.target.sys.id}
            prompt={node.data.target.fields.uploadPrompt}
          />
        );
      case 'quiz':
        return <QuizAppletChallenge appletId={node.data.target.sys.id} />;
      case 'decision-tree':
        return <DecisionTreeApplet appletId={node.data.target.sys.id} />;
      case 'rank':
        return (
          <RankApplet
            appletId={node.data.target.sys.id}
            optionsContentful={node.data.target.fields.options}
          />
        );
      case 'madlib':
        return <MadlibAppletChallenge appletId={node.data.target.sys.id} />;
      case 'random-selection':
        return (
          <RandomSelectionAppletChallenge appletId={node.data.target.sys.id} />
        );
      default:
        return null;
    }
  }, [node]);

  return applet;
};

export default Applet;
