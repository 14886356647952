import React from 'react';

import { QuestApplet } from 'models/QuestApplet';
import MadlibApplet from 'components/applets/madlib';
import useContentfulRenderer from 'hooks/useContentfulRenderer';

type MadlibAppletProfileProps = {
  applet: QuestApplet;
};

const MadlibAppletProfile = ({ applet }: MadlibAppletProfileProps) => {
  const renderedMadlibSentence = useContentfulRenderer(applet.madlibSentence, {
    madlibAnswers: applet.madlibAnswers,
  });

  return (
    <MadlibApplet
      madlibSentence={renderedMadlibSentence}
      disabled
      key={applet.id}
    />
  );
};

export default MadlibAppletProfile;
