import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';

import {
  Container,
  ContainerLeft,
  ContainerRight,
  FormContainer,
  FormContent,
  FormTitle,
  Logo,
  AuthErrorMessage,
  ButtonSecondary,
} from 'modules/auth/components';
import { AppDispatch, RootState } from 'redux/typings';
import logo from 'assets/images/Logo.webp';
import localizedStrings from 'localization';
import { resetPasswordSchema } from 'modules/auth/validationSchemas';
import * as StatusSelectors from 'modules/status/selectors';
import { clearSignup, resetPassword } from 'modules/auth/actions';
import { AUTH } from 'routes';
import { AUTH_LOGIN } from 'modules/auth/routes';
import { ResetPasswordPayload } from 'modules/auth/typings';
import { emailSelector } from 'modules/auth/selectors';
import ButtonPrimary from 'components/button-primary';
import InputField from 'components/input-field';
import useErrorMessage from 'hooks/useErrorMessage';

const initialValues = {
  verificationCode: '',
  password: '',
  repeatPassword: '',
  email: '',
};

const ResetPassword = () => {
  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();

  const [errorMessage, setErrorMessage] = useErrorMessage();

  const email = useSelector(emailSelector);
  const isLoading = useSelector((state: RootState) =>
    StatusSelectors.isPendingSelector(state, resetPassword.typePrefix),
  );

  const isFulfilled = useSelector((state: RootState) =>
    StatusSelectors.wasFulfilledSelector(state, resetPassword.typePrefix),
  );

  useEffect(() => {
    if (isFulfilled) {
      history.push(AUTH + AUTH_LOGIN);
    }
  }, [history, isFulfilled]);

  const goToLogin = useCallback(() => {
    dispatch(clearSignup());
    history.push(AUTH + AUTH_LOGIN);
  }, [dispatch, history]);

  const handleSubmit = useCallback(
    (values: typeof initialValues) => {
      setErrorMessage('');
      if (email) {
        const resetPasswordPayload: ResetPasswordPayload = {
          ...values,
          email,
        };
        dispatch(resetPassword(resetPasswordPayload));
      }
    },
    [dispatch, email, setErrorMessage],
  );

  return (
    <Container>
      <ContainerLeft>
        <Logo src={logo}></Logo>
      </ContainerLeft>
      <ContainerRight>
        <FormContainer>
          <FormTitle>{localizedStrings.resetPasswordHeader}</FormTitle>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={resetPasswordSchema}>
            <FormContent>
              <InputField
                name="verificationCode"
                placeholder={localizedStrings.resetPasswordPlaceHolder1}
              />
              <InputField
                type="password"
                name="password"
                placeholder={localizedStrings.newPassword}
              />
              <InputField
                type="password"
                name="repeatPassword"
                placeholder={localizedStrings.repeatPassword}
              />
              {errorMessage && !isLoading && (
                <AuthErrorMessage>{errorMessage}</AuthErrorMessage>
              )}
              <ButtonPrimary type="submit" isLoading={isLoading}>
                {localizedStrings.updatePassword}
              </ButtonPrimary>
              <ButtonSecondary type="button" onClick={goToLogin}>
                {localizedStrings.resetPasswordCancelBtn}
              </ButtonSecondary>
            </FormContent>
          </Formik>
        </FormContainer>
      </ContainerRight>
    </Container>
  );
};

export default ResetPassword;
