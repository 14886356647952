import localizedStrings from 'localization/en';
import {
  EmptyPlaylistCardsContainer,
  EmptyPlaylistText,
  EmptyPlaylistTitle,
  MessageContainer,
} from './components';

export const EmptyPlaylistCards = () => {
  return (
    <EmptyPlaylistCardsContainer>
      <MessageContainer>
        <EmptyPlaylistTitle>
          {localizedStrings.titleEmptyPlaylist}
        </EmptyPlaylistTitle>
        <EmptyPlaylistText>
          {localizedStrings.textEmptyPlaylist}
        </EmptyPlaylistText>
      </MessageContainer>
    </EmptyPlaylistCardsContainer>
  );
};
