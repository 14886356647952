import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

import localizedStrings from 'localization';
import {
  FieldErrorMessage,
  PublishShowcaseAppletsContainer,
  PublishShowcaseDescription,
} from 'modules/profile/components';
import { selectAppletsImageToAddShowcase, selectExistsAppletImagesToAddShowcase } from 'modules/applets/selectors';
import { QuestApplet } from 'models/QuestApplet';
import AppletPreview from 'modules/profile/portfolio/quest-applets/AppletPreview';
import { StepProps } from '../typings';

const SelectBackgroundImage = ({
  values,
  setFieldValue,
  setNextButtonText,
}: StepProps) => {
  const existsAppletImages = useSelector(selectExistsAppletImagesToAddShowcase);
  const applets = useSelector(selectAppletsImageToAddShowcase);

  const handleAppletSelect = useCallback(
    (applet: QuestApplet) => {
      setFieldValue('backgroundApplet', applet.id);
    },
    [setFieldValue],
  );

  useEffect(() => {
    if (values.backgroundApplet !== '') {
      setNextButtonText(localizedStrings.continue);
    } else {
      setNextButtonText(localizedStrings.selectAnArtifact);
    }
  }, [setNextButtonText, values.backgroundApplet]);

  return (
    <>
      <PublishShowcaseDescription>
        {localizedStrings.selectShowcaseBackground}
      </PublishShowcaseDescription>
      {!existsAppletImages ? (
        <FieldErrorMessage>
          {localizedStrings.showcaseImageError}
        </FieldErrorMessage>
      ) : (
        <PublishShowcaseAppletsContainer>
          {applets.map((applet) => (
            <AppletPreview
              key={applet.id}
              applet={applet}
              onAppletClick={handleAppletSelect}
              showTitle={true}
              height="168px"
              width="178px"
              selected={values.backgroundApplet === applet.id}
            />
          ))}
        </PublishShowcaseAppletsContainer>
      )}
    </>
  );
};

export default SelectBackgroundImage;
