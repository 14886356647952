import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import localizedStrings from 'localization';
import { CHALLENGE } from 'routes';
import { selectActiveQuestWeekIds, selectHasErrorsContentInActiveQuest } from 'modules/loading-screen/quests/selectors';
import { selectSelectedWeekId } from 'modules/loading-screen/weeks/selectors';
import { selectWeek } from 'modules/loading-screen/weeks/actions';
import useSectionsList from '../../../hooks/useSectionList';
import WeeklyOverviewItem from './WeeklyOverviewItem';
import {
  WeeklyTabDateContainer,
  WeeklyTabDay,
  WeeklyTabMonth,
  WeeklyTabSectionContainer,
  WeeklyTabTitle,
  WeeklyTabTitleContainer,
  WeeklyTabWeekContainer,
  WeeklyTabContainer,
  WeeklyChallengesContainer,
  WeeklyEmptyChallengeCard,
  WeeklyDateArrowContainer,
} from 'modules/overview/components';
import DateArrow from 'modules/overview/weekly-tab/DateArrow';
import { selectWeeksHash } from '../../../modules/loading-screen/weeks/selectors';
import { Week } from 'models/Week';
import { ChallengeInformation } from 'models/ChallengeInformation';
import { format } from 'date-fns';
import { selectOverviewFilter } from '../selectors';
import { FILTER_OPTIONS } from '../typings.d';

const WeeklyTabChallenges = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const activeQuestWeekIds = useSelector(selectActiveQuestWeekIds);
  const selectedWeekId = useSelector(selectSelectedWeekId);
  const weeksHash = useSelector(selectWeeksHash);
  const filter = useSelector(selectOverviewFilter);
  const questHasContentErrors = useSelector(selectHasErrorsContentInActiveQuest)

  const sections = useSectionsList();

  const handleChallengeClick = useCallback(
    (item: ChallengeInformation, weekContentfulId?: string) => () => {
      const weeksUserMapByContentfulId = Object.values(weeksHash).reduce(
        (acc, week) => ({
          ...acc,
          [week.contentfulId]: week,
        }),
        {} as Record<string, Week>,
      );

      if (weekContentfulId) {
        const weekId = weeksUserMapByContentfulId[weekContentfulId];
        if (
          weekId &&
          activeQuestWeekIds.includes(weekId.id) &&
          item.contentUser
        ) {
          if (selectedWeekId !== weekId.id) {
            dispatch(selectWeek(weekId.id));
          }
          history.push(CHALLENGE + '/' + item.contentUser.id + '/');
          dispatch(selectWeek(weekId.id));
        }
      }
    },
    [weeksHash, activeQuestWeekIds, dispatch, selectedWeekId, history],
  );

  const renderChallenges = useCallback(
    (weeklyItems: ChallengeInformation[], contentfulId: string) => {
      let filteredChallenges = weeklyItems;
      switch (filter) {
        case FILTER_OPTIONS.ALL:
          filteredChallenges = weeklyItems;
          break;
        case FILTER_OPTIONS.COMPLETED:
          filteredChallenges = weeklyItems.filter((item) => item.completed);
          break;
        case FILTER_OPTIONS.TODO:
          filteredChallenges = weeklyItems.filter(
            (item) => !item.completed && !item.isLate,
          );
          break;
        case FILTER_OPTIONS.LATE:
          filteredChallenges = weeklyItems.filter((item) => item.isLate);
          break;
        default:
          filteredChallenges = weeklyItems;
      }

      return (
        <WeeklyChallengesContainer>
          {filteredChallenges.length > 0 ? (
            filteredChallenges.map((item) => {
              if (questHasContentErrors) {
                const isNavigationEnabled = !item.completed && !item.contentUser
                if (!isNavigationEnabled) {
                  return (
                    <WeeklyOverviewItem
                      item={item}
                      key={item.title}
                      onClick={handleChallengeClick(item, contentfulId)}
                    />
                  );
                } else {
                  return null
                }
              } else {
                return (
                  <WeeklyOverviewItem
                    item={item}
                    key={item.title}
                    onClick={handleChallengeClick(item, contentfulId)}
                  />
                );
              }

            })
          ) : (
            <WeeklyEmptyChallengeCard>
              {localizedStrings.noChalenges}
            </WeeklyEmptyChallengeCard>
          )}
        </WeeklyChallengesContainer>
      );
    },
    [handleChallengeClick, filter, questHasContentErrors],
  );

  const renderItems = useMemo(
    () =>
      sections?.map((value) => {
        return (
          <div key={value.weekContentfulId}>
            <WeeklyTabTitleContainer>
              <WeeklyTabTitle>{value.title}</WeeklyTabTitle>
            </WeeklyTabTitleContainer>
            <WeeklyTabSectionContainer>
              <WeeklyTabWeekContainer>
                <WeeklyTabDateContainer>
                  <WeeklyTabMonth>
                    {new Date(value.startingDate).getUTCDate()}
                  </WeeklyTabMonth>
                  <WeeklyTabDay>
                    {format(new Date(value.startingDate), 'MMMM')?.substring(
                      0,
                      3,
                    )}
                  </WeeklyTabDay>
                </WeeklyTabDateContainer>
                <WeeklyDateArrowContainer>
                  <DateArrow />
                </WeeklyDateArrowContainer>
                <WeeklyTabDateContainer>
                  <WeeklyTabMonth>
                    {new Date(value.endingDate).getUTCDate()}
                  </WeeklyTabMonth>
                  <WeeklyTabDay>
                    {format(new Date(value.endingDate), 'MMMM')?.substring(
                      0,
                      3,
                    )}
                  </WeeklyTabDay>
                </WeeklyTabDateContainer>
              </WeeklyTabWeekContainer>
              {renderChallenges(value.data, value.weekContentfulId)}
            </WeeklyTabSectionContainer>
          </div>
        );
      }),
    [renderChallenges, sections],
  );

  return <WeeklyTabContainer>{renderItems}</WeeklyTabContainer>;
};

export default WeeklyTabChallenges;
