import { createSelector } from '@reduxjs/toolkit';
import { selectWeeksOnboardings } from 'modules/loading-screen/contentful/weeks-onboardings/selectors';
import { selectWeeksContentful } from 'modules/loading-screen/contentful/weeks/selectors';
import {
  selectAvailableWeeks,
  selectCompletedWeek,
  selectFirstWeek,
  selectWeeksHash,
} from 'modules/loading-screen/weeks/selectors';
import { RootState } from 'redux/typings';

const selectAppOnboardings = (state: RootState) => state.appOnboardings;

export const selectAppOnboardingsList = createSelector(
  selectAppOnboardings,
  (onboardings) => onboardings.appOnboarding ?? [],
);

export const selectAppOnboardingSeen = createSelector(
  selectAppOnboardings,
  (onboardings) => onboardings.appOnboardingSeen,
);

export const selectQuestOnboardingSeen = (questId?: string) =>
  createSelector(selectAppOnboardings, (onboardings) =>
    questId ? onboardings.questOnboardingSeen[questId] : false,
  );

export const selectQuestOffboardingSeen = (questId?: string) =>
  createSelector(selectAppOnboardings, (onboardings) =>
    questId ? onboardings.questOffboardingSeen?.[questId] : false,
  );

export const selectWeekOnboardingSeen = (weekId?: string) =>
  createSelector(selectAppOnboardings, (onboardings) =>
    weekId ? onboardings.weekOnboardingSeen[weekId] : false,
  );

export const selectNewOnboardingWeekId = createSelector(
  selectAppOnboardings,
  (onboardingsState) => onboardingsState.newOnboardingWeekId,
);

export const selectNewWeekForOnboarding = createSelector(
  selectAppOnboardings,
  selectFirstWeek,
  selectAvailableWeeks,
  selectCompletedWeek,
  selectWeeksContentful,
  (
    onboardingsState,
    firstWeek,
    availableWeeks,
    completedWeek,
    weeksContentful,
  ) => {
    if (firstWeek && !onboardingsState.weekOnboardingSeen[firstWeek.id]) {
      return firstWeek;
    }

    if (completedWeek) {
      const completedWeekContentful =
        weeksContentful[completedWeek.contentfulId];
      if (completedWeekContentful) {
        const completedWeekNumber = completedWeekContentful.weekNumber;
        const nextWeek = availableWeeks.find(
          (week) =>
            weeksContentful[week.contentfulId]?.weekNumber ===
            completedWeekNumber + 1,
        );
        if (nextWeek && !onboardingsState.weekOnboardingSeen[nextWeek.id]) {
          return nextWeek;
        }
      }

      return undefined;
    }
  },
);

export const selectNewOnboardings = createSelector(
  selectNewOnboardingWeekId,
  selectWeeksHash,
  selectWeeksOnboardings,
  (nextWeekId, weeksHash, weeksOnboardings) =>
    weeksHash[nextWeekId || '']?.onboardingIds
      ?.map((onboardingId) => weeksOnboardings[onboardingId])
      .sort((onboarding) => onboarding.number),
);

export const selectComeFromChallenge = createSelector(
  selectAppOnboardings,
  (onboardings) => onboardings.fromChallenge ?? false,
);


// ACTIVE CAMPAIGN
export const selectActiveCampaignUserCreated = createSelector(
  selectAppOnboardings,
  (state) => state.activeCampaignContactCreated || false,
);

export const selectContactFilteredActiveCampaign = createSelector(
  selectAppOnboardings,
  (state) => state?.contactFilteredResponse?.contacts[0]
);

export const selectContactActiveCampaignrCreated = createSelector(
  selectAppOnboardings,
  (state) => state?.contactActiveCampaign
);

export const selectIsNewContactActiveCampaign = createSelector(
  selectAppOnboardings,
  (state) => state?.contactNewActiveCampaing
);

export const selectContactActiveCampaignId = createSelector(
  selectContactActiveCampaignrCreated,
  selectContactFilteredActiveCampaign,
  (state, contactFiltered) => state?.contact.id || contactFiltered?.id
);
