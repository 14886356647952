import React, { useMemo } from 'react';

import { QuestApplet } from 'models/QuestApplet';
import { IMAGE_MIMETYPE, VIDEO_MIMETYPE } from 'helpers/FileHelpers';
import useAppletPreviewSwitch from 'hooks/useAppletPreviewSwitch';

type GroupAppletPreviewProps = {
  applet: QuestApplet;
};

const GroupAppletPreview = ({ applet }: GroupAppletPreviewProps) => {
  const itemForPreview = useMemo(() => {
    const { applets } = applet;
    let previewItemIndex = 0;
    if (applets) {
      let imageAppletIndex = -1;
      let videoAppletIndex = -1;

      const cycleBoundary = applets?.length ?? 0;
      for (let idx = 0; idx < cycleBoundary; idx++) {
        const app = applets[idx];
        switch (app.appletType) {
          case 'upload-file':
          case 'upload-image':
          case 'upload-video':
            if (app.fileType === IMAGE_MIMETYPE && imageAppletIndex === -1) {
              imageAppletIndex = idx;
            }
            if (app.fileType === VIDEO_MIMETYPE && videoAppletIndex === -1) {
              videoAppletIndex = idx;
            }
            break;
        }
      }

      if (imageAppletIndex !== -1) {
        previewItemIndex = imageAppletIndex;
      }

      if (videoAppletIndex !== -1 && imageAppletIndex === -1) {
        previewItemIndex = videoAppletIndex;
      }
    }
    return applets?.[previewItemIndex];
  }, [applet]);

  const preview = useAppletPreviewSwitch(itemForPreview);

  return <>{preview}</>;
};

export default GroupAppletPreview;
