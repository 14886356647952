import React from 'react';
import { OptionsContainer } from '../components';

type QuestionOptionsProps = {
  questionOptions: React.ReactNode;
};

const QuestionOptions = ({ questionOptions }: QuestionOptionsProps) => {
  return <OptionsContainer>{questionOptions}</OptionsContainer>;
};

export default QuestionOptions;
