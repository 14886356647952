import { Content } from 'models/Content';
import { baseService } from 'modules/baseService';
import HttpClient from 'services/HttpClient';
import {
  CompleteStepServicePayload,
  LinkToSharePayload,
  SharePostPayload,
  SubmitPostPayload,
  SubmitPostResponse,
} from './typings';

export const getContentById = baseService<string, Content>((contentId) =>
  HttpClient.get(`/challenges/${contentId}`),
);

export const submitPost = baseService<SubmitPostPayload, SubmitPostResponse>(
  ({ challengeId, submission }) =>
    HttpClient.post(`/challenges/${challengeId}/posts`, submission, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data; charset=UTF-8',
      },
    }),
);

export const sharePost = baseService<SharePostPayload, void>(
  ({ postId, ...body }) => HttpClient.post(`/posts/${postId}/share`, body),
);

export const getLinkToSharePost = baseService<LinkToSharePayload, void>(
  ({ postId, userId }) => HttpClient.post(`/posts/${postId}/link-shared`, { userId })
);


export const completeStep = baseService<CompleteStepServicePayload, Content>(
  ({ challengeId, stepId, applets, platform }) =>
    HttpClient.patch(`/challenges/${challengeId}/steps/${stepId}/completed`, {
      applets,
      platform,
    }),
);
