import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { ShowCaseImagesContainer } from '../ShowcaseOverviewComponents';
import { selectApplets } from 'modules/showcase/selectors';
import { QuestApplet } from 'models/QuestApplet';
import AppletPreview from 'modules/profile/portfolio/quest-applets/AppletPreview';
import { buildQuestAppletsList } from 'helpers/AppletHelpers';

type ShowcaseGridProps = {
  onAppletClick: (applet: QuestApplet) => void;
};

const ShowcaseGrid = ({ onAppletClick }: ShowcaseGridProps) => {
  const applets = useSelector(selectApplets);
  const groupedApplets = useMemo(() => buildQuestAppletsList(applets), [
    applets,
  ]);

  return (
    <ShowCaseImagesContainer>
      {groupedApplets.map((applet) => (
        <AppletPreview
          key={applet.id}
          applet={applet as QuestApplet}
          onAppletClick={onAppletClick}
          height="12rem"
          width="12rem"
          margin="0 0.85rem 1.7rem 0.85rem"
        />
      ))}
    </ShowCaseImagesContainer>
  );
};

export default ShowcaseGrid;
