import * as yup from 'yup';

import localizedStrings from 'localization';

export const inviteSomeoneElseSchema = (isRunningPartner: boolean) =>
  yup.object().shape({
    name: yup
      .string()
      .required(
        isRunningPartner
          ? localizedStrings.runningPartnerNameRequiredMessage
          : localizedStrings.mentorNameRequired,
      ),
    email: yup
      .string()
      .email(localizedStrings.emailValidMessage)
      .required(
        isRunningPartner
          ? localizedStrings.runningPartnerEmailRequiredMessage
          : localizedStrings.mentorEmailRequiredMessage,
      ),
    message: yup.string().required(localizedStrings.messageRequired),
  });
