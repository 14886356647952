import styled from '@emotion/styled';

export const LoadTitle = styled.div`
  ${(props) => `
        border-radius: 6px;
        width: 250px;
        height: 30px; 
        margin-bottom: 10px;
  `}
`;

export const LoadRowContainer = styled.div`
  ${(props) => `
        flex-direction: row;
        display: flex;
        gap:20px;
  `}
`;

export const LoadFlex = styled.div`
  ${(props) => `
        flex: 1;        
  `}
`;

export const LoadCardContainer = styled.div`
${(props) => `
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 8px;
    min-height: 200px;
    height: 100%;
    width: 100%;
    flex: 0 0 327px;
    border-radius: 12px;         
    margin-right: 32px;    
    `}
`;



export const LoadWeekProgressLinesk = styled(LoadCardContainer)`
${(props) => `
        margin: 20px 0;
    `}
`;