import * as yup from 'yup';

import localizedStrings from 'localization';

export const shareShowcaseSchema = yup.object().shape({
  name: yup.string().required(localizedStrings.shareNameValidation),
  email: yup
    .string()
    .email(localizedStrings.emailValidMessage)
    .required(localizedStrings.shareEmailValidation),
  message: yup.string().required(localizedStrings.shareMessageValidation),
});

export const publishShowcaseSchema = [
  yup.object().shape({
    name: yup.string().required(localizedStrings.showcaseNameValidation),
    callToAction: yup
      .string()
      .required(localizedStrings.showcaseCallToActionValidation),
  }),
  yup.object().shape({
    applets: yup.array().of(yup.string()).min(3),
  }),
  yup.object().shape({
    featuredApplets: yup.array().of(yup.string()).min(3),
  }),
  yup.object().shape({
    backgroundApplet: yup.string().required(),
  }),
  yup.object().shape({
    videoApplet: yup.string().required(),
  }),
  yup.object().shape({
    headlineApplet: yup.string().required(),
  }),
];
