import styled from '@emotion/styled';

import { DarkModeProps } from '../contentful-renders/components';

export const ListItem = styled.li<DarkModeProps>`
  margin-bottom: 0.5rem;
  ::before {
    color: ${(props) =>
      props.darkMode
        ? props.theme?.colors.white[100]
        : props.theme?.colors.blue2[100]};
  }
  ::marker {
    color: ${(props) =>
      props.darkMode
        ? props.theme?.colors.white[100]
        : props.theme?.colors.blue2[100]};
  }
`;

export const FirstLevelOrderedListItem = styled(ListItem)`
  list-style-type: decimal;
`;

export const SecondLevelOrderedListItem = styled(ListItem)`
  list-style-type: upper-latin;
`;

export const ThirdLevelOrderedListItem = styled(ListItem)`
  list-style-type: lower-roman;
`;

export const FourthLevelOrderedListItem = styled(ListItem)`
  list-style-type: lower-latin;
`;

export const FifthLevelOrderedListItem = styled(ListItem)`
  list-style-type: lower-latin;
`;

export const SixthLevelOrderedListItem = styled(ListItem)`
  list-style-type: lower-latin;
`;

export const FirstLevelUnorderedListItem = styled(ListItem)`
  list-style-type: disc;
`;

export const SecondLevelUnorderedListItem = styled(ListItem)`
  list-style-type: circle;
`;

export const ThirdLevelUnorderedListItem = styled(ListItem)`
  list-style-type: square;
`;

export const FourthLevelUnorderedListItem = styled(ListItem)`
  list-style-type: square;
`;

export const FifthLevelUnorderedListItem = styled(ListItem)`
  list-style-type: square;
`;

export const SixthLevelUnorderedListItem = styled(ListItem)`
  list-style-type: square;
`;
