import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { Applet } from 'models/Applet';
import { uploadFiles } from 'modules/files/actions';
import {
  getQuestApplets as getQuestAppletsService,
  uploadGenericApplet as uploadGenericAppletService,
  shareApplet as shareAppletService,
  shareStep as shareStepService
} from './services';
import {
  ShareAppletEmailPayload,
  ShareStepEmailPayload,
  UploadGenericFileAppletPayload,
  UploadGenericRichTextPayload,
} from './typings';

const GET_QUEST_APPLETS = 'GET_QUEST_APPLETS';
export const getQuestApplets = createAsyncThunk(
  GET_QUEST_APPLETS,
  getQuestAppletsService
);

const GET_REST_QUEST_APPLETS = 'GET_REST_QUEST_APPLETS';
export const getRestQuestApplets = createAsyncThunk(
  GET_REST_QUEST_APPLETS,
  getQuestAppletsService
);

const CLEAR_APPLETS = 'CLEAR_APPLETS';
export const clearApplets = createAction<void>(CLEAR_APPLETS);

const UPLOAD_GENERIC_FILE_APPLET = 'UPLOAD_GENERIC_FILE_APPLET';
export const uploadGenericFileApplet = createAsyncThunk<
  Applet,
  UploadGenericFileAppletPayload
>(
  UPLOAD_GENERIC_FILE_APPLET,
  async ({ file, questId, appletId }, { dispatch }) => {
    const uploadedFile = await uploadFiles([{ file, id: appletId }], dispatch);
    const [{ fileUrl }] = uploadedFile;
    const newApplet = await uploadGenericAppletService({
      questId,
      appletId,
      type: 'generic-upload-file',
      fileUrl,
      fileType: file.type,
    });
    return newApplet;
  },
);

const UPLOAD_GENERIC_RICH_TEXT = 'UPLOAD_GENERIC_RICH_TEXT';
export const uploadGenericRichText = createAsyncThunk<
  Applet,
  UploadGenericRichTextPayload
>(UPLOAD_GENERIC_RICH_TEXT, async ({ appletId, questId, richText, type }) =>
  uploadGenericAppletService({
    appletId,
    questId,
    type,
    richText,
  }),
);

const SHARE_APPLET = 'SHARE_APPLET';
export const shareApplet = createAsyncThunk<void, ShareAppletEmailPayload>(
  SHARE_APPLET,
  shareAppletService,
);

const SHARE_STEP = 'SHARE_STEP';
export const shareStep = createAsyncThunk<void, ShareStepEmailPayload>(
  SHARE_STEP,
  shareStepService,
);
