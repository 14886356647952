import React, { useCallback } from 'react';

import Modal from 'components/modal';
import useContentfulRenderer from 'hooks/useContentfulRenderer';
import localizedStrings from 'localization';
import {
  PlaylistModalAudioTitle,
  PlaylistModalContent,
  PlaylistModalReading,
  PlaylistReadingAudio,
} from './components';
import { Artifact } from 'modules/home/playlist/typings';

type PlaylistModalProps = {
  open: boolean;
  close: (artifact: Artifact | undefined) => void;
  artifact?: Artifact;
};

const PlaylistModal = ({ open, close, artifact }: PlaylistModalProps) => {
  const reading = useContentfulRenderer(artifact?.reading?.content);

  const handleClose = useCallback(() => {
    close(artifact);
  }, [artifact, close]);

  return artifact ? (
    <Modal
      open={open}
      closeModal={handleClose}
      title={artifact.title}
      height="80%">
      <PlaylistModalContent>
        {artifact?.reading?.audioUrl && (
          <>
            <PlaylistModalAudioTitle>
              {localizedStrings.readingAudio}
            </PlaylistModalAudioTitle>
            <PlaylistReadingAudio src={artifact.reading?.audioUrl} controls />
          </>
        )}
        <PlaylistModalReading>{reading}</PlaylistModalReading>
      </PlaylistModalContent>
    </Modal>
  ) : null;
};

export default PlaylistModal;
