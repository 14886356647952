import React, { useCallback } from 'react';

import {
  SeparatorContainer,
  WeekNumberText,
  WeekProgressContainer,
} from '../components';
import DashedLine from '../../dashed-line';
import PieProgressIndicator from '../../icons/PieProgressIndicator';
import theme from 'utils/theme';
import CheckIcon from 'components/icons/CheckIcon';
import { useHistory } from 'react-router-dom';
import { OVERVIEW } from '../../../routes';
import { setWeekNumber } from '../../../modules/overview/actions';
import { useDispatch, useSelector } from 'react-redux';
import { selectWeekHighlighted } from '../../../modules/loading-screen/weeks/actions';
import { selectIsContentUnlocked, selectWeeksSelected } from '../../../modules/loading-screen/weeks/selectors';

interface WeekProgressIndicatorProps {
  progress: number;
  weekName: string;
  weekNumber: number;
  isLast: boolean;
  isCurrentWeek?: boolean;
}

const WeekProgressIndicator = ({
  progress,
  weekName,
  weekNumber,
  isLast,
  isCurrentWeek,
}: WeekProgressIndicatorProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const weeksSelected = useSelector(selectWeeksSelected);
  const isContentUnlocked = useSelector(selectIsContentUnlocked)
  const contentNumber = weekName

  const onClick = useCallback(
    (e) => {
      e.stopPropagation();
      if (isContentUnlocked) {
        dispatch(selectWeekHighlighted([...weeksSelected, weekNumber]));
        dispatch(setWeekNumber(weekNumber));
      } else {
        dispatch(setWeekNumber(weekNumber));
      }
      history.push(OVERVIEW);
    },
    [dispatch, history, weekNumber, isContentUnlocked, weeksSelected],
  );

  return (
    <>
      <WeekProgressContainer onClick={onClick}>
        {progress >= 100 ? (
          <CheckIcon
            height={theme.typography.large70}
            width={theme.typography.large70}
          />
        ) : (
          <PieProgressIndicator
            height={theme.typography.large70}
            width={theme.typography.large70}
            progress={progress}
          />
        )}
        <WeekNumberText isCurrentWeek={isCurrentWeek}>
          {contentNumber}
        </WeekNumberText>
      </WeekProgressContainer>
      {!isLast && (
        <SeparatorContainer>
          <DashedLine />
        </SeparatorContainer>
      )}
    </>
  );
};

export default WeekProgressIndicator;
