import { createSelector } from '@reduxjs/toolkit';
import { ALL_ID_ORDER } from 'modules/profile/portfolio/quest-applets/QuestSection';
import { RootState } from 'redux/typings';

const selectTagsState = (state: RootState) => state.tags;

export const selectTags = createSelector(selectTagsState, (state) => {
  const tagsResult = state.tags && state.tags.length > 0
    ? state.tags
    : [{ id: ALL_ID_ORDER.toString(), name: 'All', order: ALL_ID_ORDER }]

  const arrayForSort = [...tagsResult]
  return tagsResult.length > 1 ? arrayForSort.sort((a, b) => a.order - b.order) : tagsResult
});
