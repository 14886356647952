import { createReducer } from '@reduxjs/toolkit';

import {
  getAppOnboardings,
  getAppOnboardingSeen,
  getQuestOnboardingSeen,
  getWeekOnboardingSeen,
  notifyAppOnboardingSeen,
  notifyQuestOnboardingSeen,
  notifyWeekOnboardingSeen,
  getQuestOffboardingSeen,
  notifyQuestOffboardingSeen,
  setNewOnboardingWeekId,
  setComeFromChallenge,
  createContactActiveCampaign,
  getContactActiveCampaignByEmail,
} from './actions';
import { AppOnboardingState } from './typings';

const initialState: AppOnboardingState = {
  appOnboarding: [],
  appOnboardingSeen: false,
  questOnboardingSeen: {},
  questOffboardingSeen: {},
  weekOnboardingSeen: {},
  fromChallenge: false,
  activeCampaignContactCreated: false,
  contactNewActiveCampaing: 'NOT_VERIFIED'
};

export default createReducer<AppOnboardingState>(initialState, (builder) =>
  builder
    .addCase(getAppOnboardings.fulfilled, (state, action) => ({
      ...state,
      appOnboarding: action.payload,
    }))
    .addCase(getAppOnboardingSeen.fulfilled, (state, action) => ({
      ...state,
      appOnboardingSeen: action.payload,
    }))
    .addCase(notifyAppOnboardingSeen.pending, (state) => ({
      ...state,
      appOnboardingSeen: true,
    }))
    .addCase(getQuestOnboardingSeen.fulfilled, (state, action) => ({
      ...state,
      questOnboardingSeen: {
        ...state.questOnboardingSeen,
        [action.meta.arg.questId]: action.payload,
      },
    }))
    .addCase(getQuestOffboardingSeen.fulfilled, (state, action) => ({
      ...state,
      questOffboardingSeen: {
        ...state.questOffboardingSeen,
        [action.meta.arg.questId]: action.payload,
      },
    }))
    .addCase(notifyQuestOnboardingSeen.pending, (state, action) => ({
      ...state,
      questOnboardingSeen: {
        ...state.questOnboardingSeen,
        [action.meta.arg.questId]: true,
      },
    }))
    .addCase(notifyQuestOffboardingSeen.pending, (state, action) => ({
      ...state,
      questOffboardingSeen: {
        ...state.questOffboardingSeen,
        [action.meta.arg.questId]: true,
      },
    }))
    .addCase(getWeekOnboardingSeen.fulfilled, (state, action) => ({
      ...state,
      weekOnboardingSeen: {
        ...state.weekOnboardingSeen,
        [action.meta.arg.weekId]: action.payload,
      },
    }))
    .addCase(notifyWeekOnboardingSeen.pending, (state, action) => ({
      ...state,
      weekOnboardingSeen: {
        ...state.weekOnboardingSeen,
        [action.meta.arg.weekId]: true,
      },
      newOnboardingWeekId: undefined,
    }))
    .addCase(setNewOnboardingWeekId, (state, action) => ({
      ...state,
      newOnboardingWeekId: action.payload,
    }))
    .addCase(setComeFromChallenge, (state, action) => ({
      ...state,
      fromChallenge: action.payload,
    }))
    .addCase(createContactActiveCampaign.fulfilled, (state, action) => ({
      ...state,
      activeCampaignContactCreated: true,
      contactActiveCampaign: action.payload,
      contactNewActiveCampaing: 'CONTACT_EXISTS'
    }))
    .addCase(getContactActiveCampaignByEmail.fulfilled, (state, action) => ({
      ...state,
      contactFilteredResponse: action.payload,
      contactNewActiveCampaing: action.payload.contacts.length === 0 ? 'IS_NEW' : 'CONTACT_EXISTS'
    }))
);
