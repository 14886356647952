import useContentfulRenderer from 'hooks/useContentfulRenderer';
import { Document } from '@contentful/rich-text-types';
import { ContentfulRendererOptions } from './useContentfulRenderer';
import theme from 'utils/theme';

const useMadlibSentenceRenderer = (
  madlibSentence?: Document,
  options: ContentfulRendererOptions = {},
) => {
  const { additionalStyles = {}, ...otherOptions } = options;
  const { paragraph = {}, ...otherAdditionalStyles } = additionalStyles;

  const renderSentence = useContentfulRenderer(madlibSentence, {
    ...otherOptions,
    additionalStyles: {
      ...otherAdditionalStyles,
      paragraph: {
        color: theme.colors.grey[100],
        ...paragraph,
      },
    },
  });

  return renderSentence;
};

export default useMadlibSentenceRenderer;
