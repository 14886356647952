import React, { useMemo } from 'react';
import { Document } from '@contentful/rich-text-types';

import { IQuiz } from 'models/contentful';
import useContentfulRenderer from 'hooks/useContentfulRenderer';
import { Image } from 'components/contentful-renders/components';
import QuizChart from './QuizChart';
import useQuizChartInfo from '../../../hooks/useQuizChartInfo';
import { QuizQuestion } from 'models/Applet';
import { useSelector } from 'react-redux';
import { selectChallengeAppletByContentfulId } from 'modules/loading-screen/contents/selectors';
import { selectQuestChallengeApplet } from 'modules/applets/selectors';
import { selectAppletById } from 'modules/applet-upload/selectors';

type QuizResultProps = {
  quiz?: IQuiz;
  quizQuestions?: QuizQuestion[];
  hideRewardText?: boolean;
  mixedResultText?: Document;
  appletId: string;
};

export const QUIZ_RESULT_THRESHOLD = 56;

const QuizResult = ({
  quiz,
  quizQuestions,
  hideRewardText,
  mixedResultText,
  appletId,
}: QuizResultProps) => {
  const appletAnswer = useSelector(
    selectChallengeAppletByContentfulId(appletId),
  );
  const questAnswer = useSelector(selectQuestChallengeApplet(appletId));
  const appletToUpload = useSelector(selectAppletById(appletId));

  const appletsToUploadQuizResults =
    appletToUpload?.quizInformation?.questionsMap;

  const [rewardText, rewardMedia] = useMemo(() => {
    const quizResults = appletsToUploadQuizResults
      ? Object.values(appletsToUploadQuizResults)
      : quizQuestions ||
        appletAnswer?.quizQuestions ||
        questAnswer?.quizQuestions;

    if (quizResults && quizResults.length > 0) {
      let maxResult = 0;
      let maxResultAnswer = '';
      const resultsMap: Record<string, number> = {};
      for (const quizResult of quizResults) {
        const answer = quizResult.answer || '';
        const currentCount = resultsMap[answer] || 0;
        const newCount = currentCount + 1;
        resultsMap[answer] = newCount;
        if (maxResult < newCount) {
          maxResult = newCount;
          maxResultAnswer = answer;
        }
      }

      if (maxResultAnswer) {
        const rewardInformation = quiz?.fields.rewards?.find(
          (reward) => reward.fields.associatedResult === maxResultAnswer,
        );
        if (rewardInformation) {
          return [
            rewardInformation.fields.textReward,
            rewardInformation.fields.mediaReward,
          ];
        }
      }
    }

    return [undefined, undefined];
  }, [
    appletAnswer?.quizQuestions,
    appletsToUploadQuizResults,
    questAnswer?.quizQuestions,
    quiz?.fields.rewards,
    quizQuestions,
  ]);

  const renderedRewardText = useContentfulRenderer(rewardText);
  const renderedMixedResultText = useContentfulRenderer(mixedResultText);

  const chartInfo = useQuizChartInfo(appletId, quiz, quizQuestions);

  const quizResultCount = chartInfo.find(
    (item) => item.count >= QUIZ_RESULT_THRESHOLD,
  )?.count;
  const isMixedResult = !quizResultCount;
  const showImage = rewardMedia && !isMixedResult;
  const showRewardText = !isMixedResult && !hideRewardText;
  const showChart = !showImage && !hideRewardText && chartInfo.length > 0;
  const showMixedResultText = isMixedResult && !hideRewardText;

  return (
    <div>
      {showRewardText && renderedRewardText}
      {showMixedResultText && renderedMixedResultText}
      {showImage ? (
        <Image
          src={`https:${rewardMedia?.fields.file.url}`}
          alt={rewardMedia?.fields.description}
        />
      ) : null}
      {showChart ? <QuizChart fields={chartInfo} /> : null}
    </div>
  );
};

export default QuizResult;
