import React, { useState } from 'react';
import { pdfjs, Document, Page } from 'react-pdf';

import {
  NextButton,
  PrevButton,
  PreviewContainer,
} from 'components/components';
import PageLoadingPlaceholder from '../applets/app-upload/file-preview/PageLoadingPlaceholder';
import { CurrentPageText, PdfPreviewControls } from './components';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

type PdfPreviewProps = {
  file: { uri: string; type: string; name: string };
};

const PdfPreview = ({ file }: PdfPreviewProps) => {
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }: any) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset: number) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }
  return (
    <PreviewContainer>
      <Document
        file={file.uri}
        onLoadSuccess={onDocumentLoadSuccess}
        loading={<PageLoadingPlaceholder />}>
        <Page
          pageNumber={pageNumber}
          renderAnnotationLayer={false}
          loading={<PageLoadingPlaceholder />}
          width={900}
        />
      </Document>
      <PdfPreviewControls>
        <PrevButton
          type="button"
          disabled={pageNumber <= 1}
          onClick={previousPage}
        />
        <CurrentPageText>
          {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
        </CurrentPageText>
        <NextButton
          type="button"
          disabled={pageNumber >= numPages}
          onClick={nextPage}
        />
      </PdfPreviewControls>
    </PreviewContainer>
  );
};

export default PdfPreview;
