
import { NavigationSection, NavigationTitle } from './components';
import { VerticalNavbarProps } from './typings';
import NavigationButton from './NavigationButton';

export const VerticalNavSettingsContent = ({
  title,
  items,
  selectedItem,
  onItemClick,
}: VerticalNavbarProps) => {
  return (
    <NavigationSection>
      <NavigationTitle>{title}</NavigationTitle>
      {items.map((item) => (
        <NavigationButton
          key={item.name}
          name={item.name}
          selected={selectedItem}
          onClick={onItemClick}>
          {item.description}
        </NavigationButton>
      ))}
    </NavigationSection>
  );
};
