import React, { useCallback, useEffect, useState } from 'react';

import AppUpload, { UploadFile } from 'components/applets/app-upload';
import Modal from 'components/modal';

import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'redux/typings';
import { uploadGenericFileApplet } from 'modules/applets/actions';
import { selectUploadFileGenericApplet } from 'modules/profile/selectors';
import * as statusSelector from 'modules/status/selectors';
import { QuestPortfolio } from 'models/QuestPortfolio';
import useFileUploadStatus from 'hooks/useFileUploadStatus';

type AddFileModalProps = {
  open: boolean;
  quest: QuestPortfolio;
  closeModal: () => void;
};

const AddFileModal = ({ open, quest, closeModal }: AddFileModalProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const [file, setfile] = useState<UploadFile | undefined>(undefined);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const uploadFileGenericApplet = useSelector(selectUploadFileGenericApplet);

  const isUploading = useSelector((state: RootState) =>
    statusSelector.isPendingSelector(state, uploadGenericFileApplet.typePrefix),
  );
  const [isUploadingFile, uploadingText] = useFileUploadStatus();

  const finishedUploading = useSelector((state: RootState) =>
    statusSelector.wasFulfilledSelector(
      state,
      uploadGenericFileApplet.typePrefix,
    ),
  );

  const handleChangeFile = useCallback((file: UploadFile | undefined) => {
    setfile(file);
  }, []);

  const handleUpload = useCallback(() => {
    if (uploadFileGenericApplet && file) {
      setHasSubmitted(true);
      dispatch(
        uploadGenericFileApplet({
          appletId: uploadFileGenericApplet.id,
          file,
          questId: quest.id,
        }),
      );
    }
  }, [uploadFileGenericApplet, file, quest, dispatch]);

  const handleCloseModal = useCallback(() => {
    setfile(undefined);
    closeModal();
  }, [closeModal]);

  useEffect(() => {
    if (finishedUploading && hasSubmitted) {
      setHasSubmitted(false);
      handleCloseModal();
    }
  }, [finishedUploading, handleCloseModal, hasSubmitted]);

  return (
    <Modal
      open={open}
      closeModal={handleCloseModal}
      width="auto"
      acceptButtonText={isUploadingFile ? uploadingText : 'Upload'}
      onAcceptButtonClick={handleUpload}
      acceptButtonDisabled={!file}
      acceptButtonisLoading={isUploading}>
      <AppUpload value={file} onChangeFile={handleChangeFile} />
    </Modal>
  );
};

export default AddFileModal;
