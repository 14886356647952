import { createReducer } from '@reduxjs/toolkit';
import { arrayToHash } from 'helpers/helperFunctions';
import { WritableDraft } from 'immer/dist/internal';
import { Content } from 'models/Content';
import { getActiveQuest, getOldQuests } from '../quests/actions';

import {
  selectContentById,
  submitPost,
  getContentById,
  clearSelectedContent,
  updateAvailableContent,
  selectStep,
  completeStep,
  updateIsEditableContent,
  getLinkToSharePost,
  cleanLinkToSharePost,
} from './actions';
import { ContentState } from './typings';

const initialState: ContentState = {
  contents: {},
  linkToShare: ''
};

export default createReducer<ContentState>(initialState, (builder) =>
  builder
    .addCase(getActiveQuest.fulfilled, (state, action) => ({
      ...state,
      contents: {
        ...state.contents,
        ...contentArrayToHash(action.payload.content),
      },
    }))
    .addCase(getOldQuests.fulfilled, (state, action) => ({
      ...state,
      contents: {
        ...state.contents,
        ...contentArrayToHash(action.payload.content),
      },
    }))
    .addCase(selectContentById, (state, action) => ({
      ...state,
      selectedId: action.payload,
    }))
    .addCase(clearSelectedContent, (state, _) => ({
      ...state,
      selectedId: undefined,
    }))
    .addCase(completeStep.fulfilled, (state, action) => ({
      ...state,
      contents: updateContentHash(state.contents, action.payload),
    }))
    .addCase(submitPost.fulfilled, (state, action) => ({
      ...state,
      contents: updateContentHash(state.contents, action.payload.content),
    }))
    .addCase(getContentById.fulfilled, (state, action) => ({
      ...state,
      contents: updateContentHash(state.contents, action.payload),
    }))
    .addCase(updateAvailableContent, (state, action) => ({
      ...state,
      contents: contentArrayToHash(action.payload),
    }))
    .addCase(selectStep, (state, action) => ({
      ...state,
      selectedStepIdx: action.payload,
    }))
    .addCase(updateIsEditableContent, (state, action) => ({
      ...state,
      isEditableContentSubmitted: action.payload,
    }))
    .addCase(getLinkToSharePost.fulfilled, (state, action) => ({
      ...state,
      linkToShare: action.payload
    }))
    .addCase(cleanLinkToSharePost, (state) => ({
      ...state,
      linkToShare: ''
    }))
);

const contentArrayToHash = (contentArray: Content[]) =>
  arrayToHash<Content>(contentArray, (content) => content.id);

const updateContentHash = (
  contents: WritableDraft<Record<string, Content>>,
  content: Content,
) =>
({
  ...contents,
  [content.id]: content,
} as WritableDraft<Record<string, Content>>);
