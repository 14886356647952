import styled from '@emotion/styled';

type SyncInformationContainerProps = {
  visible?: boolean;
};

export const SyncInformationContainer = styled.div<SyncInformationContainerProps>`
  position: absolute;
  right: 0;
  color: ${(props) => props?.theme?.colors.white[100]};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  overflow: hidden;
  padding: ${(props) => (props.visible ? '1rem' : '0px')};
  height: ${(props) => (props.visible ? '50px' : '0px')};
  transition: height 0.5s cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    padding 0.5s cubic-bezier(0.4, 0, 0.2, 1) 0ms;
`;
