import React, {
  PropsWithChildren,
  useCallback,
  useState,
  MouseEvent,
} from 'react';

type DraggableCarouselItemProps = {
  onClick: () => void;
  className?: string;
};

const DraggableCarouselItem = ({
  onClick,
  children,
  className,
}: PropsWithChildren<DraggableCarouselItemProps>) => {
  const [moved, setMoved] = useState(false);

  const handleItemMouseDown = useCallback(() => {
    setMoved(false);
  }, []);

  const handleItemMouseMove = useCallback(() => {
    setMoved(true);
  }, []);

  const handleItemMouseUp = useCallback(
    (evt: MouseEvent<HTMLDivElement>) => {
      if (!moved && evt.button === 0) {
        onClick();
      }
    },
    [moved, onClick],
  );
  return (
    <div
      data-testid="drag-carousel-card-item"
      className={className}
      onMouseDown={handleItemMouseDown}
      onMouseMove={handleItemMouseMove}
      onMouseUp={handleItemMouseUp}
    >
      {children}
    </div>
  );
};

export default DraggableCarouselItem;
