import React, { useCallback, useMemo, useState } from 'react';

import { QuestApplet } from 'models/QuestApplet';
import {
  ShowcaseModalBackground,
  ShowcasePictureModalPanel,
  ShowcaseModalCloseButton,
  ShowcaseModalHeaderVideo,
  ShowcasePictureCard,
  ShowcaseSeeMoreButton,
  ShowCaseSeeMoreText,
  ShowcasePictureModalContent,
} from '../ShowcaseOverviewComponents';
import Close from 'assets/icons/Close.webp';
import AppletPreviewSwitch from '../../../../common/applet-preview-switch/index';

type ShowcaseModalProps = {
  modalState: {
    open: boolean;
    applet?: QuestApplet;
  };
  closeModal: () => void;
};

const ShowcaseModal = ({ modalState, closeModal }: ShowcaseModalProps) => {
  const [seeMore, setSeeMore] = useState(false);
  const changeSeeMore = useCallback(() => setSeeMore(true), []);

  const renderPreview = useMemo(() => {
    const applet = modalState.applet;
    if (applet) {
      return <AppletPreviewSwitch applet={applet} />;
    }
  }, [modalState.applet]);

  const handleClose = useCallback(() => {
    setSeeMore(false);
    closeModal();
  }, [closeModal]);

  return (
    <ShowcaseModalBackground visible={modalState.open}>
      <ShowcasePictureModalPanel>
        <ShowcaseModalCloseButton onClick={handleClose}>
          <img src={Close} alt="close" />
        </ShowcaseModalCloseButton>
        <ShowcaseModalHeaderVideo>
          {modalState.applet?.title}
        </ShowcaseModalHeaderVideo>
        <ShowcasePictureModalContent>
          <ShowcasePictureCard>{renderPreview}</ShowcasePictureCard>
          <ShowcaseSeeMoreButton onClick={changeSeeMore}>
            Show more
          </ShowcaseSeeMoreButton>
          {seeMore && (
            <ShowCaseSeeMoreText>
              {modalState.applet?.description}
            </ShowCaseSeeMoreText>
          )}
        </ShowcasePictureModalContent>
      </ShowcasePictureModalPanel>
    </ShowcaseModalBackground>
  );
};

export default ShowcaseModal;
