import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch } from 'redux/typings';
import { Relationship, RelationshipType } from 'models/Relationship.d';
import { getInvitePayloadFromRelationship } from 'helpers/relationshipHelpers';
import localizedStrings from 'localization';
import { selectRelationshipsList } from 'modules/relationships/selectors';
import { invite } from 'modules/relationships/actions';
import {
  AddRunningPartenerModalContent,
  AddRunningPartnerSearchBox,
  AddSomeoneElseButton,
  FellowTravelersList,
  SearchInput,
} from 'modules/relationships/components';
import RelationshipCard from '../RelationshipCard';
import Modal from 'components/modal';
import SearchIcon from 'components/icons/SearchIcon';
import { AddOptions } from './AddModal';

type AddRunningPartnerModalProps = {
  open: boolean;
  closeModal: () => void;
  option?: AddOptions;
  onInvitationSent: (invitedUserName: string) => void;
  onAddSomeoneElseClick: () => void;
};

const AddRelationshipModal = ({
  open,
  closeModal,
  option = AddOptions.ADD_RUNNING_PARTNER,
  onInvitationSent,
  onAddSomeoneElseClick,
}: AddRunningPartnerModalProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const relationships = useSelector(selectRelationshipsList);
  const [filterValue, setFilterValue] = useState('');

  const modalTitle = useMemo(
    () =>
      option === AddOptions.ADD_RUNNING_PARTNER
        ? localizedStrings.pairWithRunningPartner
        : localizedStrings.addMentorTitle,
    [option],
  );

  const filteredRelationships = useMemo(
    () =>
      relationships.filter(
        (relationship) =>
          relationship.relationshipType === RelationshipType.FELLOW_TRAVELER &&
          relationship.relatedUser.displayName
            .toLowerCase()
            .includes(filterValue.toLowerCase()),
      ),
    [relationships, filterValue],
  );

  const handleFilterChange = useCallback((evt) => {
    setFilterValue(evt.target.value);
  }, []);

  const handleInvitationSent = useCallback(
    (relationship: Relationship) => {
      dispatch(
        invite(
          getInvitePayloadFromRelationship(
            relationship,
            option === AddOptions.ADD_RUNNING_PARTNER
              ? RelationshipType.RUNNING_PARTNER
              : RelationshipType.MENTOR,
          ),
        ),
      );
      onInvitationSent(relationship.relatedUser.displayName);
    },
    [dispatch, onInvitationSent, option],
  );

  useEffect(() => {
    if (!open) {
      setFilterValue('');
    }
  }, [open]);

  return (
    <Modal
      open={open}
      closeModal={closeModal}
      width={'516px'}
      height={'80%'}
      title={modalTitle}>
      <AddRunningPartenerModalContent>
        <AddRunningPartnerSearchBox>
          <SearchIcon />
          <SearchInput
            type="text"
            name="filter"
            value={filterValue}
            onChange={handleFilterChange}
            placeholder={localizedStrings.searchPlaceholder}
          />
        </AddRunningPartnerSearchBox>
        <FellowTravelersList>
          {filteredRelationships.map((relationship) => (
            <RelationshipCard
              key={relationship.id}
              relationship={relationship}
              onInvite={handleInvitationSent}
              type="invite"
              option={option}
            />
          ))}
        </FellowTravelersList>
        <AddSomeoneElseButton onClick={onAddSomeoneElseClick}>
          {localizedStrings.addSomeoneElse}
        </AddSomeoneElseButton>
      </AddRunningPartenerModalContent>
    </Modal>
  );
};

export default AddRelationshipModal;
