import React from 'react';

import {
  ChallengeTitlesContainer,
  NextChallengeCardContainer,
  NextChallengeIconsContainer,
  NextChallengeInfoContainer,
  NextChallengeTitle,
  NextStepTitle,
  NextUpChallengeSubtitle,
  StepDetailsContainer,
  StepNumber,
} from '../components';
import ChallengeBasicInformation from 'modules/home/challenge-basic-information';
import { StepSchedule } from 'models/Schedule';
import useStepCardInformation from 'hooks/useStepCardInformation';
import { useSelector } from 'react-redux';
import { selectContents } from '../../loading-screen/contents/selectors';
import { selectContentsContentful } from '../../loading-screen/contentful/contents/selectors';
import { NextChallengeContainerBackground } from 'modules/challenge/components';

type NextChallengeCardProps = {
  step: StepSchedule;
  onClick: () => void;
  className?: string;
};

const NextChallengeCard = ({
  step,
  onClick,
  className,
}: NextChallengeCardProps) => {
  const [challengeSubtitle, stepNumber] = useStepCardInformation(step);

  const contents = useSelector(selectContents);
  const challengeUser = contents[step.challengeId || ''];

  const contentsContentful = useSelector(selectContentsContentful);
  const challengeContentful = contentsContentful[challengeUser?.contentfulId];
  const challengeLocation = challengeContentful?.location;

  return (
    <NextChallengeCardContainer
      data-testid="drag-carousel-NextChallengeCardContainer"
      className={className}
      imageUrl={step.challengeImage}
      onClick={onClick}>
      <NextChallengeInfoContainer>
        <NextChallengeContainerBackground>
          <NextUpChallengeSubtitle small>
            {challengeSubtitle}
          </NextUpChallengeSubtitle>
          <ChallengeTitlesContainer>
            <NextChallengeTitle>{step.challengeTitle}</NextChallengeTitle>

            <StepDetailsContainer>
              <NextStepTitle>{step.stepTitle}</NextStepTitle>
              <StepNumber small>{stepNumber}</StepNumber>
            </StepDetailsContainer>

            <NextChallengeIconsContainer>
              <ChallengeBasicInformation
                time={step.stepDuration}
                xp={step.stepPoints}
                small={true}
                location={step.location || challengeLocation || ''}
                bonusChallenge={challengeContentful?.bonusChallenge}
              />
            </NextChallengeIconsContainer>
          </ChallengeTitlesContainer>
        </NextChallengeContainerBackground>
      </NextChallengeInfoContainer>
    </NextChallengeCardContainer>
  );
};

export default NextChallengeCard;
