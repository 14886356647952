import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import {
  selectIsEditableContentSubmitted,
  selectSelectedContent,
} from 'modules/loading-screen/contents/selectors';
import {
  selectSelectedStep,
  selectSelectedStepAdditionalInfo,
  selectSelectedStepButtonColor,
  selectSelectedStepButtonText,
  selectSelectedStepContent,
  selectSelectedStepIsCompleted,
} from 'modules/loading-screen/contents/selectors';
import useContentfulRenderer from 'hooks/useContentfulRenderer';
import {
  ButtonContainer,
  StepButton,
  SectionContainer,
  StepCompletionError,
} from '../components';
import {
  selectAppletAnswers,
  selectAppletsForShareModal,
  selectHasCompletedAppletsForShareModal,
  selectHasStepApplets,
  selectUserHasCompletedOptionalApplets,
  selectUserHasCompletedRequiredApplets,
} from 'modules/applet-upload/selectors';
import { AppDispatch, RootState } from 'redux/typings';
import {
  completeStep,
  updateIsEditableContent,
} from 'modules/loading-screen/contents/actions';
import * as statusSelector from 'modules/status/selectors';
import StepAdditionalInfo from '../step-aditional-info';
import { CHALLENGE } from 'routes';
import { selectSelectedWeekId } from 'modules/loading-screen/weeks/selectors';
import { selectStepDate } from 'modules/loading-screen/schedules/selectors';
import useFileUploadStatus from 'hooks/useFileUploadStatus';
import Dialog from 'components/dialog';
import LocalizedStrings from 'localization';
import { openShareStepModal } from 'modules/modal/actions';
import { selectIsReadingModalOpen } from '../../modal/selectors';
import { closeReadingModal } from '../../modal/actions';
import ReadingModal from '../../modal/ReadingModal';

const Step = () => {
  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();
  const [submitted, setSubmitted] = useState(false);
  const [showAppletDialog, setShowAppletDialog] = useState(false);
  const [showAppletMoveDialog, setShowAppletMoveDialog] = useState(false);
  const selectedStep = useSelector(selectSelectedStep);
  const selectedStepButtonText = useSelector(selectSelectedStepButtonText);
  const selectedStepButtonColor = useSelector(selectSelectedStepButtonColor);
  const selectedStepContent = useSelector(selectSelectedStepContent);
  const selectedStepAdditionalInfo = useSelector(
    selectSelectedStepAdditionalInfo,
  );
  const isStepCompleted = useSelector(selectSelectedStepIsCompleted);

  const selectedContentId = useSelector(selectSelectedContent)?.id ?? '';
  const selectedStepId = selectedStep?.id;
  const stepScheduledDate = useSelector(selectStepDate(selectedStepId));
  const weekId = useSelector(selectSelectedWeekId);
  const selectedStepNumber = selectedStep?.stepNumber ?? -1;
  const appletsAnswers = useSelector(selectAppletAnswers);
  const hasCompletedRequiredApplets = useSelector(selectUserHasCompletedRequiredApplets);
  const hasStepAppletsToEdit = useSelector(selectHasStepApplets);
  const hasCompletedOptionalApplets = useSelector(
    selectUserHasCompletedOptionalApplets,
  );
  const shareModalApplets = useSelector(selectAppletsForShareModal);
  const enabledSharebutton = useSelector(
    selectHasCompletedAppletsForShareModal,
  );

  const isLoading = useSelector((state: RootState) =>
    statusSelector.isPendingSelector(state, completeStep.typePrefix),
  );
  const finishedSubmitting = useSelector((state: RootState) =>
    statusSelector.wasFulfilledSelector(state, completeStep.typePrefix),
  );
  const isReadingModalOpen = useSelector(selectIsReadingModalOpen);
  const isEditableContentSubmitted = useSelector(
    selectIsEditableContentSubmitted,
  );

  const [isUploading, uploadingText, hasFailed, errorMessage] =
    useFileUploadStatus();

  const stepContent = useContentfulRenderer(selectedStepContent, {
    textBesideImage: true,
  });

  const handleUpdateContentCompleted = (state: boolean) => {
    dispatch(updateIsEditableContent(state));
  };

  const handleSubmit = useCallback(
    (editContent = false) => {
      if (
        selectedContentId &&
        weekId &&
        selectedStepId &&
        selectedStepNumber > -1
      ) {
        if (!editContent) {
          setSubmitted(true);
        }
        dispatch(
          completeStep({
            appletsAnswers,
            challengeId: selectedContentId,
            stepId: selectedStepId,
            weekId,
            stepScheduledDate,
            stepNumber: selectedStepNumber,
          }),
        );
      }
    },
    [
      appletsAnswers,
      dispatch,
      selectedContentId,
      selectedStepId,
      selectedStepNumber,
      stepScheduledDate,
      weekId,
    ],
  );

  const moveForward = useCallback(() => {
    if (!hasCompletedRequiredApplets) {
      return setShowAppletMoveDialog(true)
    }
    if (!hasCompletedOptionalApplets) {
      return setShowAppletDialog(true);
    }
    if (!stepScheduledDate) {
      alert(
        'Warning! The step you are trying to complete seems to not have been scheduled. Please contact the admin. You will be able to complete it nonetheless.',
      );
    }
    handleSubmit();
  }, [
    hasCompletedRequiredApplets,
    hasCompletedOptionalApplets,
    stepScheduledDate,
    handleSubmit,
  ]);

  const handleAppletDialogClick = useCallback(
    (yes: boolean) => () => {
      if (yes) {
        handleSubmit();
      }
      setShowAppletDialog(false);
    },
    [handleSubmit],
  );

  const handleAppletMoveDialogClick = useCallback(
    (openState: boolean) => () => {
      setShowAppletMoveDialog(openState)
    }, [],
  );

  const handleChangeContentCompleted = () => handleUpdateContentCompleted(true);
  const handleSaveContentChanged = () => {
    handleSubmit(isEditableContentSubmitted);
    handleUpdateContentCompleted(false);
  };

  const onShareStepClick = useCallback(() => {
    if (selectedStepId) {
      dispatch(
        openShareStepModal({
          stepId: selectedStepId,
          applets: shareModalApplets,
        }),
      );
    }
  }, [dispatch, selectedStepId, shareModalApplets]);

  useEffect(() => {
    if (submitted && finishedSubmitting) {
      const nextStep = selectedStepNumber === -1 ? 1 : selectedStepNumber + 1;
      history.push(`${CHALLENGE}/${selectedContentId}/${nextStep}`);
      setSubmitted(false);
    }
  }, [
    submitted,
    finishedSubmitting,
    history,
    selectedContentId,
    selectedStepNumber,
  ]);

  const closeReadModal = useCallback(() => {
    dispatch(closeReadingModal());
  }, [dispatch]);

  return (
    <>
      <SectionContainer data-testid="step-container">{stepContent}</SectionContainer>

      {selectedStepAdditionalInfo && (
        <SectionContainer>
          <StepAdditionalInfo additionalInfo={selectedStepAdditionalInfo} />
        </SectionContainer>
      )}

      {shareModalApplets && shareModalApplets.length > 1 ? (
        <ButtonContainer>
          <StepButton
            data-testid="btn-share-step-content"
            disabled={!enabledSharebutton}
            onClick={onShareStepClick}>
            {LocalizedStrings.share}
          </StepButton>
        </ButtonContainer>
      ) : null}

      {!isStepCompleted && !isEditableContentSubmitted ? (
        <SectionContainer>
          <Dialog
            open={showAppletDialog}
            title={LocalizedStrings.areYouSure}
            prompt={LocalizedStrings.completeStepConfirmation}
            onNoClick={handleAppletDialogClick(false)}
            onYesClick={handleAppletDialogClick(true)}
          />
          {hasFailed ? (
            <StepCompletionError>{errorMessage}</StepCompletionError>
          ) : null}
          <ButtonContainer>
            <StepButton
              data-testid="btn-complete-step-content"
              onClick={moveForward}
              isLoading={isLoading}
              backgroundColor={selectedStepButtonColor}>
              {isUploading ? uploadingText : selectedStepButtonText}
            </StepButton>
          </ButtonContainer>
        </SectionContainer>
      ) : null}


      <SectionContainer>
        <Dialog
          open={showAppletMoveDialog}
          title={LocalizedStrings.cantContinue}
          prompt={LocalizedStrings.completeAllApplets}
          onYesClick={handleAppletMoveDialogClick(false)}
          yesText={LocalizedStrings.ok}
          width='25rem'
        />
      </SectionContainer>


      {isStepCompleted && !isEditableContentSubmitted && hasStepAppletsToEdit ? (
        <ButtonContainer>
          <StepButton
            data-testid="btn-edit-content"
            onClick={handleChangeContentCompleted}
            isLoading={isLoading}
            backgroundColor={selectedStepButtonColor}>
            {isUploading ? uploadingText : LocalizedStrings.editContent}
          </StepButton>
        </ButtonContainer>
      ) : null}

      {isEditableContentSubmitted ? (
        <SectionContainer>
          <Dialog
            open={showAppletDialog}
            title={LocalizedStrings.areYouSure}
            prompt={LocalizedStrings.completeStepConfirmation}
            onNoClick={handleAppletDialogClick(false)}
            onYesClick={handleAppletDialogClick(true)}
          />

          {hasFailed ? (
            <StepCompletionError>{errorMessage}</StepCompletionError>
          ) : null}

          <ButtonContainer>
            <StepButton
              data-testid="btn-save-content"
              onClick={handleSaveContentChanged}
              isLoading={isLoading}
              backgroundColor={selectedStepButtonColor}>
              {isUploading ? uploadingText : LocalizedStrings.saveContent}
            </StepButton>
          </ButtonContainer>
        </SectionContainer>
      ) : null}

      <ReadingModal open={isReadingModalOpen} close={closeReadModal} />
    </>
  );
};

export default Step;
