import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch } from 'redux/typings';
import { saveRichTextApplet } from 'modules/applet-upload/actions';
import useIsAppletDisabled from 'hooks/useIsAppletDisabled';
import useContentfulRenderer from 'hooks/useContentfulRenderer';
import useExistingApplet from 'hooks/useExistingApplet';
import {
  selectAppletById,
  selectAppletsForShareModal,
} from 'modules/applet-upload/selectors';
import RichTextApplet from 'components/applets/rich-text';
import { selectAppletContentfulById } from 'modules/loading-screen/contentful/applets/selectors';
import ShareAppletButton from 'components/applets/share-applet-button';
import theme from 'utils/theme';

type RichTextAppletChallengeProps = {
  appletId: string;
};

const RichTextAppletChallenge = ({
  appletId,
}: RichTextAppletChallengeProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const appletContentful = useSelector(selectAppletContentfulById(appletId));
  const appletToUpload = useSelector(selectAppletById(appletId));
  const shareModalApplets = useSelector(selectAppletsForShareModal);
  const [firstEdition, setFirstEdition] = useState<boolean>(false);

  const showSendButton = useMemo(
    () => appletContentful?.sendButton && shareModalApplets.length <= 1,
    [appletContentful, shareModalApplets],
  );

  const disabled = useIsAppletDisabled();
  const id = useExistingApplet(appletId);
  const renderedPrompt = useContentfulRenderer(appletContentful?.uploadPrompt, {
    additionalStyles: { paragraph: { color: theme.colors.grey[90] } },
  });

  const onContentChange = useCallback(
    (content: string) => {
      dispatch(
        saveRichTextApplet({
          applet: {
            id,
            appletId,
            appletType: 'rich-text-entry',
            richText: content,
            untouched: false,
          },
          modified: true,
        }),
      );
    },
    [id, appletId, dispatch],
  );

  return (
    <RichTextApplet
      disabled={disabled || appletContentful?.readOnly}
      initialContent={appletToUpload?.richText ?? ''}
      onContentChange={onContentChange}
      onFirstEdition={setFirstEdition}
      firstEdition={firstEdition}
      sendButton={
        showSendButton ? (
          <ShareAppletButton
            richText={appletToUpload?.richText}
            disabled={!firstEdition}
            appletContentfulId={appletId}
            type="rich-text-entry"
          />
        ) : null
      }
      uploadPrompt={renderedPrompt}
    />
  );
};

export default RichTextAppletChallenge;
