import React from 'react';
import theme from 'utils/theme';

type PieProgressIndicatorProps = {
  width?: string;
  height?: string;
  progress: number;
};

const PieProgressIndicator = ({
  width,
  height,
  progress,
}: PieProgressIndicatorProps) => {
  const CENTER = 11;
  const RADIUS = 11;

  const START_ANGLE = 270;
  const endAngle = START_ANGLE + (progress / 100) * 360;
  const isLarge = progress > 50;
  const isComplete = progress >= 100;
  const X1 = CENTER + RADIUS * Math.cos((Math.PI * START_ANGLE) / 180);
  const Y1 = CENTER + RADIUS * Math.sin((Math.PI * START_ANGLE) / 180);
  const x2 = CENTER + RADIUS * Math.cos((Math.PI * endAngle) / 180);
  const y2 = CENTER + RADIUS * Math.sin((Math.PI * endAngle) / 180);
  const d = `
    M ${CENTER} ${CENTER}
    L ${X1} ${Y1}
    A ${RADIUS} ${RADIUS} 0 ${isLarge ? 1 : 0} 1 ${x2} ${y2}
    Z
  `;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 22"
      style={{ overflow: 'visible' }}>
      <circle
        r="10"
        cx="11"
        cy="11"
        stroke={theme.colors.white[25]}
        strokeWidth="2"
        fill="transparent"
      />
      {isComplete ? (
        <circle
          r="10"
          cx="11"
          cy="11"
          stroke={theme.colors.gold[100]}
          fill={theme.colors.gold[100]}
          strokeWidth="2"
        />
      ) : (
        <path
          d={d}
          fill={theme.colors.gold[100]}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      )}
    </svg>
  );
};

export default PieProgressIndicator;
