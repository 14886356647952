import styled from '@emotion/styled';
import { ScrollableArea } from 'components/components';

type RecentActivityContainerProps = {
  visible?: boolean;
};

export const AvatarOptionsMenuContainer = styled.div<RecentActivityContainerProps>`
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
  position: absolute;
  top: 0;
  left: -50px;
  width: 100%;
  height: 100%;
`;

type PositionProps = {
  xPosition: number;
  yPosition: number;
};

export const MenuItemPanel = styled(ScrollableArea) <PositionProps>`
  position: absolute;
  top: ${(props) => props.yPosition}px;
  right: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1.2rem;
  box-sizing: border-box;  
  width: 300px;
  border: 0.5px solid #979797;
  border-radius: 30px 0 30px 30px;
  background-color: #ffffff;
  box-shadow: 0 1px 35px 0 rgba(0, 0, 0, 0.6);
  z-index: 1;
`;

export const MenuItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  &:hover { cursor: pointer; }
`;


export const NotificationContainer = styled.div`
  padding: 15px 0 0 0 ;
`;

export const MenuItemOption = styled.div`
  color: ${(props) => props?.theme?.colors.grey[100]};
  font-size: ${(props) => props?.theme?.typography.large20};
  line-height: ${(props) => props?.theme?.typography.large24}; 
`;

export const LineBreakOptionMenu = styled.div`
  border-width: 1px 0 0 0;  
  border-color: #F4BF5D;
  border-style: solid;
  margin: 0.8rem 0.5rem;
`;

export const ClearContainer = styled.div`
  position: absolute;
  top: 10px;
  right: 20px;
  &:hover {    
    cursor: pointer;
  }
`;

type ActivityContainerProps = {
  clickEnabled?: boolean;
};

export const ActivityContainer = styled.div<ActivityContainerProps>`
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5rem;
  cursor: ${(props) => (props?.clickEnabled ? 'pointer' : 'auto')};
`;

export const ActivityBody = styled.div`
  margin-left: 0.8rem;
  color: ${(props) => props?.theme?.colors.grey[90]};
  font-size: ${(props) => props?.theme?.typography.large16};
  line-height: ${(props) => props?.theme?.typography.large20};
`;

export const ActivitySeparator = styled.div`
  box-sizing: border-box;
  height: 1px;
  border: 1px solid #f2f2f7;
  margin-bottom: 1rem;
`;
