import React, { useEffect, useRef } from 'react';
import { Document } from '@contentful/rich-text-types';

import useContentfulRenderer from 'hooks/useContentfulRenderer';
import { ContentPanel } from '../components';
import { useLocation } from 'react-router';

type LegalDocRenderProps = {
  document?: Document;
};

const LegalDocRender = ({ document }: LegalDocRenderProps) => {
  const location = useLocation();
  const contentPanelRef = useRef<HTMLDivElement>(null);

  const renderDoc = useContentfulRenderer(document, {
    darkMode: true,
  });

  useEffect(() => {
    contentPanelRef.current?.scrollTo(0, 0);
  }, [location.pathname]);

  return <ContentPanel ref={contentPanelRef}>{renderDoc}</ContentPanel>;
};

export default LegalDocRender;
