import styled from '@emotion/styled';
import ButtonPrimary from 'components/button-primary';
import { ScrollableArea } from 'components/components';
import { ButtonsRow } from 'modules/relationships/components';

type PlaylistCardContainerProps = {
  noMarginBottom: boolean;
};

export const PlaylistCardContainer = styled.div<PlaylistCardContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 12.2rem;
  width: 100%;
  background-color: rgba(26, 26, 26, 0.8);
  margin: ${(props) =>
    props.noMarginBottom ? '0 0.8rem 0 0.8rem' : '0 0.8rem 1.7rem  0.8rem'};
  border-radius: 0.6rem;
  padding: 1rem;
  cursor: pointer;

  @media (min-width: ${(props) => props?.theme?.breakpoints?.desktopLarge}px) {
    width: calc(50% - 1.6rem);
  }
`;

export const PlaylistCardTitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const PlaylistCardDetailsRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const PlaylistCardTitle = styled.div`
  flex: 1;
  color: #ffffff;
  font-size: ${(props) => props?.theme?.typography.large24};
  line-height: ${(props) => props?.theme?.typography.large32};
  margin-right: 3rem;
`;

export const PlaylistCardSeparator = styled.div`
  box-sizing: border-box;
  height: 1px;
  border: 1px solid rgba(255, 255, 255, 0.6);
`;

export const PlaylistCardIcons = styled.div``;

export const PlaylistCardIcon = styled.img`
  margin-right: 1rem;
`;

export const PlaylistCardDetails = styled.div`
  display: flex;
`;

export const PlaylistCardDetailItem = styled.div`
  display: flex;
  align-items: center;
  color: rgba(255, 255, 255, 0.6);
  font-size: ${(props) => props?.theme?.typography.large18};
  line-height: ${(props) => props?.theme?.typography.large24};
  margin-left: 1.6rem;
`;

export const PlaylisCardTime = styled.div`
  margin-left: 0.5rem;
`;

export const ConfirmModalPropmt = styled.div`
  font-size: ${(props) => props?.theme?.typography.large20};
  line-height: ${(props) => props?.theme?.typography.large24};
  margin-bottom: 2rem;
  margin-top: 1rem;
  text-align: left;
  color: ${(props) => props?.theme?.colors.dark[100]};
`;

export const PlaylistModalContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const PlaylistModalReading = styled(ScrollableArea)`
  flex: 1;
  margin-bottom: 1rem;
  padding-right: 0.5rem;
  flex-basis: 80;
`;

export const PlaylistModalAudioTitle = styled.div`
  font-size: ${(props) => props?.theme?.typography.large20};
  line-height: ${(props) => props?.theme?.typography.large24};
  color: ${(props) => props?.theme?.colors.blue1[100]};
  margin-bottom: 0.5rem;
`;

export const PlaylistReadingAudio = styled.audio`
  margin-bottom: 1rem;
`;

export const CancelPlaylistButton = styled(ButtonPrimary)`
  background-color: ${(props) => props?.theme?.colors.grey[90]};
`;
export const ConfirmPlaylistButton = styled(ButtonPrimary)`
  background-color: ${(props) => props?.theme?.colors.green[100]};
`;
export const ButtonsPlaylistRow = styled(ButtonsRow)`
  gap: 10px;
`;
