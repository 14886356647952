import React, { ReactNode } from 'react'
import { ClearContainer } from '../components'
import { ClearIcon } from './ClearIcon'

type NotificationMainContainerType = {
    closeAction: () => void
    children: ReactNode
}

export const NotificationMainContainer = ({ closeAction, children }: NotificationMainContainerType) => {
    return (
        <>
            <ClearContainer onClick={closeAction}>
                <ClearIcon />
            </ClearContainer>
            {children}
        </>
    )
}
