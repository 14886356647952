import styled from '@emotion/styled';
import ButtonPrimary from 'components/button-primary';
import { Panel, ScrollableArea, TextArea } from 'components/components';
import { Form } from 'formik';
import { SearchBox } from 'modules/relationships/components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${(props) => props?.theme?.colors.challengeBackground};
  min-height: calc(100vh - 4.5rem);
`;

export const ChallengeContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 1100px;
  max-width: 90%;
  margin-top: 50px;
`;

export const ChallengeHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const ChallengeTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ChallengeTitle = styled.div`
  color: ${(props) => props?.theme?.colors.blue1[100]};
  font-size: ${(props) => props?.theme?.typography.large32};
`;

export const ChallengeSubTitle = styled.div`
  font-size: ${(props) => props?.theme?.typography.large16};
  margin-bottom: 1rem;
`;

export const ChallengeStepTitle = styled.div`
  font-size: ${(props) => props?.theme?.typography.large24};
  margin-bottom: 1.6rem;
`;

export const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const StepButton = styled(ButtonPrimary)`
  margin: 1rem 0 2rem 0;
  width: 30%;
  align-self: center;
`;

export const StepCompletionError = styled.div`
  color: ${(props) => props?.theme?.colors.red[70]};
  width: 100%;
  text-align: center;
`;

export const ReturnToHomeButton = styled(StepButton)`
  background-color: ${(props) => props?.theme?.colors.blue2[100]};
  width: 100%;
`;

export const SectionContainer = styled(Panel)`
  width: 100%;
  padding: 30px;
  margin-bottom: 0.8rem;
`;

export const CelebrateForm = styled.form`
  display: flex;
  flex-direction: column;
`;

export const CelebrateFormTextArea = styled(TextArea)`
  margin-bottom: 30px;
`;

export const CelebrateFileUploadContainer = styled.div`
  margin-bottom: 30px;
`;

export const CelebrateFileUploadText = styled.div`
  margin-bottom: 10px;
`;

export const CelebrateErrorMessage = styled.div`
  color: ${(props) => props?.theme?.colors.red[80]};
`;

export const CelebrateButton = styled(StepButton)`
  align-self: center;
  width: ${(props) => (props?.small ? '30%' : '100%')};
`;

export const CelebrateAppletTitle = styled.div`
  font-size: ${(props) => props?.theme?.typography.large24};
  line-height: ${(props) => props?.theme?.typography.large32};
  margin-bottom: 1rem;
`;

export const ChallengeDetailsContainer = styled.div`
  display: flex;
`;

export const ChallengeDetailsItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 10px;
`;

export const ChallengeDetailItemText = styled.div`
  color: #8e8e93;
  font-family: 'Open Sans';
  font-size: ${(props) => props?.theme?.typography.large20};
  letter-spacing: 0;
  line-height: 18px;
`;

export const StepResourcesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-self: center;
  width: 350px;
  max-width: 90%;
`;

type StepResourceButtonProps = { disabled: boolean };

export const StepResourceButton = styled.div<StepResourceButtonProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 54px;
  cursor: pointer;
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
`;

export const StepResourceIcon = styled.div``;

type StepResourceTextProps = {
  disabled: boolean;
};

export const StepResourceText = styled.div<StepResourceTextProps>`
  color: ${(props) =>
    props.disabled
      ? props?.theme?.colors.grey[90]
      : props?.theme?.colors.blue2[100]};
  font-size: ${(props) => props?.theme?.typography.large20};
  line-height: ${(props) => props?.theme?.typography.large18};
`;

export const CongratulationsModalContent = styled(ScrollableArea)`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  max-height: 90%;
  width: 516px;
  border: 0.5px solid #979797;
  border-radius: 12px;
  box-shadow: 0 1px 35px 0 rgba(0, 0, 0, 0.6);
  background-color: ${(props) => props?.theme?.colors.blue3[100]};
`;

export const CongratulationsTopContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: ${(props) => props?.theme?.colors.blue3[100]};
`;

export const CongratulationsArt = styled.img`
  height: 270px;
`;

export const CongratulationsBadge = styled.img`
  height: 41px;
  margin-top: -21px;
`;

export const CongratulationsTitle = styled.div`
  color: ${(props) => props?.theme?.colors.gold[100]};
  font-size: ${(props) => props?.theme?.typography.large24};
  line-height: ${(props) => props?.theme?.typography.large32};
  font-weight: ${(props) => props?.theme?.typography.semiBold};
  margin-top: 14px;
`;

export const CongratulationsExperienceText = styled.div`
  color: ${(props) => props?.theme?.colors.white[100]};
  font-size: ${(props) => props?.theme?.typography.large20};
  line-height: ${(props) => props?.theme?.typography.large18};
  margin-top: 6px;
`;

export const CongratulationsSeparator = styled.div`
  box-sizing: border-box;
  height: 1px;
  width: 64px;
  border: 0.5px solid #ffffff;
  margin-top: 20px;
`;

export const CongratulationsText = styled.div`
  width: 359px;
  color: ${(props) => props?.theme?.colors.white[100]};
  text-align: center;
  margin-top: 24px;
  margin-bottom: 27px;
`;

export const CongratulationsBottomContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  border-radius: 1rem;
  background-color: #ffffff;
  margin-bottom: 1.5rem;
  padding: 0rem 1rem 1rem;
  cursor: pointer;
`;

export const CongratulationsNextText = styled.div`
  color: ${(props) => props?.theme?.colors.grey[100]};
  font-size: ${(props) => props?.theme?.typography.large24};
  line-height: ${(props) => props?.theme?.typography.large18};
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
`;

export const ShareModalContent = styled(Form)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const ShareModalSearchBox = styled(SearchBox)`
  margin-bottom: 1rem;
`;

export const SharePrompt = styled.div`
  margin-bottom: 1rem;
`;

export const CancelButton = styled(ButtonPrimary)`
  background-color: ${(props) => props?.theme?.colors.grey[90]};
  margin: 0 1rem 0 0;
`;

export const ShareButton = styled(ButtonPrimary)`
  margin: 0;
`;

export const SimpleLinkCopied = styled.p`  
  flex: 1;
  text-align: center;   
  margin: 1rem 0 0 0;
  color: ${(props) => props?.theme?.colors.grey[90]};  
`;

export const SimpleLink = styled(SimpleLinkCopied)`  
  color: ${(props) => props?.theme?.colors.green[90]};
  cursor: pointer;
`;

export const CongratulationsButtonsContainer = styled.div`
  width: 95%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`;

export const SingleButtonContainer = styled.div`
  width: 50%;
  padding-right: 1rem;
  padding-left: 1rem;
`;

export const NextChallengeInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const NextChallengeTitle = styled.div`
  color: #f4bf5d;
  font-size: ${(props) => props?.theme?.typography.large20};
  font-weight: ${(props) => props?.theme?.typography.bold};
`;


export const NextChallengeContainerBackground = styled.div`
  background-color: #00000075;
  padding: 0.8rem;
  border-radius: 1rem;
`;


export const NextStepTitle = styled.div`
  font-size: ${(props) => props?.theme?.typography.large20};
  color: #ffffff;
`;

export const NextChallengeIconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

type NextChallengeCardContainerProps = {
  imageUrl: string;
};

export const NextChallengeCardContainer = styled.div<NextChallengeCardContainerProps>`
  display: flex;
  cursor: pointer;
  background-image: linear-gradient(
      180deg,
      #1a1a1a 0%,
      rgba(26, 26, 26, 0) 51.45%,
      #1a1a1a 100%
    ),
    url(${(props) => props.imageUrl});
  height: 230px;
  width: 100%;
  border-radius: 1rem;
  background-size: cover;
`;

export const ChallengeTitlesContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Text = styled.div`
  color: ${(props) => props?.theme?.colors.white[100]};
  font-size: ${(props) => props?.theme?.typography.large18};
  margin-left: 0.8rem;
`;

export const PointsContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const TimeContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 12px;
`;

export const ChallengeIconsRow = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const ChallengeMobileContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const NextStepsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5rem;
`;

export const TotalStepsContainer = styled.div`
  padding-left: 0.5rem;
  color: ${(props) => props?.theme?.colors.grey[90]};
`;

export const ChallengeContentContainer = styled.div`
  width: 100%;
`;

export const ChallengeSubtitle = styled.div`
  color: ${(props) => props?.theme?.colors.white[100]};
  font-size: ${(props) => props?.theme?.typography.large20};
  font-style: italic;
  margin-right: 4rem;
  padding: 0.8rem;
`;

export const MadlibQuestionContainer = styled.div`
  border: 0.5px solid #979797;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1.7rem;
`;
