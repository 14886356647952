import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { SubmitCommitPayload, WeekDays } from './typings';
import {
  submitScheduleCommit as submitScheduleCommitService,
  getScheduleCommit as getScheduleCommitService,
  editSchedule as editScheduleService,
} from './services';

const ADD_HOUR = 'ADD_HOUR';
export const addHour = createAction<WeekDays>(ADD_HOUR);

const REMOVE_HOUR = 'REMOVE_HOUR';
export const removeHour = createAction<WeekDays>(REMOVE_HOUR);

const SUBMIT_SCHEDULE_COMMIT = 'SUBMIT_SCHEDULE_COMMIT';
export const submitScheduleCommit = createAsyncThunk<void, SubmitCommitPayload>(
  SUBMIT_SCHEDULE_COMMIT,
  submitScheduleCommitService,
);

const GET_SCHEDULE_COMMIT = 'GET_SCHEDULE_COMMIT';
export const getScheduleCommit = createAsyncThunk(
  GET_SCHEDULE_COMMIT,
  async ({ questId }: { questId: string }, thunkApi) => {
    const response = await getScheduleCommitService(questId);
    if (response.status === 'success') {
      return response.data;
    } else {
      const { errorCode, errorInformation } = response;
      return thunkApi.rejectWithValue({ errorCode, errorInformation });
    }
  },
);

const SELECT_WEEK_CONFIG_BY_DATE = 'SELECT_WEEK_CONFIG_BY_DATE';
export const selectWeekConfigByDate = createAction<Date>(
  SELECT_WEEK_CONFIG_BY_DATE,
);

const EDIT_SCHEDULE = 'EDIT_SCHEDULE';
export const editSchedule = createAsyncThunk(
  EDIT_SCHEDULE,
  editScheduleService,
);

const RESET_SCHEDULE_COMMIT = 'RESET_SCHEDULE_COMMIT';
export const resetScheduleCommit = createAction(RESET_SCHEDULE_COMMIT);
