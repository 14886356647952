import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  getActiveQuest as getActiveQuestService,
  getOldQuests as getOldQuestsService,
} from './services';

const GET_ACTIVE_QUEST = 'GET_ACTIVE_QUEST';
export const getActiveQuest = createAsyncThunk(
  GET_ACTIVE_QUEST,
  async (_data: undefined, thunkApi) => {
    const response = await getActiveQuestService();
    if (response.status === 'success') {
      return response.data;
    } else {
      const { errorCode, errorInformation } = response;
      return thunkApi.rejectWithValue({ errorCode, errorInformation });
    }
  },
);

const GET_OLD_QUESTS = 'GET_OLD_QUESTS';
export const getOldQuests = createAsyncThunk(
  GET_OLD_QUESTS,
  getOldQuestsService,
);

const SET_LOADING_FIRST_FINISHED = 'SET_LOADING_FIRST_FINISHED';
export const setLoadingFirstFinished = createAction<boolean>(SET_LOADING_FIRST_FINISHED);

const SET_LOADING_LAST_FINISHED = 'SET_LOADING_LAST_FINISHED';
export const setLoadingLastFinished = createAction<boolean>(SET_LOADING_LAST_FINISHED);

const SET_LOADING_HOME_FINISHED = 'SET_LOADING_HOME_FINISHED';
export const setLoadingHomeFinished = createAction<boolean>(SET_LOADING_HOME_FINISHED);

const SET_LOADING_FINISHED = 'SET_LOADING_FINISHED';
export const setLoadingFinished = createAction<boolean>(SET_LOADING_FINISHED);

const SET_LOADING_ADDITIONAL_FINISHED = 'SET_LOADING_ADDITIONAL_FINISHED';
export const setLoadingAdditionalFinished = createAction<boolean>(SET_LOADING_ADDITIONAL_FINISHED);

const RESET_HAS_ADDED_QUEST = 'RESET_HAS_ADDED_QUEST';
export const resetHasAddedQuest = createAction(RESET_HAS_ADDED_QUEST);

const RESET_COMPLETE_QUEST_ADDED = 'RESET_COMPLETE_QUEST_ADDED';
export const resetCompleteQuestAdded = createAction(RESET_COMPLETE_QUEST_ADDED);

const SET_LOADING_SCHEDULE_COMMIT_FINISHED = 'SET_LOADING_SCHEDULE_COMMIT_FINISHED';
export const setLoadingScheduleCommitFinished = createAction<boolean>(SET_LOADING_SCHEDULE_COMMIT_FINISHED);
