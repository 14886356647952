import { createAsyncThunk } from '@reduxjs/toolkit';

import { getSteps as getStepsService } from './services';
import { StepContentful } from 'models/StepContentful';

const GET_STEPS = 'GET_STEPS';
export const getSteps = createAsyncThunk<StepContentful[], string | undefined>(
  GET_STEPS,
  getStepsService,
);
