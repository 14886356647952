import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'redux/typings';

import {
  checkHasCompletedAnswer,
  checkHasCompletedSliderQuestions,
  checkHasEmptyDecisionBranch,
  checkHasEmptyMadlibs,
  checkShareModalAppletCompleted,
} from 'helpers/AppletHelpers';
import { selectStepAppletIds } from 'modules/loading-screen/contents/selectors';
import { selectAppletsContentful } from 'modules/loading-screen/contentful/applets/selectors';
import { AppletUpload } from './typings';
import { ShareStepAppletInformation } from 'modules/applets/typings';

const selectAppletUploadState = (state: RootState) => state.appletUpload;

export const selectAppletById = (appletId: string) =>
  createSelector(
    selectAppletUploadState,
    (appletUpload) => appletUpload.list[appletId]
  );

export const selectQuizQuestionValue = (
  appletId: string,
  quizQuestionContentfulId: string,
) =>
  createSelector(
    selectAppletById(appletId),
    (applet) => applet?.quizInformation?.questionsMap[quizQuestionContentfulId],
  );

export const selectBranchInformation = (appletId: string, fileId: string) =>
  createSelector(
    selectAppletById(appletId),
    (applet) => applet?.files?.[fileId],
  );

export const selectAppletAnswers = createSelector(
  selectAppletUploadState,
  (appletUpload) => appletUpload.list,
);

export const selectRichTextAsset = createSelector(
  selectAppletUploadState,
  (appletUpload) => (appletUpload ? appletUpload.richText : undefined),
);

export const selectAppletsModified = createSelector(
  selectAppletUploadState,
  (appletUploadState) => appletUploadState.modified,
);

export const selectUserHasCompletedSomeApplets = createSelector(
  selectAppletAnswers,
  selectStepAppletIds,
  (appletAnswers, appletIds) =>
    appletIds.some(
      (appletId) =>
        appletAnswers[appletId]?.file ||
        appletAnswers[appletId]?.richText ||
        appletAnswers[appletId]?.text ||
        appletAnswers[appletId]?.quizInformation ||
        appletAnswers[appletId]?.files,
    ),
);

export const selectHasStepApplets = createSelector(selectStepAppletIds, stepAppletIds => stepAppletIds.length !== 0)

export const selectUserHasCompletedRequiredApplets = createSelector(
  selectAppletAnswers,
  selectStepAppletIds,
  selectAppletsContentful,
  (appletAnswers, stepAppletIds, appletsContentful) => {
    const stepAppletIdsMap = stepAppletIds.reduce((acc, appId) => {
      const ret = {
        ...acc,
        [appId]: true,
      };

      return ret;
    }, {});


    const stepAppletsAnswers: AppletUpload[] = [];
    const extraAppletsAnswers: AppletUpload[] = [];

    for (const appAns of Object.values(appletAnswers)) {
      if (stepAppletIdsMap[appAns.appletId]) {
        stepAppletsAnswers.push(appAns);
      } else {
        extraAppletsAnswers.push(appAns);
      }
    }

    if (stepAppletsAnswers.length < stepAppletIds.length) {
      return false;
    }

    let isExtraApplet = false;
    const hasCompletedAllStepApplets = stepAppletsAnswers.every((ans) =>
      checkHasCompletedAnswer(
        appletAnswers,
        appletsContentful,
        ans,
        isExtraApplet,
      ),
    );

    isExtraApplet = true;
    const hasCompletedAllExtraApplets = extraAppletsAnswers.every((ans) =>
      checkHasCompletedAnswer(
        appletAnswers,
        appletsContentful,
        ans,
        isExtraApplet,
      ),
    );

    return hasCompletedAllStepApplets && hasCompletedAllExtraApplets;
  },
);

export const selectUserHasCompletedOptionalApplets = createSelector(
  selectAppletAnswers,
  selectStepAppletIds,
  selectAppletsContentful,
  (appletAnswers, appletIds, appletsContentful) =>
    appletIds.every((appletId) => {
      const applet = appletAnswers[appletId];
      const { appletType } = applet || {};
      switch (appletType) {
        case 'quiz':
          return checkHasCompletedSliderQuestions(
            appletAnswers,
            appletsContentful[appletId],
          );
        default:
          return true;
      }
    }),
);

export const selectHasUserEmptyApplets = createSelector(
  selectAppletAnswers,
  (appletAnswers) => {
    return !Object.keys(appletAnswers).every(
      (appletId) =>
        appletAnswers[appletId]?.file ||
        appletAnswers[appletId]?.richText ||
        appletAnswers[appletId]?.text ||
        appletAnswers[appletId]?.quizInformation?.questionsMap ||
        checkHasEmptyDecisionBranch(appletAnswers[appletId]) ||
        appletAnswers[appletId]?.options ||
        checkHasEmptyMadlibs(appletAnswers[appletId]),
    );
  },
);

export const selectAppletsForShareModal = createSelector(
  selectStepAppletIds,
  selectAppletAnswers,
  selectAppletsContentful,
  (stepAppletIds, appletAnswers, appletsContentful) => {
    return stepAppletIds
      .filter((appId) => appletsContentful[appId]?.sendButton)
      .map((appletId) => {
        const answeredApplet = appletAnswers[appletId];
        return {
          appletContentfulId: appletId,
          type: appletsContentful[appletId]?.type,
          file: answeredApplet?.file,
          richText: answeredApplet?.richText,
          madlibAnswers: answeredApplet?.madlibAnswers
            ? Object.values(answeredApplet.madlibAnswers)
            : undefined,
        } as ShareStepAppletInformation;
      });
  },
);

export const selectHasCompletedAppletsForShareModal = createSelector(
  selectAppletsForShareModal,
  (applets) => applets.every((app) => checkShareModalAppletCompleted(app)),
);

export const selectAppletByInputId = (appletId: string, inputId: string) =>
  createSelector(
    selectAppletById(appletId),
    (applets) => applets?.madlibAnswers?.[inputId]?.answer
  );