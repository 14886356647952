import { UploadFile } from 'components/applets/app-upload';

export const PDF_EXTENSIONS = '.pdf';
export const PDF_EXTENSIONS_LIST = PDF_EXTENSIONS.split(' ');
export const PDF_MIMETYPE = 'application/pdf';

export const AUDIO_EXTENSIONS =
  '.3g2 .3gp .aac .adt .adts .aif .aifc .aiff .asf .au .m3u .m4a .m4b .mid .midi .mp2 .mp3 .mp4 .rmi .snd .wav .wax .wma';
export const AUDIO_EXTENSIONS_LIST = AUDIO_EXTENSIONS.split(' ');
export const AUDIO_MIMETYPE = 'audio/*';

export const IMAGE_EXTENSIONS = '.jpeg .jpg .webp';
export const IMAGE_EXTENSIONS_LIST = [
  ...IMAGE_EXTENSIONS.split(' '),
  '.heif',
  '.heic',
  '.heif',
  '.heics',
];
export const IMAGE_MIMETYPE = 'image/*';

export const VIDEO_EXTENSIONS = '.mp4 .mov';
export const VIDEO_EXTENSIONS_LIST = VIDEO_EXTENSIONS.split(' ');
export const VIDEO_MIMETYPE = 'video/*';

export const TEXT_EXTENSIONS = '.txt';
export const TEXT_MIMETYPE = 'text/plain';

export const getFileObject = (file?: any): UploadFile | undefined => {
  if (!file || (!file.uri && !file.fileUrl)) {
    return undefined;
  }

  const fileUrl = file.uri || file.fileUrl;
  const fileType = file.fileType || file.type;

  const name = file.fileName || file.name || `NGA_FILE_${Date.now()}`;
  let type: string;

  if (!fileType) {
    switch (true) {
      case PDF_EXTENSIONS_LIST.some((pdfExt) => fileUrl.includes(pdfExt)):
        type = PDF_MIMETYPE;
        break;
      case AUDIO_EXTENSIONS_LIST.some((audioExt) => fileUrl.includes(audioExt)):
        type = AUDIO_MIMETYPE;
        break;
      case IMAGE_EXTENSIONS_LIST.some((imageExt) => fileUrl.includes(imageExt)):
        type = IMAGE_MIMETYPE;
        break;
      case [...VIDEO_EXTENSIONS_LIST, '.m4v', '.mov'].some((videoExt) =>
        fileUrl.includes(videoExt),
      ):
        type = VIDEO_MIMETYPE;
        break;
      case fileUrl.includes(TEXT_EXTENSIONS):
        type = TEXT_MIMETYPE;
        break;
      default:
        type = '*/*';
        break;
    }
  } else {
    switch (true) {
      case fileType.includes('pdf'):
        type = PDF_MIMETYPE;
        break;
      case fileType.includes('audio'):
        type = AUDIO_MIMETYPE;
        break;
      case fileType.includes('image'):
        type = IMAGE_MIMETYPE;
        break;
      case fileType.includes('video') || fileType.includes('movie'):
        type = VIDEO_MIMETYPE;
        break;
      case fileType.includes('text'):
        type = TEXT_MIMETYPE;
        break;
      default:
        type = '*/*';
        break;
    }
  }

  return {
    mime: file.type ?? file.mime,
    name,
    type,
    uri: fileUrl.startsWith('file://') ? decodeURI(fileUrl) : fileUrl,
  };
};
