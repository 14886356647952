import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Formik, FormikProps } from 'formik';

import Modal from 'components/modal';
import localizedStrings from 'localization';
import InputField from 'components/input-field';
import { CancelButton } from 'modules/challenge/components';
import {
  AddQuestFormButtons,
  AddQuestPrompt,
  ErrorMessage,
  StartButton,
} from 'modules/settings/components';
import { AppDispatch } from 'redux/typings';
import {
  addCohort,
  getCohortStartingDate,
  userCohortExists,
} from 'modules/settings/actions';
import useActionStatus from 'hooks/useActionStatus';
import useErrorMessage from 'hooks/useErrorMessage';
import { addCohortSchema } from './validationSchema';
import { useHistory } from 'react-router';
import { HOME } from 'routes';
import Dialog from 'components/dialog';
import useCohortConfirmationDate from 'hooks/useCohortConfirmationDate';
import { UserLocalDate } from 'models/UserLocalDate';
import { DialogConfirmButtons } from '../../../../components/dialog/components';
import {
  DialogNoButton,
  DialogYesButton,
} from '../../../../components/dialog/components';

type AddQuestModalProps = {
  open: boolean;
  close: () => void;
  stateQuest: boolean;
};

const AddQuestModal = ({ open, close, stateQuest }: AddQuestModalProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [isNewQuestPrompt, setIsNewQuestPrompt] = useState(false);
  const formikRef = useRef<FormikProps<any>>(null);
  const [isLoading, wasFulfilled] = useActionStatus(addCohort);
  const [isPending] = useActionStatus(getCohortStartingDate);

  const [errorMessage, setErrorMessage] = useErrorMessage();

  const onFinalSubmit = useCallback(
    (questCode: string, userLocalDate: UserLocalDate) => {
      dispatch(addCohort({ enrollmentCode: questCode, userLocalDate }));
      dispatch(userCohortExists({ enrollmentCode: questCode, exist: false }));
      setHasSubmitted(true);
      setErrorMessage('');
    },
    [dispatch, setErrorMessage],
  );

  const [
    dateDialogOpen,
    ,
    setHasInitiallySubmitted,
    setValuesToSubmit,
    handleDateDialog,
  ] = useCohortConfirmationDate<string>(onFinalSubmit);

  const handleSubmit = useCallback(
    ({ questCode }: { questCode: string }) => {
      dispatch(getCohortStartingDate(questCode)).catch(() =>
        setHasInitiallySubmitted(false),
      );
      setHasInitiallySubmitted(true);
      setValuesToSubmit(questCode);
    },
    [dispatch, setHasInitiallySubmitted, setValuesToSubmit],
  );

  const handleClose = useCallback(() => {
    formikRef.current?.resetForm();
    setErrorMessage('');
    setIsNewQuestPrompt(false);
    close();
  }, [close, setErrorMessage, setIsNewQuestPrompt]);

  const handleYes = useCallback(() => {
    setIsNewQuestPrompt(true);
  }, [setIsNewQuestPrompt]);

  useEffect(() => {
    if (hasSubmitted && wasFulfilled) {
      history.push(HOME);
      handleClose();
    }
  }, [dispatch, handleClose, hasSubmitted, history, wasFulfilled]);

  return (
    <>
      <Modal
        open={open}
        width={'500px'}
        title={localizedStrings.newQuest}
        hideCloseButton>
        {!isNewQuestPrompt && (
          <>
            <AddQuestPrompt>
              {stateQuest
                ? localizedStrings.newQuestAffirmCompleteState
                : localizedStrings.newQuestAffirmIncompleteState}
            </AddQuestPrompt>
            <DialogConfirmButtons>
              <DialogNoButton onClick={handleClose}>
                {localizedStrings.no}
              </DialogNoButton>
              <DialogYesButton onClick={handleYes}>
                {localizedStrings.yes}
              </DialogYesButton>
            </DialogConfirmButtons>
          </>
        )}

        {isNewQuestPrompt && (
          <>
            <AddQuestPrompt>{localizedStrings.newQuestPrompt}</AddQuestPrompt>
            <Formik
              innerRef={formikRef}
              initialValues={{ questCode: '' }}
              onSubmit={handleSubmit}
              validationSchema={addCohortSchema}>
              <Form>
                <InputField
                  id="questCode"
                  name="questCode"
                  placeholder={localizedStrings.questCode}
                />
                <ErrorMessage>{errorMessage}</ErrorMessage>
                <AddQuestFormButtons>
                  <CancelButton type="button" onClick={handleClose}>
                    {localizedStrings.cancel}
                  </CancelButton>
                  <StartButton type="submit" isLoading={isLoading || isPending}>
                    {localizedStrings.start}
                  </StartButton>
                </AddQuestFormButtons>
              </Form>
            </Formik>
          </>
        )}
      </Modal>
      <Dialog
        onNoClick={handleDateDialog(false)}
        onYesClick={handleDateDialog(true)}
        open={dateDialogOpen}
        title={localizedStrings.startOnSameWeek}
        prompt={localizedStrings.startOnSameWeekPrompt}
        yesText={localizedStrings.startLate}
        noText={localizedStrings.reschedule}
      />
    </>
  );
};

export default AddQuestModal;
