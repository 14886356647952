import { useMemo } from 'react';

import localizedStrings from 'localization';
import {
  AchievementCardContainer,
  AchievementCardImage,
  AchievementCardTitleAndRating,
  AchievementCardTitle,
  AchievementCardRating,
  AchievementCardReviews,
} from 'modules/profile/components';
import Rating from 'react-rating';
import StarEmpty from './StarEmpty';
import StarFilled from './StartFilled';
import AchievementBadge from './Badge';
import { ContentContentful } from 'models/ContentContentful';

type ChallengeAchievementCardProps = {
  contentContentful?: ContentContentful;
  feedbackAverage: number;
  xp: number;
  bp: number;
  feedbacksLength?: number;
  onClick: () => void;
};

const ChallengeAchievementCard = ({
  contentContentful,
  feedbackAverage,
  xp,
  bp,
  feedbacksLength,
  onClick,
}: ChallengeAchievementCardProps) => {
  const ribbonColor = useMemo(() => (bp > 0 ? 'blue' : 'red'), [bp]);

  return (
    <AchievementCardContainer onClick={onClick}>
      <AchievementCardImage
        src={contentContentful?.imageUrl}
        alt={contentContentful?.title}
      />
      <AchievementCardTitleAndRating>
        <AchievementCardTitle>{contentContentful?.title}</AchievementCardTitle>
        <AchievementCardRating>
          <Rating
            readonly={true}
            initialRating={feedbackAverage}
            fullSymbol={<StarFilled />}
            emptySymbol={<StarEmpty />}
          />
          <AchievementCardReviews>{`${feedbacksLength} ${localizedStrings.reviews}`}</AchievementCardReviews>
        </AchievementCardRating>
      </AchievementCardTitleAndRating>
      <AchievementBadge ribbonColor={ribbonColor} xp={xp} bp={bp} />
    </AchievementCardContainer>
  );
};

export default ChallengeAchievementCard;
