import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import {
  Relationship,
  RelationshipStatus,
  RelationshipType,
} from 'models/Relationship.d';
import localizedStrings from 'localization';
import {
  AcceptButton,
  AvatarContainer,
  CancelButton,
  LastActivity,
  LastActivityAndUnreadMessagesContainer,
  RelationshipCardContainer,
  RelationshipSeparator,
  UnreadMessages,
  UserName,
  UserNameAndStatusContainer,
  UserStatus,
  ViewProfileButton,
} from '../components';
import Avatar from 'components/avatar';
import { selectEmail } from 'modules/auth/selectors';
import ChevronRight from 'components/icons/ChevronRight';
import { AddOptions } from './modals/AddModal';
import { formatDateRelative } from 'helpers/dateHelpers';

type RelationshipCardProps = {
  relationship: Relationship;
  type?: 'chat' | 'invite' | 'share' | 'shareForm';
  option?: AddOptions;
  isLast?: boolean;
  onCardClick?: (relationship: Relationship) => void;
  onInvitationAccept?: (relationship: Relationship) => void;
  onInvitationCancel?: (relationship: Relationship) => void;
  onInvite?: (relationship: Relationship) => void;
  onViewProfile?: (relationship: Relationship) => void;
};

const RelationshipCard = ({
  relationship,
  relationship: {
    id,
    relatedUser: { displayName, profilePictureUrl },
    relationshipStatus,
    relationshipType,
    unreadMessages,
    updatedAt,
  },
  type,
  option,
  isLast = false,
  onCardClick = () => {},
  onInvitationAccept = () => {},
  onInvitationCancel = () => {},
  onInvite = () => {},
  onViewProfile = () => {},
}: RelationshipCardProps) => {
  const currentUserEmail = useSelector(selectEmail);
  const invitationReceived = useMemo(
    () => relationshipStatus === RelationshipStatus.RECEIVED,
    [relationshipStatus],
  );

  const invitationPending = useMemo(
    () => relationshipStatus === RelationshipStatus.SENT,
    [relationshipStatus],
  );

  const isExternal = useMemo(
    () =>
      relationshipType === RelationshipType.EXTERNAL_MENTOR ||
      relationshipType === RelationshipType.EXTERNAL_RUNNING_PARTNER,
    [relationshipType],
  );

  const displayRelationshipType = useMemo(() => {
    if (
      relationshipType === RelationshipType.EXTERNAL_MENTOR ||
      relationshipType === RelationshipType.MENTOR
    ) {
      return relationship.inviteeEmail === currentUserEmail
        ? 'Mentoring'
        : relationshipType;
    } else {
      return relationshipType;
    }
  }, [currentUserEmail, relationship.inviteeEmail, relationshipType]);

  const onClickDisabled = useMemo(
    () =>
      type === 'chat' ||
      type === 'invite' ||
      type === 'shareForm' ||
      (isExternal && type !== 'share'),
    [isExternal, type],
  );

  const handleCardClick = useCallback(() => {
    if (!onClickDisabled) {
      onCardClick(relationship);
    }
  }, [onClickDisabled, onCardClick, relationship]);

  const handleCancelInvite = useCallback(() => {
    onInvitationCancel(relationship);
  }, [onInvitationCancel, relationship]);

  const handleAcceptInvite = useCallback(() => {
    onInvitationAccept(relationship);
  }, [onInvitationAccept, relationship]);

  const handleInvite = useCallback(() => {
    onInvite(relationship);
  }, [onInvite, relationship]);

  const handleViewProfile = useCallback(() => {
    onViewProfile(relationship);
  }, [onViewProfile, relationship]);

  const renderRightSide = useMemo(() => {
    switch (type) {
      case 'chat':
        return (
          <ViewProfileButton onClick={handleViewProfile}>
            {localizedStrings.profile}
            <ChevronRight />
          </ViewProfileButton>
        );
      case 'invite':
        return (
          <AcceptButton onClick={handleInvite}>
            {option === AddOptions.ADD_MENTOR && localizedStrings.add}
            {option === AddOptions.ADD_RUNNING_PARTNER && localizedStrings.pair}
          </AcceptButton>
        );
      case 'share':
      case 'shareForm':
        return null;
      default:
        if (invitationReceived) {
          return (
            <>
              <CancelButton onClick={handleCancelInvite}>
                {localizedStrings.declineInvitation}
              </CancelButton>
              <AcceptButton onClick={handleAcceptInvite}>
                {localizedStrings.acceptInvitation}
              </AcceptButton>
            </>
          );
        }
        if (invitationPending) {
          return (
            <CancelButton onClick={handleCancelInvite}>
              {localizedStrings.cancelInvitation}
            </CancelButton>
          );
        }
        if (isExternal) {
          return null;
        }
        return (
          <LastActivityAndUnreadMessagesContainer>
            {updatedAt ? (
              <LastActivity>{formatDateRelative(updatedAt)}</LastActivity>
            ) : null}
            {unreadMessages > 0 ? (
              <UnreadMessages>{unreadMessages}</UnreadMessages>
            ) : null}
          </LastActivityAndUnreadMessagesContainer>
        );
    }
  }, [
    type,
    handleViewProfile,
    handleInvite,
    option,
    invitationReceived,
    invitationPending,
    isExternal,
    handleCancelInvite,
    handleAcceptInvite,
    unreadMessages,
    updatedAt,
  ]);

  return (
    <>
      <RelationshipCardContainer
        onClick={handleCardClick}
        pointer={!onClickDisabled}
        isLast={isLast}>
        <AvatarContainer>
          <Avatar displayName={displayName} uri={profilePictureUrl} size={40} />
        </AvatarContainer>
        <UserNameAndStatusContainer>
          <UserName>{displayName}</UserName>
          <UserStatus>
            {!invitationReceived && !invitationPending
              ? displayRelationshipType
              : relationshipStatus}
          </UserStatus>
        </UserNameAndStatusContainer>
        {renderRightSide}
      </RelationshipCardContainer>
      {!isLast && <RelationshipSeparator />}
    </>
  );
};

export default RelationshipCard;
