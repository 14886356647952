import React from 'react';

type DoneIconProps = {
  width: number | string;
  height: number | string;
};

const DoneIcon = ({ width, height }: DoneIconProps) => {
  return (
    <svg width={width} height={height} viewBox="0 0 18 18">
      <title>Done</title>
      <g id="V3" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="Quest---Active-user-1"
          transform="translate(-334.000000, -119.000000)"
          stroke="#FFFFFF">
          <g id="Group-33" transform="translate(335.000000, 120.000000)">
            <circle
              id="Oval-Copy-21"
              strokeWidth="1.5"
              cx="8"
              cy="8"
              r="8"></circle>
            <polyline
              id="Path-2"
              strokeWidth="1.2"
              strokeLinecap="round"
              strokeLinejoin="round"
              points="5 9 7.4 11 11 5"></polyline>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default DoneIcon;
