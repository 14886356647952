import React from 'react';
import QuizSection from '../quiz-section/QuizSection';
import useQuizChallengeLogic from 'hooks/useQuizChallengeLogic';
import QuizSectionDescriptionRenderer from '../quiz-section/QuizSectionDescriptionRenderer';
import QuizSectionQuestionsListChallenge from '../quiz-section/QuizSectionQuestionListChallenge';

type QuizSectionListChallengeProps = {
  appletId: string;
};

const QuizSectionListChallenge = ({
  appletId,
}: QuizSectionListChallengeProps) => {
  const { quiz, isAppletDisabled } = useQuizChallengeLogic(appletId);

  return (
    <>
      {quiz?.fields?.sections?.map((item) => {
        return (
          <QuizSection
            key={item.sys.id}
            title={item?.fields?.title}
            description={<QuizSectionDescriptionRenderer item={item} />}
            quizSectionQuestions={
              <QuizSectionQuestionsListChallenge
                quizContentfulId={quiz.sys.id}
                quizSection={item}
                appletId={appletId}
                disabled={isAppletDisabled}
              />
            }
          />
        );
      })}
    </>
  );
};

export default QuizSectionListChallenge;
