import { QuizQuestion } from 'models/Applet';
import { AppletContentful } from 'models/AppletContentful';
import { QuizQuestionAnswerType } from 'models/QuizQuestionAnswerType';
import { AppletUpload, QuizInformation } from 'modules/applet-upload/typings';
import { FileUploadWithId, UploadFileResponse } from 'modules/files/typings';
import {
  FileUploadedInformation,
  UpsertAppletUser,
} from 'modules/loading-screen/contents/typings';

export const getFilesToUpload = (
  applets: AppletUpload[],
): FileUploadWithId[] => {
  const filesToUpload: FileUploadWithId[] = [];
  for (const app of applets) {
    const { appletId, appletType, file, files, fileUploaded } = app;
    switch (appletType) {
      case 'upload-file':
      case 'upload-image':
      case 'upload-video':
        if (file && !fileUploaded) {
          filesToUpload.push({
            file: file,
            id: appletId,
            appletId: appletId,
          });
        }
        break;
      case 'decision-tree':
        const appFiles = Object.values(files || {});
        for (const branchFile of appFiles) {
          if (branchFile?.file && !branchFile.fileUploaded) {
            filesToUpload.push({
              file: branchFile.file,
              id: branchFile.fileId,
              appletId: appletId,
            });
          }
        }
        break;
    }
  }

  return filesToUpload;
};

export const getAppletsToSend = (
  fileUrls: UploadFileResponse[],
  applets: AppletUpload[],
  appletsContentful: Record<string, AppletContentful>,
): UpsertAppletUser[] => {
  const fileMap = fileUrls.reduce((acc, file) => {
    acc[file.id] = file;
    return acc;
  }, {} as Record<string, UploadFileResponse>);
  const ret: UpsertAppletUser[] = [];

  for (const app of applets) {
    const {
      id,
      appletId,
      appletType,
      file,
      richText,
      text,
      quizInformation,
      options,
      madlibAnswers,
    } = app;
    const appletContentful = appletsContentful[appletId];
    switch (appletType) {
      case 'upload-file':
      case 'upload-image':
      case 'upload-video':
        const appFile = fileMap[appletId];
        ret.push({
          id,
          appletId,
          type: appletType,
          fileUrl: appFile?.fileUrl ?? file?.uri,
          fileType: appFile?.fileType ?? file?.type,
        });
        break;
      case 'rich-text-entry':
        ret.push({
          id,
          appletId,
          type: appletType,
          richText,
        });
        break;
      case 'inline-text':
        ret.push({
          id,
          appletId,
          type: appletType,
          text,
        });
        break;
      case 'quiz':
        ret.push({
          id,
          appletId,
          type: appletType,
          quizQuestions: buildQuizQuestions(appletContentful, quizInformation),
        });
        break;
      case 'decision-tree':
        ret.push({
          id,
          appletId,
          type: appletType,
          files: buildDecisionTreeFiles(app, fileMap),
        });
        break;
      case 'rank':
        ret.push({
          id,
          appletId,
          type: appletType,
          options,
        });
        break;
      case 'madlib':
        ret.push({
          id,
          appletId,
          type: appletType,
          madlibAnswers: Object.values(madlibAnswers || {}),
        });
        break;
      case 'random-selection':
        ret.push({
          id,
          appletId,
          type: appletType,
          text,
          options,
        });
    }
  }

  return ret;
};

const buildQuizQuestions = (
  appletContentful: AppletContentful,
  quizInfo?: QuizInformation,
): QuizQuestion[] => {
  const quizQuestions: QuizQuestion[] = [];
  if (quizInfo) {
    const { id: quizContentfulId, questionsMap } = quizInfo;
    const sections = appletContentful.quiz?.fields.sections ?? [];
    for (const section of sections) {
      const questions = section.fields.questions;
      for (const question of questions) {
        const questionId = question.sys.id;
        const questionAnswer = questionsMap[questionId];
        if (questionAnswer) {
          const {
            answerType,
            value: userValue,
            madlibAnswers: userMadlibAnswers,
            answer: userAnswer,
            madlibAppletContentfulId,
          } = questionAnswer;

          const value =
            answerType === QuizQuestionAnswerType.SLIDER
              ? userValue ?? question.fields.minimumValue
              : undefined;

          const answer =
            answerType === QuizQuestionAnswerType.OPTION
              ? userAnswer
              : undefined;

          const madlibAnswers = userMadlibAnswers
            ? Object.values(userMadlibAnswers)
            : undefined;

          quizQuestions.push({
            quizContentfulId,
            quizSectionContentfulId: section.sys.id,
            quizQuestionContentfulId: questionId,
            answerType,
            madlibAppletContentfulId,
            value,
            answer,
            madlibAnswers,
          });
        }
      }
    }
  }

  return quizQuestions;
};

const buildDecisionTreeFiles = (
  applet: AppletUpload,
  uploadedFilesMap: Record<string, UploadFileResponse>,
): FileUploadedInformation[] => {
  const ret: FileUploadedInformation[] = [];

  const appFiles = Object.values(applet.files || {});
  for (const appFile of appFiles) {
    const { fileId, description, file, fileOrder } = appFile;
    const uploadedFile = uploadedFilesMap[appFile.fileId];
    ret.push({
      contentfulId: fileId,
      description: description!,
      fileOrder,
      fileType: uploadedFile?.fileType ?? file?.type!,
      fileUrl: uploadedFile?.fileUrl ?? file?.uri!,
    });
  }

  return ret;
};

export const removeUntouchedExtraApplets = (
  applets: AppletUpload[],
  stepAppletsIds: string[],
  appletsContentful: Record<string, AppletContentful>,
): AppletUpload[] => {
  const stepAppletsIdsMap: Record<string, AppletContentful> =
    stepAppletsIds.reduce(
      (acc, app) => ({ ...acc, [app]: appletsContentful[app] }),
      {} as Record<string, AppletContentful>,
    );
  const filteredApplets = applets.filter((app) => {
    const appContentful = stepAppletsIdsMap[app.appletId];
    return appContentful
      ? appContentful.type === 'rich-text-entry' || !appContentful.readOnly
      : !app.untouched;
  });
  return filteredApplets;
};
