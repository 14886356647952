import { useState, useEffect, useCallback } from 'react';

const useAudio = (url: string): [boolean, () => void, () => void] => {
  const [audio] = useState(new Audio(url));
  const [playing, setPlaying] = useState(false);

  const playAudio = useCallback(() => {
    setPlaying(true);
  }, []);

  const stopAudio = useCallback(() => {
    setPlaying(false);
  }, []);

  useEffect(() => {
    playing ? audio.play() : audio.pause();
  }, [audio, playing]);

  useEffect(() => {
    audio.addEventListener('ended', () => setPlaying(false));
    return () => {
      audio.removeEventListener('ended', () => setPlaying(false));
    };
  }, [audio]);

  return [playing, playAudio, stopAudio];
};

export default useAudio;
