import { useCallback } from 'react'
import { QuestApplet } from 'models/QuestApplet';
import { QuestSectionApplets } from 'modules/profile/components';
import AppletPreview from './AppletPreview';

type QuestDropdownItemProps = {
    applets: QuestApplet[];
    onAppletClick?: (applet: QuestApplet) => void;
    previewHeight?: string;
    previewWidth?: string;
};

export const QuestItems = ({
    applets,
    onAppletClick = () => { },
    previewHeight = '139px',
    previewWidth = '146px',
}: QuestDropdownItemProps) => {
    const renderItem = useCallback(
        (applet: QuestApplet) =>
            <AppletPreview
                key={applet.id}
                applet={applet}
                onAppletClick={onAppletClick}
                showTitle={true}
                height={previewHeight}
                width={previewWidth}
            />,
        [onAppletClick, previewHeight, previewWidth],
    );

    return applets.length > 0 ? (
        <QuestSectionApplets>
            {applets.map((applet) => renderItem(applet))}
        </QuestSectionApplets>
    ) : null
}
