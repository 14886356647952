import * as yup from 'yup';

import LocalizedStrings from 'localization';
import { IAppletFields } from 'models/contentful';
import { shareByEmailSchema } from 'helpers/ValidationElements';

const madlibAnswerValidationSchema = yup.array().of(
  yup.object().shape({
    contentfulId: yup.string().required(),
    answer: yup.string().required(),
  }),
);

const fileValidationSchema = yup.object().shape({
  type: yup.string().required(),
  uri: yup.string().required(),
});

const acceptedUploadAppletTypes = [
  'upload-file',
  'upload-image',
  'upload-video',
];

const acceptedAppletTypes: IAppletFields['type'][] = [
  'madlib',
  'rich-text-entry',
  ...(acceptedUploadAppletTypes as IAppletFields['type'][]),
];

export const basicAppletValidation = yup.object().shape({
  appletContentfulId: yup
    .string()
    .required(LocalizedStrings.shareAppletContentfulIdRequired),
  type: yup.string().oneOf(acceptedAppletTypes).required(),
  richText: yup.string().when('type', {
    is: 'rich-text-entry',
    then: (schema: yup.StringSchema<string | undefined>) =>
      schema.required(LocalizedStrings.shareAppletRequired),
    otherwise: (schema: yup.StringSchema<string | undefined>) =>
      schema.optional(),
  }),
  madlibAnswers: madlibAnswerValidationSchema.when('type', {
    is: 'madlib',
    then: (schema) => {
      return schema.required(LocalizedStrings.shareAppletRequired);
    },
    otherwise: (schema) => schema.optional(),
  }),
  file: fileValidationSchema.when(
    'type',
    (value: string, schema: yup.AnyObjectSchema) => {
      const isFileApplet = acceptedUploadAppletTypes.includes(value);
      return isFileApplet ? schema.required() : yup.object({}).optional();
    },
  ),
});

export default basicAppletValidation.concat(shareByEmailSchema.required());
