import { createAction } from '@reduxjs/toolkit';

import { uploadFile } from './services';
import {
  ProgressUpdate,
  FileUploadWithId,
  UploadFileResponse,
} from './typings';

export const startUpload = createAction<FileUploadWithId>('START_UPLOAD');
export const updateUploadProgress = createAction<ProgressUpdate>(
  'UPDATE_UPLOAD_PROGRESS',
);
export const finishUpload = createAction('FINISH_UPLOAD');

export const uploadFiles = async (
  files: FileUploadWithId[],
  dispatch: any,
): Promise<UploadFileResponse[]> => {
  let fileIds: string[] = [];
  const fileUrls: UploadFileResponse[] = await Promise.all(
    files.map(async ({ file, id, appletId }) => {
      fileIds.push(id);
      dispatch(
        startUpload({
          appletId,
          id,
          file,
        }),
      );
      try {
        const response = await uploadFile({ file, id, appletId }, (p) =>
          dispatch(updateUploadProgress({ id, progress: p })),
        );

        return {
          ...response,
          fileType: file.type,
        };
      } catch (error) {
        console.log({ error });
        return {
          id,
          appletId,
          fileUrl: '',
          failed: true,
        };
      }
    }),
  );
  const fileUrlsMap = fileUrls.reduce((acc, fileData) => {
    return {
      ...acc,
      [fileData.id]: fileData,
    };
  }, {} as Record<string, UploadFileResponse>);
  fileIds.forEach((fileId) => {
    const progress = fileUrlsMap[fileId]?.failed ? 0 : 100;
    dispatch(
      updateUploadProgress({
        id: fileId,
        progress,
        failed: fileUrlsMap[fileId]?.failed,
      }),
    );
  });
  dispatch(finishUpload());
  return fileUrls;
};
