import MadlibInput from 'components/madlib-input';
import { MadlibAnswer } from 'models/Applet';
import { IMadlibInputFields } from 'models/contentful';
import React, { useMemo } from 'react';

type MadlibInputCompletedProps = {
  appletId?: string;
  inputId: string;
  inputType: IMadlibInputFields['type'];
  placeholder?: string;
  isMadlibEditMode?: boolean;
  madlibAnswers?: MadlibAnswer[];
};

const MadlibInputCompleted = ({
  inputId,
  inputType,
  placeholder,
  isMadlibEditMode,
  madlibAnswers,
}: MadlibInputCompletedProps) => {
  const value = useMemo(
    () =>
      madlibAnswers?.find((ans) => ans.contentfulId === inputId)?.answer || '',
    [madlibAnswers, inputId],
  );

  return (
    <MadlibInput
      inputType={inputType}
      value={value}
      disabled
      isMadlibEditMode={isMadlibEditMode}
      placeholder={placeholder}
    />
  );
};

export default MadlibInputCompleted;
