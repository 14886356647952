import React from 'react';

type BlueRibbonMedalIconProps = {
  width: number;
  height: number;
};

const BlueRibbonMedalIcon = ({ width, height }: BlueRibbonMedalIconProps) => {
  return (
    <svg width={width} height={height} viewBox="0 0 26 35">
      <g id="V3" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="Profile---Achievements"
          transform="translate(-97.000000, -379.000000)"
          fillRule="nonzero">
          <g id="Badges/Blue" transform="translate(97.000000, 379.000000)">
            <circle
              id="Oval"
              fill="#FFDC61"
              cx="12.6778167"
              cy="21.8555167"
              r="12.4444833"></circle>
            <path
              d="M18.83805,20.3142333 C18.71345,20.00215 18.4636667,19.8149 18.2151667,19.7524833 L14.8519,19.25315 L13.41935,16.1326667 C13.2948667,15.8205833 12.9833667,15.6333333 12.6719833,15.6333333 C12.3606,15.6333333 12.0492167,15.8205833 11.9239167,16.1326667 L10.49209,19.25315 L7.1288,19.7524833 C6.81738167,19.8149 6.56825167,20.00215 6.505975,20.3142333 C6.38078,20.6262 6.505975,20.9382833 6.69219833,21.1255333 L9.12123333,23.6219667 L8.560685,27.1170667 C8.49840833,27.4290333 8.62359167,27.7411167 8.87210333,27.9283667 C9.12123333,28.1156167 9.43265167,28.1156167 9.74407,27.9907833 L12.6719833,26.3680667 L15.5992667,27.9907833 C15.8477667,28.1156167 16.22215,28.1156167 16.47065,27.9283667 C16.7204333,27.7411167 16.8449167,27.4290333 16.7820333,27.1170667 L16.22215,23.6219667 L18.6505667,21.1255333 C18.90035,20.9382833 18.96195,20.5637833 18.83805,20.3142333 Z"
              id="Path"
              fill="#E0AA1E"></path>
            <path
              d="M14.33215,1.22120833 C14.5483333,0.895669833 14.91315,0.700007 15.3039833,0.700007 L21.7067667,0.700007 C22.6368333,0.700007 23.1929833,1.73495 22.6797667,2.510515 L17.28965,10.6555633 L10.2415133,10.6555633 C9.31061833,10.6555633 8.75464333,9.618875 9.26966833,8.84342667 L14.33215,1.22120833 Z"
              id="Path"
              fill="#3B4EDF"></path>
            <path
              d="M2.44063167,2.14972333 C2.02861167,1.52936 2.47338,0.700007 3.21809833,0.700007 L10.1767983,0.700007 C10.4894417,0.700007 10.7813067,0.856536333 10.9542767,1.11696433 L17.2895333,10.6555633 L8.590505,10.6555633 C8.27787333,10.6555633 7.98600833,10.4990317 7.81303833,10.2386083 L2.44063167,2.14972333 Z"
              id="Path"
              fill="#5468FF"></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default BlueRibbonMedalIcon;
