import * as React from 'react';
import theme from 'utils/theme';

type RightArrowIconProps = {
  size?: number;
  disabled?: boolean;
  color?: string;
};

const RightArrowIcon = ({
  size = 12,
  disabled = false,
  color = theme.colors.white[100],
}: RightArrowIconProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 8 12">
      <g id="V3" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="Quest---Active-user-1"
          transform="translate(-259.000000, -308.000000)"
          fill={color}
          opacity={disabled ? 0.2 : 1}>
          <g id="Icons/24/Arrow" transform="translate(251.000000, 302.000000)">
            <polygon
              id="Arrow"
              points="8 7.26511798 9.39308733 6 16 12 9.39308733 18 8 16.734882 13.2138253 12"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default RightArrowIcon;
