import { Block, Inline } from '@contentful/rich-text-types';
import { Next, NodeRenderer } from '@contentful/rich-text-html-renderer';

export const getQuoteHtml = ((node: Block | Inline, next: Next) =>
  `<blockquote>${next(node.content)}</blockquote>`) as NodeRenderer;
export const getParagraphHtml = ((node: Block | Inline, next: Next) =>
  `<p>${next(node.content)
    .replace(/<\/b>(\s*)<b>/g, '</b><small>&#20;</small><b>')
  }</p>`) as NodeRenderer;
export const getH1Html = ((node: Block | Inline, next: Next) =>
  `<h1>${next(node.content)}</h1>`) as NodeRenderer;
export const getH2Html = ((node: Block | Inline, next: Next) =>
  `<h2>${next(node.content)}</h2>`) as NodeRenderer;
export const getH3Html = ((node: Block | Inline, next: Next) =>
  `<h3>${next(node.content)}</h3>`) as NodeRenderer;
export const getH4Html = ((node: Block | Inline, next: Next) =>
  `<h4>${next(node.content)}</h4>`) as NodeRenderer;
export const getH5Html = ((node: Block | Inline, next: Next) =>
  `<h5>${next(node.content)}</h5>`) as NodeRenderer;
export const getH6Html = ((node: Block | Inline, next: Next) =>
  `<h6>${next(node.content)}</h6>`) as NodeRenderer;

export const getHrHtml = ((node: Block | Inline, next: Next) =>
  `${next(node.content)} <hr>`) as NodeRenderer;
