import React, { useContext, useMemo } from 'react';
import { BLOCKS } from '@contentful/rich-text-types';
import { nanoid } from '@reduxjs/toolkit';

import { NestingContext, NestingProvider } from '../NestingProvider';
import { OlList } from './components';

const OrderedList = ({ node, children }) => {
  const nestingContext = useContext(NestingContext);

  const olNestingLevel = useMemo(
    () =>
      nestingContext.olNestingLevel ? nestingContext.olNestingLevel + 1 : 1,
    [nestingContext],
  );

  if (node.nodeType === BLOCKS.OL_LIST) {
    return (
      <NestingProvider
        ulNestingLevel={nestingContext.ulNestingLevel}
        olNestingLevel={olNestingLevel}>
        <OlList>
          {children &&
            children instanceof Array &&
            children.map((Child) => (
              <Child listType="ordered" key={nanoid()} />
            ))}
        </OlList>
      </NestingProvider>
    );
  }
  return null;
};

export default OrderedList;
