import React, { useMemo } from 'react';

import { ChallengeApplet } from 'models/ChallengeApplet';

import { GroupAppletContainer } from 'components/applets/components';
import useRenderSubmissionApplet from 'hooks/useRenderSubmissionApplet';

type SubmissionGroupAppletProps = {
  applet: ChallengeApplet;
};

const SubmissionGroupApplet = ({ applet }: SubmissionGroupAppletProps) => {
  const renderApplet = useRenderSubmissionApplet();
  const renderApplets = useMemo(() => {
    return applet.applets!.map((app) => renderApplet(app));
  }, [applet, renderApplet]);

  return <GroupAppletContainer>{renderApplets}</GroupAppletContainer>;
};

export default SubmissionGroupApplet;
