import { useEffect, RefObject } from 'react';
import { FormikProps } from 'formik';

import useFormikRef from './useFormikRef';

const useSetValuesOnInitialValuesChange = <FormikFormType>(
  initialValues: FormikFormType,
): [RefObject<FormikProps<FormikFormType | null>>, (instance: any) => void] => {
  const [formikRef, assignRef] = useFormikRef<FormikFormType>();

  useEffect(() => {
    if (initialValues) {
      formikRef.current?.setValues(initialValues);
    }
  }, [initialValues, formikRef]);

  return [formikRef, assignRef];
};

export default useSetValuesOnInitialValuesChange;
