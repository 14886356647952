import React, { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import localizedStrings from 'localization';
import {
  PublishShowcaseAppletsContainer,
  PublishShowcaseDescription,
} from 'modules/profile/components';
import { selectQuestPortfolioApplets } from 'modules/applets/selectors';
import { QuestApplet } from 'models/QuestApplet';
import AppletPreview from 'modules/profile/portfolio/quest-applets/AppletPreview';
import { StepProps } from '../typings';
import { buildQuestAppletsList } from 'helpers/AppletHelpers';
import { VIDEO_MIMETYPE } from 'helpers/FileHelpers';

const SelectHeadline = ({
  values,
  setFieldValue,
  setNextButtonText,
}: StepProps) => {
  const questPortfolioApplets = useSelector(selectQuestPortfolioApplets);

  const applets = useMemo(() => {
    const applets = questPortfolioApplets.filter(
      (applet) =>
        applet.appletType === 'upload-video' ||
        (applet.appletType === 'upload-file' &&
          applet.fileType === VIDEO_MIMETYPE) ||
        (applet.appletType === 'generic-upload-file' &&
          applet.fileType === VIDEO_MIMETYPE) ||
        applet.appletType === 'inline-text',
    );
    return buildQuestAppletsList(applets);
  }, [questPortfolioApplets]);

  const handleAppletSelect = useCallback(
    (applet: QuestApplet) => {
      setFieldValue('headlineApplet', applet.id);
    },
    [setFieldValue],
  );

  useEffect(() => {
    if (values.headlineApplet !== '') {
      setNextButtonText(localizedStrings.publishShowcase);
    } else {
      setNextButtonText(localizedStrings.selectAnArtifact);
    }
  }, [setNextButtonText, values.headlineApplet]);

  return (
    <>
      <PublishShowcaseDescription>
        {localizedStrings.selectShowcaseHeadline}
      </PublishShowcaseDescription>
      <PublishShowcaseAppletsContainer>
        {applets.map((applet) => (
          <AppletPreview
            key={applet.id}
            applet={applet}
            onAppletClick={handleAppletSelect}
            showTitle={true}
            height="168px"
            width="178px"
            selected={values.headlineApplet === applet.id}
          />
        ))}
      </PublishShowcaseAppletsContainer>
    </>
  );
};

export default SelectHeadline;
