import React, { useMemo } from 'react';

import { CelebrateAppletTitle } from '../components';
import FileUploadApplet from 'components/applets/file-upload';
import InlineTextApplet from 'components/applets/inline-text-applet';
import DecisionTreeApplet from 'components/applets/decision-tree-applet';
import RankApplet from 'components/applets/rank-applet';
import { ChallengeApplet } from 'models/ChallengeApplet';
import GroupApplet from 'components/applets/group-applet';
import { AppletContentful } from 'models/AppletContentful';
import RandomSelectionAppletChallenge from 'modules/challenge/random-selection-applet-challenge';
import MadlibAppletChallenge from '../madlib-applet-challenge';
import QuizAppletCelebrate from './QuizAppletCelebrate';
import RichTextAppletChallenge from '../rich-text-applet-challenge';

type SubmittedAppletsSwitchProps = {
  applet: ChallengeApplet;
  withoutTitle?: boolean;
  appletContentful?: AppletContentful;
};

const SubmittedAppletsSwitch = ({
  applet,
  withoutTitle,
  appletContentful,
}: SubmittedAppletsSwitchProps) => {
  const title = useMemo(
    () =>
      withoutTitle ? null : (
        <CelebrateAppletTitle>{applet.title}</CelebrateAppletTitle>
      ),
    [applet, withoutTitle],
  );

  const renderedApplet = useMemo(() => {
    switch (applet.appletType) {
      case 'upload-file':
      case 'upload-image':
      case 'upload-video':
        return (
          <div key={applet.appletId}>
            {title}
            <FileUploadApplet
              appletId={applet.appletId}
              appletType={applet.appletType}
              supportedTypes={applet.supportedTypes}
            />
          </div>
        );

      case 'rich-text-entry':
        return (
          <div key={applet.appletId}>
            {title}
            <RichTextAppletChallenge
              key={applet.appletId}
              appletId={applet.appletId}
            />
          </div>
        );

      case 'inline-text':
        return (
          <InlineTextApplet
            appletId={applet.appletId}
            prompt={applet.uploadPrompt}
          />
        );

      case 'quiz':
        return (
          <div key={applet.appletId}>
            {title}
            <QuizAppletCelebrate applet={applet} />
          </div>
        );

      case 'decision-tree':
        return (
          <div key={applet.appletId}>
            {title}
            <DecisionTreeApplet
              appletId={applet.appletId}
              branches={applet.decisionTreeBranches}
            />
          </div>
        );

      case 'group-applet':
        return <GroupApplet applet={applet} />;

      case 'rank':
        return (
          <div key={applet.appletId}>
            {title}
            <RankApplet
              appletId={applet.appletId}
              optionsContentful={applet.optionsContentful!}
            />
          </div>
        );

      case 'madlib':
        return (
          <div key={applet.appletId}>
            {title}
            <MadlibAppletChallenge appletId={applet.appletId} />
          </div>
        );

      case 'random-selection':
        return <RandomSelectionAppletChallenge appletId={applet.appletId} />;

      default:
        return null;
    }
  }, [applet, title]);

  return renderedApplet;
};

export default SubmittedAppletsSwitch;
