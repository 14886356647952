import React, { useMemo } from 'react';

import localizedStrings from 'localization';
import {
  ProgressBarContainer,
  ProgressBarFull,
  WeeklyOverviewItemContainer,
  ProgressBar,
  WeeklyOverviewStatusContainer,
  WeeklyOverViewCompletedChallenges,
  WeekChallengeCardInfo,
  ChallengeCardTitlesContainer,
  ChallengeCardIconsContainer,
  ChallengeIconsRow,
  PointsContainer,
  TextXp,
} from 'modules/overview/components';
import theme from 'utils/theme';
import BadgeIcon from '../../../components/icons/BadgeIcon';
import { ChallengeInformation } from '../../../models/ChallengeInformation';

type ChallengeCardProps = {
  item: ChallengeInformation;
  onClick: () => void;
};

const WeeklyOverviewItem = ({ item, onClick }: ChallengeCardProps) => {
  const { completed = false } = item;
  const dimension = useMemo(() => theme.typography.large24, []);
  const [percentage, stepsCompleted, borderColor] = useMemo(() => {
    const totalSteps = item.stepIds?.length + 1;
    const completedStepsLength = item.contentUser?.completedSteps?.length ?? 0;
    const completedPost = item.contentUser?.completed ? 1 : 0;
    const completedSteps = completedStepsLength + completedPost;
    let containerBorder = theme.colors.white[100];

    if (completedSteps === totalSteps) {
      containerBorder = theme.colors.gold[100];
    }

    const completedStepsString = localizedStrings.completedSteps(
      completedSteps,
      totalSteps,
    );
    if (item.contentUser) {
      const percentageSteps = Math.floor((completedSteps / totalSteps) * 100);
      return [percentageSteps, completedStepsString, containerBorder];
    }
    return [0, completedStepsString, containerBorder];
  }, [item]);

  return (
    <WeeklyOverviewItemContainer
      imageUrl={item.imageUrl}
      completed={completed}
      onClick={onClick}
      borderColor={borderColor}>
      <WeeklyOverviewStatusContainer>
        <ProgressBarContainer>
          <ProgressBarFull percentage={percentage} />
          <ProgressBar />
        </ProgressBarContainer>
        <WeeklyOverViewCompletedChallenges>
          {stepsCompleted}
        </WeeklyOverViewCompletedChallenges>
      </WeeklyOverviewStatusContainer>
      <WeekChallengeCardInfo>
        <ChallengeCardTitlesContainer>
          {item.title}
        </ChallengeCardTitlesContainer>
        <ChallengeCardIconsContainer>
          <ChallengeIconsRow>
            <PointsContainer>
              <BadgeIcon width={dimension} height={dimension} />
              <TextXp>{`${item.totalXp ?? 0}${localizedStrings.experience(
                item.bonusChallenge,
              )}`}</TextXp>
            </PointsContainer>
          </ChallengeIconsRow>
        </ChallengeCardIconsContainer>
      </WeekChallengeCardInfo>
    </WeeklyOverviewItemContainer>
  );
};

export default WeeklyOverviewItem;
