import { createReducer } from '@reduxjs/toolkit';
import { arrayToHash } from 'helpers/helperFunctions';
import { QuestOffboarding } from 'models/QuestOffboarding';
import { getQuestsOffboardings } from './actions';
import { QuestsOffboardingsState } from './typings';

const initialState: QuestsOffboardingsState = {
  questsOffboardings: {},
};

export default createReducer<QuestsOffboardingsState>(initialState, (builder) =>
  builder.addCase(getQuestsOffboardings.fulfilled, (state, action) => ({
    questsOffboardings: arrayToHash<QuestOffboarding>(
      action.payload,
      (questOffboarding) => questOffboarding.id,
      state.questsOffboardings,
    ),
    lastUpdatedAt: new Date().toISOString(),
  })),
);
