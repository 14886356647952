import React from 'react';

import { BlockNodeProps } from 'components/contentful-renders/typings';
import Applet from './applet';
import MissingContentfulRender from 'components/contentful-renders/missing-contentful-render';
import { BLOCKS } from '@contentful/rich-text-types';
import TextCard from './text-card';

type EmbeddedEntryProps = Omit<BlockNodeProps, 'children'>;

const EmbeddedEntry = ({ node }: EmbeddedEntryProps) => {
  return (
    <>
      {node.data.target.sys.contentType.sys.id === 'applet' ? (
        <Applet node={node} />
      ) : null}
      {node.data.target.sys.contentType.sys.id === 'textCard' ? (
        <TextCard node={node} />
      ) : null}
      {!['applet', 'textCard'].includes(
        node.data.target.sys.contentType.sys.id,
      ) ? (
        <MissingContentfulRender
          componentType={`${BLOCKS.EMBEDDED_ENTRY} - ${node.data.target.sys.contentType.sys.id}`}
        />
      ) : null}
    </>
  );
};

export default EmbeddedEntry;
