import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { ChallengeRouteParams } from 'modules/challenge';
import { useDispatch } from 'react-redux';
import { getContentById } from 'modules/loading-screen/contents/actions';
import useActionStatus from 'hooks/useActionStatus';
import { SyncInformationContainer } from './components';
import LoadingSpinner from 'components/loading-spinner';

const SyncChallenge = () => {
  const dispatch = useDispatch();
  const { id } = useParams<ChallengeRouteParams>();
  const [isGettingContent] = useActionStatus(getContentById);

  useEffect(() => {
    dispatch(getContentById(id));
  }, [dispatch, id]);

  return (
    <SyncInformationContainer visible={isGettingContent}>
      <LoadingSpinner size={20} />
      Syncing Content
    </SyncInformationContainer>
  );
};

export default SyncChallenge;
