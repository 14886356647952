import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import Modal from 'components/modal';
import localizedStrings from 'localization';
import { CancelButton } from 'modules/challenge/components';
import {
  AddQuestFormButtons,
  AddQuestPrompt,
  StartButton,
} from 'modules/settings/components';
import { AppDispatch } from 'redux/typings';
import { addCohort, userCohortExists } from 'modules/settings/actions';
import useActionStatus from 'hooks/useActionStatus';
import { useHistory } from 'react-router';
import { HOME } from 'routes';

type ChangeQuestModalProps = {
  open: boolean;
  close: () => void;
  cohort: string;
};

const ChangeQuestModal = ({ open, close, cohort }: ChangeQuestModalProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const [isLoading, wasFulfilled] = useActionStatus(addCohort);

  const handleChangeQuest = useCallback(() => {
    const localDate = new Date();
    const userLocalDate = {
      year: localDate.getFullYear(),
      month: localDate.getMonth(),
      day: localDate.getDate(),
      startOnSameWeek: true,
    };

    dispatch(addCohort({ enrollmentCode: cohort, userLocalDate }));
    dispatch(userCohortExists({ enrollmentCode: cohort, exist: true }));
    setHasSubmitted(true);
  }, [dispatch, cohort]);

  const handleClose = useCallback(() => close(), [close]);

  useEffect(() => {
    if (hasSubmitted && wasFulfilled) {
      history.push(HOME);
      handleClose();
    }
  }, [dispatch, handleClose, hasSubmitted, history, wasFulfilled]);

  return (
    <>
      <Modal
        open={open}
        width={'500px'}
        title={localizedStrings.switchQuest}
        hideCloseButton>
        <>
          {!isLoading ? (
            <>
              <AddQuestPrompt>
                {localizedStrings.switchQuestState}
              </AddQuestPrompt>
              <AddQuestFormButtons>
                <CancelButton type="button" onClick={handleClose}>
                  {localizedStrings.cancel}
                </CancelButton>
                <StartButton onClick={handleChangeQuest}>
                  {localizedStrings.start}
                </StartButton>
              </AddQuestFormButtons>
            </>
          ) : (
            <AddQuestPrompt>{localizedStrings.loading}</AddQuestPrompt>
          )}
        </>
      </Modal>
    </>
  );
};

export default ChangeQuestModal;
