import axios from 'axios';

import { baseService } from 'modules/baseService';
import HttpClient from 'services/HttpClient';
import { FeedbackPayload } from './typings';
import { Feedback } from 'models/Feedback';
import { AchievementsState, ShowcaseItem } from 'modules/profile/typings';
import { apiUrl } from 'config';

export const getUserAchievements = baseService<
  { userId: string },
  AchievementsState
>(({ userId }) => HttpClient.get(`/achievements?userId=${userId}`));

export const getUserShowcases = baseService<{ userId: string }, ShowcaseItem[]>(
  ({ userId }) => HttpClient.get(`/showcases?userId=${userId}`),
);

export const giveFeedback = baseService<FeedbackPayload, Feedback>((feedback) =>
  HttpClient.post('/feedback', feedback),
);

export const giveExternalFeedback = baseService<
  { feedback: FeedbackPayload; token: string },
  Feedback
>(({ feedback, token }) =>
  axios.post(apiUrl + 'feedback', feedback, {
    headers: { authorization: `Bearer ${token}` },
  }),
);
