import React, { useCallback } from 'react';

import Avatar from 'components/avatar';
import { Feedback } from 'models/Feedback';
import {
  FeedbackContainer,
  FeedbackUserAndComment,
  FeedbackUsername,
  FeedbackUserComment,
  FeedbackSeparator,
} from 'modules/profile/components';

type ChallengeFeedbacksProps = {
  feedbacks: Feedback[];
};

const ChallengeFeedbacks = ({ feedbacks }: ChallengeFeedbacksProps) => {
  const renderItem = useCallback(
    (feedback: Feedback) => (
      <FeedbackContainer key={feedback.id}>
        <Avatar
          uri={feedback.writtenBy.profilePictureUrl}
          displayName={feedback.writtenBy.displayName}
          size={60}
        />
        <FeedbackUserAndComment>
          <FeedbackUsername>{feedback.writtenBy.displayName}</FeedbackUsername>
          <FeedbackUserComment>{feedback.answers}</FeedbackUserComment>
        </FeedbackUserAndComment>
      </FeedbackContainer>
    ),
    [],
  );

  return (
    <>
      {feedbacks.map((feedback, index) => (
        <>
          {renderItem(feedback)}
          {index !== feedbacks.length - 1 && <FeedbackSeparator />}
        </>
      ))}
    </>
  );
};

export default ChallengeFeedbacks;
