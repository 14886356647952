import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { AUTH } from 'routes';
import EmailVerification from './email-verification';
import ForgotPassword from './forgot-password';
import Login from './login';
import Register from './register';
import ResetPassword from './reset-password';
import {
  AUTH_EMAIL_VERIFICATION,
  AUTH_FORGOT_PASSWORD,
  AUTH_LOGIN,
  AUTH_REGISTER,
  AUTH_RESET_PASSWORD,
} from './routes';

const Auth = () => {
  return (
    <Switch>
      <Route exact path={AUTH + AUTH_LOGIN} component={Login} />
      <Route exact path={AUTH + AUTH_REGISTER} component={Register} />
      <Route
        exact
        path={AUTH + AUTH_EMAIL_VERIFICATION}
        component={EmailVerification}
      />
      <Route
        exact
        path={AUTH + AUTH_FORGOT_PASSWORD}
        component={ForgotPassword}
      />
      <Route
        exact
        path={AUTH + AUTH_RESET_PASSWORD}
        component={ResetPassword}
      />
    </Switch>
  );
};

export default Auth;
