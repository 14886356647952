import React, { useCallback } from 'react';
import { useHistory } from 'react-router';

import { Notification } from 'models/Notification';
import Avatar from 'components/avatar';
import {
  ActivityBody,
  ActivityContainer,
  ActivitySeparator,
  NotificationContainer,
} from '../components';
import { NotificationType } from 'models/NotificationType';
import { CHALLENGE, PROFILE, RELATIONSHIPS } from 'routes';


type NotificationsProps = {
  notifications: Notification[]
};

const Notifications = ({ notifications }: NotificationsProps) => {
  const history = useHistory();

  const isNavigationEnabled = useCallback(
    (notification: Notification) =>
      notification.type === NotificationType.INVITATION_ACCEPTED ||
      notification.type === NotificationType.INVITATION_RECEIVED ||
      notification.type === NotificationType.RELATIONSHIP_SWITCHED ||
      (notification.targetId !== undefined &&
        (notification.type === NotificationType.CHALLENGE_COMPLETED ||
          notification.type === NotificationType.FEEDBACK_RECEIVED ||
          notification.type === NotificationType.FEEDBACK_REQUESTED)),
    [],
  );

  const handleClick = useCallback(
    (type: NotificationType, targetId?: string) => () => {
      switch (type) {
        case NotificationType.CHALLENGE_COMPLETED:
          history.push(`${CHALLENGE}/${targetId}`);
          break;
        case NotificationType.FEEDBACK_RECEIVED:
          history.push(`${PROFILE}?challengeId=${targetId}`);
          break;
        case NotificationType.FEEDBACK_REQUESTED:
          const [userId, challengeId] = targetId!.split(':');
          history.push(`${RELATIONSHIPS}/${userId}${PROFILE}/${challengeId}`);
          break;
        case NotificationType.INVITATION_ACCEPTED:
        case NotificationType.INVITATION_RECEIVED:
        case NotificationType.RELATIONSHIP_SWITCHED:
          history.push(RELATIONSHIPS);
          break;
        default:
          break;
      }
    },
    [history],
  );

  return (
    <NotificationContainer>
      {notifications.map((notification, index) => (
        <div key={notification.id}>
          <ActivityContainer
            clickEnabled={isNavigationEnabled(notification)}
            onClick={handleClick(notification.type, notification.targetId)}>
            {(notification.pictureUrl || notification.displayName) && (
              <Avatar
                displayName={notification.displayName ?? ''}
                uri={notification.pictureUrl}
                size={40}
              />
            )}
            <ActivityBody>{notification.body}</ActivityBody>
          </ActivityContainer>
          {index !== notifications.length - 1 && <ActivitySeparator />}
        </div>
      ))}
    </NotificationContainer>
  );
};

export default Notifications;
