import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import ContentLoader from 'react-content-loader';

import { PROFILE, RELATIONSHIPS } from 'routes';
import { AppDispatch } from 'redux/typings';
import { sendMessage } from './actions';
import { selectSelectedRelationship } from 'modules/relationships/selectors';
import { selectLoading, selectMessages } from './selectors';
import {
  ChatInput,
  ChatInputContainer,
  ChatMessagesContainer,
  ChatPanel,
  RelationshipSeparator,
  SendMessageButton,
} from 'modules/relationships/components';
import RelationshipChatMessage from './ChatMessage';
import RelationshipCard from '../RelationshipCard';
import { Relationship } from 'models/Relationship.d';
import SendIcon from 'components/icons/SendIcon';

const Chat = () => {
  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();
  const refInput = useRef<HTMLInputElement>(null)
  const [messageInputText, setMessageInputText] = useState('');
  const selectedRelationship = useSelector(selectSelectedRelationship);
  const isLoading = useSelector(selectLoading);
  const messages = useSelector(selectMessages);
  const messagesContainerRef = useRef<HTMLDivElement>(null);

  const handleSendMessage = useCallback(() => {
    if (messageInputText.length > 0) {
      dispatch(sendMessage(messageInputText));
      setMessageInputText('');
    }
  }, [dispatch, messageInputText]);

  const handleMessageInputChange = useCallback(
    (evt) => {
      setMessageInputText(evt.target.value);
    },
    [setMessageInputText],
  );

  const handleMessageInputKeyDown = useCallback(
    (evt) => {
      if (evt.key === 'Enter') {
        handleSendMessage();
      }
    },
    [handleSendMessage],
  );

  const scrollToBottom = useCallback(() => {
    messagesContainerRef.current?.scrollTo(
      0,
      messagesContainerRef.current.scrollHeight,
    );
  }, []);

  const handleViewProfile = useCallback(
    (relationship: Relationship) => {
      history.push(`${RELATIONSHIPS}/${relationship.relatedUser.id + PROFILE}`);
    },
    [history],
  );

  useEffect(() => {
    if (!isLoading) {
      scrollToBottom();
    }
  }, [isLoading, scrollToBottom]);

  useEffect(() => {
    scrollToBottom();
  }, [messages, scrollToBottom]);

  useEffect(() => {
    if (refInput.current) {
      refInput.current.focus()
    }
  }, [selectedRelationship])


  return (
    <ChatPanel>
      {selectedRelationship && (
        <>
          <RelationshipCard
            relationship={selectedRelationship}
            type="chat"
            onViewProfile={handleViewProfile}
          />
          <ChatMessagesContainer ref={messagesContainerRef}>
            {isLoading ? (
              <ContentLoader viewBox="0 0 400 260" width={'100%'}>
                <rect x="0" y="12" rx="5" ry="5" width="220" height="30" />
                <rect x="0" y="49" rx="5" ry="5" width="220" height="30" />
                <rect x="179" y="98" rx="5" ry="5" width="220" height="30" />
                <rect x="179" y="136" rx="5" ry="5" width="220" height="30" />
                <rect x="0" y="184" rx="5" ry="5" width="220" height="30" />
                <rect x="0" y="222" rx="5" ry="5" width="220" height="30" />
              </ContentLoader>
            ) : (
              messages.map((message, index, array) => (
                <RelationshipChatMessage
                  key={message.id}
                  message={message}
                  previousMessage={array[index - 1]}
                />
              ))
            )}
          </ChatMessagesContainer>
          <RelationshipSeparator />
          <ChatInputContainer>
            <ChatInput
              ref={refInput}
              type="text"
              id="message"
              name="message"
              value={messageInputText}
              onChange={handleMessageInputChange}
              onKeyDown={handleMessageInputKeyDown}
              autoComplete="off"
            />
            <SendMessageButton onClick={handleSendMessage}>
              <SendIcon />
            </SendMessageButton>
          </ChatInputContainer>
        </>
      )}
    </ChatPanel>
  );
};

export default Chat;
