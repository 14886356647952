import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'redux/typings';

const selectWeeksOnboardingsState = (state: RootState) =>
  state.contentful.weeksOnboardings;

export const selectWeeksOnboardings = createSelector(
  selectWeeksOnboardingsState,
  (weeksOnboardingsState) => weeksOnboardingsState.weeksOnboardings,
);

export const selectWeeksOnboardingsLastUpdatedAt = createSelector(
  selectWeeksOnboardingsState,
  (weeksOnboardingsState) => weeksOnboardingsState.lastUpdatedAt,
);
