import { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { AppDispatch } from 'redux/typings';
import useActionStatus from './useActionStatus';
import { selectLoadingFirstFinished, selectLoadingLastFinished } from 'modules/loading-screen/quests/selectors';
import { getContents } from 'modules/loading-screen/contentful/contents/actions';
import { getSteps } from 'modules/loading-screen/contentful/steps/actions';
import { getApplets } from 'modules/loading-screen/contentful/applets/actions';
import { selectContentsContentfulLastUpdatedAt } from 'modules/loading-screen/contentful/contents/selectors';
import { selectStepsContentfulLastUpdatedAt } from 'modules/loading-screen/contentful/steps/selectors';
import { selectAppletsContentfulLastUpdatedAt } from 'modules/loading-screen/contentful/applets/selectors';

const useLoadContentfulLastContent = () => {
  const dispatch = useDispatch<AppDispatch>();
  const loadingFinishedFirst = useSelector(selectLoadingFirstFinished);
  const loadingFinishedLast = useSelector(selectLoadingLastFinished);

  const contentLastUpdatedAt = useSelector(selectContentsContentfulLastUpdatedAt);
  const stepsLastUpdatedAt = useSelector(selectStepsContentfulLastUpdatedAt);
  const appletsLastUpdatedAt = useSelector(selectAppletsContentfulLastUpdatedAt);

  const [, getContentsWasFulfilled] = useActionStatus(getContents);
  const [, getStepsWasFulfilled] = useActionStatus(getSteps);
  const [, getAppletsWasFulfilled] = useActionStatus(getApplets);

  useEffect(() => {
    if (loadingFinishedFirst && !loadingFinishedLast) {
      dispatch(getContents(contentLastUpdatedAt));
      dispatch(getSteps(stepsLastUpdatedAt));
      dispatch(getApplets(appletsLastUpdatedAt));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hasLoadedLastContent = useMemo(() => {
    return (
      getContentsWasFulfilled &&
      getStepsWasFulfilled &&
      getAppletsWasFulfilled
    );
  }, [
    getContentsWasFulfilled,
    getStepsWasFulfilled,
    getAppletsWasFulfilled,
  ]);

  return [hasLoadedLastContent];
};

export default useLoadContentfulLastContent;
