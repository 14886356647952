import { createReducer } from '@reduxjs/toolkit';
import { addMinutes, isSameDay, parseISO } from 'date-fns';

import {
  addHour,
  getScheduleCommit,
  removeHour,
  resetScheduleCommit,
  selectWeekConfigByDate,
} from './actions';
import { ScheduleCommitState } from './typings';

const initialState: ScheduleCommitState = {
  hasCommittedSchedule: false,
  editingScheduleCommit: {
    mondayHours: 0,
    tuesdayHours: 0,
    wednesdayHours: 0,
    thursdayHours: 0,
    fridayHours: 0,
    saturdayHours: 0,
    sundayHours: 0,
  },
  schedule: {},
};

export default createReducer(initialState, (builder) =>
  builder
    .addCase(addHour, (state, action) => ({
      ...state,
      editingScheduleCommit: {
        ...state.editingScheduleCommit,
        [action.payload]: state.editingScheduleCommit[action.payload] + 1,
      },
    }))
    .addCase(removeHour, (state, action) => ({
      ...state,
      editingScheduleCommit: {
        ...state.editingScheduleCommit,
        [action.payload]: state.editingScheduleCommit[action.payload] - 1,
      },
    }))
    .addCase(getScheduleCommit.fulfilled, (state, action) => {
      return {
        ...state,
        hasCommittedSchedule: action.payload.hasCommittedSchedule,
        schedule: action.payload.weekScheduleMap
          ? action.payload.weekScheduleMap
          : {},
      };
    })
    .addCase(selectWeekConfigByDate, (state, action) => {
      const weekConfig = Object.values(state.schedule).find((weekCfg) => {
        const weekCfgDate = parseISO(weekCfg.startingDate as string);
        const weekCfgDateOffset = weekCfgDate.getTimezoneOffset();
        const weekCfgDateWithOffset = addMinutes(
          weekCfgDate,
          weekCfgDateOffset,
        );
        return isSameDay(weekCfgDateWithOffset, action.payload);
      });
      if (weekConfig) {
        const {
          id,
          startingDate,
          weekId,
          weekNumber,
          ...weekHours
        } = weekConfig;
        return {
          ...state,
          editingScheduleCommit: weekHours,
          editedWeekId: weekId,
        };
      } else {
        return {
          ...state,
          editedWeekId: undefined,
        };
      }
    })
    .addCase(resetScheduleCommit, () => initialState),
);
