import { useMemo } from 'react';

import SpeakerIcon from '../components/icons/SpeakerIcon';
import GestureIcon from '../components/icons/GestureIcon';
import ReadingIcon from '../components/icons/ReadingIcon';
import ComputerIcon from '../components/icons/ComputerIcon';
import WritingIcon from '../components/icons/WritingIcon';
import VideoIcon from '../components/icons/VideoIcon';
import MobileIcon from '../components/icons/MobileIcon';
import localizedStrings from 'localization/en';

const useLocationIconText = (location: string) => {
  const [Icon, Text]: any = useMemo(() => {
    switch (location) {
      case 'phone':
        return [MobileIcon, localizedStrings.phoneRecommended];
      case 'video':
        return [VideoIcon, localizedStrings.phoneRecommended];
      case 'audio':
        return [SpeakerIcon, localizedStrings.phoneRecommended];
      case 'gesture':
        return [GestureIcon, localizedStrings.phoneRecommended];
      case 'computer':
        return [ComputerIcon, localizedStrings.computerRecommended];
      case 'reading':
        return [ReadingIcon, localizedStrings.computerRecommended];
      case 'writing':
        return [WritingIcon, localizedStrings.computerRecommended];
      case 'desktop':
        return [ComputerIcon, localizedStrings.desktopRecommended];
      default:
        return [MobileIcon, localizedStrings.phoneRecommended];
    }
  }, [location]);

  return [Icon, Text];
};
export default useLocationIconText;
