import React, { useCallback } from 'react';
import { Formik } from 'formik';
import { CircularProgress } from '@material-ui/core';

import Modal from 'components/modal';
import {
  EmptyIcon,
  FieldsContainer,
  InputWithoutBorder,
  SendForm,
  SendMailButton,
  SendModalTitle,
  SendModalTitleContainer,
} from 'components/applets/share-applet-button/components';
import SendIcon from 'components/icons/SendIcon';
import LocalizedStrings from 'localization';

interface ShareModalValuesType {
  receiver: string;
  subject: string;
  message: string;
}

type ShareModalByEmailProps<ValuesType, ValidationSchema> = {
  isOpen?: boolean;
  closeModal: () => void;
  initialValues: ValuesType;
  assignRef: (instance: any) => void;
  validationSchema: ValidationSchema;
  onValidSubmit: (values: ValuesType) => void;
  loading?: boolean;
  preview: React.ReactNode;
  sendButtonDisabled?:
    | boolean
    | ((values: ValuesType, isValid: boolean) => boolean);
};

const ShareByEmailModal = <
  ValuesType extends ShareModalValuesType,
  ValidationSchema,
>({
  isOpen = false,
  closeModal,
  initialValues,
  assignRef,
  validationSchema,
  onValidSubmit,
  loading,
  preview,
  sendButtonDisabled,
}: ShareModalByEmailProps<ValuesType, ValidationSchema>) => {
  const disabledSendButton = useCallback(
    (values: ValuesType, isValid: boolean) => {
      let ret = false;
      if (sendButtonDisabled !== undefined) {
        if (typeof sendButtonDisabled === 'boolean') {
          ret = sendButtonDisabled;
        } else {
          ret = sendButtonDisabled(values, isValid);
        }
      }

      return ret;
    },
    [sendButtonDisabled],
  );

  return (
    <Modal width="50%" open={isOpen} closeModal={closeModal}>
      <Formik
        initialValues={initialValues}
        onSubmit={onValidSubmit}
        validationSchema={validationSchema}
        innerRef={assignRef}
        enableReinitialize>
        {({ values, handleSubmit, isValid }) => (
          <SendForm onSubmit={handleSubmit}>
            <SendModalTitleContainer>
              <EmptyIcon />
              <SendModalTitle>{LocalizedStrings.newMessage}</SendModalTitle>
              {loading ? (
                <CircularProgress size={22} color="primary" />
              ) : (
                <SendMailButton
                  disabled={disabledSendButton(values, isValid)}
                  type="submit">
                  <SendIcon disabled={disabledSendButton(values, isValid)} />
                </SendMailButton>
              )}
            </SendModalTitleContainer>
            <FieldsContainer>
              <InputWithoutBorder
                name="receiver"
                placeholder={LocalizedStrings.shareAppletTo}
              />
              <InputWithoutBorder
                name="subject"
                placeholder={LocalizedStrings.shareAppletSubject}
              />
              <InputWithoutBorder
                name="message"
                textarea
                rows={5}
                placeholder={LocalizedStrings.shareAppletMessage}
              />
              {preview}
            </FieldsContainer>
          </SendForm>
        )}
      </Formik>
    </Modal>
  );
};

export default ShareByEmailModal;
