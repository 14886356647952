import React from 'react';
import theme from 'utils/theme';

type HeartIconProps = {
  width: number | string;
  height: number | string;
  color?: string;
};

const HeartIcon = ({
  width,
  height,
  color = theme.colors.white[100],
}: HeartIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill="none">
      <path
        d="M4.8824 12.9557L10.5021 19.3071C11.2981 20.2067 12.7019 20.2067 13.4979 19.3071L19.1176 12.9557C20.7905 11.0649 21.6596 8.6871 20.4027 6.41967C18.9505 3.79992 16.2895 3.26448 13.9771 5.02375C13.182 5.62861 12.5294 6.31934 12.2107 6.67771C12.1 6.80224 11.9 6.80224 11.7893 6.67771C11.4706 6.31934 10.818 5.62861 10.0229 5.02375C7.71053 3.26448 5.04945 3.79992 3.59728 6.41967C2.3404 8.6871 3.20947 11.0649 4.8824 12.9557Z"
        stroke={color}
        strokeWidth="2"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default HeartIcon;
