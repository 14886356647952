import * as React from 'react';
import theme from 'utils/theme';

type DownCaretProps = {
  color?: string;
}

const DownCaret = ({ color = theme.colors.grey[90] }: DownCaretProps) => {
  return (
    <svg width={14} height={8} viewBox="0 0 14 8" fill="none">
      <path
        d="M13.176.576a.6.6 0 00-.849 0L6.876 6.027 1.424.576a.6.6 0 00-.848.848l5.877 5.877a.6.6 0 00.877-.031l5.846-5.846a.6.6 0 000-.848z"
        fill={color}
      />
    </svg>
  );
};

export default DownCaret;
