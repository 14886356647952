import { createAction } from '@reduxjs/toolkit';
import { FILTER_OPTIONS } from './typings';

const SET_FILTER = 'SET_FILTER';
export const setFilter = createAction<FILTER_OPTIONS>(SET_FILTER);

const SET_ACTIVE_TAB = 'SET_ACTIVE_TAB';
export const setActiveTab = createAction<number>(SET_ACTIVE_TAB);

const SET_WEEK_NUMBER = 'SET_WEEK_NUMBER';
export const setWeekNumber = createAction<number | undefined>(SET_WEEK_NUMBER);
