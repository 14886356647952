import React from 'react'
import 'react-loading-skeleton/dist/skeleton.css'
import { LoadFlex, LoadCardContainer, LoadRowContainer, LoadTitle } from './components'
import './styleLoader.css'

export const LoadNextUp = () => {
    return (
        <>
            <LoadTitle  className='loader'/>
            <LoadRowContainer>
                <LoadFlex>
                    <LoadCardContainer className='loader' />
                </LoadFlex>
                <LoadFlex>
                    <LoadCardContainer className='loader' />
                </LoadFlex>
                <LoadFlex>
                    <LoadCardContainer className='loader' />
                </LoadFlex>
            </LoadRowContainer >
        </>
    )
}
