import { ContentContentful } from 'models/ContentContentful';
import { baseService } from 'modules/baseService';
import HttpClient from 'services/HttpClient';
import { getLastUpdatedAtQuery } from 'helpers/helperFunctions';

export const getContents = baseService<string | undefined, ContentContentful[]>(
  (lastUpdatedAt) =>
    HttpClient.get(
      `/contentful/contents${getLastUpdatedAtQuery(lastUpdatedAt)}`,
    ),
);
