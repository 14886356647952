import React from 'react';
import { INLINES } from '@contentful/rich-text-types';

import MissingContentfulRender from 'components/contentful-renders/missing-contentful-render';
import { BlockNodeProps } from 'components/contentful-renders/typings';
import MadlibInputContent from './MadlibInputContent';
import { MadlibAnswer } from 'models/Applet';

export type EmbeddedEntryInlineProps = BlockNodeProps & {
  appletId?: string;
  isMadlibEditMode?: boolean;
  madlibAnswers?: MadlibAnswer[];
  madlibAppletContentfulId?: string;
  quizAppletContentfulId?: string;
  quizContentfulId?: string;
  quizSectionContentfulId?: string;
  quizQuestionContentfulId?: string;
};

const EmbeddedEntryInline = ({
  node,
  appletId,
  isMadlibEditMode,
  madlibAnswers,
  children,
  madlibAppletContentfulId,
  quizAppletContentfulId,
  quizContentfulId,
  quizSectionContentfulId,
  quizQuestionContentfulId,
}: EmbeddedEntryInlineProps) => {
  const contentType = node.data.target.sys.contentType.sys.id;

  return contentType === 'madlibInput' ? (
    <MadlibInputContent
      node={node}
      appletId={appletId}
      isMadlibEditMode={isMadlibEditMode}
      madlibAnswers={madlibAnswers}
      children={children}
      madlibAppletContentfulId={madlibAppletContentfulId}
      quizAppletContentfulId={quizAppletContentfulId}
      quizContentfulId={quizContentfulId}
      quizSectionContentfulId={quizSectionContentfulId}
      quizQuestionContentfulId={quizQuestionContentfulId}
    />
  ) : (
    <MissingContentfulRender componentType={INLINES.EMBEDDED_ENTRY} />
  );
};

export default EmbeddedEntryInline;
