import { createReducer } from '@reduxjs/toolkit';
import { arrayToHash } from 'helpers/helperFunctions';
import { Quest } from 'models/Quest';
import { addCohort } from 'modules/settings/actions';
import { getAvailableWeeks } from '../weeks/actions';
import {
  getActiveQuest,
  getOldQuests,
  resetCompleteQuestAdded,
  resetHasAddedQuest,
  setLoadingAdditionalFinished,
  setLoadingFinished,
  setLoadingFirstFinished,
  setLoadingHomeFinished,
  setLoadingLastFinished,
  setLoadingScheduleCommitFinished,
} from './actions';
import { QuestsState } from './typings';

const initialState: QuestsState = {
  quests: {},
  loadingFirstFinished: false,
  loadingLastFinished: false,
  loadingHomeFinished: false,
  loadingFinished: false,
  loadingAdditionalFinished: false,
  loadingScheduleCommitFinished: false,
  hasAddedQuest: false,
  completeQuestAdded: true
};

export default createReducer<QuestsState>(initialState, (builder) =>
  builder
    .addCase(getActiveQuest.fulfilled, (state, action) => ({
      ...state,
      quests: {
        ...state.quests,
        [action.payload.quest.id]: action.payload.quest,
      },
      activeQuestId: action.payload.quest.id,
      errorContent: action.payload.errorContent
    }))
    .addCase(getOldQuests.fulfilled, (state, action) => ({
      ...state,
      quests: {
        ...state.quests,
        ...arrayToHash<Quest>(action.payload.quests, (quest) => quest.id),
      },
    }))
    .addCase(setLoadingFirstFinished, (state, action) => ({
      ...state,
      loadingFirstFinished: action.payload,
    }))
    .addCase(setLoadingLastFinished, (state, action) => ({
      ...state,
      loadingLastFinished: action.payload,
    }))
    .addCase(setLoadingHomeFinished, (state, action) => ({
      ...state,
      loadingHomeFinished: action.payload,
    }))
    .addCase(setLoadingFinished, (state, action) => ({
      ...state,
      loadingFinished: action.payload,
    }))
    .addCase(setLoadingScheduleCommitFinished, (state, action) => ({
      ...state,
      loadingScheduleCommitFinished: action.payload,
    }))
    .addCase(setLoadingAdditionalFinished, (state, action) => ({
      ...state,
      loadingAdditionalFinished: action.payload,
    }))
    .addCase(getAvailableWeeks.fulfilled, (state, action) => ({
      ...state,
      quests: {
        ...state.quests,
        [action.meta.arg.questId]: {
          ...state.quests[action.meta.arg.questId],
          weekIds: action.payload.map((week) => week.id),
        },
      },
    }))
    .addCase(addCohort.fulfilled, (state, _) => ({
      ...state,
      hasAddedQuest: true,
      completeQuestAdded: false
    }))
    .addCase(resetHasAddedQuest, (state, _) => ({
      ...state,
      hasAddedQuest: false,
    }))
    .addCase(resetCompleteQuestAdded, (state, _) => ({
      ...state,
      completeQuestAdded: true,
    })),
);
