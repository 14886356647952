import React, { useContext, useMemo } from 'react';
import { BLOCKS } from '@contentful/rich-text-types';
import { nanoid } from '@reduxjs/toolkit';

import { NestingContext, NestingProvider } from '../NestingProvider';
import { UlList } from './components';

const UnorderedList = ({ node, children }) => {
  const nestingContext = useContext(NestingContext);

  const ulNestingLevel = useMemo(
    () =>
      nestingContext.ulNestingLevel ? nestingContext.ulNestingLevel + 1 : 1,
    [nestingContext],
  );

  if (node.nodeType === BLOCKS.UL_LIST) {
    return (
      <NestingProvider
        ulNestingLevel={ulNestingLevel}
        olNestingLevel={nestingContext.olNestingLevel}>
        <UlList>
          {children &&
            children instanceof Array &&
            children.map((Child) => (
              <Child listType="unordered" key={nanoid()} />
            ))}
        </UlList>
      </NestingProvider>
    );
  }
  return null;
};

export default UnorderedList;
