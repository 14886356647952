import React from 'react';

type GreenRibbonMedalIconProps = {
  width: number;
  height: number;
};

const GreenRibbonMedalIcon = ({ width, height }: GreenRibbonMedalIconProps) => {
  return (
    <svg width={width} height={height} viewBox="0 0 22 30">
      <g id="V3" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="Profile---Achievements"
          transform="translate(-316.000000, -628.000000)"
          fillRule="nonzero">
          <g id="Achievements-2" transform="translate(16.000000, 614.000000)">
            <g id="Badges/Green" transform="translate(300.000000, 14.000000)">
              <circle
                id="Oval"
                fill="#FFDC61"
                cx="10.8667"
                cy="18.7333"
                r="10.6667"></circle>
              <path
                d="M16.1469,17.4122 C16.0401,17.1447 15.826,16.9842 15.613,16.9307 L12.7302,16.5028 L11.5023,13.828 C11.3956,13.5605 11.1286,13.4 10.8617,13.4 C10.5948,13.4 10.3279,13.5605 10.2205,13.828 L8.99322,16.5028 L6.1104,16.9307 C5.84347,16.9842 5.62993,17.1447 5.57655,17.4122 C5.46924,17.6797 5.57655,17.9471 5.73617,18.1076 L7.8182,20.2474 L7.33773,23.2432 C7.28435,23.5107 7.39165,23.7781 7.60466,23.9386 C7.8182,24.0991 8.08513,24.0991 8.35206,23.9921 L10.8617,22.6012 L13.3708,23.9921 C13.5838,24.0991 13.9047,24.0991 14.1177,23.9386 C14.3318,23.7781 14.4385,23.5107 14.3846,23.2432 L13.9047,20.2474 L15.9862,18.1076 C16.2003,17.9471 16.2531,17.6262 16.1469,17.4122 Z"
                id="Path"
                fill="#E0AA1E"></path>
              <path
                d="M12.2847,1.04672 C12.47,0.767686 12.7827,0.599976 13.1177,0.599976 L18.6058,0.599976 C19.403,0.599976 19.8797,1.48707 19.4398,2.15184 L14.8197,9.13331 L8.77844,9.13331 C7.98053,9.13331 7.50398,8.24472 7.94543,7.58005 L12.2847,1.04672 Z"
                id="Path"
                fill="#6EDB9A"></path>
              <path
                d="M2.09197,1.84258 C1.73881,1.31085 2.12004,0.599976 2.75837,0.599976 L8.72297,0.599976 C8.99095,0.599976 9.24112,0.734144 9.38938,0.957367 L14.8196,9.13331 L7.36329,9.13331 C7.09532,9.13331 6.84515,8.99914 6.69689,8.77592 L2.09197,1.84258 Z"
                id="Path"
                fill="#5DEF98"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default GreenRibbonMedalIcon;
