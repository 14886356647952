import React, { PropsWithChildren } from 'react';
import { PreviewCard } from '../../components';

type PreviewCardProps = {
  backgroundUrl?: string;
};

const PreviewCardContainer = ({
  backgroundUrl,
  children,
}: PropsWithChildren<PreviewCardProps>) => (
  <PreviewCard backgroundUrl={backgroundUrl}>
    {!backgroundUrl && children}
  </PreviewCard>
);

export default PreviewCardContainer;
