import React from 'react';
import {
  Main,
  FirstChild,
  SecondChild,
  ThirdChild,
  FourthChild,
} from './components';

type LoadingSpinnerProps = {
  size: number;
  marginLeft?: number;
  color?: string;
};

const LoadingSpinner = ({ size, marginLeft = 0, color }: LoadingSpinnerProps) => {
  return (
    <Main size={size} marginLeft={marginLeft}>
      <FirstChild size={size} color={color}></FirstChild>
      <SecondChild size={size} color={color}></SecondChild>
      <ThirdChild size={size} color={color}></ThirdChild>
      <FourthChild size={size} color={color}></FourthChild>
    </Main>
  );
};

export default LoadingSpinner;
