import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { reportLoadingErrors as reportLoadingErrorsService } from './services';

export const CLEAR_ERROR_MESSAGE = 'CLEAR_ERROR_MESSAGE';
export const clearErrorMessage = createAction<undefined>(CLEAR_ERROR_MESSAGE);

export const SET_INTERNET_CONNECTION = 'SET_INTERNET_CONNECTION';
export const setInternetConnection = createAction<boolean | null>(
  SET_INTERNET_CONNECTION,
);

const REPORT_LOADING_ERRORS = 'REPORT_LOADING_ERRORS';
export const reportLoadingErrors = createAsyncThunk(
  REPORT_LOADING_ERRORS,
  reportLoadingErrorsService,
);
