import React, { useCallback, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import ContentLoader from 'react-content-loader';

import {
  selecteSelectedContentPostTitle,
  selectSelectedContent,
  selectSelectedContentCompletedSteps,
  selectSelectedContentSubtitle,
  selectSelectedContentTitle,
  selectSelectedContentTotalSteps,
  selectSelectedStepIsCelebrate,
} from 'modules/loading-screen/contents/selectors';
import {
  clearSelectedContent,
  selectContentById,
} from 'modules/loading-screen/contents/actions';
import ChallengeDetails from './challenge-details';
import StepList from './step-list';
import Step from './step';
import NavBar from 'modules/navbar';
import {
  ChallengeContainer,
  ChallengeHeaderContainer,
  ChallengeStepTitle,
  ChallengeSubTitle,
  ChallengeTitle,
  Container,
  SectionContainer,
  StepContainer,
} from './components';
import Celebrate from './celebrate';
import { CHALLENGE } from 'routes';
import { ChallengeTitlesContainer } from 'modules/home/components';
import { selectSelectedStepTitle } from 'modules/loading-screen/contents/selectors';
import { selectStep } from 'modules/loading-screen/contents/actions';
import { selectContentsContentful } from 'modules/loading-screen/contentful/contents/selectors';
import NavigationWarning from './navigation-warning';
import SyncContent from 'components/sync-content';
import useToastErrorMessage from 'hooks/useToastErrorMessage';
import LoadingNewWeek from 'modules/home/LoadingNewWeek';
import useLoadAdditionalContent from 'hooks/useLoadAdditionalContent';
import useLoadLastContent from 'hooks/use-load-content/useLoadLastContent';

export type ChallengeRouteParams = {
  id: string;
  step: string;
};

const Challenge = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  useLoadLastContent();
  useLoadAdditionalContent();
  const { id, step } = useParams<ChallengeRouteParams>();

  const challenge = useSelector(selectSelectedContent);
  const completedSteps = useSelector(selectSelectedContentCompletedSteps);

  const title = useSelector(selectSelectedContentTitle);
  const contentsContentful = useSelector(selectContentsContentful);
  const subtitle = useSelector(selectSelectedContentSubtitle);
  const selectedStepTitle = useSelector(selectSelectedStepTitle);
  const selectedPostTitle = useSelector(selecteSelectedContentPostTitle);
  const selectedContentTotalSteps = useSelector(
    selectSelectedContentTotalSteps,
  );
  const isCelebrate = useSelector(selectSelectedStepIsCelebrate);

  const stepTitle = useMemo(
    () => selectedStepTitle || selectedPostTitle,
    [selectedStepTitle, selectedPostTitle],
  );

  useEffect(() => {
    dispatch(selectContentById(id));
    return () => {
      dispatch(clearSelectedContent());
    };
  }, [dispatch, id]);

  useEffect(() => {
    if (step && selectedContentTotalSteps > 0) {
      dispatch(selectStep(+step));
    }
  }, [dispatch, step, selectedContentTotalSteps]);

  useEffect(() => {
    if (!step && challenge) {
      let stepToSelect: number;
      if (challenge.completed || completedSteps.length === 0) {
        stepToSelect = 0;
      } else {
        const maxCompletedStep = Math.max(...completedSteps);
        stepToSelect = maxCompletedStep + 1;
      }
      if (stepToSelect !== undefined) {
        history.replace(`${CHALLENGE}/${id}/${stepToSelect}`);
      }
    }
  }, [challenge, completedSteps, history, id, step]);

  const goBackEventListener = useCallback(
    (e: PopStateEvent) => {
      e.preventDefault();
      history.push(history.location);
    },
    [history],
  );

  useEffect(() => {
    window.addEventListener('popstate', goBackEventListener);
    return () => {
      window.removeEventListener('popstate', goBackEventListener);
    };
  }, [goBackEventListener]);

  useToastErrorMessage();

  return (
    <>
      <NavBar />
      <LoadingNewWeek />
      <SyncContent onlyChallenge />
      <NavigationWarning />
      <Container>
        {challenge ? (
          <ChallengeContainer>
            <ChallengeHeaderContainer>
              <ChallengeTitlesContainer>
                <ChallengeTitle>{title}</ChallengeTitle>
                <ChallengeSubTitle>{subtitle}</ChallengeSubTitle>
                <ChallengeStepTitle>{stepTitle}</ChallengeStepTitle>
              </ChallengeTitlesContainer>
              <ChallengeDetails
                challenge={contentsContentful[challenge.contentfulId]}
              />
            </ChallengeHeaderContainer>
            <StepList challenge={challenge} />
            <StepContainer>
              {isCelebrate ? <Celebrate /> : <Step />}
            </StepContainer>
          </ChallengeContainer>
        ) : (
          <ChallengeContainer>
            <ContentLoader
              height={180}
              width={'100%'}
              backgroundColor="#f0f0f0"
              foregroundColor="#dedede">
              <rect x="0" y="0" rx="3" ry="3" width="25%" height="33" />
              <rect x="0" y="40" rx="3" ry="3" width="15%" height="24" />
              <rect x="0" y="100" rx="3" ry="3" width="20%" height="32" />
              <rect x="75%" y="0" rx="3" ry="3" width="25%" height="52" />
            </ContentLoader>
            <SectionContainer>
              <ContentLoader
                height={550}
                width={'100%'}
                backgroundColor="#f0f0f0"
                foregroundColor="#dedede">
                <rect x="0" y="0" rx="5" ry="5" width="40%" height="20" />
                <rect x="0" y="42" rx="5" ry="5" width="100%" height="400" />
                <rect x="0" y="465" rx="5" ry="5" width="100%" height="10" />
                <rect x="0" y="485" rx="5" ry="5" width="100%" height="10" />
                <rect x="0" y="505" rx="5" ry="5" width="100%" height="10" />
                <rect x="0" y="525" rx="5" ry="5" width="100%" height="10" />
              </ContentLoader>
            </SectionContainer>
          </ChallengeContainer>
        )}
      </Container>
    </>
  );
};

export default Challenge;
