import styled from '@emotion/styled';

export const NavBarContainer = styled.div`
  background-color: ${(props) => props?.theme?.colors.blue3[100]};
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  min-height: 4.5rem;
`;

export const NavBarLeft = styled.div`
  background-color: ${(props) => props?.theme?.colors.blue3[100]};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 0 0.8rem;
`;

export const NavBarRight = styled.div`
  background-color: ${(props) => props?.theme?.colors.blue3[100]};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 0 0.8rem;
  margin-right: 4rem;
`;

export const NavBarContact = styled.div`
  flex: 1;
`;

export const NavBarContactImage = styled.img`
  height: 1.7rem;
  width: 5.3rem;
  @media (min-width: ${(props) => props?.theme?.breakpoints?.largeTablet}px) {
    height: 2.2rem;
    width: 6.3rem;
  }
`;

export const NavBarLine = styled.img`
  height: 2.4rem;
  margin-left: 0.6rem;
  @media (min-width: ${(props) => props?.theme?.breakpoints?.largeTablet}px) {
    height: 3.2rem;
    margin-left: 1.6rem;
  }
`;

export const NavBarLogo = styled.img`
  height: 2rem;
  flex: 1;
  margin-left: 0;
  @media (min-width: ${(props) => props?.theme?.breakpoints?.largeTablet}px) {
    height: 2.77rem;
    flex: unset;
    margin-left: 3.16rem;
  }
`;

export const NavBarName = styled.p`
  color: ${(props) => props?.theme?.colors.white[100]};
  font-family: ${(props) => props?.theme?.typography.semiBold};
  margin-right: 1.38rem;
  margin-bottom: 0;
  font-size: ${(props) => props?.theme?.typography.large16};
  margin-left: 0.67rem;
  flex: 1;
  @media (min-width: ${(props) => props?.theme?.breakpoints?.largeTablet}px) {
    flex: unset;
    font-size: 1.3rem;
    margin-left: 1.67rem;
  }
`;
