import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'redux/typings';

const selectFiles = (state: RootState) => state.files;

export const selectIsUploadingFile = createSelector(
  selectFiles,
  (files) => files.inProgress,
);

export const selectHasFailed = createSelector(
  selectFiles,
  (files) => files.failed ?? false,
);

export const selectProgressForCurrentUploads = createSelector(
  selectFiles,
  (files) =>
    Object.keys(files.uploads).length
      ? Object.values(files.uploads).reduce(
          (acum, cur) => acum + (cur.progress ?? 0),
          0,
        ) / Object.keys(files.uploads).length
      : 0,
);
