import { PLAYLIST_FILTERS } from './../modules/home/playlist/typings';
import { Content } from 'models/Content';
import { Feedback } from 'models/Feedback';
import { Artifact } from 'modules/home/playlist/typings';
import { filtersDefinitions } from 'modules/home/playlist/utils';

export const sleep = (timeInMilliseconds: number): Promise<void> => {
  return new Promise((resolve) =>
    setTimeout(() => resolve(), timeInMilliseconds),
  );
};

export const convertToQueryString = (
  prefix: string,
  values: string[],
): string => {
  let query = '';
  values.forEach((value, index) => {
    if (index === 0) {
      query = query + `${prefix}=${value}`;
    } else {
      query = query + `&${prefix}=${value}`;
    }
  });
  return query;
};

export const arrayToHash = <T>(
  array: T[],
  keyExtractor: (object: T) => string,
  initialHash: Record<string, T> = {},
) =>
  array.reduce(
    (acc: Record<string, T>, element) => {
      acc[keyExtractor(element)] = element;
      return acc;
    },
    { ...initialHash },
  );

export const getLastUpdatedAtQuery = (lastUpdatedAt?: string) =>
  lastUpdatedAt ? `?lastUpdatedAt=${lastUpdatedAt}` : '';

export const calculateProgress = (content: Content) => {
  const completed = content.completed;
  const steps = content.stepIds.length;
  const completedSteps = content.completedSteps?.length || 0;
  return completed ? 100 : (completedSteps / (steps + 1)) * 100;
};

export const calculateFeedbacksAverage = (feedbacks: Feedback[] | undefined) =>
  feedbacks && feedbacks.length > 0
    ? feedbacks.reduce((acc, feedback) => acc + feedback.score, 0) /
      feedbacks.length
    : 0;

export const getFilteredPlaylists = (
  filterType: PLAYLIST_FILTERS,
  acc: Record<PLAYLIST_FILTERS, Artifact[]>,
  item: Artifact,
) => {
  if (filtersDefinitions[filterType].includes(item.type)) {
    if (!acc[filterType]) {
      acc[filterType] = [];
    }
    if (filterType === PLAYLIST_FILTERS.LISTEN) {
      if (item.type === 'reading' && item.reading?.audioUrl) {
        acc[filterType].push(item);
      }
    } else {
      acc[filterType].push(item);
    }
  }
};

export const getWeeklyOverviewEmptyElement = (contentId?: string) => ({
  title: `Missing Content: ${contentId}`,
  totalXp: 0,
  stepIds: [],
  imageUrl:
    'https://fuelingnewbusiness.com/wp-content/uploads/2017/10/1-MISSING-550x224.jpeg',
  contentUser: undefined,
  isLate: false,
  completedSteps: [],
  totalSteps: 0,
  bonusChallenge: false,
});
