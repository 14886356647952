import * as React from 'react';

type WritingIconProps = {
  width: number | string;
  height: number | string;
  color?: string;
};

const WritingIcon = ({ width, height, color = '#FFFF' }: WritingIconProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={color}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.71 7.04006C21.1 6.65006 21.1 6.00006 20.71 5.63006L18.37 3.29006C18 2.90006 17.35 2.90006 16.96 3.29006L15.12 5.12006L18.87 8.87006L20.71 7.04006ZM3 17.2501V21.0001H6.75L17.81 9.93006L14.06 6.18006L3 17.2501Z"
        fill={color}
      />
    </svg>
  );
};

export default WritingIcon;
