import React from 'react';

type ClearIconProps = {
    color?: string;
};

export const ClearIcon = ({ color = '#747474' }: ClearIconProps) => {
    return (
        <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13 1.30929L11.6907 0L6.5 5.19071L1.30929 0L0 1.30929L5.19071 6.5L0 11.6907L1.30929 13L6.5 7.80929L11.6907 13L13 11.6907L7.80929 6.5L13 1.30929Z" fill={color} />
        </svg>
    );
};
