import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectQuestsHash } from 'modules/loading-screen/quests/selectors';
import { selectQuestsContentful } from 'modules/loading-screen/contentful/quests/selectors';
import WeekSummary from './WeekSummary';
import { selectWeekNumber } from '../selectors';
import {
  selectWeeksHash,
  selectWeeksProgress,
} from 'modules/loading-screen/weeks/selectors';
import { selectWeeksContentful } from 'modules/loading-screen/contentful/weeks/selectors';

interface QuestSummaryProps {
  questId: string;
}

const QuestSummary = ({ questId }: QuestSummaryProps) => {
  const questsHash = useSelector(selectQuestsHash);
  const questsContentful = useSelector(selectQuestsContentful);
  const weekHash = useSelector(selectWeeksHash);
  const weekProgress = useSelector(selectWeeksProgress);
  const weekNumber = useSelector(selectWeekNumber);
  const weeksContentful = useSelector(selectWeeksContentful);

  const quest = useMemo(() => questsHash[questId], [questsHash, questId]);
  const weekMap = useMemo(() => {
    const ret: Record<string, string> = {};
    for (const id of quest?.weekIds || []) {
      const week = weekHash[id];
      ret[week.contentfulId] = week.id;
    }

    return ret;
  }, [quest, weekHash]);

  const questContentful = useMemo(
    () => quest && questsContentful[quest.contentfulId],
    [quest, questsContentful],
  );

  return (
    <>
      {questContentful?.weekIds.map((weekId, weekIndex) => (
        <WeekSummary
          isOpen={weeksContentful[weekId]?.weekNumber === weekNumber}
          weekId={weekId}
          weekUserId={weekMap[weekId]}
          progress={weekProgress[weekIndex]?.progress}
          isLastWeek={weekIndex === questContentful?.weekIds?.length - 1}
          key={weekId}
        />
      ))}
    </>
  );
};

export default QuestSummary;
