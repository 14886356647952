import * as yup from 'yup';

import localizedStrings from 'localization';

export const showcaseEmailSchema = yup.object().shape({
  firstName: yup.string().required(localizedStrings.firstNameRequired),
  lastName: yup.string().required(localizedStrings.lastNameRequired),
  email: yup
    .string()
    .email(localizedStrings.emailValidMessage)
    .required(localizedStrings.emailRequiredMessage),
  message: yup.string().required(localizedStrings.messageRequired),
});
