import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import { selectAchievements } from 'modules/profile/selectors';
import { Achievement, ACHIEVEMENT_TYPE } from 'modules/profile/typings.d';
import ReviewsModal from './ReviewsModal';
import AchievementCard from './AchievementCard';
import { ContentContentful } from 'models/ContentContentful';
import { selectContentsContentful } from 'modules/loading-screen/contentful/contents/selectors';
import { ModalState } from 'modules/profile/portfolio/quest-applets';
import { QuestApplet } from 'models/QuestApplet';
import AppletPreviewModal from 'modules/profile/portfolio/quest-applets/AppletPreviewModal';
import useActionStatus from 'hooks/useActionStatus';
import { CenterLoader } from 'components/loading-spinner/components';
import LoadingSpinner from 'components/loading-spinner';
import { getQuestApplets } from 'modules/applets/actions';
import theme from 'utils/theme';

const AchievementsList = () => {
  const location = useLocation();
  const [openModal, setOpenModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState<{
    achievement: Achievement;
    content: ContentContentful;
  } | null>(null);
  const [modalState, setModalState] = useState<ModalState>({});

  const openAppletPreviewModal = (applet: QuestApplet) => {
    setModalState({ appletPreviewModalOpen: true, applet });
  };
  const achievements = useSelector(selectAchievements);
  const contentsContentful = useSelector(selectContentsContentful);
  const [isPendingAchievements, wasFulfilledAchievements] = useActionStatus(getQuestApplets);
  const shouldShowLoader = useMemo(() => isPendingAchievements || !wasFulfilledAchievements, [isPendingAchievements, wasFulfilledAchievements]);


  const closeModal = useCallback(() => {
    setOpenModal(false);
  }, [setOpenModal]);

  const closeAppletPreviewModal = () => {
    setModalState({});
  };

  const handleAchievementClick = useCallback(
    (achievement: Achievement, content: ContentContentful) => {
      setSelectedItem({ achievement, content });
      setOpenModal(true);
    },
    [setOpenModal, setSelectedItem],
  );

  const renderAchievements = useMemo(
    () =>
      achievements.map((achievement) => (
        <AchievementCard
          achievement={achievement}
          onAchievementClick={handleAchievementClick}
          key={achievement.id}
        />
      )),
    [achievements, handleAchievementClick],
  );

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const challengeId = query.get('challengeId');
    if (challengeId) {
      const achievement = achievements.find(
        (achievement) =>
          achievement.contentContentfulId === challengeId &&
          achievement.type === ACHIEVEMENT_TYPE.CHALLENGE,
      );
      if (achievement) {
        const content = contentsContentful[achievement.contentContentfulId!];
        setSelectedItem({ achievement, content });
        setOpenModal(true);
      }
    }
  }, [achievements, contentsContentful, location.search]);

  return (
    <>
      <ReviewsModal
        achievement={selectedItem?.achievement}
        content={selectedItem?.content}
        isOpen={openModal}
        closeModal={closeModal}
        openAppletPreviewModal={openAppletPreviewModal}
      />
      {
        shouldShowLoader ?
          <CenterLoader>
            <LoadingSpinner size={50} color={theme.colors.blue1[100]} />
          </CenterLoader>
          :
          renderAchievements
      }
      <AppletPreviewModal
        open={modalState.appletPreviewModalOpen ?? false}
        applet={modalState.applet!}
        closeModal={closeAppletPreviewModal}
      />
    </>
  );
};

export default AchievementsList;
