import { createAsyncThunk, createAction } from '@reduxjs/toolkit';

import {
  getContentById as contentByIdService,
  submitPost as submitPostService,
  sharePost as sharePostService,
  getLinkToSharePost as getLinkToSharePostService,
  completeStep as completeStepService,
} from './services';
import { Content } from 'models/Content';
import {
  CompleteStepActionPayload,
  SubmitPostRawPayload,
  SubmitPostResponse,
} from './typings';
import { UploadFileResponse } from 'modules/files/typings';
import { uploadFiles } from 'modules/files/actions';
import { getQuestApplets } from 'modules/applets/actions';
import { getAchievements } from 'modules/profile/actions';
import {
  getAppletsToSend,
  getFilesToUpload,
  removeUntouchedExtraApplets,
} from 'helpers/StepsHelpers';
import { selectAppletsContentful } from '../contentful/applets/selectors';
import { selectStepAppletIds } from './selectors';

const GET_CONTENT_BY_ID = 'GET_CONTENT_BY_ID';
export const getContentById = createAsyncThunk<Content, string>(
  GET_CONTENT_BY_ID,
  contentByIdService,
);

const SELECT_CONTENT_BY_ID = 'SELECT_CONTENT_BY_ID';
export const selectContentById = createAction<string>(SELECT_CONTENT_BY_ID);

const CLEAR_SELECTED_CONTENT = 'CLEAR_SELECTED_CONTENT';
export const clearSelectedContent = createAction(CLEAR_SELECTED_CONTENT);

const SUBMIT_POST = 'SUBMIT_POST';
export const submitPost = createAsyncThunk<
  SubmitPostResponse,
  SubmitPostRawPayload,
  { dispatch: any; getState: any }
>(
  SUBMIT_POST,
  async (
    { submission, challengeId, appletsAnswers },
    { dispatch, getState },
  ) => {
    const state = getState() as any;
    const applets = Object.values(appletsAnswers || {});
    const appletsContentful = selectAppletsContentful(state);

    const filesToUpload = getFilesToUpload(applets);
    if (submission.file) {
      filesToUpload.push({
        id: challengeId,
        file: submission.file,
      });
    }

    let uploadedFiles: UploadFileResponse[] | undefined = [];
    let submissionFile: UploadFileResponse | undefined;
    if (filesToUpload.length > 0) {
      uploadedFiles = await uploadFiles(filesToUpload, dispatch);
      submissionFile = uploadedFiles?.find(
        (uplFile) => uplFile.id === challengeId,
      );
    }

    const appletsToSend = getAppletsToSend(
      uploadedFiles,
      applets,
      appletsContentful,
    );

    const ret = await submitPostService({
      submission: {
        reflectAnswers: submission.reflectAnswers,
        applets: appletsToSend,
        file: submissionFile
          ? {
            location: submissionFile.fileUrl,
            mimetype: submission.file?.mime || submission.file?.type || '',
          }
          : undefined,
      },
      challengeId,
    });

    dispatch(getQuestApplets());
    dispatch(getAchievements());

    return ret;
  },
);

const UPDATE_AVAILABLE_CONTENT = 'UPDATE_AVAILABLE_CONTENT';
export const updateAvailableContent = createAction<Content[]>(
  UPDATE_AVAILABLE_CONTENT,
);

const SHARE_POST = 'SHARE_POST';
export const sharePost = createAsyncThunk(SHARE_POST, sharePostService);

const LINK_TO_SHARE = 'LINK_TO_SHARE';
export const getLinkToSharePost = createAsyncThunk(LINK_TO_SHARE, getLinkToSharePostService);

const CLEAN_LINK_TO_SHARE = 'CLEAN_LINK_TO_SHARE';
export const cleanLinkToSharePost = createAction(CLEAN_LINK_TO_SHARE);

const SELECT_STEP = 'SELECT_STEP';
export const selectStep = createAction<number>(SELECT_STEP);

const UPDATE_ENABLE_CONTENT_EDITABLE = 'UPDATE_ENABLE_CONTENT_EDITABLE';
export const updateIsEditableContent = createAction<boolean>(
  UPDATE_ENABLE_CONTENT_EDITABLE,
);

const COMPLETE_STEP = 'COMPLETE_STEP';
export const completeStep = createAsyncThunk<
  Content,
  CompleteStepActionPayload,
  { dispatch: any; getState: any }
>(COMPLETE_STEP, async (args, { dispatch, getState }) => {
  const state = getState() as any;
  const allApplets = Object.values(args.appletsAnswers || {});
  const stepAppletsIds = selectStepAppletIds(state);
  const appletsContentful = selectAppletsContentful(state);
  const applets = removeUntouchedExtraApplets(
    allApplets,
    stepAppletsIds,
    appletsContentful,
  );

  const filesToUpload = getFilesToUpload(applets);
  const fileUrls = await uploadFiles(filesToUpload, dispatch);

  const appletsToSend = getAppletsToSend(fileUrls, applets, appletsContentful);

  const ret: Content = await completeStepService({
    ...args,
    applets: appletsToSend,
    platform: 'web',
  });
  dispatch(getQuestApplets());
  dispatch(getAchievements());
  return ret;
});
