import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'redux/typings';
import { getOldQuests, setLoadingAdditionalFinished } from 'modules/loading-screen/quests/actions';
import useActionStatus from './useActionStatus';
import { selectLoadingAdditionalFinished } from 'modules/loading-screen/quests/selectors';

type useLoadContentReturn = {
  loadFinished: boolean;
};

const useLoadAdditionalContent = (): useLoadContentReturn => {
  const dispatch = useDispatch<AppDispatch>();
  const loadingFinished = useSelector(selectLoadingAdditionalFinished);

  const [, oldQuestsFullfilled] = useActionStatus(getOldQuests);

  const loadFinished = useMemo(() => {
    const loadFinished = oldQuestsFullfilled;
    if (loadFinished) {
      dispatch(setLoadingAdditionalFinished(loadFinished));
    }
    return loadFinished
  }, [
    dispatch,
    oldQuestsFullfilled,
  ]);

  useEffect(() => {
    if (!loadingFinished) {
      dispatch(getOldQuests());
    }
  }, [dispatch, loadingFinished]);

  return { loadFinished };
};

export default useLoadAdditionalContent;
