import React from 'react';
import {
  QuizSectionDescription,
  QuizSectionSeparator,
  QuizSectionTitle,
} from 'components/contentful-renders/components';

type QuizSectionProps = {
  title?: string;
  description?: React.ReactNode;
  quizSectionQuestions: React.ReactNode;
};

const QuizSection = ({
  title,
  description,
  quizSectionQuestions,
}: QuizSectionProps) => {
  return (
    <>
      <QuizSectionTitle>{title}</QuizSectionTitle>
      <QuizSectionDescription>{description}</QuizSectionDescription>
      <QuizSectionSeparator />
      {quizSectionQuestions}
    </>
  );
};

export default QuizSection;
