import React from 'react';

type SignOutIconProps = {
    color?: string;
};

export const SignOutIcon = ({ color = '#484848' }: SignOutIconProps) => {
    return (
        <svg width="29" height="32" viewBox="0 0 29 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_3_22)">
                <mask id="mask0_3_22" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="0" y="0" width="29" height="32">
                    <path d="M29 0H0V32H29V0Z" fill="white" />
                </mask>
                <g mask="url(#mask0_3_22)">
                    <path d="M20.5417 9.33333L18.8379 11.2133L21.9554 14.6667H9.66669V17.3333H21.9554L18.8379 20.7733L20.5417 22.6667L26.5834 16L20.5417 9.33333ZM4.83335 6.66667H14.5V4H4.83335C3.50419 4 2.41669 5.2 2.41669 6.66667V25.3333C2.41669 26.8 3.50419 28 4.83335 28H14.5V25.3333H4.83335V6.66667Z" fill={color} />
                </g>
            </g>
            <defs>
                <clipPath id="clip0_3_22">
                    <rect width="29" height="32" fill="white" />
                </clipPath>
            </defs>
        </svg >

    );
};
