import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'redux/typings';

const selectOverviewState = (state: RootState) => state.overview;

export const selectOverviewFilter = createSelector(
  selectOverviewState,
  (overviewState) => overviewState.filter,
);

export const selectActiveTab = createSelector(
  selectOverviewState,
  (overviewState) => overviewState.activeTab,
);

export const selectWeekNumber = createSelector(
  selectOverviewState,
  (overviewState) => overviewState.weekNumber,
);
