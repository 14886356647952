import React, { useMemo } from 'react';

import BadgeIcon from '../../../components/icons/BadgeIcon';
import ClockIcon from '../../../components/icons/ClockIcon';
import {
  ChallengeIconsRow,
  PointsContainer,
  TimeContainer,
  Text,
  LocationContainer,
} from '../components';
import theme from 'utils/theme';
import useLocationIconText from '../../../hooks/useLocationIconText';
import localizedStrings from 'localization/en';

type ChallengeBasicInformationProps = {
  xp: number;
  time: number;
  small?: boolean;
  location: string;
  bonusChallenge?: boolean;
};

const ChallengeBasicInformation = ({
  xp = 0,
  time = 0,
  small = false,
  location,
  bonusChallenge,
}: ChallengeBasicInformationProps) => {
  const dimension = useMemo(
    () => (small ? theme.typography.large16 : theme.typography.large24),
    [small],
  );

  const [Icon, text] = useLocationIconText(location);

  return (
    <ChallengeIconsRow>
      <LocationContainer>
        <Icon
          width={dimension}
          height={dimension}
          color={theme.colors.white[100]}
        />
        <Text>{text === localizedStrings.phoneRecommended ? '' : text}</Text>
      </LocationContainer>
      <PointsContainer>
        <BadgeIcon width={dimension} height={dimension} />
        <Text small={small}>{`${xp}${localizedStrings.experience(
          bonusChallenge,
        )}`}</Text>
      </PointsContainer>
      <TimeContainer>
        <ClockIcon width={dimension} height={dimension} />
        <Text small={small}>{time} min</Text>
      </TimeContainer>
    </ChallengeIconsRow>
  );
};

export default ChallengeBasicInformation;
