import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { QuestApplet } from 'models/QuestApplet';
import { QuestPortfolio } from 'models/QuestPortfolio';
import { sortByQuestSelect } from 'modules/profile/selectors';
import { useAppletByFilterQuest } from 'hooks/useAppletByFilterQuest';
import { QuestDropdownItem } from './QuestDropdownItem';

export type QuestSectionItemsProps = {
  quests?: QuestPortfolio[];
  onAppletClick: (applet: QuestApplet) => void;
  selectedApplets?: string[];
  showTitles?: boolean;
  previewHeight?: string;
  previewWidth?: string;
};

export const QuestSectionItems = ({
  quests = [],
  onAppletClick,
}: QuestSectionItemsProps) => {
  const sortByQuest = useSelector(sortByQuestSelect);
  const { appletsByQuestTag, appletsByTagOrderedAll } = useAppletByFilterQuest(quests);

  const dropDownAppletsToRender = useMemo(
    () => (sortByQuest ? appletsByQuestTag : appletsByTagOrderedAll),
    [sortByQuest, appletsByTagOrderedAll, appletsByQuestTag],
  );

  return <>
    {dropDownAppletsToRender.map((dataItem, index) => (
      <QuestDropdownItem
        key={index}
        item={dataItem}
        onAppletClick={onAppletClick}
      />
    ))}
  </>
};
