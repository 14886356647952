import * as React from 'react';

type GestureIconProps = {
  width: number | string;
  height: number | string;
  color?: string;
};

const GestureIcon = ({ width, height, color = '#FFFF' }: GestureIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill={color}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.0001 9C10.0001 8.73478 10.1055 8.48043 10.293 8.29289C10.4805 8.10536 10.7349 8 11.0001 8C11.2653 8 11.5197 8.10536 11.7072 8.29289C11.8947 8.48043 12.0001 8.73478 12.0001 9V13.47L13.2101 13.6L18.1501 15.79C18.6801 16.03 19.0001 16.56 19.0001 17.14V21.5C18.9701 22.32 18.3201 22.97 17.5001 23H11.0001C10.6201 23 10.2601 22.85 10.0001 22.57L5.1001 18.37L5.8401 17.6C6.0301 17.39 6.3001 17.28 6.5801 17.28H6.8001L10.0001 19V9ZM11.0001 5C12.061 5 13.0784 5.42143 13.8285 6.17157C14.5787 6.92172 15.0001 7.93913 15.0001 9C15.0001 10.5 14.2001 11.77 13.0001 12.46V11.24C13.6101 10.69 14.0001 9.89 14.0001 9C14.0001 8.20435 13.684 7.44129 13.1214 6.87868C12.5588 6.31607 11.7957 6 11.0001 6C10.2044 6 9.44139 6.31607 8.87878 6.87868C8.31617 7.44129 8.0001 8.20435 8.0001 9C8.0001 9.89 8.3901 10.69 9.0001 11.24V12.46C7.8001 11.77 7.0001 10.5 7.0001 9C7.0001 7.93913 7.42153 6.92172 8.17167 6.17157C8.92182 5.42143 9.93923 5 11.0001 5Z"
        fill={color}
      />
    </svg>
  );
};

export default GestureIcon;
