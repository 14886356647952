import React from 'react';
import { BLOCKS } from '@contentful/rich-text-types';

import { BlockNodeProps } from 'components/contentful-renders/typings';
import MissingContentfulRender from 'components/contentful-renders/missing-contentful-render';
import {
  AudioTitle,
  Image,
  ImageCaption,
  ImageDescription,
  ImageTitle,
} from 'components/contentful-renders/components';

interface EmbeddedAssetProps extends Omit<BlockNodeProps, 'children'> {
  style: React.CSSProperties;
}

const EmbeddedAsset = ({ node, style }: EmbeddedAssetProps) => {
  if (node.nodeType === BLOCKS.EMBEDDED_ASSET) {
    if (node.data.target.fields.file.contentType.includes('image')) {
      const {
        file: { url },
        description,
        title,
      } = node.data.target.fields;
      return title || description ? (
        <div style={style}>
          <Image src={url} alt={description} />
          <ImageCaption>
            <ImageTitle>{`${title?.trim() || ''}${
              title ? ' ' : ''
            }`}</ImageTitle>
            <ImageDescription>{description?.trim() || ''}</ImageDescription>
          </ImageCaption>
        </div>
      ) : null;
    }
    if (node.data.target.fields.file.contentType.includes('audio')) {
      const {
        fields: {
          file: { url },
          title,
        },
      } = node.data.target;
      return (
        <div style={style}>
          <AudioTitle>{title}</AudioTitle>
          <audio src={`https:${url}`} controls />
        </div>
      );
    }
  }
  return <MissingContentfulRender componentType={BLOCKS.EMBEDDED_ASSET} />;
};

export default EmbeddedAsset;
