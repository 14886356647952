import React from 'react';

const SearchIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 24 24">
      <path
        d="M11.1 4.80005C14.5794 4.80005 17.4 7.62066 17.4 11.1C17.4 12.6237 16.8591 14.021 15.9589 15.1105L18.964 18.1155C19.1983 18.3498 19.1983 18.7297 18.964 18.964C18.7296 19.1983 18.3497 19.1983 18.1154 18.964L15.1104 15.9589C14.0209 16.8592 12.6236 17.4001 11.1 17.4001C7.62059 17.4001 4.79999 14.5794 4.79999 11.1C4.79999 7.62066 7.62059 4.80005 11.1 4.80005ZM11.1 6.00005C8.28334 6.00005 5.99999 8.2834 5.99999 11.1C5.99999 13.9167 8.28334 16.2001 11.1 16.2001C13.9166 16.2001 16.2 13.9167 16.2 11.1C16.2 8.2834 13.9166 6.00005 11.1 6.00005Z"
        fill="#8E8E93"
      />
    </svg>
  );
};

export default SearchIcon;
