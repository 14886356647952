import { createReducer } from '@reduxjs/toolkit';
import { arrayToHash } from 'helpers/helperFunctions';
import { QuestContentful } from 'models/QuestContentful';
import { getQuests } from './actions';
import { QuestsState } from './typings';

const initialState: QuestsState = {
  quests: {},
};

export default createReducer<QuestsState>(initialState, (builder) =>
  builder.addCase(getQuests.fulfilled, (state, action) => ({
    quests: arrayToHash<QuestContentful>(
      action.payload,
      (quest) => quest.id,
      state.quests,
    ),
    lastUpdatedAt: new Date().toISOString(),
  })),
);
