import React from 'react';

type AlertIconProps = {
    color?: string;
};

export const AlertIcon = ({ color = '#484848' }: AlertIconProps) => {
    return (
        <svg width="24" height="31" viewBox="0 0 24 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 31C13.65 31 15 29.5692 15 27.8205H9C9 29.5692 10.35 31 12 31ZM21 21.4615V13.5128C21 8.63231 18.555 4.54667 14.25 3.46564V2.38462C14.25 1.06513 13.245 0 12 0C10.755 0 9.75 1.06513 9.75 2.38462V3.46564C5.46 4.54667 3 8.61641 3 13.5128V21.4615L0 24.641V26.2308H24V24.641L21 21.4615ZM18 23.0513H6V13.5128C6 9.57026 8.265 6.35897 12 6.35897C15.735 6.35897 18 9.57026 18 13.5128V23.0513Z" fill={color} />
        </svg>
    );
};
