import { createReducer } from '@reduxjs/toolkit';

import {
  getPlaylist,
  updatePlaylistFilter,
  markArtifactCompleted,
  updateAvailableList,
  updatePlaylistSelected,
  getPlaylistSelected,
} from './actions';
import { PlaylistState, PLAYLIST_FILTERS } from './typings';

const initialState: PlaylistState = {
  list: {},
  filter: PLAYLIST_FILTERS.ALL,
  availableList: false,
  listSelected: [],
};

export default createReducer(initialState, (builder) => {
  builder
    .addCase(getPlaylist.fulfilled, (state, action) => ({
      ...state,
      list: action.payload,
    }))
    .addCase(updatePlaylistFilter, (state, action) => ({
      ...state,
      filter: action.payload,
    }))
    .addCase(markArtifactCompleted, (state, action) => ({
      ...state,
      list: {
        ...state.list,
        [action.payload.weekId]: {
          ...state.list[action.payload.weekId],
          [action.payload.id]: {
            ...state.list[action.payload.weekId][action.payload.id],
            completed: true,
          },
        },
      },
    }))
    .addCase(updateAvailableList, (state, action) => ({
      ...state,
      availableList: action.payload,
    }))
    .addCase(updatePlaylistSelected, (state, action) => ({
      ...state,
      listSelected: action.payload,
    }))
    .addCase(getPlaylistSelected.fulfilled, (state, action) => ({
      ...state,
      listSelected: action.payload,
    }));
});
