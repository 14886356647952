import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  getShowcase as getShowcaseService,
  sendEmail as sendEmailService,
} from './services';
import { Showcase, ShowcaseEmail } from './typings';

const GET_SHOWCASE = 'GET_SHOWCASE';
export const getShowcase = createAsyncThunk<Showcase, string>(
  GET_SHOWCASE,
  getShowcaseService,
);

const SEND_EMAIL = 'SEND_EMAIL';
export const sendEmail = createAsyncThunk<void, ShowcaseEmail>(
  SEND_EMAIL,
  sendEmailService,
);
