import { Block, Inline } from '@contentful/rich-text-types';
import { Next, NodeRenderer } from '@contentful/rich-text-html-renderer';
import { AppletContentful } from 'models/AppletContentful';
import { QuestApplet } from 'models/QuestApplet';
import { MadlibAnswer } from '../../../../models/Applet';

export const getEmbeddedEntryHtml = (
  _matchedAppletContentful: AppletContentful,
  _matchedAppletUser?: QuestApplet,
  _madlibAnswers?: MadlibAnswer[],
) =>
  ((_node: Block | Inline, _next: Next) => {
    return 'The Embedded entry feature is not yet supported.';
  }) as NodeRenderer;
