import React from 'react';

type SvgTextProps = {
  text: string;
  color: string;
};

const SvgText = ({ text, color }: SvgTextProps) => {
  return (
    <text
      x="50%"
      y="65%"
      textAnchor="middle"
      fontFamily="OpenSans, Open Sans"
      fontSize="10"
      fill={color}>
      {text}
    </text>
  );
};

export default SvgText;
