import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Tooltip } from '@material-ui/core';

import NavBar from '../navBar';
import ShowcaseBottomItems from '../bottom';
import {
  ShowCaseButtonsContainer,
  ShowcaseContainer,
  ShowcaseItemsContainer,
  ShowcaseTimelineButton,
  ShowcaseOverviewTitle,
  ShowcaseTextAndButtons,
  ShowCaseTopImage,
  ShowCaseTopImageOpacity,
  ShowcaseImageAndTitle,
} from './ShowcaseOverviewComponents';
import GridOn from '../../../assets/icons/GridOn.webp';
import GridOff from '../../../assets/icons/GridOff.webp';
import TimelineOn from '../../../assets/icons/TimelineOn.webp';
import TimelineOff from '../../../assets/icons/TimelineOff.webp';
import Camera from '../../../assets/icons/Camera.webp';
import ShowcaseGrid from './components/Grid';
import ShowCaseTimeline from './components/Timeline';
import { SHOWCASE, SHOWCASE_RESUME_VIDEO } from '../../../routes';
import { QuestApplet } from 'models/QuestApplet';
import ShowcaseModal from './components/ShowcaseModal';
import localizedStrings from 'localization';
import { selectBackgroundUrl, selectShowcaseTitle } from '../selectors';

type ModalState = {
  open: boolean;
  applet?: QuestApplet;
};

const ShowcaseOverview = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [isSelectedGrid, setSelectedGrid] = useState(false);
  const [modalState, setModalState] = useState<ModalState>({ open: false });
  const backgroundUrl = useSelector(selectBackgroundUrl);
  const showcaseTitle = useSelector(selectShowcaseTitle);

  const changeToTimeline = useCallback(() => setSelectedGrid(false), []);
  const changeToGrid = useCallback(() => setSelectedGrid(true), []);

  const goToResume = useCallback(() => {
    history.push(`${SHOWCASE}/${id + SHOWCASE_RESUME_VIDEO}`);
  }, [history, id]);

  const openModal = (applet: QuestApplet) => {
    setModalState({ open: true, applet });
  };

  const closeModal = () => {
    setModalState({ open: false });
  };

  return (
    <ShowcaseContainer>
      <NavBar />
      <ShowcaseItemsContainer>
        <ShowcaseImageAndTitle>
          <ShowCaseTopImage src={backgroundUrl} />
          <ShowCaseTopImageOpacity />
          <ShowcaseTextAndButtons>
            <ShowcaseOverviewTitle>{showcaseTitle}</ShowcaseOverviewTitle>
            <ShowCaseButtonsContainer>
              <Tooltip title={localizedStrings.overview}>
                <ShowcaseTimelineButton
                  onClick={goToResume}
                  isSelectedGrid={false}>
                  <img src={Camera} alt="grid" />
                </ShowcaseTimelineButton>
              </Tooltip>
              <Tooltip title={localizedStrings.gridView}>
                <ShowcaseTimelineButton
                  onClick={changeToGrid}
                  isSelectedGrid={isSelectedGrid}>
                  <img src={isSelectedGrid ? GridOn : GridOff} alt="grid" />
                </ShowcaseTimelineButton>
              </Tooltip>
              <Tooltip title={localizedStrings.timelineView}>
                <ShowcaseTimelineButton
                  onClick={changeToTimeline}
                  isSelectedGrid={!isSelectedGrid}>
                  <img
                    src={isSelectedGrid ? TimelineOff : TimelineOn}
                    alt="timeline"
                  />
                </ShowcaseTimelineButton>
              </Tooltip>
            </ShowCaseButtonsContainer>
          </ShowcaseTextAndButtons>
        </ShowcaseImageAndTitle>

        <ShowcaseModal modalState={modalState} closeModal={closeModal} />
        {isSelectedGrid ? (
          <ShowcaseGrid onAppletClick={openModal} />
        ) : (
          <ShowCaseTimeline onAppletClick={openModal} />
        )}
        <ShowcaseBottomItems />
      </ShowcaseItemsContainer>
    </ShowcaseContainer>
  );
};

export default ShowcaseOverview;
