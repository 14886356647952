import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'redux/typings';
import { ShowcaseApplet } from './typings';

const selectShowcaseState = (state: RootState) => state.showcase;

export const selectShowcase = createSelector(
  selectShowcaseState,
  (showcaseState) => showcaseState.showcase,
);

export const selectUserDisplayName = createSelector(
  selectShowcase,
  (showcase) => showcase?.userDisplayName ?? '',
);

export const selectApplets = createSelector(
  selectShowcase,
  (showcase) => showcase?.applets ?? [],
);

export const selectAppletsMap = createSelector(selectApplets, (apps) =>
  apps.reduce(
    (acc, app) => ({
      ...acc,
      [app.contentfulId]: app,
    }),
    {} as Record<string, ShowcaseApplet>,
  ),
);

export const selectShowcaseAppletById = (appletId: string) =>
  createSelector(selectAppletsMap, (map) => map[appletId]);

export const selectFeaturedApplets = createSelector(
  selectShowcase,
  (showcase) => showcase?.featuredApplets ?? [],
);

export const selectHeadlineApplets = createSelector(
  selectShowcase,
  (showcase) => showcase?.headlineApplets ?? [],
);

export const selectCallToAction = createSelector(
  selectShowcase,
  (showcase) => showcase?.callToAction ?? '',
);

export const selectBackgroundUrl = createSelector(
  selectShowcase,
  (showcase) => showcase?.backgroundUrl ?? '',
);

export const selectVideoUrl = createSelector(
  selectShowcase,
  (showcase) => showcase?.videoUrl ?? '',
);

export const selectShowcaseTitle = createSelector(
  selectShowcase,
  (showcase) => showcase?.title ?? '',
);
