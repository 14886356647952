import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import Avatar from 'components/avatar';
import ChatIcon from 'components/icons/ChatIcon';
import {
  RelatedUserName,
  RelatedUserRelation,
  ChatButton,
  ChatButtonText,
} from '../../components';
import { RELATIONSHIPS } from 'routes';
import localizedStrings from 'localization';

type RelatedUserInfoProps = {
  displayName: string;
  profilePictureUrl?: string | null;
  relationshipType?: string;
  hideChat?: boolean;
};

const RelatedUserInfo = ({
  displayName,
  profilePictureUrl,
  relationshipType,
  hideChat,
}: RelatedUserInfoProps) => {
  const history = useHistory();
  const handleChatClick = useCallback(() => {
    history.push(RELATIONSHIPS);
  }, [history]);

  return (
    <>
      <Avatar displayName={displayName} uri={profilePictureUrl} size={189} />
      <RelatedUserName>{displayName}</RelatedUserName>
      <RelatedUserRelation>{relationshipType}</RelatedUserRelation>
      {!hideChat && (
        <ChatButton>
          <ChatButtonText onClick={handleChatClick}>
            {localizedStrings.chat}
          </ChatButtonText>
          <ChatIcon />
        </ChatButton>
      )}
    </>
  );
};

export default RelatedUserInfo;
