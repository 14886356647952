import React from 'react';

import theme from 'utils/theme';

type CompletedIconEmptyProps = {
  width?: string;
  height?: string;
  alternativeIcon?: boolean;
};

const CompletedIconEmpty = ({
  width = theme.typography.large24,
  height = theme.typography.large24,
  alternativeIcon,
}: CompletedIconEmptyProps) =>
  alternativeIcon ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 576 512"
      width={width}
      height={height}>
      {/* <!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --> */}
      <path
        fill="#F4BF5D"
        d="M381.2 150.3L524.9 171.5C536.8 173.2 546.8 181.6 550.6 193.1C554.4 204.7 551.3 217.3 542.7 225.9L438.5 328.1L463.1 474.7C465.1 486.7 460.2 498.9 450.2 506C440.3 513.1 427.2 514 416.5 508.3L288.1 439.8L159.8 508.3C149 514 135.9 513.1 126 506C116.1 498.9 111.1 486.7 113.2 474.7L137.8 328.1L33.58 225.9C24.97 217.3 21.91 204.7 25.69 193.1C29.46 181.6 39.43 173.2 51.42 171.5L195 150.3L259.4 17.97C264.7 6.954 275.9-.0391 288.1-.0391C300.4-.0391 311.6 6.954 316.9 17.97L381.2 150.3z"
      />
    </svg>
  ) : (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ verticalAlign: 'top' }}>
      <rect
        x="1"
        y="1"
        width="18"
        height="18"
        rx="9"
        stroke="#F4BF5D"
        strokeWidth="2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.35708 12.9715C9.52242 12.9715 9.65316 12.8985 9.74545 12.7562L13.3792 7.03451C13.4484 6.923 13.4753 6.8384 13.4753 6.74996C13.4753 6.53848 13.3369 6.40005 13.1254 6.40005C12.9716 6.40005 12.887 6.45004 12.7947 6.59615L9.3417 12.0987L7.54983 9.75308C7.4537 9.61849 7.35757 9.56466 7.21914 9.56466C6.99996 9.56466 6.85 9.71462 6.85 9.92611C6.85 10.0146 6.88845 10.1145 6.96151 10.2068L8.95718 12.7485C9.07253 12.8985 9.19174 12.9715 9.35708 12.9715Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.93421 12.8786C9.80221 13.0821 9.60184 13.1965 9.35708 13.1965C9.2387 13.1965 9.12858 13.1697 9.02691 13.1117C8.92829 13.0554 8.84853 12.9761 8.77956 12.8866L6.7851 10.3465C6.78499 10.3463 6.78489 10.3462 6.78478 10.3461C6.68887 10.2248 6.625 10.0772 6.625 9.92611C6.625 9.76563 6.68288 9.61573 6.79447 9.5058C6.90592 9.396 7.05704 9.33966 7.21914 9.33966C7.3195 9.33966 7.41801 9.35971 7.51141 9.41272C7.60117 9.46367 7.67129 9.53664 7.73069 9.61919L9.32356 11.7043L12.6045 6.47601C12.6598 6.38843 12.7271 6.30616 12.8223 6.24974C12.9203 6.19168 13.0238 6.17505 13.1254 6.17505C13.2812 6.17505 13.4292 6.22703 13.5388 6.3366C13.6483 6.44617 13.7003 6.59413 13.7003 6.74996C13.7003 6.893 13.6539 7.01859 13.5703 7.15317L13.5691 7.15514L9.93421 12.8786ZM12.7947 6.59615C12.887 6.45004 12.9716 6.40005 13.1254 6.40005C13.3369 6.40005 13.4753 6.53848 13.4753 6.74996C13.4753 6.8384 13.4484 6.923 13.3792 7.03451L9.74545 12.7562C9.65316 12.8985 9.52242 12.9715 9.35708 12.9715C9.19174 12.9715 9.07253 12.8985 8.95718 12.7485L6.96151 10.2068C6.88845 10.1145 6.85 10.0146 6.85 9.92611C6.85 9.71462 6.99996 9.56466 7.21914 9.56466C7.35757 9.56466 7.4537 9.61849 7.54983 9.75308L9.3417 12.0987L12.7947 6.59615Z"
        fill="white"
      />
    </svg>
  );
export default CompletedIconEmpty;
