import React, { useMemo } from 'react';

import { BlockNodeProps } from 'components/contentful-renders/typings';
import { Container } from './components';
import theme from 'utils/theme';
import { ITextCardFields } from 'models/contentful';
import useContentfulRenderer from 'hooks/useContentfulRenderer';

type TextCardPorps = Omit<BlockNodeProps, 'children'>;

const TextCard = ({ node }: TextCardPorps) => {
  const textCardStyle = useMemo(
    () => node.data.target.fields.style as ITextCardFields['style'],
    [node],
  );

  const [
    backgroundColor,
    textColor,
    color,
    hoverColor,
    visitedColor,
    focusColor,
  ] = useMemo(() => {
    return [
      theme.colors.textCard[textCardStyle],
      theme.colors.textCardText[textCardStyle],
      theme.colors.textCardLink[textCardStyle],
      theme.colors.textCardLinkHover[textCardStyle],
      theme.colors.textCardLinkVisited[textCardStyle],
      theme.colors.textCardLinkFocus[textCardStyle],
    ];
  }, [textCardStyle]);

  const renderedContent = useContentfulRenderer(
    node.data.target.fields.content,
    {
      additionalStyles: {
        paragraph: {
          color: textColor,
        },
      },
      linkColors: {
        color,
        hoverColor,
        visitedColor,
        focusColor,
        bold: true,
      },
    },
  );

  return (
    <Container backgroundColor={backgroundColor}>{renderedContent}</Container>
  );
};

export default TextCard;
