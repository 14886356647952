export const LOADING_SCREEN_ERRORS = [
  'errorNotFoundActiveQuest',
  'errorGetWeeksForQuestNoQuest',
  'errorWeekContentNoQuestDef',
  'errorWeekContentNoWeekUser',
  'errorGetScheduleConfigNoQuest',
  'errorGetScheduleConfigNoWeekSpec',
  'errorGetScheduleConfigNoWeekContentful',
  'errorContentWeekDefMissing',
  'errorContentWeekDefPlaylistMissing'
];


export const LOADING_SCREEN_ERRORS_CONTENT = [
  'errorWeekContentNoContentContentful',
];
