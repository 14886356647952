import React from 'react';
import { IQuizQuestion } from '../../../../models/contentful';
import { QuestionTitle } from '../components';
import QuestionOptions from '../quiz-question/QuestionOptions';
import { QuizSectionSeparator } from '../../../contentful-renders/components';
import QuizQuestionOptionsListProfileAndSubmission from '../quiz-question/QuizQuestionOptionsListProfileAndSubmission';
import { QuizQuestion } from 'models/Applet';
import { QuizQuestionAnswerType } from 'models/QuizQuestionAnswerType';
import { QuizSlider } from '../quiz-question/QuizQuestionSliderList';

type QuizSectionQuestionsListProfileAndSubmissionProps = {
  questions: IQuizQuestion[];
  quizQuestionAnswers?: QuizQuestion[];
};

const QuizSectionQuestionsListProfileAndSubmission = ({
  questions,
  quizQuestionAnswers,
}: QuizSectionQuestionsListProfileAndSubmissionProps) => {
  return (
    <>
      {questions?.map((question: IQuizQuestion) => {
        const foundQuestion = quizQuestionAnswers?.find(
          (q) => q.quizQuestionContentfulId === question.sys.id,
        );

        let foundQuestionAnswer: string | undefined = undefined;
        if (foundQuestion) {
          foundQuestionAnswer =
            foundQuestion.answerType === QuizQuestionAnswerType.MADLIB
              ? foundQuestion?.madlibAppletContentfulId
              : foundQuestion.answer;
        }

        const marks = Array.from(
          Array(question.fields.maximumValue).keys(),
        ).map((val) => ({
          value: val + 1,
          label: val + 1,
        }));

        return (
          <div key={question.sys.id}>
            <QuestionTitle>{question?.fields?.title}</QuestionTitle>
            <QuestionOptions
              questionOptions={
                question.fields.options ? (
                  <QuizQuestionOptionsListProfileAndSubmission
                    question={question}
                    answer={foundQuestionAnswer}
                    madlibAnswers={foundQuestion?.madlibAnswers}
                    questionType={foundQuestion?.answerType}
                  />
                ) : (
                  <QuizSlider
                    value={foundQuestion?.value}
                    valueLabelDisplay="off"
                    step={1}
                    marks={marks}
                    min={question.fields.minimumValue}
                    max={question.fields.maximumValue}
                    disabled={true}
                  />
                )
              }
            />

            <QuizSectionSeparator />
          </div>
        );
      })}
    </>
  );
};

export default QuizSectionQuestionsListProfileAndSubmission;
