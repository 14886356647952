import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { groupChallengeApplets } from 'helpers/AppletHelpers';
import SubmittedAppletsSwitch from './SubmittedAppletsSwitch';
import { selectSelectedContentApplets } from 'modules/loading-screen/contents/selectors';
import { selectAppletsContentful } from 'modules/loading-screen/contentful/applets/selectors';

const SubmittedApplets = () => {
  const applets = useSelector(selectSelectedContentApplets);
  const appletsContentful = useSelector(selectAppletsContentful);

  const groupedApplets = useMemo(
    () => groupChallengeApplets(applets),
    [applets],
  );

  return (
    <>
      {groupedApplets.map((app) => (
        <SubmittedAppletsSwitch
          key={app.appletId}
          applet={app}
          appletContentful={appletsContentful[app.appletId]}
        />
      ))}
    </>
  );
};

export default SubmittedApplets;
