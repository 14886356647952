import React from 'react';

type EditIconProps = {
  color?: string;
};

const EditIcon = ({ color = '#007DC2' }: EditIconProps) => {
  return (
    <svg width="32px" height="32px" viewBox="0 0 20 21">
      <title>C839F032-B541-460A-8548-868495AAC3B6</title>
      <g id="V3" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="Profile---Achievements"
          transform="translate(-208.000000, -103.000000)">
          <g id="Group-3" transform="translate(148.000000, 44.000000)">
            <g id="Icons/24/Edit" transform="translate(60.000000, 59.789474)">
              <ellipse
                id="Oval"
                fill={color}
                cx="10"
                cy="10.1052632"
                rx="10"
                ry="10.1052632"></ellipse>
              <path
                d="M12.7748917,6.36191663 L13.703325,7.30012632 C14.0827,7.68213895 14.097875,8.29306105 13.7494667,8.69401263 L13.704025,8.74300632 L12.6340333,9.82124632 L12.6354167,9.82285474 L12.1592333,10.3040505 L12.15725,10.3020295 L8.73921667,13.7488421 C8.48670833,14.0035032 8.14453333,14.1465347 7.78778333,14.1465347 L6.673225,14.1465347 C6.30129917,14.1465347 5.9997975,13.8418611 5.9997975,13.4660211 L5.9997975,12.3247663 C5.9997975,11.9636463 6.1418175,11.6173305 6.39459,11.3620632 L11.3461083,6.36168168 C11.7408583,5.96350333 12.3802667,5.96360867 12.7748917,6.36191663 Z M10.277725,8.40272 L6.87061667,11.8434105 C6.74423333,11.9710484 6.673225,12.1442021 6.673225,12.3247663 L6.673225,13.2958905 C6.673225,13.3898526 6.7486,13.4660211 6.841575,13.4660211 L7.78778333,13.4660211 C7.96615833,13.4660211 8.13724167,13.3945095 8.2635,13.2671747 L11.6794583,9.81920842 L10.277725,8.40272 Z M11.8221333,6.84303158 L10.754175,7.9216 L12.1562417,9.33842526 L13.2579583,8.22698947 C13.3583,8.09379368 13.34815,7.90317474 13.2271417,7.78132211 L12.2987083,6.84311579 L12.2672,6.81506526 C12.13485,6.71118484 11.9435417,6.72057432 11.8221333,6.84303158 Z"
                id="Shape"
                fill="#FFFFFF"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default EditIcon;
