import React from 'react';
import { IQuizQuestion } from '../../../../models/contentful';

import QuizOptionProfileAndSubmission from './QuizOptionProfileAndSubmission';
import { MadlibAnswer } from 'models/Applet';

type QuizQuestionOptionsListProfileAndSubmissionProps = {
  question: IQuizQuestion;
  answer?: string;
  madlibAnswers?: MadlibAnswer[];
  questionType?: string;
};

const QuizQuestionOptionsListProfileAndSubmission = ({
  question,
  answer,
  madlibAnswers,
  questionType,
}: QuizQuestionOptionsListProfileAndSubmissionProps) => {
  return (
    <>
      {question.fields.options?.map((option) => (
        <QuizOptionProfileAndSubmission
          option={option}
          answer={answer}
          madlibAnswers={madlibAnswers}
          key={option.sys.id}
          questionType={questionType}
        />
      ))}
    </>
  );
};

export default QuizQuestionOptionsListProfileAndSubmission;
