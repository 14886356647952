import styled from '@emotion/styled';

type ContainerProps = {
  backgroundColor: string;
};

export const Container = styled.div<ContainerProps>`
  width: 100%;
  border-radius: 8px;
  margin: 1rem 0;
  background-color: ${(props) => props.backgroundColor};
  padding: ${(props) => props.theme?.typography.large32};
`;
