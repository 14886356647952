import { useEffect, useState, SetStateAction, Dispatch } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { selectErrorMessage } from 'modules/error/selectors';
import localizedStrings from 'localization';
import { clearErrorMessage } from 'modules/error/actions';

const useErrorMessage = (): [string, Dispatch<SetStateAction<string>>] => {
  const dispatch = useDispatch();
  const errorIndex = useSelector(selectErrorMessage);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (errorIndex) {
      setErrorMessage(
        (localizedStrings as any)[errorIndex] || localizedStrings.errorDefault,
      );
      dispatch(clearErrorMessage());
    }
  }, [dispatch, errorIndex]);

  return [errorMessage, setErrorMessage];
};

export default useErrorMessage;
