import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, FormikProps } from 'formik';
import { useTheme } from '@emotion/react';
import { toast } from 'react-toastify';

import Modal from 'components/modal';
import {
  ButtonsRow,
  FellowTravelersList,
  SearchInput,
} from 'modules/relationships/components';
import RelationshipCard from 'modules/relationships/home/RelationshipCard';
import SearchIcon from 'components/icons/SearchIcon';
import { AppDispatch } from 'redux/typings';
import { selectRelationshipsList } from 'modules/relationships/selectors';
import localizedStrings from 'localization';
import { getRelationships } from 'modules/relationships/actions';
import {
  CancelButton,
  ShareButton,
  ShareModalContent,
  ShareModalSearchBox,
  SharePrompt,
  SimpleLink,
  SimpleLinkCopied,
} from '../components';
import { Relationship } from 'models/Relationship';
import InputField from 'components/input-field';
import { shareSubmissionSchema } from '../celebrate/validationSchema';
import { cleanLinkToSharePost, getLinkToSharePost, sharePost } from 'modules/loading-screen/contents/actions';
import useActionStatus from 'hooks/useActionStatus';
import { selectLinkToShare } from 'modules/loading-screen/contents/selectors';

type InitialValuesType = {
  relationship?: Relationship;
  message: string;
};

const initialValues: InitialValuesType = {
  relationship: undefined,
  message: '',
};

type ShareModalProps = {
  open: boolean;
  close: () => void;
  challengeId: string;
  postId: string;
};

const ShareModal = ({ open, close, challengeId, postId }: ShareModalProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const theme = useTheme();
  const textInputRef = useRef<any>(null);

  const [filterValue, setFilterValue] = useState('');
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const formikRef = useRef<FormikProps<InitialValuesType>>(null);
  const relationships = useSelector(selectRelationshipsList);
  const [isPending, wasFulfilled] = useActionStatus(sharePost);
  const [isPendingCopyLink] = useActionStatus(getLinkToSharePost);
  const linkCopied = useSelector(selectLinkToShare)

  const filteredRelationships = useMemo(
    () =>
      relationships.filter((relationship) =>
        relationship.relatedUser.displayName
          .toLowerCase()
          .includes(filterValue.toLowerCase()),
      ),
    [relationships, filterValue],
  );

  const handleFilterChange = useCallback((evt) => {
    setFilterValue(evt.target.value);
  }, []);

  const handleClose = useCallback(() => {
    dispatch(cleanLinkToSharePost())
    setFilterValue('');
    formikRef.current?.resetForm();
    close();
  }, [close, dispatch]);

  const handleSubmit = useCallback(
    (values: InitialValuesType) => {
      dispatch(sharePost({
        challengeId,
        postId,
        message: values.message,
        userId: values.relationship?.relatedUser.id!,
      }));
      setHasSubmitted(true);
    },
    [challengeId, dispatch, postId],
  );

  const copyLink = useCallback((values: InitialValuesType) => {
    dispatch(getLinkToSharePost({
      userId: values.relationship?.relatedUser.id!,
      postId,
    }))
  }, [dispatch, postId])

  useEffect(() => {
    const copyToClipboard = () => {
      if (navigator?.clipboard) {
        navigator.clipboard.writeText(linkCopied)
          .catch(err => console.error('Error al copiar al portapapeles:', err));
      } else {
        textInputRef.current.select();
        document.execCommand('copy');
      }
    };

    linkCopied && copyToClipboard()
  }, [linkCopied])

  useEffect(() => {
    if (relationships.length === 0) {
      dispatch(getRelationships());
    }
  }, [dispatch, relationships.length]);

  useEffect(() => {
    if (hasSubmitted && wasFulfilled) {
      setHasSubmitted(false);
      handleClose();

      toast.success(localizedStrings.emailSuccess, {
        autoClose: 5000,
        style: { backgroundColor: theme?.colors.success },
      });
    }
  }, [handleClose, hasSubmitted, theme, wasFulfilled]);

  return (
    <Modal
      open={open}
      closeModal={handleClose}
      width="516px"
      title={localizedStrings.shareYourWork}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={shareSubmissionSchema}
        innerRef={formikRef}>
        {({ values, setFieldValue }) => (
          <ShareModalContent>
            {values.relationship ? (
              <>
                <RelationshipCard
                  relationship={values.relationship}
                  type="shareForm"
                />
                <SharePrompt>{localizedStrings.shareMessagePrompt}</SharePrompt>
                <InputField
                  name="message"
                  placeholder="Message"
                  textarea
                  rows={10}
                />
                <ButtonsRow>
                  <CancelButton type="button" onClick={handleClose}>
                    {localizedStrings.cancel}
                  </CancelButton>
                  <ShareButton type="submit" isLoading={isPending}>
                    {localizedStrings.share}
                  </ShareButton>
                </ButtonsRow>
                <ButtonsRow>
                  {!linkCopied ?
                    <SimpleLink onClick={() => copyLink(values)}>
                      {isPendingCopyLink ?
                        `${localizedStrings.copying}...`
                        : localizedStrings.linkToCopy}
                    </SimpleLink> :
                    <SimpleLinkCopied>
                      {localizedStrings.linkCopied}
                    </SimpleLinkCopied>}
                </ButtonsRow>
              </>
            ) : (
              <>
                <ShareModalSearchBox>
                  <SearchIcon />
                  <SearchInput
                    type="text"
                    name="filter"
                    value={filterValue}
                    onChange={handleFilterChange}
                    placeholder={localizedStrings.searchPlaceholder}
                  />
                </ShareModalSearchBox>
                <FellowTravelersList>
                  {filteredRelationships.map((relationship) => (
                    <RelationshipCard
                      key={relationship.id}
                      relationship={relationship}
                      onCardClick={(relationship) =>
                        setFieldValue('relationship', relationship)
                      }
                      type="share"
                    />
                  ))}
                </FellowTravelersList>
              </>
            )}
          </ShareModalContent>
        )}
      </Formik>
    </Modal>
  );
};

export default ShareModal;
