import styled from '@emotion/styled';

import { Card } from 'react-bootstrap';

export const ShowCaseActionButton = styled.button`
  border: none;
  padding: 0;
  background-color: ${(props) => props?.theme?.colors.blue2[100]};
  border-radius: 8px;
  color: ${(props) => props?.theme?.colors.white[100]};
  font-size: ${(props) => props?.theme?.typography.large20};
  height: 2.77rem;
  margin-bottom: 2.77rem;
  width: 14.83rem;
`;

export const ShowcaseContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  overflow-x: hidden;
`;

export const ShowCaseImageActionButton = styled.img`
  height: 2.77rem;
  margin-top: 4.72rem;
`;

export const ShowcaseImagesButton = styled.button`
  background-color: transparent;
  border: none;
  padding: 0;
  max-height: 22.22rem;
  margin-bottom: 1.7rem;
  max-width: 22.22rem;
`;

export const ShowCaseImagesContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  margin-top: -11rem;
  width: 100%;
  flex-direction: column;
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
  @media (min-width: ${(props) => props?.theme?.breakpoints.tablet}px) {
    flex-direction: row;
  }
`;

export const ShowcaseItemsContainer = styled.div`
  ${(props) => `
    align-items: center;
    background-color: ${props?.theme?.colors.blue3[100]};
    display: flex;
    flex-direction: column;
    z-index: 3;
  `}
`;

export const ShowCaseTopImageContainer = styled.div`
  height: 27rem;
  width: 100%;
  position: relative;
`;

export const ShowCaseTopImage = styled(Card.Img)`
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center center;
`;

export const ShowCaseTopImageOpacity = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: black;
  opacity: 0.5;
  z-index: 2;
`;

export const ShowCaseVideo = styled.video`
  max-height: 50vh;
  max-width: 70%;
`;

export const ShowCaseVideoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  align-items: center;
  @media (min-width: ${(props) => props?.theme?.breakpoints.largeTablet}px) {
    margin-top: 4rem;
  }
`;

export const ShowCaseVideoTitle = styled.p`
  font-size: ${(props) => props?.theme?.typography.large32};
  font-weight: ${(props) => props?.theme?.typography.bold};
  text-align: center;
  margin-bottom: 14rem;
  margin-top: 1rem;
  @media (min-width: ${(props) => props?.theme?.breakpoints.largeTablet}px) {
    font-size: ${(props) => props?.theme?.typography.large60};
  }
`;
