import styled from '@emotion/styled';

export const EmptyPlaylistCardsContainer = styled.div`
  height: 100%;
  width: 100%;
  border-radius: 0.6rem;
  background-color: ${(props) => props?.theme?.colors.weekProgressBackground};
  color: ${(props) => props?.theme?.colors.white[100]};
  align-items: center;
  justify-items: center;
  text-align: center;
  padding: 10% 0;
`;

export const MessageContainer = styled.div``;

export const EmptyPlaylistTitle = styled.p`
  font-size: ${(props) => props?.theme?.typography.large32};
  font-weight: ${(props) => props?.theme?.typography.bold};
  text-align: center;
`;

export const EmptyPlaylistText = styled.p`
  font-size: ${(props) => props?.theme?.typography.large24};
  text-align: center;
`;
