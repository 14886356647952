import { useCallback } from 'react';

import { QuestApplet } from 'models/QuestApplet';

const useIsAppletSelected = (selectedApplets: string[]) => {
  const isSelected = useCallback(
    (applet: QuestApplet) => {
      if (applet.appletType === 'group-applet') {
        const ids = applet.applets?.map((app) => app.id) || [];
        return ids.every((id) => selectedApplets.includes(id));
      } else {
        return selectedApplets.includes(applet.id);
      }
    },
    [selectedApplets],
  );

  return isSelected;
};

export default useIsAppletSelected;
