import React, { useCallback, useMemo, ReactNode, useState } from 'react';
import { useSelector } from 'react-redux';

import { BlockNodeProps } from 'components/contentful-renders/typings';
import ExamplesIcon from '../../../../modules/challenge/step-aditional-info/examples-icon/index';
import TipsIcon from '../../../../modules/challenge/step-aditional-info/tips-icon/index';
import RubricsIcon from '../../../../modules/challenge/step-aditional-info/rubrics-icon/index';
import GoDeeperIcon from '../../../../modules/challenge/step-aditional-info/go-deeper-icon/index';
import localizedStrings from 'localization/en';
import useContentfulRenderer from 'hooks/useContentfulRenderer';
import { selectSelectedStepAdditionalInfo } from '../../../../modules/loading-screen/contents/selectors';
import Modal from 'components/modal';
import { LinkProps, LinkText } from 'components/contentful-renders/components';
import { useHistory } from 'react-router';
import { PROFILE, RELATIONSHIPS, SETTINGS_GET_HELP } from 'routes';
import { IResourceLinkFields } from 'models/contentful';

type ModalStateType = {
  open: boolean;
  title?: string;
  titleIcon?: ReactNode;
  content?: ReactNode;
};

type RoutesMapType = {
  [key in IResourceLinkFields['link']]?: string;
};

const routesMap: RoutesMapType = {
  profile: PROFILE,
  help: SETTINGS_GET_HELP,
  relationships: RELATIONSHIPS,
  showcase: PROFILE,
  notebook: PROFILE,
};

type ResourceLinkProps = BlockNodeProps & LinkProps;

const ResourceLink = ({ node, children, ...linkProps }: ResourceLinkProps) => {
  const history = useHistory();
  const [modalState, setModalState] = useState<ModalStateType>({
    open: false,
    title: undefined,
    titleIcon: undefined,
    content: undefined,
  });

  const selectedStepAdditionalInfo = useSelector(
    selectSelectedStepAdditionalInfo,
  );
  const goDeeperContent = useContentfulRenderer(
    selectedStepAdditionalInfo?.goDeeper,
    renderStyles,
  );
  const examplesContent = useContentfulRenderer(
    selectedStepAdditionalInfo?.examples,
    renderStyles,
  );
  const rubricsContent = useContentfulRenderer(
    selectedStepAdditionalInfo?.rubrics,
    renderStyles,
  );
  const tipsContent = useContentfulRenderer(
    selectedStepAdditionalInfo?.tips,
    renderStyles,
  );

  const exampleModalTitle = useMemo<[string, ReactNode]>(() => {
    return [localizedStrings.examples, <ExamplesIcon />];
  }, []);

  const tipsModalTitle = useMemo<[string, ReactNode]>(() => {
    return [localizedStrings.tips, <TipsIcon />];
  }, []);
  const rubricsModalTitle = useMemo<[string, ReactNode]>(() => {
    return [localizedStrings.rubrics, <RubricsIcon />];
  }, []);
  const goDeeperModalTitle = useMemo<[string, ReactNode]>(() => {
    return [localizedStrings.goDeeper, <GoDeeperIcon />];
  }, []);

  const getModalTitle = useCallback(
    (resourceType: string) => {
      switch (resourceType) {
        case 'examples':
          return exampleModalTitle;
        case 'tips':
          return tipsModalTitle;
        case 'rubrics':
          return rubricsModalTitle;
        case 'goDeeper':
          return goDeeperModalTitle;
        default:
          return [undefined, undefined];
      }
    },
    [exampleModalTitle, tipsModalTitle, rubricsModalTitle, goDeeperModalTitle],
  );

  const getModalContent = useCallback(
    (resourceType: string) => {
      switch (resourceType) {
        case 'examples':
          return examplesContent;
        case 'tips':
          return tipsContent;
        case 'rubrics':
          return rubricsContent;
        case 'goDeeper':
          return goDeeperContent;
        default:
          return null;
      }
    },
    [examplesContent, tipsContent, rubricsContent, goDeeperContent],
  );

  const openModal = useCallback(
    (resourceType: string) => () => {
      const [title, titleIcon] = getModalTitle(resourceType);
      const content = getModalContent(resourceType);
      setModalState({ open: true, title, titleIcon, content });
    },
    [getModalContent, getModalTitle],
  );

  const closeModal = useCallback(() => {
    setModalState({ open: false });
  }, []);

  const handleClick = useCallback(() => {
    const link = node.data.target.fields.link as IResourceLinkFields['link'];
    const route = routesMap[link];
    if (route) {
      history.push(route);
    } else {
      openModal(node.data.target.fields.link)();
    }
  }, [history, node.data.target.fields.link, openModal]);

  return (
    <>
      <Modal
        open={modalState.open}
        title={modalState.title}
        titleIcon={modalState.titleIcon}
        closeModal={closeModal}
        width="516px">
        {modalState.content}
      </Modal>
      <LinkText onClick={handleClick} {...linkProps}>
        {children}
      </LinkText>
    </>
  );
};

const renderStyles = {
  additionalStyles: { embeddedAsset: { alignSelf: 'center' } },
};

export default ResourceLink;
