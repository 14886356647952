import { createReducer } from '@reduxjs/toolkit';
import { arrayToHash } from 'helpers/helperFunctions';
import { ContentContentful } from 'models/ContentContentful';
import { getContents } from './actions';
import { ContentsState } from './typings';

const initialState: ContentsState = {
  contents: {},
};

export default createReducer<ContentsState>(initialState, (builder) =>
  builder.addCase(getContents.fulfilled, (state, action) => ({
    contents: arrayToHash<ContentContentful>(
      action.payload,
      (content) => content.id,
      state.contents as Record<string, ContentContentful>,
    ),
    lastUpdatedAt: new Date().toISOString(),
  })),
);
