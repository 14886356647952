import { WeekOnboarding } from 'models/WeekOnboarding';
import { baseService } from 'modules/baseService';
import HttpClient from 'services/HttpClient';
import { getLastUpdatedAtQuery } from 'helpers/helperFunctions';

export const getWeeksOnboardings = baseService<
  string | undefined,
  WeekOnboarding[]
>((lastUpdatedAt) =>
  HttpClient.get(
    `/contentful/weeks/onboardings${getLastUpdatedAtQuery(lastUpdatedAt)}`,
  ),
);
