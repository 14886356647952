import { createReducer } from '@reduxjs/toolkit';
import { ShowcaseState } from './typings';
import { getShowcase, sendEmail } from './actions';
import { toast } from 'react-toastify';

const initialState: ShowcaseState = {};

export default createReducer<ShowcaseState>(initialState, (builder) =>
  builder
    .addCase(getShowcase.fulfilled, (_, action) => ({
      showcase: action.payload,
    }))
    .addCase(sendEmail.rejected, (state, _) => {
      toast.error(
        'There was an error sending your email. Please try again later',
      );
      return state;
    }),
);
