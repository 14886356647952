import React, { useMemo } from 'react';
import ExperienceSummary from 'modules/profile/achievements/experience-summary';
import { ProfileSectionContentContainer } from 'modules/profile/components';
import { useSelector } from 'react-redux';
import {
  selectProfileMenuSelected,
  selectUserTotalBp,
  selectUserTotalXp,
} from 'modules/profile/selectors';
import Achievements from 'modules/profile/achievements';
import Portfolio from 'modules/profile/portfolio';
import PublishedShowcases from 'modules/profile/portfolio/published-showcases';
import ManageQuests from 'modules/settings/components/manage-quests/ManageQuests';

export const ProfileSectionSelected = () => {
  const userTotalXp = useSelector(selectUserTotalXp);
  const userTotalBp = useSelector(selectUserTotalBp);
  const menuSelected = useSelector(selectProfileMenuSelected);

  const renderItem = useMemo(() => {
    switch (menuSelected) {
      case 'ManageQuests':
        return <ManageQuests />;
      case 'AchievementsList':
        return <Achievements />;
      case 'NotebookGrid':
        return <Portfolio />;
      case 'ShowcasesList':
        return <PublishedShowcases />;
      default:
        return <Achievements />;
    }
  }, [menuSelected]);

  return (
    <ProfileSectionContentContainer>
      <ExperienceSummary xp={userTotalXp} bp={userTotalBp} />
      {renderItem}
    </ProfileSectionContentContainer>
  );
};
