import React from 'react';
import useContentfulRenderer from 'hooks/useContentfulRenderer';
import theme from 'utils/theme';
import { IReadingFields } from '../../models/contentful';

interface ReadingContentProps {
  readingContent?: Omit<IReadingFields, 'subtitle' | 'byline'>;
}

const WebReading = ({ readingContent }: ReadingContentProps) => {
  const renderReadingContent = useContentfulRenderer(readingContent?.content, {
    additionalStyles: {
      paragraph: {
        color: theme.colors.black,
      },
    },
  });

  return <div>{renderReadingContent}</div>;
};

export default WebReading;
