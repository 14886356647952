import React from 'react';

const CirclePlus = () => {
  return (
    <svg width="22px" height="27px" viewBox="0 0 22 27">
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd">
        <g
          id="Setup-your-schedule-2"
          transform="translate(-1091.000000, -301.000000)">
          <g id="Group-14-Copy" transform="translate(784.000000, 292.000000)">
            <g id="Group-3-Copy-3" transform="translate(307.000000, 9.000000)">
              <circle id="Oval" fill="#007DC2" cx="11" cy="14" r="11"></circle>
              <text
                id="+"
                fontFamily="OpenSans, Open Sans"
                fontSize="20"
                fontWeight="normal"
                fill="#FFFFFF">
                <tspan x="5.78222656" y="21">
                  +
                </tspan>
              </text>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default CirclePlus;
