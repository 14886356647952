import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/database';

import { firebaseConfig } from 'config';
import { ChatMessage, Subscription, ChatroomObserver } from './typings';
import {
  Relationship,
  RelationshipStatus,
  RelationshipType,
} from 'models/Relationship.d';
import HttpClient from 'services/HttpClient';
import localizedStrings from 'localization';
import { NotificationType } from 'models/NotificationType';

firebase.initializeApp(firebaseConfig);

const firestore = firebase.firestore();
const auth = firebase.auth();

export const signInAnon = async () => {
  if (auth.currentUser === null) {
    await auth.signInWithEmailAndPassword('admin-nga@spacedev.uy', 'tPvUwD7P');
  }
};

export const upsertChatroomForRelationship = async (
  chatId: string,
  userId?: string,
) => {
  const data = userId
    ? {
        [userId]: 0,
      }
    : {};
  await firestore
    .collection('chatrooms')
    .doc(chatId)
    .set(data, { merge: true });
};

export const fetchMessagesFromChatroom = async (chatId: string) => {
  const snap = await firestore
    .collection('chatrooms')
    .doc(chatId)
    .collection('messages')
    .orderBy('createdAt')
    .endAt(new Date())
    .get();

  return snap.docs.map(
    (doc) =>
      ({
        ...doc.data(),
        createdAt: doc.data().createdAt.toDate(),
      } as ChatMessage),
  );
};

export const subscribeToChatroom: (
  chatId: string,
  observer: ChatroomObserver,
) => Subscription = (chatId, observer) => {
  const unsubscribe = firestore
    .collection('chatrooms')
    .doc(chatId)
    .collection('messages')
    .orderBy('createdAt')
    .startAfter(new Date())
    .onSnapshot({
      next: (snap) => {
        snap.docChanges().forEach((docChange) => {
          if (docChange.type === 'added') {
            const data = docChange.doc.data();
            observer.onNewMessage({
              ...data,
              createdAt: data.createdAt.toDate(),
            } as ChatMessage);
          }
        });
      },
    });

  return { unsubscribe };
};

export const sendMessageToChatroom = async (
  relationship: Relationship,
  message: ChatMessage,
) => {
  await firestore
    .collection('chatrooms')
    .doc(relationship.chatId)
    .collection('messages')
    .add(message);

  await firestore
    .collection('chatrooms')
    .doc(relationship.chatId)
    .set(
      {
        [relationship.relatedUser.id]: firebase.firestore.FieldValue.increment(
          1,
        ),
        [message.senderId]: 0,
        lastMessageSentAt: new Date(),
      },
      { merge: true },
    );

  const isRunningPartner =
    (relationship.relationshipType === RelationshipType.RUNNING_PARTNER ||
      relationship.relationshipType ===
        RelationshipType.EXTERNAL_RUNNING_PARTNER) &&
    relationship.relationshipStatus === RelationshipStatus.CONFIRMED;
  const uid = relationship.relatedUser.id;
  const title = isRunningPartner
    ? localizedStrings.runningPartnerCheckingIn
    : localizedStrings.fellowTravelerCheckingIn;
  const body = isRunningPartner
    ? localizedStrings.runningPartnerChat
    : localizedStrings.fellowTravelerChat;
  const type = NotificationType.CHAT;
  const targetId = message.senderId;

  await HttpClient.post('/notifications/push', {
    uid,
    body,
    title,
    type,
    data: {
      targetId,
      chatId: relationship.chatId,
    },
  });
};
