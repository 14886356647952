import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectAppletContentfulById } from 'modules/loading-screen/contentful/applets/selectors';
import useContentfulRenderer from 'hooks/useContentfulRenderer';
import {
  selectAppletAnswers,
  selectAppletById,
  selectAppletsForShareModal,
} from 'modules/applet-upload/selectors';
import useExistingApplet from 'hooks/useExistingApplet';
import useIsAppletDisabled from 'hooks/useIsAppletDisabled';
import useIsModalOpen from 'hooks/useIsModalOpen';
import { selectSelectedStepIsCelebrate } from 'modules/loading-screen/contents/selectors';
import theme from 'utils/theme';
import MadlibApplet from 'components/applets/madlib';
import MadlibAppletModal from './MadlibAppletModal';
import ShareAppletButton from 'components/applets/share-applet-button';
import { checkHasCompletedMadlib } from 'helpers/AppletHelpers';
import useMadlibSentenceRenderer from '../../../hooks/useMadlibSentenceRenderer';

type MadlibAppletChallengeProps = {
  appletId: string;
};

const MadlibAppletChallenge = ({ appletId }: MadlibAppletChallengeProps) => {
  const [isModalOpen, openModal, closeModal] = useIsModalOpen();
  const appletContentful = useSelector(selectAppletContentfulById(appletId));
  const appletToUpload = useSelector(selectAppletById(appletId));
  const appletAnswers = useSelector(selectAppletAnswers);
  const disabled = useIsAppletDisabled();
  const isCelebrate = useSelector(selectSelectedStepIsCelebrate);
  const shareModalApplets = useSelector(selectAppletsForShareModal);

  const madlibAnswers = useMemo(() => {
    if (appletToUpload?.madlibAnswers) {
      return Object.values(appletToUpload?.madlibAnswers);
    }

    return [];
  }, [appletToUpload]);

  const showSendButton = useMemo(
    () => appletContentful?.sendButton && shareModalApplets.length <= 1,
    [appletContentful, shareModalApplets],
  );

  useExistingApplet(appletId);

  const renderedUploadPrompt = useContentfulRenderer(
    appletContentful?.uploadPrompt,
    {
      additionalStyles: {
        paragraph: {
          color: theme.colors.grey[90],
        },
      },
    },
  );

  const madlibTitle = useContentfulRenderer(appletContentful?.uploadPrompt, {
    additionalStyles: {
      paragraph: {
        color: theme.colors.blue2[100],
        fontSize: theme.typography.large24,
        marginBottom: '2rem',
        textAlign: 'center',
      },
    },
  });

  const renderedMadlibSentence = useMadlibSentenceRenderer(
    appletContentful?.madlibSentence,
    { appletId },
  );

  const renderedEditableMadlibSentence = useMadlibSentenceRenderer(
    appletContentful?.madlibSentence,
    { appletId, isMadlibEditMode: true },
  );

  return (
    <>
      <MadlibApplet
        disabled={disabled}
        madlibSentence={renderedMadlibSentence}
        onEditPress={openModal}
        uploadPrompt={isCelebrate ? null : renderedUploadPrompt}
        shareButton={
          showSendButton ? (
            <ShareAppletButton
              appletContentfulId={appletId}
              type="madlib"
              madlibAnswers={madlibAnswers}
              disabled={
                !checkHasCompletedMadlib(
                  appletAnswers,
                  appletContentful,
                  appletId,
                )
              }
            />
          ) : null
        }
      />
      <MadlibAppletModal
        closeModal={closeModal}
        editableMadlibSentence={renderedEditableMadlibSentence}
        isModalOpen={isModalOpen}
        modalTitle={madlibTitle}
      />
    </>
  );
};

export default MadlibAppletChallenge;
