import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTheme } from '@emotion/react';
import { Formik, FormikProps } from 'formik';
import { toast } from 'react-toastify';

import localizedStrings from 'localization';
import {
  ChangePasswordField,
  ChangePasswordFormContent,
  FormTitle,
  ContentPanel,
} from '../components';
import { changePasswordSchema } from '../validationSchema';
import { AppDispatch } from 'redux/typings';
import ButtonPrimary from 'components/button-primary';
import { changePassword } from '../actions';
import useActionStatus from 'hooks/useActionStatus';
import useErrorMessage from 'hooks/useErrorMessage';

type InitialValueType = {
  oldPassword: string;
  newPassword: string;
  newPasswordConfirm: string;
};

const ChangePassword = () => {
  const dispatch = useDispatch<AppDispatch>();
  const theme = useTheme();
  const formikRef = useRef<FormikProps<InitialValueType>>(null);
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const [isLoading, wasFulfilled] = useActionStatus(changePassword);
  const [errorMessage, setErrorMessage] = useErrorMessage();

  const handleSubmit = useCallback(
    ({ oldPassword, newPassword }: InitialValueType) => {
      setErrorMessage('');
      dispatch(changePassword({ oldPassword, newPassword }));
      setHasSubmitted(true);
    },
    [dispatch, setErrorMessage],
  );

  useEffect(() => {
    if (hasSubmitted && wasFulfilled) {
      formikRef.current?.resetForm();
      toast.success(localizedStrings.changePasswordSuccess, {
        autoClose: 5000,
        style: {
          backgroundColor: theme?.colors.success,
        },
      });
      setHasSubmitted(false);
    }
  }, [hasSubmitted, theme, wasFulfilled]);

  useEffect(() => {
    if (errorMessage) {
      toast.error(errorMessage);
    }
  }, [errorMessage]);
  return (
    <ContentPanel>
      <FormTitle>
        {localizedStrings.changePassword}
      </FormTitle>
      <Formik
        initialValues={{
          oldPassword: '',
          newPassword: '',
          newPasswordConfirm: '',
        }}
        validationSchema={changePasswordSchema}
        onSubmit={handleSubmit}
        innerRef={formikRef}>
        <ChangePasswordFormContent>
          <ChangePasswordField
            type="password"
            name="oldPassword"
            placeholder={localizedStrings.currentPassword}
          />
          <ChangePasswordField
            type="password"
            name="newPassword"
            placeholder={localizedStrings.newPassword}
          />
          <ChangePasswordField
            type="password"
            name="newPasswordConfirm"
            placeholder={localizedStrings.repeatPassword}
          />
          <ButtonPrimary type="submit" isLoading={isLoading}>
            {localizedStrings.updatePassword}
          </ButtonPrimary>
        </ChangePasswordFormContent>
      </Formik>
    </ContentPanel>
  );
};

export default ChangePassword;
