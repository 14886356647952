import React from 'react';
import logo from 'assets/images/Logo.webp';
import { BgContainer } from 'common/CommonComponents';
import BackendDownError from 'components/backend-down-error';
import LoadingScreenErrors from 'components/loading-screen-error';

const GenericErrorScreen = () => {
  return (
    <BgContainer>
      <img src={logo} alt="brand logo" />
      <BackendDownError />
      <LoadingScreenErrors />
    </BgContainer>
  );
};

export default GenericErrorScreen;
