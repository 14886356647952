import React from 'react';
import theme from 'utils/theme';
import SvgText from './SvgText';

type ProgressIndicatorProps = {
  width?: number | string;
  height?: number | string;
  progress: number;
  text?: string;
  textColor?: string;
  filled?: boolean;
};

const ProgressIndicator = ({
  width,
  height,
  progress,
  text,
  textColor,
  filled,
}: ProgressIndicatorProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 22"
      style={{ verticalAlign: 'top' }}>
      <circle
        cx="11"
        cy="11"
        r={filled ? 11 : 10}
        fill={filled ? theme.colors.white[25] : 'transparent'}
        stroke={filled ? 'none' : theme.colors.white[25]}
      />
      {progress > 0 ? (
        <circle
          r="10"
          cx="11"
          cy="11"
          stroke={theme.colors.gold[100]}
          strokeWidth="2"
          strokeDasharray={`${(2 * Math.PI * 10 * progress) / 100} ${
            2 * Math.PI * 10 * (1 - progress / 100)
          }`}
          transform="rotate(-90, 11, 11)"
          strokeLinecap="round"
          fill="transparent"
        />
      ) : null}
      {text ? (
        <SvgText text={text} color={textColor || theme.colors.white[100]} />
      ) : null}
    </svg>
  );
};

export default ProgressIndicator;
