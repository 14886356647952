import * as React from 'react';
import theme from 'utils/theme';

type UpCaretProps = {
  color?: string;
}

const UpCaret = ({ color = theme.colors.grey[90] }: UpCaretProps) => {
  return (
    <svg width={14} height={8} viewBox="0 0 14 8" fill="none">
      <path
        d="M13.176 7.424a.6.6 0 01-.849 0L6.876 1.973 1.424 7.424a.6.6 0 11-.848-.848L6.453.699A.6.6 0 017.33.73l5.846 5.846a.6.6 0 010 .848z"
        fill={color}
      />
    </svg>
  );
};

export default UpCaret;
