import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import {
  selectIsEditableContentSubmitted,
  selectSelectedContent,
  selectSelectedStepIsCelebrate,
  selectSelectedStepIsCompleted,
} from 'modules/loading-screen/contents/selectors';

const useIsAppletDisabled = () => {
  const selectedContent = useSelector(selectSelectedContent);
  const isStepCompleted = useSelector(selectSelectedStepIsCompleted);
  const isCelebrate = useSelector(selectSelectedStepIsCelebrate);
  const isEditableContentSubmitted = useSelector(
    selectIsEditableContentSubmitted,
  );

  const isAppletDisabled = useMemo(() => {
    if (selectedContent) {
      if (isEditableContentSubmitted) {
        return false;
      }
      return isCelebrate ? selectedContent.completed : isStepCompleted;
    } else {
      return true;
    }
  }, [
    isCelebrate,
    isStepCompleted,
    selectedContent,
    isEditableContentSubmitted,
  ]);

  return isAppletDisabled;
};

export default useIsAppletDisabled;
