import { useMemo } from 'react';

import LocalizedStrings from 'localization';
import { QuestApplet } from 'models/QuestApplet';
import {
  ImageAppletPreview,
  PreviewQuizCard,
} from 'modules/profile/components';
import PreviewCardContainer from 'modules/profile/portfolio/quest-applets/PreviewCardContainer';
import VideoThumbnail from 'modules/profile/portfolio/quest-applets/VideoThumbnail';
import FileUploadThumbnail from 'modules/profile/portfolio/quest-applets/FileUploadThumbnail';
import QuizResult, {
  QUIZ_RESULT_THRESHOLD,
} from 'components/applets/quiz-applet/QuizResult';
import GroupAppletPreview from 'modules/profile/portfolio/quest-applets/applet-preview/GroupAppletPreview';
import useQuizChartInfo from './useQuizChartInfo';
import { getDecisionTreeFirstFile } from 'helpers/AppletHelpers';

const useAppletPreviewSwitch = (applet?: QuestApplet) => {
  const chartInfo = useQuizChartInfo(
    applet?.contentfulId || '',
    applet?.quiz,
    applet?.quizQuestions,
  );
  const quizResultCount = chartInfo.find(
    (item) => item.count >= QUIZ_RESULT_THRESHOLD,
  )?.count;

  return useMemo(() => {
    if (applet) {
      const { appletType, fileUrl, title, thumbnailUrl, fileType, files } =
        applet;

      switch (appletType) {
        case 'upload-image':
          return <ImageAppletPreview src={fileUrl} alt={title} />;
        case 'upload-video':
          return fileUrl ? (
            <VideoThumbnail file={fileUrl} />
          ) : (
            <PreviewCardContainer backgroundUrl={thumbnailUrl}>
              {LocalizedStrings.playVideo}
            </PreviewCardContainer>
          );
        case 'generic-rich-text':
        case 'rich-text-entry':
          return (
            <PreviewCardContainer backgroundUrl={thumbnailUrl}>
              {LocalizedStrings.showText}
            </PreviewCardContainer>
          );
        case 'upload-file':
        case 'generic-upload-file':
          return (
            <FileUploadThumbnail
              fileUrl={fileUrl!}
              fileType={fileType!}
              title={title}
              thumbnailUrl={thumbnailUrl}
            />
          );
        case 'quiz':
          return (
            <PreviewQuizCard backgroundUrl={thumbnailUrl}>
              <QuizResult
                appletId={applet.contentfulId}
                quiz={applet.quiz}
                hideRewardText={!!quizResultCount}
                quizQuestions={applet.quizQuestions}
              />
            </PreviewQuizCard>
          );
        case 'decision-tree':
          if (files && files.length > 0) {
            const firstFile = getDecisionTreeFirstFile(files);
            return (
              <PreviewCardContainer
                backgroundUrl={firstFile?.fileUrl ?? thumbnailUrl}>
                {LocalizedStrings.showDecisionTree}
              </PreviewCardContainer>
            );
          } else {
            return null;
          }
        case 'rank':
          return (
            <PreviewCardContainer backgroundUrl={thumbnailUrl}>
              {LocalizedStrings.showRank}
            </PreviewCardContainer>
          );
        case 'madlib':
          return (
            <PreviewCardContainer backgroundUrl={thumbnailUrl}>
              {title}
            </PreviewCardContainer>
          );
        case 'random-selection':
          return (
            <PreviewCardContainer backgroundUrl={thumbnailUrl}>
              Show Random Selection
            </PreviewCardContainer>
          );
        case 'group-applet':
          return <GroupAppletPreview applet={applet} />;
        default:
          return <div>{title}</div>;
      }
    }
  }, [applet, quizResultCount]);
};

export default useAppletPreviewSwitch;
