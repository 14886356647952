import { ErrorState, ErrorActionType } from './typings';
import { CLEAR_ERROR_MESSAGE, SET_INTERNET_CONNECTION } from './actions';
import { LOADING_SCREEN_ERRORS } from './constants';

const initialState: ErrorState = {};

const reducer = (state = initialState, action: ErrorActionType) => {
  const { type, error, payload } = action;
  const matchesError = /(.*)\/(rejected)/.exec(type);

  if (matchesError) {
    if (
      typeof payload !== 'boolean' &&
      payload !== null &&
      payload !== undefined &&
      LOADING_SCREEN_ERRORS.includes(payload.errorCode || '')
    ) {
      const loadingScreenErrors = state.loadingScreenErrors || [];
      loadingScreenErrors.push(payload);
      return { ...state, loadingScreenErrors } as ErrorState;
    }
    if (error.message === 'errorBackendDown') {
      return { ...state, isBackendDown: true } as ErrorState;
    }
    return { ...state, message: error.message } as ErrorState;
  } else if (type === CLEAR_ERROR_MESSAGE) {
    return { ...state, message: undefined } as ErrorState;
  } else if (type === SET_INTERNET_CONNECTION) {
    return {
      ...state,
      internetConnection: payload,
    } as ErrorState;
  }

  return state as ErrorState;
};

export default reducer;
