import React from 'react';

import { ChallengeApplet } from '../../models/ChallengeApplet';
import QuizApplet from 'components/applets/quiz-applet';
import QuizResult from '../../components/applets/quiz-applet/QuizResult';
import QuizSectionListProfileAndSubmission from 'components/applets/quiz-applet/quiz-section-list/QuizSectionListProfileAndSubmission';

type QuizAppletSubmissionProps = {
  applet: ChallengeApplet;
};

const QuizAppletSubmission = ({ applet }: QuizAppletSubmissionProps) => {
  const { appletId, quiz, quizQuestions, title } = applet;
  return (
    <>
      <QuizApplet
        disabled
        showResultsButton
        quizSections={
          <QuizSectionListProfileAndSubmission
            title={title}
            sections={quiz?.fields?.sections}
            quizQuestions={quizQuestions}
          />
        }
        quizResult={
          <QuizResult
            appletId={appletId}
            quiz={quiz}
            mixedResultText={quiz?.fields.mixedResultText}
            quizQuestions={quizQuestions}
          />
        }
      />
    </>
  );
};

export default QuizAppletSubmission;
