import React from 'react';

type DeleteIconProps = {
  color?: string;
  backgroundColor?: string;
};

const DeleteIcon = ({
  color = '#007DC2',
  backgroundColor = '#FFFFFF',
}: DeleteIconProps) => {
  return (
    <>
      <svg
        style={{ cursor: 'pointer' }}
        width="32px"
        height="32px"
        viewBox="0 0 32 32">
        <title>4363DD00-FF7A-4CD3-93F7-4B7812E21B4B</title>
        <g id="V3" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g
            id="Challenge---03B"
            transform="translate(-296.000000, -591.000000)">
            <g
              id="Delete"
              transform="translate(312.000000, 607.000000) scale(-1, 1) translate(-312.000000, -607.000000) translate(296.000000, 591.000000)">
              <path
                d="M22,9.77776562 L19.2499908,9.77776562 L18.4642897,9 L14.5357103,9 L13.7500092,9.77776562 L11,9.77776562 L11,11.3333333 L22,11.3333333 L22,9.77776562 Z M11.7857011,21.4444323 C11.7857011,22.303901 12.4889392,23 13.3571402,23 L19.6428598,23 C20.5110608,23 21.214262,22.303901 21.214262,21.4444323 L21.214262,12.111099 L11.7857011,12.111099 L11.7857011,21.4444323 L11.7857011,21.4444323 Z"
                fill={color}></path>
            </g>
          </g>
        </g>
      </svg>
    </>
  );
};

export default DeleteIcon;
