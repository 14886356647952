import React from 'react';

import {
  AchievementCardExperience,
  AchievementCardMedal,
} from 'modules/profile/components';
import Medal from '../experience-summary/medal';

type BadgeProps = {
  ribbonColor?: 'blue' | 'red' | 'green';
  xp: number;
  bp: number;
};

const AchievementBadge = ({ ribbonColor, xp, bp }: BadgeProps) => {
  return (
    <AchievementCardMedal>
      <Medal ribbonColor={ribbonColor} />
      {xp > 0 && (
        <AchievementCardExperience>{`${xp} xp`}</AchievementCardExperience>
      )}
      {bp > 0 && (
        <AchievementCardExperience>{`${bp} bp`}</AchievementCardExperience>
      )}
    </AchievementCardMedal>
  );
};

export default AchievementBadge;
