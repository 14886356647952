import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { ONBOARDING } from 'routes';
import { ONBOARDING_APP, ONBOARDING_QUEST, ONBOARDING_WEEK } from './routes';
import AppOnboarding from './app-onboarding';
import QuestOnboarding from './quest-onboarding';
import WeekOnboarding from './week-onboarding';

const Onboarding = () => {
  return (
    <Switch>
      <Route
        exact
        path={ONBOARDING + ONBOARDING_APP}
        component={AppOnboarding}
      />
      <Route
        exact
        path={ONBOARDING + ONBOARDING_QUEST}
        component={QuestOnboarding}
      />
      <Route
        exact
        path={ONBOARDING + ONBOARDING_WEEK}
        component={WeekOnboarding}
      />
    </Switch>
  );
};

export default Onboarding;
