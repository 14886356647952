import { createReducer } from '@reduxjs/toolkit';
import { arrayToHash } from 'helpers/helperFunctions';
import { StepContentful } from 'models/StepContentful';
import { getSteps } from './actions';
import { StepsState } from './typings';

const initialState: StepsState = {
  steps: {},
};

export default createReducer<StepsState>(initialState, (builder) =>
  builder.addCase(getSteps.fulfilled, (state, action) => ({
    steps: arrayToHash<StepContentful>(
      action.payload,
      (step) => step.id,
      state.steps,
    ),
    lastUpdatedAt: new Date().toISOString(),
  })),
);
