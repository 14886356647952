import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectWeeksContentful } from 'modules/loading-screen/contentful/weeks/selectors';
import { selectLastAvailableWeek } from 'modules/loading-screen/weeks/selectors';

const useCurrentWeekInformation = () => {
  const currentWeek = useSelector(selectLastAvailableWeek);
  const weeksContentful = useSelector(selectWeeksContentful);

  const currentWeekContentful = useMemo(
    () => weeksContentful?.[currentWeek?.contentfulId || ''],
    [weeksContentful, currentWeek],
  );

  return [currentWeekContentful?.weekNumber, currentWeekContentful?.title];
};

export default useCurrentWeekInformation;
