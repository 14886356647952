import React, { useCallback, useMemo, useRef, useState } from 'react';
import { nanoid } from '@reduxjs/toolkit';

import Modal from 'components/modal';
import EditIcon from 'components/icons/EditIcon';
import DeleteIcon from 'components/icons/DeleteIcon';
import localizedStrings from 'localization';
import { SortableItemType } from '../rank-applet';
import { Formik, FormikProps } from 'formik';
import InputField from 'components/input-field';
import { addUserOptionValidationSchema } from './validationSchema';
import { ErrorMessage } from 'components/contentful-renders/components';
import {
  UserOptionContainer,
  UserOptionIconsContainer,
  IconContainer,
  FormContainer,
  InputContainer,
  AddOptionButtonContainer,
  AddOptionButton,
  SaveOptionsButtonContainer,
  SaveOptionsButton,
} from 'common/CommonComponents';

type UserOption = {
  id: string | '';
  userInput?: string | undefined;
};

type AddOptionSchema = {
  userValue: string;
};

type UserOptionsModalProps = {
  setUserOptions: React.Dispatch<React.SetStateAction<SortableItemType[]>>;
  userOptions: UserOption[];
  isEditModalOpen: boolean;
  setIsEditModalOpen: (val: boolean) => void;
  onListUpdate?: (items: SortableItemType[]) => void;
};

const UserOptionsModal = ({
  userOptions,
  setUserOptions,
  isEditModalOpen,
  setIsEditModalOpen,
  onListUpdate,
}: UserOptionsModalProps) => {
  const formikRef = useRef<FormikProps<AddOptionSchema>>(null);
  const [error, setError] = useState('');
  const disabled = useMemo(() => userOptions.length < 3, [userOptions]);

  const handleEditOption = useCallback(
    (id: string) => () => {
      const filteredOptions = userOptions.filter((opt) => opt.id !== id);
      const editedOption = userOptions.find((opt) => opt.id === id);
      if (editedOption?.userInput) {
        formikRef.current?.setFieldValue(
          'userValue',
          editedOption?.userInput || '',
        );
      }
      setUserOptions(filteredOptions);
      setError('');
    },
    [setUserOptions, userOptions],
  );

  const handleDeleteOption = useCallback(
    (id: string) => () => {
      let filteredArray = userOptions.filter(
        (item: UserOption) => item.id !== id,
      );
      setUserOptions(filteredArray);
      setError('');
    },
    [setUserOptions, userOptions],
  );

  const saveOptions = useCallback(() => {
    onListUpdate?.(userOptions);
    setIsEditModalOpen(false);
  }, [onListUpdate, userOptions, setIsEditModalOpen]);

  const closeModal = useCallback(() => {
    setIsEditModalOpen(false);
  }, [setIsEditModalOpen]);

  const addOption = useCallback(
    ({ userValue }: { userValue: string }) => {
      if (userValue) {
        setUserOptions((options: SortableItemType[]) => [
          ...options,
          { id: nanoid(), userInput: userValue },
        ]);
        setError('');
        formikRef.current?.resetForm();
      }
    },
    [setUserOptions],
  );

  return (
    <Modal
      title={localizedStrings.addOptionsTitle}
      open={isEditModalOpen}
      closeModal={closeModal}>
      <div>
        <>
          {userOptions?.map((option: UserOption, index: number) => (
            <UserOptionContainer key={option.id}>
              {`${index + 1}. ${option.userInput}`}
              <UserOptionIconsContainer>
                <IconContainer onClick={handleEditOption(option.id)}>
                  <EditIcon />
                </IconContainer>
                <IconContainer onClick={handleDeleteOption(option.id)}>
                  <DeleteIcon />
                </IconContainer>
              </UserOptionIconsContainer>
            </UserOptionContainer>
          ))}
        </>
        <Formik
          initialValues={{ userValue: '' } as AddOptionSchema}
          validationSchema={addUserOptionValidationSchema}
          onSubmit={addOption}
          innerRef={formikRef}>
          <FormContainer>
            <InputContainer>
              <InputField name="userValue" />
            </InputContainer>
            <AddOptionButtonContainer>
              <AddOptionButton type="submit">
                {localizedStrings.addOption}
              </AddOptionButton>
            </AddOptionButtonContainer>
          </FormContainer>
        </Formik>
        <SaveOptionsButtonContainer>
          <SaveOptionsButton onClick={saveOptions} disabled={disabled}>
            {localizedStrings.save}
          </SaveOptionsButton>
          {error ? <ErrorMessage>{error}</ErrorMessage> : null}
        </SaveOptionsButtonContainer>
      </div>
    </Modal>
  );
};

export default UserOptionsModal;
