import { createReducer } from '@reduxjs/toolkit';
import { TagsState } from './typings';
import { getTags } from './actions';

const initialState: TagsState = {};

export default createReducer<TagsState>(initialState, (builder) =>
  builder
    .addCase(getTags.pending, () => initialState)
    .addCase(getTags.fulfilled, (state, action) => ({
      ...state,
      tags: action.payload,
    })),
);
