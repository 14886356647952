import { CohortStartingDate } from 'models/CohortStartingDate';
import { baseService } from '../baseService';
import HttpClient from '../../services/HttpClient';
import {
  AppFeedbackPayload,
  AppFeedback,
  Cohort,
  ChangePasswordPayload,
  UserAuthPayload,
} from './typings';
import { UserLocalDate } from 'models/UserLocalDate';

const adminBaseUrl = '/admin/users';

export const sendFeedback = baseService<AppFeedbackPayload, AppFeedback>(
  (data) => HttpClient.post('/app-feedback', data),
);

export const getCohorts = baseService<void, Cohort[]>(() =>
  HttpClient.get('/cohorts'),
);

export const addCohort = baseService<
  { enrollmentCode: string; userLocalDate: UserLocalDate },
  void
>((data) => HttpClient.post('/cohorts', data));

export const getCohortStartingDate = baseService<string, CohortStartingDate>(
  (enrollementCode) => HttpClient.get(`cohorts/code/${enrollementCode}`),
);

export const changePassword = baseService<ChangePasswordPayload, void>((data) =>
  HttpClient.patch('/users/change-password', data),
);

export const deleteUserAccount = baseService<UserAuthPayload, void>((data) =>
  HttpClient.delete(`${adminBaseUrl}/${data}`),
);
