import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch, RootState } from 'redux/typings';
import {
  AvatarOptionsMenuContainer,
  LineBreakOptionMenu,
  MenuItemContainer,
  MenuItemOption,
  MenuItemPanel,
} from './components';
import { selectAllNotifications } from './selectors';
import * as StatusSelectors from 'modules/status/selectors';
import { getNotifications } from './actions';
import { AlertIcon } from './components/AlertIcon';
import { SignOutIcon } from './components/SignOutIcon';
import NotificationsPlaceholder from './components/NotificationsPlaceholder';
import Notifications from './components/Notifications';
import { logout } from '../../modules/auth/actions';
import localizedStrings from '../../localization/en';
import { NotificationMainContainer } from './components/NotificationContainer';
import { useHistory } from 'react-router';

type RecentActivityProps = {
  open: boolean;
  close: () => void;
  xPosition?: number;
  yPosition?: number;
};

const AvatarOptionsMenu = ({
  open,
  close,
  xPosition = 0,
  yPosition = 0,
}: RecentActivityProps) => {
  const [openRecentActivities, setOpenRecentActivities] = useState(false)
  const dispatch = useDispatch<AppDispatch>();
  const panelRef = useRef(null);
  const history = useHistory()

  const notifications = useSelector(selectAllNotifications);
  const isLoading = useSelector((state: RootState) =>
    StatusSelectors.isPendingSelector(state, getNotifications.typePrefix),
  );

  const OPTIONS_MENU = [
    {
      title: localizedStrings.recentActivity,
      icon: <AlertIcon />,
      action: () => setOpenRecentActivities(true)
    },
    {
      title: localizedStrings.signOut,
      icon: <SignOutIcon />,
      action: () => {
        dispatch(logout());
        history.replace('/')
      }
    }
  ]

  const handleCloseRecentActivity = () => {
    setOpenRecentActivities(false)
  }

  const handleClick = useCallback(
    (evt) => {
      if (evt.target === panelRef.current) {
        handleCloseRecentActivity()
        close();
      }
    },
    [close],
  );

  useEffect(() => {
    if (open) {
      dispatch(getNotifications());
    }
  }, [dispatch, open]);

  return (
    <AvatarOptionsMenuContainer
      visible={open}
      onClick={handleClick}
      ref={panelRef}>
      <MenuItemPanel xPosition={xPosition} yPosition={yPosition + 9}>
        {openRecentActivities ?
          isLoading ?
            <NotificationMainContainer closeAction={handleCloseRecentActivity} >
              <NotificationsPlaceholder />
            </NotificationMainContainer> :
            <NotificationMainContainer closeAction={handleCloseRecentActivity} >
              <Notifications notifications={notifications} />
            </NotificationMainContainer>
          :
          OPTIONS_MENU.map((menuItem, index) =>
            <div key={index + menuItem.title}>
              <MenuItemContainer onClick={menuItem.action}>
                <MenuItemOption>{menuItem.title}</MenuItemOption>
                <MenuItemOption>{menuItem.icon}</MenuItemOption>
              </MenuItemContainer>
              {!(OPTIONS_MENU.length === index + 1) && <LineBreakOptionMenu />}
            </div>
          )}
      </MenuItemPanel>
    </AvatarOptionsMenuContainer>
  );
};

export default AvatarOptionsMenu;
