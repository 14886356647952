import format from 'date-fns/format';
import isYesterday from 'date-fns/isYesterday';
import isToday from 'date-fns/isToday';
import isThisYear from 'date-fns/isThisYear';
import localizedStrings from 'localization';
import { parse, parseISO } from 'date-fns';

export const formatDateRelative = (date: number | string | Date): string => {
  if (typeof date === 'string') {
    date = new Date(date);
  }

  if (isToday(date)) {
    return localizedStrings.today;
  } else if (isYesterday(date)) {
    return localizedStrings.yesterday;
  } else if (isThisYear(date)) {
    return format(date, 'dd MMM');
  }

  return format(date, 'dd MMM yyyy');
};

export const formatDate = (date?: Date) => {
  if (!date) {
    return null;
  }
  const dateSeparatedBySpaces = date.toDateString().split(' ');
  return `${dateSeparatedBySpaces[1]} ${dateSeparatedBySpaces[2]}`;
};

export const formatUTCDate = (date?: Date) => {
  let formattedDate = '';
  if (date) {
    const dateSeparatedBySpaces = date.toUTCString().split(' ');
    formattedDate = `${dateSeparatedBySpaces[2]} ${dateSeparatedBySpaces[1]}`;
  }
  return formattedDate;
};

export const compareStringDates = (
  dateStringA: string,
  dateStringB: string,
): number => {
  const dateA = parse(dateStringA, 'yyyy-MM-dd', new Date());
  const dateB = parse(dateStringB, 'yyyy-MM-dd', new Date());

  return dateA.getTime() - dateB.getTime();
};

export const compareIsoStringDates = (
  isoDateStringA: string,
  isoDateStringB: string,
) => {
  const dateA = parseISO(isoDateStringA);
  const dateB = parseISO(isoDateStringB);

  return dateA.getTime() - dateB.getTime();
};

export const getTodayEightAm = () => {
  const currentDate = new Date();
  return new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate(),
    8,
    0,
  );
};
