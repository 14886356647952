import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'redux/typings';

const selectStepsState = (state: RootState) => state.contentful.steps;

export const selectStepsContentful = createSelector(
  selectStepsState,
  (stepsState) => stepsState.steps,
);

export const selectStepsContentfulLastUpdatedAt = createSelector(
  selectStepsState,
  (stepsState) => stepsState.lastUpdatedAt,
);
