import { createSelector } from '@reduxjs/toolkit';
import * as yup from 'yup';

import { RootState } from 'redux/typings';
import { UploadFile } from 'components/applets/app-upload';
import localizedStrings from 'localization';
import { selectContentsContentful } from '../contentful/contents/selectors';
import { selectStepsContentful } from '../contentful/steps/selectors';
import { selectAppletsContentful } from '../contentful/applets/selectors';
import {
  appletToChallengeApplet,
  retrieveContentApplets,
} from 'helpers/AppletHelpers';
import { ChallengeApplet } from 'models/ChallengeApplet';

const selectContentsState = (state: RootState) => state.contents;

export const selectContents = createSelector(
  selectContentsState,
  (contentsState) => contentsState.contents,
);

export const selectLinkToShare = createSelector(
  selectContentsState,
  (contentsState) => contentsState.linkToShare,
);

export const selectAvailableContent = createSelector(
  selectContentsState,
  (contentsState) => Object.values(contentsState.contents),
);

export const selectSelectedContent = createSelector(
  selectContentsState,
  (contentsState) =>
    contentsState.selectedId
      ? contentsState.contents[contentsState.selectedId]
      : undefined,
);

export const selectSelectedContentContentful = createSelector(
  selectSelectedContent,
  selectContentsContentful,
  (selectedContent, contentsContentful) =>
    selectedContent
      ? contentsContentful[selectedContent.contentfulId]
      : undefined,
);

export const selectSelectedContentTitle = createSelector(
  selectSelectedContentContentful,
  (contentContentful) => contentContentful?.title,
);

export const selectSelectedContentSubtitle = createSelector(
  selectSelectedContentContentful,
  (contentContentful) => contentContentful?.subtitle,
);

export const selectSelectedContentCongratulations = createSelector(
  selectSelectedContentContentful,
  (contentContentful) => contentContentful?.congratsMessage,
);

export const selectSelectedContentTotalXp = createSelector(
  selectSelectedContentContentful,
  (contentContentful) => contentContentful?.totalXp ?? 0,
);

export const selectSelectedContentId = createSelector(
  selectSelectedContent,
  (content) => content?.id ?? '',
);

export const selectSelectedContentIsCompleted = createSelector(
  selectSelectedContent,
  (content) => content?.completed ?? false,
);

export const selectChallengeQuizQuestion = (
  appletId: string,
  quizQuestionContentfulId: string,
) =>
  createSelector(selectChallengeAppletByContentfulId(appletId), (applet) =>
    applet?.quizQuestions?.find(
      (question) =>
        question.quizQuestionContentfulId === quizQuestionContentfulId,
    ),
  );

export const selectSelectedContentApplets = createSelector(
  selectSelectedContent,
  selectAppletsContentful,
  (content, appletsContentful) =>
    content?.applets
      ? content.applets.map((applet) =>
        appletsContentful[applet.contentfulId]
          ? appletToChallengeApplet(
            applet,
            appletsContentful[applet.contentfulId],
          )
          : ({} as ChallengeApplet),
      )
      : [],
);

export const selectSelectedContentStepIds = createSelector(
  selectSelectedContent,
  (content) => content?.stepIds ?? [],
);

export const selectSelectedContentTotalSteps = createSelector(
  selectSelectedContent,
  (content) => content?.stepIds?.length ?? 0,
);

export const selectSelectedContentCompletedSteps = createSelector(
  selectSelectedContent,
  (content) => content?.completedSteps ?? [],
);

export const selectChallengeAppletByContentfulId = (appletId: string) =>
  createSelector(selectSelectedContent, (content) =>
    content?.applets?.find((app) => app.contentfulId === appletId),
  );

export const selectSelectedContentPost = createSelector(
  selectSelectedContentContentful,
  (contentContentful) => contentContentful?.post,
);

export const selectSelectedContentPostId = createSelector(
  selectSelectedContentPost,
  (post) => post?.id,
);

export const selecteSelectedContentPostTitle = createSelector(
  selectSelectedContentPost,
  (post) => post?.title ?? '',
);

export const selectSelectedContentPostReflect = createSelector(
  selectSelectedContentPost,
  (post) => post?.reflect,
);

export const selectSelectedContentPostReflectPlaceholder = createSelector(
  selectSelectedContentPost,
  (post) => post?.reflectPlaceholder,
);

export const selectSelectedContentPostFileUpload = createSelector(
  selectSelectedContentPost,
  (post) => post?.fileUpload,
);

export const selectSelectedContentPostButton = createSelector(
  selectSelectedContentPost,
  (post) => post?.doneButton,
);

export const selectSelectedContentPostButtonText = createSelector(
  selectSelectedContentPostButton,
  (postButton) => postButton?.text ?? '',
);

export const selectSelectedContentPostButtonColor = createSelector(
  selectSelectedContentPostButton,
  (postButton) => postButton?.color,
);

export const selectSelectedContentPostShare = createSelector(
  selectSelectedContentPost,
  (post) => post?.share,
);

export const selectSelectedContentPostUser = createSelector(
  selectSelectedContent,
  (content) => content?.post,
);

export const selectSelectedContentPostUserId = createSelector(
  selectSelectedContentPostUser,
  (postUser) => postUser?.id,
);

export const selectSelectedContentPostInitialValues = createSelector(
  selectSelectedContentPostUser,
  (postUser) => {
    const initialValues: {
      reflectAnswers: string;
      file: UploadFile | undefined;
    } = {
      reflectAnswers: '',
      file: undefined,
    };
    if (postUser) {
      initialValues.reflectAnswers = postUser.reflectAnswers;
      if (postUser.fileUrl) {
        initialValues.file = {
          name: '',
          uri: postUser.fileUrl,
          type: postUser.fileType,
        } as UploadFile;
      }
    }
    return initialValues;
  },
);

export const selectSelectedContentPostValidation = createSelector(
  selectSelectedContentContentful,
  (contentContentful) => {
    const postContentful = contentContentful?.post;
    const reflectAnswers = yup
      .string()
      .required(localizedStrings.reflectRequiredMessage);
    const file = yup.object().required(localizedStrings.fileRequiredMessage);
    if (postContentful?.fileUpload) {
      return yup.object().shape({
        reflectAnswers,
        file,
      });
    }
    return yup.object().shape({
      reflectAnswers,
    });
  },
);

export const selectSelectedStep = createSelector(
  selectContentsState,
  selectSelectedContent,
  selectStepsContentful,
  (contentStates, selectedContent, stepsContentful) =>
    contentStates.selectedStepIdx !== undefined && selectedContent
      ? stepsContentful[selectedContent.stepIds[contentStates.selectedStepIdx]]
      : undefined,
);

export const selectSelectedStepIsCelebrate = createSelector(
  selectContentsState,
  selectSelectedContent,
  (contentsState, selectedContent) =>
    contentsState.selectedStepIdx && selectedContent
      ? contentsState.selectedStepIdx === selectedContent.stepIds.length
      : false,
);

export const selectSelectedStepTitle = createSelector(
  selectSelectedStep,
  (step) => step?.title || '',
);

export const selectSelectedStepButton = createSelector(
  selectSelectedStep,
  (step) => step?.doneButton,
);

export const selectSelectedStepButtonText = createSelector(
  selectSelectedStepButton,
  (button) => button?.text ?? '',
);

export const selectSelectedStepButtonColor = createSelector(
  selectSelectedStepButton,
  (button) => button?.color,
);

export const selectSelectedStepContent = createSelector(
  selectSelectedStep,
  (step) => step?.content,
);

export const selectSelectedStepAdditionalInfo = createSelector(
  selectSelectedStep,
  (step) => step?.additionalInfo,
);

export const selectSelectedStepIsFirst = createSelector(
  selectSelectedStep,
  selectSelectedContentStepIds,
  (selectedStep, stepIds) =>
    selectedStep ? stepIds.indexOf(selectedStep.id) === 0 : false,
);

export const selectSelectedStepIndex = createSelector(
  selectSelectedStep,
  selectSelectedContentStepIds,
  (selectedStep, stepIds) =>
    selectedStep ? stepIds.indexOf(selectedStep.id) : stepIds.length,
);

export const selectSelectedStepIsCompleted = createSelector(
  selectSelectedContentCompletedSteps,
  selectSelectedStep,
  (completedSteps, step) =>
    step &&
      completedSteps.find(
        (compStepNumber) => compStepNumber === step.stepNumber,
      ) !== undefined
      ? true
      : false,
);

export const selectStepApplets = createSelector(selectSelectedStep, (step) => {
  return retrieveContentApplets(step?.content.content || []);
});

export const selectStepAppletIds = createSelector(
  selectStepApplets,
  (applets) =>
    applets?.map((stepContent) => stepContent.data.target.sys.id) ?? [],
);

export const selectIsEditableContentSubmitted = createSelector(
  selectContentsState,
  (contentsState) => contentsState.isEditableContentSubmitted,
);

export const selectContentsByContentfulId = (contentfulId = '') => createSelector(
  selectContentsState, (contentsState) => {
    for (const contentfulIdKey in contentsState.contents) {
      const contentByContentfulId = contentsState.contents[contentfulIdKey];
      if (contentByContentfulId.contentfulId === contentfulId) {
        return contentByContentfulId;
      }
    }
    return null;
  }
)
