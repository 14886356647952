import React, { useMemo } from 'react';

import {
  AUDIO_MIMETYPE,
  getFileObject,
  IMAGE_MIMETYPE,
  PDF_MIMETYPE,
  TEXT_MIMETYPE,
  VIDEO_MIMETYPE,
} from 'helpers/FileHelpers';
import { ImageAppletPreview, PreviewCard } from 'modules/profile/components';
import PdfAppletPreview from './PdfAppletPreview';
import PlayIcon from 'components/icons/PlayIcon';
import localizedStrings from 'localization';
import VideoThumbnail from './VideoThumbnail';
import TxtIcon from 'components/icons/TxtIcon';

type FileUploadPreviewProps = {
  fileUrl: string;
  fileType: string;
  title?: string;
  thumbnailUrl?: string;
};

const FileUploadThumbnail = ({
  fileUrl,
  fileType,
  title,
  thumbnailUrl,
}: FileUploadPreviewProps) => {
  const Preview = useMemo(() => {
    const fileObject = getFileObject({ fileType, fileUrl });
    switch (fileObject?.type) {
      case IMAGE_MIMETYPE:
        return <ImageAppletPreview src={fileUrl} alt={title} />;
      case VIDEO_MIMETYPE:
        return fileUrl ? (
          <VideoThumbnail file={fileUrl} />
        ) : (
          <PreviewCard backgroundUrl={thumbnailUrl}>
            {localizedStrings.playVideo}
          </PreviewCard>
        );
      case PDF_MIMETYPE:
        return <PdfAppletPreview src={fileUrl} />;
      case AUDIO_MIMETYPE:
        return (
          <PreviewCard backgroundUrl={thumbnailUrl}>
            <PlayIcon />
          </PreviewCard>
        );
      case TEXT_MIMETYPE:
        return (
          <PreviewCard backgroundUrl={thumbnailUrl}>
            <TxtIcon />
          </PreviewCard>
        );
      default:
        return null;
    }
  }, [fileType, fileUrl, thumbnailUrl, title]);

  return Preview;
};

export default FileUploadThumbnail;
