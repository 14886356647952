import { baseService } from 'modules/baseService';
import HttpClient from 'services/HttpClient';
import { Showcase, ShowcaseEmail } from './typings';

export const getShowcase = baseService<string, Showcase>((showcaseId) =>
  HttpClient.get(`/showcases/${showcaseId}`),
);

export const sendEmail = baseService<ShowcaseEmail, void>(
  ({ showcaseId, ...body }) =>
    HttpClient.post(`/showcases/${showcaseId}/contact`, body),
);
