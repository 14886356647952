import React from 'react';
import { CalendarTileProperties } from 'react-calendar';

import ProgressIndicator from 'components/icons/ProgressIndicator';
import SvgText from 'components/icons/SvgText';
import theme from 'utils/theme';
import {
  IconsContainer,
  TileContainer,
  WarningIconContainer,
} from '../components';
import WarningIcon from 'components/icons/WarningIcon';

type TileProps = {
  dayScheduled?: boolean;
  progress?: number;
  isLate?: boolean;
};

const Tile = ({
  date,
  dayScheduled,
  progress = 0,
  isLate,
}: CalendarTileProperties & TileProps) => {
  return (
    <TileContainer>
      {dayScheduled ? (
        <IconsContainer>
          {isLate ? (
            <WarningIconContainer>
              <WarningIcon width="100%" height="100%" />
            </WarningIconContainer>
          ) : null}
          <ProgressIndicator
            progress={progress}
            height="100%"
            width="100%"
            text={date.getDate().toString()}
            textColor={theme.colors.white[100]}
          />
        </IconsContainer>
      ) : (
        <svg width="100%" height="100%" viewBox="0 0 22 22">
          <SvgText
            text={date.getDate().toString()}
            color={theme.colors.white[100]}
          />
        </svg>
      )}
    </TileContainer>
  );
};

export default Tile;
