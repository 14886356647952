import React, { useCallback } from 'react';

import localizedStrings from 'localization';
import {
  PublishedShowcaseContainer,
  PublishedShowcaseTitleAndSubtitle,
  PublishedShowcaseTitle,
  PublishedShowcaseSubtitle,
  PublishedShowcaseShareAndView,
  PublishedShowcaseTextButton,
  PublishedShowcaseSeparator,
} from 'modules/profile/components';
import { ShowcaseItem } from 'modules/profile/typings';

type PublishedShowcaseItemProps = {
  showcase: ShowcaseItem;
  index: number;
  maxIndex: number;
  onDeleteShowcaseClick?: (showcaseId: string) => void;
  onShareShowcaseClick?: (showcaseId: string) => void;
  onViewShowcaseClick: (showcaseId: string) => void;
  showShareAndDelete?: boolean;
};

const PublishedShowcaseItem = ({
  showcase,
  index,
  maxIndex,
  onDeleteShowcaseClick = () => { },
  onShareShowcaseClick = () => { },
  onViewShowcaseClick,
  showShareAndDelete = true,
}: PublishedShowcaseItemProps) => {
  const createdAtDate = new Date(showcase.createdAt);

  const handleDeleteShowcase = useCallback(() => {
    onDeleteShowcaseClick(showcase.id);
  }, [onDeleteShowcaseClick, showcase]);

  const handleShareShowcase = useCallback(() => {
    onShareShowcaseClick(showcase.id);
  }, [onShareShowcaseClick, showcase]);

  const handleViewShowcase = useCallback(() => {
    onViewShowcaseClick(showcase.id);
  }, [onViewShowcaseClick, showcase.id]);

  return (
    <>
      <PublishedShowcaseContainer>
        <PublishedShowcaseTitleAndSubtitle>
          <PublishedShowcaseTitle>{showcase.title}</PublishedShowcaseTitle>
          <PublishedShowcaseSubtitle>
            {localizedStrings.publishedOn}
            {createdAtDate.getUTCMonth() + 1}/{createdAtDate.getDate()}/
            {createdAtDate.getFullYear()}
          </PublishedShowcaseSubtitle>
        </PublishedShowcaseTitleAndSubtitle>
        <PublishedShowcaseShareAndView>
          {showShareAndDelete && (
            <>
              <PublishedShowcaseTextButton onClick={handleDeleteShowcase}>
                {localizedStrings.delete}
              </PublishedShowcaseTextButton>
              <PublishedShowcaseTextButton onClick={handleShareShowcase}>
                {localizedStrings.share}
              </PublishedShowcaseTextButton>
            </>
          )}
          <PublishedShowcaseTextButton onClick={handleViewShowcase}>
            {localizedStrings.viewShowcase}
          </PublishedShowcaseTextButton>
        </PublishedShowcaseShareAndView>
      </PublishedShowcaseContainer>
      {index !== maxIndex && <PublishedShowcaseSeparator />}
    </>
  );
};

export default PublishedShowcaseItem;
