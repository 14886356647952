import styled from '@emotion/styled';

export const PdfPreviewControls = styled.div`
  display: flex;
  align-items: center;
  align-self: center;
  margin-top: 1rem;
`;

export const CurrentPageText = styled.div`
  font-size: ${(props) => props?.theme?.typography.large16};
  line-height: ${(props) => props?.theme?.typography.large20};
  margin-left: 0.5rem;
  margin-right: 0.5rem;
`;
