import styled from '@emotion/styled';
import ButtonPrimary from 'components/button-primary';

export const OptionsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: ${(props) => props?.theme?.typography.large32};
`;

export const SeeResultsButton = styled(ButtonPrimary)`
  width: 50%;
`;

export const QuestionTitle = styled.div`
  font-weight: ${(props) => props?.theme?.typography.semiBold};
  margin-bottom: 0.7rem;
`;
