import styled from '@emotion/styled';

type ButtonProps = {
  disabled?: boolean;
  backgroundColor?: string;
};

export const Button = styled.button<ButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props?.theme?.colors.white[100]};
  font-size: ${(props) => props?.theme?.typography.large18};
  font-weight: ${(props) => props?.theme?.typography.semiBold};
  line-height: ${(props) => props?.theme?.typography.large24};
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  padding: 0.7rem 1rem 0.7rem 1rem;
  border: none;
  border-radius: 0.4rem;
  background-color: ${(props) =>
    props.backgroundColor
      ? props.backgroundColor
      : props?.theme?.colors.blue2[100]};
  opacity: ${(props) => (props.disabled ? 0.3 : 1)};
`;
