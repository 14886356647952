import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useTheme } from '@emotion/react';

import { AppDispatch } from 'redux/typings';
import localizedStrings from 'localization';

import Modal from 'components/modal';
import useActionStatus from 'hooks/useActionStatus';
import { deleteShowcase } from 'modules/profile/actions';

type DeleteShowcaseModalProps = {
  open: boolean;
  closeModal: () => void;
  showcaseId: string;
};

const DeleteShowcaseModal = ({
  open,
  closeModal,
  showcaseId,
}: DeleteShowcaseModalProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const theme = useTheme();
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [isPending, wasFulfilled] = useActionStatus(deleteShowcase);

  const handleDelete = useCallback(() => {
    setHasSubmitted(true);
    dispatch(deleteShowcase(showcaseId));
  }, [setHasSubmitted, dispatch, showcaseId]);

  useEffect(() => {
    if (hasSubmitted && wasFulfilled) {
      setHasSubmitted(false);
      closeModal();
      toast.success(localizedStrings.showcaseDeleted, {
        autoClose: 3000,
        style: { backgroundColor: theme?.colors.success },
      });
    }
  }, [hasSubmitted, wasFulfilled, closeModal, theme?.colors.success]);

  return (
    <Modal
      open={open}
      closeModal={closeModal}
      title={localizedStrings.deleteShowcase}
      width="516px"
      acceptButtonText="Delete"
      acceptButtonisLoading={isPending}
      onAcceptButtonClick={handleDelete}>
      {localizedStrings.deleteShowcasePrompt}
    </Modal>
  );
};

export default DeleteShowcaseModal;
