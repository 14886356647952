import * as React from 'react';

type ClockIconProps = {
  color?: string;
  width: number | string;
  height: number | string;
};

const ClockIcon = ({ color = '#FFFFFF', width, height }: ClockIconProps) => {
  return (
    <svg width={width} height={height} viewBox="0 0 12 12">
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd">
        <g
          id="Icons/12/Clock"
          transform="translate(-281.000000, -148.000000)"
          fill={color}>
          <path d="M286.6,148 C288.085212,148 289.509594,148.589998 290.559798,149.640202 C291.610002,150.690406 292.2,152.114788 292.2,153.6 C292.2,156.692795 289.692795,159.2 286.6,159.2 C283.507205,159.2 281,156.692795 281,153.6 C281,150.507205 283.507205,148 286.6,148 Z M286.6,148.748 C283.920314,148.748 281.748,150.920314 281.748,153.6 C281.748,156.279686 283.920314,158.452 286.6,158.452 C289.277859,158.447596 291.447596,156.277859 291.452,153.6 C291.452,150.920314 289.279686,148.748 286.6,148.748 Z M286.598,150.614 C286.804554,150.614 286.972,150.781446 286.972,150.988 L286.972,150.988 L286.972,153.444 L287.98,154.456 C288.05035,154.52584 288.089916,154.62087 288.089916,154.72 C288.089916,154.81913 288.05035,154.91416 287.98,154.984 C287.828997,155.116658 287.603003,155.116658 287.452,154.984 L287.452,154.984 L286.332,153.864 C286.264781,153.792262 286.226335,153.698282 286.224,153.6 L286.224,153.6 L286.224,150.988 C286.224,150.781446 286.391446,150.614 286.598,150.614 Z" />
        </g>
      </g>
    </svg>
  );
};

export default ClockIcon;
