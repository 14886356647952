import React from 'react';

import LocalizedStrings from 'localization/en';

import {
  RandomSelectionSubmitButton,
  RandomSelectionButtonAddOptionsButton,
  RandomSelectionButtonContainer,
} from '../components';

type RandomSelectionAppletProps = {
  options: React.ReactNode;
  uploadPrompt?: React.ReactNode;
  onButtonClick?: () => void;
  buttonText?: string;
  disabled?: boolean;
  color?: string;
  onAddOptionsClick?: () => void;
  showAddOptionsButton?: boolean;
};

const RandomSelectionApplet = ({
  options,
  uploadPrompt,
  disabled,
  onButtonClick,
  buttonText = LocalizedStrings.chooseRandomly,
  color,
  onAddOptionsClick,
  showAddOptionsButton,
}: RandomSelectionAppletProps) => {
  return (
    <div>
      {uploadPrompt}
      {options}
      {!disabled && onButtonClick ? (
        <RandomSelectionButtonContainer>
          {onAddOptionsClick && showAddOptionsButton ? (
            <RandomSelectionButtonAddOptionsButton onClick={onAddOptionsClick}>
              {LocalizedStrings.addRandomSelectionOptionsButton}
            </RandomSelectionButtonAddOptionsButton>
          ) : null}
          <RandomSelectionSubmitButton
            backgroundColor={color}
            onClick={onButtonClick}>
            {buttonText}
          </RandomSelectionSubmitButton>
        </RandomSelectionButtonContainer>
      ) : null}
    </div>
  );
};

export default RandomSelectionApplet;
