import React, { useMemo } from 'react';

import {
  ActiveSlideIndicatorContainer,
  ActiveSlideIndicatorItem,
} from './components';

type ActiveSlideIndicatorProps = {
  activeIndex: number;
  maxIndex: number;
};

const ActiveSlideIndicator = ({
  activeIndex,
  maxIndex,
}: ActiveSlideIndicatorProps) => {
  const renderItems = useMemo(() => {
    let items = [];
    for (let index = 0; index < maxIndex; index++) {
      items.push(
        <ActiveSlideIndicatorItem active={index === activeIndex} key={index} />,
      );
    }
    return items;
  }, [activeIndex, maxIndex]);

  return (
    <ActiveSlideIndicatorContainer>{renderItems}</ActiveSlideIndicatorContainer>
  );
};

export default ActiveSlideIndicator;
