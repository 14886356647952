import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch } from 'redux/typings';
import { Relationship, RelationshipStatus } from 'models/Relationship.d';
import localizedStrings from 'localization';
import { getRelationshipStatusChangePayloadFromRelationship } from 'helpers/relationshipHelpers';
import {
  changeRelationshipStatus,
  deleteInvo,
  getActiveCohortInformation,
  getRelationships,
  selectRelationship,
} from '../actions';
import { subscribeToChatroom, unsubscribeFromChatroom } from './chat/actions';
import { selectRelationshipsList } from '../selectors';
import { selectLoading } from './chat/selectors';
import {
  RelationshipsSection,
  RelationshipsTitle,
  RelationshipsTitleAndAdd,
  RelationshipsFirstRow,
  RelationshipsSecondRow,
  SearchBox,
  SearchInput,
  RelationshipsPanel,
  AddButton,
  PendingInvitationsPanel,
  RelationshipListContainer,
} from '../components';
import NavBar from 'modules/navbar';
import RelationshipCard from './RelationshipCard';
import Chat from './chat';
import RelationshipModals, { ModalState } from './modals';
import AddIcon from 'components/icons/AddIcon';
import SearchIcon from 'components/icons/SearchIcon';
import { MainContainer, PageBackground } from 'components/components';
import { selectDisplayName } from 'modules/auth/selectors';
import { selectActiveQuest, selectLoadingLastContentFinished } from 'modules/loading-screen/quests/selectors';
import { selectQuestsContentful } from 'modules/loading-screen/contentful/quests/selectors';
import ContentLoader from 'react-content-loader';

const RelationshipsHome = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [filterValue, setFilterValue] = useState('');
  const [modalState, setModalState] = useState<ModalState>({});
  const relationships = useSelector(selectRelationshipsList);
  const isLoading = useSelector(selectLoading);
  const displayName = useSelector(selectDisplayName);
  const activeQuest = useSelector(selectActiveQuest);
  const questsContentful = useSelector(selectQuestsContentful);
  const loadingFinished = useSelector(selectLoadingLastContentFinished);
  
  const areRelationshipsEnabled = useMemo(() => {
    const contentfulId = activeQuest?.contentfulId || '';
    const activeQuestDefinition = questsContentful[contentfulId];
    return activeQuestDefinition?.relationshipsEnabled || false;
  }, [activeQuest, questsContentful]);

  const openAddModal = useCallback(
    () => setModalState({ addModalOpen: true }),
    [],
  );

  const openInvitationAcceptedModal = useCallback(
    (relationship: Relationship) =>
      setModalState({
        invitationAcceptedOpen: true,
        pairedUserDisplayName: relationship.relatedUser.displayName,
        pairedUserProfilePicture: relationship.relatedUser.profilePictureUrl,
        pairedUserRelationshipType: relationship.relationshipType,
      }),
    [],
  );

  const handleCardClick = useCallback(
    (relationship: Relationship) => {
      if (!isLoading) {
        dispatch(selectRelationship(relationship.relatedUser.id));
        dispatch(unsubscribeFromChatroom());
        dispatch(subscribeToChatroom());
      }
    },
    [isLoading, dispatch],
  );

  const handleCancelInvite = useCallback(
    (relationship) => {
      dispatch(
        deleteInvo({
          relationshipId: relationship.id,
        }),
      );
    },
    [dispatch],
  );

  const handleAcceptInvite = useCallback(
    (relationship) => {
      dispatch(
        changeRelationshipStatus(
          getRelationshipStatusChangePayloadFromRelationship(
            relationship,
            displayName,
          ),
        ),
      ).then(() => dispatch(getRelationships()));
      openInvitationAcceptedModal(relationship);
    },
    [dispatch, displayName, openInvitationAcceptedModal],
  );

  const pendingInvitations = useMemo(
    () =>
      relationships.filter(
        (relationship) =>
          relationship.relationshipStatus === RelationshipStatus.RECEIVED,
      ),
    [relationships],
  );

  const filteredRelationships = useMemo(
    () =>
      relationships.filter(
        (relationship) =>
          relationship.relationshipStatus !== RelationshipStatus.RECEIVED &&
          relationship.relatedUser?.displayName
            .toLowerCase()
            .includes(filterValue.toLowerCase()),
      ),
    [relationships, filterValue],
  );

  const handleFilterChange = useCallback((evt) => {
    setFilterValue(evt.target.value);
  }, []);

  useEffect(() => {
    dispatch(getActiveCohortInformation());
    dispatch(getRelationships());
    const id = setInterval(() => {
      dispatch(getRelationships());
    }, 120000);
    dispatch(subscribeToChatroom());
    return () => {
      dispatch(unsubscribeFromChatroom());
      clearInterval(id);
    };
  }, [dispatch]);

  return (
    <>
      <NavBar />
      <RelationshipModals
        modalState={modalState}
        setModalState={setModalState}
      />
      <PageBackground>
        {loadingFinished ? (
          <MainContainer>
            {areRelationshipsEnabled ? (
              <>
                <RelationshipsFirstRow>
                  <RelationshipsSection>
                    <RelationshipsTitleAndAdd>
                      <RelationshipsTitle>
                        {localizedStrings.relationships}
                      </RelationshipsTitle>
                      <AddButton onClick={openAddModal}>
                        <AddIcon />
                      </AddButton>
                    </RelationshipsTitleAndAdd>
                  </RelationshipsSection>
                  <RelationshipsSection>
                    <SearchBox>
                      <SearchIcon />
                      <SearchInput
                        type="text"
                        name="filter"
                        value={filterValue}
                        onChange={handleFilterChange}
                        placeholder={localizedStrings.searchPlaceholder}
                      />
                    </SearchBox>
                  </RelationshipsSection>
                </RelationshipsFirstRow>
                <RelationshipsSecondRow>
                  <RelationshipsSection>
                    <RelationshipListContainer>
                      {pendingInvitations.length > 0 && (
                        <PendingInvitationsPanel>
                          {pendingInvitations.map((relationship, index) => (
                            <RelationshipCard
                              key={relationship.id}
                              relationship={relationship}
                              onCardClick={handleCardClick}
                              onInvitationAccept={handleAcceptInvite}
                              onInvitationCancel={handleCancelInvite}
                              isLast={index === pendingInvitations.length - 1}
                            />
                          ))}
                        </PendingInvitationsPanel>
                      )}
                      <RelationshipsPanel>
                        {filteredRelationships.map((relationship, index) => (
                          <RelationshipCard
                            key={relationship.id}
                            relationship={relationship}
                            onCardClick={handleCardClick}
                            onInvitationAccept={handleAcceptInvite}
                            onInvitationCancel={handleCancelInvite}
                            isLast={index === filteredRelationships.length - 1}
                          />
                        ))}
                      </RelationshipsPanel>
                    </RelationshipListContainer>
                  </RelationshipsSection>
                  <RelationshipsSection>
                    <Chat />
                  </RelationshipsSection>
                </RelationshipsSecondRow>
              </>
            ) : (
              <p>This feature is not available in the trial version.</p>
            )}
          </MainContainer>
        ) : (
          <MainContainer>
            <ContentLoader
              height={60}
              width={'100%'}
              backgroundColor="#f0f0f0"
              foregroundColor="#dedede">
              <rect x="0" y="0" rx="3" ry="3" width="25%" height="38" />
              <rect x="45%" y="0" rx="3" ry="3" width="85%" height="38" />
            </ContentLoader>

            <ContentLoader
              height={700}
              width={'100%'}
              backgroundColor="#f0f0f0"
              foregroundColor="#dedede">
              <rect x="0" y="24" rx="3" ry="3" width="49%" height="100%" />
              <rect x="51%" y="24" rx="3" ry="3" width="60%" height="100%" />
            </ContentLoader>
          </MainContainer>
        )}
      </PageBackground>
    </>
  );
};

export default RelationshipsHome;
