import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  getAppOnboardings as getAppOnboardingsService,
  getAppOnboardingSeen as getAppOnboardingSeenService,
  notifyAppOnboardingSeen as notifyAppOnboardingSeenService,
  getQuestOnboardingSeen as getQuestOnboardingSeenService,
  getQuestOffboardingSeen as getQuestOffboardingSeenService,
  notifyQuestOnboardingSeen as notifyQuestOnboardingSeenService,
  notifyQuestOffboardingSeen as notifyQuestOffboardingSeenService,
  getWeekOnboardingSeen as getWeekOnboardingSeenService,
  notifyWeekOnboardingSeen as notifyWeekOnboardingSeenService,
  createContactActiveCampaignSv,
  addTagToContactActiveCampaignSv,
  getToContactActiveCampaignSv,
} from './services';
import { AddTagToContactPayload, ContactCreatedResponse, ContactTagsCreatedResponse, ContactsResponse, CreateContactPayload } from './typings';

const GET_APP_ONBOARDINGS = 'GET_APP_ONBOARDINGS';
export const getAppOnboardings = createAsyncThunk(
  GET_APP_ONBOARDINGS,
  getAppOnboardingsService,
);

const GET_APP_ONBOARDING_SEEN = 'GET_APP_ONBOARDING_SEEN';
export const getAppOnboardingSeen = createAsyncThunk(
  GET_APP_ONBOARDING_SEEN,
  async (_, thunkApi) => {
    const state = thunkApi.getState() as any;
    const onboardingSeen = state.appOnboardings.appOnboardingSeen;

    if (!onboardingSeen) {
      return getAppOnboardingSeenService();
    }

    return onboardingSeen;
  },
);

const NOTIFY_APP_ONBOARDING_SEEN = 'NOTIFY_APP_ONBOARDING_SEEN';
export const notifyAppOnboardingSeen = createAsyncThunk(
  NOTIFY_APP_ONBOARDING_SEEN,
  notifyAppOnboardingSeenService,
);

const GET_QUEST_ONBOARDING_SEEN = 'GET_QUEST_ONBOARDING_SEEN';
export const getQuestOnboardingSeen = createAsyncThunk<
  boolean,
  { questId: string }
>(GET_QUEST_ONBOARDING_SEEN, ({ questId }, thunkApi) => {
  const state = thunkApi.getState() as any;
  const onboardingSeen = state.appOnboardings.questOnboardingSeen[questId];

  if (!onboardingSeen && questId) {
    return getQuestOnboardingSeenService({ questId });
  }

  return onboardingSeen;
});

const GET_QUEST_OFFBOARDING_SEEN = 'GET_QUEST_OFFBOARDING_SEEN';
export const getQuestOffboardingSeen = createAsyncThunk<
  boolean,
  { questId: string }
>(GET_QUEST_OFFBOARDING_SEEN, ({ questId }, thunkApi) => {
  const state = thunkApi.getState() as any;
  const offboardingSeen = state.appOnboardings.questOffboardingSeen[questId];

  if (!offboardingSeen && questId) {
    return getQuestOffboardingSeenService({ questId });
  }

  return offboardingSeen;
});

const NOTIFY_QUEST_ONBOARDING_SEEN = 'NOTIFY_QUEST_ONBOARDING_SEEN';
export const notifyQuestOnboardingSeen = createAsyncThunk(
  NOTIFY_QUEST_ONBOARDING_SEEN,
  notifyQuestOnboardingSeenService,
);

const NOTIFY_QUEST_OFFBOARDING_SEEN = 'NOTIFY_QUEST_OFFBOARDING_SEEN';
export const notifyQuestOffboardingSeen = createAsyncThunk(
  NOTIFY_QUEST_OFFBOARDING_SEEN,
  notifyQuestOffboardingSeenService,
);

const GET_WEEK_ONBOARDING_SEEN = 'GET_WEEK_ONBOARDING_SEEN';
export const getWeekOnboardingSeen = createAsyncThunk<
  boolean,
  { weekId: string }
>(GET_WEEK_ONBOARDING_SEEN, ({ weekId }, thunkApi) => {
  const state = thunkApi.getState() as any;
  const onboardingSeen = state.appOnboardings.weekOnboardingSeen[weekId];

  if (!onboardingSeen) {
    return getWeekOnboardingSeenService({ weekId });
  }

  return onboardingSeen;
});

const NOTIFY_WEEK_ONBOARDING_SEEN = 'NOTIFY_WEEK_ONBOARDING_SEEN';
export const notifyWeekOnboardingSeen = createAsyncThunk(
  NOTIFY_WEEK_ONBOARDING_SEEN,
  notifyWeekOnboardingSeenService,
);

const SET_NEW_ONBOARDING_WEEK_ID = 'SET_NEW_ONBOARDING_WEEK_ID';
export const setNewOnboardingWeekId = createAction<string>(
  SET_NEW_ONBOARDING_WEEK_ID,
);

const SET_COME_FROM_CHALLENGE = 'SET_COME_FROM_CHALLENGE';
export const setComeFromChallenge = createAction<boolean>(
  SET_COME_FROM_CHALLENGE,
);


// ACTIVE CAMPAIGN
const CREATE_CONTACT_ACTIVECAMPAIGN = 'CREATE_CONTACT_ACTIVECAMPAIGN';
export const createContactActiveCampaign = createAsyncThunk<ContactCreatedResponse, CreateContactPayload>(
  CREATE_CONTACT_ACTIVECAMPAIGN,
  createContactActiveCampaignSv,
);

const ADD_TAG_CONTACT_ACTIVECAMPAIGN = 'ADD_TAG_CONTACT_ACTIVECAMPAIGN';
export const addContactActiveCampaign = createAsyncThunk<ContactTagsCreatedResponse, AddTagToContactPayload>(
  ADD_TAG_CONTACT_ACTIVECAMPAIGN,
  addTagToContactActiveCampaignSv,
);

const GET_CONTACT_ACTIVECAMPAIGN_BY_EMAIL = 'GET_CONTACT_ACTIVECAMPAIGN_BY_EMAIL';
export const getContactActiveCampaignByEmail = createAsyncThunk<ContactsResponse, string>(
  GET_CONTACT_ACTIVECAMPAIGN_BY_EMAIL,
  getToContactActiveCampaignSv,
);
