import React from 'react';
import { useSelector } from 'react-redux';
import { selectLoadingScreenErrors } from '../../modules/error/selectors';
import LocalizedStrings from '../../localization';
import { TxtMessage } from 'common/CommonComponents';
import ErrorActions from 'components/error-actions';

const LoadingScreenErrors = () => {
  const loadingScreenErrors = useSelector(selectLoadingScreenErrors);


  return <>
    {loadingScreenErrors && loadingScreenErrors?.length > 0 ? (
      <TxtMessage> {LocalizedStrings.loadingScreenErrorMessage}</TxtMessage>
    ) : null}

    <br />
    <ErrorActions hasQuitButton hasReportAdminButton />
  </>
};

export default LoadingScreenErrors;
