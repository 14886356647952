import React from 'react';

import useContentfulRenderer from 'hooks/useContentfulRenderer';
import {
  RandomSelectionOptionContainer,
  RandomSelectionOptionText,
} from '../components';
import theme from 'utils/theme';
import { RandomSelectionOptionData } from 'modules/challenge/random-selection-applet-challenge';
import DartAnimation from './DartAnimation';

type RandomSelectionOptionProps = {
  option: RandomSelectionOptionData;
  selected?: boolean;
  disabled?: boolean;
};

const RandomSelectionOption = ({
  option,
  selected,
  disabled,
}: RandomSelectionOptionProps) => {
  const renderedOption = useContentfulRenderer(
    option.optionType === 'contentful-option' ? option.content : undefined,
    {
      additionalStyles: {
        paragraph: {
          marginBottom: '0px',
          flexGrow: 1,
          color:
            selected || !disabled
              ? theme.colors.dark[100]
              : theme.colors.grey[90],
          transition: 'color 1s',
          padding: '1rem',
          marginRight: selected ? '1rem' : '0px',
        },
      },
    },
  );

  return (
    <RandomSelectionOptionContainer selected={selected} disabled={disabled}>
      {option.optionType === 'contentful-option' ? (
        renderedOption
      ) : (
        <RandomSelectionOptionText selected={selected} disabled={disabled}>
          {option.content}
        </RandomSelectionOptionText>
      )}
      {selected ? <DartAnimation /> : null}
    </RandomSelectionOptionContainer>
  );
};

export default RandomSelectionOption;
