import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'redux/typings';

const selectSettingsState = (state: RootState) => state.settings;

export const selectCohorts = createSelector(
  selectSettingsState,
  (settingsState) => settingsState.cohorts,
);

export const selectCohortStartingDate = createSelector(
  selectSettingsState,
  (settingsState) => settingsState.cohortStartingDate,
);

export const selectUserCohortExist = createSelector(
  selectSettingsState,
  (settingsState) => settingsState.userCohortExist,
);

export const selectCohortsLength = createSelector(
  selectCohorts,
  (cohortsState) => cohortsState?.length || 0,
);
