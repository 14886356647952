import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectAppletById } from 'modules/applet-upload/selectors';
import { selectAppletContentfulById } from 'modules/loading-screen/contentful/applets/selectors';
import { quizInformationToQuizQuestion } from 'helpers/AppletHelpers';
import useIsAppletDisabled from './useIsAppletDisabled';
import useExistingApplet from './useExistingApplet';
import useContentfulRenderer from './useContentfulRenderer';
import theme from 'utils/theme';
import { IQuizQuestionOption } from '../models/contentful';

const useQuizChallengeLogic = (appletId: string) => {
  const appletToUpload = useSelector(selectAppletById(appletId));
  const appletContentful = useSelector(selectAppletContentfulById(appletId));
  const quizQuestions = useMemo(
    () => quizInformationToQuizQuestion(appletToUpload?.quizInformation),
    [appletToUpload],
  );

  const isAppletDisabled = useIsAppletDisabled();
  const id = useExistingApplet(appletId);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const allQuizQuestions = useMemo(
    () =>
      appletContentful.quiz?.fields.sections?.flatMap((section) => {
        return section?.fields.questions?.filter((qst) =>
          qst.fields.options?.some((opt) =>
            opt.sys.contentType.sys.id === 'applet'
              ? false
              : (opt as IQuizQuestionOption).fields.result,
          ),
        );
      }),
    [appletContentful],
  );

  const showSeeResultsButton = useMemo(() => {
    if (appletToUpload) {
      const answeredQuestionsMap =
        appletToUpload?.quizInformation?.questionsMap || {};

      return allQuizQuestions?.every((qst) => answeredQuestionsMap[qst.sys.id]);
    }

    return false;
  }, [allQuizQuestions, appletToUpload]);

  const quiz = appletContentful.quiz;

  const renderedQuizIntroduction = useContentfulRenderer(
    quiz?.fields.description,
    {
      additionalStyles: {
        paragraph: { color: theme.colors.black },
      },
    },
  );

  const openResultsModal = useCallback(() => {
    setIsModalOpen(true);
  }, [setIsModalOpen]);

  const showQuizResult =
    isAppletDisabled && (quiz?.fields.rewards?.length || 0) > 0;

  const showResultsButton =
    !appletToUpload ||
    Object.keys(appletToUpload?.quizInformation?.questionsMap || {}).length !==
      quiz?.fields.sections.reduce(
        (prev, currentSection) => prev + currentSection.fields.questions.length,
        0,
      );

  const disabled = isAppletDisabled || !showSeeResultsButton;

  return {
    disabled,
    showQuizResult,
    openResultsModal,
    renderedQuizIntroduction,
    quiz,
    id,
    isModalOpen,
    quizQuestions,
    isAppletDisabled,
    setIsModalOpen,
    showResultsButton,
  };
};

export default useQuizChallengeLogic;
