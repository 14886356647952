import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';

import {
  Container,
  ContainerLeft,
  ContainerRight,
  FormContainer,
  FormTitle,
  Logo,
  ButtonSecondary,
  AuthErrorMessage,
  FormContent,
  ForgotPasswordLink,
} from 'modules/auth/components';
import { AppDispatch } from 'redux/typings';
import { login } from 'modules/auth/actions';
import { selectIsUserVerified } from 'modules/auth/selectors';
import { LoginPayload } from 'modules/auth/typings';
import { loginSchema } from 'modules/auth/validationSchemas';
import {
  AUTH_FORGOT_PASSWORD,
  AUTH_REGISTER,
  AUTH_EMAIL_VERIFICATION,
} from 'modules/auth/routes';
import { LOADING_SCREEN, AUTH } from 'routes';
import logo from 'assets/images/Logo.webp';
import localizedStrings from 'localization';
import ButtonPrimary from 'components/button-primary';
import InputField from 'components/input-field';
import useErrorMessage from 'hooks/useErrorMessage';
import useActionStatus from 'hooks/useActionStatus';

const Login = () => {
  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();
  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);
  const dataEmail = urlParams.get('email')?.replace(' ', '+') || ''
  const dataPassword = urlParams.get('temporalpass')?.replace(' ', '+') || ''

  const [hasPressedButton, setHasPressedButton] = useState(false);
  const [errorMessage, setErrorMessage] = useErrorMessage();

  const isUserVerified = useSelector(selectIsUserVerified);
  const [isLoggingIn, wasLoginFulfilled] = useActionStatus(login);

  useEffect(() => {
    if (wasLoginFulfilled) {
      if (isUserVerified) {
        history.push(LOADING_SCREEN);
      } else if (hasPressedButton) {
        setHasPressedButton(false);
        history.push(AUTH + AUTH_EMAIL_VERIFICATION);
      }
    }
  }, [wasLoginFulfilled, isUserVerified, hasPressedButton, history, dispatch]);

  const handleSubmit = useCallback(
    (values: LoginPayload) => {
      setErrorMessage('');
      setHasPressedButton(true);
      dispatch(login(values));
    },
    [dispatch, setErrorMessage],
  );

  const goToRegister = useCallback(() => {
    history.push(AUTH + AUTH_REGISTER);
  }, [history]);

  const goToForgotPassword = useCallback(() => {
    history.push(AUTH + AUTH_FORGOT_PASSWORD);
  }, [history]);

  useEffect(() => {
    if (dataEmail && dataPassword) {
      handleSubmit({ email: dataEmail, password: dataPassword })
    }
    return () => { }
  }, [dataEmail, dataPassword, handleSubmit])


  return (
    <Container>
      <ContainerLeft>
        <Logo src={logo}></Logo>
      </ContainerLeft>
      <ContainerRight>
        <FormContainer>
          <FormTitle>{localizedStrings.loginHeader}</FormTitle>
          <Formik
            initialValues={{ email: dataEmail, password: dataPassword }}
            onSubmit={handleSubmit}
            validationSchema={loginSchema}>
            <FormContent>
              <InputField
                id="email"
                type="email"
                name="email"
                placeholder={localizedStrings.emailPlaceholder}
              />
              <InputField
                id="password"
                type="password"
                name="password"
                placeholder={localizedStrings.passwordPlaceholder}
              />
              {errorMessage && (
                <AuthErrorMessage>{errorMessage}</AuthErrorMessage>
              )}
              <ForgotPasswordLink onClick={goToForgotPassword}>
                {localizedStrings.loginForgotPassword}
              </ForgotPasswordLink>
              <ButtonPrimary type="submit" isLoading={isLoggingIn}>
                {localizedStrings.loginSignInBtn}
              </ButtonPrimary>
              <ButtonSecondary type="button" onClick={goToRegister}>
                {localizedStrings.register}
              </ButtonSecondary>
            </FormContent>
          </Formik>
        </FormContainer>
      </ContainerRight>
    </Container>
  );
};

export default Login;
