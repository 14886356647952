import { createReducer } from '@reduxjs/toolkit';

import { selectStep } from 'modules/loading-screen/contents/actions';
import { startUpload, updateUploadProgress, finishUpload } from './actions';
import { FilesUploadsState } from './typings';

const initialState: FilesUploadsState = {
  inProgress: false,
  uploads: {},
};

export default createReducer(initialState, (builder) => {
  return builder
    .addCase(startUpload, (state, action) => ({
      ...state,
      inProgress: true,
      failed: false,
      uploads: {
        ...state.uploads,
        [action.payload.id]: {
          id: action.payload.id,
          inProgress: true,
          fileName: action.payload.file.name,
          progress: 0,
        },
      },
    }))
    .addCase(updateUploadProgress, (state, action) => ({
      ...state,
      inProgress: Object.values(state.uploads).every((upload) =>
        upload.id !== action.payload.id
          ? upload.inProgress
          : !action.payload.failed,
      ),
      uploads: {
        ...state.uploads,
        [action.payload.id]: {
          ...state.uploads[action.payload.id],
          progress: action.payload.progress,
          inProgress: !action.payload.failed,
        },
      },
    }))
    .addCase(finishUpload, (state) => ({
      ...initialState,
      failed: Object.values(state.uploads).some(
        (upload) => upload.progress !== 100,
      ),
    }))
    .addCase(selectStep, () => initialState);
});
