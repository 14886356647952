import React from 'react';
import QuizSection from '../quiz-section/QuizSection';
import QuizSectionRenderedDescription from '../quiz-section/QuizSectionDescriptionRenderer';
import QuizSectionQuestionListProfileAndSubmission from '../quiz-section/QuizSectionQuestionListProfileAndSubmission';
import { IQuizSection } from 'models/contentful';
import { QuizQuestion } from 'models/Applet';

type QuizSectionListProfileAndSubmissionProps = {
  title?: string;
  sections?: IQuizSection[];
  quizQuestions?: QuizQuestion[];
};

const QuizSectionListProfileAndSubmission = ({
  title,
  sections,
  quizQuestions,
}: QuizSectionListProfileAndSubmissionProps) => {
  return (
    <>
      {sections?.map((item, index) => {
        return (
          <QuizSection
            key={item.sys.id}
            title={title}
            description={<QuizSectionRenderedDescription item={item} />}
            quizSectionQuestions={
              <QuizSectionQuestionListProfileAndSubmission
                questions={item?.fields?.questions}
                quizQuestionAnswers={quizQuestions}
              />
            }
          />
        );
      })}
    </>
  );
};

export default QuizSectionListProfileAndSubmission;
