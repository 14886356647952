import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'redux/typings';
import { RelationshipType, RelationshipStatus } from 'models/Relationship.d';

const selectRelationships = (state: RootState) => state.relationships;

export const selectRelationshipsList = createSelector(
  selectRelationships,
  (relationships) =>
    Object.values(relationships.hash).sort((relA, relB) => {
      const dateA = new Date(relA.updatedAt ?? 0);
      const dateB = new Date(relB.updatedAt ?? 0);
      const timeDifference = dateB.getTime() - dateA.getTime();
      if (timeDifference === 0) {
        return relA.relatedUser.displayName.localeCompare(
          relB.relatedUser.displayName,
        );
      }
      return timeDifference;
    }),
);

export const selectHasRunningPartner = createSelector(
  selectRelationshipsList,
  (relationshipList) =>
    relationshipList.find(
      (relationship) =>
        (relationship.relationshipType === RelationshipType.RUNNING_PARTNER ||
          relationship.relationshipType ===
            RelationshipType.EXTERNAL_RUNNING_PARTNER) &&
        relationship.relationshipStatus === RelationshipStatus.CONFIRMED,
    )
      ? true
      : false,
);

export const selectHasMentor = createSelector(
  selectRelationshipsList,
  (relationshipList) =>
    relationshipList.find(
      (relationship) =>
        (relationship.relationshipType === RelationshipType.MENTOR ||
          relationship.relationshipType === RelationshipType.EXTERNAL_MENTOR) &&
        relationship.relationshipStatus === RelationshipStatus.CONFIRMED,
    )
      ? true
      : false,
);

export const selectSelectedRelationship = createSelector(
  selectRelationships,
  ({ hash, selectedRelationshipId }) =>
    selectedRelationshipId ? hash[selectedRelationshipId] : undefined,
);

export const selectRunningPartner = createSelector(
  selectRelationships,
  (relationships) => relationships.runningPartner,
);

export const selectActiveCohortInformation = createSelector(
  selectRelationships,
  (relationships) => relationships.cohortInformation,
);

export const selectVisibilityPermissions = createSelector(
  selectRelationships,
  (relationships) => relationships.visibilityPermissions,
);
