import { createAsyncThunk } from '@reduxjs/toolkit';

import { getContents as getContentsService } from './services';
import { ContentContentful } from 'models/ContentContentful';

const GET_CONTENTS = 'GET_CONTENTS';
export const getContents = createAsyncThunk<
  ContentContentful[],
  string | undefined
>(GET_CONTENTS, getContentsService);
