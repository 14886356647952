import { createReducer } from '@reduxjs/toolkit';

import { selectStep } from 'modules/loading-screen/contents/actions';
import {
  openArtifactModal,
  closeArtifactModal,
  openArtifactConfirmModal,
  closeArtifactConfirmModal,
  openAssetHyperlinkModal,
  closeAssetHyperlinkModal,
  openShareAppletModal,
  closeShareAppletModal,
  openShareStepModal,
  closeShareStepModal,
  openCelebrateModal,
  closeCelebrateModal,
  openReadingModal,
  closeReadingModal,
} from './actions';
import { ModalState } from './typings';

const initialState: ModalState = {};

export default createReducer<ModalState>(initialState, (builder) =>
  builder
    .addCase(openArtifactModal, (state, action) => ({
      ...state,
      playlistArtifactId: action.payload,
    }))
    .addCase(closeArtifactModal, (state) => ({
      ...state,
      playlistArtifactId: undefined,
    }))
    .addCase(openArtifactConfirmModal, (state) => ({
      ...state,
      playlistArtifactConfirmModal: true,
    }))
    .addCase(closeArtifactConfirmModal, (state) => ({
      ...state,
      playlistArtifactConfirmModal: false,
    }))
    .addCase(openAssetHyperlinkModal, (state, action) => ({
      ...state,
      assetHyperlinkModalOpen: true,
      assetHyperlinkModalInformation:
        action.payload.assetHyperlinkModalInformation,
    }))
    .addCase(closeAssetHyperlinkModal, (state) => ({
      ...state,
      assetHyperlinkModalOpen: false,
      assetHyperlinkModalInformation: undefined,
    }))
    .addCase(openShareAppletModal, (state, action) => ({
      ...state,
      shareAppletModalOpen: true,
      shareAppletModalInformation: action.payload,
    }))
    .addCase(closeShareAppletModal, (state) => ({
      ...state,
      shareAppletModalOpen: false,
      shareAppletModalInformation: undefined,
    }))
    .addCase(openShareStepModal, (state, action) => ({
      ...state,
      shareStepModalOpen: true,
      shareStepModalInformation: action.payload,
    }))
    .addCase(closeShareStepModal, (state) => ({
      ...state,
      shareStepModalOpen: false,
      shareStepModalInformation: undefined,
    }))
    .addCase(openCelebrateModal, (state) => ({
      ...state,
      celebrateModalOpen: true,
    }))
    .addCase(closeCelebrateModal, (state) => ({
      ...state,
      celebrateModalOpen: false,
    }))
    .addCase(selectStep, (state) => ({
      ...initialState,
      celebrateModalOpen: state.celebrateModalOpen
    }))
    .addCase(openReadingModal, (state, action) => ({
      ...state,
      reading: action.payload,
    }))
    .addCase(closeReadingModal, (state) => ({
      ...state,
      reading: undefined,
    })),
);
