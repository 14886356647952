import React from 'react';
import QuizApplet from 'components/applets/quiz-applet';
import QuizResult from '../../../components/applets/quiz-applet/QuizResult';
import QuizSectionListProfileAndSubmission from '../../../components/applets/quiz-applet/quiz-section-list/QuizSectionListProfileAndSubmission';
import { QuestApplet } from '../../../models/QuestApplet';

type QuizAppletProfileProps = {
  applet: QuestApplet;
};

const QuizAppletProfile = ({ applet }: QuizAppletProfileProps) => {
  const { contentfulId, quiz, quizQuestions, title } = applet;

  return (
    <QuizApplet
      disabled
      showResultsButton
      quizSections={
        <QuizSectionListProfileAndSubmission
          title={title}
          quizQuestions={quizQuestions}
          sections={quiz?.fields.sections}
        />
      }
      quizResult={
        <QuizResult
          appletId={contentfulId}
          quiz={quiz}
          mixedResultText={quiz?.fields.mixedResultText}
          quizQuestions={quizQuestions}
        />
      }
    />
  );
};

export default QuizAppletProfile;
