import styled from '@emotion/styled';

import background from 'assets/images/MapBackground.webp';
import { ScrollableArea } from 'components/components';
import DraggableCarouselItem from 'components/draggable-carousel/DraggableCarouselItem';
import NextChallengeCard from './next-up/NextChallengeCard';
import NextUpCard from './next-up/NextUpCard';

export const NextUpTitle = styled.div`
  ${(props) => `
    color: ${props?.theme?.colors.blue1[100]};
    font-size: ${props?.theme?.typography.large24};
    font-weight: ${props?.theme?.typography.semiBold};
    margin-bottom: 26px;
  `}
`;

type NextUpCardPlaceholderProps = {
  small: boolean;
  widthCard?: string
};

export const NextUpCardPlaceholder = styled.div<NextUpCardPlaceholderProps>`
  font-size: ${(props) =>
    props.small
      ? props?.theme?.typography.large18
      : props?.theme?.typography.large32};
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${(props) => (props.small ? '170px' : '150px')};
  width: ${({ small, widthCard }) => (small ? '327px' : widthCard || '700px')};
  border-radius: 12px;
  border: 1px solid ${(props) => props?.theme?.colors.grey[80]};
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  padding: 3rem;
`;

export const SingleNextUpCard = styled(NextUpCard)`
  margin-right: 0;
`;

export const SingleNextChallengeCard = styled(NextChallengeCard)`
  margin-right: 0;
`;

export const ChallengesSection = styled.div`
  background-image: url(${background});
  background-repeat: no-repeat;
  background-position: center 296px;
  padding: 50px 40px 40px 40px;
`;

export type ChallengeCardContainerProps = {
  imageUrl: string;
};

export const NextUpCardContainer = styled(
  DraggableCarouselItem,
) <ChallengeCardContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px;
  height: 350px;
  width: 700px;
  flex: 0 0 700px;
  border-radius: 12px;
  background-image: linear-gradient(
      180deg,
      #1a1a1a 0%,
      rgba(26, 26, 26, 0) 51.45%,
      #1a1a1a 100%
    ),
    url(${(props) => props.imageUrl});
  background-size: cover;
  margin-right: 32px;
  cursor: pointer;
`;

type NextUpChallengeSubtitleProps = {
  small?: boolean;
};

export const NextUpChallengeSubtitle = styled.div<NextUpChallengeSubtitleProps>`
  font-style: italic;
  font-size: ${(props) =>
    props.small
      ? props?.theme?.typography.large20
      : props?.theme?.typography.large32};
  color: ${(props) => props?.theme?.colors.white[100]};
`;

export const ChallengeInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ChallengeTitlesContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StepDetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

type StepNumberProps = {
  small?: boolean;
};

export const StepNumber = styled.div<StepNumberProps>`
  font-size: ${(props) =>
    props.small
      ? props?.theme?.typography.large20
      : props?.theme?.typography.large32};
  color: ${(props) => props?.theme?.colors.white[25]};
  margin-left: ${(props) =>
    props.small
      ? props?.theme.typography.large20
      : props.theme?.typography.large32};
`;

export const NextUpChallengeTitle = styled.div`
  color: ${(props) => props?.theme?.colors.gold[100]};
  font-size: ${(props) => props?.theme?.typography.large32};
  flex: 1;
`;

export const NextUpStepTitle = styled.div`
  color: ${(props) => props?.theme?.colors.white[100]};
  font-size: ${(props) => props?.theme?.typography.large32};
`;

export const ChallengeIconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-top: ${(props) => props?.theme?.typography.large16};
`;

export const NextChallengeCardContainer = styled(
  DraggableCarouselItem,
) <ChallengeCardContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 8px;
  height: 170px;
  width: 327px;
  flex: 0 0 327px;
  border-radius: 12px;
  background-image: linear-gradient(
      180deg,
      #1a1a1a 0%,
      rgba(26, 26, 26, 0) 51.45%,
      #1a1a1a 100%
    ),
    url(${(props) => props.imageUrl});
  background-size: cover;
  margin-right: 32px;
  cursor: pointer;
`;

export const NextChallengeInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const NextChallengeTitle = styled.div`
  color: ${(props) => props?.theme?.colors.gold[100]};
  font-size: ${(props) => props?.theme?.typography.large20};
  line-height: 20px;
  font-weight: 600;
`;

export const NextStepTitle = styled.div`
  font-size: ${(props) => props?.theme?.typography.large20};
  font-weight: 600;
  line-height: 20px;
  color: #ffffff;
`;

export const NextChallengeIconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

export const ChallengeIconsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const LocationContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

type TextProps = {
  small?: boolean;
};

export const Text = styled.div<TextProps>`
  color: white;
  font-size: ${(props) => (props.small ? '13px' : '18px')};
  margin-left: ${(props) => (props.small ? '4px' : '8px')};
`;

export const PointsContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const TimeContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 12px;
`;

export const DateSelectorContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 60px 0 60px 0;
  color: ${(props) => props?.theme?.colors.white[100]};
`;

export const DateRange = styled.div`
  font-size: ${(props) => props?.theme?.typography.large24};
  margin: 0 30px 0 30px;
`;

export const ChallengeCardPlaceholder = styled.div`
  height: 527px;
  width: 336px;
  opacity: 0.4;
  border-radius: 12px;
  background-color: rgba(26, 26, 26, 0.8);
  margin-right: 32px;
`;

export const LoadingWeekText = styled.div`
  color: white;
  font-size: ${(props) => props?.theme?.typography.large24};
  margin-right: 1rem;
`;

export const PlaylistBackground = styled.div`
  display: flex;
  background-color: black;
  padding: 3.5rem;
  height: 47rem;
`;

export const PlayListCardsPanel = styled(ScrollableArea)`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  height: fit-content;
  max-height: 100%;
`;
