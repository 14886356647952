import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import QuizApplet from 'components/applets/quiz-applet';
import localizedStrings from 'localization';
import { ButtonContainer, SaveButton } from 'common/CommonComponents';
import { ChallengeApplet } from 'models/ChallengeApplet';
import QuizResult from 'components/applets/quiz-applet/QuizResult';
import { selectSelectedContent } from 'modules/loading-screen/contents/selectors';
import useQuizChallengeLogic from 'hooks/useQuizChallengeLogic';
import QuizSectionListCelebrate from '../../../components/applets/quiz-applet/quiz-section-list/QuizSectionListCelebrate';

type QuizAppletCelebrateProps = {
  applet: ChallengeApplet;
};

const QuizAppletCelebrate = ({ applet }: QuizAppletCelebrateProps) => {
  const { quiz, quizQuestions } = useQuizChallengeLogic(applet.appletId);
  const [showQuiz, setShowQuiz] = useState(false);

  const challenge = useSelector(selectSelectedContent);

  const buttonText = useMemo(() => {
    let ret = '';
    if (showQuiz) {
      ret = localizedStrings.seeResults;
    } else {
      if (challenge?.completed) {
        ret = localizedStrings.seeQuiz;
      } else {
        ret = localizedStrings.editQuizButton;
      }
    }

    return ret;
  }, [challenge?.completed, showQuiz]);

  const onClick = useCallback(() => setShowQuiz((prevState) => !prevState), []);

  return (
    <>
      <QuizApplet
        disabled={challenge?.completed}
        showResultsButton
        quizSections={
          <QuizSectionListCelebrate
            showQuiz={showQuiz}
            appletId={applet.appletId}
            disabled={Boolean(challenge?.completed)}
          />
        }
        quizResult={
          !showQuiz ? (
            <QuizResult
              appletId={applet.appletId}
              quiz={quiz}
              mixedResultText={quiz?.fields.mixedResultText}
              quizQuestions={quizQuestions}
            />
          ) : null
        }
      />
      <ButtonContainer>
        <SaveButton onClick={onClick}>{buttonText}</SaveButton>
      </ButtonContainer>
    </>
  );
};

export default QuizAppletCelebrate;
