import HttpClient from 'services/HttpClient';
import { UploadFileFn } from './typings';

export const uploadFile: UploadFileFn = (
  { file, id, appletId },
  onProgress,
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const linkResponse = await HttpClient.post('/storage', {
        originalName: file.uri.split('/').pop(),
        mimeType: file.mime,
      });
      const { link } = linkResponse.data;

      const xhr = new XMLHttpRequest();
      xhr.open('PUT', link);
      xhr.setRequestHeader('Content-Type', file.mime ?? file.type);

      xhr.upload.onprogress = (ev) =>
        onProgress(Math.round((ev.loaded / ev.total) * 100));
      xhr.onload = () => resolve({ fileUrl: link.split('?')[0], id, appletId });
      xhr.onerror = (ev) => {
        reject(ev);
      };

      const blob = await fetch(file.uri).then((r) => r.blob());

      xhr.send(blob);
    } catch (err) {
      console.log(err);
      reject(err);
    }
  });
};
