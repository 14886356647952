import { QuestOffboarding } from 'models/QuestOffboarding';
import { baseService } from 'modules/baseService';
import HttpClient from 'services/HttpClient';
import { getLastUpdatedAtQuery } from 'helpers/helperFunctions';

export const getQuestsOffboardings = baseService<
  string | undefined,
  QuestOffboarding[]
>((lastUpdatedAt) =>
  HttpClient.get(
    `/contentful/quests/offboardings${getLastUpdatedAtQuery(lastUpdatedAt)}`,
  ),
);
