import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import theme from 'utils/theme';

type MainProps = {
  size: number;
  marginLeft: number;
};

export const Main = styled.div<MainProps>`
  display: inline-block;
  position: relative;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  margin-left: ${(props) => props.marginLeft}px;
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

type FirstChildProps = {
  size: number;
  color?: string;
};

export const FirstChild = styled.div<FirstChildProps>`
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  border: ${(props) => props.size * 0.125}px solid #fff;
  border-radius: 50%;
  animation: ${rotate} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: ${(props) => props.color || theme.colors.white} transparent transparent transparent;
`;

export const SecondChild = styled(FirstChild)`
  animation-delay: -0.45s;
`;

export const ThirdChild = styled(FirstChild)`
  animation-delay: -0.3s;
`;

export const FourthChild = styled(FirstChild)`
  animation-delay: -0.15s;
`;


export const CenterLoader = styled.div`
  display: flex; 
  flex: 1;
  justify-content: center;
`;