import React, { ChangeEventHandler, LegacyRef, useRef } from 'react';

type RichTextImageUploadButtonProps = {
  onImageChange: (file: File) => void;
};

const ImageUploadButton = ({
  onImageChange,
}: RichTextImageUploadButtonProps) => {
  const imageInputRef: LegacyRef<HTMLInputElement> | undefined = useRef(null);

  const handleClick = () => {
    imageInputRef.current?.click();
  };

  const handleImage: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (e.target.files) {
      const newFiles = e.target.files;
      const fileType = newFiles[0].type;
      const isSupported =
        fileType && ['image/png', 'image/jpg', 'image/jpeg'].includes(fileType);

      if (isSupported) {
        onImageChange(e.target.files[0]);
      } else {
        alert('File type not supported!');
      }
      e.target.value = '';
    }
  };

  return (
    <>
      <input
        type="file"
        ref={imageInputRef}
        onChange={handleImage}
        accept="image/*"
        hidden></input>
      <button onClick={handleClick}>
        <svg viewBox="0 0 18 18">
          <rect className="ql-stroke" height="10" width="12" x="3" y="4" />
          <circle className="ql-fill" cx="6" cy="7" r="1" />
          <polyline
            className="ql-even ql-fill"
            points="5 12 5 11 7 9 8 10 11 7 13 9 13 12 5 12"
          />
        </svg>
      </button>
    </>
  );
};

export default ImageUploadButton;
