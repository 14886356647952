import { createAsyncThunk } from '@reduxjs/toolkit';

import { getWeeks as getWeeksService } from './services';
import { WeekContentful } from 'models/WeekContentful';

const GET_WEEKS = 'GET_WEEKS';
export const getWeeks = createAsyncThunk<WeekContentful[], string | undefined>(
  GET_WEEKS,
  getWeeksService,
);
