import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { AppDispatch } from 'redux/typings';

import localizedStrings from 'localization/';
import {
  SingleNextChallengeCard,
  SingleNextUpCard,
  NextUpCardPlaceholder,
  NextUpTitle,
} from '../components';
import DraggableCarousel from 'components/draggable-carousel';
import { selectNextUp } from 'modules/loading-screen/schedules/selectors';
import { StepSchedule } from 'models/Schedule';
import TodaysChallengesCard from './NextUpCard';
import { CHALLENGE } from 'routes';
import NextChallengeCard from './NextChallengeCard';
import {
  selectIsQuestCompleted,
  selectActiveQuestCompletedAt,
  selectActiveQuestStartingDate,
  selectHasErrorsContentInActiveQuest,
  selectIsSampleQuest,
} from 'modules/loading-screen/quests/selectors';
import { differenceInDays } from 'date-fns';
import { selectSelectedWeekId } from 'modules/loading-screen/weeks/selectors';
import { selectWeek } from 'modules/loading-screen/weeks/actions';

type NextUpProps = {
  small?: boolean;
  onCardClicked?: () => void;
};

const NextUp = ({ small = false, onCardClicked = () => {} }: NextUpProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();
  const nextUp = useSelector(selectNextUp({ includeBonus: false }));
  const nextUpWithBonus = useSelector(selectNextUp({ includeBonus: true }));
  const questStartingDate = useSelector(selectActiveQuestStartingDate);
  const questCompletedAt = useSelector(selectActiveQuestCompletedAt);
  const isQuestCompleted = useSelector(selectIsQuestCompleted);
  const selectedWeekId = useSelector(selectSelectedWeekId);
  const hasErrorsContent = useSelector(selectHasErrorsContentInActiveQuest);
  const isSampleQuest = useSelector(selectIsSampleQuest);

  const showBonusChallenge = useMemo(
    () =>
      isQuestCompleted &&
      (!nextUp || nextUp.steps.length === 0) &&
      nextUpWithBonus &&
      nextUpWithBonus?.steps.length > 0,
    [isQuestCompleted, nextUp, nextUpWithBonus],
  );

  const nextSteps =
    (showBonusChallenge
      ? nextUpWithBonus &&
        nextUpWithBonus?.steps.length &&
        nextUpWithBonus.steps
      : nextUp && nextUp?.steps.length && nextUp.steps) || [];

  const noChallengesMessage = useMemo(() => {
    if (small) {
      return (
        <NextUpCardPlaceholder small={small}>
          {localizedStrings.noChallengeDefault}
        </NextUpCardPlaceholder>
      );
    }

    if (questCompletedAt || isQuestCompleted) {
      return (
        <NextUpCardPlaceholder small={small}>
          {localizedStrings.noChallengeQuestCompleted(isSampleQuest)}
        </NextUpCardPlaceholder>
      );
    }

    if (hasErrorsContent) {
      return (
        <NextUpCardPlaceholder small={small} widthCard="900px">
          {localizedStrings.errorContentDetectedQuest}
        </NextUpCardPlaceholder>
      );
    }

    if (questStartingDate) {
      const dayDiff = differenceInDays(new Date(), new Date(questStartingDate));
      if (dayDiff < 0) {
        return (
          <NextUpCardPlaceholder small={small}>
            {localizedStrings.noChallengeQuestNotStarted(Math.abs(dayDiff))}
          </NextUpCardPlaceholder>
        );
      } else {
        return (
          <NextUpCardPlaceholder small={small}>
            {localizedStrings.noChallengeQuestStarted}
          </NextUpCardPlaceholder>
        );
      }
    }
    return (
      <NextUpCardPlaceholder small={small}>
        {localizedStrings.noChallengeDefault}
      </NextUpCardPlaceholder>
    );
  }, [
    questCompletedAt,
    questStartingDate,
    small,
    isQuestCompleted,
    hasErrorsContent,
    isSampleQuest,
  ]);

  const handleClick = useCallback(
    (step: StepSchedule) => () => {
      onCardClicked();
      if (nextUp?.weekId && selectedWeekId !== nextUp?.weekId) {
        dispatch(selectWeek(nextUp.weekId));
      }
      history.push(CHALLENGE + '/' + step.challengeId + '/' + step.stepNumber);
    },
    [onCardClicked, nextUp, selectedWeekId, dispatch, history],
  );

  const renderStep = useCallback(
    (step: StepSchedule) => {
      if (small) {
        return (
          <NextChallengeCard
            step={step}
            onClick={handleClick(step)}
            key={step.id}
          />
        );
      } else {
        return (
          <TodaysChallengesCard
            step={step}
            onClick={handleClick(step)}
            key={step.id}
          />
        );
      }
    },
    [small, handleClick],
  );

  const renderSingleStep = useCallback(
    (step: StepSchedule) => {
      if (small) {
        return (
          <div>
            <SingleNextChallengeCard
              step={step}
              onClick={handleClick(step)}
              key={step.id}
            />
          </div>
        );
      } else {
        return (
          <div>
            <SingleNextUpCard
              step={step}
              onClick={handleClick(step)}
              key={step.id}
            />
          </div>
        );
      }
    },
    [small, handleClick],
  );

  return (
    <>
      {!small && (
        <NextUpTitle>
          {showBonusChallenge
            ? localizedStrings.bonusChallenge
            : localizedStrings.nextUp}
        </NextUpTitle>
      )}
      {nextSteps.length > 1 && (
        <DraggableCarousel items={nextSteps} renderItem={renderStep} />
      )}
      {nextSteps.length === 1 && renderSingleStep(nextSteps[0])}
      {nextSteps.length === 0 && noChallengesMessage}
    </>
  );
};

export default NextUp;
