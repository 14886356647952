import { QuestSectionTitle, } from 'modules/profile/components';
import Collapsible from 'components/collapsible';
import { AppletsTitle } from 'modules/profile/typings';
import { QuestApplet } from 'models/QuestApplet';
import { QuestItems } from './QuestItems';

type QuestDropdownItemProps = {
  item: AppletsTitle;
  onAppletClick: (applet: QuestApplet) => void;
  previewHeight?: string;
  previewWidth?: string;
};

export const QuestDropdownItem = ({
  item,
  onAppletClick,
  previewHeight = '139px',
  previewWidth = '146px',
}: QuestDropdownItemProps) => {
  return item.applets.length > 0 ? (
    <Collapsible
      title={<QuestSectionTitle>{item.title}</QuestSectionTitle>}
      initOpen={true}>
      <QuestItems {...{
        applets: item.applets,
        onAppletClick,
        previewHeight,
        previewWidth
      }} />
    </Collapsible>
  ) : null
};
