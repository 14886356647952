import React from 'react';

import { QuestApplet } from 'models/QuestApplet';
import FileUploadPreview from 'modules/profile/portfolio/quest-applets/FileUploadPreview';
import DecisionTreePreview from 'components/applets/decision-tree-applet/DecisionTreePreview';
import {
  ImageAppletModalPreview,
  VideoAppletModalPreview,
  QuizAppletModalPreview,
} from '../../modules/profile/components';
import RankApplet from 'components/applets/rank-applet';
import GroupedAppletModalPreview from '../../modules/profile/portfolio/quest-applets/GroupedAppletModalPreview';
import RandomSelectionAppletProfile from './random-selection-applet-profile';
import MadlibAppletProfile from './madlib-applet-profile';
import QuizAppletProfile from './quiz-applet-profile/index';
import RichTextApplet from 'components/applets/rich-text';

type GroupedAppletModalPreviewProps = {
  applet: QuestApplet;
};

const AppletPreviewSwitch = ({ applet }: GroupedAppletModalPreviewProps) => {
  switch (applet.appletType) {
    case 'upload-image':
      return (
        <ImageAppletModalPreview src={applet.fileUrl} alt={applet.title} />
      );
    case 'upload-video':
      return <VideoAppletModalPreview src={applet.fileUrl} controls autoPlay />;
    case 'generic-rich-text':
    case 'rich-text-entry':
      return <RichTextApplet initialContent={applet.richText} disabled />;
    case 'inline-text':
      return <RichTextApplet initialContent={applet.text} disabled />;
    case 'upload-file':
    case 'generic-upload-file':
      return (
        <FileUploadPreview
          fileUrl={applet.fileUrl!}
          fileType={applet.fileType!}
          title={applet.title}
        />
      );
    case 'quiz':
      return (
        <QuizAppletModalPreview>
          <QuizAppletProfile applet={applet} />
        </QuizAppletModalPreview>
      );
    case 'decision-tree':
      return <DecisionTreePreview files={applet.files} />;
    case 'madlib':
      return <MadlibAppletProfile applet={applet} />;
    case 'rank':
      return (
        <RankApplet
          appletId={applet.contentfulId}
          optionsContentful={applet.optionsContentful!}
          options={applet.options}
          forcedDisabled
        />
      );
    case 'group-applet':
      return <GroupedAppletModalPreview applet={applet} />;
    case 'random-selection':
      return <RandomSelectionAppletProfile applet={applet} />;
    default:
      return null;
  }
};

export default AppletPreviewSwitch;
