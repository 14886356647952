import React from 'react';

import localizedStrings from 'localization';
import {
  InvitationSentDescription,
  InivitationSentButton,
  InvitationSentContainer,
} from 'modules/relationships/components';
import Modal from 'components/modal';
import { AddOptions } from './AddModal';

type InvitationSentModalProps = {
  open: boolean;
  closeModal: () => void;
  option?: AddOptions;
  invitedUserName?: string;
};

const InvitationSentModal = ({
  open,
  closeModal,
  option = AddOptions.ADD_RUNNING_PARTNER,
  invitedUserName = '',
}: InvitationSentModalProps) => {
  return (
    <Modal
      open={open}
      closeModal={closeModal}
      width={'516px'}
      title={localizedStrings.invitationSent}>
      <InvitationSentContainer>
        <InvitationSentDescription>
          {option === AddOptions.ADD_RUNNING_PARTNER
            ? localizedStrings.invitationSentDescription(invitedUserName)
            : localizedStrings.mentorInvitationSentDescription(invitedUserName)}
        </InvitationSentDescription>
        <InivitationSentButton onClick={closeModal}>
          {localizedStrings.gotIt}
        </InivitationSentButton>
      </InvitationSentContainer>
    </Modal>
  );
};

export default InvitationSentModal;
