import React, { useCallback, useEffect, useState } from 'react';

import { QuestPortfolio } from 'models/QuestPortfolio';
import Modal from 'components/modal';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'redux/typings';
import * as statusSelector from 'modules/status/selectors';
import { selectRichTextGenericApplet } from 'modules/profile/selectors';
import { uploadGenericRichText } from 'modules/applets/actions';
import RichTextApplet from 'components/applets/rich-text';
import { toast } from 'react-toastify';
import localizedStrings from 'localization/en';

type AddRichTextModalProps = {
  open: boolean;
  quest: QuestPortfolio;
  closeModal: () => void;
};

const AddRichTextModal = ({
  open,
  quest,
  closeModal,
}: AddRichTextModalProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const [richText, setRichText] = useState('');
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const genericApplet = useSelector(selectRichTextGenericApplet);
  const isUploading = useSelector((state: RootState) =>
    statusSelector.isPendingSelector(state, uploadGenericRichText.typePrefix),
  );
  const finishedUploading = useSelector((state: RootState) =>
    statusSelector.wasFulfilledSelector(
      state,
      uploadGenericRichText.typePrefix,
    ),
  );

  const onContentChange = useCallback((content: string) => {
    setRichText(content);
  }, []);

  const handleUpload = useCallback(() => {
    if (genericApplet && genericApplet.type === 'generic-rich-text') {
      if (richText === '' || richText === '<p><br></p>') {
        toast.error(localizedStrings.errorMessageRichTextModalIncomplete);
      } else {
        setHasSubmitted(true);
        dispatch(
          uploadGenericRichText({
            appletId: genericApplet.id,
            questId: quest.id,
            type: genericApplet.type,
            richText,
          }),
        );
      }
    }
  }, [dispatch, genericApplet, quest, richText]);

  useEffect(() => {
    if (finishedUploading && hasSubmitted) {
      setHasSubmitted(false);
      closeModal();
    }
  }, [closeModal, finishedUploading, hasSubmitted]);

  return (
    <Modal
      open={open}
      closeModal={closeModal}
      width="auto"
      acceptButtonText="Upload"
      onAcceptButtonClick={handleUpload}
      acceptButtonisLoading={isUploading}>
      <RichTextApplet
        disabled={false}
        onContentChange={onContentChange}
        initialContent={genericApplet?.defaultHtml}
      />
    </Modal>
  );
};

export default AddRichTextModal;
