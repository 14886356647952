import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { ContentContentful } from 'models/ContentContentful';

import BadgeIcon from '../../../components/icons/BadgeIcon';
import clock from 'assets/icons/Clock.webp';
import solo from 'assets/icons/Solo.webp';
import {
  ChallengeDetailItemText,
  ChallengeDetailsContainer,
  ChallengeDetailsItem,
} from '../components';
import { selectSelectedStepIsFirst } from 'modules/loading-screen/contents/selectors';
import theme from 'utils/theme';
import useLocationIconText from '../../../hooks/useLocationIconText';
import LocalizedStrings from 'localization';

export enum Icons {
  PHONE = 'phone',
  VIDEO = 'video',
  AUDIO = 'audio',
  GESTURE = 'gesture',
  COMPUTER = 'computer',
  READING = 'reading',
  WRITING = 'writing',
}

type ChallengeDetailsProps = {
  challenge: ContentContentful;
};

const ChallengeDetails = ({ challenge }: ChallengeDetailsProps) => {
  const isFirstStep = useSelector(selectSelectedStepIsFirst);

  const dimension = useMemo(() => theme.typography.large24, []);

  const [Icon, text] = useLocationIconText(challenge.location || '');

  return isFirstStep ? (
    <ChallengeDetailsContainer>
      <ChallengeDetailsItem>
        <Icon
          width={dimension}
          height={dimension}
          color={theme.colors.grey[100]}
        />
        <ChallengeDetailItemText>{text}</ChallengeDetailItemText>
      </ChallengeDetailsItem>
      <ChallengeDetailsItem>
        <img src={clock} width={22} height={22} alt="clock" />
        <ChallengeDetailItemText>{challenge.duration}</ChallengeDetailItemText>
      </ChallengeDetailsItem>
      <ChallengeDetailsItem>
        <img src={solo} width={28} height={28} alt="solo" />
        <ChallengeDetailItemText>
          {challenge.participants}
        </ChallengeDetailItemText>
      </ChallengeDetailsItem>
      <ChallengeDetailsItem>
        <BadgeIcon
          width={dimension}
          height={dimension}
          color={theme.colors.grey[90]}
        />
        <ChallengeDetailItemText>{`Earn ${challenge.totalXp ?? 0
          }${LocalizedStrings.experience(
            challenge.bonusChallenge,
          )}`}</ChallengeDetailItemText>
      </ChallengeDetailsItem>
    </ChallengeDetailsContainer>
  ) : null;
};

export default ChallengeDetails;
