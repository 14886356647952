import React from 'react';
import theme from 'utils/theme';

type OvalIconProps = {
  width: number | string;
  height: number | string;
  color?: string;
};

const OvalIcon = ({
  width,
  height,
  color = theme.colors.white[100],
}: OvalIconProps) => {
  return (
    <svg width={width} height={height} viewBox="0 0 18 18">
      <g
        id="V3"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        opacity="0.2">
        <g
          id="Quest---Active-user-1"
          transform="translate(-288.000000, -379.000000)"
          stroke={color}
          strokeWidth="1.5">
          <g id="Cards/Day" transform="translate(172.000000, 372.000000)">
            <g id="To-do-Copy" transform="translate(117.000000, 8.000000)">
              <circle id="Oval-Copy-21" cx="8" cy="8" r="8" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default OvalIcon;
