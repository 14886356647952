interface DefaultAvatarColors {
  background: string;
  text: string;
}

const AVATAR_COLORS: DefaultAvatarColors[] = [
  { background: '#FFE66D', text: '#674125' },
  { background: '#AF309B', text: '#FFFEFE' },
  { background: '#6D597A', text: '#FFFFFF' },
  { background: '#FF006E', text: '#FFFFFF' },
  { background: '#DDBEA9', text: '#674125' },
  { background: '#A5A58D', text: '#FFFFFF' },
  { background: '#457B9D', text: '#FFFFFF' },
  { background: '#A8DADC', text: '#674125' },
  { background: '#1E96FC', text: '#FFFFFF' },
  { background: '#2A9D8F', text: '#FFFFFF' },
  { background: '#F7D3AA', text: '#674125' },
  { background: '#D0814E', text: '#FFFFFF' },
  { background: '#77FCD9', text: '#674125' },
  { background: '#9D4EDD', text: '#FFFFFF' },
  { background: '#12ECEC', text: '#674125' },
  { background: '#6F5CBE', text: '#FFFFFF' },
  { background: '#E26D5C', text: '#F6F6F6' },
  { background: '#CFDBD5', text: '#674125' },
  { background: '#9F86C0', text: '#FFFFFF' },
  { background: '#FFB600', text: '#674125' },
  { background: '#E766DA', text: '#674125' },
  { background: '#577590', text: '#FFFFFF' },
  { background: '#0F44AB', text: '#FFFFFF' },
  { background: '#6A4C93', text: '#FFFFFF' },
  { background: '#9BFF5E', text: '#674125' },
  { background: '#A3B18A', text: '#FFFFFF' },
];

const hashName = (name: string, maxRange: number): number => {
  let total = 0;
  const multiplier = 37;
  // the reason to choose 37 being, by some empirical research, if we take over 50,000 English words
  // (formed as the union of the word lists provided in two variants of Unix),
  // using the constants 31, 33, 37, 39, and 41 will produce less than 7 collisions
  // in each case, while creating a hasing function.
  // For more information: https://medium.com/javascript-in-plain-english/algorithm-in-javascript-hash-table-7b0464d2b81b
  for (let letterIndex = 0; letterIndex < name.length; letterIndex++) {
    total += multiplier * total + name.charCodeAt(letterIndex);
  }

  // There was a crash originating from the hashName function returning NaN when the name has too many characters.
  // What happened is it ended multiplying too many times and returning a really big number (Infinity).

  // Then the total returned (and the hashName function) returned NaN
  // So when using said getAvatarColors to get the avatar color it would break since NaN is not a valid number to insert into an array
  total %= maxRange;
  return Number.isFinite(total) ? total : 0;
};

export const getAvatarColors = (name?: string): DefaultAvatarColors =>
  name
    ? AVATAR_COLORS[hashName(name, AVATAR_COLORS.length)]
    : AVATAR_COLORS[AVATAR_COLORS.length - 1];

export const getUserInitials = (name?: string): string => {
  const firstName = 0;
  const firstLetter = 0;
  let initials = '*';
  if (name && name.length > 0) {
    const names = name.trim().split(' ');
    initials = names[firstName].charAt(firstLetter);
    if (names.length > 1) {
      const lastName = names.length - 1;
      initials += names[lastName].charAt(firstLetter);
    }
  }

  return initials.toUpperCase();
};
