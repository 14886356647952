import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'redux/typings';

const selectContentsState = (state: RootState) => state.contentful.contents;

export const selectContentsContentful = createSelector(
  selectContentsState,
  (contentsState) => contentsState.contents,
);

export const selectContentsContentfulLastUpdatedAt = createSelector(
  selectContentsState,
  (contentsState) => contentsState.lastUpdatedAt,
);
