import styled from '@emotion/styled';
import ButtonPrimary from 'components/button-primary';
import {
  MainContainer,
  ScrollablePanel,
  ScrollableArea,
  Panel,
} from 'components/components';
import { Form } from 'formik';

export const RelationshipsFirstRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.6rem;
`;

export const RelationshipsSecondRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const RelationshipsSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 49%;
`;

export const RelationshipListContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 13rem);
`;

export const RelationshipsPanel = styled(ScrollablePanel)`
  flex: 1;
`;

export const PendingInvitationsPanel = styled(Panel)`
  margin-bottom: 1rem;
`;

export const ChatPanel = styled(Panel)`
  height: calc(100vh - 13rem);
`;

export const RelationshipsTitleAndAdd = styled.div`
  display: flex;
  align-self: center;
  justify-content: space-between;
  width: 100%;
`;

export const RelationshipsTitle = styled.div`
  color: ${(props) => props?.theme?.colors.blue1[100]};
  font-size: ${(props) => props?.theme?.typography.large24};
  font-weight: ${(props) => props?.theme?.typography.semiBold};
  line-height: ${(props) => props?.theme?.typography.large32};
`;

export const AddButton = styled.button`
  border: none;
  background-color: transparent;
  padding: 0;
`;

export const SearchBox = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  height: 3.1rem;
  border: 1px solid #8e8e93;
  border-radius: 14px;
  background-color: #ffffff;
`;

export const SearchInput = styled.input`
  flex: 1;
  border: none;
  outline: none;
`;

type RelationshipCardContainerProps = {
  pointer: boolean;
  isLast?: boolean;
};

export const RelationshipCardContainer = styled.div<RelationshipCardContainerProps>`
  cursor: ${(props) => (props.pointer ? 'pointer' : 'auto')};
  display: flex;
  margin-bottom: ${(props) => (props.isLast ? 0 : '15px')};
`;

export const AvatarContainer = styled.div`
  position: relative;
  margin-right: 15px;
`;

export const UserNameAndStatusContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const UserName = styled.div`
  color: ${(props) => props?.theme?.colors.grey[100]};
  font-size: ${(props) => props?.theme?.typography.large16};
  font-weight: ${(props) => props?.theme?.typography.semiBold};
  line-height: ${(props) => props?.theme?.typography.large20};
`;

export const UserStatus = styled.div`
  color: ${(props) => props?.theme?.colors.grey[90]};
  font-size: ${(props) => props?.theme?.typography.large16};
  line-height: ${(props) => props?.theme?.typography.large20};
`;

export const LastActivityAndUnreadMessagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const LastActivity = styled.div`
  color: ${(props) => props?.theme?.colors.grey[90]};
  font-size: ${(props) => props?.theme?.typography.large16};
  line-height: ${(props) => props?.theme?.typography.large20};
`;

export const UnreadMessages = styled.div`
  height: 1.33rem;
  width: 1.33rem;
  border-radius: 50%;
  background-color: ${(props) => props?.theme?.colors.blue2[100]};
  color: #ffffff;
  font-size: ${(props) => props?.theme?.typography.large16};
  font-weight: ${(props) => props?.theme?.typography.semiBold};
  line-height: ${(props) => props?.theme?.typography.large24};
  text-align: center;
`;

export const CancelButton = styled.button`
  background-color: transparent;
  border: none;
  padding: 0;
  color: ${(props) => props?.theme?.colors.grey[90]};
  font-size: ${(props) => props?.theme?.typography.large16};
  line-height: ${(props) => props?.theme?.typography.large20};
`;

export const AcceptButton = styled.button`
  background-color: transparent;
  border: none;
  padding: 0;
  color: ${(props) => props?.theme?.colors.blue1[100]};
  font-size: ${(props) => props?.theme?.typography.large16};
  line-height: ${(props) => props?.theme?.typography.large20};
  margin-left: 1rem;
`;

export const ViewProfileButton = styled.button`
  background-color: transparent;
  border: none;
  padding: 0;
  color: ${(props) => props?.theme?.colors.blue3[100]};
  font-size: ${(props) => props?.theme?.typography.large13};
  line-height: ${(props) => props?.theme?.typography.large18};
`;

export const RelationshipSeparator = styled.div`
  box-sizing: border-box;
  height: 2px;
  border: 1px solid rgba(199, 199, 204, 0.3);
  margin-bottom: 15px;
`;

export const ChatDate = styled.div`
  color: ${(props) => props?.theme?.colors.grey[90]};
  font-size: ${(props) => props?.theme?.typography.large13};
  line-height: ${(props) => props?.theme?.typography.large18};
  font-weight: 300;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
`;

type MessageTextProps = {
  right: boolean;
};

export const MessageText = styled.div<MessageTextProps>`
  align-self: ${(props) => (props.right ? 'flex-end' : 'flex-start')};
  margin-bottom: 0.3rem;
  display: flex;
  width: fit-content;
  color: ${(props) =>
    props.right
      ? props?.theme?.colors.white[100]
      : props?.theme?.colors.grey[100]};
  font-size: ${(props) => props?.theme?.typography.large16};
  line-height: ${(props) => props?.theme?.typography.large18};
  border-radius: ${(props) =>
    props.right ? '1rem 1rem 0.2rem 1rem' : '1rem 1rem 1rem 0.2rem'};
  background-color: ${(props) =>
    props.right
      ? props?.theme?.colors.blue2[80]
      : props?.theme?.colors.grey[80]};
  padding: 0.5rem 1rem 0.5rem 1rem;
`;

export const MessageHour = styled.div`
  align-self: flex-end;
  font-size: ${(props) => props?.theme?.typography.large10};
  line-height: ${(props) => props?.theme?.typography.large13};
  margin-left: 0.5rem;
`;

export const ChatInputContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ChatInput = styled.input`
  flex: 1;
  border: none;
  outline: none;
  padding-left: 1rem;
  border-radius: 0.8rem;
  color: ${(props) => props?.theme?.colors.grey[100]};
  font-size: ${(props) => props?.theme?.typography.large16};
  line-height: ${(props) => props?.theme?.typography.large32};
  background-color: ${(props) => props?.theme?.colors.grey[80]};
  margin-right: 0.5rem;
`;

export const SendMessageButton = styled.button`
  border: none;
  padding: 0;
  background-color: transparent;
`;

export const ChatMessagesContainer = styled(ScrollableArea)`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-right: 0.5rem;
`;

export const AddRelationshipModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const AddRelationshipButton = styled.button`
  display: flex;
  justify-content: center;
  background-color: ${(props) => props?.theme?.colors.white[100]};
  border-radius: 0.5rem;
  padding: 0.5rem 0.8rem 0.5rem 0.5rem;
  border: none;
  margin: 0.5rem;
  width: 70%;
  align-items: center;
`;

export const AddRelationshipButtonText = styled.div`
  color: ${(props) => props?.theme?.colors.blue2[100]};
  font-size: ${(props) => props?.theme?.typography.large20};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0px;
  padding-bottom: 0px;
`;

export const SwitchRunningPartnerModalContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
export const SwitchRunningPartnerDescription = styled.div`
  color: ${(props) => props?.theme?.colors.grey[100]};
  font-size: ${(props) => props?.theme?.typography.large16};
  line-height: ${(props) => props?.theme?.typography.large20};
  margin-bottom: 1rem;
`;

export const SwitchRunningPartnerPromp = styled.div`
  font-size: ${(props) => props?.theme?.typography.large16};
  font-weight: ${(props) => props?.theme?.typography.semiBold};
  line-height: ${(props) => props?.theme?.typography.large20};
  margin-bottom: 1rem;
`;

export const RadioButtonContainer = styled.button`
  background-color: transparent;
  border: none;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
`;

export const RadioButtonText = styled.div`
  color: ${(props) => props?.theme?.colors.grey[100]};
  font-size: ${(props) => props?.theme?.typography.large16};
  line-height: ${(props) => props?.theme?.typography.large20};
`;

export const RadioButtonCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${(props) => props?.theme?.colors.blue2[100]};
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 50%;
`;

export const RadioButtonFill = styled.div`
  background-color: ${(props) => props?.theme?.colors.blue2[100]};
  height: 70%;
  width: 70%;
  border-radius: 50%;
`;

export const DoneButton = styled.button`
  border: none;
  border-radius: 0.6rem;
  padding: 0.5rem 1rem 0.5rem 1rem;
  width: 50%;
  align-self: center;
  margin-top: 1rem;
  color: white;
  background-color: ${(props) => props?.theme?.colors.blue2[100]};
  font-size: ${(props) => props?.theme?.typography.large18};
  line-height: ${(props) => props?.theme?.typography.large18};
`;

export const AddRunningPartenerModalContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const AddRunningPartnerSearchBox = styled(SearchBox)`
  margin-bottom: 1rem;
`;

export const FellowTravelersList = styled(ScrollableArea)`
  margin-bottom: 1rem;
  padding-right: 0.5rem;
`;

export const AddSomeoneElseButton = styled(ButtonPrimary)`
  margin: 0;
`;

export const InvitationSentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InvitationSentDescription = styled.div`
  color: ${(props) => props?.theme?.colors.grey[100]};
  font-size: ${(props) => props?.theme?.typography.large16};
  line-height: ${(props) => props?.theme?.typography.large20};
`;

export const InivitationSentButton = styled(ButtonPrimary)`
  width: 70%;
  align-self: center;
  margin-top: 3rem;
`;

export const InvitationAcceptedContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${(props) => props?.theme?.colors.blue3[100]};
  border: 0.5px solid #979797;
  border-radius: 12px;
  box-shadow: 0 1px 35px 0 rgba(0, 0, 0, 0.6);
  padding: 2rem;
`;

export const InvitationAcceptedImage = styled.img``;

export const PairedUsersContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 60%;
  margin-top: -5rem;
`;

export const InvitationAcceptedTitle = styled.div`
  color: ${(props) => props?.theme?.colors.gold[100]};
  font-size: ${(props) => props?.theme?.typography.large24};
  line-height: ${(props) => props?.theme?.typography.large32};
  font-weight: ${(props) => props?.theme?.typography.semiBold};
  margin-top: 2rem;
`;

export const InvitationAcceptedDescription = styled.div`
  color: ${(props) => props?.theme?.colors.white[100]};
  font-size: ${(props) => props?.theme?.typography.large18};
  line-height: ${(props) => props?.theme?.typography.large24};
  margin-top: 2rem;
`;

export const InvitationAcceptedButton = styled.button`
  border: none;
  border-radius: 0.6rem;
  padding: 0.5rem 1rem 0.5rem 1rem;
  width: 50%;
  align-self: center;
  margin-top: 3rem;
  color: white;
  background-color: ${(props) => props?.theme?.colors.blue2[100]};
  font-size: ${(props) => props?.theme?.typography.large18};
  line-height: ${(props) => props?.theme?.typography.large18};
`;

export const InviteSomeoneElsePrompt = styled.div`
  font-size: ${(props) => props?.theme?.typography.large18};
  line-height: ${(props) => props?.theme?.typography.large24};
  margin-bottom: 1.5rem;
`;

export const ButtonsRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export const InviteCancelButton = styled(ButtonPrimary)`
  background-color: ${(props) => props?.theme?.colors.grey[90]};
  margin: 0 1rem 0 0;
`;

export const InviteButton = styled(ButtonPrimary)`
  margin: 0;
`;

export const RelatedUserProfileContainer = styled(MainContainer)`
  flex-direction: row;
`;

export const RelatedUserInfoSection = styled(Panel)`
  align-items: center;
  padding: 1.8rem 2.8rem 2.8rem 2.8rem;
  margin-right: 1.6rem;
  height: fit-content;
`;

export const RelatedUserName = styled.div`
  color: ${(props) => props?.theme?.colors.blue1[100]};
  font-size: ${(props) => props?.theme?.typography.large24};
  line-height: ${(props) => props?.theme?.typography.large32};
  font-weight: ${(props) => props?.theme?.typography.semiBold};
  margin-top: 2.3rem;
`;

export const RelatedUserRelation = styled.div`
  color: #02203c;
  font-size: ${(props) => props?.theme?.typography.large13};
  line-height: ${(props) => props?.theme?.typography.large18};
  margin-top: 0.2rem;
`;

export const ChatButton = styled(ButtonPrimary)`
  font-size: ${(props) => props?.theme?.typography.large16};
  line-height: ${(props) => props?.theme?.typography.large20};
  border-radius: 2rem;
  padding: 0.5rem 2rem 0.5rem 2rem;
  margin-top: 1.2rem;
  margin-bottom: 0;
  width: auto;
`;

export const ChatButtonText = styled.span`
  margin-right: 0.5rem;
`;

export const RelatedUserDetailsSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const RelatedUserPortfolioSection = styled(Panel)`
  padding: 1.8rem 3.8rem 1.8rem 3.8rem;
  margin-bottom: 1.6rem;
`;

export const RelatedUserCurrentQuest = styled.div`
  color: ${(props) => props?.theme?.colors.blue2[100]};
  font-size: ${(props) => props?.theme?.typography.large18};
  line-height: ${(props) => props?.theme?.typography.large24};
  margin-bottom: 1.6rem;
`;

export const RelatedUserAchievementsSection = styled(Panel)``;

export const RelatedUserChallengePanel = styled(Panel)`
  align-items: center;
  margin-bottom: 0.6rem;
  padding: 2rem 2.5rem 2.5rem 2.5rem;
`;

export const RelatedUserChallengeTitle = styled.div`
  color: ${(props) => props?.theme?.colors.blue1[100]};
  font-size: ${(props) => props?.theme?.typography.large24};
  line-height: ${(props) => props?.theme?.typography.large32};
  font-weight: ${(props) => props?.theme?.typography.semiBold};
  margin-bottom: 1rem;
`;

export const RelatedUserReflect = styled.div`
  align-self: flex-start;
  color: ${(props) => props?.theme?.colors.grey[100]};
  font-size: ${(props) => props?.theme?.typography.large16};
  line-height: ${(props) => props?.theme?.typography.large24};
  font-weight: ${(props) => props?.theme?.typography.semiBold};
`;

export const RelatedUserReflectText = styled.div`
  align-self: flex-start;
  color: ${(props) => props?.theme?.colors.grey[100]};
  font-size: ${(props) => props?.theme?.typography.large16};
  line-height: ${(props) => props?.theme?.typography.large24};
`;

export const RelatedUserReviewsPanel = styled(Panel)`
  padding: 1.6rem;
  margin-bottom: 1rem;
`;

export const SubmissionVideoPreview = styled.video`
  max-height: 27rem;
  max-width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 0.6rem;
  padding: 0.8rem;
  margin-bottom: 1rem;
`;

export const SubmissionImagePreview = styled.img`
  max-height: 27rem;
  max-width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 0.6rem;
  padding: 0.8rem;
  margin-bottom: 1rem;
`;

export const SubmissionPreview = styled.div`
  margin-bottom: 1rem;
`;

export const SubmissionRatingContainer = styled.div`
  margin-bottom: 1rem;
`;

export const SubmissionFeedbackTitle = styled.div`
  color: ${(props) => props?.theme?.colors.grey[100]};
  font-size: ${(props) => props?.theme?.typography.large16};
  line-height: ${(props) => props?.theme?.typography.large20};
  font-weight: ${(props) => props?.theme?.typography.semiBold};
  margin-bottom: 1rem;
`;

export const SubmissionFeedbackPanel = styled(Panel)`
  padding: 1.5rem 2.5rem 2.2rem 2.5rem;
  align-items: center;
  margin-bottom: 2rem;
`;

export const SubmissionFeedbackForm = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const SubmissionFeedbackSubmitButton = styled(ButtonPrimary)`
  width: 50%;
  margin-top: 1rem;
  margin-bottom: 0;
`;
