export enum FILTER_OPTIONS {
  ALL = 'ALL',
  COMPLETED = 'COMPLETED',
  TODO = 'TODO',
  LATE = 'LATE',
}

export type OverviewState = {
  filter: FILTER_OPTIONS;
  activeTab: number;
  weekNumber?: number;
};
