import styled from '@emotion/styled';

type AvatarImageProps = {
  size: number;
};

export const AvatarImage = styled.img<AvatarImageProps>`
  height: ${(props) => props.size}px;
  width: ${(props) => props.size}px;
  border-radius: 50%;
  object-fit: cover;
`;

type AvatarInitialsProps = {
  size: number;
  color: string;
  backgroundColor: string;
};

export const AvatarInitials = styled.div<AvatarInitialsProps>`
  height: ${(props) => props.size}px;
  width: ${(props) => props.size}px;
  flex: 0 0 ${(props) => props.size}px;
  border-radius: 50%;
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.color};
  font-size: ${(props) => props.size * 0.5}px;
  line-height: ${(props) => props.size}px;
  text-align: center;
`;
