import React, { ReactNode, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { updateProfileVisibilityAchievements } from 'modules/auth/actions';
import { VisibilityAchievementsPayload } from 'modules/auth/typings';
import { selectVisibilityAchievements } from 'modules/auth/selectors';
import './styles.css';

type VisibilityPermissionsToggleProps = {
  visibilityPermissionForUpdate: VisibilityAchievementsPayload;
  title: ReactNode;
};

export const VisibilityPermissionsToggle = ({
  visibilityPermissionForUpdate = { visibilityAchievements: 'EVERYBODY' },
  title,
}: VisibilityPermissionsToggleProps) => {
  const dispatch = useDispatch();
  const visibilityPermissions = useSelector(selectVisibilityAchievements);
  const [valueCheck, setValueCheck] = useState(
    visibilityPermissions ===
      visibilityPermissionForUpdate.visibilityAchievements ||
      visibilityPermissions === 'EVERYBODY',
  );

  const onSelectValue = () => {
    switch (visibilityPermissions) {
      case visibilityPermissionForUpdate.visibilityAchievements:
        dispatch(
          updateProfileVisibilityAchievements({
            visibilityAchievements: 'NOBODY',
          }),
        );
        break;
      case 'EVERYBODY':
        if (
          visibilityPermissionForUpdate.visibilityAchievements ===
          'FELLOW TRAVELER'
        ) {
          dispatch(
            updateProfileVisibilityAchievements({
              visibilityAchievements: 'RUNNING PARTNER',
            }),
          );
        } else {
          dispatch(
            updateProfileVisibilityAchievements({
              visibilityAchievements: 'FELLOW TRAVELER',
            }),
          );
        }
        break;
      case 'NOBODY':
        dispatch(
          updateProfileVisibilityAchievements(visibilityPermissionForUpdate),
        );
        break;
      default:
        dispatch(
          updateProfileVisibilityAchievements({
            visibilityAchievements: 'EVERYBODY',
          }),
        );
        break;
    }
    setValueCheck(!valueCheck);
  };

  return (
    <div>
      <label className="toggle">
        {title}
        <input
          className="toggle-checkbox"
          type="checkbox"
          checked={valueCheck}
          onChange={onSelectValue}
        />
        <div className="toggle-switch"></div>
      </label>
    </div>
  );
};
