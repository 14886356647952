import styled from '@emotion/styled';

export const CarouselContainer = styled.div`
  width: 100%;
  overflow: hidden;
`;

type CarouselItemsContainerProps = {
  currentIndex: number;
};

export const CarouselItemsContainer = styled.div<CarouselItemsContainerProps>`
  width: 100%;
  display: flex;
  transform: translateX(-${(props) => props.currentIndex * 100}%);
  transition: all 0.5s ease-out;
`;

type IndicatorItemProps = {
  active: boolean;
};

export const ActiveSlideIndicatorItem = styled.div<IndicatorItemProps>`
  height: 12px;
  width: 12px;
  border-radius: 6px;
  background-color: #ffffff;
  margin: 6px;
  opacity: ${(props) => (props.active ? 1 : 0.2)};
`;

export const ActiveSlideIndicatorContainer = styled.div`
  display: flex;
`;
