import React from 'react';
import Theme from '../../../../utils/theme';

const UserCardIcon = ({ active = false }: { active: boolean }) => {
  return (
    <svg
      width="41"
      height="35"
      viewBox="0 0 41 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect
        x="1.17065"
        y="0.147644"
        width="39.8293"
        height="34"
        rx="8"
        fill={active ? Theme.colors.blue2[100] : Theme.colors.white[100]}
      />
      <ellipse
        cx="13.5"
        cy="12.3333"
        rx="4.5"
        ry="4.66667"
        fill={!active ? Theme.colors.blue3[40] : Theme.colors.white[100]}
      />
      <path
        d="M6.16484 22.5618C6.91508 19.7447 9.68566 18.1667 12.6009 18.1667H14.3991C17.3143 18.1667 20.0849 19.7447 20.8352 22.5618C21.0667 23.431 21.2523 24.3733 21.3324 25.3344C21.3782 25.8847 20.9273 26.3333 20.375 26.3333H6.625C6.07272 26.3333 5.6218 25.8847 5.66763 25.3344C5.74765 24.3733 5.93334 23.431 6.16484 22.5618Z"
        fill={!active ? Theme.colors.blue3[40] : Theme.colors.white[100]}
      />
      <path
        d="M24 12H37"
        stroke={!active ? Theme.colors.blue3[40] : Theme.colors.white[100]}
        strokeWidth="2"
      />
      <path
        d="M24 17H37"
        stroke={!active ? Theme.colors.blue3[40] : Theme.colors.white[100]}
        strokeWidth="2"
      />
    </svg>
  );
};

export default UserCardIcon;
