import { createReducer } from '@reduxjs/toolkit';
import { arrayToHash } from 'helpers/helperFunctions';
import { WeekContentful } from 'models/WeekContentful';
import { WeeksState } from 'modules/loading-screen/contentful/weeks/typings';
import { getWeeks } from './actions';

const initialState: WeeksState = {
  weeks: {},
};

export default createReducer<WeeksState>(initialState, (builder) =>
  builder.addCase(getWeeks.fulfilled, (state, action) => ({
    weeks: arrayToHash<WeekContentful>(
      action.payload,
      (week) => week.id,
      state.weeks as Record<string, WeekContentful>,
    ),
    lastUpdatedAt: new Date().toISOString(),
  })),
);
