import React from 'react';

import Medal from './medal';
import {
  ExperienceBp,
  ExperienceMedal,
  ExperienceSeparator,
  ExperienceSummaryContainer,
  ExperienceTotal,
  ExperienceXp,
} from 'modules/profile/components';

type ExperienceSummaryProps = {
  xp: number;
  bp: number;
};

const ExperienceSummary = ({ xp, bp }: ExperienceSummaryProps) => {
  return (
    <ExperienceSummaryContainer>
      <ExperienceMedal>
        <Medal />
      </ExperienceMedal>
      <ExperienceTotal>{xp + bp}</ExperienceTotal>
      <ExperienceSeparator />
      <ExperienceBp>{`${bp} BP`}</ExperienceBp>
      <ExperienceXp>{`${xp} XP`}</ExperienceXp>
    </ExperienceSummaryContainer>
  );
};

export default ExperienceSummary;
