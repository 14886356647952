import React from 'react';

import { ErrorMessage } from 'components/contentful-renders/components';

interface MissingContentfulRenderProps {
  componentType: string;
}

const MissingContentfulRender = ({
  componentType,
}: MissingContentfulRenderProps) => {
  return (
    <ErrorMessage>
      Cannot render {componentType} component, please contact NGA
    </ErrorMessage>
  );
};

export default MissingContentfulRender;
