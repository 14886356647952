import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'redux/typings';

const selectQuestsOffboardingsState = (state: RootState) =>
  state.contentful.questsOffboardings;

export const selectQuestsOffboardings = createSelector(
  selectQuestsOffboardingsState,
  (questsOffboardingsState) => questsOffboardingsState.questsOffboardings,
);

export const selectQuestsOffboardingsLastUpdatedAt = createSelector(
  selectQuestsOffboardingsState,
  (questsOffboardingsState) => questsOffboardingsState.lastUpdatedAt,
);
