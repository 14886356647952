import styled from '@emotion/styled';
import { Tabs, Tab as TabMaterial, withStyles, Fab } from '@material-ui/core';

import ButtonPrimary from 'components/button-primary';
import { ScrollableArea } from 'components/components';
import theme from 'utils/theme';

export const TabBar = withStyles({
  root: {
    backgroundColor: theme.colors.blue3[100],
    height: '3.5rem',
  },
  indicator: {
    backgroundColor: theme.colors.blue2[100],
  },
})(Tabs);

export const Tab = withStyles({
  root: {
    color: theme.colors.white[25],
    textTransform: 'none',
    fontFamily: theme.typography.normal,
    fontSize: theme.typography.large20,
  },
  selected: {
    color: theme.colors.blue2[100],
    fontWeight: theme.typography.bold,
  },
})(TabMaterial);

type TabPanelProps = {
  value: number;
  index: number;
};

export const TabPanel = styled.div<TabPanelProps>`
  background-color: ${(props) => props?.theme?.colors.blue3[100]};
  display: ${(props) => (props.value !== props.index ? 'none' : 'flex')};
  height: calc(100vh);
  @media (max-width: ${(props) => props?.theme?.breakpoints?.desktop}px) {
    height: 80rem;}
`;

export const DailyTabContainer = styled.div`
  width: 100%;
  padding: ${(props) => props?.theme?.typography.large32};
`;

export const ContentPanel = styled(ScrollableArea)`
  height: 100%;
  border-radius: 0.6rem;
  background-color: rgba(26, 26, 26, 0.8);
  padding: 1.8rem;
  -ms-overflow-style: none;
  scrollbar-color: transparent transparent;
  ::-moz-scrollbar {
    display: none;
  }
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const EditScheduleButton = styled(ButtonPrimary)`
  width: 7rem;
  align-self: flex-end;
`;

export const CalendarAndEditContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CalendarContainer = styled.div`
  margin-bottom: 1rem;

  .react-calendar {
    width: 100%;
    font-size: ${(props) => props?.theme?.typography.large18};
    line-height: ${(props) => props?.theme?.typography.large24};
  }
  .react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
  }
  .react-calendar__navigation {
    height: 2.4rem;
    margin-bottom: 1rem;
  }
  .react-calendar__navigation button {
    min-width: 2.4rem;
    background: none;
    color: white;
  }
  .react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    color: white;
    margin-bottom: 0.5rem;
  }
  .react-calendar__month-view__weekdays__weekday abbr {
    text-decoration: none;
    cursor: auto;
  }
  .react-calendar__month-view__days__day--neighboringMonth {
    color: #757575 !important;
  }
  .react-calendar__year-view .react-calendar__tile,
  .react-calendar__decade-view .react-calendar__tile,
  .react-calendar__century-view .react-calendar__tile {
    padding: 1.36rem 0.5rem;
  }
  .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    // padding: 0
    .8em 0 0.8rem 0;
    background: none;
    color: white;
  }
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  .react-calendar__tile:active {
    animation: fade-in 0.8s;
  }
  .react-calendar__tile abbr {
    display: none;
  }
`;

export const CalendarPanel = styled(ContentPanel)`
  width: 100%;
  max-width: 40rem;
  margin: 0 1.6rem 0 0;
  @media (max-width: ${(props) => props?.theme?.breakpoints?.desktop}px) {
    width: 100%;
    max-width: 100%;
    margin: 1.6rem 0 0 0;
  }
`;

export const MyScheduleContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  @media (max-width: ${(props) => props?.theme?.breakpoints?.desktop}px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const AgendaPanel = styled(ContentPanel)`
  width: 100%;
  max-width: 50rem;
  margin: 0 0 0 1.6rem;
  @media (max-width: ${(props) => props?.theme?.breakpoints?.desktop}px) {
    width: 100%;
    max-width: 100%;
    margin: 1.6rem 0 0 0;
  }
`;

export const UnavailableContentText = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  color: ${(props) => props?.theme?.colors.white[100]};
  font-size: ${(props) => props?.theme?.typography.large20};
  line-height: ${(props) => props?.theme?.typography.large24};
`;

export const EditScheduleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const EditSchedulePrompt = styled.div`
  font-size: ${(props) => props?.theme?.typography.large16};
  margin-bottom: 0.5rem;
  align-self: center;
`;

export const EditScheduleDescription = styled.div`
  font-size: ${(props) => props?.theme?.typography.large13};
  line-height: ${(props) => props?.theme?.typography.large18};
  color: ${(props) => props?.theme?.colors.grey[100]};
  margin-bottom: 1rem;
  align-self: center;
`;

export const EditScheduleSelectors = styled.div`
  width: 19rem;
  max-width: 100%;
  display: flex;
  flex-direction: column;
`;

export const EditScheduleSelectedHours = styled.div`
  font-size: ${(props) => props?.theme?.typography.large16};
`;

export const TileContainer = styled.div`
  width: ${(props) => props?.theme?.typography.large41};
  height: ${(props) => props?.theme?.typography.large41};
  padding: ${(props) => props?.theme?.typography.large10};
  box-sizing: content-box;
`;

export const IconsContainer = styled.div`
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const WarningIconContainer = styled.div`
  background-color: ${(props) => props?.theme?.colors.blue3[100]};
  position: absolute;
  top: -0.5rem;
  right: -0.3rem;
  border-radius: 100%;
  width: 1.1rem;
  height: 1.1rem;
`;

export type AgendaItemContainerProps = {
  imageUrl: string;
  completed: boolean;
  isLate?: boolean;
};

export const StepCardContainer = styled.div<AgendaItemContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;
  flex: 0 0 10rem;
  border-radius: 0.6rem;
  background-image: linear-gradient(
      180deg,
      #1a1a1a 0%,
      rgba(26, 26, 26, 0) 51.45%,
      #1a1a1a 100%
    ),
    url(${(props) => props.imageUrl});
  background-size: cover;
  margin-bottom: 1rem;
  cursor: pointer;
  border: 2px solid
    ${(props) =>
      props.completed
        ? props?.theme?.colors.gold[100]
        : props.isLate
        ? props?.theme?.colors.red[80]
        : props?.theme?.colors.white[100]};
`;

export const EmptyStepCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(26, 26, 26, 0.8);
  color: ${(props) => props?.theme?.colors.white[100]};
  font-size: ${(props) => props?.theme?.typography.large20};
  line-height: ${(props) => props?.theme?.typography.large20};
  padding: 1rem;
  flex: 0 0 10rem;
  border-radius: 0.6rem;
  margin-bottom: 1rem;
`;

export const StepCardStatusContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const StepCardStatus = styled.div`
  color: ${(props) => props?.theme?.colors.white[25]};
  font-size: ${(props) => props?.theme?.typography.large32};
  margin-right: ${(props) => props?.theme?.typography.large32};
`;

export const AStepCardInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const StepCardTitlesContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StepCardTitle = styled.div`
  color: ${(props) => props?.theme?.colors.gold[100]};
  font-size: ${(props) => props?.theme?.typography.large32};
  font-weight: 700;
`;

export const StepCardStepTitle = styled.div`
  color: ${(props) => props?.theme?.colors.white[100]};
  font-size: ${(props) => props?.theme?.typography.large24};
`;

export const AgendaContainer = styled(ScrollableArea)`
  height: 100%;
  padding-right: 0.5rem;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-color: transparent transparent;
  ::-moz-scrollbar {
    display: none;
  }
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const AgendaItemContainer = styled.div`
  display: flex;
`;

export const AgendaDay = styled.div`
  color: ${(props) => props?.theme?.colors.white[100]};
  font-size: ${(props) => props?.theme?.typography.large32};
  margin: 3.5rem 1rem 0 0;
`;

export const StepsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const AllTabContainer = styled(ScrollableArea)`
  height: 100%;
  width: 100%;
  padding: 0 ${(props) => props?.theme?.typography.large32};
  -ms-overflow-style: none;
  scrollbar-color: transparent transparent;
  ::-moz-scrollbar {
    display: none;
  }
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const SummaryPadding = styled.div`
  height: ${(props) => props?.theme?.typography.large32};
`;

export const QuestSummaryTitle = styled.div`
  color: ${(props) => props?.theme?.colors.blue2[100]};
  font-size: ${(props) => props?.theme?.typography.large24};
  line-height: ${(props) => props?.theme?.typography.large24};
`;

export const WeekSummaryTitle = styled.div`
  color: ${(props) => props?.theme?.colors.white[100]};
  font-size: ${(props) => props?.theme?.typography.large24};
`;

export const WeekItemDetail = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
`;

export const WeekItemStatusContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const WeekItemStatusIconContainer = styled.div`
  display: flex;
  align-items: center;
  padding-left: ${(props) => props?.theme?.typography.large16};
`;

type WeekItemTextProps = {
  isLink?: boolean;
};

export const WeekItemText = styled.div<WeekItemTextProps>`
  color: ${(props) => props?.theme?.colors.grey[100]};
  font-size: ${(props) => props?.theme?.typography.large20};
  line-height: ${(props) => props?.theme?.typography.large24};
  cursor: ${(props) => (props.isLink ? 'pointer' : 'auto')};
  color: ${(props) =>
    props.isLink
      ? props?.theme?.colors.blue2[70]
      : props?.theme?.colors.white[100]};
`;

export const ProgressBarContainer = styled.div`
  position: relative;
  width: 100%;
`;

export type WeeklyOverviewItemContainerProps = {
  imageUrl: string;
  completed: boolean;
  borderColor: string;
};

export const WeeklyOverviewItemContainer = styled.div<WeeklyOverviewItemContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;
  margin-right: 1rem;
  flex: 0 0 10rem;
  border-radius: 0.6rem;
  background-image: linear-gradient(
      180deg,
      #1a1a1a 0%,
      rgba(26, 26, 26, 0) 51.45%,
      #1a1a1a 100%
    ),
    url(${(props) => props.imageUrl});
  background-size: cover;
  margin-bottom: 1rem;
  cursor: pointer;
  border: 2px solid ${(props) => props.borderColor};
`;

type ProgressBarProps = {
  percentage: number;
};

export const ProgressBarFull = styled.div<ProgressBarProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: ${(props) => props.percentage}%;
  height: 10px;
  border-radius: 10px;
  background-color: ${(props) => props?.theme?.colors.gold[100]};
`;

export const ProgressBar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 10px;
  border: 2px solid;
  border-radius: 10px;
  border-color: ${(props) => props?.theme?.colors.white[25]}; ;
`;

export const WeeklyOverviewStatusContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const WeeklyOverViewCompletedChallenges = styled.text`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1rem;
`;

export const WeeklyTabTitleContainer = styled.div`
  position: sticky;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid grey;
  margin-bottom: 20px;
`;

export const WeeklyTabTitle = styled.div`
  color: ${(props) => props?.theme?.colors.white[25]};
  padding-left: 20px;
  font-size: 1.5rem;
`;

export const WeeklyTabSectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  color: ${(props) => props?.theme?.colors.white[100]};
  padding-left: 20px;
`;

export const WeeklyTabWeekContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 20px;
`;

export const WeeklyTabDateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const WeeklyTabMonth = styled.div`
  font-size: 1.5rem;
`;

export const WeeklyTabDay = styled.div`
  color: ${(props) => props?.theme?.colors.white[25]};
`;

export const WeeklyTabContainer = styled.div`
  background-color: ${(props) => props?.theme?.colors.blue3[100]};
`;

export const TabContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const WeeksProgressContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 1.5rem;
`;

export const WeeksContainer = styled.div`
  display: flex;
  flexdirection: column;
`;

export const WeeklyChallengesContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const WeeklyEmptyChallengeCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(26, 26, 26, 0.8);
  color: ${(props) => props?.theme?.colors.white[100]};
  font-size: ${(props) => props?.theme?.typography.large20};
  line-height: ${(props) => props?.theme?.typography.large20};
  padding: 1rem;
  flex: 0 0 10rem;
  border-radius: 0.6rem;
  margin-bottom: 1rem;
`;

export const WeekChallengeCardInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ChallengeCardTitlesContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: ${(props) => props?.theme?.colors.gold[100]};
  font-size: ${(props) => props?.theme?.typography.large24};
`;

export const ChallengeCardIconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

export const ChallengeIconsRow = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const PointsContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const TextXp = styled.div`
  color: ${(props) => props?.theme?.colors.white[100]};
  font-size: ${(props) => props?.theme?.typography.large24};
  margin-left: 0.5rem;
`;

export const WeeklyDateArrowContainer = styled.div`
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`;

export const WarningContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const WarningText = styled.text`
  margin-left: 0.5rem;
  color: ${(props) => props?.theme?.colors.red[70]};
`;

export const WeeklyTabInnerContainer = styled.div`
  width: 100%;
`;

export const FilterButton = withStyles({
  root: {
    position: 'fixed',
    bottom: theme.typography.large24,
    right: theme.typography.large24,
  },
  primary: {
    backgroundColor: theme.colors.blue2[100],
  },
})(Fab);
