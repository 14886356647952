import * as React from 'react';

type ComputerIconProps = {
  width: number | string;
  height: number | string;
  color?: string;
};

const ComputerIcon = ({
  width,
  height,
  color = '#FFFF',
}: ComputerIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill={color}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21 16H3V4H21V16ZM21 2H3C1.89 2 1 2.89 1 4V16C1 16.5304 1.21071 17.0391 1.58579 17.4142C1.96086 17.7893 2.46957 18 3 18H10V20H8V22H16V20H14V18H21C21.5304 18 22.0391 17.7893 22.4142 17.4142C22.7893 17.0391 23 16.5304 23 16V4C23 3.46957 22.7893 2.96086 22.4142 2.58579C22.0391 2.21071 21.5304 2 21 2Z"
        fill={color}
      />
    </svg>
  );
};

export default ComputerIcon;
