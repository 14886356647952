import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { IFileInformation } from 'models/contentful';
import Branch from './Branch';
import {
  selectChallengeAppletByContentfulId,
  selectSelectedStepIsCelebrate,
  selectSelectedStepIsCompleted,
} from 'modules/loading-screen/contents/selectors';

import DecisionTreePreview from './DecisionTreePreview';
import { selectAppletsContentful } from 'modules/loading-screen/contentful/applets/selectors';
import useExistingApplet from 'hooks/useExistingApplet';

type DecisionTreeAppletProps = {
  appletId: string;
  branches?: IFileInformation[];
};

const DecisionTreeApplet = ({
  appletId,
  branches,
}: DecisionTreeAppletProps) => {
  const appletsContentful = useSelector(selectAppletsContentful);
  const challengeApplet = useSelector(
    selectChallengeAppletByContentfulId(appletId),
  );

  const isCelebrate = useSelector(selectSelectedStepIsCelebrate);
  const isStepCompleted = useSelector(selectSelectedStepIsCompleted);

  const id = useExistingApplet(appletId);

  const appletBranches = useMemo(
    () => branches ?? appletsContentful[appletId].decisionTreeBranches,
    [appletId, appletsContentful, branches],
  );

  return (
    <>
      {isCelebrate || isStepCompleted ? (
        <DecisionTreePreview files={challengeApplet?.files} />
      ) : (
        appletBranches?.map((branch) => (
          <Branch
            id={id}
            key={branch.sys.id}
            appletId={appletId}
            fileId={branch.sys.id}
            prompt={branch.fields.prompt}
            placeholder={branch.fields.placeholder}
            type={branch.fields.fileType}
            fileOrder={branch.fields.order}
          />
        ))
      )}
    </>
  );
};

export default DecisionTreeApplet;
