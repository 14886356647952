import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'redux/typings';

import { getActiveQuest, setLoadingFirstFinished, setLoadingScheduleCommitFinished } from 'modules/loading-screen/quests/actions';
import { selectWeek } from 'modules/loading-screen/weeks/actions';
import {
  getAppOnboardings,
  getAppOnboardingSeen,
  getQuestOffboardingSeen,
  getQuestOnboardingSeen,
  getWeekOnboardingSeen,
} from 'modules/onboarding/actions';
import {
  selectActiveQuest,
  selectActiveQuestWeekIds,
  selectLoadingFirstFinished,
} from 'modules/loading-screen/quests/selectors';
import {
  selectAppOnboardingSeen,
  selectAppOnboardingsList,
  selectQuestOnboardingSeen,
} from 'modules/onboarding/selectors';

import useActionStatus from '../useActionStatus';
import { Onboarding } from 'models/Onboarding';
import { selectLoadingScreenErrors } from 'modules/error/selectors';
import { useHistory } from 'react-router';
import { GENERIC_ERROR_SCREEN } from 'routes';
import { getScheduleCommit } from 'modules/loading-screen/schedule-commit/actions';
import { getSchedules } from 'modules/loading-screen/schedules/actions';
import { selectHasAlreadyAScheduleCommit } from 'modules/loading-screen/schedule-commit/selectors';
import useLoadContentfulFirstContent from 'hooks/useLoadContentfulFirstContent';

type useLoadContentReturn = {
  loadFinished: boolean;
  appOnboardingSeen: boolean;
  appOnboardings: Onboarding[];
  questOnboardingSeen: boolean;
  hasScheduleCommit: boolean;
};

const useLoadFirstContent = (): useLoadContentReturn => {
  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();

  const [hasLoadedFirstContent] = useLoadContentfulFirstContent();
  const activeQuest = useSelector(selectActiveQuest);
  const activeQuestWeekIds = useSelector(selectActiveQuestWeekIds);
  const appOnboardingSeen = useSelector(selectAppOnboardingSeen);
  const appOnboardings = useSelector(selectAppOnboardingsList);
  const questOnboardingSeen = useSelector(selectQuestOnboardingSeen(activeQuest?.id));
  const hasScheduleCommit = useSelector(selectHasAlreadyAScheduleCommit);

  const loadingFirstFinished = useSelector(selectLoadingFirstFinished);
  const loadingScreenErrors = useSelector(selectLoadingScreenErrors);

  const [, getAppOnboardingsFulfilled] = useActionStatus(getAppOnboardings);
  const [, getAppOnboardingSeenFulfilled] = useActionStatus(getAppOnboardingSeen);
  const [, questOnboardingSeenWasFulfilled] = useActionStatus(getQuestOnboardingSeen);
  const [, questOffboardingSeenWasFulfilled] = useActionStatus(getQuestOffboardingSeen);
  const [, getWeekOnboardingSeenFulfilled] = useActionStatus(getWeekOnboardingSeen);
  const [, getSchedulesWasFulfilled] = useActionStatus(getSchedules);
  const [, scheduleCommitWasFulfilled] = useActionStatus(getScheduleCommit);

  const [, getScheduleCommitWasFulfilled] = useActionStatus(getScheduleCommit);

  const loadingScheduleCommitFinished = useMemo(() => {
    let loadFinished = getScheduleCommitWasFulfilled;

    if (loadFinished) {
      dispatch(setLoadingScheduleCommitFinished(loadFinished));
    }
    return loadFinished;
  }, [
    dispatch,
    getScheduleCommitWasFulfilled,
  ]);

  const loadFinished = useMemo(() => {
    const loadFinished =
      getAppOnboardingsFulfilled &&
      getAppOnboardingSeenFulfilled &&
      questOnboardingSeenWasFulfilled &&
      questOffboardingSeenWasFulfilled &&
      loadingScheduleCommitFinished &&
      getWeekOnboardingSeenFulfilled &&
      (!hasScheduleCommit || getSchedulesWasFulfilled) &&
      scheduleCommitWasFulfilled &&
      hasLoadedFirstContent

    if (loadFinished) {
      dispatch(setLoadingFirstFinished(loadFinished));
    }
    return loadFinished;
  }, [
    getAppOnboardingsFulfilled,
    getAppOnboardingSeenFulfilled,
    questOnboardingSeenWasFulfilled,
    questOffboardingSeenWasFulfilled,
    loadingScheduleCommitFinished,
    getWeekOnboardingSeenFulfilled,
    hasScheduleCommit,
    getSchedulesWasFulfilled,
    scheduleCommitWasFulfilled,
    hasLoadedFirstContent,
    dispatch,
  ]);

  useEffect(() => {
    if (!loadingFirstFinished) {
      dispatch(getAppOnboardings());
      dispatch(getAppOnboardingSeen());
      dispatch(getActiveQuest());
    }
  }, [dispatch, loadingFirstFinished]);

  useEffect(() => {
    if (!loadingFirstFinished && activeQuest) {
      const { id: questId } = activeQuest;
      dispatch(getQuestOnboardingSeen({ questId }));
      dispatch(getQuestOffboardingSeen({ questId }));

      const lastWeekId = activeQuestWeekIds[activeQuestWeekIds.length - 1];
      dispatch(selectWeek(lastWeekId));
      for (const weekId of activeQuestWeekIds) {
        dispatch(getWeekOnboardingSeen({ weekId }));
      }
    }
  }, [activeQuest, dispatch, loadingFirstFinished, activeQuestWeekIds]);

  useEffect(() => {
    if (activeQuest?.id) {
      if (!getScheduleCommitWasFulfilled) {
        dispatch(getScheduleCommit({ questId: activeQuest?.id }));
      } else {
        if (hasScheduleCommit) {
          dispatch(getSchedules({ questId: activeQuest?.id }));
        }
      }
    }

    return () => { }
  }, [activeQuest, dispatch, hasScheduleCommit, getScheduleCommitWasFulfilled])

  useEffect(() => {
    if (loadingScreenErrors && loadingScreenErrors.length > 0) {
      history.push(GENERIC_ERROR_SCREEN);
    }
  }, [loadingScreenErrors, history]);

  return {
    loadFinished,
    appOnboardingSeen,
    appOnboardings,
    questOnboardingSeen,
    hasScheduleCommit
  };
};

export default useLoadFirstContent;
