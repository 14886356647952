import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import OnboardingCarousel from 'modules/onboarding/onboarding-carousel';
import { HOME, LOADING_SCREEN } from 'routes';
import {
  selectActiveQuest,
  selectActiveQuestOffboardings,
} from 'modules/loading-screen/quests/selectors';
import { OnboardingContainer } from '../components';
import { AppDispatch } from 'redux/typings';
import { notifyQuestOffboardingSeen } from '../actions';
import localizedStrings from 'localization';

const QuestOffboarding = () => {
  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();

  const questOffboardings = useSelector(selectActiveQuestOffboardings);
  const activeQuest = useSelector(selectActiveQuest);

  const handleGetStarted = useCallback(() => {
    if (activeQuest) {
      dispatch(notifyQuestOffboardingSeen({ questId: activeQuest.id }));
    }

    history.push(HOME);
  }, [dispatch, history, activeQuest]);

  useEffect(() => {
    if (!questOffboardings) {
      history.push(LOADING_SCREEN);
    }
  }, [questOffboardings, history]);

  return (
    <OnboardingContainer>
      {questOffboardings && questOffboardings.length > 0 ? (
        <OnboardingCarousel
          onboardings={questOffboardings}
          handleGetStarted={handleGetStarted}
          buttonText={localizedStrings.wellDone}
        />
      ) : (
        <p>Loading...</p>
      )}
    </OnboardingContainer>
  );
};

export default QuestOffboarding;
