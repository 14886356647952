import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectHasCompletedWeek } from 'modules/loading-screen/weeks/selectors';
import {
  selectActiveQuestId,
  selectHasAddedQuest,
} from 'modules/loading-screen/quests/selectors';
import { getActiveQuest } from 'modules/loading-screen/quests/actions';
import { getSchedules } from 'modules/loading-screen/schedules/actions';
import LoadingSpinner from 'components/loading-spinner';
import useActionStatus from 'hooks/useActionStatus';
import { SyncInformationContainer } from './components';
import { selectIsComingFromLoadingScreen } from 'modules/loading-screen/navigation-information/selectors';
import { setIsComingfromLoadingScreen } from 'modules/loading-screen/navigation-information/actions';

const SyncAllContent = () => {
  const dispatch = useDispatch();

  const [hasUpdatedContent, setHasUpdatedContent] = useState<
    boolean | undefined
  >(undefined);

  const hasAddedQuest = useSelector(selectHasAddedQuest);
  const hasCompletedWeek = useSelector(selectHasCompletedWeek);
  const questId = useSelector(selectActiveQuestId);
  const isComingFromLoadingScreen = useSelector(
    selectIsComingFromLoadingScreen,
  );
  const [isPendingQuest] = useActionStatus(getActiveQuest);
  const [isPendingSchedule] = useActionStatus(getSchedules);

  useEffect(() => {
    if (hasUpdatedContent === undefined) {
      if (isComingFromLoadingScreen) {
        setHasUpdatedContent(true);
        dispatch(setIsComingfromLoadingScreen(false));
      } else {
        setHasUpdatedContent(false);
      }
    }
  }, [isComingFromLoadingScreen, hasUpdatedContent, dispatch]);

  useEffect(() => {
    if (hasUpdatedContent === false && !hasCompletedWeek && !hasAddedQuest) {
      setHasUpdatedContent(true);
      dispatch(getActiveQuest());
      if (questId) {
        dispatch(getSchedules({ questId }));
      }
    }
  }, [dispatch, hasAddedQuest, hasCompletedWeek, hasUpdatedContent, questId]);

  return (
    <SyncInformationContainer visible={isPendingQuest || isPendingSchedule}>
      <LoadingSpinner size={20} />
      {' '}Syncing Content
    </SyncInformationContainer>
  );
};

export default SyncAllContent;
