import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { AppDispatch } from 'redux/typings';
import localizedStrings from 'localization';
import useActionStatus from 'hooks/useActionStatus';
import { ONBOARDING } from 'routes';
import { ONBOARDING_QUEST } from 'modules/onboarding/routes';
import {
  getActiveQuest,
  resetHasAddedQuest,
} from 'modules/loading-screen/quests/actions';
import { selectWeek } from 'modules/loading-screen/weeks/actions';
import {
  selectActiveQuest,
  selectActiveQuestWeekIds,
  selectHasAddedQuest,
} from 'modules/loading-screen/quests/selectors';
import { ModalContainer } from 'components/modal/components';
import LoadingSpinner from 'components/loading-spinner';
import { LoadingWeekText } from './components';
import { resetScheduleCommit } from 'modules/loading-screen/schedule-commit/actions';
import { selectUserCohortExist } from 'modules/settings/selectors';

const LoadingNewQuest = () => {
  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();
  const [hasRequestedQuest, setHasRequestedQuest] = useState(false);
  const activeQuest = useSelector(selectActiveQuest);
  const hasAddedQuest = useSelector(selectHasAddedQuest);
  const activeQuestWeekIds = useSelector(selectActiveQuestWeekIds);
  const existUserInCohort = useSelector(selectUserCohortExist);

  const [, hasRetrievedQuest] = useActionStatus(getActiveQuest);

  const lastWeekId = useMemo(
    () => activeQuestWeekIds[activeQuestWeekIds.length - 1],
    [activeQuestWeekIds],
  );

  useEffect(() => {
    if (hasAddedQuest) {
      dispatch(getActiveQuest());
      setHasRequestedQuest(true);
      dispatch(resetHasAddedQuest());
    }
  }, [dispatch, hasAddedQuest, existUserInCohort]);

  useEffect(() => {
    if (
      hasRequestedQuest &&
      hasRetrievedQuest &&
      activeQuest &&
      !existUserInCohort?.exist
    ) {
      setHasRequestedQuest(false);
      dispatch(resetScheduleCommit());      
      dispatch(selectWeek(lastWeekId));
      history.push(ONBOARDING + ONBOARDING_QUEST);
    } else if (existUserInCohort?.exist) {
      window.location.reload();
    }
  }, [
    activeQuest,
    activeQuestWeekIds,
    dispatch,
    hasRequestedQuest,
    hasRetrievedQuest,
    history,
    lastWeekId,
    existUserInCohort,
  ]);

  return (
    <ModalContainer visible={hasAddedQuest || hasRequestedQuest}>
      <LoadingWeekText>{localizedStrings.loadingNewQuest}</LoadingWeekText>
      <LoadingSpinner size={48} />
    </ModalContainer>
  );
};

export default LoadingNewQuest;
