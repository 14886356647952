import React, { useMemo } from 'react';

type DashedLineProps = {
  width?: number | string;
  height?: number | string;
  numberOfDashes?: number;
  vertical?: boolean;
};

const DashedLine = ({
  width,
  height,
  numberOfDashes = 10,
  vertical,
}: DashedLineProps) => {
  const [viewBox1, viewBox2, x1, x2, y1, y2] = useMemo(() => {
    const length = numberOfDashes * 20;
    const viewBox1 = vertical ? 4 : length;
    const viewBox2 = vertical ? length : 4;
    const x1 = vertical ? 2 : 0;
    const x2 = vertical ? 2 : length;
    const y1 = vertical ? 0 : 2;
    const y2 = vertical ? length : 2;

    return [viewBox1, viewBox2, x1, x2, y1, y2];
  }, [numberOfDashes, vertical]);

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${viewBox1} ${viewBox2}`}
      fill="none"
      style={{
        borderRadius: 4,
        height,
        transition: 'height 0.5s cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      }}
      xmlns="http://www.w3.org/2000/svg">
      <line
        x1={x1}
        x2={x2}
        y1={y1}
        y2={y2}
        stroke="white"
        strokeOpacity="0.25"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="12 8"
        strokeDashoffset="6"
      />
    </svg>
  );
};

export default DashedLine;
