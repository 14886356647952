import { baseService } from 'modules/baseService';
import HttpClient from 'services/HttpClient';
import { QuestPortfolio } from 'models/QuestPortfolio';
import {
  SubmitGenericApplet,
  ShareAppletEmailPayload,
  ShareStepEmailPayload,
  APPLETS_GET_CURRENT_CONTENT,
} from './typings';
import { Applet } from 'models/Applet';

const baseUrl = 'applets';

export const getQuestApplets = baseService<string | undefined, QuestPortfolio[]>((currentContent?: string) =>
  HttpClient.get(`${baseUrl}?currentContent=${currentContent || APPLETS_GET_CURRENT_CONTENT.YES}`),
);

export const uploadGenericApplet = baseService<SubmitGenericApplet, Applet>(
  (applet) => HttpClient.post(baseUrl, applet),
);

export const shareApplet = baseService<ShareAppletEmailPayload, void>((data) =>
  HttpClient.post(`${baseUrl}/share-applet`, data),
);

export const shareStep = baseService<ShareStepEmailPayload, void>((data) =>
  HttpClient.post('share-step', data),
);
