import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import {
  getAchievements as getAchievementsService,
  getPublishedShowcases as getPublishedShowcasesService,
  shareShowcase as shareShowcaseService,
  publishShowcase as publishShowcaseService,
  getGenericApplets as getGenericAppletsService,
  deleteShowcase as deleteShowcaseService,
} from './services';
import { ShowcaseItem } from './typings';

const GET_ACHIEVEMENTS = 'GET_ACHIEVEMENTS';
export const getAchievements = createAsyncThunk(
  GET_ACHIEVEMENTS,
  getAchievementsService,
);

const GET_PUBLISHED_SHOWCASES = 'GET_PUBLISHED_SHOWCASES';
export const getPublishedShowcases = createAsyncThunk<ShowcaseItem[]>(
  GET_PUBLISHED_SHOWCASES,
  getPublishedShowcasesService,
);

const PUBLISH_SHOWCASE = 'PUBLISH_SHOWCASE';
export const publishShowcase = createAsyncThunk(
  PUBLISH_SHOWCASE,
  publishShowcaseService,
);

const SHARE_SHOWCASE = 'SHARE_SHOWCASE';
export const shareShowcase = createAsyncThunk(
  SHARE_SHOWCASE,
  shareShowcaseService,
);

const GET_GENERIC_APPLETS = 'GET_GENERIC_APPLETS';
export const getGenericApplets = createAsyncThunk(
  GET_GENERIC_APPLETS,
  getGenericAppletsService,
);

const DELETE_SHOWCASE = 'DELETE_SHOWCASE';
export const deleteShowcase = createAsyncThunk(
  DELETE_SHOWCASE,
  deleteShowcaseService,
);

const UPDATE_PROFILE_MENU_ITEM = 'UPDATE_PROFILE_MENU_ITEM';
export const updateProfileMenuSelected = createAction<
  'ManageQuests' | 'AchievementsList' | 'NotebookGrid' | 'ShowcasesList'
>(UPDATE_PROFILE_MENU_ITEM);

const UPDATE_SORT_BY_QUEST = 'UPDATE_SORT_BY_QUEST';
export const updateSortByQuest = createAction<boolean>(UPDATE_SORT_BY_QUEST);
