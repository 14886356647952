import { PropsWithChildren, ReactNode, useEffect, useRef } from 'react';
import {
  CloseModalButton,
  ModalContainer,
  ModalPanel,
  ModalContent,
  ModalTitle,
  ModalTitleIcon,
  AcceptModalButton,
  ModalDescript,
} from './components';
import closeIcon from 'assets/icons/Close.webp';

type ModalProps = {
  open: boolean;
  closeModal?: () => void;
  title?: string;
  titleIcon?: ReactNode;
  acceptButtonCustom?: JSX.Element;
  acceptButtonText?: string;
  onAcceptButtonClick?: () => void;
  acceptButtonisLoading?: boolean;
  acceptButtonDisabled?: boolean;
  width?: string;
  height?: string;
  hideCloseButton?: boolean;
};

const Modal = ({
  open,
  closeModal = () => { },
  title,
  titleIcon,
  acceptButtonCustom = undefined,
  acceptButtonText,
  onAcceptButtonClick = () => { },
  acceptButtonisLoading = false,
  acceptButtonDisabled = false,
  width = '1000px',
  height = 'auto',
  hideCloseButton = false,
  children,
}: PropsWithChildren<ModalProps>) => {
  const modalContentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!open) {
      modalContentRef.current?.scrollTo(0, 0);
    }
  }, [open]);

  return (
    <ModalContainer visible={open}>
      <ModalPanel width={width} height={height}>
        {!hideCloseButton && (
          <CloseModalButton onClick={closeModal}>
            <img src={closeIcon} alt="close" width={16} height={16} />
          </CloseModalButton>
        )}
        {title && (
          <ModalTitle>
            {titleIcon && <ModalTitleIcon>{titleIcon}</ModalTitleIcon>}
            <ModalDescript>{title}</ModalDescript>
          </ModalTitle>
        )}
        <ModalContent ref={modalContentRef}>{children}</ModalContent>
        {acceptButtonCustom}
        {acceptButtonText && (
          <AcceptModalButton
            onClick={onAcceptButtonClick}
            isLoading={acceptButtonisLoading}
            disabled={acceptButtonDisabled}>
            {acceptButtonText}
          </AcceptModalButton>
        )}
      </ModalPanel>
    </ModalContainer>
  );
};

export default Modal;
