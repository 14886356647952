import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
  GENERIC_ERROR_SCREEN,
  HOME,
  ONBOARDING,
  SCHEDULE_COMMIT,
} from 'routes';
import { ONBOARDING_APP, ONBOARDING_QUEST } from 'modules/onboarding/routes';

import logo from 'assets/images/Logo.webp';
import localizedStrings from 'localization';

import { setIsComingfromLoadingScreen } from './navigation-information/actions';
import useToastErrorMessage from 'hooks/useToastErrorMessage';
import {
  selectBlockingError,
  selectLoadingScreenErrors,
} from 'modules/error/selectors';
import { BgContainer, TxtMessage } from 'common/CommonComponents';
import { selectUserCohortExist } from 'modules/settings/selectors';
import { LoadingSquare } from 'components/loading-square/LoadingSquare';
import useLoadFirstContent from 'hooks/use-load-content/useLoadFirstContent';

const LoadingScreen = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isBlockingError = useSelector(selectBlockingError);
  const loadingScreenErrors = useSelector(selectLoadingScreenErrors);
  const existUserInCohort = useSelector(selectUserCohortExist);

  const {
    loadFinished,
    appOnboardings,
    appOnboardingSeen,
    questOnboardingSeen,
    hasScheduleCommit,
  } = useLoadFirstContent();

  useToastErrorMessage();

  useEffect(() => {
    if (loadFinished) {
      if (!appOnboardingSeen && appOnboardings.length > 0) {
        history.push(ONBOARDING + ONBOARDING_APP);
      } else if (!questOnboardingSeen) {
        history.push(ONBOARDING + ONBOARDING_QUEST);
      } else if (!hasScheduleCommit && !existUserInCohort?.exist) {
        history.push(SCHEDULE_COMMIT);
      } else if (existUserInCohort?.exist) {
        window.location.reload();
      } else {
        dispatch(setIsComingfromLoadingScreen(true));
        history.push(HOME);
      }
    }
  }, [
    questOnboardingSeen,
    history,
    hasScheduleCommit,
    loadFinished,
    appOnboardingSeen,
    appOnboardings,
    dispatch,
    existUserInCohort,
  ]);

  useEffect(() => {
    if (
      (loadingScreenErrors && loadingScreenErrors.length > 0) ||
      isBlockingError
    ) {
      history.push(GENERIC_ERROR_SCREEN);
    }
  }, [loadingScreenErrors, isBlockingError, history]);

  useEffect(() => {


    return () => { }
  }, [])


  return (
    <BgContainer>
      <img src={logo} alt="brand logo" />
      <TxtMessage>{localizedStrings.loading}</TxtMessage>
      <LoadingSquare />
    </BgContainer>
  );
};

export default LoadingScreen;
