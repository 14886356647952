import { baseService } from 'modules/baseService';
import HttpClient from 'services/HttpClient';
import {
  Artifact,
  SetSelectedArtifactPayload,
  SubmitCompletedArtifactPayload,
} from './typings';

const baseUrl = '/playlists';

export const getPlaylist = baseService<
  { weekIds: string },
  Record<string, Record<string, Artifact>>
>(({ weekIds }) => HttpClient.get(`${baseUrl}?${weekIds}`));

export const submitCompletedArtifact = baseService<
  SubmitCompletedArtifactPayload,
  Record<string, Record<string, Artifact>>
>(({ id, weekId }) => HttpClient.post(`${baseUrl}/artifact`, { id, weekId }));

export const setArtifactSelected = baseService<
  SetSelectedArtifactPayload,
  void
>(({ idArtifactSelected }) =>
  HttpClient.post(`${baseUrl}/artifact-selected`, {
    idArtifactSelected,
  }),
);

export const deleteArtifactSelected = baseService<
  SetSelectedArtifactPayload,
  void
>(({ idArtifactSelected }) =>
  HttpClient.delete(`${baseUrl}/artifact-selected/${idArtifactSelected}`),
);

export const getPlaylistArtifactSelected = baseService<void, any>(() =>
  HttpClient.get(`${baseUrl}/artifact-selected`),
);
