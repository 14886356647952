import { useCallback, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isBefore, differenceInDays } from 'date-fns';

import { selectCohortStartingDate } from 'modules/settings/selectors';
import { UserLocalDate } from 'models/UserLocalDate';

const useCohortConfirmationDate = <ValuesType>(
  onFinalSubmit: (
    valuesToSubmit: ValuesType,
    userLocalDate: UserLocalDate,
  ) => void,
): [
  boolean,
  boolean,
  React.Dispatch<React.SetStateAction<boolean>>,
  React.Dispatch<React.SetStateAction<ValuesType | undefined>>,
  (answer: boolean) => () => void,
] => {
  const [dateDialogOpen, setDateDialogOpen] = useState(false);
  const [valuesToSubmit, setValuesToSubmit] = useState<ValuesType | undefined>(
    undefined,
  );
  const [hasInitiallySubmitted, setHasInitiallySubmitted] = useState(false);

  const cohortStartingDate = useSelector(selectCohortStartingDate);

  const handleDateDialog = useCallback(
    (answer: boolean) => () => {
      if (valuesToSubmit) {
        const localDate = new Date();
        const userLocalDate = {
          year: localDate.getFullYear(),
          month: localDate.getMonth(),
          day: localDate.getDate(),
          startOnSameWeek: answer,
        };

        onFinalSubmit(valuesToSubmit, userLocalDate);
      }
      setValuesToSubmit(undefined);
      setDateDialogOpen(false);
    },
    [valuesToSubmit, onFinalSubmit],
  );

  useEffect(() => {
    if (hasInitiallySubmitted && valuesToSubmit && cohortStartingDate) {
      const localDate = new Date();
      const cohortDate = new Date(
        cohortStartingDate.year,
        cohortStartingDate.month,
        cohortStartingDate.day,
      );
      const userLocalDate = {
        year: localDate.getFullYear(),
        month: localDate.getMonth(),
        day: localDate.getDate(),
      };
      if (isBefore(localDate, cohortDate)) {
        onFinalSubmit(valuesToSubmit, userLocalDate);
        setValuesToSubmit(undefined);
      } else {
        const dayDifference = differenceInDays(localDate, cohortDate);
        if (dayDifference > 0 && dayDifference < 4) {
          setDateDialogOpen(true);
        } else {
          onFinalSubmit(valuesToSubmit, userLocalDate);
          setValuesToSubmit(undefined);
        }
      }
      setHasInitiallySubmitted(false);
    }
  }, [
    hasInitiallySubmitted,
    valuesToSubmit,
    cohortStartingDate,
    onFinalSubmit,
  ]);

  return [
    dateDialogOpen,
    hasInitiallySubmitted,
    setHasInitiallySubmitted,
    setValuesToSubmit,
    handleDateDialog,
  ];
};

export default useCohortConfirmationDate;
