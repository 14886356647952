import React, { useMemo } from 'react';

import StarIcon from '../../../components/icons/StarIcon';
import ClockIcon from '../../../components/icons/ClockIcon';
import {
  ChallengeIconsRow,
  PointsContainer,
  TimeContainer,
  Text,
  ChallengeMobileContainer,
} from '../components';
import theme from 'utils/theme';
import useLocationIconText from '../../../hooks/useLocationIconText';

type CongratsNextUpInfoProps = {
  xp: number;
  time: number;
  location: string;
};

const CongratsNextUpInfo = ({
  xp = 0,
  time = 0,
  location,
}: CongratsNextUpInfoProps) => {
  const dimension = useMemo(() => theme.typography.large24, []);

  const [Icon, text] = useLocationIconText(location);

  return (
    <ChallengeIconsRow>
      <ChallengeMobileContainer>
        <Icon
          width={dimension}
          height={dimension}
          color={theme.colors.white[100]}
        />
        <Text>{text}</Text>
      </ChallengeMobileContainer>
      <PointsContainer>
        <StarIcon width={dimension} height={dimension} />
        <Text>{`${xp}pts`}</Text>
      </PointsContainer>
      <TimeContainer>
        <ClockIcon width={dimension} height={dimension} />
        <Text>{time} min</Text>
      </TimeContainer>
    </ChallengeIconsRow>
  );
};

export default CongratsNextUpInfo;
