import { createReducer } from '@reduxjs/toolkit';

import {
  uploadResponse,
  uploadAssetForRichText,
  cleanRichTextAsset,
  saveRichTextApplet,
  updateQuizQuestionValue,
  updateBranchFile,
  updateBranchDescription,
  resetModified,
  updateMadlibAnswer,
  resetState,
} from './actions';
import { AppletUploadState } from './typings';

import { updateQuizApplet } from 'helpers/AppletHelpers';
import {
  completeStep,
  selectStep,
  submitPost,
} from 'modules/loading-screen/contents/actions';

const initialState: AppletUploadState = {
  list: {},
  modified: false,
};

export default createReducer<AppletUploadState>(initialState, (builder) =>
  builder
    .addCase(uploadResponse, (state, action) => ({
      ...state,
      modified: action.payload.modified ?? state.modified,
      list: {
        ...state.list,
        [action.payload.applet.appletId]: action.payload.applet,
      },
    }))
    .addCase(completeStep.fulfilled, () => initialState)
    .addCase(submitPost.fulfilled, () => initialState)
    .addCase(saveRichTextApplet, (state, action) => ({
      ...state,
      modified: action.payload.modified ?? state.modified,
      list: {
        ...state.list,
        [action.payload.applet.appletId]: action.payload.applet,
      },
    }))
    .addCase(uploadAssetForRichText.fulfilled, (state, action) => ({
      ...state,
      modified: true,
      richText: action.payload,
    }))
    .addCase(cleanRichTextAsset, (state) => ({
      ...state,
      richText: undefined,
    }))
    .addCase(selectStep, () => initialState)
    .addCase(updateQuizQuestionValue, updateQuizApplet)
    .addCase(updateBranchFile, (state, action) => ({
      ...state,
      modified: action.payload.modified ?? state.modified,
      list: {
        ...state.list,
        [action.payload.applet.appletId]: {
          id: action.payload.applet.id,
          appletId: action.payload.applet.appletId,
          appletType: 'decision-tree',
          files: {
            ...state.list?.[action.payload.applet.appletId]?.files,
            [action.payload.applet.fileId]: {
              ...state.list?.[action.payload.applet.appletId]?.files?.[
                action.payload.applet.fileId
              ],
              fileId: action.payload.applet.fileId,
              file: action.payload.applet.file,
              fileOrder: action.payload.applet.fileOrder,
              fileUploaded: action.payload.applet.fileUploaded,
            },
          },
          untouched: action.payload.applet.untouched,
        },
      },
    }))
    .addCase(updateBranchDescription, (state, action) => ({
      ...state,
      modified: action.payload.modified ?? state.modified,
      list: {
        ...state.list,
        [action.payload.applet.appletId]: {
          id: action.payload.applet.id,
          appletId: action.payload.applet.appletId,
          appletType: 'decision-tree',
          files: {
            ...state.list?.[action.payload.applet.appletId]?.files,
            [action.payload.applet.fileId]: {
              ...state.list?.[action.payload.applet.appletId]?.files?.[
                action.payload.applet.fileId
              ],
              fileId: action.payload.applet.fileId,
              description: action.payload.applet.description,
              fileOrder: action.payload.applet.fileOrder,
            },
          },
          untouched: action.payload.applet.untouched,
        },
      },
    }))
    .addCase(updateMadlibAnswer, (state, action) => ({
      ...state,
      modified: action.payload.modified ?? state.modified,
      list: {
        ...state.list,
        [action.payload.applet.appletId]: {
          ...(state.list[action.payload.applet.appletId] || {}),
          id: action.payload.applet.id,
          appletId: action.payload.applet.appletId,
          appletType: 'madlib',
          madlibAnswers: {
            ...(state.list[action.payload.applet.appletId]?.madlibAnswers ||
              {}),
            [action.payload.applet.inputId]: {
              contentfulId: action.payload.applet.inputId,
              answer: action.payload.applet.value,
            },
          },
          untouched: action.payload.applet.untouched,
        },
      },
    }))
    .addCase(resetModified, (state) => ({
      ...state,
      modified: false,
    }))
    .addCase(resetState, (state) => ({
      ...state,
      modified: false,
      list: {},
    })),
);
