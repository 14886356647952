import React from 'react';

import { QuestApplet } from 'models/QuestApplet';
import { GroupAppletContainer } from 'components/applets/components';
import AppletPreviewSwitch from '../../../../common/applet-preview-switch/index';

type GroupedAppletModalPreviewProps = {
  applet: QuestApplet;
};

const GroupedAppletModalPreview = ({
  applet,
}: GroupedAppletModalPreviewProps) => {
  return (
    <GroupAppletContainer>
      {applet.applets?.map((applet) => {
        return <AppletPreviewSwitch applet={applet} />;
      })}
    </GroupAppletContainer>
  );
};

export default GroupedAppletModalPreview;
