import React from 'react';

const ChatIcon = () => {
  return (
    <svg width="13px" height="13px" viewBox="0 0 13 13">
      <title>Chat Icon</title>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd">
        <g
          id="Profile"
          transform="translate(-609.000000, -504.000000)"
          fill="#FFFFFF"
          stroke="#FFFFFF"
          strokeWidth="0.5">
          <g id="Group-2-Copy" transform="translate(529.000000, 492.000000)">
            <g id="Group-4" transform="translate(37.000000, 8.000000)">
              <g id="001-messenger" transform="translate(44.000000, 5.000000)">
                <path
                  d="M5.5,0 C2.46030469,0 0,2.459875 0,5.5 C0,6.46469141 0.251603516,7.40691016 0.729373047,8.23792578 L0.0207324219,10.4385488 C-0.028703125,10.5919687 0.0119238281,10.7601484 0.125876953,10.874123 C0.238755859,10.987002 0.406527344,11.0291113 0.561451172,10.9792676 L2.76207422,10.270627 C3.59308984,10.7483965 4.53530859,11 5.5,11 C8.53969531,11 11,8.540125 11,5.5 C11,2.46030469 8.540125,0 5.5,0 Z M5.5,10.140625 C4.62861523,10.140625 3.77923047,9.89750781 3.04364844,9.43750586 C2.93781641,9.37137695 2.80639648,9.35350195 2.68412891,9.39286133 L1.09553125,9.90446875 L1.60713867,8.31587109 C1.64589648,8.19536523 1.62954687,8.06377344 1.5624082,7.95635156 C1.10249219,7.22076953 0.859375,6.37138477 0.859375,5.5 C0.859375,2.94116797 2.94116797,0.859375 5.5,0.859375 C8.05883203,0.859375 10.140625,2.94116797 10.140625,5.5 C10.140625,8.05883203 8.05883203,10.140625 5.5,10.140625 Z"
                  id="Fill-1"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default ChatIcon;
