import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { BlockNodeProps } from '../../typings';
import { openReadingModal } from '../../../../modules/modal/actions';
import { LinkText, LinkProps } from '../../components';

type ReadingLinkProps = BlockNodeProps & LinkProps;

const ReadingLink = ({ node, children, ...linkProps }: ReadingLinkProps) => {
  const dispatch = useDispatch();

  const openModal = useCallback(() => {
    if (node.data?.target?.sys?.id) {
      dispatch(openReadingModal(node.data.target.fields));
    }
  }, [dispatch, node]);

  return (
    <LinkText onClick={openModal} {...linkProps}>
      {children}
    </LinkText>
  );
};

export default ReadingLink;
