import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectOverviewFilter } from 'modules/overview/selectors';
import Modal from 'components/modal';
import localizedStrings from 'localization';
import { FILTER_OPTIONS } from 'modules/overview/typings.d';
import RadioButton from 'components/radio-button';
import { setFilter } from 'modules/overview/actions';

type FilterModalProps = {
  isOpen?: boolean;
  close?: () => void;
};

const filterLabels = {
  [FILTER_OPTIONS.ALL]: localizedStrings.all,
  [FILTER_OPTIONS.COMPLETED]: localizedStrings.completed,
  [FILTER_OPTIONS.LATE]: localizedStrings.late,
  [FILTER_OPTIONS.TODO]: localizedStrings.todo,
};

const FilterModal = ({
  isOpen = false,
  close = () => {},
}: FilterModalProps) => {
  const dispatch = useDispatch();
  const overviewFilter = useSelector(selectOverviewFilter);

  const onSelect = useCallback(
    (value) => () => {
      dispatch(setFilter(value));
    },
    [dispatch],
  );
  return (
    <>
      <Modal title={localizedStrings.filter} open={isOpen} closeModal={close}>
        {Object.values(FILTER_OPTIONS).map((value) => (
          <RadioButton
            value={value}
            label={filterLabels[value]}
            selected={overviewFilter === value}
            key={value}
            onSelect={onSelect(value)}
          />
        ))}
      </Modal>
    </>
  );
};

export default FilterModal;
