import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { AppDispatch } from 'redux/typings';

import Collapsible from 'components/collapsible';
import { WeekSummaryTitle } from '../components';
import { CHALLENGE } from 'routes';
import { selectActiveQuestWeekIds, selectHasErrorsContentInActiveQuest } from 'modules/loading-screen/quests/selectors';
import { selectWeeksContentful } from 'modules/loading-screen/contentful/weeks/selectors';
import { selectAvailableContent } from 'modules/loading-screen/contents/selectors';
import { selectContentsContentful } from 'modules/loading-screen/contentful/contents/selectors';
import { calculateProgress } from 'helpers/helperFunctions';
import {
  selectSelectedWeekId,
  selectWeeksHash,
} from 'modules/loading-screen/weeks/selectors';
import { Content } from 'models/Content';
import { selectWeek } from 'modules/loading-screen/weeks/actions';
import theme from 'utils/theme';
import { selectWeekNumber } from '../selectors';
import LocalizedStrings from 'localization';
import {
  setComeFromChallenge,
  setNewOnboardingWeekId,
} from 'modules/onboarding/actions';
import { selectWeekOnboardingSeen } from 'modules/onboarding/selectors';
import { WeekSummaryItemToRender } from './WeekSummaryItemToRender';

type WeekItemProps = {
  weekUserId: string;
  weekId: string;
  isLastWeek?: boolean;
  progress?: number;
  isOpen?: boolean;
};

const WeekSummary = ({
  weekUserId,
  weekId,
  isLastWeek,
  progress = 0,
  isOpen,
}: WeekItemProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();
  const activeQuestWeekIds = useSelector(selectActiveQuestWeekIds);
  const weeksHash = useSelector(selectWeeksHash);
  const weeksContentful = useSelector(selectWeeksContentful);
  const contents = useSelector(selectAvailableContent);
  const contentsContentful = useSelector(selectContentsContentful);
  const selectedWeekId = useSelector(selectSelectedWeekId);
  const weekNumber = useSelector(selectWeekNumber);
  const weekOnboardingSeen = useSelector(selectWeekOnboardingSeen(weekUserId));
  const questHasContentErrors = useSelector(selectHasErrorsContentInActiveQuest)

  const week = useMemo(
    () => weeksContentful[weekId],
    [weekId, weeksContentful],
  );

  const activeQuestWeeks = useMemo(
    () =>
      activeQuestWeekIds
        .map((weekId) => weeksHash[weekId])
        .sort(
          (weekA, weekB) =>
            new Date(weekA.startingDate).getTime() -
            new Date(weekB.startingDate).getTime(),
        ),
    [weeksHash, activeQuestWeekIds],
  );

  const lastAvailableWeek = useMemo(
    () => activeQuestWeeks[activeQuestWeeks.length - 1],
    [activeQuestWeeks],
  );

  const contentsHashByContentfulId = useMemo(
    () =>
      contents.reduce((acc, cont) => {
        acc[cont.contentfulId] = cont;
        return acc;
      }, {} as Record<string, Content>),
    [contents],
  );

  const renderProgress = useCallback(
    (progress: number, bonusChallenge?: boolean) => {
      let ret = '';
      if (progress === 100) {
        if (bonusChallenge) {
          ret = LocalizedStrings.optional;
        } else {
          ret = LocalizedStrings.completed;
        }
      }

      if (progress === 0) {
        if (bonusChallenge) {
          ret = LocalizedStrings.optional;
        } else {
          ret = LocalizedStrings.todo;
        }
      }

      if (ret === '') {
        ret = `${progress.toFixed()}%`;
      }

      return ret;
    },
    [],
  );

  const handleChallengeClick = useCallback(
    (challengeId?: string) => () => {
      if (challengeId) {
        if (selectedWeekId !== weekUserId && weekUserId) {
          if (!weekOnboardingSeen) {
            dispatch(setComeFromChallenge(true));
            dispatch(setNewOnboardingWeekId(weekUserId));
          }
          dispatch(selectWeek(weekUserId));
        }
        history.push(CHALLENGE + '/' + challengeId);
      }
    },
    [selectedWeekId, weekUserId, dispatch, history, weekOnboardingSeen],
  );

  const renderContent = useCallback(
    (contentId: string) => {
      const content = contentsHashByContentfulId[contentId];
      const contentContentful = contentsContentful[contentId];
      const challengeProgress = content ? calculateProgress(content) : 0;
      const isNavigationEnabled = Boolean(content);

      if (questHasContentErrors) {
        if (isNavigationEnabled) {
          return <WeekSummaryItemToRender
            content={content}
            isNavigationEnabled={isNavigationEnabled}
            challengeProgress={challengeProgress}
            contentContentful={contentContentful}
            handleChallengeClick={handleChallengeClick}
            renderProgress={renderProgress}
          />
        } else {
          return null
        }
      } else {
        return <WeekSummaryItemToRender
          content={content}
          isNavigationEnabled={isNavigationEnabled}
          challengeProgress={challengeProgress}
          contentContentful={contentContentful}
          handleChallengeClick={handleChallengeClick}
          renderProgress={renderProgress}
        />
      }
    },
    [
      contentsContentful,
      contentsHashByContentfulId,
      handleChallengeClick,
      renderProgress,
      questHasContentErrors
    ],
  );

  const isInitOpen = weekNumber
    ? isOpen
    : lastAvailableWeek && lastAvailableWeek.contentfulId === week.id;

  const contentAvailableLength = week?.contentIds.filter((contentId) => Boolean(contentsHashByContentfulId[contentId])).length;

  const ContentCollapsible = useMemo(() =>
    <Collapsible
      title={<WeekSummaryTitle>{week.title}</WeekSummaryTitle>}
      initOpen={isInitOpen}
      withProgress
      progress={progress}
      withoutDash={isLastWeek}
      caretColor={theme.colors.white[100]}
      separatorColor={theme.colors.white[25]}
      contentLength={contentAvailableLength}>
      {week?.contentIds.map((contentId) => renderContent(contentId))}
    </Collapsible>,
    [
      contentAvailableLength,
      isInitOpen,
      isLastWeek,
      progress,
      renderContent,
      week?.contentIds,
      week.title
    ])

  return questHasContentErrors
    ? Boolean(contentAvailableLength) ? ContentCollapsible : null
    : ContentCollapsible
};

export default WeekSummary;
