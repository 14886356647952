import * as React from 'react';

type EditIconProps = {
  height?: number | string;
  width?: number | string;
};

const EditIcon = ({ width = 32, height = 32 }: EditIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      style={{ cursor: 'pointer' }}
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="16" cy="16" r="16" fill="#007DC2" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.4398 10.073L21.9253 11.5585C22.5323 12.1634 22.5565 13.1307 21.9991 13.7655L21.9264 13.8431L20.2144 15.5503L20.2166 15.5529L19.4547 16.3148L19.4515 16.3116L13.9827 21.769C13.5787 22.1722 13.0312 22.3987 12.4604 22.3987H10.6771C10.082 22.3987 9.59961 21.9163 9.59961 21.3212V19.5142C9.59961 18.9424 9.82684 18.3941 10.2313 17.9899L18.1537 10.0727C18.7853 9.44222 19.8084 9.44238 20.4398 10.073ZM16.4443 13.3043L10.9929 18.7521C10.7907 18.9542 10.6771 19.2283 10.6771 19.5142V21.0518C10.6771 21.2006 10.7977 21.3212 10.9465 21.3212H12.4604C12.7458 21.3212 13.0195 21.208 13.2215 21.0064L18.6871 15.5471L16.4443 13.3043ZM18.9153 10.8348L17.2066 12.5425L19.4499 14.7858L21.2127 13.0261C21.3732 12.8152 21.357 12.5134 21.1634 12.3204L19.6779 10.8349L19.6275 10.7905C19.4157 10.626 19.1096 10.6409 18.9153 10.8348Z"
        fill="white"
      />
    </svg>
  );
};

export default EditIcon;
