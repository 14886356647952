import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'redux/typings';

const selectWeeksState = (state: RootState) => state.contentful.weeks;

export const selectWeeksContentful = createSelector(
  selectWeeksState,
  (weeksState) => weeksState.weeks,
);

export const selectWeeksContentfulLastUpdatedAt = createSelector(
  selectWeeksState,
  (weeksState) => weeksState.lastUpdatedAt,
);
