import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { WeekDays } from 'modules/loading-screen/schedule-commit/typings';
import { addHour } from 'modules/loading-screen/schedule-commit/actions';
import { selectActiveQuestId } from 'modules/loading-screen/quests/selectors';
import useMinHoursPerWeek from './useMinHoursPerWeek';

const DAYS_PER_WEEK = 7;
const INDEXED_DAYS: WeekDays[] = [
  'mondayHours',
  'tuesdayHours',
  'wednesdayHours',
  'thursdayHours',
  'fridayHours',
  'saturdayHours',
  'sundayHours',
];

const useScheduleHoursDefault = () => {
  const dispatch = useDispatch();
  const questId = useSelector(selectActiveQuestId);
  const minHoursPerWeek = Math.abs(useMinHoursPerWeek());

  useEffect(() => {
    if (questId && minHoursPerWeek) {
      for (var index = 0; index < minHoursPerWeek; index++) {
        const dayIndex = index % DAYS_PER_WEEK;
        dispatch(addHour(INDEXED_DAYS[dayIndex]));
      }
    }
  }, [dispatch, questId, minHoursPerWeek]);
};

export default useScheduleHoursDefault;
