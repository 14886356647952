import React, { useCallback } from 'react';

import CheckIcon from 'components/icons/CheckIcon';
import OvalIcon from 'components/icons/OvalIcon';
import theme from 'utils/theme';
import {
  RadioButtonContainer,
  IconContainer,
  LabelContainer,
} from './components';

type RadioButtonProps = {
  value?: string;
  label?: string | React.ReactNode;
  selected?: boolean;
  onSelect?: (value?: string) => void;
  disabled?: boolean;
};

const RadioButton = ({
  value,
  label,
  selected,
  onSelect,
  disabled,
}: RadioButtonProps) => {
  const onSelectCallback = useCallback(() => {
    if (!disabled) {
      onSelect?.(value);
    }
  }, [onSelect, value, disabled]);

  return (
    <RadioButtonContainer onClick={onSelectCallback} disabled={disabled}>
      <IconContainer>
        {selected ? (
          <CheckIcon />
        ) : (
          <OvalIcon
            width={theme.typography.large24}
            height={theme.typography.large24}
            color={theme.colors.grey[90]}
          />
        )}
      </IconContainer>
      {label ? <LabelContainer>{label}</LabelContainer> : null}
    </RadioButtonContainer>
  );
};

export default RadioButton;
