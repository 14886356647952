import styled from '@emotion/styled';

export const StepListContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  margin-bottom: 40px;
`;

export const StepListSeparator = styled.div`
  height: 2px;
  width: 20px;
  background-color: #f2f2f7;
  margin: 4px;
`;

const CircleDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: ${(props) => props?.theme?.typography.large16};
`;

type ClickableProp = {
  clickable?: boolean;
  currentStep?: boolean;
};

export const OrangeCircle = styled(CircleDiv)<ClickableProp>`
  height: ${(props) => (props.currentStep ? '34px' : '28px')};
  width: ${(props) => (props.currentStep ? '34px' : '28px')};
  border: ${(props) => (props.currentStep ? '3px solid #ffffff' : 'none')};
  margin-top: ${(props) => (props.currentStep ? '-3px' : 0)};
  margin-bottom: ${(props) => (props.currentStep ? '-3px' : 0)};
  box-shadow: ${(props) =>
    props.currentStep
      ? `0 5px 5px 0 rgba(137, 143, 150, 0.14),
    0 3px 14px 0 rgba(137, 143, 150, 0.12),
    0 8px 10px 0 rgba(137, 143, 150, 0.2);`
      : 'none'};
  background-color: #f4bf5d;
`;
export const GreyCircle = styled(CircleDiv)<ClickableProp>`
  height: 28px;
  width: 28px;
  border: 3px solid #e8e8ec;
  background-color: #f2f2f7;
  cursor: ${(props) => (props.clickable === false ? 'auto' : 'pointer')};
`;
