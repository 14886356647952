import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Rating from 'react-rating';
import { Formik, FormikProps } from 'formik';

import StarEmpty from 'modules/profile/achievements/achievements-list/StarEmpty';
import StarFilled from 'modules/profile/achievements/achievements-list/StartFilled';
import { selectSelectedAchievement } from '../selectors';
import ChallengeFeedbacks from 'modules/profile/achievements/achievements-list/ChallengeFeedbacks';
import {
  RelatedUserChallengePanel,
  RelatedUserChallengeTitle,
  RelatedUserReviewsPanel,
  SubmissionRatingContainer,
  SubmissionFeedbackTitle,
  SubmissionFeedbackPanel,
  SubmissionFeedbackSubmitButton,
  SubmissionFeedbackForm,
} from '../../components';
import localizedStrings from 'localization';
import { submissionFeedbackSchema } from './validationSchema';
import { AppDispatch } from 'redux/typings';
import { TextArea, ValidationError } from 'components/components';
import { selectSelectedRelationship } from 'modules/relationships/selectors';
import { getUserAchievements, giveFeedback } from '../actions';
import { ExternalSubmission } from 'modules/external-feedback/typings';
import { getSubmission } from 'modules/external-feedback/actions';
import { giveExternalFeedback } from '../actions';
import useActionStatus from 'hooks/useActionStatus';
import { selectContentsContentful } from 'modules/loading-screen/contentful/contents/selectors';
import { calculateFeedbacksAverage } from 'helpers/helperFunctions';
import { appletToChallengeApplet } from 'helpers/AppletHelpers';
import { selectAppletsContentful } from 'modules/loading-screen/contentful/applets/selectors';
import SubmissionApplets from './SubmissionApplets';

type RelatedUserChallengeDetailsProps = {
  submission?: ExternalSubmission;
  external?: boolean;
  token?: string | null;
};

const RelatedUserChallengeDetails = ({
  submission,
  external = false,
  token,
}: RelatedUserChallengeDetailsProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const formikRef = useRef<FormikProps<any>>(null);

  const selectedAchievement = useSelector(selectSelectedAchievement);
  const selectedRelationship = useSelector(selectSelectedRelationship);
  const contentsContentful = useSelector(selectContentsContentful);
  const appletsContentful = useSelector(selectAppletsContentful);

  const [feedbackSubmitPending, feedbackSubmitFulfilled] = useActionStatus(
    giveFeedback,
  );
  const [
    externalFeedbackSubmitPending,
    externalFeedbackSubmitFulfilled,
  ] = useActionStatus(giveExternalFeedback);

  const content = useMemo(
    () =>
      selectedAchievement?.contentContentfulId
        ? contentsContentful[selectedAchievement.contentContentfulId]
        : undefined,
    [contentsContentful, selectedAchievement?.contentContentfulId],
  );

  const [isPending, wasFullfilled] = useMemo(
    () =>
      external
        ? [externalFeedbackSubmitPending, externalFeedbackSubmitFulfilled]
        : [feedbackSubmitPending, feedbackSubmitFulfilled],
    [
      external,
      externalFeedbackSubmitFulfilled,
      externalFeedbackSubmitPending,
      feedbackSubmitFulfilled,
      feedbackSubmitPending,
    ],
  );

  const challengeTitle = useMemo(
    () => (external ? submission?.challengeTitle : content?.title),
    [content, external, submission],
  );

  const feedbackAvg = useMemo(
    () =>
      external
        ? submission?.feedbackAvg
        : calculateFeedbacksAverage(selectedAchievement?.feedbacks),
    [external, selectedAchievement, submission],
  );

  const applets = useMemo(
    () =>
      (external
        ? submission?.applets
        : selectedAchievement?.applets?.map((app) =>
            appletToChallengeApplet(app, appletsContentful[app.contentfulId]),
          )) ?? [],
    [
      appletsContentful,
      external,
      selectedAchievement?.applets,
      submission?.applets,
    ],
  );

  const feedbacks = useMemo(
    () =>
      (external ? submission?.feedbacks : selectedAchievement?.feedbacks) ?? [],
    [external, selectedAchievement, submission],
  );

  const handleSubmit = useCallback(
    (values) => {
      const { rating, feedback } = values;
      setHasSubmitted(true);
      if (
        !external &&
        selectedAchievement?.postUserId &&
        selectedRelationship?.relatedUser.id
      ) {
        dispatch(
          giveFeedback({
            postId: selectedAchievement.postUserId,
            answer: feedback,
            score: rating,
          }),
        ).then(() =>
          dispatch(
            getUserAchievements({
              userId: selectedRelationship.relatedUser.id,
            }),
          ),
        );
      }
      if (external && submission && token) {
        dispatch(
          giveExternalFeedback({
            feedback: {
              postId: submission.id,
              answer: feedback,
              score: rating,
            },
            token,
          }),
        ).then(() => dispatch(getSubmission({ id: submission.id, token })));
      }
    },
    [
      dispatch,
      external,
      selectedAchievement,
      selectedRelationship,
      submission,
      token,
    ],
  );

  useEffect(() => {
    if (hasSubmitted && wasFullfilled) {
      setHasSubmitted(false);
      formikRef.current?.resetForm();
    }
  }, [hasSubmitted, wasFullfilled]);

  return (
    <>
      <RelatedUserChallengePanel>
        <RelatedUserChallengeTitle>{challengeTitle}</RelatedUserChallengeTitle>
        <SubmissionRatingContainer>
          <Rating
            readonly={true}
            initialRating={feedbackAvg}
            fullSymbol={<StarFilled />}
            emptySymbol={<StarEmpty />}
          />
        </SubmissionRatingContainer>
        <SubmissionApplets applets={applets} />
      </RelatedUserChallengePanel>
      {feedbacks.length > 0 && (
        <RelatedUserReviewsPanel>
          <ChallengeFeedbacks feedbacks={feedbacks} />
        </RelatedUserReviewsPanel>
      )}
      <SubmissionFeedbackPanel>
        {external && submission?.hasAlreadySubmittedFeedback ? (
          <SubmissionFeedbackTitle>
            {localizedStrings.feedbackAlreadySubmitted}
          </SubmissionFeedbackTitle>
        ) : (
          <>
            <SubmissionFeedbackTitle>
              {localizedStrings.submitFeedbackPrompt}
            </SubmissionFeedbackTitle>
            <Formik
              initialValues={{ rating: 1, feedback: '' }}
              validationSchema={submissionFeedbackSchema}
              onSubmit={handleSubmit}
              innerRef={formikRef}>
              {({
                values,
                errors,
                submitCount,
                handleChange,
                setFieldValue,
              }) => (
                <SubmissionFeedbackForm>
                  <SubmissionRatingContainer>
                    <Rating
                      initialRating={values.rating}
                      fullSymbol={<StarFilled />}
                      emptySymbol={<StarEmpty />}
                      onChange={(value) => setFieldValue('rating', value)}
                    />
                  </SubmissionRatingContainer>
                  <TextArea
                    rows={10}
                    value={values.feedback}
                    onChange={handleChange('feedback')}
                    placeholder={localizedStrings.writeYourFeedback}
                  />
                  {submitCount > 0 && errors.feedback !== undefined && (
                    <ValidationError>{errors.feedback}</ValidationError>
                  )}

                  <SubmissionFeedbackSubmitButton
                    type="submit"
                    isLoading={isPending}>
                    {localizedStrings.leaveFeedback}
                  </SubmissionFeedbackSubmitButton>
                </SubmissionFeedbackForm>
              )}
            </Formik>
          </>
        )}
      </SubmissionFeedbackPanel>
    </>
  );
};

export default RelatedUserChallengeDetails;
