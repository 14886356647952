import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import NavBar from 'modules/navbar';

import { ChallengesSection } from './components';
import { selectSelectedWeek } from 'modules/loading-screen/weeks/selectors';
import LoadingNewWeek from './LoadingNewWeek';
import LoadingNewQuest from './LoadingNewQuest';
import Playlist from './playlist';
import { selectPlaylistCount } from './playlist/selectors';
import { getPlaylist, getPlaylistSelected } from './playlist/actions';
import { selectActiveQuestWeekIds, selectLoadingLastContentFinished } from 'modules/loading-screen/quests/selectors';
import SyncContent from 'components/sync-content';
import { selectIsSampleQuest } from '../loading-screen/quests/selectors';
import NextUp from './next-up';
import WeekProgressTimeline from 'components/week-progress-timeline';
import { LoadNextUp } from 'modules/loaders/LoadNextUp';
import { LoadWeekProgressLine } from 'modules/loaders/LoadWeekProgressLine';
import { LOADING_SCREEN } from 'routes';
import useLoadLastContent from 'hooks/use-load-content/useLoadLastContent';

const Home = () => {
  const loadFinished = useSelector(selectLoadingLastContentFinished)
  const availableWeeks = useSelector(selectActiveQuestWeekIds);
  const currentPlaylistCount = useSelector(selectPlaylistCount);
  const selectedWeek = useSelector(selectSelectedWeek);
  const isSampleQuest = useSelector(selectIsSampleQuest);

  const history = useHistory();
  const dispatch = useDispatch();
  useLoadLastContent();

  useEffect(() => {
    if (!selectedWeek) {
      history.push(LOADING_SCREEN);
    }
  }, [history, selectedWeek]);

  useEffect(() => {
    if (currentPlaylistCount < availableWeeks.length) {
      dispatch(getPlaylist());
      dispatch(getPlaylistSelected());
    }
  }, [availableWeeks, currentPlaylistCount, dispatch]);

  return (
    <>
      <NavBar />
      <LoadingNewWeek />
      <LoadingNewQuest />
      <ChallengesSection>
        <SyncContent />
        {loadFinished ? <NextUp /> : <LoadNextUp />}
        {loadFinished ? <>{isSampleQuest ? null : <WeekProgressTimeline />}</> : <LoadWeekProgressLine />}
      </ChallengesSection>
      <Playlist />
    </>
  );
};

export default Home;
