import styled from '@emotion/styled';

import next from 'assets/icons/Next.webp';
import back from 'assets/icons/Back.webp';
import background from 'assets/images/MapBackground.webp';

export const TextArea = styled.textarea`
  font-size: ${(props) => props?.theme?.typography.large18};
  line-height: ${(props) => props?.theme?.typography.large24};
  background-color: transparent;
  border: 1px solid ${(props) => props?.theme?.colors.grey[90]};
  border-radius: 0.7rem;
  resize: none;
  outline: none;
  padding: 0.7rem 1.2rem 0.5rem 0.8rem;
  width: 100%;
  ::placeholder {
    color: ${(props) => props?.theme?.colors.grey[90]};
    font-size: ${(props) => props?.theme?.typography.large18};
    line-height: ${(props) => props?.theme?.typography.large24};
    opacity: 1;
  }
  ::-moz-placeholder {
    color: ${(props) => props?.theme?.colors.grey[90]};
    font-size: ${(props) => props?.theme?.typography.large18};
    line-height: ${(props) => props?.theme?.typography.large24};
  }
  &:disabled {
    background-color: ${(props) => props?.theme?.colors.grey[80]};
    color: ${(props) => props?.theme?.colors.grey[90]};
  }
`;

export const ValidationError = styled.div`
  color: ${(props) => props?.theme?.colors.red[80]};
  font-size: ${(props) => props?.theme?.typography.large16};
  line-height: ${(props) => props?.theme?.typography.large16};
  margin-top: 0.2rem;
  padding-left: 0.7rem;
`;

const BaseNavButton = styled.button`
  background-color: transparent;
  border: none;
  padding: 0;
  height: 1.8rem;
  width: 1.8rem;
  border-radius: 0.4rem;
  opacity: ${(props) => (props.disabled ? '0.3' : '1')};
`;

export const PrevButton = styled(BaseNavButton)`
  background-image: url(${back});
  background-size: cover;
  background-position: center;
`;

export const NextButton = styled(BaseNavButton)`
  background-image: url(${next});
  background-size: cover;
  background-position: center;
`;

export const ScrollableArea = styled.div`
  overflow: auto;
  ::-webkit-scrollbar {
    width: 7px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px ${(props) => props?.theme?.colors.grey[100]};
    -webkit-box-shadow: inset 0 0 5px
      ${(props) => props?.theme?.colors.grey[100]};
    border-radius: 5px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: ${(props) => props?.theme?.colors.grey[90]};
  }
`;

export const MapBackground = styled.div`
  background-image: url(${background});
  background-position: center;
  background-size: cover;
`;

export const StyledButton = styled.button`
  background-color: transparent;
  padding: 0;
  border: none;
`;

export const PageBackground = styled.div`
  display: flex;
  justify-content: center;
  background-color: #f9f9f9;
  min-height: calc(100vh - 4.5rem);
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 1100px;
  max-width: 90%;
  margin-top: 2.7rem;
`;

export const ScrollablePanel = styled(ScrollableArea)`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  border: 0.5px solid #efeff4;
  border-radius: 0.6rem;
  background-color: #ffffff;
  padding: 0.8rem;
`;

export const Panel = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  border: 0.5px solid #efeff4;
  border-radius: 0.6rem;
  background-color: #ffffff;
  padding: 0.8rem;
`;

export const TopAlignedSvg = styled.svg`
  vertical-align: top;
`;

export const PreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(0, 0, 0, 0.3);
  padding: 15px;
  border-radius: 12px;
  max-width: 100%;
  overflow: scroll;
  inline-size: min-content;
  overflow-wrap: break-word;
  min-width: 1000px
`;
