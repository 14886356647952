import React, {
  ReactNode,
  useCallback,
  useMemo,
  useState,
  useEffect,
} from 'react';
import { nanoid } from 'nanoid/non-secure';

import useContentfulRenderer from 'hooks/useContentfulRenderer';
import { AdditionalInfo } from 'models/StepContentful';
import ExamplesIcon from './examples-icon';
import RubricsIcon from './rubrics-icon';
import TipsIcon from './tips-icon';
import GoDeeperIcon from './go-deeper-icon';
import localizedStrings from 'localization';
import Modal from 'components/modal';
import {
  StepResourceButton,
  StepResourceIcon,
  StepResourcesContainer,
  StepResourceText,
} from '../components';

type StepAdditionalInfoProps = {
  additionalInfo: AdditionalInfo;
};

type ModalStateType = {
  open: boolean;
  title?: string;
  titleIcon?: ReactNode;
  content?: ReactNode;
};

const StepAdditionalInfo = ({ additionalInfo }: StepAdditionalInfoProps) => {
  const [modalState, setModalState] = useState<ModalStateType>({
    open: false,
    title: undefined,
    titleIcon: undefined,
    content: undefined,
  });
  const { examples, rubrics, tips, goDeeper } = additionalInfo;
  const examplesContent = useContentfulRenderer(examples, renderStyles);
  const goDeeperContent = useContentfulRenderer(goDeeper, renderStyles);
  const rubricsContent = useContentfulRenderer(rubrics, renderStyles);
  const tipsContent = useContentfulRenderer(tips, renderStyles);

  useEffect(() => {
    if (modalState.open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [modalState.open]);

  const exampleModalTitle = useMemo<[string, ReactNode]>(() => {
    return [localizedStrings.examples, <ExamplesIcon />];
  }, []);

  const tipsModalTitle = useMemo<[string, ReactNode]>(() => {
    return [localizedStrings.tips, <TipsIcon />];
  }, []);
  const rubricsModalTitle = useMemo<[string, ReactNode]>(() => {
    return [localizedStrings.rubrics, <RubricsIcon />];
  }, []);
  const goDeeperModalTitle = useMemo<[string, ReactNode]>(() => {
    return [localizedStrings.goDeeper, <GoDeeperIcon />];
  }, []);

  const getModalTitle = useCallback(
    (resourceType: string) => {
      switch (resourceType) {
        case 'examples':
          return exampleModalTitle;
        case 'tips':
          return tipsModalTitle;
        case 'rubrics':
          return rubricsModalTitle;
        case 'goDeeper':
          return goDeeperModalTitle;
        default:
          return [undefined, undefined];
      }
    },
    [exampleModalTitle, tipsModalTitle, rubricsModalTitle, goDeeperModalTitle],
  );

  const openModal = useCallback(
    (resourceType: string, content: ReactNode) => () => {
      const [title, titleIcon] = getModalTitle(resourceType);
      setModalState({ open: true, title, titleIcon, content });
    },
    [getModalTitle],
  );

  const closeModal = useCallback(() => {
    setModalState({ open: false });
  }, []);

  return (
    <>
      <Modal
        open={modalState.open}
        title={modalState.title}
        titleIcon={modalState.titleIcon}
        closeModal={closeModal}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {modalState.content}
        </div>
      </Modal>
      <StepResourcesContainer>
        <StepResourceButton
          key={nanoid()}
          onClick={openModal('examples', examplesContent)}
          disabled={examples === undefined}>
          <StepResourceIcon>
            <ExamplesIcon disabled={examples === undefined} />
          </StepResourceIcon>
          <StepResourceText disabled={examples === undefined}>
            {localizedStrings.examples}
          </StepResourceText>
        </StepResourceButton>
        <StepResourceButton
          key={nanoid()}
          onClick={openModal('tips', tipsContent)}
          disabled={tips === undefined}>
          <StepResourceIcon>
            <TipsIcon disabled={tips === undefined} />
          </StepResourceIcon>
          <StepResourceText disabled={tips === undefined}>
            {localizedStrings.tips}
          </StepResourceText>
        </StepResourceButton>
        <StepResourceButton
          key={nanoid()}
          onClick={openModal('rubrics', rubricsContent)}
          disabled={rubrics === undefined}>
          <StepResourceIcon>
            <RubricsIcon disabled={rubrics === undefined} />
          </StepResourceIcon>
          <StepResourceText disabled={rubrics === undefined}>
            {localizedStrings.rubrics}
          </StepResourceText>
        </StepResourceButton>
        <StepResourceButton
          key={3}
          onClick={openModal('goDeeper', goDeeperContent)}
          disabled={goDeeper === undefined}>
          <StepResourceIcon>
            <GoDeeperIcon disabled={goDeeper === undefined} />
          </StepResourceIcon>
          <StepResourceText disabled={goDeeper === undefined}>
            {localizedStrings.goDeeper}
          </StepResourceText>
        </StepResourceButton>
      </StepResourcesContainer>
    </>
  );
};

const renderStyles = {
  additionalStyles: { embeddedAsset: { alignSelf: 'center' } },
};

export default StepAdditionalInfo;
