import { baseService } from 'modules/baseService';
import HttpClient from 'services/HttpClient';
import {
  AchievementsState,
  GenericApplet,
  PublishShowcaseArgs,
  ShareShowcaseArgs,
  ShowcaseItem,
} from './typings';

const baseUrl = '/achievements';

export const getAchievements = baseService<undefined, AchievementsState>(() =>
  HttpClient.get(baseUrl),
);

export const getPublishedShowcases = baseService<void, ShowcaseItem[]>(() =>
  HttpClient.get('/showcases'),
);

export const publishShowcase = baseService<PublishShowcaseArgs, ShowcaseItem>(
  (showcase) => HttpClient.post(`/showcases/`, showcase),
);

export const shareShowcase = baseService<ShareShowcaseArgs, void>(
  ({ showcaseId, data }) =>
    HttpClient.post(`/showcases/${showcaseId}/share`, data),
);

export const getGenericApplets = baseService<void, GenericApplet[]>(() =>
  HttpClient.get('/applets/generic'),
);

export const deleteShowcase = baseService<string, void>((showcaseId) =>
  HttpClient.delete(`/showcases/${showcaseId}`),
);
