import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { isAfter, parse, startOfDay } from 'date-fns';

import { StepCardInformation, StepSchedule } from 'models/Schedule';
import { DailySchedule } from '.';
import StepCard from './StepCard';
import {
  AgendaContainer,
  AgendaDay,
  AgendaItemContainer,
  EmptyStepCard,
  StepsContainer,
} from '../components';
import { formatUTCDate } from 'helpers/dateHelpers';
import localizedStrings from 'localization';
import { CHALLENGE } from 'routes';
import { selectActiveQuestWeekIds } from 'modules/loading-screen/quests/selectors';
import { selectSelectedWeekId } from 'modules/loading-screen/weeks/selectors';
import { selectWeek } from 'modules/loading-screen/weeks/actions';
import { selectOverviewFilter } from '../selectors';
import { FILTER_OPTIONS } from '../typings.d';

type AgendaProps = {
  visibleDay: string;
  dailyScheduleDuplicated: DailySchedule;
  days?: DailySchedule;
};

type ItemRefs = {
  [key: string]: HTMLDivElement | null;
};

const Agenda = ({ visibleDay, dailyScheduleDuplicated, days }: AgendaProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const itemRefs = useRef<ItemRefs>({});
  const activeQuestWeekIds = useSelector(selectActiveQuestWeekIds);
  const selectedWeekId = useSelector(selectSelectedWeekId);
  const filter = useSelector(selectOverviewFilter);

  const handleStepClick = useCallback(
    (step: StepSchedule) => {
      if (activeQuestWeekIds.includes(step.weekId)) {
        if (selectedWeekId !== step.weekId) {
          dispatch(selectWeek(step.weekId));
        }
        history.push(
          CHALLENGE + '/' + step.challengeId + '/' + step.stepNumber,
        );
      }
    },
    [activeQuestWeekIds, selectedWeekId, history, dispatch],
  );

  const renderSteps = useCallback(
    (stepSchedules: StepSchedule[], day: string) => {
      const today = startOfDay(new Date());
      const steps = stepSchedules.map((step) => {
        const stepDate = parse(day, 'yyyy-MM-dd', new Date());
        return {
          ...step,
          isLate: !step.completed && isAfter(today, stepDate),
        } as StepCardInformation;
      });

      let filteredSteps = steps;
      switch (filter) {
        case FILTER_OPTIONS.ALL:
          filteredSteps = steps;
          break;
        case FILTER_OPTIONS.COMPLETED:
          filteredSteps = steps.filter((step) => step.completed);
          break;
        case FILTER_OPTIONS.LATE:
          filteredSteps = steps.filter((step) => step.isLate);
          break;
        case FILTER_OPTIONS.TODO:
          filteredSteps = steps.filter(
            (step) => !step.completed && !step.isLate,
          );
          break;
        default:
          filteredSteps = steps;
      }
      return (
        <StepsContainer>
          {filteredSteps.length > 0 ? (
            filteredSteps.map((stepSchedule) => (
              <StepCard
                step={stepSchedule}
                key={stepSchedule.id}
                onClick={handleStepClick}
                day={day}
              />
            ))
          ) : (
            <EmptyStepCard>{localizedStrings.noChalenges}</EmptyStepCard>
          )}
        </StepsContainer>
      );
    },
    [handleStepClick, filter],
  );

  /**
   * Queda no utilizado este componente por el momento. Hasta que se decida hacer el refactor correspondiente
   * a la visualizacion de contenido por fecha para un schedule tradicional con unlockAfterDays y un schedule por modulos.
   */
  // const renderItems = useMemo(
  //   () =>
  //     Object.entries(days).map(([key, value]) => (
  //       <AgendaItemContainer
  //         key={key}
  //         ref={(el) => (itemRefs.current = { ...itemRefs.current, [key]: el })}>
  //         <AgendaDay>{formatUTCDate(new Date(key))}</AgendaDay>
  //         {renderSteps(value, key)}
  //       </AgendaItemContainer>
  //     )),
  //   [days, renderSteps],
  // );

  const renderItemsDuplicated = useMemo(
    () =>
      Object.entries(dailyScheduleDuplicated).map(([key, value]) => (
        <AgendaItemContainer
          key={key}
          ref={(el) => (itemRefs.current = { ...itemRefs.current, [key.split('&')[1]]: el })}>
          <AgendaDay>{formatUTCDate(new Date(key.split('&')[1]))}</AgendaDay>
          {renderSteps(value, key.split('&')[1])}
        </AgendaItemContainer>
      )),
    [dailyScheduleDuplicated, renderSteps],
  );

  useEffect(() => {
    itemRefs.current[visibleDay]?.scrollIntoView();
  }, [visibleDay]);

  return <AgendaContainer>{renderItemsDuplicated}</AgendaContainer>;
};

export default Agenda;
