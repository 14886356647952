import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import localizedStrings from 'localization';
import { ShowcaseItem } from 'modules/profile/typings';
import { selectPublishedShowcases } from 'modules/profile/selectors';
import {
  ProfileSection,
  ProfileSectionPanel,
  ProfileSectionTitle,
  PublishedShowcaseEmpty,
  PublishedShowcasesContainer,
} from 'modules/profile/components';
import PublishShowcaseModal from './publish-showcase-modal';
import ShareShowcaseModal from './ShareShowcaseModal';
import { SHOWCASE } from 'routes';
import ButtonPrimary from 'components/button-primary';
import PublishedShowcaseItem from './PublishedShowcaseItem';
import DeleteShowcaseModal from './DeleteShowcaseModal';
import { getPublishedShowcases } from 'modules/profile/actions';
import useActionStatus from 'hooks/useActionStatus';
import { selectLoadingAdditionalFinished } from 'modules/loading-screen/quests/selectors';
import { CenterLoader } from 'components/loading-spinner/components';
import LoadingSpinner from 'components/loading-spinner';
import theme from 'utils/theme';

type ModalState = {
  publishShowcaseOpen?: boolean;
  shareShowcaseOpen?: boolean;
  deleteShowcaseOpen?: boolean;
  showcaseId?: string;
};
const PublishedShowcases = () => {
  const [modalState, setModalState] = useState<ModalState>({});
  const publishedShowcases = useSelector(selectPublishedShowcases);
  const loadingFinished = useSelector(selectLoadingAdditionalFinished);
  const [isPendingCohorts, wasFulfilledCohorts] = useActionStatus(getPublishedShowcases);

  const shouldShowLoader = useMemo(() =>
    !loadingFinished || isPendingCohorts || !wasFulfilledCohorts
    , [loadingFinished, isPendingCohorts, wasFulfilledCohorts]);

  const openPublishShowcaseModal = useCallback(() => {
    setModalState({ publishShowcaseOpen: true });
  }, []);

  const openShareShowcaseModal = useCallback((showcaseId: string) => {
    setModalState({ shareShowcaseOpen: true, showcaseId });
  }, []);

  const openDeleteShowcaseModal = useCallback((showcaseId: string) => {
    setModalState({ deleteShowcaseOpen: true, showcaseId });
  }, []);

  const closeModal = useCallback(() => {
    setModalState({});
  }, []);

  const navigateToShowcase = useCallback((showcaseId: string) => {
    window.open(`${SHOWCASE}/${showcaseId}`);
  }, []);

  return (
    <ProfileSection>
      <ProfileSectionTitle>{localizedStrings.showcases}</ProfileSectionTitle>
      <ProfileSectionPanel>
        <PublishShowcaseModal
          open={modalState.publishShowcaseOpen ?? false}
          closeModal={closeModal}
        />
        <ShareShowcaseModal
          open={modalState.shareShowcaseOpen ?? false}
          closeModal={closeModal}
          showcaseId={modalState.showcaseId ?? ''}
        />
        <DeleteShowcaseModal
          open={modalState.deleteShowcaseOpen ?? false}
          closeModal={closeModal}
          showcaseId={modalState.showcaseId ?? ''}
        />
        {
          shouldShowLoader ?
            <CenterLoader>
              <LoadingSpinner size={50} color={theme.colors.blue1[100]} />
            </CenterLoader>
            :
            <>
              <PublishedShowcasesContainer>
                {publishedShowcases && publishedShowcases.length > 0 ? (
                  publishedShowcases.map((publishedShowcase: ShowcaseItem, index) => (
                    <PublishedShowcaseItem
                      key={publishedShowcase.id}
                      showcase={publishedShowcase}
                      index={index}
                      maxIndex={publishedShowcases.length - 1}
                      onDeleteShowcaseClick={openDeleteShowcaseModal}
                      onShareShowcaseClick={openShareShowcaseModal}
                      onViewShowcaseClick={navigateToShowcase}
                    />
                  ))
                ) : (
                  <PublishedShowcaseEmpty>
                    {localizedStrings.noPublishedShowcases}
                  </PublishedShowcaseEmpty>
                )}
              </PublishedShowcasesContainer>
              <ButtonPrimary onClick={openPublishShowcaseModal}>
                {localizedStrings.createShowcase}
              </ButtonPrimary>
            </>
        }
      </ProfileSectionPanel>
    </ProfileSection>
  );
};

export default PublishedShowcases;
