import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import OnboardingCarousel from 'modules/onboarding/onboarding-carousel';
import { LOADING_SCREEN, SCHEDULE_COMMIT } from 'routes';
import {
  selectActiveQuest,
  selectActiveQuestOnboardings,
} from 'modules/loading-screen/quests/selectors';
import { OnboardingContainer } from '../components';
import { selectHasAlreadyAScheduleCommit } from 'modules/loading-screen/schedule-commit/selectors';
import { AppDispatch } from 'redux/typings';
import { notifyQuestOnboardingSeen } from '../actions';
import useActionStatus from 'hooks/useActionStatus';
import { getQuestsOnboardings } from 'modules/loading-screen/contentful/quests-onboardings/actions';
import { selectQuestsContentfulLastUpdatedAt } from 'modules/loading-screen/contentful/quests/selectors';
import { selectQuestsOnboardingsLastUpdatedAt } from 'modules/loading-screen/contentful/quests-onboardings/selectors';
import { selectQuestsOffboardingsLastUpdatedAt } from 'modules/loading-screen/contentful/quests-offboardings/selectors';
import { getQuests } from 'modules/loading-screen/contentful/quests/actions';
import { getQuestsOffboardings } from 'modules/loading-screen/contentful/quests-offboardings/actions';

const QuestOnboarding = () => {
  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();

  const questOnboardings = useSelector(selectActiveQuestOnboardings);
  const withScheduleCommit = useSelector(selectHasAlreadyAScheduleCommit);
  const activeQuest = useSelector(selectActiveQuest);
  const [, hasRetrievedQuestOnboardings] = useActionStatus(getQuestsOnboardings);

  const questsLastUpdatedAt = useSelector(selectQuestsContentfulLastUpdatedAt);
  const questOnboardingsLastUpdatedAt = useSelector(selectQuestsOnboardingsLastUpdatedAt);
  const questOffboardingsLastUpdatedAt = useSelector(selectQuestsOffboardingsLastUpdatedAt);

  const handleGetStarted = useCallback(() => {
    if (activeQuest) {
      dispatch(notifyQuestOnboardingSeen({ questId: activeQuest.id }));
    }
    if (withScheduleCommit) {
      history.push(LOADING_SCREEN);
    } else {
      history.push(SCHEDULE_COMMIT);
    }
  }, [dispatch, history, activeQuest, withScheduleCommit]);

  useEffect(() => {
    if (hasRetrievedQuestOnboardings && !questOnboardings && activeQuest) {
      dispatch(notifyQuestOnboardingSeen({ questId: activeQuest.id }));
      history.push(LOADING_SCREEN);
    }
  }, [
    questOnboardings,
    history,
    dispatch,
    activeQuest,
    hasRetrievedQuestOnboardings,
  ]);

  useEffect(() => {
    if (!hasRetrievedQuestOnboardings) {
      dispatch(getQuests(questsLastUpdatedAt));
      dispatch(getQuestsOnboardings(questOnboardingsLastUpdatedAt));
      dispatch(getQuestsOffboardings(questOffboardingsLastUpdatedAt));
    }
  }, [
    dispatch,
    hasRetrievedQuestOnboardings,
    questOffboardingsLastUpdatedAt,
    questOnboardingsLastUpdatedAt,
    questsLastUpdatedAt
  ])


  return (
    <OnboardingContainer>
      {questOnboardings && questOnboardings.length > 0 ? (
        <OnboardingCarousel
          onboardings={questOnboardings}
          handleGetStarted={handleGetStarted}
        />
      ) : (
        <p>Loading...</p>
      )}
    </OnboardingContainer>
  );
};

export default QuestOnboarding;
