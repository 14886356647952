import React, { useMemo } from 'react';

import { FileUploadedInformation } from 'modules/loading-screen/contents/typings';
import DecisionTreeImagePreview from './DecisionTreeImagePreview';
import {
  DecisionTreePreviewBottomImage,
  DecisionTreePreviewContainer,
  DecisionTreePreviewHorizontalSeparator,
  DecisionTreePreviewImagesContainer,
  DecisionTreePreviewTopImage,
  DecisionTreePreviewVerticalSeparator,
} from '../components';

type DecisionTreePreviewProps = {
  files?: FileUploadedInformation[];
};

const DecisionTreePreview = ({ files }: DecisionTreePreviewProps) => {
  const [firstFile, otherFiles] = useMemo(() => {
    let first: FileUploadedInformation | undefined;
    let others: FileUploadedInformation[] = [];
    if (files && files.length > 0) {
      const orderedFiles = [...files].sort(
        (fileA, fileB) => fileA.fileOrder - fileB.fileOrder,
      );
      first = orderedFiles[0];
      others = orderedFiles.slice(1, 3);
    }

    return [first, others];
  }, [files]);

  return (
    <>
      {files ? (
        <DecisionTreePreviewContainer>
          <DecisionTreePreviewImagesContainer>
            <DecisionTreePreviewTopImage>
              {firstFile ? (
                <DecisionTreeImagePreview
                  url={firstFile.fileUrl}
                  description={firstFile.description}
                />
              ) : null}
            </DecisionTreePreviewTopImage>
            <DecisionTreePreviewHorizontalSeparator />

            <DecisionTreePreviewBottomImage>
              {otherFiles[0] ? (
                <DecisionTreeImagePreview
                  url={otherFiles[0].fileUrl}
                  description={otherFiles[0].description}
                />
              ) : null}
            </DecisionTreePreviewBottomImage>
            <DecisionTreePreviewVerticalSeparator />
            <DecisionTreePreviewBottomImage>
              {otherFiles[1] ? (
                <DecisionTreeImagePreview
                  url={otherFiles[1].fileUrl}
                  description={otherFiles[1].description}
                />
              ) : null}
            </DecisionTreePreviewBottomImage>
          </DecisionTreePreviewImagesContainer>
        </DecisionTreePreviewContainer>
      ) : null}
    </>
  );
};

export default DecisionTreePreview;
