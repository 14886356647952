import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../redux/typings';

export const errorSelector = (state: RootState) => state.error;

export const selectErrorMessage = createSelector(
  errorSelector,
  (error) => error.message,
);

export const selectIsConnectedInternet = createSelector(
  errorSelector,
  (error) => error.internetConnection,
);

export const selectIsBackendDown = createSelector(
  errorSelector,
  (error) => error.isBackendDown,
);

export const selectBlockingError = createSelector(
  selectIsConnectedInternet,
  selectIsBackendDown,

  (isConnectedInternet, isBackendDown) =>
    isConnectedInternet === false || isBackendDown,
);

export const selectLoadingScreenErrors = createSelector(
  errorSelector,
  (error) => error.loadingScreenErrors,
);
