import styled from '@emotion/styled';
import { Form } from 'formik';

import ButtonPrimary from 'components/button-primary';

export const ShowcaseButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;
  @media (min-width: ${(props) => props?.theme?.breakpoints?.largeTablet}px) {
    justify-content: flex-end;
  }
`;

export const ShowcaseContactButton = styled(ButtonPrimary)`
  font-size: ${(props) => props?.theme?.typography.large20};
  width: 10rem;
  max-width: 40%;
`;

export const ShowcaseCancelButton = styled(ShowcaseContactButton)`
  background-color: ${(props) => props?.theme?.colors.grey[90]};
  margin-right: 2rem;
`;

export const ShowcaseContactContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  width: 100%;
`;

export const ShowCaseContactItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 90%;
  @media (min-width: ${(props) => props?.theme?.breakpoints?.largeTablet}px) {
    align-items: flex-start;
  }
`;

export const ShowcaseContactRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @media (min-width: ${(props) => props?.theme?.breakpoints?.largeTablet}px) {
    width: 50.38rem;
  }
`;

export const ShowcaseContactRowItem = styled.div`
  flex: 0 0 100%;
  @media (min-width: ${(props) => props?.theme?.breakpoints?.largeTablet}px) {
    flex: 0 0 48%;
  }
`;

export const ShowcaseContactSubtitle = styled.p`
  ${(props) => `
    color: ${props?.theme?.colors.grey[100]};
    font-size: ${props?.theme?.typography.large16};
    margin-top: 0.27rem;
    @media (min-width: ${props?.theme?.breakpoints?.largeTablet}px) {
      font-size: ${props?.theme?.typography.large24};
    }
  `}
`;

export const ShowcaseContactTitle = styled.p`
  ${(props) => `
    color: ${props?.theme?.colors.blue2[100]};
    font-size: ${props?.theme?.typography.large32};
    font-weight: ${props?.theme?.typography.semiBold};
    margin-bottom: 0px;
    margin-top: 1.33rem;
    @media (min-width: ${props?.theme?.breakpoints?.largeTablet}px) {
      font-size: ${props?.theme?.typography.large70};
      margin-top: 5rem;
    }
  `}
`;

export const ShowcaseForm = styled(Form)`
  display: flex;
  flex-direction: column;
`;
