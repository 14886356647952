import React, { useCallback, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import ShowcaseBottomItems from '../bottom';
import {
  ShowCaseActionButton,
  ShowcaseContainer,
  ShowCaseImagesContainer,
  ShowcaseItemsContainer,
  ShowCaseTopImage,
  ShowCaseTopImageContainer,
  ShowCaseTopImageOpacity,
} from './ShowcaseResumeComponents';
import { SHOWCASE, SHOWCASE_OVERVIEW } from '../../../routes';
import ResumeCard from './components/ResumeCard';
import ResumeVideo from './components/ResumeVideo';
import { useSelector } from 'react-redux';
import { selectFeaturedApplets } from '../selectors';
import ShowcaseModal from '../overview/components/ShowcaseModal';
import { QuestApplet } from 'models/QuestApplet';
import NavBar from '../navBar';
import { buildQuestAppletsList } from 'helpers/AppletHelpers';

interface ShowCaseResumeProps {
  image?: string;
  video?: string;
}

type ModalState = {
  open: boolean;
  applet?: QuestApplet;
};

const ShowCaseResume = ({ image, video }: ShowCaseResumeProps) => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [modalState, setModalState] = useState<ModalState>({ open: false });
  const featuredApplets = useSelector(selectFeaturedApplets);
  const groupedFeaturedApplets = useMemo(
    () => buildQuestAppletsList(featuredApplets),
    [featuredApplets],
  );

  const openModal = (applet: QuestApplet) => {
    setModalState({ open: true, applet });
  };

  const closeModal = () => {
    setModalState({ open: false });
  };

  const goToOverview = useCallback(
    () => history.push(`${SHOWCASE}/${id + SHOWCASE_OVERVIEW}`),
    [history, id],
  );

  const aboveFoldHero = useMemo(
    () =>
      image ? (
        <ShowCaseTopImageContainer>
          <ShowCaseTopImage src={image} />
          <ShowCaseTopImageOpacity />
        </ShowCaseTopImageContainer>
      ) : (
        <ResumeVideo videoSource={video ? video : ''} />
      ),
    [image, video],
  );

  return (
    <ShowcaseContainer>
      <NavBar />
      {aboveFoldHero}
      <ShowcaseItemsContainer>
        <ShowcaseModal modalState={modalState} closeModal={closeModal} />
        <ShowCaseImagesContainer>
          {groupedFeaturedApplets.map((applet) => (
            <ResumeCard
              applet={applet}
              key={applet.id}
              onAppletClick={openModal}
            />
          ))}
        </ShowCaseImagesContainer>
        <ShowCaseActionButton onClick={goToOverview}>
          Learn more
        </ShowCaseActionButton>
      </ShowcaseItemsContainer>
      <ShowcaseBottomItems />
    </ShowcaseContainer>
  );
};

export default ShowCaseResume;
