import { createSelector } from '@reduxjs/toolkit';
import { ChallengeApplet } from 'models/ChallengeApplet';

import { RootState } from 'redux/typings';

const selectExternalFeedbackState = (state: RootState) =>
  state.externalFeedback;

export const selectSubmission = createSelector(
  selectExternalFeedbackState,
  (externalFeedbackState) => externalFeedbackState.submission,
);

export const selectSubmissionApplets = createSelector(
  selectSubmission,
  (submission) => submission?.applets,
);

export const selectSubmissionAppletMap = createSelector(
  selectSubmissionApplets,
  (apps) =>
    apps?.reduce(
      (acc, app) => ({
        ...acc,
        [app.appletId]: app,
      }),
      {} as Record<string, ChallengeApplet>,
    ),
);

export const selectSubmissionAppletById = (appletId: string) =>
  createSelector(selectSubmissionAppletMap, (map) => map?.[appletId]);
