import React from 'react';

type ExamplesIconProps = {
  colorPrimary?: string;
  colorDisabled?: string;
  disabled?: boolean;
};

const ExamplesIcon = ({
  colorPrimary = '#007DC2',
  colorDisabled = '#8E8E93',
  disabled = false,
}: ExamplesIconProps) => {
  return (
    <svg width="20px" height="20px" viewBox="0 0 20 20">
      <title>B890D5C6-7F21-44C7-8A5D-D7602059FEB7</title>
      <g id="V3" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="Challenge---03-"
          transform="translate(-54.000000, -439.000000)"
          fill={disabled ? colorDisabled : colorPrimary}>
          <g id="Group" transform="translate(38.000000, 437.000000)">
            <g
              id="Icons/24/Examples"
              transform="translate(14.000000, 0.000000)">
              <path d="M8.1707,4 L8.2377,3.828 C8.6942,2.753 9.7591,2 11,2 C12.3062,2 13.4175,2.835 13.8293,4 L17,4 C18.6569,4 20,5.343 20,7 L20,10.171 C21.1652,10.583 22,11.694 22,13 C22,14.293 21.1794,15.403 20,15.823 L20,19 C20,20.657 18.6569,22 17,22 L14,22 C13.4872,22 13.0645,21.614 13.0067,21.117 L12.9945,20.851 C12.9182,19.816 12.0544,19 11,19 C9.8954,19 9,19.895 9,21 C9,21.552 8.5523,22 8,22 L5,22 C3.3431,22 2,20.657 2,19 L2,16 C2,15.487 2.386,15.064 2.8834,15.007 L3.1493,14.995 C4.1841,14.918 5,14.054 5,13 C5,11.895 4.1046,11 3,11 C2.4477,11 2,10.552 2,10 L2,7 C2,5.343 3.3431,4 5,4 L8.1707,4 Z M17,20 C17.5523,20 18,19.552 18,19 L18,15 C18,14.462 18.4256,14.02 18.9633,14.001 L19.0757,13.997 C19.5945,13.959 20,13.524 20,13 C20,12.487 19.614,12.064 19.1166,12.007 L18.8834,11.993 C18.386,11.936 18,11.513 18,11 L18,7 C18,6.448 17.5523,6 17,6 L13,6 C12.4872,6 12.0645,5.614 12.0067,5.117 L11.9933,4.883 C11.9355,4.386 11.5128,4 11,4 C10.4872,4 10.0645,4.386 10.0067,4.883 L9.9933,5.117 C9.9355,5.614 9.5128,6 9,6 L5,6 C4.4477,6 4,6.448 4,7 L4,9.126 C5.7252,9.57 7,11.136 7,13 C7,14.864 5.7252,16.43 4,16.874 L4,19 C4,19.552 4.4477,20 5,20 L7.126,20 C7.5701,18.275 9.1362,17 11,17 C12.7948,17 14.3135,18.182 14.8201,19.81 L14.874,20 L17,20 Z"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default ExamplesIcon;
